<template>
    <v-menu
        ref="menu"
        v-model="openMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="datetimeR"
        transition="scale-transition"
        offset-y
        max-width="600px"
        min-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
           <div class="inputItem align-center d-flex justify-center">
                <div class="label">{{label}}</div>
                <v-input class="sinputBorder d-flex align-center justify-center pa-2 elevation-5" dense hide-details >
                    <input v-bind:value="datetimeR"
                        v-bind="attrs"
                        v-on="on"
                        v-on:input="$emit('update:datetime', $event.target.value)" :placeholder="placeholder"/>
                </v-input>
            </div>
        </template>
        <v-card >
            <v-row no-gutters>
            <v-col cols="6">
                <v-date-picker
                    v-if="openMenu"
                    v-model="date"
                    full-width
                >
                </v-date-picker>
            </v-col>
            <v-col cols="6">
                <v-time-picker
                    :use-seconds="useSeconds"
                    ampm-in-title
                    v-if="openMenu"
                    v-model="time"
                    full-width                   
                ></v-time-picker>   
            </v-col>
            </v-row> 
            <v-card-actions>
                <v-btn @click="ok">{{$i18n.t("components.datatimepicker.btn.ok")}}</v-btn>
                <v-btn @click="close">{{$i18n.t("components.datatimepicker.btn.close")}}</v-btn>
            </v-card-actions>                                                   
        </v-card>        
      </v-menu>
</template>
<script>
export default {
    name:"SearchDateTimePicker",
    props:{
        placeholder:{
            type:String,
            default:""
        },
        label:{
            type:String,
            default:""
        },
        useSeconds:{
            type:Boolean,
            default:false
        },
        datetime:{
            type:String,
            default:" "
        }
    },
    data:()=>({
        openMenu:false,
        time:"",
        date:"",
        datetimeR:""
    }),
    methods:{
        ok(){
            if(this.time == ""){
                openNotice({
                    type:"error",
                    text:"请选择时间"
                })
                return
            }
            
            if(this.date == ""){
                openNotice({
                    type:"error",
                    text:"请选择日期"
                })
                return
            }
            this.$refs.menu.save(this.date + ' ' + this.time)
        },
        close(){
            this.$refs.menu.save(this.datetime)
        }
    }
}
</script>
<style scoped lang="scss">
.inputItem{
    margin: 10px;
}
.label{
    margin: 5px;
}
.sinputBorder{
    /* border:1px solid var(--v-white-darken4); */
    box-shadow:1px 0px 2px -1px  var(--v-white-darken4), 1px 1px 4px 0px  var(--v-white-darken4), 0px 1px 6px 0px  var(--v-white-darken4) !important;
    border-radius: 5px;    
}
:deep(.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections + .v-input__append-inner){
    width: 0 !important;

}
:deep(.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span,.v-time-picker-title__time .v-picker__title__btn),:deep(.v-time-picker-title__time span){
    height: 56px !important;
    font-size: 56px !important;
}
</style>
<template>
    <v-container class="containChartBox ma-0 pa-0" style="height: calc(100% - 54px);">
        <v-overlay
            absolute
            :value="overlay"
            >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>  
   <v-overlay
    class=" ma-16"
        absolute
        :value="noData"
        opacity="0.2"
        >
        {{dataShow}}
    </v-overlay>
    <v-card class="width100 height100">   
        <v-card-title class="ma-0 pa-1 rhiTitle">RCS图谱</v-card-title>
        <v-card-actions class="mapToolbar mt-4 mx-4"> 
            <v-row class="ma-0 pa-0 ">
                <v-col class="pa-0">
                    <v-menu open-on-click offset-y bottom @input="timerDraw">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                            elevation="0"                                                             
                            v-bind="attrs"
                            v-on="on"
                            >
                            {{scaleConfig.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="scaleConfig" >
                                <v-list-item
                                    v-for="(item, index) in scaleConfigList"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>                                   
                </v-col>
            </v-row>            
        </v-card-actions>
        <ColorBarConfig  @update="timerDraw" :dataKey="17" ref="colorBar"  class="mapColorBarConfig"/>        
        <div class="mx-1" style="position:absolute;top:32px;z-index: 300;">{{time}}</div>  
        <div ref="chart" class="plotychart rhitab width100" >
        </div>
    </v-card>   
</v-container>
</template>
    
<script>
import transferDataType from '@/plugins/datas/transferDataType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import scanType from '@/plugins/datas/scanType'
import Plotly from 'plotly.js-dist'
import {getPlsRcsByLatestApi} from '@/api'
export default {
      name: 'pls.windowUnion.RHI',
      components: {
        ColorBarConfig
      },
      props:{
        currentSite:{
            type:Object
        }}
      ,
      data(){
        return{
            datas:[],
            scaleConfig:{
                name:'1:1',
                value:1
            },
            scaleConfigList:[{
                name:'1:1',
                value:1
            },
            {
                name:'2:1',
                value:2
            },
            {
                name:'5:1',
                value:5
            },{
                name:'10:1',
                value:10
            },{
                name:'1:2',
                value:0.5
            }],           
            yaw:90,
            canvas:null,
            ctx:null,
            scale:2,
            step:0.01,
            offsetX:-20,
            offsetY:180,
            timer:null,
            scanDistance:6,
            time:'',
            overlay:false,
            dataShow:'正在加载数据',
            currentWatch:null
        }
      },
      computed:{
        noData(){
            return !this.overlay && (!this.datas || this.datas.length == 0) 
        },
      },
      watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()
            }
        },      
        currentSite:{
            handler(n,v){                                
                var that = this;  
                if(this.currentSite.extendInfo){            
                    let extendInfo = JSON.parse(this.currentSite.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }   
                this.datas = []        
                this.$nextTick(()=>{    
                    that.watchData(n.siteId)        
                    that.load();
                })                
            }
        },
        datas:{
            handler(n){
                // if(this.ctx){
                //     this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)
                // }
                this.timerDraw()
            },deep:true
        }
      },
      beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
      },
      mounted(){
        if(this.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
        } 
        var that = this;  
        setTimeout(() => {
            that.watchData(that.currentSite.siteId)
            that.loadRHIData()    
        }, 200);
      },
      methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                              
                if(n.SiteId == that.currentSite.siteId && (n.ScanMode == "RHI" || n.ScanMode == 2)){                                                       
                    let json = JSON.parse(JSON.stringify(n.jsonData));                                                                  
                    if(n.DataType == transferDataType['rcs'].value){   
                        that.datas = json.sort((a,b)=>{
                            return new Date(a.dataTime) - new Date(b.dataTime)
                        });
                        that.noData = false  
                        that.time=$help.periodToTimeStr(that.datas[0].period)
                    }        
                }
            }, {immediate: true })
        },  
        loadRHIData(){            
            var that = this; 
            that.overlay = true;    
            getPlsRcsByLatestApi({
                id:that.currentSite.siteId,               
                success:(d)=>{ 
                    if(that.datas.length == 0){
                        that.noData = true;
                        that.dataShow='无数据';
                    }                   
                    that.overlay = false;
                    that.datas = d.map(a=>{
                        return {
                            angleOfPitch: a.DprPitch,
                            data:a.DprValue,                                                    
                            dataTime: new Date(a.DprTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.rcs,
                            distanceData: a.DprDis,
                            period:a.DprPeriod,
                            siteId: a.DprSiteId,
                            scanType:scanType[a.DprScanMode],
                            straightAngle:a.DprStraight
                        }
                    })       
                    let period =d[0].DprPeriod?.split('_')[0]       
                    that.time = $help.periodToTimeStr(period)
                    that.noData = false;            
                }
            })                   
        },               
        loadChart(){   
             //处理数据
             var that = this;
            let d = this.scanDistance //扫描公里
            let target = this.$refs.chart
            if(!this.$refs.colorBar || !target){
                return;
            }
            let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
            let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);                         
            let _datas =this.datas.map(a=>{
                return {
                    angle:a.angleOfPitch,
                    data:a.data,                    
                    dataTime:a.dataTime,
                    distanceData:a.distanceData,       
                }
            });  
            if(_datas.length == 0)     {
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                                
                var data = [
                {
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,                    
                    }
                ];
            
                var layout = {               
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,   
                    uirevision:'true',       
                    xaxis: {
                        constrain:'range',
                        color:fontColor,
                        title:"距离(km)",
                        range:[0,8],
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        scaleanchor:'y',
                        scaleratio:this.scaleConfig.value
                    },
                    yaxis: {     
                        constrain:'range',
                        color:fontColor,  
                        range:[-0.1,3],     
                        dtick:1,
                        domain:5,
                        title:"距离(km)",
                        tickmode:'linear',
                        tick0:0
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:48,
                        r:46,  
                        l:42  
                        // l:22,
                    }
                };                       
                Plotly.newPlot(target, data, layout, {responsive: true,scrollZoom: true});
                return
            }            
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (distanceData[0])/distanceData[1]   //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
            let hoverText = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => ''));             
            $help.drawScan(_datas,(obj)=>{                
                zData[obj.y][obj.x]=obj.value;
                hoverText[obj.y][obj.x]= `角度：${(obj.angle).toFixed(2)}<br />距离：${(obj.magnitude/100).toFixed(4)}`
            },()=>{
                let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                          
                var data = [
                {
                    y: yData,
                    x: xData,
                    z: zData,
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:hoverText,
                    colorscale:that.$refs.colorBar.colorScaleValue,
                    zmin:that.$refs.colorBar.rangeInfo[0].min,
                    zmax:that.$refs.colorBar.rangeInfo[that.$refs.colorBar.rangeInfo.length-1].max
                    }
                ];
        
                var layout = {               
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,     
                    uirevision:'true',     
                    xaxis: {
                        constrain:'range',
                        color:fontColor,
                        range:[0,8],
                        title:"距离(km)",
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        scaleanchor:'y',
                        scaleratio:this.scaleConfig.value
                    },
                    yaxis: {     
                        constrain:'range',
                        color:fontColor,   
                        range:[-0.1,3],    
                        dtick:1,
                        domain:5,
                        title:"距离(km)",
                        tickmode:'linear',
                        tick0:0
                    },
                    
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },

                    margin:{
                        t:48,
                        r:46,  
                        l:42  
                        // l:22,
                    }
                };     
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
                }       
            },noDataLength,scanLength,distanceData[2],{d:d})            
        }, 
        timerDraw(){            
          var that = this;
          if(this.timer){
            clearTimeout(this.timer)
          }
          this.timer = setTimeout(() => {
            //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
            that.loadChart()  
          }, 1000/3);
        },                           
      }
    }
</script>
    
<style scoped lang="scss">
.containChartBox{
    position: relative;
}
.mapToolbar{
position: absolute;
z-index: 267;
width:120px;
right:0px;
}
.rhitab{
    top:54px;
    height: calc(100% - 54px);
    position: absolute;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
.rhiTitle{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.plotychart{
    :deep(.modebar){
        padding-top: 48px;
        padding-right:60px ;
    }
}
</style>
    
import { getField, updateField } from 'vuex-map-fields'
import {getMonitorPointsApi} from '@/api'
import windAngle from '@/plugins/datas/windAngle'
import Vue from 'vue';


const state ={        
  avatarPath:1,
  widthHeight:'0,0',
  currentSite:{},
  currentType:{},
  alarmFilter:false,//控点过滤
  message:"",  //只存放新消息,
  messageList:[],
  alarmProfileList:[],
  siteData:{},
  monitorPoints:[],
  monitorPointTime:null, 
}

const getters = {
    getField, 
}

const mutations = {
    updateField, 
    updateAlarmFilter(state,data){
        state.alarmFilter = data;
    },
    updateAvatarPath(state,data){
        state.avatarPath = data;
    },
    updateCurrentSite(state,data){
        state.currentSite = data;        
    },
    updateCurrentType(state,data){
        state.currentType = data;        
    },
    updateMessage(state,data){
        state.message = data
        state.messageList = state.messageList.slice(0,6);
        state.messageList.unshift(data)
    },    
    updateSiteData(state,data){
        if(data.siteList){
            for(var site of data.siteList){
                Vue.set(state.siteData,site.siteId,{})
            }
        }else{
            //state.siteData[data.SiteId] = JSON.parse(JSON.stringify(data.data))
            Vue.set(state.siteData,data.data.SiteId,data.data)
           
        }
    },
    updateCurrentSiteScanType(state,data){
        if(state.currentSite.siteId == data.siteId){
            Vue.set(state.currentSite,"scanType",data.scanType)            
        }
    },
    updateMonitorPoints(state,data){
        state.monitorPoints = data;        
        state.monitorPointTime = new Date()
    },
    updatewidthHeight(state,d){
        state.widthHeight = d;
    },
    updateAlarmProfile(state,data){
        state.alarmProfileList = data;
    }     
}

const actions = {  
    updateCurrentSiteById({rootState,commit},data){
        var sites = rootState.storage_user.sites
        var currentSite = sites.find(a=>{
            return a.siteId == data.data
        })        
        if(currentSite){
            commit("updateCurrentSite",currentSite)       
            if(data.callback){
                data.callback(currentSite)
            }
        }        
    },
    getMonitorPoints({commit,state,rootState},callback){
        if(!state.monitorPointTime){
            getMonitorPointsApi({
                params:{                    
                    PageIndex:1,
                    PageSize:10000,
                },
                success:(d)=>{             
                    let _data = d.Data
                    commit("updateMonitorPoints",_data)                         
                    callback(_data)                  
                }
            })
        }else{
            var overtime = (new Date().getTime() - state.monitorPointTime.getTime()) / (1000 * 60 )
            if(overtime > 5 || state.monitorPoints.length == 0){
                getMonitorPointsApi({
                    params:{                    
                        PageIndex:1,
                        PageSize:10000,
                    },
                    success:(d)=>{   
                        let _data = d.Data
                        commit("updateMonitorPoints",_data)
                        callback(_data)  
                    }
                })
            }else{
                callback(state.monitorPoints)  
            }
        }       
    },
    getSitesByCurrentType({rootState,state},fun){
        var sites = rootState.storage_user.sites.filter(a=>{
            return a.type == state.currentType.code;
        }).sort((a,b)=>{
            return b.commitStatus - a.commitStatus;
        })              
        fun(sites)
    },
    filterAlarmPoints({dispatch,state},data){
        dispatch("getMonitorPoints",(d)=>{
            let siteId = data.siteId;
            let windDirection = data.windDirection;
            let alarmList = data.alarmList;
            let angleInfo = windAngle.find(a=>{
                return a.name == windDirection
            })
            let _currentPoints = d.filter(a=>{
                return a.BelongSites.indexOf(siteId) >=0;
            })
            let pass = [];
            if(alarmList && alarmList.length > 0 && angleInfo && angleInfo.angle != null){
                for(var alarmPoint of alarmList){
                    for(var mp of _currentPoints){
                        let angleX =  $help.getAngle(mp.BmpLng,mp.BmpLat,alarmPoint.lng,alarmPoint.lat)                                
                        var _angel =  Math.abs(angleInfo.angle - angleX)
                        _angel = _angel > 180 ? 360 - _angel:_angel;                                
                        if(_angel < 22.5){
                            pass.push(alarmPoint);
                            break ;
                        }
                    }
                }
            }
            data.callback(pass)
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

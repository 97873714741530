<template>
    
</template>
<script>
export default {
    name:"sys.about"
}
</script>
<style scoped>

</style>
import axios from "axios"
import Qs from 'qs'
import {i18n} from '@/plugins/helper/vuetify'
import router from "@/router";
import * as url from '@/api/url'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

//非验证路由
const except = [url.loginURL,url.uploadMinioURL,url.uploadMinioURLServer,url.userURL + '/'];
if(window.requestIdleCallback){
    requestIdleCallback(()=>{
        getUniqueCode();
    })
}else{
    setTimeout(() => {
        getUniqueCode();
    }, 500);
}


axios.defaults.timeout = 1000 * 60 * 3;//默认超时
axios.defaults.headers['Accept-Language'] = 'zh-'
axios.defaults.headers['clientType'] = "Web_Client"

function getUniqueCode(){    
    FingerprintJS.load().then(fp => {
        fp.get().then(result => {
            axios.defaults.headers['clientId'] = result.visitorId                      
        });
    });
}

//添加默认头文件
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
//拦截响应
axios.interceptors.response.use((response) => {    
    return response;
},function (error) {      
    //minio 异常
    if(error.request.responseURL.indexOf(url.uploadMinioURLServer) >= 0 || error.request.responseURL.indexOf(url.uploadMinioURL) >= 0){        
        if(error.response.status == "401"){
            openNotice({
                type:'error',
                text:"文件加载验证异常"
            })    
        }
        else if(error.response.status == "403") {
            openNotice({
                type:'error',
                text:"文件加载异常"
            })  
            console.log(error)
        }
    }else{          
        //超时或未登录             
        if(error.response.status == "401" && error.response.statusText == "Unauthorized"){                
            //提示错误消息 
            localStorage.removeItem("token")
            router.push({path:'/login'})        
            let msg = i18n.t('sys.self.authError')
            if(window.openNotice){
                openNotice({
                    type:'error',
                    text:msg
                }) 
            }
                                       
        }   
        else if(error.response.status == "404"){
            debugger
            localStorage.removeItem("token")
            router.push({path:'/login'})        
            let msg = i18n.t('sys.self.authError')
            if(window.openNotice){
                openNotice({
                    type:'error',
                    text:msg
                }) 
            }
              setTimeout(() => {                                
                window.location.reload()
            }, 800); 
        }
        else if(error.response.status == "403") {
            localStorage.removeItem("token")
            router.push({path:'/login'})        
            //提示错误消息 
            let msg = error.response && error.response.data && error.response.data.title ? error.response.data.title : i18n.t('sys.self.systemError')
            setTimeout(() => {
                openNotice({
                    type:'error',
                    text:msg
                })
            }, 500);        
        }
        else{
            //提示错误消息             
            let msg = error.response && error.response.data && error.response.data.title ? error.response.data.title : i18n.t('sys.self.systemError')
            if(msg == "One or more validation errors occurred."){
                msg ="";
                for(var i of error.response.data.errors){
                    msg += i;
                }                
            }
            openNotice({
                type:'error',
                text:msg
            })
        }    
    }    
    return Promise.reject(error);
});
//拦截请求
axios.interceptors.request.use(
    function(config) {                
        //加载loading
        let _url = "";
        if(typeof(config.url) == "object"){
            _url = config.url.url
        }
        else{
            _url = config.url;
        }        
        let _index = except.findIndex(a=>{
            return a == _url.toLocaleLowerCase() || _url.indexOf(a) >= 0 
        })
        let token = localStorage.getItem("token");                               
        if(_index < 0 && !config.noAuth){            
            if(token == "" || !token){                
                router.push({path:'/login'})
                let msg = i18n.t('sys.self.authErrorNoLogin')
                openNotice({
                    type:'error',
                    text:msg
                })  
                return;   
            }            
        }     
        if(_url.indexOf(url.uploadMinioURL) >= 0 || _url.indexOf(url.uploadMinioURLServer) >=0 || _url.indexOf('v3/geocode') >= 0 || _url.indexOf('v3/weather') >=0){            
        }else{
            config.headers.Authorization = "Bearer "+token;  
        }    
        if(config.method=='delete' && _url == url.loginURL){            
            if(token == "" || !token){                
                router.push({path:'/login'})
                let msg = i18n.t('sys.self.authErrorNoLogin')
                openNotice({
                    type:'error',
                    text:msg
                })  
                return;   
            }  
            config.headers.Authorization = "Bearer "+token; 
        }        
        return config;
    },   
    function(error) {
        return Promise.reject(error);
    }
);

//post添加
function post(_url,params){
    axios({
        url:_url,
        method:"post",
        data:params.data,
        noAuth:params.noAuth,
        params:params.params,
        headers:params.headers?params.headers:{
            "Content-type":"application/json"
        },
        ...params.config
    }).then((d)=>{
        if(params.success){
            params.success(d.data)
        }          
    }).catch(function (error) {                
        if(params.err)
        {
            params.err()
        }           
    })
};
//上传到minio
function upToMinio(_url,file,params,onUploadProgress){   
    _url = _url.replace(url.uploadMinioURLServer,url.uploadMinioURL)
    // let bucketName = _url.split('/')[0]
    // let objectName = _url.replace(bucketName,"").split('?')[0];    
    // _url = url.uploadAndDownLoadURL 
    // let fd = new FormData();
    // fd.append('file',file)    
    axios.put(_url,file,{
        // params:{
        //     bucketName:bucketName,
        //     objectName:objectName
        // },
        headers: {
            'Content-Type': 'application/octet-stream',            
            //"Content-Type": "multipart/form-data"
        },        
        onUploadProgress:(d)=>{
            if(onUploadProgress){
                onUploadProgress(d)
            }
        }
    }).then((d)=>{        
        if(params.success){
            params.success(d.data)
        }          
    }).catch(function (error) {  
        console.log(error)              
        if(params.err)
        {
            params.err()
        }           
    })    
}
//从minio下载
function downToMinio(_url,params){      
    //_url = _url.replace(url.uploadMinioURLServer,"http://124.133.246.59:13395/")    
    //_url = _url.split("?")[0];  
    _url = _url.replace(url.uploadMinioURLServer,url.uploadMinioURL)  
    let _currentId = $help.guid();
    axios.get(_url,{
        responseType:"blob",
        onDownloadProgress: function (progressEvent) {
            //console.log( progressEvent.loaded,progressEvent.total)
            // 处理原生进度事件           
            if((progressEvent.total - progressEvent.loaded) == 0){
                updateFileProcess(_currentId,true)
            }else{
                updateFileProcess(_currentId,false, progressEvent.loaded,progressEvent.total)
            }
        },
    }).then(d=>{        
        var blob = new Blob([d.data]);
        let filename = ""
        if(params.fileName){
            filename = params.fileName
        }else{
            filename = d.headers["content-disposition"]?.split(";")?.filter(a=>{
                return a.indexOf("filename") >= 0
            })?.pop()?.replace(/(filename=)|(")/g,"")?.replace("filename*=UTF-8''","")?.replace("filename = ","")?.replace(" ","")
        }
        if(params.reCallBack){
            params.reCallBack(d,filename)
        }else{   
            if("download" in document.createElement("a")) {
            // 非IE下载
                var elink = document.createElement("a");                
                elink.download = filename;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
            // IE10+下载
                navigator.msSaveBlob(blob, filename); 
            }           
            if(params.success){
                params.success(d)
            }    
        }
    }).catch(function (error) {                
        if(params.err)
        {
            params.err()
        }           
    })   
}


function put(_url,params){
    axios({
        url:_url,
        noAuth:params.noAuth,
        method:"put",
        data:params.data,
        params:params.params,
        ...params.config
    }).then((d)=>{
        if(params.success){
            params.success(d.data)
        }          
    }).catch(function (error) {                
        if(params.err)
        {
            params.err()
        }           
    })
};

function patch(_url,params){
    axios({
        url:_url,
        method:"patch",
        noAuth:params.noAuth,
        data:params.data,
        params:params.params,
        ...params.config
    }).then((d)=>{
        if(params.success){
            params.success(d.data)
        }           
    }).catch(function (error) {
        if(params.err)
        {
            params.err(d.data)
        }   
    })
}

function get(_url,params){        
    axios({
        url:_url,
        method:"get",  
        noAuth:params.noAuth,    
        params:  params.params,
        paramsSerializer: function (par) {
            return Qs.stringify(par, {arrayFormat: 'repeat'})
          },
        ...params.config
    }).then((d)=>{
        if(params.success){
            params.success(d.data,params?.params?.taskId)
        }           
    }).catch(function (error) {
        if(params.err)
        {
            params.err(d.data)
        }   
    })
}

function getFile(_url,success){
    axios({
        method: 'get',
        url: _url,       
        responseType: 'blob'
      })
    .then(function (d) {
        success(d)
        //response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
    });
}

function remove(_url,params){
    axios({
        url:_url,
        noAuth:params.noAuth,
        method:"delete",
        data:params.data,
        params:params.params,
        ...params.config
    }).then((d)=>{
        if(params.success){
            params.success(d.data)
        }           
    }).catch(function (error) {
        if(params.err)
        {
            params.err(error)
        }   
    })
}

function allGet(urlList,params){
    Promise.all(
        urlList.map(
            (url,i) => axios({
                url:url,
                method:"get",  
                noAuth:params.noAuth,    
                params: !params?.params ? '' : params?.params[i],
                paramsSerializer: function (par) {
                    return Qs.stringify(par, {arrayFormat: 'repeat'})
                  },
                ...params.config
            })
        )).then(
        axios.spread((...allData) => {
            if(params.success){
                params.success(allData.map(a=>{
                   return a.data
                }))
            }   
        })
      ).catch(function (error) {
        if(params.err)
        {
            params.err(error)
        }   
    });          
}

export default{
    allGet,
    post,
    get,
    patch,
    upToMinio,
    downToMinio,
    getFile,
    put,
    remove
}




<template>
<v-container class="scrollBar">
    <v-expand-transition>
        <canvas v-show="animateNotice" ref="noticeScroll" class="width100 height100 scrollCanvas"></canvas>        
    </v-expand-transition>
    <v-icon v-show="animateNotice" class="closeIcon white--text" @click="close">mdi-close</v-icon>
</v-container>    
</template>
<script>
export default {
    name:"scrollNoticeTopPlus",
    data(){
        return {
            animateNotice:false,
            animaNo:null,
            canvas:null,
            ctx:null,
            x:0,
            messages:[],
            startStatus:false,       
        }
    },
    watch:{
        "$store.state.storage_global.message":{
            handler(d){
                let warnText= this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls ?this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls: '污染物'
                let _msg = `${d.date}:${d.position?.formatted_address}发现${warnText}报警点`                
                this.messages.push(_msg)                
                this.animateNotice = true;
                if(!this.startStatus){
                    this.startStatus = true;
                    var that = this;
                    this.$nextTick(()=>{
                        that.canvas = that.$refs.noticeScroll;
                        that.canvas.width = that.canvas.clientWidth;
                        that.x = that.canvas.width;
                        that.canvas.height = 30; 
                        that.ctx = this.canvas.getContext('2d')
                        that.ctx.fillStyle = '#e9651e';
                        that.ctx.font = '18px STheiti, SimHei';
                        that.start()           
                    })                    
                }                
            }
        }
    },
    mounted(){
        var that = this;
        var stepSet = function(timestamp, elapsed){
            //设置帧率
            if (elapsed > 1000 / 29.9) {
                elapsed = 0
            }        
            requestAnimaFrame2(
                _timestamp =>stepSet(elapsed + _timestamp - timestamp)
            )
        }  
        window.requestAnimaFrame2 = 
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 30);
        };
        requestAnimaFrame2(timestamp => stepSet(timestamp, 0))   
        this.load()
    },
    methods:{
        load(){            
            this.canvas = this.$refs.noticeScroll;
            this.canvas.width = this.canvas.clientWidth;
            this.canvas.height = this.canvas.clientHeight;            
            this.ctx = this.canvas.getContext('2d')
            this.ctx.fillStyle = '#e9ab1e';
            this.ctx.font = '18px STheiti, SimHei';            
        },
        async start(){   
            var that = this;         
            that.animaNo = requestAnimaFrame2(()=>{
                var msg = "";
                if(that.messages.length > 0){
                    msg = that.messages[0]
                }else{
                    that.close();
                }
                that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height);
                that.x -=1; 
                if(that.x < (msg.length * (-18))){
                    that.x = that.canvas.width;
                    //消除已发送的消息                    
                    that.messages = that.messages.slice(1)
                }                             
                that.ctx.fillText(msg,that.x,that.canvas.height-10)
                that.start();
            })
        },
        stop(){            
            if(this.animaNo){
                cancelAnimationFrame(this.animaNo)
            }            
        },
        close(){
            this.stop();
            this.startStatus = false;
            this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
            this.animateNotice = false;
        }
    }
}
</script>
<style scoped lang="scss">
.scrollBar{
    position: absolute;
    width:100%;    
    max-width: 100%;
    top: 100%;
    padding: 0;
    left: 0;
}
.scrollCanvas{
    height: 30px;
    background-color: rgba(0,0,0,0.3);
}
.closeIcon{
    position: absolute;
    right: 10px;
    top: 5px;
}
</style>
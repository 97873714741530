<template>
<v-container class="containMapBox" :style="`height:${height}px;width:${width}px`">
    <v-overlay
    class="ma-3 mt-11"
          absolute
          :value="overlay"
        >        
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
        class="ma-3 mt-11"
            absolute
            :value="noData"
            opacity="0.2"
            >
        暂无数据
        </v-overlay>
    <v-card class="width100 height100 ma-0 d-flex flex-column">
        <v-card-actions class="pa-0 ma-0 ">
            <v-row no-gutters class="ma-0 pa-0 align-center">
                <v-icon style="min-width:32px;flex-shrink: 0;" class="ma-2" @mousedown="resize">mdi-arrow-all</v-icon>     
                <div  style="min-width:68px;flex-shrink: 0;">{{title}}</div>
                <v-spacer></v-spacer>
                <v-col cols="1"><v-icon class="ma-2 move" title="移动">mdi-pan</v-icon></v-col>
                <v-col cols="1" v-show="false"><ColorBarConfig /></v-col>
                <v-col cols="1" class="mr-2"><v-icon class="ma-2"  @click="$emit('close',keyV)">mdi-close</v-icon></v-col>                
            </v-row>
        </v-card-actions>
        <div  class="map width100 height100">
            <div class="d-flex flex-column colorBar flex-column-reverse" style="z-index:1">
                <div  v-for="p,i in pieces" :key="i">
                    <div :style="`background-color:${p.color};width:20px;height:12px;border-radius:2px;margin-top:10px;`">
                        <div :style="`
                        color:${p.color};
                        white-space: nowrap;
                        text-align: right;
                        font-size: 14px;
                        right: 30px;
                        position: absolute;`">
                            {{`${p.gt} - ${p.lte}`}}
                        </div>
                    </div>
                </div>
            </div>         
            <div ref="map" class="width100 height100"> 
                           
            </div>    
        </div>                   
    </v-card>
</v-container>    
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import equipmentType from '@/plugins/datas/equipmentType.json'
import { getAgtExternalApi} from '@/api'
export default {
    name:"ExternalDataMap",  
    components:{ColorBarConfig},  
    props:{
        keyV:{
            type:String
        }
    },
    computed:{
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },
        title:function(){
            return equipmentType.find(a=>{
                return a.key == this.keyV
            }).name
        }
    },
    data(){
        return {
            overlay:false,
            map:null,
            mapEchart:null,
            mapContextMenuPositon:[],
            opacity:0.7,
            center:[],
            width:370,
            height:268,
            data:[],
            pieces:null
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.load();                            
            }
        },
        "$store.state.storage_agt.agtId":{
            handler(n,v){    
                this.load();                            
            }
        },
        "$store.state.storage_agt.widthHeight":{
            handler(n,v){    
                if(localStorage.getItem("monitorCarExternalHeight")){
                    this.height = localStorage.getItem("monitorCarExternalHeight")
                }
            
                if(localStorage.getItem("monitorCarExternalWidth")){
                    this.width = localStorage.getItem("monitorCarExternalWidth")
                }                        
            }
        },
        "$store.state.storage_agt.loadKey":{
            handler(n,v){   
                if(this.keyV == n){
                    this.load()                
                }                 
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                        if(this.data.length == 0){                
                            this.mapEchart.showLoading({
                                text: '暂无数据',
                                showSpinner: false,    // 隐藏加载中的转圈动图
                                textColor: '#9d9d9d',
                                maskColor: 'rgba(0, 0, 0, 0.8)',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                fontFamily: 'Microsoft YaHei'
                            });
                            return
                        }
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                        if(this.data.length == 0){                
                            this.mapEchart.showLoading({
                                text: '暂无数据',
                                showSpinner: false,    // 隐藏加载中的转圈动图
                                textColor: '#9d9d9d',
                                maskColor: 'rgba(255, 255, 255, 0.8)',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                fontFamily: 'Microsoft YaHei'
                            });
                            return
                        }
                    }
                }
            }
        },
    },
    mounted(){
        this.loadMap();
        this.load()               
    },
    methods:{
        load(){
            if(localStorage.getItem("monitorCarExternalHeight")){
                this.height = localStorage.getItem("monitorCarExternalHeight")
            }
        
            if(localStorage.getItem("monitorCarExternalWidth")){
                this.width = localStorage.getItem("monitorCarExternalWidth")
            } 
            let siteId = this.$store.state.storage_global.currentSite.siteId;
            let agtId = this.$store.state.storage_agt.agtId;
            let key = this.keyV;
            var dataInfo = this.$store.state.storage_agt.externalDatas.find(a=>{
                return a.siteId == siteId && a.agtId == agtId && a.key == this.keyV;
            })            
            if(!dataInfo){
                this.$store.commit("storage_agt/updateExternalDataInfo",{
                    siteId:siteId,
                    agtId:agtId,
                    key:key,
                    datas:[]
                },{root:true}) 
                this.loadData(key,siteId,agtId);
            }   
            else{
                this.data = dataInfo.datas;
                 var that = this;
                this.$nextTick(a=>{
                    that.loadPath();
                })                     
            }           
        },
        loadMap(){
            let target = this.$refs.map;            
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    //center: mapcenter,
                    center:center ,
                    rotateEnable:false,
                    pitchEnable:false,   
                    isHotspot:false,             
                    pitch: 0,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }                 
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;        
             if(!this.map){
                this.map = mapEchart.getModel().getComponent('amap').getAMap();
                //添加右键菜单
                this.addContextMenu();
                //加载控件 
                this.addControl(); 
            }  
            this.map = mapEchart.getModel().getComponent('amap').getAMap();                                       
            //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl();                                          
        }, 
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //添加一个缩放控件
                // map.addControl(new BasicControl.Zoom({
                //     theme:"dark",
                //     position: 'lb'
                // }));
        
                //缩放控件，显示Zoom值
                // that.map.addControl(new BasicControl.Zoom({
                //     position: config.zoom.position,
                //     theme:"myself",
                //     showZoomNum: true
                // }));
                
                //比例尺
                var scale = new AMap.Scale(
                    {
                        position: [{
                            top: '110px',
                            left: '40px'
                        }]                  
                    }
                );
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '60px',left: '10px' },
                    theme:'myself'
                }));
                        
                
                let el = that.map.getContainer();
                //地点定位                
                let styleP = 'cursor:pointer;bottom:10px;left:15px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{   
                    that.map.setCenter([that.data[0].DaeLng, that.data[0].DaeLat])   
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]); 
            })    
            
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },
        loadData(key,siteId,agtId,page=1,datas = [],time){           
            var that = this;
            this.overlay = true            
            getAgtExternalApi({
                    id:siteId,
                    agtId:agtId,
                    params:{
                        LastDate:time,
                        PageSize:1000,
                        DadTypeId:key
                    },
                    success:(d)=>{   
                        datas = datas.concat(d.Data)                                               
                        if(datas.length == d.Total){   
                            let _datas = datas.reverse();  
                            that.overlay = false;                            
                            that.data = _datas                       
                            that.$store.commit("storage_agt/updateExternalDatas",{
                                siteId:siteId,
                                agtId:agtId,
                                key:key,
                                datas:_datas
                            },{root:true})   
                            that.loadPath();  
                        }else{
                            that.loadData(key,siteId,agtId,page+1,datas,d.Data[d.Data.length -1].DaeTime)
                        }                                            
                    }
                })
        },
        loadPath(){
            this.mapEchart.hideLoading();           
            let data = [];        
            var equipment = equipmentType.find(a=>{
                return a.key == this.keyV;
            })                          
                  
            let pieces = equipment.colors.map((a,index)=>{
                return {
                    color:a,
                    gt:index * equipment.max/5, //大于
                    lte:equipment.max/5 * (index + 1) //小于等于
                }
            })     
            this.pieces = pieces;
            for(var i in this.data){                                             
                if(i!=0){
                    if(i > 0){
                        let _ptime = new Date(this.data[i-1].DaeTime)
                        let _ntime = new Date(this.data[i].DaeTime)
                        var dateDiff = _ntime.getTime() - _ptime.getTime();
                        var minDiff = Math.floor(dateDiff / (  60 * 1000));//计算出相差分钟
                        if(Math.abs(minDiff) >= 10){
                            continue;
                        }    
                    } 
                    let color = "";   
                    if(this.data[i].DaeValue1 < pieces[0].gt){
                        color = pieces[0].color
                    }else if(this.data[i].DaeValue1 > pieces[pieces.length -1].lte){
                        color = pieces[pieces.length -1].color
                    }else{
                        color= pieces.find(a=>{
                            return a.gt<= this.data[i].DaeValue1 && a.lte >= this.data[i].DaeValue1;
                        }).color
                    }
                    
                    let time = new Date(this.data[i].DaeTime).pattern("yyyy年MM月dd HH:mm:ss");                                                     
                    if(this.data[i-1].DaeLng == "0.0" || this.data[i-1].DaeLat == "0.0" || this.data[i].DaeLng == "0.0" || this.data[i].DaeLat == "0.0"){}
                    else{
                        let point = [[ this.data[i-1].DaeLng, this.data[i-1].DaeLat],[ this.data[i].DaeLng, this.data[i].DaeLat]]
                        let obj = {
                            name:this.data[i].DaeValue1 + "\n " + time,
                            coords:point,
                            lineStyle: {
                                normal: {
                                    color:color                            
                                    //color: echarts.color.modifyHSL('#5A94DF', Math.round(hStep * idx))
                                }
                            },
                            emphasis:{
                                label:{
                                    show:true,
                                    fontSize:16,
                                    fontWeight:700,

                                }
                            }
                        }
                        data.push(obj)
                    }
                }                                    
            }    
            let option = this.mapEchart.getOption();//AgtSN: "20211125161414"         
            option.series= [{
                    type:"lines",
                    coordinateSystem:'amap',
                    lineStyle:{          
                        width:5,
                    },
                    data:data
                }]  
            this.mapEchart.setOption(option)  
            var that = this;
            this.$nextTick(()=>{  
                if(that.data.length > 0){
                    setTimeout(() => {                    
                        that.map.setCenter([that.data[0].DaeLng, that.data[0].DaeLat])   
                    }, 200);                         
                }                  
            })                               
        },

        //重置大小
        resize(){
            var that = this;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = Number(that.width) - e.movementX ;
                let resetH = Number(that.height) + e.movementY;
                that.width = resetW > 0.4 * document.body.clientWidth ? 0.4 * document.body.clientWidth : resetW < 370 ? 370 : resetW;
                that.height = resetH > 0.4 * document.body.clientHeight ? 0.4 * document.body.clientHeight : resetH < 260 ?260 : resetH;
                localStorage.setItem("monitorCarExternalHeight",that.height)
                localStorage.setItem("monitorCarExternalWidth",that.width)
                that.$store.commit("storage_agt/updatewidthHeight",`${that.width},${that.height}`)          
            }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{
                    
                document.removeEventListener('mousemove',fun)
            });
        },
    }
}
</script>
<style scoped lang="scss">
.containMapBox{
    min-width:306px;
    min-height: 204px;   
}
.map :deep(.amap-scalecontrol){
    bottom:10px;
    right:20px;
}
.map :deep(.ec-extension-amap){
    position: relative !important;
    width: 100%;
    height: 100%;
}
.colorBar{
    bottom: 40px;
    right: 10px;
    position: absolute;
}
</style>
<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">
        <v-overlay
          absolute
          :value="overlay"
          class="ma-3"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <ColorBarConfig @update="timerDraw" :dataKey="52"  ref="colorBar" class="mapColorBarConfig"/>        
        <v-card height="100%" width="100%" elevation="1"> 
            <v-card-title class="ma-0 pa-1 heatmapTitle">矢量风</v-card-title>               
            <div class="mx-1" style="position:absolute;top:32px;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>                             
            <div ref="chart" class="width100 " style="height:calc(100% - 32px);top: 32px;position: relative;">
            </div>
        </v-card>                      
    </v-container>
</template>
<script>
import { getVwlsQuiverApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
import quiver from '@/plugins/helper/D3DrawHelper/quiver';
export default {
    name:'vwls.realtime.quiver',
    components: {
        ColorBarConfig
    },
    data(){
        return {
            overlay:false,
            dataQuiver:[],
            dataQuiverBack:[],
            quiverbkScale:1,
            currentWatch:null,
            timer:null
        }
    },
    computed:{        
        noData(){
            return !this.overlay&&(!this.dataQuiver|| this.dataQuiver.length == 0) 
        },
        time(){
            if(!this.dataQuiver||this.dataQuiver.length == 0){
                return ''
            }            
            let period = this.dataQuiver[0]?.period
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }            
        },
        yaw()
        {
            if(!this.dataQuiver||this.dataQuiver.length == 0){
                return ''
            } 
        
            if(this.dataQuiver.length>0)
            {
                return Number(this.dataQuiver[0].yaw)%360;
            }
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){  
                this.currentWatch()   
                this.dataQuiver = [];
                this.dataQuiverBack = [] 
                let currentScanType = this.$store.state.storage_global.currentSite.scanType;   
                if(currentScanType == "RHI" || currentScanType == "PPI"){ 
                    this.watchData(n.siteId )   
                    this.load()    
                }            
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()     
            }
        },    
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;
        setTimeout(() => {
            let currentScanType = that.$store.state.storage_global.currentSite.scanType;   
            if(that.dataQuiver.length == 0  && (currentScanType == "RHI" || currentScanType == "PPI")){   
                that.watchData(that.$store.state.storage_global.currentSite.siteId )             
                that.load()  
            }  
        }, 600);            
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                                     
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == transferDataType.quiverWind.value ){                                                                                                                                       
                    let json = JSON.parse(JSON.stringify(n.jsonData))
                    that.dataQuiver = [];
                    that.dataQuiverBack = [];                    
                    let quiver = json[0].quiver;
                    let back = json[0].back;
                    for(var i in quiver.localAngle){
                        let _item = that.dataQuiver.find(a=>a.angle ==quiver.localAngle[i])
                        if(!_item){                            
                            _item = {
                                siteId:quiver.siteId,
                                period:quiver.period,
                                yaw:quiver.yaw,
                                dataTime:quiver.dataTime.replace("T"," "),
                                scanMode:scanType[quiver.scanType],
                                angle:quiver.localAngle[i],
                                data:[],
                                distance:[],
                                quiverAngle:[],
                            };
                            that.dataQuiver.push(_item)
                        }
                        _item.distance.push(quiver.locDis[i])
                        _item.quiverAngle.push(quiver.quiverAngle[i])
                        _item.data.push(quiver.quiverValue[i])
                    }

                    for(var i in back.localAngle){
                        let _item = that.dataQuiverBack.find(a=>a.angle == back.localAngle[i])
                        if(!_item){
                            _item = {
                                siteId:back.siteId,
                                period:back.period,
                                yaw:back.yaw,
                                dataTime:back.dataTime.replace("T"," "),
                                scanMode:scanType[back.scanType],
                                angle:back.localAngle[i],
                                data:[],
                                distance:[]
                            };
                            that.dataQuiverBack.push(_item)
                            
                        }
                        _item.data.push(back.quiverValue[i])
                        _item.distance.push(back.locDis[i])
                    }           
                    that.loadChart();
                }
            }, {immediate: true })
        },   
        load(){
            var that = this;
            this.overlay = true;
            let _scanType = this.$store.state.storage_global.currentSite.scanType == null || typeof(this.$store.state.storage_global.currentSite.scanType) == 'undefined'? "PPI":this.$store.state.storage_global.currentSite.scanType
            getVwlsQuiverApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                period:'latest',
                scanType:_scanType == "PPI"?0:_scanType == "FIX"?1:_scanType == "RHI"?2:_scanType == "Tri"?3:0,
                success:(d)=>{    
                    that.overlay = false;    
                    if(d[0][0].DwqSiteId!= that.$store.state.storage_global.currentSite.siteId){
                        return;
                    }            
                    that.dataQuiver = []
                    that.dataQuiverBack = []
                    let quiver = d[0][0];
                    let back = d[1][0];                    
                    for(var i in quiver.DwqLocAngle){
                        let _item = that.dataQuiver.find(a=>a.angle ==quiver.DwqLocAngle[i])
                        if(!_item){                            
                            _item = {
                                siteId:quiver.DwqSiteId,
                                period:quiver.DwqPeriod.split("_")[0],
                                yaw:quiver.DwqPeriod.split("_")[1],
                                dataTime:quiver.DwqTime.replace("T"," "),
                                scanMode:scanType[quiver.DwqScanMode],
                                angle:quiver.DwqLocAngle[i],
                                data:[],
                                distance:[],
                                quiverAngle:[],
                            };
                            that.dataQuiver.push(_item)
                        }
                        _item.distance.push(quiver.DwqLocDis[i])
                        _item.quiverAngle.push(quiver.DwqQuiverAngle[i])
                        _item.data.push(quiver.DwqQuiverValue[i])
                    }

                    for(var i in back.DwqbLocAngle){
                        let _item = that.dataQuiverBack.find(a=>a.angle == back.DwqbLocAngle[i])
                        if(!_item){
                            _item = {
                                siteId:back.DwqbSiteId,
                                period:back.DwqbPeriod.replace("_")[0],
                                yaw:back.DwqbPeriod.replace("_")[1],
                                dataTime:back.DwqbTime.replace("T"," "),
                                scanMode:scanType[back.DwqbScanMode],
                                angle:back.DwqbLocAngle[i],
                                data:[],
                                distance:[]
                            };
                            that.dataQuiverBack.push(_item)
                        }
                        _item.data.push(back.DwqbQuiverValue[i])
                        _item.distance.push(back.DwqbLocDis[i])
                    }    
           
                    that.loadChart();
                }
            })
        },
        loadChart(){   
            //处理数据      
            var that = this;  
            let target = this.$refs.chart;   
            if(!this.$refs.colorBar || !target){
                return;
            }         
            let d = 3.3      
            let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
            let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
            let _datas =this.dataQuiverBack;              
            if(_datas.length == 0)     {                   
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,  
                    uirevision:'true',                              
                    xaxis: {
                        scaleanchor:'y',
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,  
                        scaleratio:1    
                    },
                    yaxis: {                    
                        dtick:1,
                        title:"距离(km)",
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,                      
                    },
                    modebar:{
                        add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                        b:22
                    }
                };
                Plotly.newPlot(target, [       
                    {
                    name:'',
                    y: [],
                    x: [],
                    z:[],            
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[],
                    }                
                ], layout,{responsive: true,scrollZoom: true});
                return
            }
            let distanceData = [_datas[0].distance[0],_datas[0].distance[1] - _datas[0].distance[0],_datas[0].distance.length]
            let noDataLength =  (distanceData[0])/distanceData[1]  //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
            let hoverText = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => '')); 
            let w =  d * 30 *2;
              // //角度风抽样桶数量
              let therodsy = 10;
            
            //         let arrSampling2 = $help.sampling(i.data,therodsy)   
            //         debugger    
            let minSpanAngle = 0.025
            $help.drawScan(_datas,(obj)=>{                
                zData[obj.x][w-obj.y]=obj.value;
                hoverText[obj.x][w-obj.y]= `值：${obj.value.toFixed(6)}<br />角度：${(obj.angle).toFixed(2)}<br />距离：${(obj.magnitude/100).toFixed(4)}`
            },()=>{
                let quiverIconX = [],quiverIconY = [],customData = [];
                let lastAngle = 0;
                for(var i in that.dataQuiver) {                       
                    if(Math.abs(that.dataQuiver[i].angle -lastAngle) <= minSpanAngle || that.dataQuiver[i].distance.length < 3){
                        lastAngle = that.dataQuiver[i].angle
                        continue;
                    }
                    if(that.dataQuiver[i].data.length > 5){
                        let arrSampling = $help.sampling(that.dataQuiver[i].data,therodsy < that.dataQuiver[i].data.length? therodsy:that.dataQuiver[i].data.length)
                        for(let j in that.dataQuiver[i].distance){
                            if(arrSampling.indexOf(j) >= 0){
                                if(that.dataQuiver[i].data[j]!=0){
                                    quiverIconX.push(-1 *that.dataQuiver[i].distance[j] * Math.cos((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                    quiverIconY.push(that.dataQuiver[i].distance[j] * Math.sin((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                    customData.push({angle:that.dataQuiver[i].quiverAngle[j],speed:that.dataQuiver[i].data[j]/10})                             
                                }                    
                            }
                        }      
                    }else{
                        for(let j in that.dataQuiver[i].distance){
                            if(that.dataQuiver[i].data[j]!=0){
                                quiverIconX.push(-1 *that.dataQuiver[i].distance[j] * Math.cos((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                quiverIconY.push(that.dataQuiver[i].distance[j] * Math.sin((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                customData.push({angle:that.dataQuiver[i].quiverAngle[j],speed:that.dataQuiver[i].data[j]/10})                             
                            }                    
                        }      
                    }                     
                                           
                }
                var data = [                    
                    {
                    name:'',
                    y: yData,
                    x: xData,
                    z:zData,            
                    type: 'heatmap',           
                    showscale: false,
                    hoverinfo:'none',
                    hovertext:hoverText,
                    colorscale:that.$refs.colorBar.colorScaleValue,
                    zmin:that.$refs.colorBar.rangeInfo[0].min,
                    zmax:that.$refs.colorBar.rangeInfo[that.$refs.colorBar.rangeInfo.length-1].max
                    } ,
                    {
                        name:'',
                        type:'scatter',
                        mode: 'markers',
                        marker:{
                            size:0.001
                        },
                        hovertext:customData.map(a=>`风向：${a.angle < 0 ? a.angle + 360 : a.angle},风速：${a.speed.toFixed(2)}`),
                        x:quiverIconX,
                        y:quiverIconY,                 
                        customdata:customData,
                    // hoverinfo:'none'
                    },               
                ];                    
                let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black
                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor, 
                    uirevision:'true',                               
                    xaxis: {
                        title:'距离(km)',
                        labelalias:"0du",
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,    
                        scaleanchor:'y',
                        scaleratio:1  
                    },
                    yaxis: {
                        title:'距离(km)',
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,  
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                        b:42
                    }
                };
            
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
                }  
                quiver(target)  
                let rab = (target.layout.xaxis.range[1] - target.layout.xaxis.range[0]) 
                target.on('plotly_relayout', (a,b,c)=>{
                    //console.log(that.quiverbkScale)
                    if(a['xaxis.autorange']){
                        that.quiverbkScale = 1;
                    }
                    let rar = a['xaxis.range[1]'] - a['xaxis.range[0]']
                    if(rar){
                        let scale = rab/rar ? rab/rar:1;
                        scale = scale ? scale :1;
                        that.quiverbkScale = scale;
                        quiver(target,scale,a)
                    }else{
                        quiver(target,that.quiverbkScale,a )
                    }                
                })
            },noDataLength,scanLength,distanceData[2],{d:d})                        
        }, timerDraw(){  
            if(this.dataQuiverBack.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        },                                 
    }
}
</script>
<style scoped lang="scss">
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 64px;
    right: 20px;    
}.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
</style>
<template>
    <v-menu
        ref="menu"
        v-model="openMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="dateR"
        transition="scale-transition"
        offset-y
        max-width="340px"
        min-width="340px"
      >
        <template v-slot:activator="{ attrs ,on }">
           <div class="inputItem align-center d-flex justify-center">
                <div class="label">{{label}}</div>
                <v-input class="sinputBorder d-flex align-center justify-center pa-2 elevation-5" dense hide-details >
                    <input v-bind:value="dateText"
                        v-bind="attrs"
                        v-on="on"                        
                       :placeholder="placeholder"/>
                       <v-icon @click="commitDate()" v-show="clearable && dateText" color="primary">mdi-close-circle</v-icon>
                </v-input>
            </div>
        </template>
        <v-card >
            <v-date-picker
                v-if="openMenu"
                :multiple="multiple"
                :range="range"
                v-model="dateR"
                full-width
                @change="ok"
            >
            </v-date-picker>                                                             
        </v-card>        
      </v-menu>
</template>
<script>
export default {
    name:"SearchDatePicker",
    props:{
        placeholder:{
            type:String,
            default:""
        },
        label:{
            type:String,
            default:""
        },
        useSeconds:{
            type:Boolean,
            default:false
        },
        multiple:{
            type:Boolean,
            default:false
        },
        range:{
            type:Boolean,
            default:false
        },
        date:{
        },
        clearable:{
            type:Boolean,
            default:false
        }
    },
    data:()=>({
        openMenu:false,
        dateR:""
    }),
    mounted(){
        this.dateR = this.date
    },
    computed:{
        dateText(){
            if(typeof(this.dateR) == "object"){
                return this.dateR.join(" 至 ")
            }else{
                return this.dateR;
            }
        }
    },
    methods:{
        ok(){            
            if(this.dateR == ""){
                openNotice({
                    type:"error",
                    text:"请选择日期"
                })
                return
            }   
            if(this.range) {
                if(new Date(this.dateR[0]) > new Date(this.dateR[1])){
                    this.dateR = [this.dateR[1],this.dateR[0]]
                }
            }
            this.$emit('update:date', this.dateR)        
            this.$refs.menu.save(this.dateR)
        },
        commitDate(){
            this.dateR = this.range?[]: '';
            this.$emit('update:date',this.dateR)
        }
    }
}
</script>
<style scoped lang="scss">
.inputItem{
    margin: 10px;
}
.label{
    margin: 5px;
}
.sinputBorder{
    /* border:1px solid var(--v-white-darken4); */
    box-shadow:1px 0px 2px -1px  var(--v-white-darken4), 1px 1px 4px 0px  var(--v-white-darken4), 0px 1px 6px 0px  var(--v-white-darken4) !important;
    border-radius: 5px;    
}
:deep(.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections + .v-input__append-inner){
    width: 0 !important;

}
:deep(.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span,.v-time-picker-title__time .v-picker__title__btn),:deep(.v-time-picker-title__time span){
    height: 56px !important;
    font-size: 56px !important;
}
</style>
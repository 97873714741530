<template>
    <v-container class="contain width100 height100 ma-0 pa-0">   
        <v-card width="100%" height="100%" elevation="0">
            <v-card-actions class="mapToolbar mt-0 d-flex flex-nowrap">
                <v-row class="ma-0 pa-0 align-center justify-start">                 
                    <v-card elevation="0" class="my-0 mx-0 pa-0" style="min-width:360px;">
                        <SearchDateTimePickerPlus :maxRangeDay='3' range v-bind:datetime.sync="timeRange" />
                    </v-card>
                    <v-menu open-on-click offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                                                        
                            v-bind="attrs"
                            v-on="on"
                            class="ma-3"
                            >
                            {{currentTime.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="(item, index) in menuTime"
                                :key="index"
                                @click="setTime(item)"
                            >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>                          
                    </v-menu>  
                    <v-menu open-on-click offset-y bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                              
                            v-bind="attrs"
                            v-on="on"
                            class="ma-3"
                            >
                          {{currentViewMode.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentViewMode">
                                <v-list-item
                                    v-for="(item, index) in viewMode"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{item.text}}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>           
                </v-row>
            </v-card-actions>
            <v-card height="calc(100% - 100px)" style="top:100px">
                <v-tabs  show-arrows :value="currentShowType"  style="width: 100%;" align-with-title class="px-3 mt-1" center-active dark background-color="primary">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab @click="setShowChart(i)" v-show="getShowStatus(i)" v-for="i in showTypeList" :key="i.value">{{i.text}}</v-tab>
                </v-tabs>
                <v-row class="ma-0 pa-0 width100" style="height:calc(100% - 48px)">                    
                    <shear class="pt-0" v-if="currentShowType == 3" :timeRange="timeRange" :currentScanType="currentViewMode.value"></shear>   
                    <quiver class="pt-0"  v-if="currentShowType == 4" :timeRange="timeRange" :currentScanType="currentViewMode.value"></quiver>  
                    <profile class="pt-0" :currentView="currentShowType" v-if="currentShowType == 5 || currentShowType == 6 || currentShowType == 7|| currentShowType == 8" :timeRange="timeRange" :currentScanType="currentViewMode.value"></profile>                                   
                    <radial class="pt-0" :currentView="currentShowType" v-if="currentShowType == 0 || currentShowType == 1 || currentShowType == 2" :timeRange="timeRange" :currentScanType="currentViewMode.value"></radial>
                </v-row> 
            </v-card> 
        </v-card>     
    </v-container>
</template>
<script>

import {getLastestRawApi } from '@/api' 
import radial from '@/components/mobile/monitoring/wls/history/radial.vue'
import shear from '@/components/mobile/monitoring/wls/history/shear.vue'
import quiver from '@/components/mobile/monitoring/wls/history/quiver.vue'
import profile from '@/components/mobile/monitoring/wls/history/profile.vue'
export default {
    components:{radial,shear,quiver,profile},
    name:"monitoring.wls.history",
    data(){
        return {
            timeRange:[],
            //快捷
            menuTime:[
                {
                    text:this.$i18n.t('history.btn.btn05'),
                    value:'btn05'
                },
                {
                    text:this.$i18n.t('history.btn.btn1'),
                    value:'btn1'
                },
                {
                    text:this.$i18n.t('history.btn.btn05t'),
                    value:'btn05t'
                },
                {
                    text:this.$i18n.t('history.btn.btn24'),
                    value:'btn24'
                },                
                {
                    text:this.$i18n.t('history.btn.btn3t'),
                    value:'btn3t'
                }
            ],
            currentTime:{
                text:this.$i18n.t('history.btn.btn24'),
                value:'btn24'
            },
            currentViewMode:{
                text:"水平扫描",
                value:'PPI'
            }, 
            viewMode:[{
                text:"水平扫描",
                value:'PPI'
            },{
                text:"定点扫描",
                value:'FIX'
            },{
                text:"垂直扫描",
                value:'RHI'
            },{
                text:"三轴",
                value:'Tri'
            },{
                text:"四轴",
                value:'Qua'
            }],
            showTypeList:[{
                text:"径向风极地图",
                value:0,
                scanType:[0,2]
            },{
                text:"径向风时间热图",
                value:1,
                scanType:[0,1,2,3,4]
            },{
                text:"径向风折线图",
                value:2,
                scanType:[0,1,2,3,4]
            },{
                text:"风切变",
                value:3,
                scanType:[0,2]
            },{
                text:"矢量风",
                value:4,
                scanType:[0,2]
            },{
                text:"风廓线风羽图",
                value:5,
                scanType:[3,4]
            },{
                text:"风廓线垂直风速时间热图",
                value:6,
                scanType:[3,4]
            },{
                text:"风廓线水平风速时间热图",
                value:7,
                scanType:[3,4]
            },{
                text:"风廓线水平风向时间热图",
                value:8,
                scanType:[3,4]
            }],
            currentShowType:0,
            showShear:false
        }
    },
    watch:{   
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.load(true)
            }
        },       
        currentTime:{
            handler(n){
                let end = new Date();
                let start = new Date();        
                if(n.value == 'btn24'){
                    start.setTime(start.getTime() - 3600 * 1000 * 24); 
                }else if(n.value == 'btn1'){
                    start.setTime(start.getTime() - 3600 * 1000 * 1);
                }else if(n.value == 'btn05'){
                    start.setTime(start.getTime() - 3600 * 1000 * 0.5);
                }else if(n.value == 'btn05t'){    
                    start.setTime(start.getTime() - 3600 * 1000 * 12);
                }else if(n.value == 'btn3t'){
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                }
                this.timeRange = [start,end]                 
            }
        },
        timeRange(n){
            if(this.$store.state.storage_global.currentSite && n.length > 1){
                let start = n[0].pattern("yyyy-MM-dd HH:mm:ss") //
                let end = n[1].pattern("yyyy-MM-dd HH:mm:ss") //  
                //this.loadData(start,end,this.$store.state.storage_global.currentSite.siteId)        
            }            
        },
        profile(n){
            if(n){
                this.cols=[4,4,4]
            }else{
                this.cols=[5,7,0]
            }
        },
        currentViewMode(n){
            let tabs = this.showTypeList.filter(a=>this.getShowStatus(a));
            this.currentShowType = tabs[0].value;
            if(n.value == 'Tri'){
                this.profile = true;
            }else{
                this.profile = false;
            }
        }
    },
    mounted(){
        var that = this;                   
        this.$nextTick(()=>{
            that.load()
            that.showShear = !that.$store.state.storage_user?.user?.extendInfo?.wls?.shearHide
        })       
    },
    methods:{
        setTime(item){
            this.currentTime = Object.assign({},item);            
        },load(update){
            let that = this;            
            getLastestRawApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    siteType:this.$store.state.storage_global.currentSite.type
                },
                success:(d)=>{                                                       
                    if(d){          
                        if(d.ScanType == 0){
                            that.currentViewMode = that.viewMode[0]
                        }else if(d.ScanType == 1){
                            that.currentViewMode = that.viewMode[1]
                        }else if(d.ScanType == 2){
                            that.currentViewMode = that.viewMode[2]
                        }else if(d.ScanType == 3){
                            that.currentViewMode = that.viewMode[3]
                        }else if(d.ScanType == 4){
                            that.currentViewMode = that.viewMode[4]
                        }
                        if(!update){
                            that.timeRange =[new Date(new Date(d?.DateTime?.replace('T',' ')).setHours(new Date(d?.DateTime?.replace('T',' ')).getHours() -24)),new Date(d?.DateTime?.replace('T',' '))]
                        }                        
                    }else{                        
                        if(!update){
                            that.timeRange =[new Date(new Date().setHours(new Date().getHours() -24)),new Date()]   
                        }                        
                    }    
                    // that.currentTime = {
                    //     text:this.$i18n.t('history.btn.btn1'),
                    //     value:'btn1'
                    // }                
                }
            })            
        },
        getShowStatus(item){      
            if(item.value == 3 && !this.showShear) {
                return false
            }
            if(this.currentViewMode.value == "PPI") {
                if(item.scanType.indexOf(0) >= 0){
                    return true
                }                
            }
            else if(this.currentViewMode.value == "FIX" ) {
                if(item.scanType.indexOf(1) >= 0){
                    return true
                }     
            }
            else if(this.currentViewMode.value == "RHI" ) {
                if(item.scanType.indexOf(2) >= 0){
                    return true
                }     
            }
            else if(this.currentViewMode.value == "Tri") {
                if(item.scanType.indexOf(3) >= 0){
                    return true
                }     
            }
            else if(this.currentViewMode.value == "Qua") {
                if(item.scanType.indexOf(4) >= 0){
                    return true
                }     
            }    
            return false       
        },
        setShowChart(item){
            this.currentShowType = item.value    
        }
    }
    
}
</script>
<style scoped>
.contain{
    max-width: 100%;
}
.height50{
    height: 50%;
}
.mapToolbar{
    position: absolute;
    z-index: 3;
    width: 100%;
}
.rowHeight{
    height:calc(100% - 50px);
    top: 40px;
    position: relative;
}
.height33{
    height: 33.3%;
}
</style>
<template>
  <v-card height="100%" width="100%">
    <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
        <div ref="scanMap" class="map noevent">                            
        </div>                                     
    </v-card> 
</template>

<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
export default {
    name:'scanmap_pls',
    components:{monitorPointDataCard},
    props:{
       timeRange:{
        type:Array
       },
       currentWindow:{
        type:String
       }
    },
    data(){
        return {
            tab:0,
            map:null,
            mapEchart:null,
            mapContextMenuPositon:[],
            center:[],
            loadMapOver:false,
            attachMap:false,
            width:0,
            height:0,
            chartHeight:300,
            chartWidth:500,
            //点标记
            marker:null,
            siteMarker:null,
            //信息窗体
            winInfo:null,
            monitorPointMarkers:[],//国控点标记
            //历史扫描图  
            timmer:null,
            timeLoadImg:null,//防重复加载
            //按钮
            PPI_ScanTypes:[],//当前站点水平数据类型
       
            rows:10,
            total:0
         
        }        
    }, 
    beforeUnmount(){
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
    }, 
    computed:{
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        } 
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                var that = this;  
                        
                this.$nextTick(()=>{   
                    that.load();                                                                        
                })                        
            }
        },
    },
    mounted(){
        this.load()                         
    },
    methods:{
        load(t){                              
            var that = this;                     
            this.timeLoadImg = setTimeout(() => {  
                that.loadMap(); 
                if(that.timmer){
                    clearInterval(that.timmer)
                }                   
            }, 100); 
            
        },
        loadMap(){
            let target = this.$refs.scanMap;   
            if(!target){
                return
            }
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;        
            if(!this.map){
                this.map = mapEchart.getModel().getComponent('amap').getAMap();
                //添加右键菜单
                this.addContextMenu();
                //加载控件 
                this.addControl(); 
            }      
            this.map = mapEchart.getModel().getComponent('amap').getAMap();       
            var that = this;            
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-12, -16), // 相对于基点的偏移位置                                    
            }); 
            that.siteMarker.on('click',(e)=>{                                
                    new AMap.InfoWindow({
                        content: `
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${e.lnglat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>                     
                       `,                        
                    }).open(that.map,e.lnglat)
                })
            that.map.add(that.siteMarker)
            this.loadMapOver = true;
            //加载国控点
            this.loadMonitPoint()
        },
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //添加一个缩放控件
                // map.addControl(new BasicControl.Zoom({
                //     theme:"dark",
                //     position: 'lb'
                // }));
        
                //缩放控件，显示Zoom值
                // that.map.addControl(new BasicControl.Zoom({
                //     position: config.zoom.position,
                //     theme:"myself",
                //     showZoomNum: true
                // }));
                
                //比例尺
                
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '160px',left: '50px' },
                    theme:'myself'
                }));

                //3d指南针控件
                var controlBar = new AMap.ControlBar({
                    position:{bottom: '180px',left: '-70px' },
                    theme:'myself'
                });
                that.map.addControl(controlBar) 
                let el = that.map.getContainer();
                //地点定位                
                let styleP = 'cursor:pointer;bottom:50px;left:55px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]); 
            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        }, 
 }
}
</script>

<style lang="scss" scoped>
.map{
    width: 100%;
    height: 100%;
}

.weatherinfo{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 30px;
    bottom: 90px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 10px;
  padding: 3px 10px;
  border-radius: 5px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
.hotchart{
    position: relative;
    z-index: 1;
}

.mapToolbar{
    position: absolute;
    z-index: 1;
    width: 100%;    
}
//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 160px;
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

//自定义分组

.v_table_group_selfC{ 
  width:100%;
  display: flex;
}
.v_table_group_header_self{
  flex-grow: 1;
  width:100%;
  display: flex;
  flex-wrap: nowrap;
}
.v_group_span{
  display: flex;
  margin-right: 16px;
  align-items: center;
}
.v_table_group_header_btn{
  display: flex;
  align-items: center;
}
.listDateInfoItem{
  height: 100%;
  width:100%;    
  display: flex;
  align-items: center;
  justify-content:center;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
//比例尺
:deep(.amap-scalecontrol){
    left:50px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }



.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 

.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 80px;
}
</style>
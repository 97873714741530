<template>
  <div class="_main" @keydown="login($event.keyCode)">    
    <div class="headTitle">
        {{$i18n.t('login.title')}}
    </div>
    <v-dialog width="500" persistent  v-model="forgetPass.dialog">
        <v-card width="100%">
            <v-card-title
                class="headline"
                >
                忘记密码
            </v-card-title>
            <v-card-text class="pa-2 px-8">
                <v-form ref="form">
                    <v-row class="ma-0">
                        <v-select solo :items="options" v-model="forgetPass.type" disabled>
                        </v-select>
                    </v-row>
                    <v-row class="ma-0">
                        <v-text-field :rules="accountRules" v-model="forgetPass.account"  label="账号" placeholder="请输入您的账号">

                        </v-text-field>
                    </v-row>
                    <v-row class="ma-0" v-if="forgetPass.type == 0">
                        <v-text-field :rules="emailRules" v-model="forgetPass.input" label="邮箱" placeholder="请输入您预留的邮箱">

                        </v-text-field>
                    </v-row>
                    <v-row class="ma-0" v-if="forgetPass.type == 1">
                        <v-text-field  v-model="forgetPass.input" label="手机号" placeholder="请输入您预留的手机号">

                        </v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>            
            <v-card-actions class="justify-end">                
                <v-btn color="primary" @click="resetPass">重置密码</v-btn>
                <v-btn @click="forgetPass = {
                    dialog:false,
                    type:0,
                    account:'',
                    input:''
                };">
                    取消
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div class="loginBox" >
        <div class="form">
            <div class="form_title form_item">{{$i18n.t('login.form.title')}}</div>
            <div class="form_item">
                <div class="form_item_icon"><v-icon>mdi-account-circle</v-icon> </div>
                <input class="form_input" v-model="account" />
            </div>
            <div class="form_item">
                <div class="form_item_icon"><v-icon>mdi-lock</v-icon> </div>
                <input class="form_input" v-model="pwd" type="password" />
            </div>
            <div class="form_item">
                <v-btn class="login_btn" @click="login(13)">{{$i18n.t('login.form.loginBtn')}}</v-btn>
            </div>
            <div class="d-flex justify-end px-10">
                <v-btn text @click="forgetPass.dialog = true" class="blue--text text-decoration-underline">忘记密码</v-btn>
            </div>
        </div>
    </div>
    <!-- <div ref="qrcode" class="qrcode"></div> -->
    <div class="grey--text" style="position:absolute;right:120px;bottom:10px;font-family: monospace;">数据中心2.0<span class="mx-2"></span>{{version}}</div>
  </div>
</template>
<script>
  import QRCode from 'qrcodejs2'
  import sysconfig from '@/plugins/datas/sysconfig'
  import { loginApi,forgetPasswordApi } from '@/api' 
  export default {
    name: 'login',
    data(){
        return {            
            account:"",
            pwd:"",
            forgetPass:{
                dialog:false,
                type:0,
                account:"",
                input:""
            },
            options:[{
                text:"通过邮箱重置",
                value:0
            },{
                text:"通过短信重置",
                value:1
            }],
            accountRules:[v => !!v || '您必须填写账号',],
            emailRules:[
                v => !!v || '您必须填写邮箱',
                v => /.+@.+\..+/.test(v) || '邮箱格式不正确，请重新确认',
            ]
        }
    },
    computed:{
        version(){            
            return sysconfig.sysVersion;
        }      
    },
    mounted(){     
        // new QRCode(this.$refs.qrcode, {
        //     text: "http://dbcenter.gyquantum.com/helpergy/",
        //     width: 100,
        //     height: 100,
        //     colorDark : '#000000',
        //     colorLight : '#ffffff',
        //     correctLevel : QRCode.CorrectLevel.H
        // });    
        if($help.isMobile()) {            
            this.$router.push({name:"m.login"})            
        }        
        if(this.$store.state.storage_user.user.userName && localStorage.getItem("token")){
            if($help.isMobile()) {            
                this.$router.push({name:"m.home.index"})            
            }else{
                this.$router.push({name:"home.index"})
            }                    
        }
    },
    methods:{
      login(code){
        if(code == 13){
            let _acc = this.account.replace(/\s/g,"");
            let _pwd = this.pwd.replace(/\s/g,"");
            if(_acc==""){
              return
            }
            if(_pwd==""){               
                return;
            }
            let that = this; 
            localStorage.removeItem("checkLink") 
            loginApi({
                data:{
                    UserName:_acc,
                    Password:_pwd
                },
                success:(d)=>{                    
                    localStorage.setItem("token",d.token)  
                    localStorage.setItem("tokenTime",new Date().pattern("yyyy-MM-dd HH:mm:ss"))  
                    that.$store.dispatch('storage_user/getCurrentUser',function(){
                        that.$router.push({name:"home.index"})                        
                    });                                                               
                }
            })                              
        }        
      },
      resetPass(){
        var that = this;
        var rel = this.$refs.form.validate();
        if(rel){
            openDialog({
                title:"重置密码提示",
                content:"您的操作将不可逆，请再次确认是否重置密码",
                okBack:(d)=>{
                    forgetPasswordApi({
                        noAuth:true,
                        account:that.forgetPass.account,
                        params:{
                            input:that.forgetPass.input,
                            type:that.forgetPass.type
                        },
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:'success',
                                    text:"重置成功，您的新密码已发送至邮箱"
                                })
                                that.forgetPass.dialog = false;

                            }
                        }
                    })         
                },//确认回调
                noBack:()=>{
                    that.forgetPass = {
                        dialog:false,
                        type:0,
                        account:'',
                        input:''
                    };
                } //取消回调
            })
        }
      }      
    }
}
</script>
<style lang="scss" scoped>
._main{
  width: 100%;
  height: 100%;
  position: relative;
  background: url('~@/assets/img/bg.png');
}
.headTitle{
    padding: 20vh 0 8vh;
    text-align: center;
    font-size: 44px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 8px;
    }
.loginBox{
    width: 100%;
    display: flex;
    justify-content: center;
}
.form{
    width: 480px;
    background: url('~@/assets/img/loginbox.png');
    background-size: 100% 100%;
    padding: 40px 0;
    }
.form_title{
    font-size: 34px;
    text-align: center;
    color: #fff;
}
.form_item{
    margin: 24px;
    padding:0 40px;
    position: relative;    
    }
.form_input{
    width: 100%;
    height: 60px;
    padding: 15px 10px 15px 45px;
    font-size: 18px;
    color: #fff;
    background: hsla(0,0%,100%,.1);
    border: 1px solid #239fdd;
    border-radius: 5px;
    outline: none;
}
.form_item_icon i{
    position:absolute;
    height: 100%;
    width: 52px;
    font-size: 2rem;
    color: white !important;
    text-align: center;
    }
.login_btn{
    width: 100%;
    height: 60px !important;
    margin: 10px 0;
    font-size: 26px !important;
    color: #fff !important;
    background-image: linear-gradient( 180deg,#0096ff,#0060ff);
    }
.qrcode{
    position: absolute;  
    right:132px;
    bottom: 35px; 
    padding: 20px;
    background: url('~@/assets/img/loginbox.png');            
    background-size: 100% 100%; 
}
</style>
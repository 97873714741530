<template>
<v-menu v-model="show" open-on-click offset-y bottom nudge-top="45" max-height="400" z-index="1000000" :close-on-content-click='false'>
    <template v-slot:activator="{ on, attrs }">
        <v-text-field
        class="siteSelectInput"
        :rules='[v => v.length > 0 || "请选择站点"]'
        :label="label"
        :value="textValue"
        :required="required"   
        append-icon="mdi-menu-down"                                                                                                                      
        v-bind="attrs"
        v-on="on"
        >            
        </v-text-field>
        </template>
         <v-card>
            <v-sheet class="pa-4 primary">
                <v-text-field
                    v-show="searchBtn"
                    v-model="search"
                    label="搜索"
                    dark
                    flat
                    solo-inverted
                    hide-details
                    dense
                    clearable
                    clear-icon="mdi-close-circle-outline"
                ></v-text-field>
                <v-row class="d-flex ma-0 pa-0">
                <v-checkbox
                    class="mx-1"
                    v-show="checkAllBtn"
                    dark
                    @click="selectAllSite"
                    :input-value="checkedAll"
                    dense
                    hide-details
                    label="全选"
                ></v-checkbox>                       
                <v-checkbox
                    class="mx-1"
                    v-show="showCityLevel"
                    dark                    
                    v-model="cityLevel"
                    dense
                    hide-details
                    label="城市分组"
                ></v-checkbox>    
                <v-checkbox
                     class="mx-1"
                    v-show="online"
                    dark                    
                    v-model="onlyOnline"
                    dense
                    hide-details
                    label="只看启用"
                ></v-checkbox>    
                </v-row>           
            </v-sheet>
            <v-treeview         
                selectable       
                item-text="siteName"
                item-key="siteId"
                multiple-active
                open-all
                dense
                selected-color="primary"
                :items="sites"
                :search="search"
                :filter="filter"
                v-model="selectSites"
                return-object
            >
            </v-treeview>
        </v-card> 
    </v-menu>
</template>

<script>
export default {
    name:"SitesSelect",
    props:{
        rule:{
            type:Array,
            default:()=>{
                return new Array()
            }
        },
        required:{
            type:Boolean,
            default:false
        },
        clearable:{
            type:Boolean,
            default:false
        },  
        noDataText:{
            type:String,
            default:""
        },
        label:{
            type:String,
            default:""
        },
        multiple:{
            type:Boolean,
            default:false
        }, 
        placeholder:{
            type:String,
            default:""
        },  
        value:{            
            default:""
        },  
        change:{
            type:Function
        },  
        searchBtn:{
            type:Boolean,
            default:false
        },
        checkAllBtn:{
            type:Boolean,
            default:false
        },
        checkAllLimitBtn:{
            type:Boolean,
            default:false
        } ,
        online:{
            type:Boolean,
            default:true
        },
        showCityLevel:{
            type:Boolean,
            default:false
        }             
    },     
    data(){
        return{
            selectSites:[],
            search:"",
            show:false,  
            timelock:null,
            onlyOnline:true,
            lockSelectSites:false,
            cityLevel:false           
        }
    },
    watch:{
        value(n){        
           let selected = this.selectSites.map(a=>a.siteId);
           let selectSites = [];
            this.sites.forEach(a=>{
                selectSites = selectSites.concat(a.children)
            })       
           let _values = this.value.indexOf('all') >= 0 ? selectSites.map(a=>a.siteId).toString() : this.value.toString();           
            if(selected.toString()!= _values){                
                this.lockSelectSites = true;
                this.setSite(n)     
            }    
        },
        selectSites(n){
            if(!this.lockSelectSites){
                let selectSites = [];
                this.sites.forEach(a=>{
                    selectSites = selectSites.concat(a.children)
                })                                    
                if(this.checkAllLimitBtn && n.length == selectSites.length){
                    this.$emit("update:value",['all'])
                }else{
                    let e = n.map(a=>{
                        return a.siteId
                    })
                    this.$emit("update:value",e)
                    if(this.change){
                        this.change(e)
                    }
                }                        
            }
        }
    },
    computed:{
        sites(){           
            let allsites = this.$store.state.storage_user.sites;
            if(this.onlyOnline){
                allsites = allsites.filter(a=>a.transStatus == 0)
            }else{
                allsites = this.$store.state.storage_user.sites;
            }
            var _siteList = $help.groupBy("province",allsites);            
            var siteList = [];
            for(var i in _siteList){
                var _siteListCity = $help.groupBy("city",_siteList[i]);                
                if(this.cityLevel){                    
                    let cityList = []
                    for(var j in _siteListCity){
                        cityList.push({
                            siteId:j,
                            siteName:j,
                            children:_siteListCity[j]
                        })
                    }
                    siteList.push({
                        siteId:i,
                        siteName:i,
                        children:cityList
                    })
                }else{
                    siteList.push(
                        {
                            siteId:i,
                            siteName:i,
                            children:_siteList[i]
                        }
                    )
                }                
            }                   
            return siteList;
        },
        siteNames(){
            return this.selectSites.map(a=>a.siteName)
        },
        checkedAll(){
            let selectSites = [];
            this.sites.forEach(a=>{
                selectSites = selectSites.concat(a.children)
            })
            return selectSites.length == this.value.length || this.value.indexOf('all')>=0
        },         
        filter () {
            return (item, search, textKey) => {
                if(this.onlyOnline){
                    return item?.transStatus == 0 && item[textKey].indexOf(search) > -1
                }
                return item[textKey].indexOf(search) > -1
            }
        },
        icon(){
            if (this.selectSites.length == this.sites.length) return 'mdi-close-box'
            if (this.selectSites.length != this.sites.length && this.selectSites.length >0) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        textValue(){            
            return this.selectSites.map(a=>a.siteName).join(",")
        }
    },
    mounted(){       
        if(this.value.length > 0){
            this.lockSelectSites = true;
            this.setSite(this.value)
        }        
    },
    methods:{
        setSite(n){
            var that = this;               
            if(this.timelock){
                clearTimeout(this.timelock)
            }
            this.timelock = setTimeout(() => {                            
                let selectSites = [];
                that.sites.forEach(a=>{
                    selectSites = selectSites.concat(a.children)
                })      
                if(n == 'all')  {
                    that.selectSites = selectSites;    
                }else{
                    that.selectSites =selectSites.filter(a=>{
                        return n.indexOf(a.siteId) >=0
                    })
                }   
                that.lockSelectSites = false;               
            }, 200);      
        },
         selectAllSite(){
            let selectSites = [];
            this.sites.forEach(a=>{
                selectSites = selectSites.concat(a.children)
            })
            if(this.selectSites.length == selectSites.length){
                this.selectSites= []
            }else{                
                this.selectSites = selectSites;
            }
        }
        

    }
}
</script>


<style scoped lang="scss">
.siteSelectInput{
    :deep(input)
    {
        text-overflow: ellipsis !important;    
    }    
}
</style>
import { render, staticRenderFns } from "./realtime.vue?vue&type=template&id=15d08466&scoped=true"
import script from "./realtime.vue?vue&type=script&lang=js"
export * from "./realtime.vue?vue&type=script&lang=js"
import style0 from "./realtime.vue?vue&type=style&index=0&id=15d08466&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d08466",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})

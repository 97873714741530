import { getField, updateField } from 'vuex-map-fields'

const state ={        
    widthHeight:'0,0',  
    agtId:"",
    loadKey:"",
    externalDatas:[],  
    lidarDatas:[]
}

  const getters = {
    getField, 
}

const mutations = {
    updateField, 
    updatewidthHeight(state,d){
        state.widthHeight = d;
    },
    updateAgtId(state,d){
        state.agtId = d;
    },
    updateLoadKey(state,d){
        state.loadKey = d;
    },
    updateExternalDataInfo(state,data){        
        state.externalDatas.unshift(data)
        state.externalDatas = state.externalDatas.slice(0,5)
    },
    updateLidarDataInfo(state,data){
        state.lidarDatas.unshift(data)
        state.lidarDatas = state.lidarDatas.slice(0,5)
    },
    updateExternalDatas(state,dataInfo){               
        state.externalDatas.forEach(a=>{
            if(a.siteId == dataInfo.siteId && a.agtId == dataInfo.agtId && a.key == dataInfo.key){                
                a.datas = dataInfo.datas                
            }
        })
        state.loadKey = dataInfo.key;
    },
    updateLidarDatas(state,dataInfo){
        state.lidarDatas.forEach(a=>{
            if(a.siteId == dataInfo.siteId && a.agtId == dataInfo.siteId && a.key == dataInfo.key){
                a.datas = dataInfo.datas                
            }
        })
    },
    removeExternalDataInfo(state,dataInfo){
        state.externalDatas = state.externalDatas.filter(a=>{
            return a.siteId != dataInfo.siteId && a.agtId != dataInfo.agtId && a.key != dataInfo.key
        })
    }
   
}

const actions = {     
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
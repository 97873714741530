<template>
    <v-container
        class="py-2 d-flex flex-column"
        fluid      
      >
      <v-row class="d-flex align-center flex-grow-0" dense >
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('monitorPointManage.label.name')"
                    :placeholder="$i18n.t('monitorPointManage.placeholder.name')"
                    v-bind:value.sync="name"
                />
                <!-- <SearchDatePicker 
                    :range="true"
                    :label="$i18n.t('monitorPointManage.label.editDateRange')"
                    :placeholder="$i18n.t('monitorPointManage.placeholder.editDateRange')"
                    v-bind:date.sync="editDateRange"                    
                /> -->
                <SelectInput             
                    :label="$i18n.t('monitorPointManage.label.province')"
                    :options="provinceList"  
                    :placeholder="$i18n.t('monitorPointManage.placeholder.province')" 
                    :width='200'
                    v-bind:value.sync="province"
                    clearable
                    />         
                <SelectInput clearable v-bind:value.sync="city" :label="$i18n.t('monitorPointManage.label.city')" :options="cityList" :placeholder="$i18n.t('monitorPointManage.placeholder.city')" :width='198' /> 
                <v-btn class="mx-2"  @click="page=1;load()">{{$i18n.t('monitorPointManage.btn.search')}}</v-btn>
                <v-btn
                color="primary"                        
                class="mx-2"                
                @click="newForm"
                >{{$i18n.t('monitorPointManage.btn.new')}}</v-btn>
                <input  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadFile('load')" ref="nationMonitor_upload" type="file" hidden />
                <!-- <v-btn
                color="secondary"                        
                class="mr-2 mx-2"   
                @click="uploadFile('up')"                     
                >{{$i18n.t('monitorPointManage.btn.upload')}}</v-btn>  
                <v-btn
                color="accent"                        
                class="mr-2 mx-2"   
                @click="getTemplate()"                     
                >{{$i18n.t('monitorPointManage.btn.download')}}</v-btn>   -->
            </v-row>
        </v-col>                   
        <!-- <v-col class="ma-0 d-flex align-end height100" cols="0" >  
                              
        </v-col>               -->
      </v-row>       
      <v-row class="tableContainer ma-0">
          <v-data-table   
            height="100%"
            :items="datas"         
            fixed-header
            :headers="headers"
            :page.sync="page"
            :items-per-page="rows"
            :loading="loadingData"
            hide-default-footer
          >
          <template v-slot:top>   
            <v-toolbar :elevation="0" height="0">     
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        
                    </template>
                <v-card>
                    <v-toolbar color="primary">
                        <v-btn icon dark @click="dialog= false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title style="color:white">编辑地图标记</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-toolbar-items>
                            <v-btn dark text @click="save">{{$i18n.t('monitorPointManage.btn.save')}}</v-btn>
                        </v-toolbar-items> -->
                    </v-toolbar>
                    <v-card-text>                      
                        <v-row justify="center" class="ma-5">
                            <v-card class="pa-6 dialogContent" >
                                <v-form
                                class="ma-6"
                                ref="form"
                                v-model="form.valid"                            
                                >
                                    <input hidden v-model="form.body.MiId" />
                                    <v-row>
                                       <v-row>
                                            <v-col>
                                                 <v-text-field
                                                    v-model="form.body.MiName"                                
                                                    :label="$i18n.t('monitorPointManage.label.form.name')"
                                                    required
                                                    :rules="rules.name"
                                                ></v-text-field>  
                                            </v-col>                                            
                                            <v-col>
                                                <v-select
                                                v-model="form.body.MiType"
                                                :rules="rules.type"
                                                :items="types"                                
                                                :label="$i18n.t('reportInfo.label.form.type')"                                                
                                                required
                                            ></v-select> 
                                            </v-col>
                                       </v-row>
                                    </v-row>                                   
                                    <v-row>
                                        <v-col>
                                            <SitesSelect 
                                                ref="siteSelect"
                                                searchBtn
                                                checkAllBtn
                                                :rules="rules.siteId"
                                                :value.sync="form.body.MiSiteId"                               
                                                :label="$i18n.t('reportInfo.label.form.site')"
                                                multiple
                                                required/> 
                                        </v-col>                                                        
                                    </v-row> 
                                    <v-row>
                                        <v-col class="d-flex align-end">
                                            <v-combobox                                                
                                                :rules="rules.type"
                                                :items="logoList"                                
                                                label="图标"                                                
                                                required
                                                v-model="form.body.MiIcon"
                                            >
                                                <template v-slot:selection="{item}">                                                
                                                    <div v-html="getSelected(item)"></div>
                                                </template>
                                                <template v-slot:item="{item}">                                                
                                                    <div v-html="item"></div>
                                                </template>
                                            </v-combobox> 
                                            <v-color-picker
                                                dot-size="25"
                                                v-model="iconColor"
                                                hide-inputs
                                                hide-mode-switch
                                                swatches-max-height="200"
                                                ></v-color-picker>
                                        </v-col>  
                                    </v-row>   
                                    <v-row>
                                        <v-card width="100%">                                           
                                            <v-card-title>
                                                <h4  class="ma-2 pa-2">地图标记卡片显示信息 </h4>
                                            </v-card-title>
                                            <v-row class="ma-0 pa-2" v-for="(item,k) in extendJson" :key="k">
                                                <v-col>
                                                    <v-text-field dense label="名称" v-model="item.name">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field dense label="值" v-model="item.value">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-btn color="warning" @click="extendJson = extendJson.filter((a,i)=>i != k)" dense>删除</v-btn>
                                                </v-col>                                                                                                
                                            </v-row>
                                            <v-card-actions class=" d-flex justify-end">
                                                <v-btn @click="extendJson.push({name:'',value:''})">增加</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-row>
                                    <v-row class="flex-column">
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    @change="changed = true"
                                                    v-model="form.body.MiLng"
                                                    type="number" 
                                                    step="0.000001"
                                                    :rules="rules.lng"                                              
                                                    :label="$i18n.t('monitorPointManage.label.form.lng')"
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    v-model="form.body.MiLat"
                                                    @change="changed = true"
                                                    type="number" 
                                                    step="0.000001"          
                                                    :rules="rules.lat"                                                                                  
                                                    :label="$i18n.t('monitorPointManage.label.form.lat')"
                                                    required
                                                ></v-text-field>
                                            </v-col>    
                                        </v-row>
                                        <v-row>
                                            <LocationSelector  
                                                v-bind:position.sync="position"
                                                v-bind:province.sync="form.body.MiProvince"
                                                v-bind:city.sync="form.body.MiCity"
                                                v-bind:district.sync="form.body.MiDistrict"
                                                v-bind:lng.sync="form.body.MiLng" 
                                                v-bind:lat.sync="form.body.MiLat"  
                                                v-bind:township="form.body.MiStreet"
                                                class="mapSelect"/>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    v-model="form.body.MiPosition"                                                                                                                                                                                                                                           
                                                    :label="$i18n.t('monitorPointManage.label.form.position')"
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-row>                                                                                                                                                                 
                                </v-form>
                            </v-card>                           
                        </v-row>
                    </v-card-text> 
                    <v-card-actions class="bottomAction d-flex justify-end"> 
                        <v-btn class="mr-6" color="primary" @click="save">{{$i18n.t('monitorPointManage.btn.save')}}</v-btn>
                        <v-btn class="mr-6" @click="dialog=false">{{$i18n.t('monitorPointManage.btn.cancel')}}</v-btn>
                    </v-card-actions>                                     
                </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.MiIcon="{ item }">
            <div v-html="item.MiIcon"></div>            
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="edit(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="del(item)"
            >
                mdi-delete
            </v-icon>
        </template>

          </v-data-table>          
      </v-row>      
      <v-row justify="center" align="center" class="ma-0 flex-grow-0" >
          <v-pagination
            v-model="page"
            @input="load"
            :length="length"
            circle
            :total-visible="8"
            ></v-pagination>
            {{$i18n.t("monitorPointManage.pageTotal",[total])}}
      </v-row>
      </v-container>
</template>
<script>
import cityJson from '@/plugins/datas/cityJson';
import { getMarkInfoListApi,addMarkInfoApi,editMarkInfoApi,deleteMarkInfoApi} from '@/api'
import LocationSelector from '@/components/map/LocationSelector.vue'
import * as XLSX from 'xlsx/xlsx.mjs';
import excelHeader from '@/plugins/datas/excelHeader';
import baseType from '@/plugins/datas/baseType'
import customMarkIconList from '@/plugins/datas/customMarkIconList'
export default {
    name:"monitorPointManage.index",
    components:{LocationSelector},
    data(){
        return{
            datas:[],
            page:1,
            rows:10,
            total:0,
            loadingData:false,
            name:"",
            province:"",
            city:"",
            icon:'',
            iconColor:"#000000FF",
            editDateRange:[],
            provinceList:cityJson.map(a=>{
                return {
                    value:a.text,
                    text:a.text
                }
            }),
            cityList:[],                   
            headers:[
                {
                    text:this.$i18n.t('monitorPointManage.table.header.name'),
                    value: 'MiName',      
                    width:'24%'      
                },                
                {
                    text:this.$i18n.t('monitorPointManage.table.header.province'),
                    value: 'MiProvince',
                },  
                {
                    text:this.$i18n.t('monitorPointManage.table.header.city'),
                    value: 'MiCity',
                }, 
                {
                    text:this.$i18n.t('monitorPointManage.table.header.position'),
                    value: 'MiPosition',
                },
                {
                    text:this.$i18n.t('monitorPointManage.table.header.lng'),
                    value: 'MiLng',
                },
                {
                    text:this.$i18n.t('monitorPointManage.table.header.lat'),
                    value: 'MiLat',
                },
                {
                    text:this.$i18n.t('reportInfo.table.header.type'),
                    value: 'type',
                }, 
                {
                    text:'图标',
                    value: 'MiIcon',
                }, 
                {
                    text:this.$i18n.t('monitorPointManage.table.header.editDate'),
                    value: 'MiModifyTime',
                },                   
                {
                    text:this.$i18n.t('monitorPointManage.table.header.operation'),  
                    value: 'actions', sortable: false              
                },
            ],
            types:[],            
            dialog:false,
            rules:{
                siteId: [
                        v => v.length > 0 || this.$i18n.t("reportInfo.label.rule.siteid")
                    ],
                name: [
                    v => !!v || this.$i18n.t("monitorPointManage.label.rule.name"),
                    v =>(v && v.length <= 64)|| this.$i18n.t("monitorPointManage.label.rule.nameLength"),
                    // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],            
                lng:[v => !!v || this.$i18n.t("monitorPointManage.label.rule.lng")],
                lat:[v => !!v || this.$i18n.t("monitorPointManage.label.rule.lat")],                      
                pointNo:[
                    v =>(!v || v.length <= 40)|| this.$i18n.t("monitorPointManage.label.rule.pointNo")
                ],
                descLength:[
                    v =>(!v || v.length <= 255)|| this.$i18n.t("monitorPointManage.label.rule.pointNo")
                ],
                type: [
                    v => !!v || '请选择图标',
                    // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],                  
            },
            position:"",
            changed:false,
            form:{
                valid:false,
                title:"",
                body:{
                    MiId:"",
                    MiName:"",
                    MiPosition:"",
                    MiSiteId:"",                    
                    MiLng:"",
                    MiLat:"",
                    MiProvince:"",
                    MiCity:"",
                    MiDistrict:"",
                    MiStreet:"",
                    MiType:0,
                    MiExtend:'',
                    MiIcon:''                               
                }
            },
            extendJson:[
                {
                    name:"面积/行业",
                    value:""
                },
                {
                    name:"现状",
                    value:""
                }
            ],
            logo:[]         
        }
    },    
    watch:{ 
        province(n,v){            
            this.city = ""
            if(!n){
                 this.cityList = [];                 
            }else{                
                this.cityList = cityJson.find(a=>a.text == n).children.map(a=>{
                    return {
                        text:a.text,
                        value:a.text
                    }
                })
            }
        },position(n){           
            this.form.body.MiPosition = n;
        }
    },
    computed:{ 
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        }, 
        logoList(){
            return customMarkIconList.map(a=>(a.path))
        }         
    },
    mounted(){
        this.getType();     
        this.load()
    },
    methods:{
        getSelected(text){
            let regexp = /#[a-f0-9]{8}/gi;
            return text.replace(text.match(regexp)[0],this.iconColor) 
        },
        getType(){
            var that = this;
            this.$store.dispatch('storage_type/getDicMenu',{
                typeid:baseType.markType,
                callback:(d)=>{
                    that.types = d.map(a=>{
                        return {
                            text:a.BdValue,
                            value:a.BdCode
                        }
                    })
                    that.load()
                }
            })  
        },
        load(){
            var that =this;
            this.loadingData = true;
            getMarkInfoListApi({
                params:{
                    Province:this.province,
                    City:this.city,
                    Name:this.name,
                    PageIndex:this.page,
                    PageSize:this.rows,
                },
                success:(d)=>{
                    that.loadingData = false;
                    that.datas = d.Data.map(a=>{
                        a.MiModifyTime =new Date(a.MiModifyTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss')
                        a.type = that.types.find(b=>b.value == a.MiType).text
                        return a
                    });
                    that.total = d.Total;
                }
           })
        },
        edit(item){     
            let regexp = /#[a-f0-9]{8}/gi;    
            this.changed = false;
            this.form.body.MiLng=item.MiLng;
            this.form.body.MiLat=item.MiLat;        
            this.form.body.MiId = item.MiId;            
            this.form.body.MiName = item.MiName;
            this.form.body.MiPosition = item.MiPosition;
            this.form.body.MiProvince = item.MiProvince;
            this.form.body.MiCity = item.MiCity;            
            this.form.body.MiSiteId=item.MiSiteId;                                
            this.form.body.MiIcon = item.MiIcon.replace(item.MiIcon.match(regexp)[0],"#000000FF");
            this.iconColor=item.MiIcon.match(regexp)[0]
            this.form.body.MiExtend = item.MiExtend;
            this.form.body.MiType = item.MiType;  
            this.extendJson = item.MiExtend ? JSON.parse(item.MiExtend) : this.extendJson         
            this.dialog = true;
        },
        del(item){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:()=>{
                    deleteMarkInfoApi({
                        id:item.MiId,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }                            
                        }
                    })
                },//确认回调
                noBack:null //取消回调
            })
        },        
        newForm(){
            this.form.body={
                MiId:"",
                MiName:"",
                MiPosition:"",
                MiSiteId:"",                    
                MiLng:"",
                MiLat:"",
                MiProvince:"",
                MiCity:"",
                MiDistrict:"",
                MiStreet:"",
                MiType:0,
                MiExtend:'',
                MiIcon:''       
            };     
            this.extendJson = [
                {
                    name:"面积/行业",
                    value:""
                },
                {
                    name:"现状",
                    value:""
                }
            ]     
            if(this.$refs.form){
                this.$refs.form.resetValidation() 
            }
            this.dialog = true;
        },      
        save(){
            let regexp = /#[a-f0-9]{8}/gi;   
            var rel = this.$refs.form.validate();
            var that = this;                        
            this.form.body.MiLng = Number(this.form.body.MiLng).toFixed(6)
            this.form.body.MiLat = Number(this.form.body.MiLat).toFixed(6)  
            this.form.body.MiIcon = this.form.body.MiIcon.replace(this.form.body.MiIcon.match(regexp)[0],this.iconColor)            
            this.form.body.MiExtend = JSON.stringify(this.extendJson)
            if(rel){                
                //修改                
                if(this.form.body.MiId){                    
                    editMarkInfoApi({
                        id:this.form.body.MiId,
                        data:{
                            ...this.form.body
                        },
                        success:(d)=>{
                            that.load()
                            that.dialog = false;                            
                        }
                    })
                }
                //z增加
                else{
                    addMarkInfoApi({
                        data:{...that.form.body},
                        success:(d)=>{
                            that.load()
                            that.dialog = false;                            
                        }                        
                    })
                }
            }     
        },
        uploadFile(type){
            if(type == 'up'){
                this.$refs.nationMonitor_upload.click()
            }
            if(type == 'load'){
            let that = this;
            let file = this.$refs.nationMonitor_upload.files[0]
            File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
            Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;

            function myArrayBuffer() {
                // this: File or Blob
                return new Promise((resolve) => {
                let fr = new FileReader();
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.readAsArrayBuffer(this);
                })
            }
            file.arrayBuffer().then((d)=>{                
                let workbook = XLSX.read(d);
                let json = XLSX.utils.sheet_to_json(workbook.Sheets["国控点"],{header:1});
                if(json.length == 0){
                    openNotice({
                        type:'error',
                        text:"请检查左下角工作表标签栏是否具有‘国控点’标签"
                    })
                    return;
                }
                let header = ['序号', '名称', '控属性质（国控/省控/市控/其他）', '站点编号', '省', '市', '区', '街道', '经度', '纬度', '点位详细地址', '备注1', '备注2'];
                if(json[0].length != 14){
                    let noHead = [];
                    for(var i of header){
                        if(json[0].indexOf(i) <0){
                            noHead.push(i)
                        }
                    }
                    if(noHead.length > 0){            
                        openNotice({
                            type:'error',
                            text:`请检查表头,未包含表头${noHead.join(",")}`
                        })
                        that.$refs.nationMonitor_upload.value = ''
                        return;
                    }
                    
                }
                let dataJson = XLSX.utils.sheet_to_json(workbook.Sheets["国控点"]);                
                let lngLats =dataJson.map(a=>{
                    return [a['经度'],a['纬度']]
                }).sort();
                let repeatLnglats = [];
                lngLats.forEach((a,i)=>{
                    if(i != 0){
                        if((a[0] == lngLats[i-1][0]) &&(a[1] == lngLats[i-1][1]) ){
                            repeatLnglats.push(a)
                        }
                    }
                })
                if(repeatLnglats.length > 0){
                    var arr = help.unique(repeatLnglats)
                    openNotice({
                        type:'error',
                        text:`一下经纬度信息重复，请检查${arr.join(",")}`
                    })
                    that.$refs.nationMonitor_upload.value = ''
                    return;
                }
                let _data = [];                
                for(var i of dataJson){
                    let obj = {}
                    for(var j of header){
                        let key = excelHeader[j]
                        obj[key] = (typeof(i[j]) == 'undefined' || typeof(i[j]) ==  'NaN' )? "" :i[j].toString()
                    }
                    _data.push(obj)
                }  
                      
                addMonitorPointsApi({
                    data:_data,
                    success:(d)=>{
                        that.load()
                        that.dialog = false;  
                        openNotice({
                            type:'success',
                            text:`添加成功`
                        })                          
                    }                        
                })              
                });            
                /* data is an ArrayBuffer */
                
            }   
        },
        getTemplate(){
            let data = [{
                '序号':'', 
                '名称':"", 
                '控属性质（国控/省控/市控/其他）':'',
                '站点编号':"", 
                '省':'', 
                '市':'',
                '区':'',
                '街道':'',
                '经度':'',
                '纬度':"",
                '点位详细地址':'',
                '备注1':'',
                '备注2':''
            }]            
            var worksheet = XLSX.utils.json_to_sheet(data, { bookType:"xlsx", bookSST:false, type:"array" });            
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "国控点");
            XLSX.writeFile(workbook, "国控点模板.xlsx");   
        }
    }
    
}
</script>
<style scoped lang="scss">
.v-data-table{
    width: 100%;
    height: 100%;
}
.dialogContent{
    overflow: auto;
}
.permissionItem{
    width: 100%;
}
.mapSelect{
    width: 100%;
    min-height: 280px;
}

</style>
import { getField, updateField } from 'vuex-map-fields'
import {getUserInfoApi,getExternalDevicesApi,getLastestRawApi} from '@/api'
import baseType from '@/plugins/datas/baseType'
import scanType from '@/plugins/datas/scanType'
import siteTypeWithRoute from '@/plugins/datas/siteTypeWithRoute'
import help from '@/plugins/helper/help'
import Vue from 'vue'
import alarmFilterRuleType from '@/plugins/datas/alarmFilterRuleType'


const state ={        
  user:{},
  role:{},
  sites:[],
  siteTypes:[],  
  devices:[]
}

const getters = {
    getField, 
}

const mutations = {
    updateField, 
    updateUser(state,data){
        state.user = data;
    },
    updateRole(state,data){
        state.role = data;
    },
     //更新站点
    updateSites(state,data){
        state.sites = data;        
    },
    //更新雷达类型
    updateSiteTypes(state,data){
        state.siteTypes = data;
    },
    updateAvator(state,data){
        state.user.extendInfo.avator = data;
    },
    updateDevice(state,data){
        state.devices = data;
    },
    updateDeviceData(state,data){
        for(var i of state.devices){
            if(i.id == data.id){
                if(data.value.length > 0){
                    i.average = Number((help.sum(data.value) / data.value.length).toFixed(2))
                }              
            }
        } 
    },
    //根据id更新站点状态
    updateSiteStatus(state,data){
        for(var i of state.sites){
            if(i.siteId == data.siteId){
                i.commitStatus = data.commitStatus;
                i.transStatus = data.transStatus ? data.transStatus:i.transStatus;
            }
        }
    },
    updateSiteWeather(state,data){
        for(var i of state.sites){
            if(i.siteId == data.siteId){
                i.weatherInfo = data.weatherInfo;
                i.weatherUpdateTime = new Date();
            }
        }
    },
    updateSiteScanType(state,data){        
        for(var i of state.sites){
            if(i.siteId == data.siteId){
                Vue.set(i,'scanType',data.scanType) 
            }
        }
        if(data.cb){
            data.cb(data)
        }
    }
}

const actions = { 
    getLastestScanType({commit},data){           
        let type = siteTypeWithRoute.find(a=>a.type == data.site.type)?.type        
        if(type != null || typeof(type) != 'undefined'){            
            getLastestRawApi({
                id:data.site.siteId,
                params:{
                    siteType:type
                },
                success:(d)=>{                                                              
                    let obj = {
                        siteId:d.SiteId,
                        scanType:scanType[d.ScanType]
                    }
                    commit('updateSiteScanType',obj)
                    if(data.cb){
                        data.cb(obj)
                    }
                }
            })            
        }        
    },
    getSiteType({commit,dispatch}){
        dispatch('storage_type/getDicMenu',{
            typeid:baseType.siteType,
            callback:(d)=>{
                let siteType = d.map(a=>{
                    return {
                        name:a.BdValue,
                        id:a.BdId,
                        code:a.BdCode,
                        baseId:a.BdType,
                        icon:a.BdCode == 0 ? 'mdi-atom-variant' : a.BdCode == 1 ? 'mdi-atom-variant':a.BdCode == 2 ? 'mdi-weather-fog':a.BdCode == 3? 'mdi-fire-truck':a.BdCode == 4?'mdi-wind-power':''
                    }
                })                                
                commit('updateSiteTypes',siteType)
            }
        },{root:true})       
    },
    getExternalDeviceInfo({commit,dispatch},citys){
        getExternalDevicesApi({
           params:{citys:citys,},
           success:(d)=>{
            commit('updateDevice',d.map(a=>{
                return {
                    city:a.EdiCity,
                    code:a.EdiCode,//设备id,
                    company:a.EdiCompany,
                    district:a.EdiDistrict,
                    extend:a.EdiExtend,
                    id:a.EdiId,
                    lat:a.EdiLat,
                    lng:a.EdiLng,
                    loc:a.EdiLoc,
                    name:a.EdiName,
                    position:a.EdiPosition,
                    province:a.EdiProvince,
                    street:a.EdiStreet,
                    type:a.EdiType,
                    data:[],
                    average:0
                }
            }))
           }           
        })
    },
    getCurrentUser({commit,dispatch},cb){
        dispatch('getSiteType')
        getUserInfoApi({                       
            success:(d)=>{                
                let extendInfo = {
                    avator: 'web/image/avator/a1.png',
                };                
                if(d.SuiExtendInfo){
                    extendInfo = JSON.parse(d.SuiExtendInfo)
                    extendInfo.avator =  extendInfo.avator? extendInfo.avator: 'web/image/avator/a1.png'
                }                                              
                let user = {
                    id:d.SuiId,
                    userName:d.SuiName,
                    account:d.SuiAccount,
                    roleName:d.SuiName,
                    company:{},
                    companyKey:d.SuiCompanyKey,
                    email:d.SuiEmail,
                    phone:d.SuiPhone,
                    pwd:d.SuiPwd,
                    extendInfo:extendInfo
                }
                
                let companyJson = JSON.parse(d?.SuiCompany);
                user.company = {
                    id:companyJson?.BciId,
                    name:companyJson?.BciName,
                    icon:companyJson?.BciIcon,
                    logo:companyJson?.BciLogo,
                    lng:companyJson?.BciLng,
                    lat:companyJson?.BciLat,
                    localName:companyJson?.BciLocalName,
                    extendInfo:companyJson?.BciExtend? JSON.parse(companyJson.BciExtend):null,
                    time:companyJson?.BciTime,
                }
                commit("updateUser",user)              
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';            
                link.href =user.company?.icon ? user.company?.icon:require('@/assets/img/ico/guoyao.ico');
                document.getElementsByTagName('head')[0].appendChild(link);
                
                let role = {
                    roleId:d.SysRole.SriId,
                    permission:d.SysRole.SriPermission,
                    roleName:d.SysRole.SriRoleName,
                    type:d.SysRole.SriTypeId
                }
                commit("updateRole",role)
                var sites = d.Sites.map(a=>{
                    let _ararmFilterRule = a.BsiFilterRule ? JSON.parse(a.BsiFilterRule):[]
                    let ararmFilterRule = []
                    if(_ararmFilterRule.length > 0){
                        ararmFilterRule = _ararmFilterRule?.map(b=>{
                            return {
                                id:b.Filter,
                                checked:true,
                                name:alarmFilterRuleType[b.Filter]
                            }
                        })
                    }
                    let item = {
                        siteId:a.BsiId,
                        siteName:a.BsiName,
                        lng:a.BsiLng,
                        lat:a.BsiLat,
                        deploytime:a.BsiDeployTime,
                        yaw:a.BsiYaw,
                        type:a.BsiTypeId,
                        transStatus:a.BsiTransStatus,
                        commTime:new Date(a.BsiCommTime).pattern("yyyy-MM-dd HH:mm:ss"),
                        commitStatus:(new Date().getTime() - new Date(a.BsiCommTime).getTime()) / 60 / 1000 > 5 ? 0:1, //0离线  1 在线
                        principal:a.BsiPrincipal,
                        localtion:a.BsiLocation,
                        province:a.BsiProvince,
                        city:a.BsiCity == "" ? a.BsiProvince:a.BsiCity,
                        district:a.BsiDistrict,
                        extendInfo:a.BsiExtendInfo,
                        scanDistance:Number(a.BsiExtendInfo ? JSON.parse(a.BsiExtendInfo).scanDistance? JSON.parse(a.BsiExtendInfo).scanDistance : 6:6),
                        lidarInfo:{
                            lidarId:a.BaseLidarInfo?.BliLidarId,
                            bliInvalidDistance:a.BaseLidarInfo?.BliInvalidDistance,
                            bliTypeId:a.BaseLidarInfo?.BliTypeId
                        },
                        weatherUpdateTime:null,
                        weatherInfo:null,
                        scanType:a.LastScanType,
                        alarmFilterRule:ararmFilterRule
                    }                                        
                    return item;
                })   
                commit('storage_global/updateSiteData',{
                    siteList:sites                  
                },{root:true})   
                dispatch("getExternalDeviceInfo",sites.map(a=>a.city).join(","))                           
                commit("updateSites",sites) 
                if(cb){                                       
                   cb()
                } 
            },
            fail:()=>{
                if(cb){                    
                    cb()
                } 
            }
        })
    },        
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
//自动导入
const req = require.context('@/store/modules/')
const components = {};
req.keys().forEach(a=>{    
    if(a != "./" && a.indexOf('.js') < 0 && a.slice(a.length -1,a.length) !="/" && a!='./index'){                        
        components[a.replace("./","").replace(/(radarModule\/aerosols\/)|(radarModule\/car\/)/g,"").replace(/(\/)/g,"_")] = req(a).default
    }    
})
export default{
    ...components
}
export default {
    wsUrl:process.env.VUE_APP_RabbitUrl,
    wsacc:process.env.VUE_APP_RabbitAcc,
    wspc:process.env.VUE_APP_RabbitPW,
    sysVersion:"v1.0.10."+process.env.VERSION,
    promotionalDocuments:process.env.VUE_APP_MinioIp+'web/guoyaoads/数据中心使用说明.mp4',//宣传下载地址
    TimingMessageTopic:process.env.VUE_APP_TimingMessageTopic,//消息主题
    externalDeviceDataTopic:process.env.VUE_APP_externalDeviceDataTopic,//外部数据mq主题
    alarmPointTopic:process.env.VUE_APP_alarmPointTopic,
    videodownloadversion:process.env.VUE_APP_VideoDownloadVersion
}
<template>
    <v-container class="containChartBox ma-0 pa-0 width100 height100">
    <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
    class=" ma-16"
        absolute
        :value="noData"
        opacity="0.2"
        >
    暂无数据
    </v-overlay>
    <v-card  class="width100 height100 ma-0 d-flex flex-column">   
        <v-card-title  class="ma-0 pa-1 trendmapTitle">能见度趋势图[24小时]</v-card-title>   
        <div ref="chart" class=" width100 height100" >

        </div>
    </v-card>
</v-container>
</template>
<script>
import { getVlsAveApi } from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
import scanType from '@/plugins/datas/scanType'
export default {
    name:"vls.trendmap",
    props:{
        title:{
            type:String
        },
        type:{
            type:String
        },
        currentSite:{
            type:Object
        }
    },
    data(){
        return {
            overlay:false,
            chart:null,
            width:370,
            height:268,         
            data:[],
            currentWatch:null
        }
    },
    computed:{       
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        }, 
    },
    watch:{
        currentSite:{
            handler(n,v){ 
                this.data = [] 
                this.watchData(n.siteId)  
                this.load();                            
            }
        },        
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()                    
            }
        },
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this; 
        setTimeout(() => {
            that.watchData(that.currentSite.siteId)
            that.load()        
        }, 800);   
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                            
                if(n.SiteId == that.currentSite.siteId && n.DataType == transferDataType.vizPeriod.value){                                                                               
                    var json = JSON.parse(JSON.stringify(n.jsonData)); 
                    let len = that.data.length-json.length
                    json.forEach(a=>{
                        for(var i in scanType){
                            if(a.scanType == scanType[i]){
                                a.scanType = i;
                            }
                        }
                    })
                    that.data = json.concat(that.data)
                    that.data.slice(0,len)  
                    that.loadChart()                                               
                } 
            }, {immediate: true })
        },   
        load(){            
            let siteId = this.currentSite?.siteId;
            let start = new Date (new Date ().setTime(new Date ().getTime() - 3600 * 1000 * 24)).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId)          
        },     
        loadData(start,end,siteId,datas = [],time){  
            this.overlay = true;
            var that = this;      
            getVlsAveApi({
                id:siteId,                  
                params:{
                    LastDate:time,
                    PageSize:1000,                        
                    StartTime:start,
                    EndTime:end
                },success:(d)=>{                         
                    let _datas = d.Data.map(a=>{
                        return {
                            dataType:transferDataType.vizPeriod.value,
                            siteId:a.DvaSiteId,
                            period:a.DvaPeriod,
                            scanType:a.DvaScanMode,
                            dataTime:a.DvaTime,
                            angleOfPitch:a.DvaPitch,
                            straightAngle:a.DvaStraight,
                            data:a.DvaValue
                        }})                 
                    datas = datas.concat(_datas)   
                    that.data = datas;      
                    if(that.data.length == 0){   
                        that.overlay = false;
                        that.loadChart() 
                        return
                    }                   
                    if(d.Data.length > 0 &&new Date(d.Data[d.Data.length -1].DvaTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Data.length < 1000){                                                                                   
                        that.overlay = false;
                        that.loadChart()                                                  
                            //that.loadChart();       
                    }else{                              
                        that.data = datas;                  
                        that.loadChart()                         
                        that.loadData(start,end,siteId,datas,d.Data[d.Data.length -1].DvaTime)                                  
                    }  
                  
                }
            })            
        },
        loadChart(){                        
            let target = this.$refs.chart;         
            if(!target){
                return
            }
            let xData = [];            
            let datas = this.data.sort((a,b)=>{
                return new Date(a.dataTime.replace("T"," ")).getTime() - new Date(b.dataTime.replace("T"," ")).getTime()
            }); 
            datas.forEach((a,i)=>{                                            
                xData.push(new Date(a.dataTime.replace("T"," ")))                           
            });              
                        
            var data = [
                {
                    type: 'scatter',               
                    x: xData,
                    y: [],                              
                    showlegend: true,
                    text:[],
                    marker:{
                        size:4
                    },
                    name:'水平扫描', 
                    mode: 'lines+markers',                  
                },{
                    type: 'scatter',               
                    x: xData,
                    y: [],                              
                    showlegend: true,
                    text:[],
                    marker:{
                        size:4
                    },
                    mode: 'lines+markers',
                    name:'定点扫描'
                }
            ];
            for(let i in datas){                     
                for(let j in data){                                                          
                    if(datas[Number(i)].scanType == j){ 
                        let _value = Number(datas[i].data);                                                 
                        data[j].y.push(_value)    
                        data[j].text.push(`时间：${new Date(datas[i].dataTime).pattern("HH:mm <br /> MM月dd日")}<br />能见度：${_value}公里<br />扫描方式：${j == 0 ? "水平扫描":"定点扫描"}`)                        
                        // markers.push({
                        //     color:_value > 10 ?'rgb(227, 255, 246)':_value>1?'rgb(202, 251, 235)':_value>0.5?
                        //     'rgb(104, 253, 250)': _value > 0.2 ? 'rgb(4, 204, 203)':_value > 0.05?'rgb(5, 152, 151)':'rgb(6, 82, 72)' ,
                        //     x:new Date(datas[i].dataTime),
                        //     y:_value,
                        //     hovertext:_value > 10?'':_value > 1?'轻雾':_value > 0.5?'大雾':_value > 0.2?'浓雾':_value > 0.05?'强浓雾':'特强浓雾'
                        // })                                
                    }else{
                        // markers.push({                            
                        //     x:new Date(datas[i].dataTime),
                        //     y:null,
                        //     hovertext:''
                        // })      
                        data[j].text.push('')
                        data[j].y.push(null)
                    }              
                }                
            }   
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {
                // title: {
                //     text:'能见度趋势图',
                //     x:0.01,
                //     font: {
                //     size: 16,
                //     weight:800
                //     },

                // },
                //autocolorscale:true,
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.1,
                    font:{
                        color: fontColor
                    }
                },
                uirevision:'true', 
                xaxis: {  
                    color:fontColor,
                    nticks:10,
                    tickformat:"%H:%M <br /> %m月%d日 ",
                },
                yaxis: {
                    color:fontColor,
                    title:"能见度(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,  
                    l:42                        
                }
            };       
            data.push({
                type: 'scatter',
                x: [xData[0], xData[xData.length-1]],
                y: [3, 3],
                mode: 'lines',
                name: '轻雾',
                showlegend: true,
                line: {
                    color: 'orange',
                    width: 2,
                    dash: 'dash'
                }
            },
            {
                type: 'scatter',
                x: [xData[0], xData[xData.length-1]],
                y: [1, 1],
                mode: 'lines',
                name: '浓雾',
                showlegend: true,
                line: {
                    color: 'red',
                    width: 2,
                    dash: 'dash'
                }
            })        
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }   
        },
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
    max-width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 360px;
    right: 25px;
    width: 30px;
    height: 30px;
    z-index: 2;
}
.ColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
.trendmapTitle{
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
</style>
<template>
    <v-container
        class="py-2 d-flex flex-column"
        fluid      
     > 
      <v-row class="d-flex align-center flex-grow-0" dense>
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('dailyManage.label.title')"
                    :placeholder="$i18n.t('dailyManage.placeholder.title')"
                    v-bind:value.sync="title"
                />
                <SelectInput             
                    :label="$i18n.t('dailyManage.label.site')"
                    :options="sites"  
                    :placeholder="$i18n.t('dailyManage.placeholder.site')" 
                    :width='200'
                    v-bind:value.sync="siteIds"
                    clearable
                    /> 
                <SearchDatePicker                     
                    :label="$i18n.t('dailyManage.label.createDateRange')"
                    :placeholder="$i18n.t('dailyManage.placeholder.createDateRange')"
                    v-bind:date.sync="createDate"   
                    clearable                 
                />
                <!-- <SelectInput             
                    :label="$i18n.t('dailyManage.label.province')"
                    :options="provinceList"  
                    :placeholder="$i18n.t('dailyManage.placeholder.province')" 
                    :width='200'
                    v-bind:value.sync="province"
                    />         
                <SelectInput v-bind:value.sync="city" :label="$i18n.t('dailyManage.label.city')" :options="cityList" :placeholder="$i18n.t('dailyManage.placeholder.city')" :width='198' />  -->
                <SelectInput             
                    :label="$i18n.t('dailyManage.label.publish')"
                    :options="publishs"  
                    :placeholder="$i18n.t('dailyManage.placeholder.publish')" 
                    :width='200'                    
                    v-bind:value.sync="publish"
                    clearable
                    />  
                <v-btn class="mx-2"  @click="page=1;load()">{{$i18n.t('dailyManage.btn.search')}}</v-btn>
            </v-row>
        </v-col>              
      </v-row>       
      <v-row class="tableContainer ma-0">
          <v-data-table   
            :items="datas"          
            fixed-header
            :headers="headers"
            :page.sync="page"
            :items-per-page="rows"
            :loading="loadingData"
            hide-default-footer
          >
        <template v-slot:item.RdPublishStatus="{item,value}">
        <span :class="[value ?'success--text':'warning--text']">{{value == 0 ? "未审批":"已审批"}}</span>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="edit(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="del(item)"
            >
                mdi-delete
            </v-icon>
        </template>

          </v-data-table>          
      </v-row>      
      <v-row justify="center" align="center" class="ma-0 flex-grow-0">
          <v-pagination
            v-model="page"
            :length="length"
             @input="load"
            circle
            :total-visible="8"
            ></v-pagination>
            {{$i18n.t("dailyManage.pageTotal",[total])}}
      </v-row>
      </v-container>
</template>
<script>
import cityJson from '@/plugins/datas/cityJson';
import {getDailyReportsApi,deleteDailyReportApi } from '@/api'
export default {
    name:"report.dailyManage.index",
    data(){
        return{
            datas:[],
            page:1,
            rows:10,
            total:0,
            loadingData:false,
            title:"",
            siteIds:"",
            province:"",
            city:"",
            createDate:"",
            provinceList:cityJson.map(a=>{
                return {
                    value:a.text,
                    text:a.text
                }
            }),
            publish:"",
            cityList:[],
            sites:[],  
            publishs:[{
                text:"未审批",
                value:0
            },{
                text:"已审批",
                value:1
            }],                  
            headers:[
                {
                    text:this.$i18n.t('dailyManage.table.header.title'),
                    value: 'RdTitle',      
                    width:'24%'      
                },
                {
                    text:this.$i18n.t('dailyManage.table.header.site'),
                    value: 'SiteName',
                },
                // {
                //     text:this.$i18n.t('dailyManage.table.header.province'),
                //     value: 'Province',
                // },  
                // {
                //     text:this.$i18n.t('dailyManage.table.header.city'),
                //     value: 'City',
                // }, 
                {
                    text:this.$i18n.t('dailyManage.table.header.publish'),
                    value: 'RdPublishStatus',
                },  
                 
                {
                    text:this.$i18n.t('dailyManage.table.header.createDate'),
                    value: 'CreateTime',
                },    
                {
                    text:this.$i18n.t('dailyManage.table.header.editDate'),
                    value: 'RdModifyTime',
                },                   
                {
                    text:this.$i18n.t('dailyManage.table.header.operation'),  
                    value: 'actions', sortable: false              
                },
            ],
                        
        }
    },
    watch:{
       // province(n,v){ 
        // this.city = ""           
        //     if(!n){
        //          this.cityList = [];
        //     }else{
        //         this.cityList = cityJson.find(a=>a.text == n).children.map(a=>{
        //             return {
        //                 text:a.text,
        //                 value:a.text
        //             }
        //         })
        //     }
        // }             
    },
    computed:{ 
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        }          
    },
    mounted(){
        this.load()
    },
    methods:{
        load(){
            var that = this;
            var siteData = this.$store.state.storage_user.sites
            this.sites = siteData.map(a=>{
                    return {
                        text:a.siteName,
                        value:a.siteId
                    }
                })
            this.loadingData = true;
            getDailyReportsApi({
                params:{
                    PageIndex:this.page,
                    PageSize:this.rows,
                    Title:this.title,
                    SiteIds:this.siteIds,
                    CreateDate:this.createDate,
                    PublishStatus:this.publish
                    // Province:this.province,
                    // City:this.city

                },
                success:(d)=>{
                    that.loadingData = false;
                    that.datas = d.Data.map(a=>{
                        let sites = this.sites.filter(b=>{
                            return a.RdSiteId.indexOf(b.value) >= 0
                        })
                        a.SiteName= sites.map(b=>{
                            return b.text
                        }).join(',')
                        
                        a.CreateTime = new Date(a.RdCreateTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss')
                        a.RdModifyTime = new Date(a.RdModifyTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss')
                       // a.Publish = a.RdPublishStatus == 0 ? "未审批":"已审批"
                        return a;
                    })
                    that.total = d.Total;
                }        
           })
        },
        edit(item){
            this.$router.push({
                path:'/report/dailyManage/edit',
                query:{
                    id:item.RdId,
                    time:item.RdCreateTime
                }
            })
        },
        del(item){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:()=>{
                    deleteDailyReportApi({
                        id:item.RdCreateTime + '/'+ item.RdId,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }                            
                        }
                    })
                },//确认回调
                noBack:null //取消回调
            })
        },
        publishReport(item){
        }
    }
    
}
</script>
<style scoped lang="scss">
.v-data-table{
    width: 100%;
    height: 100%;
}
.dialogContent{
    overflow: auto;
}
.permissionItem{
    width: 100%;
}

</style>

<template>
    <div class="views_main">
        <div v-show="type=='doc'" ref="file"></div>
        <div class="table_xl" v-show="type=='xlsx'" >
            <div class="table_data">
                <v-data-table fixed-header :headers="sheet.currentSheet.header"
                    :items="sheet.currentSheet.data"
                    item-key="index"
                    disable-pagination
                    hide-default-footer            
                    :item-class="()=>{return'_tableActive'}"
                    :calculate-widths='false'
                    >
                </v-data-table>
            </div>
            <div class="sheets">
                <v-btn @click="setSheetData(i.index)" v-for="i in sheet.sheets" :key=i.index>{{i.name}}</v-btn>
            </div>
        </div>
        <div class="pdf" v-show="type=='pdf'">
            <pdf style="box-shadow: 0 0 10px 5px #a5a5a5;" 
            :src="pdfsrc" 
            v-for="i in pdfPage"
			:key="i"			
			:page="i"></pdf>
        </div>
    </div>
</template>
<script>
import { downloadApi } from '@/api'
import pdf from 'vue-pdf'
import * as XLSX from 'xlsx/xlsx.mjs';
let docx = require('docx-preview');
export default {
    components:{
      pdf
     },
    name:"preview",
    data(){
        return {
            type:"",
            pdfsrc:"",
            pdfPage:null,
            sheet:{
                currentSheet:{
                    data:[],
                    header:[]
                },               
                data:[],
                sheets:[]
            }
        }
    },
    mounted(){
        this.type = ""             
        if(this.$route.query.url) {
            this.load(this.$route.query.url)
        }
        
    },
    methods:{
        load(url){
            var that = this;
            downloadApi(
            {
                url:url,
                fileName:url.split('/').pop(),
                reCallBack:(res,filename)=>{                    
                    var _index = filename.lastIndexOf(".");
                    var suffix = filename.substr(_index+1);
                    if(suffix.indexOf('pdf') >= 0){
                        that.type= 'pdf'
                        var blob = new Blob([res.data]);
                        that.pdfsrc =  pdf.createLoadingTask(URL.createObjectURL(blob));
                        
                        that.pdfsrc.promise.then(pdf => {
                            that.pdfPage = pdf.numPages;
                        });
                    }
                    else if(suffix.indexOf('do') >=0){                        
                        if(suffix == "doc"){
                            // msg({
                            //     message:'暂时不支持doc个格式的预览，请下载后查看',
                            //     type:'error'
                            // })
                            // return;
                        }
                        this.type = 'doc'
                        docx.renderAsync(res.data,that.$refs.file)                     
                    }
                    else if(suffix.indexOf('xl') >=0){                        
                        this.type = 'xlsx'
                        let blob = new Blob([res.data])
                        let reader = new FileReader();
                        
                        reader.onload = function(e) {
                            let workbook = XLSX.read(e.target.result); // 解析数据
                            console.log(workbook)
                            var sheets = workbook.Props.SheetNames;
                            that.sheet.sheets = sheets.map((a,_i)=>{
                                return{
                                    index:_i,
                                    name:a
                                }
                            })                            
                            let sheetData = [];                        
                            for(var i in sheets){                                
                                var worksheet = workbook.Sheets[workbook.SheetNames[i]]; // workbook.SheetNames 下存的是该文件每个工作表名字,这里取出第一个工作表
                                let json = XLSX.utils.sheet_to_json(worksheet,{header:'A',raw:false}); 
                                if(json.length > 0){
                                    let header = [{text:'序号',value:'index'}]
                                   for(var key in json[0]){
                                       header.push({
                                           text:key,
                                           value:key
                                       })
                                   }
                                   var data = json.map((a,_i)=>{
                                       a.index = _i+1;
                                       return a
                                   })
                                   sheetData.push({
                                       header:header,
                                       data:data
                                   })
                                }                                
                            }
                            that.sheet.data = sheetData;
                            that.sheet.currentSheet.header = sheetData[0].header
                            that.sheet.currentSheet.data = sheetData[0].data
                        }
                        reader.readAsArrayBuffer(blob);
                        
                    }
                }
            })
        },
        setSheetData(index){            
            let data = this.sheet.data;
            this.sheet.currentSheet.data = data[index].data;
            this.sheet.currentSheet.header = data[index].header;
            console.log(this.sheet)

        }
    }
}
</script>
<style lang='scss' scoped>
.views_main{
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}
.table_xl{
    width: 1000px;    
    height: 100%;  
    display: flex;
    flex-direction: column;
    align-items: center; 
    box-shadow: 0 0 10px 5px #a5a5a5;
}

.table_data{
    width: 100%;
    height: 90%;    
    overflow: auto;
    padding: 10px;
}
.pdf{
    width: 1000px;    
    height: 100%
    }

.table_data>>>th .v-icon{
    display: none;    
}
</style>
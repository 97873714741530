<template>
    <v-container>
        <div ref="map" class="map">            
        </div>
    </v-container>
</template>

<script>
export default {
    name:"LocationSelector",
    props:["lng","lat","province","city","district","position"],
    data:()=>({
        map:null,
        marker:null,
        center:[0,0]
    }),
    watch:{
        lng:{
            handler(n){     
                if((n< 135.06 || n >=73.32)&& this.lat) {          
                    this.setPosition(n,this.lat)
                }
            }                        
        },        
        lat:{
            handler(n){                
                if((this.lat < 53.34 || this.lat >= 3.50) && this.lng){
                    this.setPosition(this.lng,n)
                }   
                             
            }                        
        },
        "$vuetify.theme.dark":{
            handler(n){
                if(n){
                    if(this.map){
                        this.map.setMapStyle("amap://styles/dark")
                    }
                }
                else{
                    if(this.map){
                        this.map.setMapStyle("amap://styles/macaron")
                    }     
                }
            } 
        }
    },
    mounted(){
        var that = this;
        setTimeout(() => {
            that.load();   
        },500);               
    },
    methods:{
        load(){            
            let theme = this.$vuetify.theme.dark ? "amap://styles/dark":"amap://styles/macaron" 
             this.map = new AMap.Map(this.$refs.map,{
                rotateEnable:false,
                pitchEnable:false,                
                pitch: 0,
                rotation: 0,//-55
                zoom:12,//级别
                zooms:[2,30],            
                //viewMode:'3D',//使用3D视图            
                mapStyle: theme
            });
           
            if(this.lng && this.lat){
                if(this.lng > 135.06 || this.lng <=73.32 || this.lat > 53.34 || this.lat < 3.50){
                    this.center = this.map.getCenter();
                    this.$emit('update:lng',Number(Number(this.center.lng).toFixed(6)))
                    this.$emit('update:lat',Number(Number(this.center.lat).toFixed(6))) 
                }else{
                    this.center = {lng:this.lng,lat:this.lat}
                    this.map.setCenter([this.lng,this.lat])
                }
            }else{
                this.center = this.map.getCenter();
                this.$emit('update:lng',Number(Number(this.center.lng).toFixed(6)))
                this.$emit('update:lat',Number(Number(this.center.lat).toFixed(6))) 
            }            
            this.marker = new AMap.Marker({
                position: [this.center.lng,this.center.lat],
                //icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
            });
            this.map.add(this.marker);        
            var that = this;             
            this.map.on('click', res=>{                                                        
                that.marker.setPosition(res.lnglat)
                that.$emit('update:lng',Number(Number(res.lnglat.lng).toFixed(6)))
                that.$emit('update:lat',Number(Number(res.lnglat.lat).toFixed(6)))                                  
                that.center = {
                    lng:res.lnglat.lng,
                    lat:res.lnglat.lat
                }      
                let config = {
                    data:{
                        lng:res.lnglat.lng,
                        lat:res.lnglat.lat
                    }
                }
                //获取位置
                getAddressByLngLat(config,(rel)=>{
                    let position = rel.formattedAddress;
                    let city = rel.addressComponent.city;
                    let province = rel.addressComponent.province;
                    let district = rel.addressComponent.district;
                    let township = rel.addressComponent.township;                    
                    that.$emit('update:position',position)
                    that.$emit('update:province',province)
                    that.$emit('update:city',city)
                    that.$emit('update:district',district)
                    that.$emit('update:township',township)
                })                      
            });                                              
        },
        setPosition(lng,lat){  
            if(lng > 135.06 || lng <=73.32 || lat > 53.34 || lat < 3.50){
                this.center = this.map.getCenter();
                lng = this.center.lng;
                lat = this.center.lat
            }
            else{
                this.center = {lng:this.lng,lat:this.lat}
            }
            let config = {
                data:{
                    lng:lng,
                    lat:lat
                }
            }      
            if(lng && lng != 0){
                this.map.setCenter([lng,lat])
                this.marker.setPosition([lng,lat])   
                config.data.lng = lng; 
                config.data.lat = lat
                var that= this;
                //获取位置
                getAddressByLngLat(config,(rel)=>{
                    let position = rel.formattedAddress;
                    let city = rel.addressComponent.city;
                    let province = rel.addressComponent.province;
                    let district = rel.addressComponent.district;
                    let township = rel.addressComponent.township;
                    that.$emit('update:position',position)
                    that.$emit('update:province',province)
                    that.$emit('update:city',city)
                    that.$emit('update:district',district)
                    that.$emit('update:township',township)
                })                 
            }        
        }
    }


}
</script>

<style scoped>
.map{
    width: 100%;
    height: 100%;
}
</style>
<template>
    <v-container class="containChartBox ma-0 pa-0 width100 height100">
    <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
        class="ma-3 mt-11"
            absolute
            :value="noData"
            opacity="0.2"
            >
        暂无数据
        </v-overlay>
    <v-card  class="width100 height100 ma-0 d-flex flex-column">   
        <v-card-title  class="ma-0 pa-1 trendmapTitle">能见度趋势图[24小时]</v-card-title>   
        <div ref="chart" class="plotychart width100 height100" >

        </div>
    </v-card>
</v-container>
</template>
<script>
import { getSiteListVlsAveApi } from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
import scanType from '@/plugins/datas/scanType'
import { time } from 'echarts'
export default {
    name:"vls.trendmap",
    props:{
        title:{
            type:String
        },
        type:{
            type:String
        },
        selectSites:{
            type:Array
        }
    },
    data(){
        return {
            overlay:false,
            chart:null,
            width:370,
            height:268,
            data:[],
            dataList:{},
            watchList:[],
            timer:null
        }
    },
    computed:{      
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },  
    },
    watch:{
        selectSites:{
            handler(n,v){  
                this.watchList.map(a=>a())      
                this.watchData(n)  
                this.load();                            
            }
        },                
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()                    
            }
        },
    },
    beforeDestroy(){
        this.watchList.forEach(a=>a())
    },
    beforeUnmount(){
        this.watchList.forEach(a=>a())
    },    
    mounted(){
        var that = this; 
        setTimeout(() => {
            that.load()        
            that.watchData(that.selectSites)              
        }, 300);   
    },
    methods:{
        watchData(sites){
            let that = this;
            sites.map(a=>{                    
                let _currentWatch = that.$watch(`$store.state.storage_global.siteData.${a.siteId}`,(n,v)=>{
                    let siteIds = that.selectSites.map(a=>a.siteId)                                                      
                    if(siteIds.indexOf(n.SiteId)>=0 && n.DataType == transferDataType.vizPeriod.value){                                                                               
                        var json = JSON.parse(JSON.stringify(n.jsonData));   
                        let len = that.data.length-json.length
                        json.forEach(a=>{
                            for(var i in scanType){
                                if(a.scanType == scanType[i]){
                                    a.scanType = i;
                                }
                            }
                        })
                        that.data = json.concat(that.data).sort((a,b)=>{
                            return new Date(b.dataTime.replace("T"," ")).getTime() - new Date(a.dataTime.replace("T"," ")).getTime()
                        })
                                            
                        that.data.slice(len)                        
                        that.loadChart()                                               
                    } 
                }) 
                that.watchList.push(_currentWatch) 
            })          
        },
        load(){   
            if(this.timer) {
                clearTimeout(this.timer)
            }
            let siteId = this.$store.state.storage_global.currentSite.siteId;
            let start = new Date (new Date ().setTime(new Date ().getTime() - 3600 * 1000 * 24)).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            if(this.selectSites.length > 0){
                let that = this
                setTimeout(() => {
                    that.loadData(start,end,siteId) 
                }, 600);                
            }         
        },    
        loadData(start,end,siteId,datas = [],time){  
            this.overlay = true;
            var that = this;
            getSiteListVlsAveApi({                          
                params:{
                    siteIds:this.selectSites.map(a=>a.siteId),
                    LastDate:time,
                    PageSize:this.selectSites.length * 500,                        
                    StartTime:start,
                    EndTime:end
                },success:(d)=>{   
                    if(d.Data.length == 0){  
                        that.overlay = false;
                        that.loadChart() 
                        return
                    }                              
                    let _datas = d.Data.map(a=>{
                        return {
                            dataType:transferDataType.vizPeriod.value,
                            siteId:a.DvaSiteId,
                            period:a.DvaPeriod,
                            scanType:a.DvaScanMode,
                            dataTime:a.DvaTime.replace("T"," "),
                            angleOfPitch:a.DvaPitch,
                            straightAngle:a.DvaStraight,
                            data:a.DvaValue
                        }}).sort((a,b)=>{
                            return new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime()
                        })                 
                    datas = datas.concat(_datas)                                                      
                    if(d.Data.length > 0 &&new Date(d.Data[d.Data.length -1].DvaTime.replace('T',' ')).getTime()  <= new Date(start).getTime()){                                                                                   
                        that.data = datas;                          
                        that.overlay = false;
                        that.loadChart()                
                    }else{                              
                        that.data = datas;                  
                        that.loadChart()                         
                        that.loadData(start,end,siteId,datas,_datas[_datas.length -1].dataTime)                                  
                    }    
                }
            })            
        },
        loadChart(){                        
            let target = this.$refs.chart; 
            if(!target){
                return;
            }
            let xData = []; 
            this.dataList = $help.groupBy("siteId",this.data)                   
            let timeList =$help.unique(this.data.map(a=>a.dataTime).sort((a,b)=>{
                return new Date(a).getTime() - new Date(b).getTime()
            }))
            xData = timeList.map(a=>new Date(a))   
            let data = [];  
            let markers = []
            for(let i in this.dataList){
                let siteName = this.selectSites.find(a=>a.siteId == i)?.siteName.replace("站点","").split('-')
                let name = siteName[siteName.length -1]
                let dataIndex = 0;
                let yData = [];
                let text = [];
                let datas = this.dataList[i].sort((a,b)=>{                    
                    return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
                })      
                xData.forEach(a=>{ 
                    if(datas.length -1 > dataIndex){
                        let _cd = datas[dataIndex];                              
                        if (_cd.dataTime== a.pattern('yyyy-MM-dd HH:mm:ss')){
                            let _value = Number(_cd.data);
                            yData.push(_cd.data)    
                            text.push(`时间：${a.pattern('yyyy-MM-dd HH:mm:ss')}<br />能见度：${_value}公里<br />扫描方式：${_cd.scanType == 0 ? "水平扫描":"定点扫描"}`)                        
                            markers.push({
                                color:_value > 10 ?'rgb(227, 255, 246)':_value>1?'rgb(202, 251, 235)':_value>0.5?
                                'rgb(104, 253, 250)': _value > 0.2 ? 'rgb(4, 204, 203)':_value > 0.05?'rgb(5, 152, 151)':'rgb(6, 82, 72)' ,
                                x:new Date(_cd.dataTime.replace("T"," ")),
                                y:_value,
                                text:_value > 10?'':_value > 1?'轻雾':_value > 0.5?'大雾':_value > 0.2?'浓雾':_value > 0.05?'强浓雾':'特强浓雾'
                            })  
                            dataIndex++;                                 
                        }else{    
                            yData.push(null)
                            text.push('')
                        }                                             
                    }  
                    else{   
                        yData.push(null)
                        text.push('')
                    }                                                   
                })
                data.push({
                    type: 'scatter',               
                    x: xData,
                    y: yData, 
                    text:text,                             
                    showlegend: true,
                    marker:{
                        size:4
                    },
                    mode: 'lines+markers',
                    name:name
                })
            }                
            let group = $help.groupBy('color',markers)            
            for(var i in group){
                let item = group[i]                
                data.unshift({
                    type: 'scatter', 
                    showlegend:false,              
                    x: item.map(a=>a.x),
                    y: item.map(a=>a.y),  
                    //hovertext:[],
                    mode: 'markers',
                    marker:{
                        color:i,
                        size:10
                    },
                    name:'', 
                })
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {      
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.1,
                    font:{
                        color: fontColor
                    }
                },
                uirevision:'true', 
                xaxis: {  
                    color:fontColor,
                    nticks:15,
                    tickformat:"%H:%M <br /> %m月%d日 ",
                },
                yaxis: {
                    color:fontColor,
                    title:"能见度(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:82,  
                    l:42                        
                }
            };       
            data.push({
                    type: 'scatter',
                    x: [xData[0], xData[xData.length-1]],
                    y: [3, 3],
                    mode: 'lines',
                    name: '轻雾',
                    showlegend: true,
                    line: {
                        color: 'orange',
                        width: 2,
                        dash: 'dash'
                    }
                },
                {
                    type: 'scatter',
                    x: [xData[0], xData[xData.length-1]],
                    y: [1, 1],
                    mode: 'lines',
                    name: '浓雾',
                    showlegend: true,
                    line: {
                        color: 'red',
                        width: 2,
                        dash: 'dash'
                    }
                })        
                if(target?.children?.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
                }  
                        
        },
        clickTime(siteId,n,color = 'red'){
            let target = this.$refs.chart;  
            if(target.children.length > 0){
                let timeSpan;
                let x,y;
                this.data.filter(a=>a.siteId == siteId).forEach((a,i)=>{                    
                    let _span = Math.abs(new Date(n).getTime() - new Date(a.dataTime.replace('T',' ')).getTime());
                    if(i == 0){
                        timeSpan = _span
                        x = new Date(a.dataTime.replace('T'," "));
                        y = a.data;
                    }else{
                        if(_span < timeSpan){                            
                            timeSpan = _span
                            x = new Date(a.dataTime.replace('T'," "));
                            y = a.data;
                        }
                    }
                })
                let annotation = [{
                    text: `${x.pattern('yyyy-MM-dd HH:mm:ss')},${y}`,
                    x: x,
                    y: y,
                    bgcolor:color
                }]
                if(x && y){                    
                    if(target.layout.annotations &&target.layout.annotations?.length>0 && target.layout.annotations[0].x.pattern('yyyy-MM-dd HH:mm:ss') == annotation[0].x.pattern('yyyy-MM-dd HH:mm:ss') && target.layout.annotations[0].y == annotation[0].y){                    
                        Plotly.relayout(target,{annotations: []})                    
                    }else{
                        Plotly.relayout(target,{annotations: annotation})                
                    }
                }                     
            }
        },
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
    max-width: 100%;
}
.trendmapTitle{
    position: absolute;
    width: 180px;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}

.plotychart{
    :deep(.modebar){
        padding-top: 40px;
        padding-right:60px ;
    }
}
</style>
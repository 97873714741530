
import help from '@/plugins/helper/help'
import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import {vuetify,i18n} from '@/plugins/helper/vuetify'
import router from '@/router'
import store from '@/store'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { sync } from 'vuex-router-sync'
import VueClipboard from 'vue-clipboard2'
import sysconfig from '@/plugins/datas/sysconfig'
import "@/assets/svg/index.js"
import {getSetValue,registerAllComponents} from '@/plugins/helper/vuehelper'
import maptool from '@/plugins/mapToolLoad'
import VueCoreVideoPlayer from 'vue-core-video-player'
import * as Base64 from 'js-base64'
import pako from "pako";
import dataV from '@jiaminghi/data-view'
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
//import xhrListenHelper from '@/plugins/helper/xhrListenHelper'
 
// xhrListenHelper((e,f)=>{
//   console.log(e,f)
// })
let link = document.querySelector("link[rel*='icon']");
link?.remove()
link = document.createElement('link') 
link.type = 'image/x-icon';
link.rel = 'shortcut icon';    
link.href = 'http://124.133.246.59:13395/web/image/ico/null.ico'
document.getElementsByTagName('head')[0].appendChild(link);
window.$zip = function (str){
  var binaryString = pako.gzip(encodeURIComponent(str), { to: 'string' })
  return btoa(binaryString);
}

window.$unzip = function(b64Data,siteId) {
  let strData = Buffer.from(b64Data, 'base64');
  let binData = new Uint8Array(strData);
  let data = pako.inflate(binData);
  let array = new Uint16Array(data);
  let result = '';
  //String.fromCharCode.apply(null, array) 显示 Maximum call stack size exceeded
  let chunk = 8 * 1024;
  let i;
  for (i = 0; i < array.length / chunk; i++) {
      result += String.fromCharCode.apply(null, array.slice(i * chunk, (i + 1) * chunk));
  }
  result += String.fromCharCode.apply(null, array.slice(i * chunk));
  // 将乱码的中文进行转换
  return decodeURIComponent(result)
}
// require videojs style
 
Vue.use(VueClipboard)
Vue.use(VueCoreVideoPlayer)
Vue.use(dataV)
Vue.config.productionTip = false
window.$base64helper = Base64;
window.$help = help
window.$getSetValue = getSetValue
//注册自定义全局插件
registerAllComponents(['form'])
Vue.component('vue-timepicker',VueTimepicker)

sync(store, router);

//设置当前登录人
if(localStorage.getItem("token") && localStorage.getItem("tokenTime")&&localStorage.getItem("version") == sysconfig.sysVersion){  
  let span = (new Date() - new Date(localStorage.getItem("tokenTime")))/1000/60/60  
  if(span > 1){
    localStorage.setItem("version",sysconfig.sysVersion)
    maptool(()=>{
      new Vue({  
        store,
        vuetify,
        i18n,  
        router,
        render: h => h(App)
      }).$mount('#app')
    })
    setTimeout(() => {
      localStorage.setItem("tokenTime",new Date().pattern("yyyy-MM-dd HH:mm:ss")) 
      store.dispatch('storage_user/getCurrentUser')
    }, 500);    
  }else{
    store.dispatch('storage_user/getCurrentUser',()=>{    
      localStorage.setItem("version",sysconfig.sysVersion)
      maptool(()=>{
        new Vue({  
          store,
          vuetify,
          i18n,  
          router,
          render: h => h(App)
        }).$mount('#app')
      })
    })
  }  
}
else{  
  localStorage.setItem("version",sysconfig.sysVersion)
  maptool(()=>{
    new Vue({  
      store,
      vuetify,
      i18n,  
      router,
      render: h => h(App)
    }).$mount('#app')
  })
}







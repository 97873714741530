export const version = '/api/'+ 'v1'
export const uploadMinioURL = process.env.VUE_APP_MinioIp//"http://dbcenter.gyquantum.com/minio/"
export const uploadMinioURLServer = process.env.VUE_APP_MinioServerIp
export const loginURL =version+ '/auth'
export const uploadAndDownLoadURL = version+'/attachment'
export const siteURL = version+'/site'
export const lidarURL = version+'/lidar'
export const userURL = version+'/user'
export const roleURL = version+'/role'
export const permissionsURL =roleURL+'/permission'
export const dicURL = version + '/dictionary'
export const caseURL = version + '/case'
export const reportURL = version + '/report'
export const dailyReportURL = reportURL + '/daily'
export const geoURL = version + '/redisGeo'
export const monitorPointURL = version + '/MonitorPoint'
export const noticeURL = version + '/notice'
export const messageURL = noticeURL + '/message'
export const reciverUrl = noticeURL + '/reciver'
export const reciverConfigUrl = noticeURL + '/reciver/configuration'
export const statisticsURL = version + '/statistics'
export const external = version + '/external'
export const externalDevice = external + '/device'
export const sys = version + '/sys'
export const Gis = version + '/gis'
export const markInfo = version + '/markinfo'
export const markRecords = version + '/markRecords'
export const MonitorPointExternd1 = 'http://47.114.183.34:10207' 

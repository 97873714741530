<template>
   <v-container ref="continer" class="contain ma-0 pa-0">     
        <v-card height="100%" width="100%"> 
            <v-card-actions class="mapToolbar mt-4 mx-4">
                <v-row class="ma-0 align-center">
                    <v-col cols="2">
                        <v-menu offset-y bottom z-index="800">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                                              
                                v-bind="attrs"
                                v-on="on"
                                class="agtNameBtn pl-0"
                                >
                                <v-icon class="mr-1">mdi-map-marker-path</v-icon>
                                <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="width100">
                                    {{currentAgt.DaiName}}
                                </div>
                                </v-btn>
                            </template>
                            <v-card elevation="0" class="ma-0 pa-4">
                                <v-menu
                                    ref="menu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="dateRange"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRange"
                                        :label='$i18n.t("agt.label.agtTime")'
                                        prepend-icon="mdi-calendar-search"
                                        readonly  
                                        clearable                                     
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.menu.save(dateRange);" v-model="dateRange" range no-title scrollable>                                    
                                    </v-date-picker>
                                </v-menu>
                                 <v-virtual-scroll
                                    v-if="agtList.length > 0"
                                    @scroll="scroll"
                                    :items="agtList"
                                    :item-height="80"                                    
                                    height="300"
                                    >
                                    <template v-slot="{ item }">                                    
                                        <v-list-item  style="min-height:0" class="flex-column align-start elevation-2">                                            
                                            <v-list-item-subtitle>{{new Date(item.DaiStartTime.replace("T"," ")).pattern('MM月dd HH:mm:ss')}}</v-list-item-subtitle>
                                            <v-list-item-title  class="d-flex justify-space-between width100 my-2">
                                                <div class="agtName">{{ item.DaiName }}</div>
                                                <v-btn
                                                @click="currentAgt = item"
                                                class="mx-1"
                                                depressed
                                                small
                                                >{{$i18n.t("agt.btn.show")}}</v-btn>
                                            </v-list-item-title>
                                        </v-list-item>                                    
                                    </template>
                                </v-virtual-scroll>   
                            </v-card>                                                     
                        </v-menu>
                    </v-col>
                    <v-col style="max-width:110px" class="d-flex">                    
                        <v-menu open-on-hover offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                                              
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                >
                                {{currentType.name}}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item-group v-model="currentType">
                                    <v-list-item
                                        v-for="(item, index) in scanMapTypes"
                                        :key="index" 
                                        :value="item"                                                    
                                    >
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>

                        <v-menu open-on-hover offset-y bottom max-height="600" z-index="160"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                                           
                                v-bind="attrs"
                                v-on="on"     
                                class="mr-3"                           
                                >
                                {{$i18n.t("agt.btn.externalBtn")}}
                                </v-btn>
                            </template>
                            <v-list dense shaped>
                                <v-list-item-group>
                                    <v-list-item
                                        v-for="(item, index) in ExternalDataMapTypes"
                                        :key="index" 
                                        :value="item"                                                    
                                    >
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        <v-list-item-group multiple v-model="showExternalTypeBtns">
                                            <v-list-item class="mx-2 my-1"
                                                v-for="(itemChildern, _index) in item.children"
                                                :key="_index" 
                                                :value="itemChildern"  
                                                @click.stop="showExternal(itemChildern)"                                                  
                                            >
                                                <v-list-item-title style="min-width:80px">{{ itemChildern.name }}<v-icon class="mx-1" v-show="showExternalTypes.indexOf(itemChildern.key) >=0">mdi-check-bold</v-icon></v-list-item-title>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>

                        <v-menu open-on-hover offset-y bottom max-height="600"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                                              
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                >
                                <v-icon>mdi-signal-distance-variant</v-icon>{{limitScanDistance}}公里
                                </v-btn>
                            </template>
                            <v-card min-width="350" min-height="90" class="pa-1">
                                <span
                                class="text-h4 font-weight-light px-2"
                                v-text="limitScanDistance+'km'"
                            ></span>
                                <v-slider
                                    hide-details
                                    hide-spin-buttons
                                    :color="colorlimit"
                                    always-dirty
                                    track-color="grey"
                                    v-model="limitScanDistance"
                                    max="15"
                                    min="1"
                                    @end="reload()"
                                    >
                                    <!-- <template v-slot:prepend>
                                    <v-icon
                                        :color="colorlimit"
                                        @click="limitScanDistance--"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    </template>

                                    <template v-slot:append>
                                    <v-icon
                                        :color="colorlimit"
                                        @click="limitScanDistance++"
                                    >
                                        mdi-plus
                                    </v-icon>
                                    </template> -->
                                </v-slider>
                            </v-card>
                        </v-menu>
                    </v-col>                             
                </v-row>
            </v-card-actions>   
            <v-row class="ma-0" style="height:100%">
                <ColorBarConfig :dataKey="currentType.value" ref="colorBar" class="mapColorBarConfig" @update="configColorBar"/>                
                <!-- map -->
                <div ref="map" class="map noevent">                            
                </div>

            </v-row>
            <v-row class="ma-0 pa-0 hotChart" :style="`height:${height}px;width:${width}px`">
                <!-- echart -->
                <v-card elevation="0" class="width100 height100 d-flex flex-column">
                    <v-row class="ma-0" no-gutters>
                        <v-col>
                            <v-card-subtitle class="ma-0 pa-2 d-flex">{{currentType.name}}热图</v-card-subtitle> 
                        </v-col>                        
                        <v-col cols="1" class="d-flex align-start flex-grow-0 flex-shrink-0 pointer">
                            <v-icon  class="ma-2" @mousedown.stop="resize">mdi-arrow-all</v-icon>
                        </v-col>            
                    </v-row>
                    <v-row class="ma-0 height100">
                        <v-overlay
                            absolute
                            :value="overlay"
                            >
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>
                        <v-overlay
                            class="ma-3 mt-8"
                                absolute
                                :value="noData"
                                opacity="0.2"
                                >
                            暂无数据
                            </v-overlay>
                        <div ref="echart" class="width100 height100"></div>
                    </v-row>                    
                </v-card>
            </v-row>
            <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
            <!-- 环境数据 -->
            <draggable class="externalDatasBox" tag="div" :scroll="true" handle=".move" animation="500">
                <div class="track_box" v-for="i,_index in showExternalTypes" :key="_index"> 
                    <!-- echart  -->
                    <ExternalDataChart v-if="i.key.indexOf('chart') >=0 && !i.close" :keyV="i.value" @close="i.close = true;showExternalTypeBtns = showExternalTypeBtns.filter(a=> { return a!=i})"/>
                    <!-- map -->
                    <ExternalDataMap v-if="i.key.indexOf('map') >=0 && !i.close" :keyV="i.value" @close="i.close = true;showExternalTypeBtns = showExternalTypeBtns.filter(a=> { return a!=i})"/>
                </div>
            </draggable>
        </v-card>
    </v-container>
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import Plotly from 'plotly.js-dist'
import Draggable from "vuedraggable"
import ExternalDataChart from '@/components/monitoring/agt/ExternalDataChart'
import ExternalDataMap from '@/components/monitoring/agt/ExternalDataMap'
import { getAgtInfosApi,getAgtDetailApi} from '@/api'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'

export default {
    components:{Draggable,ExternalDataMap,ExternalDataChart,ColorBarConfig,monitorPointDataCard},
    name:"monitoring.agt.history",
    data(){
        return {
            overlay:false,
            map:null,
            mapEchart:null,
            mapContextMenuPositon:[],           
            center:[],

            timer:null,

            width:500,
            height:240,

            //时间
            dateMenu:false,
            dateRange:[],

            //批次
            agtList:[],
            page:1,
            rows:10,
            total:0,
            currentAgt:{
                name:"暂无走航数据"
            },

            timeLoad:null, //防重复加载

            //数据类型 雷达数据类型
            scanMapTypes:[],
            currentType:{},

            //数据类型 设备数据 
            ExternalDataMapTypes:[],
            showExternalTypeBtns:[],
            showExternalTypes:[],  
            
            //雷达数据
            lidarDatas:[],

            //画图相关
            timing:null,//定时器
            object3Dlayer_mains:[],
            scale:10,//缩放比例,
            distance:100,//查看高度限制
            opacity:0.7,
            monitorPointMarkers:[],    //国控点
            limitScanDistance:3,//限制展示距离
            src:""
        }
    },
    computed:{  
        colorlimit() {
            if (this.limitScanDistance < 3) return 'indigo'
            if (this.limitScanDistance < 6) return 'teal'
            if (this.limitScanDistance < 9) return 'green'
            if (this.limitScanDistance < 12) return 'orange'
            return 'red'
        },      
        noData(){
            return !this.lidarDatas|| this.lidarDatas?.length == 0
        },
    },
    beforeDestroy(){
        if(this.timing){
            clearInterval(this.timing)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.map);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }          
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                var that = this; 
                this.lidarDatas = [];
                if(!this.$refs.map){
                    return;
                }
                this.$nextTick(()=>{            
                    that.load();
                })                          
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },
        dateRange(n){
            if(n && n.length == 2){                
                this.agtList = [];
                this.page=1;
                this.loadAgt();        
            }
            if(!n){
                this.agtList = [];
                this.page=1;
                this.loadAgt(); 
            }                        
        },
        currentType(n){
            var that = this;
            this.$nextTick(()=>{            
                that.loadData()
            })            
        },
        currentAgt(n){            
            this.lidarDatas = [];
            if(n.DaiAgtId){                                
                this.$store.commit("storage_agt/updateAgtId",n.DaiAgtId,{root:true})                
                this.loadData()
            }            
        }
    },
    mounted(){
        if(this.$store.state.storage_global.currentSite.siteId){              
            var that = this; 
            this.$nextTick(()=>{            
                that.load();
            })
        }           
    },
    methods:{
        load(){
            //加载地图    
            this.loadType();
            this.loadMap();
            this.loadAgt();                    
        },
        loadType(){            
            let permissionStr = this.$store.state.storage_user.role.permission;
            var permission = JSON.parse(permissionStr);            
            let currentWindow = permission.menu.client.find(a=>{
                return a.key == 'monitoring.agt';
            })?.children.find(a=>{
                return a.key =="agt.history";
            })?.children.find(a=>{return a.key == "agt.detailMap"})
            if(!currentWindow){
                currentWindow = {
                    children:[{
                        key:'undefined',
                        value:"undefined"                        
                    }]
                }
            }             
            this.scanMapTypes = currentWindow?.children?.map(a=>{
                return {
                    key:a.key,
                    value:a.value,
                    name:this.$i18n.t("agt.dataType."+a.value)
                }
            })    
            this.currentType = this.scanMapTypes[0]  
            //设备数据
            let ExternalDataMapTypes =  permission.menu.client.find(a=>{
                return a.key == 'monitoring.agt';
            })?.children.find(a=>{
                return a.key =="agt.history";
            })?.children.find(a=>{return a.key == "agt.externalMap"})
            this.ExternalDataMapTypes = ExternalDataMapTypes?.children?.map(a=>{
                return {
                    key:a.key,
                    value:a.value,
                    name:this.$i18n.t("agt.dataType."+a.key),
                    children:[
                        {
                            key:a.key+'echart',
                            value:a.value,
                            name:this.$i18n.t("agt.dataType.echart"),
                            close:true
                        },                        
                        {
                            key:a.key+'map',
                            value:a.value,
                            name:this.$i18n.t("agt.dataType.map"),
                            close:true
                        }
                    ]
                }
            })            
        },
        loadMap(){
            let target = this.$refs.map;                      
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    //center: mapcenter,
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;        
             if(!this.map){
                this.map = mapEchart.getModel().getComponent('amap').getAMap();
                //添加右键菜单
                this.addContextMenu();
                //加载控件 
                this.addControl(); 
            }  
            this.map = mapEchart.getModel().getComponent('amap').getAMap();                                       
            //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl();    
            //加载国控点
            this.loadMonitPoint()                                           
        }, 
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //添加一个缩放控件
                // map.addControl(new BasicControl.Zoom({
                //     theme:"dark",
                //     position: 'lb'
                // }));
        
                //缩放控件，显示Zoom值
                // that.map.addControl(new BasicControl.Zoom({
                //     position: config.zoom.position,
                //     theme:"myself",
                //     showZoomNum: true
                // }));
                
                //比例尺
                var scale = new AMap.Scale(
                    {
                        position: [{
                            top: '110px',
                            right: '40px'
                        }]                  
                    }
                );
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '380px',left: '50px' },
                    theme:'myself'
                }));

                //3d指南针控件
                var controlBar = new AMap.ControlBar({
                    position:{bottom: '345px',left: '-70px' },
                    theme:'myself'
                });
                that.map.addControl(controlBar) 
                
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:320px;left:50px;';                      
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min = "1" max = "10" value = "${7}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>7</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")               
                _rest.addEventListener('click',(e)=>{                                                                          
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    that.loadData()                    
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;   
                    that.loadData()                    
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);  

                //地点定位                
                let styleP = 'cursor:pointer;bottom:80px;left:55px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]); 
            })    
        },
         addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                   
            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },
        //批次滚动
        scroll(e){            
            if(e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight){                
                if(this.agtList.length < this.total){
                    openNotice({
                        type:"error",
                        text:this.$i18n.t("agt.notice.agtEnd")
                    })
                }else{
                    this.page +=1;
                    this.loadAgt();
                }
            }
        },
        loadAgt(){
            var that = this;
            if(this.dateRange == null){
                this.dateRange = [];
            }
            let start = this.dateRange[0] 
            let end = this.dateRange[1]  
            let site = this.$store.state.storage_global.currentSite;
            //加载走航批次
            getAgtInfosApi({
                id:site.siteId,
                params:{
                    StartTime:start,
                    EndTime:end,
                    PageIndex:this.page,
                    PageSize:this.rows
                },success:(d)=>{                      
                    that.total = d.Total;                 
                    that.agtList = that.agtList.concat(d.Data)    
                    if(that.agtList.length > 0){
                        if(!that.currentAgt.DaiAgtId){
                            that.currentAgt = that.agtList[0]                       
                            that.loadData()
                        }                        
                    }else{
                        that.currentAgt = {
                            DaiName:"暂无走航数据"
                        }
                    }                                        
                }
            })
        },
        //加载走航雷达数据 
        loadData(){            
            var that = this;            
            if(this.timeLoad){
                clearTimeout(that.timeLoad)
            }            
            this.timeLoad = setTimeout(() => {
                //清除残留                            
                that.clearLayer();
                that.loadLidarData();                  
            }, 100);  
        },
        loadLidarData(page=1,data=[],endPoints=[],time){
            this.overlay = true;
            let site = this.$store.state.storage_global.currentSite;
            let type = this.currentType.value
            let size = 1000;
            var that = this;
            getAgtDetailApi({
                id:site.siteId,
                agtId:that.currentAgt.DaiAgtId,
                params:{
                    DadTypeId:type,
                    PageSize:size,
                    LastDate:time
                },
                success:(d)=>{
                    d.Data = $help.isNull(d.Data) ? []:d.Data;
                    var list = data.concat(d.Data);        
                    if(list.length < d.Total){
                        //未完全加载                        
                        // .sort((a,b)=>{
                        //     return a.DadTime < b.DadTime ? 1 : -1;
                        // });
                        let endPoint = {
                            index:page,
                            end:d.Data[d.Data.length -1],
                            start:d.Data[0]
                        } 
                        endPoints.push(endPoint); 
                        that.loadLidarData(page+1,list,endPoints,d.Data[d.Data.length -1].DadTime)    
                        //加载地图  
                        that.toPath(d.Data.reverse());
                    }else{  
                        this.overlay = false;
                        if(d.Data.length > 0){
                            that.distance = d.Data[0].DadValue1.length
                            //加载地图
                            that.toPath(d.Data.reverse());
                        }
                        let endPoint = {
                            index:page,
                            start:d.Data[0],
                            end:d.Data[d.Data.length -1]
                        }  
                        endPoints.push(endPoint) 
                        if(list.length > 0){
                            //移动到起始地点
                        }

                        endPoints.sort((a,b)=>{
                            return b.index - a.index;
                        })                    
                        for(var i in endPoints){
                            if(endPoints[i].index == 0 || endPoints[i].index == 1){                                
                            }
                            else{
                                //加载地图断点
                                that.toPath([endPoints[i].end,endPoints[parseInt(i)+1].start]);
                            }                        
                        } 
                        //加载时间热图
                        that.lidarDatas = list.map(a=>{
                            a.DadTime = a.DadTime.replace('T',' ')
                            return a;
                        });                                                
                        that.loadChart() 
                        //设置点位
                        if(list.length > 0){
                            that.map.setCenter([list[0].DadLng,list[0].DadLat])
                        }                        
                    }
                    if(d.Data.length == 0){
                        that.overlay = false;
                    }
                }
            })
        },
        async loadChart(){  
            let that = this;                 
            let target = this.$refs.echart  
            if(!target){
                return;
            }
            if(this.lidarDatas.length == 0){
                var data = [ 
                {            
                y: [],
                x: [],
                z: [],               
                type: 'heatmap',           
                showscale: true,                
                }
                ];
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {  
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,
                    legend: {
                        orientation:"h",
                        x: 1,
                        xanchor: 'right',
                        y: 1,
                        font:{
                            color: fontColor
                        }
                    },
                    xaxis: {
                        color:fontColor,
                        nticks:6
                    },
                    yaxis: {     
                        color:fontColor,  
                        title:"距离(km)"    
                    },           
                margin:{
                    t:0,
                    r:42,
                    l:42, 
                }
                };
                Plotly.newPlot(target, data, layout, {responsive: true});   
                return;
            }           
            //加载雷达数据图表（时间热图）
            let _datas =[]; 
            let minSpan = 1;       
            for(let i in this.lidarDatas.sort((a,b)=>new Date(b.DadTime).getTime() - new Date(a.DadTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.lidarDatas[i].dataTime).getTime() - new Date(this.lidarDatas[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){                                           
                            j+=minSpan;                       
                            _datas.push({   
                                DadAgtId: this.lidarDatas[i].DadAgtId,
                                DadCarId: this.lidarDatas[i].DadCarId,
                                DadDis:this.lidarDatas[i].DadDis, 
                                DadDisType: this.lidarDatas[i].DadDisType, 
                                DadLat: this.lidarDatas[i].DadLat, 
                                DadLng:  this.lidarDatas[i].DadLng, 
                                DadPitch: this.lidarDatas[i].DadPitch,
                                DadTime: new Date(new Date(this.lidarDatas[i].DadTime).setMinutes(new Date(this.lidarDatas[i].DadTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                DadTypeId: this.lidarDatas[i].DadTypeId,
                                DadValue1: Array.from({length:this.lidarDatas[i].DadValue1.length},(val, i) => NaN),
                                DadValue2: Array.from({length:this.lidarDatas[i].DadValue2.length},(val, i) => NaN)
                            })   
                        }
                    }
                }
            }
            _datas = _datas.concat(this.lidarDatas)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.DadTime).getTime() - new Date(b.DadTime).getTime()
            });
            let distance = this.limitScanDistance / datas[0].DadDis[1]; 
            let nodataDis = -datas[0].DadDis[0] / datas[0].DadDis[1]
            distance += nodataDis;            
            distance = distance > datas[0].DadValue1.length ? datas[0].DadValue1.length:distance;            

            let yData = [];               
            yData = [];       
            for(let i = nodataDis;i< datas[0].DadDis[2];i++){                
                let y = datas[0].DadDis[0] + i* datas[0].DadDis[1]
                yData.push(y.toFixed(2))
            }   
                   
            let xData = []; 
            let zData = [];
            let hoverText = [];
            datas.forEach((a,i)=>{                                            
                let time = new Date(a.DadTime);
                xData.push(time)
                for(var j=nodataDis;j< distance;j++){                    
                    let _index = j - nodataDis;
                    if(!zData[_index]){
                        zData[_index] = [];
                        hoverText[_index] = []
                    }
                    zData[_index].push(a.DadValue1[j])
                    hoverText[_index].push(`经纬度：${a.DadLng?.toFixed(6)},${a.DadLat?.toFixed(6)}<br />监控值：${a.DadValue1[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.DadTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });                        
           
            var data = [ 
            {            
            y: yData,
            x: xData,
            z: zData,
            colorscale:this.$refs.colorBar.colorScaleValue,
            zmin:this.$refs.colorBar.rangeInfo[0].min,
            zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max,
            type: 'heatmap',           
            showscale: false    ,
            hovertext:hoverText,
            }
            ];
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {  
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1,
                    font:{
                        color: fontColor
                    }
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor,5,10,5)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                xaxis: {
                    color:fontColor,
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:6
                },
                yaxis: {     
                    color:fontColor,  
                    title:"距离(km)"    
                },           
            margin:{
                t:0,
                r:42,
                l:42,
                b:38 
            }
            };
            Plotly.newPlot(target, data, layout, {responsive: true});       
        },
        //加载设备数据
        showExternal(item){
            let _item = this.showExternalTypes.find(a=>{
                return a.key == item.key
            })
            if(!_item){
                this.showExternalTypes.push(item)
            }
            item.close = !item.close;
        },
        resize(){            
            var that = this;
            let targetW = this.$refs.continer.clientWidth;
            let targetH = this.$refs.continer.clientHeight;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = that.width + e.movementX ;
                let resetH = that.height - e.movementY
                that.width = resetW >targetW ? targetW : resetW < 370 ? 370 : resetW;
                that.height = resetH > targetH ? targetH : resetH < 240 ?240 : resetH;
                localStorage.setItem("agtChartHeight",that.height)
                localStorage.setItem("agtChartWidth",that.width)                            
            }
            document.addEventListener('mousemove',fun)
            let fun2 = ()=>{
                if(that.timer){
                    clearTimeout(timer)
                }
                that.timer = setTimeout(() => {
                    that.loadChart()                    
                }, 1000); 
                document.removeEventListener('mousemove',fun) 
                document.removeEventListener('mouseup',fun2)
            }
            document.addEventListener('mouseup',fun2);

        },
        configColorBar(item){
            this.loadData()           
        },
        clearLayer(){
            for(let i of this.object3Dlayer_mains){
                //目前只有一个layer 先清除
                i.obj.clear();
            }   
        },
        reload(){
            this.clearLayer();
            this.toPath(this.lidarDatas);
            this.loadChart()
        },
        async toPath(datas){     
            if(!this.map || !this.$refs.colorBar){
                return
            }
            let scale = this.scale;
            let distance = Math.floor(this.limitScanDistance / datas[0].DadDis[1]); 
            let nodataDis = -datas[0].DadDis[0] / datas[0].DadDis[1]
            distance += nodataDis;
            var that = this;                   
            distance = distance > datas[0].DadValue1.length ? datas[0].DadValue1.length:distance;           
            //路径                    
            //高德地图像素和米转换比例  z向下 向上为负数
            var miter = -1 / 0.149;
            var z = scale * miter ; //scale为倍数，miter为像素和米的比例 z即为高度
            //路径点        
            let pathXY = datas.map(a=>{
                var xy = that.map.lngLatToGeodeticCoord(new AMap.LngLat(a.DadLng,a.DadLat))
                return xy;
            })
            //let verticesLength = distance * 2 ;                                    
            let i = 0;
            let object3Dlayer;
            let entity = that.object3Dlayer_mains.find(a=>{ return a.key ==datas[0].DadCarId});
            if(!entity){
                object3Dlayer = new AMap.Object3DLayer();            
                that.map.add(object3Dlayer);
                that.object3Dlayer_mains.push({
                    key:datas[0].DadCarId,
                    obj:object3Dlayer
                })
            }
            else{
                object3Dlayer = entity.obj
            }
            let timing = setInterval(()=>{
                    if(i >= pathXY.length -1 || !that.map || !that.$refs.colorBar){
                        clearInterval(timing)
                    }                
                    let cylinder = new AMap.Object3D.Mesh();
                    var geometry = cylinder.geometry;
                    for(let k = 0;k<100;k++){                                                      
                        let _point = pathXY[i];
                        let _npoin = pathXY[i+1]                     
                        if(i >= pathXY.length -1){
                            break;
                        }                        
                        let _ptime = new Date(datas[i].DadTime.replace("T"," "))
                        let _ntime = new Date(datas[i+1].DadTime.replace("T"," "))
                        var dateDiff = _ntime.getTime() - _ptime.getTime();
                        var minDiff = Math.floor(dateDiff / (60 * 1000));//计算出相差分钟
                        if(Math.abs(minDiff) >= 10){
                            i+=1 
                            continue;
                        }                        
                        let arr = datas[i].DadValue1;                             
                        for(let j = 0;j< distance;j++){                            
                            if(!_npoin){
                                break;
                            }
                            let _z = z * j;                                        
                            geometry.vertices.push(_point.x, _point.y, _z,_npoin.x, _npoin.y, _z);//V0
                            // geometry.vertices.push();//V0                  
                            let val = arr[j+nodataDis]
                            that.$refs.colorBar?.getRgb(val,(rgb)=>{
                                if(!rgb){
                                    return;
                                }         
                                let _a = that.opacity ?that.opacity : 0.7 ; 
                                let color = [Number((Number(rgb[0])/255).toFixed(2)),Number((Number(rgb[1])/255).toFixed(2)),Number((Number(rgb[2])/255).toFixed(2)),_a]              
                                geometry.vertexColors.push.apply(geometry.vertexColors, [color[0],color[1],color[2],color[3],color[0],color[1],color[2],color[3]]); //底部颜色                                    
                                let bottomIndex =j* 2+(distance)*2 * k;
                                if(bottomIndex >= ((k+1)*distance*2-2)){
                                }
                                else{                            
                                    let topIndex = bottomIndex + 1;
                                    let nextBottomIndex =bottomIndex +2 ;
                                    let nextTopIndex =bottomIndex+ 3;
                                    geometry.faces.push(bottomIndex, topIndex, nextTopIndex,bottomIndex, nextBottomIndex,nextTopIndex); //侧面三角形1
                                    //geometry.faces.push(bottomIndex, nextBottomIndex,nextTopIndex); //侧面三角形2                
                                }        
                            })                                                                    
                        }  
                        i+=1 
                    }            
                    cylinder.backOrFront = 'both';
                    cylinder.transparent = true; // 如果使用了透明颜色，请设置true
                    object3Dlayer.add(cylinder);                
                    object3Dlayer.reDraw();                
                    
                },1000/30)   
                                    
        },
             
    }
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.map{
    position: relative;
    width: 100%;
    height: 100%;
}
.mapToolbar{
    position: absolute;
    z-index: 1;
    width: 100%;
}
.agtName{
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.agtNameBtn{
    width: 100%;
    :deep(.v-btn__content){
        width: 100%;
        overflow: hidden;
        white-space: normal;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
    }    
}
.externalDatasBox{    
    position: absolute;
    top:0;
    right:50px;  
    overflow: auto;
    max-height: 100%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
.track_box{
  position :relative;
}
.hotChart{
    width: 600px;
    bottom: 0;
    z-index:150;
    position: absolute;
}
.mapColorBarConfig{
    position: absolute;
    top: 10%;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 50%;
}
//比例尺
.map :deep(.amap-scalecontrol){
    bottom:280px;
    left:45px;
}
:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
  li:hover{
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    border-radius: 5px !important;
  }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 80px;
}

</style>
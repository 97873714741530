import { render, staticRenderFns } from "./prrHeatmap.vue?vue&type=template&id=f1fe7a8e&scoped=true"
import script from "./prrHeatmap.vue?vue&type=script&lang=js"
export * from "./prrHeatmap.vue?vue&type=script&lang=js"
import style0 from "./prrHeatmap.vue?vue&type=style&index=0&id=f1fe7a8e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1fe7a8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardTitle,VContainer,VOverlay,VProgressCircular})

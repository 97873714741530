<template>
    <v-container style="max-width: 100%;position: relative;overflow: hidden;" class="width100 height100">       
        <v-row class="rowHeight pa-0 ma-0" v-show="currentChart == 5">
            <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mb-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
            <v-card width="100%" height="100%" style="position: relative;">
                <v-card-title class="ma-0 pa-1 heatmapTitle">{{ currentSite.siteName }}</v-card-title>             
                <div ref="barbs" class="barbsChart plotychart height100">                    
                </div>                
            </v-card>
        </v-row>       
        <v-row class="rowHeight pa-0 ma-0" v-show="currentChart == 6 || currentChart == 7|| currentChart == 8">  
            <v-overlay
                absolute
                :value="overlay"
                >
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-overlay
                class="ma-3 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
                暂无数据
            </v-overlay>        
            <v-card width="100%" height="100%" style="position: relative;">
                <v-btn v-show="currentChart == 6 && false" text @click.stop="heatBarbs = !heatBarbs;loadHeatmapPitch();" color="primary" style="position: absolute;right: 200px;z-index: 1;" >{{heatBarbs?'关闭风羽':'叠加风羽'}}</v-btn> 
                <v-card-title  v-show="currentChart == 6" class="ma-0 pa-1 heatmapTitle">{{ currentSite.siteName }}</v-card-title>  
                <v-card-title v-show="currentChart == 7" class="ma-0 pa-1 heatmapTitle">{{ currentSite.siteName }}</v-card-title>             
                <v-card-title v-show="currentChart == 8" class="ma-0 pa-1 heatmapTitle">{{ currentSite.siteName }}</v-card-title>             
                <ColorBarConfig @update="timerDraw" :dataKey="dataKey" ref="colorBar" class="mapColorBarConfig"/>                      
                <div ref="heatmap" style="width:calc(100% - 10px)" class="height100 "></div>
            </v-card>
        </v-row>                     
    </v-container>
</template>
<script>
import { getWlsProfileApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import * as htmlToImage from 'html-to-image';
import barbs from '@/plugins/helper/D3DrawHelper/barbs.js'
import barbsSvg from '@/plugins/helper/D3DrawHelper/barbsSvg.js'
export default {
    name:'wls.realtime.profile',
    props:{
        currentSite:{
            type:Object
        },
        currentView:{
            type:Number,
            default:5
        },
        timeRange:{
            type:Array
        },
        currentViewMode:{
            type:Object            
        }
    },
    components: {
        ColorBarConfig
      },
    data(){
        return {
            heatBarbs:false,
            overlay:false,
            barbsLevel:[{
                level:1,
                color:''
            },{
                level:2,
                color:''
            },{
                level:4,
                color:''
            },{
                level:6,
                color:''
            },{
                level:8,
                color:''
            },{
                level:10,
                color:''
            },{
                level:12,
                color:''
            },{
                level:14,
                color:''
            },{
                level:16,
                color:''
            },{
                level:18,
                color:''
            },{
                level:20,
                color:''
            },{
                level:22,
                color:''
            },{
                level:24,
                color:''
            }],
            dataList:[],
            barbsScale:1,
            currentWatch:null,
            timer:null,
            currentChart:5,
            timerReDraw:null,
            scalex:1,
            scaley:1,
            xBN:26,//x轴标准系数
            yBN:35
        }
    },computed:{
        dataKey(){
            return this.currentChart == 6 ? 531:this.currentChart == 7? 530:532
        },
        barbsHtml(){
            return (speed = 1,angle = 90,color='black')=>{
                if(speed > 1 && speed <= 2){
                    color = '#ea80fc'
                }else if(speed > 2 && speed <= 4){
                    color = '#b75df5'
                }else if(speed > 4 && speed <= 6){
                    color = '#3220dc'
                }else if(speed > 6 && speed <= 8){
                    color = '#5efdfd'
                }else if(speed > 8 && speed <= 10){
                    color = '#23fe23'
                }else if(speed > 10 && speed <= 12){
                    color = '#6EAB42'
                }else if(speed > 12 && speed <= 14){
                    color = '#6EAB42' //FFF73E
                }else if(speed > 14 && speed <= 16){
                    color = '#FFF73E'//E4B16B
                }else if(speed > 16 && speed <= 18){
                    color = '#FFF73E'//FD5555
                }else if(speed > 18 && speed <= 20){
                    color = '#FD5555'//EF2D4E
                }else if(speed > 20 && speed <= 22){
                    color = '#FD5555'//72352B
                }else if(speed > 22 && speed <= 24){
                    color = '#969696'
                }else{
                    color = '#bd1084'
                }
                let windBarb = new barbsSvg(
                    speed * 5/2  /* wind speed in knots */,
                    angle /* wind direction angle */,      
                {
                    size:{
                        height:12,
                        width:28
                    },
                        triangle:{
                        fill:color,
                        stroke:color
                        },
                        circle:{
                        fill:color,
                        stroke:color
                        },
                        bar:{
                        stroke:color
                        }         
                    }
                    ).draw();        
                return windBarb.outerHTML     
            }             
        },
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        }
    },
    watch:{
        currentSite:{
            handler(n,v){  
                this.dataList = [] 
                this.load()   
            }
        },
        timeRange(){
            this.load()
        },
        currentView(n){
            this.currentChart = n;
            let that = this;
            setTimeout(() => {
                if(that.currentChart == 5){                        
                    that.loadBarbs()
                }                   
                if(that.currentChart == 6){
                    that.loadHeatmapPitch()
                }   
                if(that.currentChart == 7){
                    that.loadHeatmapStraight()
                }   
                if(that.currentChart == 8){
                    that.loadHeatmapStraightAngle()
                }    
            }, 200);            
        },
        currentViewMode(){
            this.load()
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                let that = this;
                setTimeout(() => {
                    if(that.currentChart == 5){                        
                        that.loadBarbs()
                    }                   
                    if(that.currentChart == 6){
                        that.loadHeatmapPitch()
                    }   
                    if(that.currentChart == 7){
                        that.loadHeatmapStraight()
                    }   
                    if(that.currentChart == 8){
                        that.loadHeatmapStraightAngle()
                    }    
                }, 200);       
            }
        },    
    },
    mounted(){
        var that = this;
        this.currentChart = this.currentView        
        this.xBN = Math.floor(26 * this.$el.clientWidth / 1664)
        this.yBN = Math.floor(35 * this.$el.clientHeight / 759)
        setTimeout(() => {
            that.load()
        }, 600);        
    },
    methods:{  
        reload(){
            let that = this;
            setTimeout(() => {
                if(that.currentChart == 5){                        
                    that.loadBarbs()
                }                   
                if(that.currentChart == 6){
                    that.loadHeatmapPitch()
                }   
                if(that.currentChart == 7){
                    that.loadHeatmapStraight()
                }   
                if(that.currentChart == 8){
                    that.loadHeatmapStraightAngle()
                }    
            }, 200);  
        },
        async load(start,end,datas=[]){
            var that = this;
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }
            this.overlay = true;
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            start =start ? start: this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            end =end ? end: this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") 
            // let endTime =new Date(new Date().setTime(new Date(start).getTime() + 1000 * 60 * 60 *5)) 
            // let endTimeStr = endTime.getTime() > new Date(end).getTime() ? end:endTime.pattern("yyyy-MM-dd HH:mm:ss")
            getWlsProfileApi({
                id:this.currentSite.siteId,
                params:{
                    StartTime:start,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    EndTime:end,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    PageSize:2500, 
                    ScanMode:this.currentViewMode.text == "三轴" ? 3:4, 
                },
                success:(d)=>{
                    that.overlay = false;                     
                    if(!d.Data || d.Data.length == 0)  {
                        that.dataList = []
                        if(that.currentChart == 5){
                            that.loadBarbs()
                        }                   
                        if(that.currentChart == 6){
                            that.loadHeatmapPitch()
                        }      
                        if(that.currentChart == 7){
                            that.loadHeatmapStraight()
                        }   
                        if(that.currentChart == 8){
                            that.loadHeatmapStraightAngle()
                        }  
                        that.$emit('update',that.dataList)         
                    }   
                    let _datas = d.Data?.map(a=>{
                        return {
                            siteId:a.DwpSiteId,
                            dataTime:a.DwpTime.replace('T',' '),
                            height:a.DwpHeight,
                            period:a.DwpPeriod.split('_')[0],
                            yaw:Number(a.DwpPeriod.split('_')[1]),
                            scanMode:scanType[a.DwpScanMode],
                            pitchValue:a.DwpPitchValue,
                            straightAngle:a.DwpStraightAngle,
                            straightValue:a.DwpStraightValue
                        }
                    })     
                    datas = _datas.concat(datas)           
                    if(end == end){     
                        that.dataList = datas.map(a=>{
                            let index = a.height.findIndex(b=>b==0)
                            a.height = a.height.slice(index + 1)
                            a.pitchValue = a.pitchValue.slice(index + 1)
                            a.straightAngle = a.straightAngle.slice(index + 1) 
                            a.straightValue = a.straightValue.slice(index + 1)
                            return a;
                        });                          
                        that.lineData = that.dataList[that.dataList.length -1]           
                        if(that.currentChart == 5){
                            that.loadBarbs()
                        }                   
                        if(that.currentChart == 6){
                            that.loadHeatmapPitch()
                        }   
                        if(that.currentChart == 7){
                            that.loadHeatmapStraight()
                        }   
                        if(that.currentChart == 8){
                            that.loadHeatmapStraightAngle()
                        }     
                        that.$emit('update',that.dataList)     
                    }else{
                        that.dataList = datas.map(a=>{
                            let index = a.height.findIndex(b=>b==0)
                            a.height = a.height.slice(index + 1)
                            a.pitchValue = a.pitchValue.slice(index + 1)
                            a.straightAngle = a.straightAngle.slice(index + 1) 
                            a.straightValue = a.straightValue.slice(index + 1)
                            return a;
                        });
                        if(that.currentChart == 5){
                            that.loadBarbs()
                        }                   
                        if(that.currentChart == 6){
                            that.loadHeatmapPitch()
                        }    
                        if(that.currentChart == 7){
                            that.loadHeatmapStraight()
                        }    
                        if(that.currentChart == 8){
                            that.loadHeatmapStraightAngle()
                        }        
                        that.load(endTimeStr,end,datas) 
                    }
                }
            })
        },
        timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                if(that.currentChart == 7){
                    that.loadHeatmapStraight()
                }   
                if(that.currentChart == 6){
                    that.loadHeatmapPitch()
                }  
                if(that.currentChart == 8){
                    that.loadHeatmapStraightAngle()
                }                    
            }, 1000/3);
        },
        loadHeatmapPitch(){
            let that = this;
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,  
                        uirevision:'true',                              
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)  
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"距离(km)"
                        },
                        modebar:{
                            add:[{
                                name: 'download image',
                                icon: Plotly.Icons.camera,
                                direction: 'download',
                                click: function(gd,event) {
                                    let w = gd.clientWidth;
                                    let h = gd.clientHeight;
                                    htmlToImage.toPng(target, { })
                                    .then(function (dataUrl) {
                                        /* do something */
                                        $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                                    });                        
                                }
                            }],
                            remove:['toimage','select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,       
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }

            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;      
            for(let i in dataList){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
            let customData = [];  
            
            let xDatab = [];   
            let yDatab = [];
            let ym = this.yBN; let xm = this.xBN;
            let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);            
            //降采样y
            for(var i in datas){
                //对y轴降采样
                datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
            }
            //降采样 x
            let xSampling =Math.floor(datas.length / xm); 
            xSampling = xSampling < 1 ? 1:xSampling
      
            datas.forEach((a,i)=>{                                     
                let t = new Date(a.dataTime);                                     
                xData.push(t)
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.pitchValue[j]))
                    let _angle = Number(a.straightAngle[j] == 'NaN' ? '' :a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw);                
                    hoverText[j].push(`垂直风速：${a.pitchValue[j]}<br />水平风速：${a.straightValue[j]}<br />角度：${_angle.toFixed(2)}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                    if(this.heatBarbs && this.currentChart == 6){
                        if(a.straightAngle[j] == 'NaN' || a.straightValue[j] == 'NaN'){
                     
                        }else{                            
                            if(a.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                                xDatab.push(t)   
                                yDatab.push(datas[0].height[j])  
                                customData.push({
                                    angle:a.straightAngle[j],
                                    speed:a.straightValue[j],
                                })      
                            }                                                           
                        }
                    }
                }                       
            });              
            
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];
            if(this.heatBarbs && this.currentChart == 6){
                data.push({
                    name:'',
                    type: 'scatter', 
                    mode:'markers',
                    marker:{
                        size:0.01
                    },             
                    x: xDatab,
                    y: yDatab,    
                    hovertext:customData.map(a=>`角度：${a.angle},风速：${a.speed?.toFixed(2)}`),
                    customdata:customData,
                    showscale: false,            
                })
            }

            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                               
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            // barbs(target)  
            // let raby = (target.layout.yaxis.range[1] - target.layout.yaxis.range[0])   
            // let rabx = (new Date(target.layout.xaxis.range[1]).getTime() - new Date(target.layout.xaxis.range[0]).getTime())   
            // target.on('plotly_relayout', (a,b,c)=>{
            //     if(that.heatBarbs && that.currentChart == 6){
            //         that.reDraw(datas,a,target,layout,raby,rabx,[ {
            //         y: yData,
            //         x: xData,
            //         z: zData,
            //         type: 'heatmap',           
            //         showscale: false,
            //         hovertext:hoverText,
            //         colorscale:that.$refs.colorBar.colorScaleValue,
            //         zmin:that.$refs.colorBar.rangeInfo[0]?.min,
            //         zmax:that.$refs.colorBar.rangeInfo[that.$refs.colorBar.rangeInfo.length-1]?.max
            //         }])
            //     }                                    
            // })   
        },  
        loadHeatmapStraight(){
            let that = this;
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,  
                        uirevision:'true',                              
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)  
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"距离(km)"
                        },
                        modebar:{
                            add:[],
                            remove:['select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,       
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }

            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;    
            for(let i in dataList){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
      
            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.straightValue[j]))
                    let _angle = Number(a.straightAngle[j] == 'NaN' ? '' :a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw);                
                    hoverText[j].push(`水平风速：${a.straightValue[j]}<br />垂直风速：${a.pitchValue[j]}<br />距离：${yData[j]}<br />角度：${_angle.toFixed(2)}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });              
            
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                             
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            htmlToImage.toPng(target, { })
                            .then(function (dataUrl) {
                                /* do something */
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            });                              
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
        },          
        loadHeatmapStraightAngle(){
            let that = this;
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(!this.dataList|| this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,                                
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"角度°"
                        },
                        modebar:{
                            add:[{
                                name: 'download image',
                                icon: Plotly.Icons.camera,
                                direction: 'download',
                                click: function(gd,event) {
                                    let w = gd.clientWidth;
                                    let h = gd.clientHeight;
                                    Plotly.toImage(gd).then(function(dataUrl) {                                
                                        $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                                    })                           
                                }
                            }],
                            remove:['toimage','select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }
            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;  
            for(let i in dataList){                                
                if(i > 0){                    
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;                        
                        while(j<span){                          
                            j+=minSpan;
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                          
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = datas[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    let _angle = Number(a.straightAngle[j] == 'NaN' ? '' :a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw);                
                    zData[j].push(_angle.toFixed(2))
                    hoverText[j].push(`角度：${_angle.toFixed(2)}<br />水平风速：${a.straightValue[j]}<br />垂直风速：${a.pitchValue[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });               
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                                
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)
                },
                yaxis: {
                    color:fontColor,  
                    title:"距离(km)"
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            htmlToImage.toPng(target, { })
                            .then(function (dataUrl) {
                                /* do something */
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            });                               
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
        },
        loadBarbs(){               
            var that = this;
            let target = this.$refs.barbs;   
            if( !target){
                return;
            }
            if(this.dataList.length == 0){
                var data = [{
                    name:'',
                    type: 'scatter', 
                    mode:'markers',
                    marker:{
                        size:0.01
                    },             
                    x: [''],
                    y: [''],            
                }];         
                            
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {      
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,   
                    uirevision:'true',              
                    xaxis: {  
                        color:fontColor,
                        nticks:$help.getNtick(target.clientWidth) 
                    },
                    yaxis: {
                        color:fontColor,
                        title:"距离(km)"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:48,
                        r:42,
                        l:42,
                        b:22                        
                    }
                };                         
                Plotly.newPlot(target, data, layout, {responsive: true,scrollZoom: true});   
                return
            }    
            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;    
            for(let i in dataList){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){    
                            j+=minSpan;                      
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)                                                                                     
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });                       
            let xData = [];   
            let yData = [];
            let customData = [];     
            let ym = this.yBN;
            let xm = this.xBN
            let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);            
            //降采样y
            for(var i in datas){
                //对y轴降采样
                datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
            }  
              //降采样 x
            let xSampling =Math.floor(datas.length / xm); 
            xSampling = xSampling < 1 ? 1:xSampling > datas.length/2 ? datas.length/2:xSampling;     
            datas.forEach((a,i)=>{                                                     
                let t = new Date(a.dataTime);               
                for(var j=0;j< this.dataList[0].height.length;j++){                                  
                    //zData[j].push('') 
                    if(a.straightAngle[j] == 'NaN' || a.straightValue[j] == 'NaN'){

                    }else{                                                
                        if(a.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                            xData.push(t)   
                            yData.push(datas[0].height[j])  
                            let _angle = Number(a.straightAngle[j] == 'NaN' ? '' :a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw);                
                            customData.push({
                                angle:Number(_angle.toFixed(2)),
                                speed:a.straightValue[j],
                            })         
                        }       
                    }
                }                       
            });                  
           // xData = this.dataList.map(a=>new Date(a.dataTime).pattern("HH:mm:ss (dd日)"))   
            var data = [{
                name:'',
                type: 'scatter', 
                mode:'markers',
                marker:{
                    size:0.01
                },             
                x: xData,
                y: yData,    
                customdata:customData,
                hovertext:customData.map(a=>`角度：${a.angle},风速：${a.speed?.toFixed(2)}`),
                showscale: false,            
            }];         
                           
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {      
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,    
                uirevision:'true',             
                xaxis: {  
                    color:fontColor,
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth) 
                },
                yaxis: {
                    color:fontColor,
                    title:"距离(km)"
                },
                modebar:{
                    add:[{
                            name: 'download image',
                            icon: Plotly.Icons.camera,
                            direction: 'download',
                            click: function(gd,event) {
                                let w = gd.clientWidth;
                                let h = gd.clientHeight;
                                htmlToImage.toPng(target, { })
                                .then(function (dataUrl) {
                                    /* do something */
                                    $help.exportPicture(dataUrl,$help.guid() + '.png')
                                });                        
                            }
                        }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,
                    l:42,
                    b:42                      
                }
            };                     
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});                 
            barbs(target)  
            let raby = (target.layout.yaxis.range[1] - target.layout.yaxis.range[0])   
            let rabx = (new Date(target.layout.xaxis.range[1]).getTime() - new Date(target.layout.xaxis.range[0]).getTime())   
            target.on('plotly_relayout', (a,b,c)=>{
                that.reDraw(datas,a,target,layout,raby,rabx)                    
            }) 
        },
        reDraw(datas,a,target,layout,raby,rabx,data = []){
            if(this.timerReDraw){
                clearTimeout(this.timerReDraw)
            }
            let that = this;
            let timeoutFun = (datas,a,target,layout,raby,rabx) => {
                let ax0 = a['xaxis.range[0]'] // ? a['xaxis.range[0]']: target.layout.xaxis.range[0];
                let ax1 =a['xaxis.range[1]'] // ? a['xaxis.range[1]']: target.layout.xaxis.range[1];
                let ay0 = a['yaxis.range[0]']// ? a['yaxis.range[0]']:target.layout.yaxis.range[0];
                let ay1 = a['yaxis.range[1]']// ? a['yaxis.range[1]']: target.layout.yaxis.range[1];

                let xData = [];   
                let yData = [];
                let customData = [];  
                let rary = ay1- ay0
                let rarx = new Date(ax1).getTime() - new Date(ax0).getTime()
                if(rarx ||rary ){
                    let scalex = that.scalex =rarx? rabx/rarx ? rabx/rarx:1: that.scalex;                    
                    let scaley = that.scaley =rary? raby/rary ? raby/rary:1:that.scaley;   
                                             
                    let ym = this.yBN * scaley; let xm = this.xBN * scalex ;   
                    ym = ym < 1 ? 1:ym;   
                     //降采样 x
                    let xSampling =Math.floor(datas.length / xm); 
                    xSampling = xSampling < 1 ? 1:xSampling > datas.length/2 ? datas.length/2:xSampling;              
                    if(ym >= datas[0].straightValue.length -2){
                        datas.forEach((d,i)=>{                                            
                            let t = new Date(d.dataTime);               
                            for(var j=0;j< datas[0].height.length;j++){                                  
                                //zData[j].push('')                     
                                if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN' || 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1
                                ){

                                }else{                            
                                    if(Number(i) % xSampling == 0){
                                        xData.push(t)   
                                        yData.push(datas[0].height[j])  
                                        let _angle =  Number(d.straightAngle[j] == 'NaN' ? '' :d.straightAngle[j] + d.yaw > 360 ? d.straightAngle[j] + d.yaw - 360 : d.straightAngle[j] + d.yaw< 0 ? d.straightAngle[j] + d.yaw+ 360 :d.straightAngle[j] + d.yaw);
                                        customData.push({
                                            angle:Number(_angle.toFixed(2)),
                                            speed:d.straightValue[j],
                                        })         
                                    }                                                              
                                }
                            }                       
                        });     
                    }else{
                        let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);          
                        //降采样y
                        for(var i in datas){
                            //对y轴降采样
                            let t = new Date(datas[i].dataTime);     
                            if(t.getTime() >= new Date(ax0).getTime() && t.getTime() <= new Date(ax1).getTime()){
                                datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
                            }                            
                        }                    
                        datas.forEach((d,i)=>{                                            
                            let t = new Date(d.dataTime);               
                            for(var j=0;j< datas[0].height.length;j++){                                  
                                //zData[j].push('')                     
                                if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN'|| 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1){

                                }else{                            
                                    if(d.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                                        xData.push(t)   
                                        yData.push(datas[0].height[j])  
                                        let _angle =  Number(d.straightAngle[j] == 'NaN' ? '' :d.straightAngle[j] + d.yaw > 360 ? d.straightAngle[j] + d.yaw - 360 : d.straightAngle[j] + d.yaw< 0 ? d.straightAngle[j] + d.yaw+ 360 :d.straightAngle[j] + d.yaw);
                                        customData.push({
                                            angle:Number(_angle.toFixed(2)),
                                            speed:d.straightValue[j],
                                        })          
                                    }                                                              
                                }
                            }                       
                        });     
                    }
                        
                    data.push({
                        name:'',
                        type: 'scatter', 
                        mode:'markers',
                        marker:{
                            size:0.01
                        },             
                        x: xData,
                        y: yData,    
                        hovertext:customData.map(d=>`角度：${d.angle},风速：${d.speed.toFixed(2)}`),
                        customdata:customData,
                        showscale: false,            
                    });         


                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});        
                    barbs(target,1,a)
                }else{
                    let ym = 35; let xm = 26;
                    let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);            
                    //降采样y
                    for(var i in datas){
                        //对y轴降采样
                        datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
                    }
                    //降采样 x
                    let xSampling =Math.floor(datas.length / xm); 
                    xSampling = xSampling < 1 ? 1:xSampling
                    datas.forEach((d,i)=>{                                            
                        let t = new Date(d.dataTime);               
                        for(var j=0;j< datas[0].height.length;j++){                                  
                            //zData[j].push('')                     
                            if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN'|| 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1){

                            }else{                            
                                if(d.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                                    xData.push(t)   
                                    yData.push(datas[0].height[j])  
                                    let _angle =  Number(d.straightAngle[j] == 'NaN' ? '' :d.straightAngle[j] + d.yaw > 360 ? d.straightAngle[j] + d.yaw - 360 : d.straightAngle[j] + d.yaw< 0 ? d.straightAngle[j] + d.yaw+ 360 :d.straightAngle[j] + d.yaw);
                                    customData.push({
                                        angle:Number(_angle.toFixed(2)),
                                        speed:d.straightValue[j],
                                    })         
                                }                                                              
                            }
                        }                       
                    });                            
                    data.push({
                        name:'',
                        type: 'scatter', 
                        mode:'markers',
                        marker:{
                            size:0.01
                        },             
                        x: xData,
                        y: yData,    
                        hovertext:customData.map(d=>`角度：${d.angle},风速：${d.speed.toFixed(2)}`),
                        customdata:customData,
                        showscale: false,            
                    });         
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});    
                    barbs(target,1,a)
                }        
            }
            this.timerReDraw = setTimeout(timeoutFun(datas,a,target,layout,raby,rabx), 1000);
        }
    }
}
</script>


<style scoped lang="scss">
.rowHeight{
    position: relative;
    height: 100%;
    width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.barbsChart{
    height: calc(100%);
}
.barbsTab{
    height: 60px;
}
.plotychart{
    :deep(.modebar){
        padding-top: 40px;
        padding-right:60px ;
    }
}
</style>
<template>
     <v-container style="max-width: 100%;position: relative;overflow: hidden;" class="width100 height100">                                            
        <v-row class="rowHeight pa-0 ma-0">
            <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mb-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
            <v-card width="100%" height="100%" style="position: relative;">
                <ColorBarConfig @update="loadHeatChart"  :dataKey="currentView == 3 ? 51:533" ref="colorBar" class="mapColorBarConfig"/>         
                <v-card-title class="ma-0 pa-1 heatmapTitle">{{ currentSite.siteName }}</v-card-title>             
                <div ref="polar" class="plotychart height100">                    
                </div>                
            </v-card>
        </v-row>   
    </v-container>
</template>
<script>
import { getWlsShearByScanModeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
export default {
    name:'wls.realtime.shear',
    props:{
        timeRange:{
            type:Array
        },
        currentSite:{
            type:Object
        },
        currentView:{
            type:Number,
        },
        currentViewMode:{
            type:Object            
        }
    },
    components: {
        ColorBarConfig
    },
    data(){
        return {
            overlay:false,
            dataList:[],
            timer:null,
            loadOver:false
        }
    },
    watch:{
        timeRange:{
            handler(n){                        
                if(n.length > 0){  
                    this.dataList = []    
                    this.load()
                }
            },
            deep:true
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadHeatmap()
            }
        },   
        currentViewMode(n){
            this.dataList = []
            this.load()         
        },
        currentSite:{
            handler(n,v){    
                this.dataList = []
                this.load()                       
            }
        },
        currentView(n){
            this.load()
        }     
    },
    computed:{
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        },
        time(){
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            let period = this.dataList[0]?.period?.split('_')[0]
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }  
        },       
    },
    mounted(){
        var that = this;
        that.load()          
    },
    methods:{
        load(desc = false){
            if(this.currentView != 9){
                return
            }
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.loadHeatmap()
            }, 600);            
        },       
        loadHeatmap(){     
            let that = this;
            this.loadOver = true;
            this.overlay = true;
            getWlsShearByScanModeApi({
                id:this.currentSite.siteId,
                scanType:this.currentViewMode.value == "PPI"?0:this.currentViewMode.value == "FIX"?1:this.currentViewMode.value == "RHI"?2:this.currentViewMode.value == "Tri"?3:this.currentViewMode.value == "Qua"?4:0 ,
                params:{
                    StartTime:this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss"),//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    EndTime:this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss"),//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    PageSize:1000, 
                    ScanMode:this.currentViewMode.value == "PPI"?0:this.currentViewMode.value == "FIX"?1:this.currentViewMode.value == "RHI"?2:this.currentViewMode.value == "Tri"?3:this.currentViewMode.value == "Qua"?4:0                   
                },
                success:(d)=>{
                    that.overlay = false;
                    if(!d.Data || d.Data.length == 0)  {          
                        that.loadHeatChart();
                    }        
                    if(d.Data[0].DwsSiteId != that.currentSite.siteId){
                        return;
                    }   
                    that.dataList = d.Data.filter(a=>a.DwsScanMode == 3 || a.DwsScanMode == 4).map(a=>{
                        return {
                            dataTime:a.DwsTime.replace('T',' '),
                            distanceData:a.DwsDis,                     
                            scanMode:scanType[a.DwsScanMode],
                            straightAngle:a.DwsStraight,
                            angleOfPitch:a.DwsPitch,   
                            data:a.DwsValue,
                            period:a.DwsPeriod.split('_')[0],
                            yaw:Number(a.DwsPeriod.split('_')[1]),
                        }
                    });
                    that.loadHeatChart()
                }
            })
        },
        loadHeatChart(){
            if(this.currentView != 9){
                return
            }
            var that = this;      
            let target = this.$refs.polar
            if(!this.$refs.colorBar || !target){
                return;
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            if(this.dataList.length == 0){
                var data = [{
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[]
                }];
                var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,  
                        uirevision:'true',                              
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)  
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"距离(km)"
                        },
                        modebar:{
                            add:[],
                            remove:['select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,      
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }
            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;    
            for(let i in dataList){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),                                
                                distanceData:this.dataList[i].distanceData,                     
                                scanMode:this.dataList[i].scanMode,
                                straightAngle:this.dataList[i].straightAngle,
                                angleOfPitch:this.dataList[i].angleOfPitch,   
                                data:Array.from({length:this.dataList[i].data.length},(val, i) => NaN),
                                period:this.dataList[i].period
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].distanceData;
            let xData = []; 
            let zData = [];
            let hoverText = [];

            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.data[j]))
                    hoverText[j].push(`垂直风切变值：${a.data[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });   
            var data = [{
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
            }];  
            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                             
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            htmlToImage.toPng(target, { })
                            .then(function (dataUrl) {
                                /* do something */
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            });                              
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }      
        }  
    }
}
</script>
<style scoped lang="scss">
.rowHeight{
    position: relative;
    height: 100%;
    width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.barbsChart{
    height: calc(100%);
}
.barbsTab{
    height: 60px;
}
.plotychart{
    :deep(.modebar){
        padding-top: 40px;
        padding-right:60px ;
    }
}
.mapColorBarConfig{
    position: absolute;
    top: 30px;
    right: 10px;
    width: 30px;
    z-index: 1;
    height: 80%;
}
</style>
<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">       
        <v-row class="rowHeight pa-0 ma-0" v-show="currentChart == 5">
            <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mb-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
            <v-card width="100%" height="100%" style="position: relative;">
                <v-card-title class="ma-0 pa-1 heatmapTitle">风廓线风羽图[30分钟]</v-card-title>             
                <div ref="barbs" class="barbsChart plotychart height100">                    
                </div>
                <div class="barbsTab mx-2 pt-1">
                    <v-row class="ma-0 pa-0 width100 flex-wrap">
                        <v-col class="ma-0 pa-0 pt-1 d-flex" v-for="i in barbsLevel" :key="i.level">
                            <div v-html="barbsHtml(i.level,0)"></div><span style="font-size: 12px;line-height: 24px;"> {{i.level == 1 ? '<1(m/s)':i.level == 24 ? '>=24':i.level}}</span>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="ma-0 pa-0 width100 flex-nowrap">
                        <v-col class="ma-0 pa-0 d-flex" v-for="i in barbsLevel.filter(a=>a.level > 12)" :key="i.level">
                            <div v-html="barbsHtml(i.level,0)"></div><span style="font-size: 12px;line-height: 24px;">{{i.level == 24 ? '>24':i.level}}</span>
                        </v-col>
                    </v-row>              -->
                </div>
            </v-card>
        </v-row>       
        <v-row class="rowHeight pa-0 ma-0" v-show="currentChart == 6 || currentChart == 7|| currentChart == 8">  
            <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>        
            <v-card width="100%" height="100%" style="position: relative;">
                <v-card-title  v-show="currentChart == 6" class="ma-0 pa-1 heatmapTitle">风廓线垂直风速时间热图[一小时]</v-card-title>  
                <v-card-title v-show="currentChart == 7" class="ma-0 pa-1 heatmapTitle">风廓线水平风速时间热图[一小时]</v-card-title>             
                <v-card-title v-show="currentChart == 8" class="ma-0 pa-1 heatmapTitle">风廓线水平风向时间热图[一小时]</v-card-title>             
                <ColorBarConfig @update="timerDraw" :dataKey="dataKey" ref="colorBar" class="mapColorBarConfig"/>                      
                <div ref="heatmap" class="width100 height100 "></div>
            </v-card>
        </v-row>                     
    </v-container>
</template>
<script>
import { getWlsProfileApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
import barbs from '@/plugins/helper/D3DrawHelper/barbs.js'
import barbsSvg from '@/plugins/helper/D3DrawHelper/barbsSvg.js'
export default {
    name:'wls.realtime.profile',
    props:{
        currentView:{
            type:Number,
            default:5
        }
    },
    components: {
        ColorBarConfig
      },
    data(){
        return {
            overlay:false,
            barbsLevel:[{
                level:1,
                color:''
            },{
                level:2,
                color:''
            },{
                level:4,
                color:''
            },{
                level:6,
                color:''
            },{
                level:8,
                color:''
            },{
                level:10,
                color:''
            },{
                level:12,
                color:''
            },{
                level:14,
                color:''
            },{
                level:16,
                color:''
            },{
                level:18,
                color:''
            },{
                level:20,
                color:''
            },{
                level:22,
                color:''
            },{
                level:24,
                color:''
            }],
            dataList:[],
            barbsScale:1,
            currentWatch:null,
            timer:null,
            currentChart:5,
            timerReDraw:null,
            scalex:1,
            scaley:1,
            xBN:26,//x轴标准系数
            yBN:35
        }
    },computed:{
        dataKey(){
            return this.currentChart == 6 ? 531:this.currentChart == 7? 530:532
        },
        barbsHtml(){
            return (speed = 1,angle = 90,color='black')=>{
                if(speed > 1 && speed <= 2){
                    color = '#ea80fc'
                }else if(speed > 2 && speed <= 4){
                    color = '#b75df5'
                }else if(speed > 4 && speed <= 6){
                    color = '#3220dc'
                }else if(speed > 6 && speed <= 8){
                    color = '#5efdfd'
                }else if(speed > 8 && speed <= 10){
                    color = '#23fe23'
                }else if(speed > 10 && speed <= 12){
                    color = '#6EAB42'
                }else if(speed > 12 && speed <= 14){
                    color = '#6EAB42' //FFF73E
                }else if(speed > 14 && speed <= 16){
                    color = '#FFF73E'//E4B16B
                }else if(speed > 16 && speed <= 18){
                    color = '#FFF73E'//FD5555
                }else if(speed > 18 && speed <= 20){
                    color = '#FD5555'//EF2D4E
                }else if(speed > 20 && speed <= 22){
                    color = '#FD5555'//72352B
                }else if(speed > 22 && speed <= 24){
                    color = '#969696'
                }else{
                    color = '#bd1084'
                }
                let windBarb = new barbsSvg(
                    speed * 5/2  /* wind speed in knots */,
                    angle /* wind direction angle */,      
                {
                    size:{
                        height:12,
                        width:28
                    },
                        triangle:{
                        fill:color,
                        stroke:color
                        },
                        circle:{
                        fill:color,
                        stroke:color
                        },
                        bar:{
                        stroke:color
                        }         
                    }
                    ).draw();        
                return windBarb.outerHTML     
            }             
        },
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){  
                this.currentWatch()
                this.dataList = [] 
                let currentScanType = this.$store.state.storage_global.currentSite.scanType;   
                if(currentScanType =="Tri"){ 
                    this.watchData(n.siteId)  
                    this.load()       
                }        
            }
        },
        currentView(n){
            this.currentChart = n;
            let that = this;
            setTimeout(() => {
                if(that.currentChart == 5){                        
                    that.loadBarbs()
                }                   
                if(that.currentChart == 6){
                    that.loadHeatmapPitch()
                }   
                if(that.currentChart == 7){
                    that.loadHeatmapStraight()
                }   
                if(that.currentChart == 8){
                    that.loadHeatmapStraightAngle()
                }    
            }, 200);            
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;
        this.currentChart = this.currentView        
        this.xBN = Math.floor(26 * this.$el.clientWidth / 1664)
        this.yBN = Math.floor(35 * this.$el.clientHeight / 759)
        setTimeout(() => {
            let currentScanType = this.$store.state.storage_global.currentSite.scanType;
            if(that.dataList.length == 0 && currentScanType =="Tri"){   
                that.watchData(that.$store.state.storage_global.currentSite.siteId)                     
                that.load()  
            }    
        }, 600);        
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                            
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == 53){                                                                                                                   
                    let json = JSON.parse(JSON.stringify(n.jsonData))
                    let len = that.dataList.length;
                    len = len < 20 ? 20:len;
                    that.dataList = json.map(a=>{
                        return {
                            dataTime:a.dataTime,
                            height:a.height,
                            period:a.period.split('_')[0],
                            scanMode:scanType[a.scanType],
                            pitchValue:a.pitchValue,
                            straightAngle:a.straightAngle,
                            straightValue:a.straightValue
                        }
                    }).concat(that.dataList).sort((a,b)=>{
                        return new Date(a.dataTime.replace('T',' ')) - new Date(b.dataTime.replace('T',' '))
                    })  
                    that.dataList = that.dataList.slice(-len)
                    if(that.currentChart == 5){
                        that.loadBarbs()
                    }                   
                    if(that.currentChart == 6){
                        that.loadHeatmapPitch()
                    }  
                    if(that.currentChart == 7){
                        that.loadHeatmapStraight()
                    }    
                    if(that.currentChart == 8){
                        that.loadHeatmapStraightAngle()
                    }                      
                }
            }, {immediate: true })
        },   
        load(){
            var that = this;
            this.overlay = true;
            //一小时内风羽图
            let _scanType = this.$store.state.storage_global.currentSite.scanType == null || typeof(this.$store.state.storage_global.currentSite.scanType) == 'undefined'? "PPI":this.$store.state.storage_global.currentSite.scanType
            getWlsProfileApi({
                id:this.$store.state.storage_global.currentSite.siteId,                
                params:{
                    StartTime:new Date(new Date().getTime() -60* 60 * 1000).pattern('yyyy-MM-dd HH:mm:ss'),
                    EndTime:new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    PageSize:500,   
                    ScanMode:_scanType == "PPI"?0:_scanType == "FIX"?1:_scanType == "RHI"?2:_scanType == "Tri"?3:_scanType == "Qua"?4:0,                 
                },
                success:(d)=>{
                    that.overlay = false;  
                    that.dataList = d.Data.map(a=>{
                        return {
                            dataTime:a.DwpTime.replace('T',' '),
                            height:a.DwpHeight,
                            period:a.DwpPeriod.split('_')[0],
                            yaw:Number(a.DwpPeriod.split('_')[1]),
                            scanMode:scanType[a.DwpScanMode],
                            pitchValue:a.DwpPitchValue,
                            straightAngle:a.DwpStraightAngle,
                            straightValue:a.DwpStraightValue
                        }
                    }).sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    })  ;            
                    if(that.currentChart == 5){
                        that.loadBarbs()
                    }                   
                    if(that.currentChart == 6){
                        that.loadHeatmapPitch()
                    }   
                    if(that.currentChart == 7){
                        that.loadHeatmapStraight()
                    }     
                    if(that.currentChart == 8){
                        that.loadHeatmapStraightAngle()
                    }     
                }
            })
        },
        timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                if(that.currentChart == 7){
                    that.loadHeatmapStraight()
                }   
                if(that.currentChart == 6){
                    that.loadHeatmapPitch()
                }  
                if(that.currentChart == 8){
                    that.loadHeatmapStraightAngle()
                }                    
            }, 1000/3);
        },
        loadHeatmapPitch(){
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,  
                        uirevision:'true',                              
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)  
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"距离(km)"
                        },
                        modebar:{
                            add:[],
                            remove:['select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,       
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }

            let _datas =[]; 
            let minSpan = 5;       
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
      
            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.pitchValue[j]))
                    hoverText[j].push(`监控值：${a.pitchValue[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });              
            
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,     
                uirevision:'true',                            
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },  
        loadHeatmapStraight(){
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,  
                        uirevision:'true',                              
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)  
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"距离(km)"
                        },
                        modebar:{
                            add:[],
                            remove:['select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,       
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }

            let _datas =[]; 
            let minSpan = 5;       
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
      
            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.straightValue[j]))
                    hoverText[j].push(`监控值：${a.straightValue[j]}<br />距离：${yData[j]}<br />角度：${a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });              
            
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,     
                uirevision:'true',                            
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },          
        loadHeatmapStraightAngle(){
            let target = this.$refs.heatmap; 
            if(!this.$refs.colorBar || !target){
                return;
            }
            if(!this.dataList|| this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                        {
                        y: [],
                        x: [],
                        z: [],
                        type: 'heatmap',           
                        showscale: false,
                        hovertext:[]
                        }
                    ];

                    var layout = {
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,                                
                        xaxis: {
                            color:fontColor,  
                            nticks:$help.getNtick(target.clientWidth)
                        },
                        yaxis: {
                            color:fontColor,  
                            title:"角度°"
                        },
                        modebar:{
                            add:[],
                            remove:['select','select2d','lasso','lasso2d']
                        },
                        margin:{                    
                            t:42,
                            r:42,
                            l:42,
                            b:22
                        }
                    };
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                    return
            }
            let _datas =[]; 
            let minSpan = 5;    
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){                                
                if(i > 0){                    
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;                        
                        while(j<span){                          
                            j+=minSpan;
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)
                            })                                          
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = datas[0].height;
            let xData = []; 
            let zData = [];
            let hoverText = [];
            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.straightAngle[j]))
                    hoverText[j].push(`角度：${a.straightAngle[j] + a.yaw > 360 ? a.straightAngle[j] + a.yaw - 360 : a.straightAngle[j] + a.yaw< 0 ? a.straightAngle[j] + a.yaw+ 360 :a.straightAngle[j] + a.yaw}<br />监控值：${a.straightValue[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });               
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                                
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)
                },
                yaxis: {
                    color:fontColor,  
                    title:"距离(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },
        loadBarbs(){               
            var that = this;
            let target = this.$refs.barbs;   
            if( !target){
                return;
            }
            if(this.dataList.length == 0){
                var data = [{
                    name:'',
                    type: 'scatter', 
                    mode:'markers',
                    marker:{
                        size:0.01
                    },             
                    x: [],
                    y: [],            
                }];         
                            
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {      
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,   
                    uirevision:'true',              
                    xaxis: {  
                        color:fontColor,
                        nticks:$help.getNtick(target.clientWidth) 
                    },
                    yaxis: {
                        color:fontColor,
                        title:"距离(km)"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:48,
                        r:42,
                        l:42,
                        b:22                        
                    }
                };                         
                Plotly.newPlot(target, data, layout, {responsive: true,scrollZoom: true});   
                return
            }    
            let _datas =[]; 
            let minSpan = 5;       
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){    
                            j+=minSpan;                      
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                height:this.dataList[i].height,
                                period:this.dataList[i].period,
                                scanMode:this.dataList[i].scanMode,
                                pitchValue:Array.from({length:this.dataList[i].pitchValue.length},(val, i) => NaN),
                                straightAngle:Array.from({length:this.dataList[i].straightAngle.length},(val, i) => NaN),
                                straightValue:Array.from({length:this.dataList[i].straightValue.length},(val, i) => NaN)                                                                                     
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            }).slice(Math.floor((_datas.length-1)/2),_datas.length-1);                       
            let xData = [];   
            let yData = [];
            let customData = [];     
            let ym = this.yBN;
            let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);            
            //降采样y
            for(var i in datas){
                //对y轴降采样
                datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
            }  
            datas.forEach((a,i)=>{                                                     
                let t = new Date(a.dataTime);               
                for(var j=0;j< this.dataList[0].height.length;j++){                                  
                    //zData[j].push('') 
                    if(a.straightAngle[j] == 'NaN' || a.straightValue[j] == 'NaN'){

                    }else{                                                
                        if(a.arrSampling.indexOf(j) >=0 ){
                            xData.push(t)   
                            yData.push(datas[0].height[j])  
                            customData.push({
                                angle:a.straightAngle[j],
                                speed:a.straightValue[j],
                            })      
                        }       
                    }
                }                       
            });                  
           // xData = this.dataList.map(a=>new Date(a.dataTime).pattern("HH:mm:ss (dd日)"))   
            var data = [{
                name:'',
                type: 'scatter', 
                mode:'markers',
                marker:{
                    size:0.01
                },             
                x: xData,
                y: yData,    
                customdata:customData,
                hovertext:customData.map(a=>`角度：${a.angle},风速：${a.speed?.toFixed(2)}`),
                showscale: false,            
            }];         
                           
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {      
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,    
                uirevision:'true',             
                xaxis: {  
                    color:fontColor,
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth) 
                },
                yaxis: {
                    color:fontColor,
                    title:"距离(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,
                    l:42,
                    b:42                      
                }
            };                         
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }   
            barbs(target)  
            let raby = (target.layout.yaxis.range[1] - target.layout.yaxis.range[0])   
            let rabx = (new Date(target.layout.xaxis.range[1]).getTime() - new Date(target.layout.xaxis.range[0]).getTime())   
            target.on('plotly_relayout', (a,b,c)=>{
                that.reDraw(datas,a,target,layout,raby,rabx)                    
            }) 
        },
        reDraw(datas,a,target,layout,raby,rabx){
            if(this.timerReDraw){
                clearTimeout(this.timerReDraw)
            }
            let that = this;
            let timeoutFun = (datas,a,target,layout,raby,rabx) => {
                let ax0 = a['xaxis.range[0]'] // ? a['xaxis.range[0]']: target.layout.xaxis.range[0];
                let ax1 =a['xaxis.range[1]'] // ? a['xaxis.range[1]']: target.layout.xaxis.range[1];
                let ay0 = a['yaxis.range[0]']// ? a['yaxis.range[0]']:target.layout.yaxis.range[0];
                let ay1 = a['yaxis.range[1]']// ? a['yaxis.range[1]']: target.layout.yaxis.range[1];

                let xData = [];   
                let yData = [];
                let customData = [];  
                let rary = ay1- ay0
                let rarx = new Date(ax1).getTime() - new Date(ax0).getTime()
                if(rarx ||rary ){
                    let scalex = that.scalex =rarx? rabx/rarx ? rabx/rarx:1: that.scalex;                    
                    let scaley = that.scaley =rary? raby/rary ? raby/rary:1:that.scaley;   
                                             
                    let ym = this.yBN * scaley; let xm = this.xBN * scalex ;   
                    ym = ym < 1 ? 1:ym;   
                     //降采样 x
                    let xSampling =Math.floor(datas.length / xm); 
                    xSampling = xSampling < 1 ? 1:xSampling > datas.length/2 ? datas.length/2:xSampling;              
                    if(ym >= datas[0].straightValue.length -2){
                        datas.forEach((d,i)=>{                                            
                            let t = new Date(d.dataTime);               
                            for(var j=0;j< datas[0].height.length;j++){                                  
                                //zData[j].push('')                     
                                if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN' || 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1
                                ){

                                }else{                            
                                    if(Number(i) % xSampling == 0){
                                        xData.push(t)   
                                        yData.push(datas[0].height[j])  
                                        customData.push({
                                            angle:d.straightAngle[j],
                                            speed:d.straightValue[j],
                                        })      
                                    }                                                              
                                }
                            }                       
                        });     
                    }else{
                        let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);          
                        //降采样y
                        for(var i in datas){
                            //对y轴降采样
                            let t = new Date(datas[i].dataTime);     
                            if(t.getTime() >= new Date(ax0).getTime() && t.getTime() <= new Date(ax1).getTime()){
                                datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
                            }                            
                        }                    
                        datas.forEach((d,i)=>{                                            
                            let t = new Date(d.dataTime);               
                            for(var j=0;j< datas[0].height.length;j++){                                  
                                //zData[j].push('')                     
                                if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN'|| 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1){

                                }else{                            
                                    if(d.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                                        xData.push(t)   
                                        yData.push(datas[0].height[j])  
                                        customData.push({
                                            angle:d.straightAngle[j],
                                            speed:d.straightValue[j],
                                        })      
                                    }                                                              
                                }
                            }                       
                        });     
                    }
                        
                    var data = [{
                        name:'',
                        type: 'scatter', 
                        mode:'markers',
                        marker:{
                            size:0.01
                        },             
                        x: xData,
                        y: yData,    
                        hovertext:customData.map(d=>`角度：${d.angle},风速：${d.speed.toFixed(2)}`),
                        customdata:customData,
                        showscale: false,            
                    }];         

                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});        
                    barbs(target,1,a)
                }else{
                    let ym = 35; let xm = 26;
                    let therodsy =Math.floor((datas[0].straightValue.length -2) / ym);            
                    //降采样y
                    for(var i in datas){
                        //对y轴降采样
                        datas[i].arrSampling = $help.sampling(datas[i].straightValue,therodsy)
                    }
                    //降采样 x
                    let xSampling =Math.floor(datas.length / xm); 
                    xSampling = xSampling < 1 ? 1:xSampling
                    datas.forEach((d,i)=>{                                            
                        let t = new Date(d.dataTime);               
                        for(var j=0;j< datas[0].height.length;j++){                                  
                            //zData[j].push('')                     
                            if(d.straightAngle[j] == 'NaN' || d.straightValue[j] == 'NaN'|| 
                                t.getTime() < new Date(ax0).getTime() || t.getTime() > new Date(ax1).getTime() 
                                || datas[0].height[j] < ay0 ||  datas[0].height[j] > ay1){

                            }else{                            
                                if(d.arrSampling.indexOf(j) >=0 && Number(i) % xSampling == 0){
                                    xData.push(t)   
                                    yData.push(datas[0].height[j])  
                                    customData.push({
                                        angle:d.straightAngle[j],
                                        speed:d.straightValue[j],
                                    })      
                                }                                                              
                            }
                        }                       
                    });                            
                    var data = [{
                        name:'',
                        type: 'scatter', 
                        mode:'markers',
                        marker:{
                            size:0.01
                        },             
                        x: xData,
                        y: yData,    
                        hovertext:customData.map(d=>`角度：${d.angle},风速：${d.speed.toFixed(2)}`),
                        customdata:customData,
                        showscale: false,            
                    }];         
                        
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});    
                    barbs(target,1,a)
                }        
            }
            this.timerReDraw = setTimeout(timeoutFun(datas,a,target,layout,raby,rabx), 1000);
        }
    }
}
</script>


<style scoped lang="scss">
.rowHeight{
    position: relative;
    height: 100%;
    width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.barbsChart{
    height: calc(100% - 80px);
}
.barbsTab{
    height: 60px;
}
.plotychart{
    :deep(.modebar){
        padding-top: 40px;
        padding-right:60px ;
    }
}
</style>

<template>
   <v-container class="d-flex flex-column container">
    <!-- 头像选择 -->
    <v-dialog v-model="avatarDialog" width="600">
        <v-card>
            <v-avatar class="profile pointer" v-for="i in 9" :key="i" @click="setAvatar(i)"
                        size="200"                    
                        tile>
                <v-img :src="require(`@/assets/img/avatar/a${i}.png`)" contain width="160px" height="160px">
                </v-img>
            </v-avatar>       
        </v-card>
    </v-dialog>
    <v-dialog v-model="changePasswordForm.dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar color="primary">
                <v-btn icon dark @click="changePasswordForm.dialog= false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title style="color:white">{{$i18n.t('userInfo.title')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>                            
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pa-0">                      
                <v-row justify="center" class="ma-1">
                    <v-card width="100%" class="pa-2 dialogContent" >
                        <v-form
                        class="ma-2"
                        ref="form"
                        v-model="changePasswordForm.valid"                            
                        >
                            <v-row>
                                <v-text-field
                                    v-model="changePasswordForm.oldPassword"                                
                                    :label="$i18n.t('userInfo.label.oldPass')"
                                    :rules="changePasswordForm.rules.oldPassword"
                                    required
                                ></v-text-field>  
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="changePasswordForm.newPassword"                                
                                    :label="$i18n.t('userInfo.label.newPass')"
                                    :rules="changePasswordForm.rules.newPassword"
                                    required
                                ></v-text-field>  
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="changePasswordForm.vaiPassword" 
                                    :rules="changePasswordForm.rules.vaiPassword"                               
                                    :label="$i18n.t('userInfo.label.vaiPass')"
                                    required
                                ></v-text-field>  
                            </v-row>                                                         
                        </v-form>
                    </v-card>                           
                </v-row>
            </v-card-text>  
            <v-card-actions class="bottomAction d-flex justify-end">                         
                <v-btn class="mr-6" color="primary" @click="changePassword">{{$i18n.t('userInfo.btn.ok')}}</v-btn>
                <v-btn class="mr-6" @click="changePasswordForm.dialog=false">{{$i18n.t('userInfo.btn.cancel')}}</v-btn>
            </v-card-actions>                 
        </v-card>
    </v-dialog>
        <v-card elevation="0" height="100%">            
            <v-row class="ma-0 ">                
                <v-avatar
                    class="profile"
                    color="grey"
                    size="160"
                    tile
                >
                    <v-img :src="avatarSrc" contain width="200px" height="200px">
                    </v-img>
                    <v-icon class="editAvator pointer" @click="avatarDialog = true">mdi-pencil-box-outline</v-icon>
                </v-avatar>
                <v-row class="ma-0 d-flex flex-column">
                    <v-row  class="ma-0">
                        <v-card-title class="align-end"><span class="text-h3">{{hello}}~,</span>{{$store.state.storage_user.user.userName}}</v-card-title>
                    </v-row>
                    <v-row>

                    </v-row>
                </v-row>
            </v-row>
            <v-row class="ma-0 px-10 flex-column" style="overflow: auto;height: calc(100% - 240px);">
                <v-row  class="ma-0 height24">
                    <div style="width:70px;">{{$i18n.t('userInfo.label.account')}}</div>{{$store.state.storage_user.user.account}}
                </v-row> 
                <v-row  class="ma-0 height24">
                    <div class="d-flex align-center">
                        <div style="width:70px;">{{$i18n.t('userInfo.label.userName')}}</div>
                        <v-text-field v-model="userName" :placeholder="$i18n.t('userInfo.placeholder.userName')" solo hide-details hint persistent-hint dense></v-text-field>
                    </div>
                </v-row> 
                <v-row  class="ma-0 height24">
                    <div style="width:70px;">{{$i18n.t('userInfo.label.company')}}</div>
                    {{$store.state.storage_user.user.company?.name}}
                </v-row>
                <v-row  class="ma-0 height24">
                    <div class="d-flex align-center">
                        <div style="width:70px;">{{$i18n.t('userInfo.label.phone')}}</div>
                        <v-text-field type="number" :placeholder="$i18n.t('userInfo.placeholder.phone')" v-model="phone" solo hide-details hint persistent-hint dense></v-text-field>
                    </div> 
                </v-row>
                <v-row  class="ma-0 height24">
                    <div class="d-flex align-center">
                            <div style="width:70px;">{{$i18n.t('userInfo.label.email')}}</div>
                            <v-text-field :placeholder="$i18n.t('userInfo.placeholder.email')" v-model="email" solo hide-details hint persistent-hint dense></v-text-field>
                        </div>      
                </v-row>
                <v-row  class="ma-0 height24">                    
                    {{$i18n.t('userInfo.label.mysites')}}
                    <div class="px-0 width100">
                        <!-- <div v-for="i,_i in siteInfo" :key="_i">
                            {{i.name}}:{{i.sites.length}}个
                        </div> -->
                        <div ref="siteChart" style="width:100%;height:400px"></div>
                    </div>
                </v-row>                      
            </v-row>
            <v-card-actions class="d-flex width100 justify-end actionsBtn">
                <v-btn color="secondary" @click="changePasswordForm.dialog = true">{{$i18n.t('userInfo.btn.changePwd')}}</v-btn>
                <!-- <v-btn color="warning">重置密码</v-btn> -->
                <v-btn color="primary" @click="save">{{$i18n.t('userInfo.btn.save')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-container> 
</template>
<script>
import {editUserInfoApi,editUserPasswordApi } from '@/api'
import {uploadMinioURL} from '@/api/url'
import * as echarts from "echarts"
export default {
    name:"userinfo.index",
    computed:{ 
        hello(){
            let now = new Date();
            let hour = now.getHours() 
            if(hour < 6){
                return this.$i18n.t('userInfo.text.hellozc')
            } 
            else if (hour < 9){
                return this.$i18n.t('userInfo.text.hellozs')
            } 
            else if (hour < 12){
               return this.$i18n.t('userInfo.text.hellosw')
            } 
            else if (hour < 14){
                return this.$i18n.t('userInfo.text.hellozw')
            } 
            else if (hour < 18){
                return this.$i18n.t('userInfo.text.helloxw')
            }             
            else if (hour < 24){
                return this.$i18n.t('userInfo.text.hellows')
            }             
        },
        siteInfo(){
            let sites =  this.$store.state.storage_user.sites;
            let info = this.$store.state.storage_user.siteTypes.map(a=>{
                return {
                    type:a.code,
                    name:a.name,
                    sites:sites.filter(b=>{
                       return b.type == a.code
                    })
                } 
            });                   
            return info
        }      
    },
    data(){        
        return {
            avatarDialog:false,
            avatarSrc:null,
            avatarIndex:1,
            userName:this.$store.state.storage_user.user.userName,
            phone:this.$store.state.storage_user.user.phone,
            email:this.$store.state.storage_user.user.email,
            changePasswordForm:{
                valid:false,
                dialog:false,
                oldPassword:"",
                vaiPassword:"",
                newPassword:"",
                rules:{
                    oldPassword:[
                        v => !!v || this.$i18n.t("userInfo.rule.password"),
                        v =>(v && v.length <= 12 && v.length >=6 )|| this.$i18n.t("userInfo.rule.passwordLength")
                    ],
                    newPassword:[
                        v => !!v || this.$i18n.t("userInfo.rule.newPassword"),
                        v =>(v && v.length <= 12 && v.length >=6 )|| this.$i18n.t("userInfo.rule.newPasswordLength")
                    ],
                    vaiPassword:[
                        v => !!v || this.$i18n.t("userInfo.rule.vaiPassword"),
                        v =>(v && v == this.changePasswordForm.newPassword )|| this.$i18n.t("userInfo.rule.vaiPasswordS")
                    ],
                }
            }
        }    
    },    
    watch:{            
    },
    mounted(){
        this.avatarSrc =uploadMinioURL + this.$store.state.storage_user.user.extendInfo.avator;
        this.loadChart();         
    },
    methods:{
        setAvatar(i){
            this.avatarSrc = `${uploadMinioURL}web/image/avator/a${i}.png`;
           // localStorage.setItem("avatarPath",i)
            this.avatarIndex = i;
            this.avatarDialog = false;
        },
        save(){  
            var that = this;          
            if(!this.userName || this.userName.replaceAll(" ","") == ""){
                openNotice({
                    type:'error',
                    text:"请输入用户名"
                })
                return;
            }
            if(this.userName.length > 8){
                openNotice({
                    type:'error',
                    text:"用户名最多可输入八个字符"
                })
                return;
            }
            if(this.phone && this.phone.length > 0 && this.phone.length!=11){
                openNotice({
                    type:'error',
                    text:"手机号输入有误，请检查"
                })
                return;
            }
            if(this.email && this.email.length > 0 && !/.+@.+\..+/.test(this.email)){
                openNotice({
                    type:'error',
                    text:"邮箱格式输入有误，请检查"
                })
                return;
            }
            this.$store.commit("storage_user/updateAvator",this.avatarSrc.replace(uploadMinioURL,""),{root:true})         
            editUserInfoApi({
                id:this.$store.state.storage_user.user.id,
                data:{
                    SuiEmail:this.email,
                    SuiPhone:this.phone,
                    SuiName:this.userName,
                    SuiExtendInfo:JSON.stringify(this.$store.state.storage_user.user.extendInfo)
                },
                success:(d)=>{
                    if(d){
                        openNotice({
                            type:'success',
                            text:"修改成功"
                        })
                        that.$store.dispatch('storage_user/getCurrentUser') 
                    }
                }
            })
        },
        changePassword(){
            var that = this;
            var rel = this.$refs.form.validate();
            if(rel){
                editUserPasswordApi({
                    id:this.$store.state.storage_user.user.id,
                    params:{
                        oldPwd:this.changePasswordForm.oldPassword,
                        newPwd:this.changePasswordForm.newPassword
                    },
                    success:(d)=>{
                        if(d){
                            openNotice({
                                type:'success',
                                text:"修改成功"
                            })
                            that.$store.dispatch('user_userInfo/getCurrentUser') 
                            that.changePasswordForm.dialog = false;
                        }
                    }
                })
            }
        },
        //加载echart
        loadChart(){
            let echart = echarts.getInstanceByDom(this.$refs.siteChart);
            if(!echart){
               echart= echarts.init(this.$refs.siteChart);
            }
            let sites =  this.$store.state.storage_user.sites;
            let info = this.$store.state.storage_user.siteTypes.map(a=>{
                let _sites = sites.filter(b=>{
                       return b.type == a.code
                    });
                return {
                    type:a.code,
                    name:a.name,
                    sites:_sites,
                    value:_sites.length
                } 
            });                   
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: 32,
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data:info
                    }
                ]
            };
            echart.setOption(option)
        }   

    }
}
</script>
<style scoped lang="scss">
.height24{
    height: 24px;
}
.actionsBtn{
    position: absolute;
    bottom: 0;
}
.editAvator{
    position: absolute;
    height: 24px;
    width: 24px;    
    right: 0px !important;
    bottom: 0px !important;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
}
</style>
<template>
    <v-container
        class="py-2 d-flex flex-column"
        fluid      
      >
      <v-row class="d-flex align-center flex-grow-0" dense >  
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('caseTemplate.label.title')"
                    :placeholder="$i18n.t('caseTemplate.placeholder.title')"
                    v-bind:value.sync="title"
                />               
                <!-- <SearchDatePicker 
                    :label="$i18n.t('caseTemplate.label.editDateRange')"
                    :placeholder="$i18n.t('caseTemplate.placeholder.editDateRange')"
                    v-bind:date.sync="editDateRange"
                    range
                />   
                             -->
                <v-btn class="mx-2" @click="load()">{{$i18n.t('caseTemplate.btn.search')}}</v-btn>
                 <v-btn
                color="primary"                        
                class="mx-2"
                @click="$router.push({path:'/case/caseTemplate/edit'})"
                >{{$i18n.t('caseTemplate.btn.new')}}
                </v-btn>
            </v-row>             
        </v-col>                              
                  
    </v-row>  
    <v-row class="tableContainer ma-0">     
        <v-card height="100%" width="100%" elevation="0"  class="d-flex cardContain flex-wrap pa-4">
            <v-card class="caseCard flex-wrap flex-grow-0 flex-shrink-0 ma-4 pa-2" height="fit-content" v-for="data in datas" :key="data.id">               
                    <!-- @click="openWindow(data.CiId)" -->
                <v-img                    
                    :src="data.src"
                    class="white--text align-end pointer"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="232px"
                    width="232px"
                >
            <v-card-title class="onlytext2" v-text="data.CtName"></v-card-title>
            </v-img>
            <v-card-text class="pa-2 " style="width:232px">
                <v-row class="ma-0 .body-1 authtext onlytext2">
                <!-- {{$i18n.t('caseTemplate.label.author')}}{{$i18n.t('caseTemplate.label.authorText')}}                 -->
                </v-row>
            </v-card-text>
            <v-card-actions>               
                <v-spacer></v-spacer> 
                <v-icon @click="$router.push({path:'/case/caseTemplate/edit',query:{id:data.CtId}})" color="#ff6f00" class="pointer" title="修改">mdi-circle-edit-outline</v-icon>                            
                <v-icon @click="del(data.CtId)" color="#e64a19" class="pointer"  title="删除">mdi-trash-can</v-icon>                    
            </v-card-actions>
            </v-card>
             <div v-if="datas.length == 0 && loadOver">
                暂无您可查看的案例模板
            </div> 
        </v-card> 
    </v-row>
    <v-row justify="center" align="center" class="ma-0 flex-grow-0">
          <v-pagination
          @input="load"
            v-model="page"
            :length="length"
            circle
            :total-visible="8"
            ></v-pagination>
            {{$i18n.t("caseTemplate.pageTotal",[total])}}
      </v-row>         
      </v-container>
</template>
<script>
import {deleteCaseTemplateApi,getCaseTemplatesApi} from '@/api'
import { uploadMinioURL} from '@/api/url'
export default {
    name:"caseTemplate.index",
    data(){
        return{
            datas:[],
            page:1,
            rows:10,
            total:0,
            loadOver:false,
            editDateRange:[],
            title:""                        
        }
    },
    computed:{  
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },       
    },
    watch:{       
    },
    mounted(){
        this.load();
    },
    methods:{
        load(){
            var that = this;
            this.loadOver = false;
            getCaseTemplatesApi({
                params:{
                    PageIndex:this.page,
                    PageSize:this.rows,            
                    Title:this.title,                    
                    // ModifyStartTime:this.editDateRange[0],
                    // ModifyEndTime:this.editDateRange[1],
                },success:(d)=>{
                    that.loadOver = true;
                    that.datas = d.Data.map(a=>{                            
                        if(a.CaseTemplateAttachmentRela){
                            a.src = `${uploadMinioURL}${a.CaseTemplateAttachmentRela.CtarBucketName}${a.CaseTemplateAttachmentRela.CtarObjectName}`
                        }                                            
                        else{
                            a.src = require('@/assets/img/staff_1024.jpg')                        
                        }                                     
                        return a;
                    });   
                    that.total = d.Total                      
                }
            })
        },             
        del(id){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:(d)=>{
                    deleteCaseTemplateApi({
                        id:id,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }     
                        }
                    })                    
                },//确认回调
                noBack:null //取消回调
            })
            
        },       
        openWindow(id){
            var {href} = this.$router.resolve({
                path:'/article',
                query:{
                    id:id
                }
            })
            window.open(href,'_blank')
        }
    }
    
}
</script>
<style scoped lang="scss">
.cardContain{
    height: 100%;
    overflow: auto;
}
:deep(.caseCard .v-responsive__content){
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.authtext{
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}
.onlytext2{
    height: 34%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

</style>
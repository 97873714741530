<template>
    <v-card v-if="show" color="pa-2" width="14vw" height="30vh" min-width="540" min-height="360">
        <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mt-12"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <v-row class="ma-0 pa-0 d-flex justify-space-between">
            <h4 class="ma-1">{{title}}</h4>
            <v-icon class="pointer" @click="show=false">mdi-close</v-icon>            
            <v-card-actions class="ma-0 pa-0  btns d-flex justify-end">
                <SearchDateTimePickerPlus  :maxRangeDay='18/24' range v-bind:datetime.sync="timeRange" />
                <v-menu  open-on-hover offset-y bottom z-index="999">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                              
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 mx-2"
                            >
                            {{currentType.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentType">
                                <v-list-item
                                    v-for="(item, index) in typeList"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>        
            </v-card-actions>
        </v-row>        
        <v-row  style="height: calc(100% - 42px);top: 10px;position: relative;" class="ma-0 pa-0  width100">       
            <div ref="chart"   class="plotychart width100 height100"></div>
        </v-row>
    </v-card>
</template>
<script>
import Plotly from 'plotly.js-dist'
import {getMonitorPointDataApi } from '@/api'
export default {
    name:"monitorPointDataCard",
    props:{
        title:{
            type:String,
            default:"趋势图"
        },
    },    
    data(){
        return{
            overlay:false,
            dataList:[],
            show:false,
            pointNo:'',
            siteName:'',
            typeList:[{
                text:"乡村镇",  
                key:'xcz'
            },{
                text:"国控点",  
                key:'gk'
            }],
            currentType:{
                text:"国控点",  
                key:'gk'
            },
            timeRange:[]
        }
    },
    computed:{
        noData(){
            return !this.dataList||this.dataList.length == 0
        }
    },
    watch:{
       currentType(){
        this.load()
       },
       timeRange(n){
            this.load()
       },
    },
    mounted(){   
        this.timeRange = [new Date(new Date().setTime(new Date().getTime() - 12 * 60 * 60 * 1000)),new Date()]          
    },
    methods:{ 
       load(){
        if($help.isNull(this.pointNo)){
            return;
        }        
        let that = this;
        this.overlay = true;
        let start = this.timeRange[0].pattern("yyyy-MM-dd HH")
        let end = this.timeRange[1].pattern("yyyy-MM-dd HH")
        getMonitorPointDataApi({
            district:this.currentType.key,
            params:{
                siteNo:this.pointNo,
                startHours:start,
                endHours:end
            },
            success:(d)=>{
                that.overlay = false;
                that.dataList = d?.data?.datas ? d?.data?.datas : [];
                that.siteName = d?.data?.siteName ;
                that.loadChart()
            }
        })
       },
       loadChart(){
            let target = this.$refs.chart;                              
            let xData = []; 
            let datas = this.dataList.sort((a,b)=>{
                return new Date(a.time).getTime() - new Date(b.time).getTime()
            });
            
            datas.forEach((a,i)=>{                                            
                xData.push(new Date(a.time).pattern("HH时 (dd日)"))                           
            });              
            var data = []
            for(let item in this.dataList[0]){
                if(item != 'time'){
                    let name = item == 'windspeed' ? '风速':item == 'winddirection' ? "风向":item == 'temperature'?'温度'
                    :item == 'humidity'?'湿度':item == 'atmos'?'大气':item
                    data.push({
                        type: 'scatter',
                        y: this.dataList.map(a=>a[item]),  
                        x:xData,
                        showlegend: true,
                        mode: 'lines',
                        name:name
                    })
                }
            }
            if(data.length == 0){
                data.push({
                    type: 'scatter',
                        y: [],  
                        x:[],
                        showlegend: true,
                        mode: 'lines',
                })
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {  
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.1,
                    font:{
                        color: fontColor
                    }
                },     
                uirevision:'true',           
                xaxis: {  
                    color:fontColor,
                    nticks:20
                },
                yaxis: {
                    color:fontColor,
                    title:"值"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,  
                    l:42                        
                },
                
            };   
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});                     
       },
    }
}
</script>
<style scoped lang="scss">
.btns{
    position: absolute;
    float: right;
    right: 40px;
    top: 5px;
    z-index: 2;
}
.plotychart{
    :deep(.modebar){
        padding-right:20px ;
    }
}
</style>
<template>
    <v-container class="contain ma-0 pa-0">        
        <draggable class="containBox width100 height100 ma-0 pa-0" tag="div" handle=".mover" animation="500">
            <v-row key="row0" :style="currentScanType=='FIX' ? 'height:50%':'height:100%'" class=" ma-0 pa-0 width100 flex-shrink-0">                  
               <Scanmap></Scanmap>
            </v-row>  
            <v-row v-show="currentScanType=='FIX'" key="row1" :style="currentScanType=='FIX' ? 'height:50%':'height:0'" class=" ma-0 pa-0 width100 flex-shrink-0">                
                <draggable class="d-flex width100 height100 ma-0 pa-0" tag="div" :scroll="true" handle=".move" animation="500">
                    <Heatmap  class="hotChart moveBox" title="RCS时间热图" type="rcsmerge"/>  
                    <Heatmap  class="hotChart moveBox" title="消光系数时间热图" type="extmerge"/>   
                </draggable>
            </v-row>     
        </draggable> 
        <RHI v-show="currentScanType=='RHI'"></RHI>
    </v-container>
</template>
<script>
import transferDataType from '@/plugins/datas/transferDataType'
import {getAlarmPointsApi,getHistoryImagesApi,getGisApi,getReciverApi,sendAlarmToWxUserApi,getAlarmPointComputedsApi} from '@/api' 
import Heatmap from '@/components/monitoring/pls/realtime/Heatmap'
import RHI from '@/components/monitoring/pls/realtime/RHI'
import Scanmap from '@/components/monitoring/pls/realtime/Scanmap'
import Draggable from "vuedraggable"
export default {
    name:"monitoring.pls.realtime",
    components:{Heatmap,RHI,Draggable,Scanmap},
    data(){
        return {     
            currentScanType:'PPI',   
            rhiHeight:450,
            rhiWidth:850,
        }},
    computed:{
    },
    watch:{       
        "$store.state.storage_global.currentSite":{
            handler(n,v){                                
                var that = this;                  
                //垂直扫描        
                if($help.isNull(n.scanType)){
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                                that.currentScanType = obj.scanType;
                                // that.currentSite.scanType=obj.scanType;
                                // if(obj.scanType == "FIX"){
                                //     that.moveControll(true)
                                // }else if((obj.scanType == "RHI")){
                                //     that.moveControll(true,true,true)
                                //     //加载前一天数据至现在 没有的数据值填空            
                                // }else if((obj.scanType == "PPI")){
                                //     that.moveControll()
                                //     //加载前一天数据至现在 没有的数据值填空            
                                // }
                            }
                        }
                    })
                }else{
                    this.currentScanType = n.scanType
                }            
            }
        },
        "$store.state.storage_global.currentSite.scanType":{
            handler(n){
                this.currentScanType = n;
            }
        }
            
    },
    beforeDestroy(){       
    },
    mounted(){     
        var that = this;
        if($help.isNull(this.$store.state.storage_global.currentSite?.scanType)){
            this.$store.dispatch('storage_user/getLastestScanType',{
                site:this.$store.state.storage_global.currentSite,
                cb:(obj)=>{
                    if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                        that.currentScanType = obj.scanType;                        
                    }
                }
            })
        } else{
            this.currentScanType = this.$store.state.storage_global.currentSite?.scanType
        }           
    },
    methods:{               
          //重置大小
          resizeRhi(touch){  
            var that=this;          
           if(touch){
                touch.preventDefault()
                touch.stopPropagation()                
                // let moveEndX = e.changedTouches[0].pageX,moveEndY = e.changedTouches[0].pageY;
                // let ox = moveEndX - startX,oy = moveEndY - startY;
                let resetW = document.body.clientWidth - touch.changedTouches[0].pageX //this.width //(ox/10);
                let resetH = document.body.clientHeight - touch.changedTouches[0].pageY//this.height -1//(oy/10);        
                this.rhitWidth = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                this.rhiHeight = resetH > 0.88 * document.body.clientHeight ? 0.88 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                localStorage.setItem("rhiHeight",this.rhiHeight)
                localStorage.setItem("rhiWidth",this.rhiWidth)   

            }else{
                //监听鼠标移动
                let fun = (e)=>{                
                    //重设表格大小
                    let resetW =this.rhiWidth + e.movementX ;
                    let resetH = this.rhiHeight - e.movementY
                    this.rhiWidth = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                    this.rhiHeight = resetH > 0.88 * document.body.clientHeight ? 0.88 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                    localStorage.setItem("rhiHeight",this.rhiHeight)
                    localStorage.setItem("rhiWidth",this.rhiWidth)          
                }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{
                    document.removeEventListener('mousemove',fun)
                    
                    that.$refs.rhichart?.loadChart();
                });
            }     
        },       
    },
   
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}

//比例尺
:deep(.amap-scalecontrol){
    left:50px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
//垂直热图
.externalDatasBox{    
    flex-shrink: 0;
    position: relative;
    top:0;
    right:0px;  
    overflow: auto;
    max-height: 100%;
    width: 6
    0%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 100%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 50%;
    height: 100%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}
</style>

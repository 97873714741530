import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {  
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {    
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    let keyPath = key.slice(2)
    const moduleNames = keyPath.slice(0,keyPath.length - 8).split("/");    
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if(!messages[locale]){
        messages[locale] = {}
      } 
      let moduleInfo = messages[locale];
      let currentModule = moduleInfo;     
      for(var i in moduleNames){
        let moduleName = moduleNames[i];
        if(i == moduleNames.length -1){
          currentModule[moduleName] = locales(key)
        }else{
          if(!currentModule[moduleName]){
            currentModule[moduleName] = {} 
          }          
          currentModule = currentModule[moduleName]        
        }        
      }      
    }
  })

  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zh',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
  messages: loadLocaleMessages()
})

<template>
    <v-container class="contain ma-0 pa-0">
        <v-card height="100%">
            <v-card-actions class="mapToolbar mt-4 mx-4">
                <v-row class="ma-0">
                    <v-col>
                        <!-- <v-menu open-on-hover offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="false" v-bind="attrs" v-on="on">
                                    {{ currentScanMapType.name }}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item-group v-model="currentScanMapType">
                                    <v-list-item v-for="(item, index) in scanMapTypes" :key="index"
                                        :value="item">
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu> -->
                        <v-menu open-on-hover offset-y bottom max-height="400" z-index="10"
                            :close-on-content-click='false'>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ma-0 pl-2 pr-4 mx-4" v-bind="attrs" v-on="on" v-show="permissionUser.siteSelectShow"><v-icon
                                        class="mx-1">mdi-map-marker-radius</v-icon>
                                    <div class="selectSitesText">
                                        <!-- <v-chip label class="mx-1" small v-for="site in selectSites" :key="site.siteId">
                                        {{ site.siteName }}
                                    </v-chip> -->
                                        选择站点
                                    </div>
                                    <!-- {{selectSites.map(a=>{
                                    return a.siteName
                                }).join(",")}} -->
                                </v-btn>
                            </template>
                            <v-card>
                                <v-sheet class="pa-4 primary">
                                    <v-text-field v-model="search" label="搜索" dark flat solo-inverted
                                        hide-details dense clearable
                                        clear-icon="mdi-close-circle-outline"></v-text-field>
                                    <v-checkbox dark @click="selectAll" :input-value="checkedAll" dense
                                        hide-details label="全选"></v-checkbox>
                                </v-sheet>
                                <v-treeview item-text="siteName" item-key="siteId" multiple-active selectable
                                    dense selected-color="primary" :items="sites" :search="search"
                                    :filter="filter" return-object @input="selectSite"
                                    :value="selectSites"><template v-slot:append="{ item, open }">
                                        <v-icon v-if="!item.children" class="pointer" color="primary"
                                            @click="setLocal(item)">
                                            mdi-map-marker
                                        </v-icon>
                                    </template>
                                </v-treeview>
                            </v-card>
                            <!-- <v-list shaped>
                                <v-list-item-group multiple v-model="selectSites" @change="filterSelectSites">
                                    <div v-for="(i, index) in sites"                                                   
                                        :key="index" >                                                   
                                        <v-subheader> 
                                            <v-icon :color="i.sites.filter(a=>{ if(a.active){return a} }).length > 0 ? 'indigo darken-4' : ''">{{ i.sites.filter(a=>{ if(a.active){return a} }).length == 0 ? 'mdi-checkbox-blank-outline': (i.sites.length > i.sites.filter(a=>{ if(a.active){return a} }).length  ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                            {{i.key}}
                                        </v-subheader>
                                        <v-list-item
                                            dense
                                            class="my-1"
                                            v-for="(item, index) in i.sites"
                                            :key="index" 
                                            :value="item" 
                                            @click="item.active=true"                                                     
                                        >         
                                        <template v-slot:default="{ active }">   
                                        <v-list-item-content>                                                                                                                                                                                                                                             
                                            <v-list-item-title  :class="{'grey--text':item.commitStatus == 0}" class="d-flex justify-space-between align-center">
                                                <v-checkbox :true-value="item" :input-value="active" dense hide-details hide-spin-buttons class="ma-0 pa-0"></v-checkbox>   {{ item.siteName }}
                                                <v-spacer class="mx-2"></v-spacer>
                                                {{ item.transStatus == 1 ? $i18n.t("layout.label.stop"): item.commitStatus == 0 ? $i18n.t("layout.label.outline"):""}}
                                            </v-list-item-title>                                                
                                        </v-list-item-content>
                                        </template>
                                        </v-list-item>
                                    </div>                                                                                                
                                </v-list-item-group>
                            </v-list> -->
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-actions>
            <monitorPointDataCard type="0" ref="monitorPointDataCard" class="monitorpointCard" />
            <div ref="scanMap" class="map noevent">
            </div>
            <ColorBarConfig style="z-index: -1;"
                :dataKey="17" ref="colorBar_rcs"
                class="mapColorBarConfig" @update="configColorBar($event,'rcs')" />
            <ColorBarConfig style="z-index: -1;"
            :dataKey="15" ref="colorBar_rcsMerge"
            class="mapColorBarConfig" @change="configColorBar($event,'rcsMerge')" />
            <ColorBarConfig style="z-index: -1;"
            :dataKey="13" ref="colorBar_extMerge"
            class="mapColorBarConfig" @change="configColorBar($event,'extMerge')" />
            <ColorBarConfig
                :dataKey="2" ref="colorBar_sps"
                class="mapColorBarConfig" @change="configColorBar($event,'sps')" />           
            <div class="hotMapColorBar" v-show="hotLayer">
                <div :style="`background-color:${i.value}`" v-for="i in hotMapColorBarColor"
                    class="hotMapColorBarBox" :key="i.key">
                    <div class="hotMapColorBarText">{{ i.numStr }}</div>
                </div>
            </div>
            <v-expand-transition>
                <v-btn v-show="!showWarnList" class="mx-2 warnBtn pointer" @click="showWarnList = !showWarnList"
                    fab large color="rgba(0,0,0,0)">
                    <v-icon title="还原窗口" color="primary" size="48px">mdi-android</v-icon>
                </v-btn>
            </v-expand-transition>
            <v-expand-transition origin="bottom bottom">
                <v-card v-show="showWarnList" :width="width" :style="`height:${height}px`" class="warnInfo">
                    <v-data-table height="100%" :loading="loadingWarnInfo" fixed-header :headers="headers"
                        show-group-by :items="alarmList" item-key="_id" disable-pagination hide-default-footer
                        @click:row="setCurrentPoint" :item-class="() => { return '_tableActive' }"
                        calculate-widths>
                        <template v-slot:top>
                            <v-row dense no-gutters class="mx-2 mt-2 flex-shrink-0 flex-grow-0 ">
                                <v-col cols="1" class="d-flex align-start flex-grow-0 flex-shrink-0 pointer">
                                    <v-icon title="设置大小" @touchmove.stop="resize($event)"
                                        @mousedown="resize()">mdi-arrow-all</v-icon>
                                </v-col>
                                <v-col cols="3" class="flex-grow-0 flex-shrink-0 d-flex">
                                    <v-switch title="开启热力图"
                                        @change="setHotLayer($event); if ($event) { setOpacity(5) } else { setOpacity(7) }"
                                        v-model="hotLayer" class="mt-0" v-show="permissionUser.hotLayerShow"
                                        :label="$i18n.t('realTimeMonitor.table.topTool.hotBar')" hide-details
                                        dense>
                                    </v-switch>
                      
                                </v-col>
                                <v-col cols="3" class="flex-grow-0 flex-shrink-0 d-flex">
                                    <v-switch title="过滤"
                                        @change="loadAlarmPoints"
                                        v-model="alarmFilter"
                                         class="mt-0"                                    
                                        label="过滤" hide-details
                                        dense>
                                    </v-switch>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="sendDialog" scrollable persistent max-width="500px"
                                    hide-overlay>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mb-2 primary--text" text v-bind="attrs" v-show="permissionUser.pushMessageShow"
                                            v-on="on">推送</v-btn>
                                    </template>
                                    <v-card max-height="400">
                                        <v-card-title class="headline">选择发送人员</v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row v-for="siteInfo, i in siteScanInfoList" :key="i"
                                                    class="d-flex flex-column"
                                                    v-show="siteInfo.alarmList.length > 0">
                                                    <v-row v-show="siteInfo.alarmList.length > 0">
                                                        <v-card-subtitle>{{ siteInfo.site.siteName
                                                        }}报警</v-card-subtitle>
                                                    </v-row>
                                                    <v-row class="ma-0" v-show="siteInfo.alarmList.length > 0">
                                                        <v-select v-model="siteInfo.wxUserIds" :items="recivers"
                                                            :label="$i18n.t('noticeSendManage.label.form.reciver')"
                                                            multiple required>
                                                            <template v-slot:prepend-item>
                                                                <v-list-item ripple
                                                                    @click="selectAllReciver(siteInfo)">
                                                                    <v-list-item-action>
                                                                        <v-icon
                                                                            :color="siteInfo.wxUserIds.length > 0 ? 'indigo darken-4' : ''">{{
                                                                                siteInfo.wxUserIds.length == 0 ?
                                                                                'mdi-checkbox-blank-outline' :
                                                                                (recivers.length >
                                                                                    siteInfo.wxUserIds.length ?
                                                                                    'mdi-minus-box' : 'mdi-close-box')
                                                                            }}</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>选择全部</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-select>
                                                    </v-row>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green darken-1" text
                                                @click="sendDialog = false">取消</v-btn>
                                            <v-btn color="green darken-1" text
                                                @click="sendDialog = false; sendMessage()">立即发送</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-col cols="1"
                                    class="d-flex justify-end align-start flex-grow-0 flex-shrink-0 pointer">
                                    <v-icon title="最小化"
                                        @click="showWarnList = !showWarnList">mdi-chevron-down-circle</v-icon>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-expand-transition>
            <div class="configBtns">
                <v-switch title="显示时间" v-model="showTime" class="mt-0" label="时间" hide-details dense>
                </v-switch>
                <v-switch title="显示天气" @change="setWeather" v-model="showWeather" class="mt-0" label="天气"
                    hide-details dense>
                </v-switch>
                <v-switch title="显示国控点" @change="setMonitorPoint" class="mt-0" label="国控点" hide-details dense>
                </v-switch>
                <v-switch title="显示站点名" v-model="showSiteName" class="mt-0" label="显示站点名" hide-details dense>
                </v-switch>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import weatherType from '@/plugins/datas/weatherType.json'
import windAngle from '@/plugins/datas/windAngle'
import Plotly, { log } from 'plotly.js-dist'
import { getSitesAlarmPointsApi, getPlsRcsByLatestApi, getGisApi, sendAlarmToWxUserApi, getReciverApi, getPLSFIXDataApi } from '@/api'
import { new_marker_y } from '@/plugins/datas/mapmark.js';
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
import dataTypeColors from '@/plugins/datas/colors'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
import scanType from '@/plugins/datas/scanType'
export default {
    name: "monitor.pls.siteUnion",
    components: { ColorBarConfig ,monitorPointDataCard},
    data() {
        return {
            permissionUser:{
                hotLayerShow:true,
                siteSelectShow:true,
                pushMessageShow:true
            },
            alarmFilter:false,
            selectSites: [],//选中的站点id
            loadOver: false,
            map: null,
            mapEchart: null,
            center: [],
            mapContextMenuPositon: [],
            showTime: true,
            showSiteName: false,
            showWeather: false,
            weatherInfo: {
            },


            //限制视野
            zoomLimit: 10.16,

            sendDialog: false,//推送弹窗            
            recivers: [],

            //报警列表
            showWarnList: true,
            loadingWarnInfo: false,
            headers: [{
                text: this.$i18n.t('realTimeMonitor.table.header.city'),
                value: 'city',
                sortable: true,
                groupable: false,
                width: '13%'
            },
            {
                text: this.$i18n.t('realTimeMonitor.table.header.district'),
                value: 'district',
                sortable: true,
                groupable: true,
                width: '13%'
            },
            {
                text: this.$i18n.t('realTimeMonitor.table.header.street'),
                value: 'street',
                sortable: true,
                groupable: true,
                width: '13%'
            }, {
                text: this.$i18n.t('realTimeMonitor.table.header.position'),
                value: 'position',
                sortable: true,
                groupable: false,
                width: '36%'
            }, {
                text: this.$i18n.t('realTimeMonitor.table.header.count'),
                value: 'count',
                groupable: false,
                width: '5%'
            }, {
                text: this.$i18n.t('realTimeMonitor.table.header.time'),
                value: 'time',
                groupable: false,
                width: '20%'
            }],
            alarmList: [],
            warnMakerList: [],
            width: 0,
            height: 0,

            siteMarker: null,
            //画图
            siteScanInfoList: [],

            opacity: 0.7,
            setOpacity: null,
            monitorPoints: [],//控点
            monitorPointMarkers: [],    //控点标记
            timmer: null,
            clearChartFun: null,
            hiddenDom: false,
            hotLayer: false,
            color: {
                0.2: '#5e04fb',
                0.5: '#a0ff2f',
                0.7: '#fde468',
                1.0: '#d7191c'
            },

            //按钮
            scanMapTypes: [],//当前站点数据类型
            //currentScanMapType: "",           
            timeOut: null,
            drawTextTime: null,
            msgTime: null,
            search: "",
        }
    },
    computed: {
        sites() {
            var sites = []
            this.$store.dispatch("storage_global/getSitesByCurrentType", (d) => {
                sites = d;
            }, { root: true });
            var _siteList = $help.groupBy("province", sites);
            var siteList = [];
            for (var i in _siteList) {
                // siteList.push({
                //     key:i,
                //     sites:_siteList[i]
                // })
                siteList.push(
                    {
                        siteId: i,
                        siteName: i,
                        children: _siteList[i]
                    }
                )
            }
            return siteList;
        },
        checkedAll() {
            let selectSites = [];
            this.sites.forEach(a => {
                selectSites = selectSites.concat(a.children)
            })
            return selectSites.length == this.selectSites.length
        },
        filter() {
            return (item, search, textKey) => item[textKey].indexOf(search) > -1
        },
        icon: () => {
            return (key) => {
                var item = weatherType.find(a => a.name == key);
                if (item) {
                    return item.value
                } else {
                    return 'mdi-biohazard'
                }
            }
        },
        hotMapColorBarColor() {
            let _color = [];
            if (this.color && this.mapHotDatas) {
                //最大值
                let max = 0;
                this.mapHotDatas.map(a => {
                    if (a.count > max) max = a.count;
                });
                for (var i in this.color) {
                    _color.push({
                        numkey: Number(i),
                        value: this.color[i]
                    })
                }
                _color = _color.sort((a, b) => {
                    return b.numkey - a.numkey;
                })
                var _colorInfos = _color.map((a, i) => {
                    return {
                        key: i,
                        value: a.value,
                        numkey: a.numkey,
                        numStr: i == _color.length - 1 ? '0' : Math.floor(_color[Number(i) + 1].numkey * max) + '-' +
                            Math.floor(a.numkey * max)
                    }
                })
                return _colorInfos
            }
            return []
        },            
    },
    watch: {
        "$vuetify.theme.isDark": {
            handler(n, v) {
                if (n) {
                    if (this.map) {
                        this.map.setMapStyle('amap://styles/dark')
                    }
                } else {
                    if (this.map) {
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },        
        "$store.state.storage_global.message": {
            handler(n) {
                if (this.map) {
                    let _site = this.selectSites.find(a => {
                        return a.siteId == n.siteId;
                    })
                    if (_site) {
                        let _currentSiteInfo;
                        this.getCurrentSiteInfo((d) => {
                            _currentSiteInfo = d
                            if (_currentSiteInfo.alarmList.length > 0 && n.date != _currentSiteInfo.alarmList[0].time) {
                                _currentSiteInfo.alarmList = [];
                            }
                            _currentSiteInfo.alarmList.push({
                                time: n.date,
                                lng: n.lng,
                                lat: n.lat,
                                position: n.position?.formatted_address,
                                province: n.position?.addressComponent.province,
                                city: n.position?.addressComponent.city == '' ? n.position?.addressComponent.province : n.position?.addressComponent.city,
                                street: n.position?.addressComponent.township,
                                district: n.position?.addressComponent.district,
                                count: n.count
                            })
                        }, _site)


                        //添加报警点到地图
                        var that = this;
                        if (this.msgTime) {
                            clearTimeout(this.msgTime)
                        }
                        this.msgTime = setTimeout(() => {
                            that.alarmList = [];
                            for (var i of that.siteScanInfoList) {
                                that.alarmList = that.alarmList.concat(i.alarmList)
                            }
                            that.addWarnPointToMap();
                        }, 200);
                    }
                }
            }
        },
        selectSites: {
            handler(n) {
                if (this.loadOver && this.map) {
                    this.resetSiteMaker(n);
                }
            }
        },    
        // currentScanMapType: {
        //     handler() {
        //         this.siteScanInfoList.forEach(a => {
        //             a.PPI.data = [];
        //             if (a.PPI.ctx) {
        //                 a.PPI.ctx.clearRect(0, 0, a.PPI.canvas.width, a.PPI.canvas.height)
        //                 if (a.PPI.canvasLayer) {
        //                     a.PPI.canvasLayer.reFresh()
        //                 }
        //             }
        //         });
        //         this.configCurrentSiteTypeInfo();

        //     }
        // },        
    },    
    beforeDestroy() {
        this.siteScanInfoList.forEach(a=>a.watch())
        if (this.timmer) {
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if (this.mapEchart != null) {
            this.mapEchart.clear()
            this.mapEchart = null;
        }
        document.removeEventListener("visibilitychange", this.clearChartFun)
    },
    mounted() {
        var that = this;      
        var target = that.$refs.scanMap
        if(this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsSiteUnion){
            this.permissionUser = this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsSiteUnion
        }
        this.$nextTick(() => {
            let sites = [];
            this.$store.dispatch("storage_global/getSitesByCurrentType", (d) => {
                sites = d;
                that.selectSites = sites;
                this.load();
            }, { root: true });

        })
        this.loadReciver();
        //加载控点
        this.$store.dispatch("storage_global/getMonitorPoints", (d) => {
            that.monitorPoints = d;
        })

        this.resetWarnTableSize()
        this.clearChartFun = () => {
            if (document.hidden) {
                that.hiddenDom = true;
                // 清除      
                that.$nextTick(() => {
                    that.mapEchart = echarts.getInstanceByDom(target);
                    if (that.mapEchart != null) {
                        that.mapEchart.clear();
                    }
                })

            } else {
                that.hiddenDom = false;
                // 开启 
                that.$nextTick(() => {
                    that.mapEchart = echarts.getInstanceByDom(target);
                    if (that.mapEchart != null) {
                        that.loadMap()
                        //that.addWarnPointToMap();  
                        if (that.hotLayer) {
                            that.setHotLayer(that.hotLayer)
                        }
                    }
                })
            }
        };
        document.addEventListener("visibilitychange", this.clearChartFun);
    },
    methods: {
        async drawItem(_currentSiteInfo,scanMode) {
            var scanMode =scanMode?scanMode: _currentSiteInfo?.site?.scanType;            
            if(!scanMode)
            {
                //查找扫描方式
                this.$store.dispatch('storage_user/getLastestScanType',{
                    site:_currentSiteInfo.site,
                    cb:(obj)=>{
                        if(obj.siteId == _currentSiteInfo.site.siteId){
                            _currentSiteInfo.site.scanType=obj.scanType;
                            scanMode=obj.scanType;
                        }
                    }
                })
            }
            if (scanMode) {
                if (scanMode == "PPI" && _currentSiteInfo.PPI?.data && _currentSiteInfo.PPI?.data?.length > 0) {                    
                    if (!this.hiddenDom && _currentSiteInfo.playStatus) {                        
                        this.draw(_currentSiteInfo.site)
                    }
                    _currentSiteInfo.time = new Date(_currentSiteInfo.PPI.data[_currentSiteInfo.PPI.data.length - 1].dataTime.replace("T", " ")).pattern("yyyy-MM-dd HH:mm:ss");
                }

                if (scanMode == "RHI") {                    
                    this.loadDataRHI(_currentSiteInfo);
                    if (!this.hiddenDom && _currentSiteInfo.playStatus) {
                        if ((!_currentSiteInfo.RHI?.data || _currentSiteInfo.RHI?.data?.length == 0) && !_currentSiteInfo.RHI.loadHistory) {
                            _currentSiteInfo.RHI.loadHistory = true;
                            this.loadDataRHI(_currentSiteInfo);
                        }                       
                    }
                    if (_currentSiteInfo.RHI?.data && _currentSiteInfo.RHI?.data?.length > 0)
                        _currentSiteInfo.time = new Date(_currentSiteInfo.RHI?.data[_currentSiteInfo.RHI?.data?.length - 1]?.dataTime.replace("T", " ")).pattern("yyyy-MM-dd HH:mm:ss");
                }
                if (scanMode == "FIX") {                   
                    if (!this.hiddenDom && _currentSiteInfo.playStatus) {

                        if ((!_currentSiteInfo.FIX.Rcs?.data || _currentSiteInfo.FIX?.Rcs?.data.length == 0)&& !_currentSiteInfo.FIX.loadHistory) {
                            _currentSiteInfo.FIX.loadHistory = true;                    
                            let start = new Date (new Date ().getTime()-24*60*60*1000).pattern('yyyy-MM-dd HH:mm:ss');
                            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
                            this.loadData(start,end,_currentSiteInfo,'rcsmerge');
                            this.loadData(start,end,_currentSiteInfo,'extmerge')    
                        }                       
                    }
                    //写一种就行

                    if (_currentSiteInfo.FIX?.Rcs?.data && _currentSiteInfo.FIX?.Rcs?.data?.length > 0)
                        _currentSiteInfo.time = new Date(_currentSiteInfo.FIX.Rcs.data[_currentSiteInfo.FIX.Rcs.data.length - 1].dataTime.replace("T", " ")).pattern("yyyy-MM-dd HH:mm:ss");
                }
                this.drawText()
            }           
        },
        load() {            
            this.loadMap();
            //加载地图               
        },
        selectSite(sites) {
            this.selectSites = sites;
        },
        selectAll() {
            let selectSites = [];
            this.sites.forEach(a => {
                selectSites = selectSites.concat(a.children)
            })
            if (this.selectSites.length == selectSites.length) {
                this.selectSites = []
            } else {

                this.selectSites = selectSites;
            }
        },
        setLocal(item) {
            if (this.map) {
                this.map.setCenter([item.lng, item.lat])
                this.map.setZoom(11)
            }
        },
        resetSiteMaker() {
            var that = this;
            if (this.timeOut) {
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(function () {
                if (!that.map && that.siteScanInfoList.length == 0) {
                    return;
                }
                //清空扫描区域信息    
                let oldmarks = that.siteScanInfoList.map(a => {
                    return a.siteMarker;
                }).filter(a => {
                    return a != null;
                })
                if (oldmarks.length > 0) {
                    that.map.remove(oldmarks);
                }
                let markers = [];
                that.siteScanInfoList = that.siteScanInfoList.filter(a => {
                    let _item = that.selectSites.find(b => {
                        return b.siteId == a.site.siteId
                    })
                    if (_item) {
                        return a;
                    } else {
                        a?.FIX?.Ext?.canvasLayer?.hide()
                        a?.FIX?.Rcs?.canvasLayer?.hide()
                        a?.PPI?.canvasLayer?.hide()
                        a?.RHI?.canvasLayer?.hide()   
                        a.watch()                 
                        console.log(a)
                    }
                })
                for (var site of that.selectSites) {
                    let currentSiteInfo;
                    that.getCurrentSiteInfo((d) => { currentSiteInfo = d }, site);
                    //添加marker
                    currentSiteInfo.siteMarker = new AMap.Marker({
                        content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                        </svg>`,  // 自定义点标记覆盖物内容
                        position: [site.lng, site.lat], // 基点位置
                        offset: new AMap.Pixel(-12, -16), // 相对于基点的偏移位置                                    
                    });
                   

                    currentSiteInfo.siteMarker.on('click', (e) => {
                        let _currsite = currentSiteInfo.site;
                        var htm = `<div>
                                <div style="margin: 10px 5px;">${_currsite.siteName}</div>
                                <div style="margin: 10px 5px;">位置：${_currsite.localtion}</div> 
                                <div style="margin: 10px 5px;">经纬度：${_currsite.lng},${_currsite.lat}</div>   
                                <div style="margin: 10px 5px;">部署时间：${new Date(_currsite.deploytime.replace("T", " ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                                <div style="margin: 10px 5px;float:right"><a id='' href="javascript: void(0)">前往查看</a></div>  
                                </div>`
                        var _el = $help.toDom(htm);
                        for (var a of _el[0].children) {
                            if (a.children && a.children.length > 0) {
                                let _link = a.children[0].tagName && (a.children[0].tagName == "a" || a.children[0].tagName == "A") ? a.children[0] : null;
                                if (_link) {
                                    _link.onclick = function () {
                                        that.$store.commit('storage_global/updateCurrentSite', _currsite, { root: true })
                                        that.$router.push({ path: '/monitoring/pls/realtime' })
                                    }
                                }
                            }
                        }
                        new AMap.InfoWindow({
                            content: _el[0],
                        }).open(that.map, e.lnglat)
                    })
                    that.map.add(currentSiteInfo.siteMarker)
                    markers.push(currentSiteInfo.siteMarker)          
                }
                
                if (that.map) {
                    let _markers = markers.filter(a => {
                        return a != null && typeof (a) != 'undefined'
                    })
                    if (_markers.length > 0) {
                        that.map.setFitView(_markers, true, null, 13)
                    }
                    setTimeout(() => {
                        that.loadOver = true;
                    }, 500);
                    that.loadAlarmPoints();
                } else {
                    that.map.on('complete', () => {
                        let _markers = markers.filter(a => {
                            return a != null && typeof (a) != 'undefined'
                        })
                        if (_markers.length > 0) {
                            that.map.setFitView(_markers, true, null, 13)
                        }
                        setTimeout(() => {
                            that.loadOver = true;
                        }, 200);
                        that.loadAlarmPoints();
                    })
                }
            }, 200);
        },
        getCurrentSiteInfo(cb, site) {
            var that = this;
            let _currentSiteInfo = this.siteScanInfoList.find(a => {
                return a.site.siteId == site.siteId;
            })
       
            if (!_currentSiteInfo) {
                let scanDistance = 6
                if(!$help.isNull(site.extendInfo)){
                    let extendInfo = JSON.parse(site.extendInfo)
                    scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6
                }                
                _currentSiteInfo = {
                    playStatus: false,
                    site: site,
                    scanDistance:scanDistance,
                    watch:null,
                    PPI: {
                        data: [],
                        canvas: null,
                        ctx: null,
                        canvasLayer: null,
                        loadHistory: false
                    },
                    FIX: {
                        Rcs: {
                            abl: [],
                            data: [],
                            el: null,
                            canvas: null,
                            ctx: null,
                            canvasLayer: null
                        },
                        Ext: {
                            abl: [],
                            data: [],
                            el: null,
                            canvas: null,
                            ctx: null,
                            canvasLayer: null,
                         
                        },
                        loadHistory: false
                    },
                    RHI: {
                        data: [],
                        el: null,
                        canvas: null,
                        ctx: null,
                        canvasLayer: null,
                        loadHistory: false
                    },
                    wxUserIds: [],//微信报警发送人

                    noDateCount: 0,
                    weatherInfo: {
                        position: "",
                        windPower: "",
                        temperature: "",
                        windDirection: '',
                        humidity: '',
                        weather: ''
                    },
                    time: "",
                    selectAllReciverMarker: null,//站点标记                    
                    alarmList: [],
                    //热力图
                    mapHotLayer: null,
                    mapHotDatas: [],
                    color: {
                        0.2: '#5e04fb',
                        0.5: '#a0ff2f',
                        0.7: '#fde468',
                        1.0: '#d7191c'
                    },
                }
                if (!_currentSiteInfo.site.weatherInfo || (_currentSiteInfo.site.weatherUpdateTime.getTime() - new Date().getTime()) / 1000 / 60 / 60 > 1) {
                    //获取当前站点天气                                        
                    getWeather(_currentSiteInfo.site.district, (data) => {
                        let weatherInfo = {
                            position: data.city,
                            windPower: data.windPower,
                            temperature: data.temperature,
                            windDirection: data.windDirection,
                            humidity: data.humidity,
                            weather: data.weather
                        }
                        that.$store.commit('storage_user/updateSiteWeather', {
                            siteId: _currentSiteInfo.site.siteId,
                            weatherInfo: weatherInfo
                        }, { root: true })
                        that.weatherInfo = {
                            position: data.city,
                            windPower: data.windPower,
                            temperature: data.temperature,
                            windDirection: data.windDirection,
                            humidity: data.humidity,
                            weather: data.weather
                        }
                        _currentSiteInfo.weatherInfo = {
                            position: data.city,
                            windPower: data.windPower,
                            temperature: data.temperature,
                            windDirection: data.windDirection,
                            humidity: data.humidity,
                            weather: data.weather
                        }
                    })
                } else {
                    _currentSiteInfo.weatherInfo = site.weatherInfo
                }

                //启动监听
                _currentSiteInfo.watch = that.$watch(`$store.state.storage_global.siteData.${site.siteId}`,(n,v)=>{
                        if (that.map) {                           
                            let site = that.selectSites?.find(a => {
                                return a.siteId == n.SiteId
                            })
                            if (site) {
                                let _currentSiteInfo;
                                that.getCurrentSiteInfo((d) => {
                                    _currentSiteInfo = d
                                }, site)
                                let json = JSON.parse(JSON.stringify(n.jsonData));
                                //划分扫描方式  
                                if (json[0].scanType == "PPI" && n.DataType == transferDataType.sps.value) {
                                    if (_currentSiteInfo.PPI.ctx) {
                                        _currentSiteInfo.PPI.ctx.clearRect(0, 0, _currentSiteInfo.PPI.canvas.width, _currentSiteInfo.PPI.canvas.height)
                                    }                               
                                    if (_currentSiteInfo.PPI.data.length > 0 && (json[0].periodId != _currentSiteInfo.PPI.data[0].periodId || n.DataType != _currentSiteInfo.PPI.data[0].dataType)) {
                                        _currentSiteInfo.PPI.data = [];
                                    }
                                    _currentSiteInfo.PPI.data = _currentSiteInfo.PPI.data.concat(json)
                                    _currentSiteInfo.PPI.data = _currentSiteInfo.PPI.data.sort((a, b) => {
                                        return new Date(a.dataTime) - new Date(b.dataTime)
                                    })                                   
                                    that.drawItem(_currentSiteInfo, "PPI")
                                        
                                }
                                else if (json[0].scanType == "RHI" && n.DataType == transferDataType.rcs.value ) {
                                    _currentSiteInfo.RHI.data = json;
                                    that.drawItem(_currentSiteInfo, "RHI")
                                }
                                else if (json[0].scanType == "FIX") {
                                    if (json[0].dataType == transferDataType.rcsMerge.value&& json[0].mergeType == '0') {
                                        _currentSiteInfo.FIX.Rcs.data = _currentSiteInfo.FIX.Rcs.data.concat(json).sort((a, b) => {
                                            return new Date(a.dataTime) - new Date(b.dataTime)
                                        })
                                    }
                                    if (json[0].dataType == transferDataType.extMerge.value&& json[0].mergeType == '0') {
                                        _currentSiteInfo.FIX.Ext.data = _currentSiteInfo.FIX.Ext.data.concat(json).sort((a, b) => {
                                            return new Date(a.dataTime) - new Date(b.dataTime)
                                        })
                                    }
                                    that.drawItem(_currentSiteInfo, "FIX")
                                }
                            }
                        }
                    }) 
                that.siteScanInfoList.push(_currentSiteInfo)
            }
            cb(_currentSiteInfo)
        },
        configColorBar(item,type) {            
            if (item) {                
                if(type == 'sps'){
                    for (var i of this.siteScanInfoList) {                    
                        this.drawItem(i);
                    }
                }                                
            }
        },       
        loadMap() {
            if (this.selectSites.length == 0) {
                return;
            }
            let target = this.$refs.scanMap;
            var isDark = this.$vuetify.theme.isDark;
            let theme = isDark ? 'amap://styles/dark' : 'amap://styles/macaron';
            let zoom = 12.5;
            let mapEchart = echarts.getInstanceByDom(target);
            if (mapEchart == null) {
                mapEchart = echarts.init(target);
            }
            mapEchart.clear()
            for (var i of this.siteScanInfoList) {
                if (i.PPI.canvasLayer) {
                    this.map.remove(i.PPI.canvasLayer)
                    i.PPI.canvasLayer = null;
                }
                if (i.FIX.Rcs.canvasLayer) {
                    this.map.remove(i.FIX.Rcs.canvasLayer)
                    i.FIX.Rcs.canvasLayer = null;
                }
                if (i.FIX.Ext.canvasLayer) {
                    this.map.remove(i.FIX.Ext.canvasLayer)
                    i.FIX.Ext.canvasLayer = null;
                }
                if (i.RHI.canvasLayer) {
                    this.map.remove(i.RHI.canvasLayer)
                    i.RHI.canvasLayer = null;
                }
            }

            let amap = {
                // 高德地图中心经纬度
                center: [this.selectSites[0].lng, this.selectSites[0].lat],
                rotateEnable: true,
                pitchEnable: true,
                isHotspot: false,
                pitch: 0,
                rotation: 0,//-55
                zoom: zoom,//级别
                zooms: [2, 30],
                viewMode: '3D',//使用3D视图                        
                // 启用resize
                resizeEnable: true,
                // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                renderOnMoving: true,
                echartsLayerInteractive: true,
                largeMode: false,
                returnMapCameraState: true,
                // 自定义地图样式主题
                //53dd45a35fd6d89a2303f51cad6cce14
                mapStyle: theme
            };

            let option = {
                amap: amap
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl();
            var that = this;
            this.resetSiteMaker()

            //添加站点位置
            this.map.on('zoomend', (el) => {
                let _zoom = el?.target?.view?.P[4]
                if (_zoom && _zoom > that.zoomLimit) {
                    that.siteScanInfoList.forEach(a => {
                        a.playStatus = true;
                        if (a.PPI.canvasLayer) {
                            a.PPI.canvasLayer.show()
                        }
                        if (a.FIX.Rcs.canvasLayer) {
                            a.FIX.Rcs.canvasLayer.show()
                        }
                        if (a.FIX.Ext.canvasLayer) {
                            a.FIX.Ext.canvasLayer.show()
                        }
                        if (a.RHI.canvasLayer) {
                            a.RHI.canvasLayer.show()
                        }
                    })
                } else {
                    that.siteScanInfoList.forEach(a => {
                        a.playStatus = false;
                        if (a.PPI.canvasLayer) {
                            a.PPI.canvasLayer.hide()
                        }
                        if (a.FIX.Rcs.canvasLayer) {
                            a.FIX.Rcs.canvasLayer.hide()
                        }
                        if (a.FIX.Ext.canvasLayer) {
                            a.FIX.Ext.canvasLayer.hide()
                        }
                        if (a.RHI.canvasLayer) {
                            a.RHI.canvasLayer.hide()
                        }
                    })
                }
            })
            this.map.on('moveend', (el) => {
                let elmap = that.map.getContainer();
                let _currentZoom = that.map.getZoom();
                if (_currentZoom > that.zoomLimit) {
                    that.siteScanInfoList.forEach(a => {
                        let _position = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                        let _refresh = false;

                        if (_position.x > 0 && _position.y > 0 && _position.x < elmap.clientWidth && _position.y < elmap.clientHeight) {
                            _refresh = !a.playStatus;
                            a.playStatus = true

                        } else {
                            _refresh = a.playStatus;
                            a.playStatus = false;
                        }
                        if (_refresh) that.drawItem(a);
                        that.drawItem(a);
                    })
                }
                that.drawText();
            })

        },
        setWeather(open) {
            if (open) {
                for (var i of this.siteScanInfoList) {
                    let Item = i;
                    getWeather(i.site.district, (data) => {
                        Item.weatherInfo = {
                            position: data.city,
                            windPower: data.windPower,
                            temperature: data.temperature,
                            windDirection: data.windDirection,
                            humidity: data.humidity,
                            weather: data.weather
                        }
                    })
                }
            }
            this.drawText();
        },
        setMonitorPoint(open) {
            if (open) {
                var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
            } else {
                this.map.remove(this.monitorPointMarkers)
            }

        },        
        addControl() {
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {
                //比例尺
                var scale = new AMap.Scale(
                    {
                        position: [
                            {
                                top: '110px',
                                right: '40px'
                            }
                        ]
                    }
                );
                that.map.addControl(scale);

                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: { bottom: '260px', left: '50px' },
                    theme: 'myself'
                }));

                //3d指南针控件
                var controlBar = new AMap.ControlBar({
                    position: { bottom: '0px', left: '-70px' },
                    theme: 'myself'
                });
                that.map.addControl(controlBar)

                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:200px;left:50px;';
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min = "1" max = "10" value = "${7}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>7</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");
                var _rest = html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = (value) => {
                    _slider.value = value;
                    _numdiv.innerHTML = value;
                    //重置maphelper
                    that.opacity = value / 10;
                    for (var i of that.siteScanInfoList) {
                        if (i.PPI.canvasLayer) {
                            i.PPI.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.FIX.Rcs.canvasLayer) {
                            i.FIX.Rcs.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.FIX.Ext.canvasLayer) {
                            i.FIX.Ext.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.RHI.canvasLayer) {
                            i.RHI.canvasLayer.setOpacity(value / 10)
                        }
                    }
                }
                _rest.addEventListener('click', (e) => {
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    for (var i of that.siteScanInfoList) {
                        if (i.PPI.canvasLayer) {
                            i.PPI.canvasLayer.setOpacity(0.7)
                        }
                        if (i.FIX.Rcs.canvasLayer) {
                            i.FIX.Rcs.canvasLayer.setOpacity(0.7)
                        }
                        if (i.FIX.Ext.canvasLayer) {
                            i.FIX.Ext.canvasLayer.setOpacity(0.7)
                        }
                        if (i.RHI.canvasLayer) {
                            i.RHI.canvasLayer.setOpacity(0.7)
                        }
                    }
                })
                _slider.addEventListener('change', (e) => {
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;
                    //重置maphelper
                    that.opacity = value / 10;
                    for (var i of that.siteScanInfoList) {
                        if (i.PPI.canvasLayer) {
                            i.PPI.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.FIX.Rcs.canvasLayer) {
                            i.FIX.Rcs.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.FIX.Ext.canvasLayer) {
                            i.FIX.Ext.canvasLayer.setOpacity(value / 10)
                        }
                        if (i.RHI.canvasLayer) {
                            i.RHI.canvasLayer.setOpacity(value / 10)
                        }
                    }
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);
            })
        },
        addContextMenu() {
            var that = this;
            //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng, that.mapContextMenuPositon.lat]
                getAddressByLngLats({ lnglats: [lnglat] }, function (result) {
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;
                    new AMap.InfoWindow({
                        content: msg,
                    }).open(that.map, lnglat);
                })

            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });
        },
        //设置报警点在地图位置
        setCurrentPoint(event, data) {
            //设置当前活动
            let childrenList = event.target?.parentElement?.parentElement?.children;
            for (var i in childrenList) {
                if (i == "length") {
                    continue;
                }
                if (i == data.index) {
                    childrenList[i].className = 'table-row-active'
                } else {
                    childrenList[i].className = ''
                }

            }
            let item = data.item;
            var msg = `<div style="font-size:16px;font-weight:600;">报警点信息</div>
            <div>位置：${item.position}</div>
            <div>经纬度：${item.lng},${item.lat}</div>
            <div>次数：${item.count}</div>`

            let InfoWindowMap = new AMap.InfoWindow({
                position: [item.lng, item.lat],
                content: msg,
            });
            InfoWindowMap.open(this.map)

            //设置中心
            let pix = this.map.lngLatToContainer([item.lng, item.lat])
            let conSize = this.map.getSize();
            if (pix.x <= 0 || pix.y <= 0 || pix.x >= conSize.width || pix.y >= conSize.height) {
                this.map.setCenter([item.lng, item.lat])
            }
        },
        loadAlarmPoints() {
            this.alarmList = [];
            let start = new Date(new Date().setHours(new Date().getHours() - 6)).pattern("yyyy-MM-dd HH:mm:ss") //前12个小时热图
            let end = new Date().pattern("yyyy-MM-dd HH:mm:ss")//
            var that = this;
            if (this.selectSites.length <= 0) {
                return;
            }
            getSitesAlarmPointsApi({
                filter:this.alarmFilter,
                params: {                    
                    siteIds: this.selectSites.map(a => { return a.siteId }),
                    StartTime: start,
                    EndTime: end,
                    PageIndex: 1,
                    PageSize: 5000,
                    DapTypeId: 6,
                },
                success: (d) => {
                    let group = []
                    let data = d.Data;
                    for (var dt of data) {
                        let item = group.find(a => {
                            return a.DapSiteId == dt.DapSiteId && a.DapLng == dt.DapLng && a.DapLat == dt.DapLat
                        })
                        if (item) {
                            item.DapLevel += dt.DapLevel;
                            item.DapCount += dt.DapCount;
                        }
                        else {
                            group.push(JSON.parse(JSON.stringify(dt)));
                        }
                    }
                    that.mapHotDatas = group.map(a => {
                        return {
                            lnglat: [a.DapLng, a.DapLat],
                            count: a.DapCount
                        }
                    })

                    //如果热力图打开则重新展示
                    if (that.hotLayer) {
                        that.setHotLayer(that.hotLayer)
                    }

                    // //获得最近一批  
                    for (var i of that.siteScanInfoList) {
                        let item = d.Data.filter(a => {
                            return a.DapSiteId == i.site.siteId;
                        })
                        item = item.sort((b, c) => {
                            return new Date(c.DapTime.replace("T", " ")) - new Date(b.DapTime.replace("T", " "))
                        });                   
                        let alarmList = item.filter(a => {                            
                            return a.DapTime == item[0].DapTime && (new Date().getTime() - new Date(a.DapTime.replace("T", " ")).getTime()) / 1000/60 < 30 
                        })
                        console.log(alarmList)
                        // //每20个一组      
                        for (let j = 0; j < alarmList.length;) {
                            let _alarmList = alarmList.slice(j, j += 20);
                            let lnglatstr = _alarmList.map(a => {
                                return `${a.DapLng},${a.DapLat}`
                            }).join('|')

                            getGisApi({
                                params: {
                                    location: lnglatstr
                                }, success: (f) => {

                                    _alarmList.forEach(a => {
                                        var gisInfo = f.find(b => {
                                            var lnglat = b.GrLngLat.split(',');
                                            return Number(lnglat[0]).toFixed(4) == a.DapLng.toFixed(4) && Number(lnglat[1]).toFixed(4) == a.DapLat.toFixed(4)
                                        })
                                        if (gisInfo) {
                                            var position = JSON.parse(gisInfo.GrLoc)
                                            var entity = {
                                                siteId: a.DapSiteId,
                                                time: new Date(a.DapTime.replace("T", " ")).pattern('yyyy-MM-dd HH:mm:ss'),
                                                lng: a.DapLng,
                                                lat: a.DapLat,
                                                position:position?.formatted_address
                                                .replace(position?.addressComponent.province,'')
                                                .replace(position?.addressComponent.city,'')
                                                .replace(position?.addressComponent.township,'')
                                                .replace(position?.addressComponent.district,''),
                                                province:position?.addressComponent.province,
                                                city: position?.addressComponent.city == "" ? position?.addressComponent.province : position?.addressComponent.city,
                                                street: position?.addressComponent.township,
                                                district: position?.addressComponent.district,
                                                count: a.DapCount
                                            };
                                            that.alarmList.push(entity)
                                            let _currSiteScanInfo = that.siteScanInfoList.find(b => {
                                                return b.site.siteId == a.DapSiteId;
                                            })
                                            if (_currSiteScanInfo) {
                                                _currSiteScanInfo.alarmList.push(entity)
                                            }
                                        }
                                    })
                                    //添加报警点到地图
                                    that.addWarnPointToMap();

                                }
                            })
                        }
                    }
                }
            })
        },
        addWarnPointToMap() {
            var that = this;
            if (this.warnMakerList.length > 0) {
                this.map.remove(this.warnMakerList)
            }
            new AMap.InfoWindow().close();
            this.warnMakerList = [];
            this.alarmList.forEach(a => {
                let marker_item = new AMap.Marker({
                    position: [a.lng, a.lat],
                    content: new_marker_y,
                    anchor: 'bottom-center',
                    offset: new AMap.Pixel(0, 15),
                    zIndex: 200
                })

                this.warnMakerList.push(marker_item)
                marker_item.on("click", function (ev) {
                    that.setCurrentPoint(ev,{item:a})
                })
            })
            if (this.warnMakerList.length > 0) {
                this.map.add(this.warnMakerList)
            }
        },
        //重置大小
        resize(touch) {
            if (touch) {
                touch.preventDefault()
                touch.stopPropagation()
                // let moveEndX = e.changedTouches[0].pageX,moveEndY = e.changedTouches[0].pageY;
                // let ox = moveEndX - startX,oy = moveEndY - startY;
                let resetW = document.body.clientWidth - touch.changedTouches[0].pageX //this.width //(ox/10);
                let resetH = document.body.clientHeight - touch.changedTouches[0].pageY//this.height -1//(oy/10);        
                this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                this.height = resetH > 0.88 * document.body.clientHeight ? 0.88 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                localStorage.setItem("warnTableMHeight", this.height)
                localStorage.setItem("warnTableMWidth", this.width)

            } else {
                //监听鼠标移动
                let fun = (e) => {
                    //重设表格大小
                    let resetW = this.width - e.movementX;
                    let resetH = this.height - e.movementY
                    this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                    this.height = resetH > 0.88 * document.body.clientHeight ? 0.88 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                    localStorage.setItem("warnTableMHeight", this.height)
                    localStorage.setItem("warnTableMWidth", this.width)
                }
                document.addEventListener('mousemove', fun)
                document.addEventListener('mouseup', () => {
                    document.removeEventListener('mousemove', fun)
                });
            }
        },
        resetWarnTableSize() {
            if (localStorage.getItem("warnTableHeight")) {
                this.height = localStorage.getItem("warnTableHeight")
            }
            else {
                this.height = document.body.clientHeight * 0.38
            }
            if (localStorage.getItem("warnTableWidth")) {
                this.width = localStorage.getItem("warnTableWidth")
            }
            else {
                this.width = document.body.clientWidth * 0.46;
            }
        },
        //ppi rhi扫描绘图    
        draw(site, type) {
            var that = this;
            let currentSiteInfo = this.siteScanInfoList.find(a => {
                return a.site.siteId == site.siteId
            })
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                                             
            if(!currentSiteInfo.playStatus){
                return;
            }
            if (currentSiteInfo.site?.scanType == "PPI") {                
                if (!currentSiteInfo || currentSiteInfo.PPI.data.length == 0) {
                    return
                }
                let _w = 400;
                if (!currentSiteInfo.PPI.canvas) {
                    let canvas = document.createElement('canvas')
                    //canvas = this.$refs.polar;
                    let ctx = canvas.getContext("2d");
                    canvas.width = _w;
                    canvas.height = _w;
                    currentSiteInfo.PPI.canvas = canvas;
                    currentSiteInfo.PPI.ctx = ctx;
                }
                //let site = this.$store.state.storage_global.currentSite;            
                let arr = $help.getLayerMapPosition(Number(currentSiteInfo.PPI.data[0].angleOfPitch),currentSiteInfo.scanDistance,[currentSiteInfo.site.lng, currentSiteInfo.site.lat])                            
                var bounds = new AMap.Bounds(arr[0], arr[1])
                if (!currentSiteInfo.PPI.canvasLayer) {
                    currentSiteInfo.PPI.canvasLayer = new AMap.CanvasLayer({
                        canvas: currentSiteInfo.PPI.canvas,
                        bounds: bounds,
                        opacity: this.opacity,
                        zooms: [2, 30]
                    });
                    this.map.add(currentSiteInfo.PPI.canvasLayer);
                } else {
                    currentSiteInfo.PPI.canvasLayer.setBounds(bounds)
                }
                //插值画法                                    
                this.drawHelper(currentSiteInfo,_w/2/currentSiteInfo.scanDistance);
            } else if (currentSiteInfo.site.scanType == "RHI") {                
                if (!currentSiteInfo || currentSiteInfo.RHI.data.length == 0) {
                    return
                }
                let d = currentSiteInfo.scanDistance
                let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
                let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);  
                let _datas =currentSiteInfo.RHI.data.map(a=>{
                    return {
                        angle:a.angleOfPitch,
                        data:a.data,                    
                        dataTime:a.dataTime,
                        distanceData:a.distanceData,       
                    }
                }); 
                if(_datas.length == 0)     {
                    return
                }   
                if (!currentSiteInfo.RHI.el) {
                    let div = document.createElement('div')
                    div.style.width = '600px'
                    div.style.height = '300px'
                    currentSiteInfo.RHI.el = div                     
                }   
                let target = currentSiteInfo.RHI.el;
                let distanceData =_datas[0].distanceData;
                let noDataLength =  (-distanceData[0])/distanceData[1]  
                let scanLength =distanceData[2]*distanceData[1]       
                let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
                $help.drawScan(_datas,(obj)=>{                
                    zData[obj.y][obj.x]=obj.value;                   
                },()=>{                    
                    var data = [
                    {
                        y: yData,
                        x: xData,
                        z: zData,
                        type: 'heatmap',           
                        showscale: false,
                        colorscale:that.$refs.colorBar_rcs.colorScaleValue,
                        zmin:that.$refs.colorBar_rcs.rangeInfo[0].min,
                        zmax:that.$refs.colorBar_rcs.rangeInfo[that.$refs.colorBar_rcs.rangeInfo.length-1].max
                        }
                    ];
            
                    var layout = {               
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,         
                        xaxis: {
                            constrain:'range',
                            color:fontColor,
                            range:[0,8],
                            dtick:1,
                            domain:5,
                            title:"距离(km)",
                            tickmode:'linear',
                            tick0:0,
                            scaleanchor:'y',
                            scaleratio:1
                        },
                        yaxis: {     
                            constrain:'range',
                            range:[-0.1,3],
                            color:fontColor,       
                            dtick:1,
                            domain:5,
                            title:"距离(km)",
                            tickmode:'linear',
                            tick0:0
                        },
                    
                        margin:{
                            t:48,
                            r:46,  
                            l:42  
                            // l:22,
                        }
                    };   
                    let arr = $help.getLayerMapPosition(90,currentSiteInfo.scanDistance, [currentSiteInfo.site.lng, currentSiteInfo.site.lat +0.04])
                    var bounds = new AMap.Bounds(arr[0], arr[1])  
                    if(target.children.length> 0){
                        let mainSvg = target.querySelector('.main-svg');                   
                        if(target.clientHeight != mainSvg.clientHeight || 
                        target.clientWidth != mainSvg.clientWidth ) {                                            
                            Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 300, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;  
                        }else{
                            Plotly.react(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });
                        }                               
                    }else{
                        Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;               
                    }       
                },noDataLength,scanLength,distanceData[2],{d:d})                                                                       
            }
            else if (currentSiteInfo.site.scanType == 'FIX') {
                let data = []
                let target
                let colorBar;
                if (type == 'rcsmerge') {
                    colorBar = this.$refs.colorBar_rcsMerge
                    if (!currentSiteInfo || currentSiteInfo.FIX.Rcs.data.length == 0) {
                        return
                    }
                    if (!currentSiteInfo.FIX.Rcs.el) {
                        let div = document.createElement('div')
                        div.style.width = '600px'
                        div.style.height = '300px'
                        currentSiteInfo.FIX.Rcs.el = div                        
                    }
                    target = currentSiteInfo.FIX.Rcs.el
                    data = JSON.parse(JSON.stringify(currentSiteInfo.FIX.Rcs.data)).sort((a, b) => {
                        return new Date(a.dataTime.replace("T", " ")).getTime() - new Date(b.dataTime.replace("T", " ")).getTime()
                    });
                }
                if (type == 'extmerge') {
                    colorBar = this.$refs.colorBar_extMerge
                    if (!currentSiteInfo || currentSiteInfo.FIX.Ext.data.length == 0) {
                        return
                    }
                    if (!currentSiteInfo.FIX.Ext.el) {
                        let div = document.createElement('div')
                        div.style.width = '600px'
                        div.style.height = '300px'
                        currentSiteInfo.FIX.Ext.el = div
                    }
                    target = currentSiteInfo.FIX.Ext.el
                    data = JSON.parse(JSON.stringify(currentSiteInfo.FIX.Ext.data)).sort((a, b) => {
                        return new Date(a.dataTime.replace("T", " ")).getTime() - new Date(b.dataTime.replace("T", " ")).getTime()
                    });
                }

                let yData = [], xData = [], zData = []
                for (let i = 0; i < data[0].distanceData[2]; i++) {
                    let y = data[0].distanceData[0] + i * data[0].distanceData[1]
                    yData.push(y.toFixed(2))
                }
                data.forEach((a, i) => {
                    xData.push(new Date(a.dataTime.replace("T", " ")).pattern("dd HH:mm:ss"))
                    for (var j = 0; j < yData.length; j++) {
                        if (!zData[j]) {
                            zData[j] = [];
                        }
                        zData[j].push(a.data[j])                       
                    }
                });
                var charatData = [
                    {
                        y: yData,
                        x: xData,
                        z: zData,
                        type: 'heatmap',
                        showscale: false,
                        colorscale:colorBar.colorScaleValue,
                        zmin:colorBar.rangeInfo[0].min,
                        zmax:colorBar.rangeInfo[colorBar.rangeInfo.length-1].max
                    },
                    // {
                    //     type: 'scatter',               
                    //     x: xData,
                    //     y: this.ablData.map(a=>a.height),                              
                    //     showlegend: false,
                    //     text:this.ablData.map(a=>a.height)
                    // }
                ];
                var layout = {
                    // title: 'Annotated Heatmap',
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,     
                    xaxis: {
                        color:fontColor,      
                        nticks:5          
                    },
                    yaxis: {
                        title:'距离(km)'
                    },
                    margin:{
                        t:10
                    }
                };
                let bounds;
                let canvasLayer;
                if (type == 'rcsmerge') {
                    //let site = this.$store.state.storage_global.currentSite;  
                    let arr = $help.getLayerMapPosition(90,currentSiteInfo.scanDistance, [currentSiteInfo.site.lng - 0.065, currentSiteInfo.site.lat + 0.04])
                    bounds = new AMap.Bounds(arr[0], arr[1])
                    if (!currentSiteInfo.FIX.Rcs.canvasLayer) {
                        currentSiteInfo.FIX.Rcs.canvasLayer = new AMap.ImageLayer({                         
                            bounds: bounds,
                            opacity: that.opacity,
                            zooms: [2, 30]
                        });
                        that.map.add(currentSiteInfo.FIX.Rcs.canvasLayer);
                    } 
                    canvasLayer = currentSiteInfo.FIX.Rcs.canvasLayer

                } else if (type == 'extmerge') {
                    let arr = $help.getLayerMapPosition(90,currentSiteInfo.scanDistance,  [currentSiteInfo.site.lng + 0.065, currentSiteInfo.site.lat + 0.04])
                    bounds = new AMap.Bounds(arr[0], arr[1])  
                    if (!currentSiteInfo.FIX.Ext.canvasLayer) {
                        currentSiteInfo.FIX.Ext.canvasLayer = new AMap.ImageLayer({                         
                            bounds: bounds,
                            opacity: that.opacity,
                            zooms: [2, 30]
                        });
                        that.map.add(currentSiteInfo.FIX.Ext.canvasLayer);
                    } 
                    canvasLayer = currentSiteInfo.FIX.Ext.canvasLayer                 
                }
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, charatData, layout,{responsive: true}).then(gd => {
                            Plotly.toImage(gd, { height: 300, width: 600 })
                                .then(function (url) {
                                    canvasLayer.setImageUrl(url)
                                    canvasLayer.setBounds(bounds)                                    
                                })
                        });;  
                    }else{
                        Plotly.react(target, charatData, layout,{responsive: true}).then(gd => {
                            Plotly.toImage(gd, { height: 250, width: 600 })
                                .then(function (url) {
                                    canvasLayer.setImageUrl(url)
                                    canvasLayer.setBounds(bounds) 
                                })
                        });
                    }                               
                }else{
                    Plotly.newPlot(target, charatData, layout,{responsive: true}).then(gd => {
                            Plotly.toImage(gd, { height: 250, width: 600 })
                                .then(function (url) {
                                    canvasLayer.setImageUrl(url)
                                    canvasLayer.setBounds(bounds) 
                                })
                        });;               
                }                                                                                     
            }
        },
        async drawHelper(currentSiteInfo,rp) {
            let yaw =currentSiteInfo.site.scanType == "RHI" || currentSiteInfo.site.scanType == 2 ?
            0: currentSiteInfo.site.yaw;                         
            let _datas = currentSiteInfo.PPI.data.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });
            if(_datas.length == 0 || !this.$refs.colorBar_sps)   {
                return;
            }
            let d = currentSiteInfo.scanDistance;  
            //273
            var that = this;
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (-distanceData[0])/distanceData[1]   
            let scanLength =distanceData[2]*distanceData[1]     
           
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar_sps.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                currentSiteInfo.PPI.ctx.putImageData(imageData,0,0)  
                if (currentSiteInfo.PPI.canvasLayer) {
                    currentSiteInfo.PPI.canvasLayer.reFresh()
                }
            },noDataLength,scanLength,_datas[0].distanceData[2],{d:d,rp:rp,yaw:yaw,span:2})                           
        },        
        //周期时间显示
        async drawText() {
            if (!this.loadOver) {
                return;
            }
            var that = this;
            //500毫秒内请求合并
            if (this.drawTextTime) {
                clearTimeout(this.drawTextTime)
            } else {
                setTimeout(() => {
                    if (that.map) {
                        let dataTime = []
                        if (that.showTime) {
                            dataTime = that.siteScanInfoList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 60) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.time]
                            })
                        }

                        let dataWeather = [];

                        if (this.showWeather) {
                            dataWeather = that.siteScanInfoList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 120) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.weatherInfo]
                            })
                        }
                        let dataSiteName = []
                        if (this.showSiteName) {
                            dataSiteName = that.siteScanInfoList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 10) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.site.siteName]
                            })
                        }


                        let opt = {
                            series: [{
                                type: 'scatter',
                                data: dataTime,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: '{@[2]}',
                                    fontSize: 24,
                                }
                            }, {
                                type: 'scatter',
                                data: dataSiteName,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: '{@[2]}',
                                    fontSize: 16,
                                    padding: 6,
                                    borderRadius: 5,
                                    fontSize: 18,
                                    // color:'#fff',
                                    align: 'center',
                                    verticalAlign: "middle",
                                    borderColor: '#5499d9',
                                    borderWidth: 1,
                                    backgroundColor: "#fff"
                                }
                            }, {
                                type: 'scatter',
                                data: dataWeather,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: (re) => {
                                        let weatherInfo = re.data[2]
                                        return `天气：${weatherInfo.weather} 风向：${weatherInfo.windDirection} 风力：${weatherInfo.windPower} 温度：${weatherInfo.temperature} 湿度：${weatherInfo.humidity}`
                                    },
                                    padding: 6,
                                    borderRadius: 5,
                                    lineHeight: 18,
                                    fontSize: 18,
                                    // color:'#fff',
                                    align: 'center',
                                    verticalAlign: "middle",
                                    borderColor: '#5499d9',
                                    borderWidth: 1,
                                    backgroundColor: "#b1d7a8"
                                }
                            },]
                        }




                        if (that.mapEchart && that.mapEchart.setOption) {
                            that.mapEchart.setOption(opt)
                        }
                    }
                }, 500);
            }
        },       
        //热图
        setHotLayer(show) {
            let color = this.color;
            if (this.mapHotLayer) {
                this.mapHotLayer.setMap(null)
            }
            this.mapHotLayer = null;
            //1.14版本      
            this.mapHotLayer = new Loca.HeatmapLayer({
                map: this.map
            })
            if (show) {
                if (this.mapHotDatas && this.mapHotDatas.length > 0) {
                    this.mapHotLayer.setData(this.mapHotDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                    let level = this.map.getZoom();
                    let r = level <= 12 ? 16 : 16 + (level - 12) * 5;
                    this.mapHotLayer.setOptions({
                        map: this.map,
                        style: {
                            //altitude:500000,
                            radius: r,
                            color: color
                        }
                    });
                    this.mapHotLayer.render();
                    this.mapHotLayer.show();
                }
            }
            else {
                this.mapHotLayer.hide();
            }
        }, 
        filterSelectSites() {
            // if(this.selectSites.length > 6){                
            //     this.selectSites = this.selectSites.slice(0,6)
            //     openNotice({
            //         text:"您最多可以同时选择六个站点",
            //         type:"error"
            //     })
            // }
        },
        loadReciver() {
            var that = this;
            getReciverApi({
                id: this.$store.state.storage_user.user.companyKey,
                params: {
                    Name: ""
                },
                success: (d) => {
                    that.recivers = d.map(a => {
                        return {
                            text: a.NerName,
                            value: a.NerWechatId
                        }
                    })
                }
            })
        },
        selectAllReciver(siteInfo) {
            if (this.recivers.length == siteInfo.wxUserIds.length) {
                siteInfo.wxUserIds = []
            } else {
                siteInfo.wxUserIds = this.recivers.map(a => {
                    return a.value;
                })
            }
        },
        sendMessage() {
            //发送消息                
            let data = []
            for (var i of this.siteScanInfoList) {
                if (i.wxUserIds.length == 0 || i.alarmList.length == 0) {

                } else {
                    let alarmList = i.alarmList;
                    let site = i.site;
                    let provinceCity = alarmList[0].province + alarmList[0].city;
                    let time = new Date(alarmList[0].time.replace("T", " "))
                    let start = new Date();
                    let end = new Date();
                    start.setTime(time.getTime() - 1000 * 60 * 4)
                    end.setTime(time.getTime() + 1000 * 60 * 4)
                    i.wxUserIds.forEach(a => {
                        alarmList.forEach(b => {
                            data.push({
                                Receiver: a,
                                SiteID: site.siteId,
                                SiteName: site.siteName.indexOf("-") >= 0 ? site.siteName.split("-")[1] : site.siteName,
                                Count: b.count,
                                Date: b.time.replace("T", " "),
                                TimeRange: `${start.pattern("yyyy-MM-dd HH:mm:ss")}~${end.pattern("yyyy-MM-dd HH:mm:ss")}`,
                                Province: b.province,
                                City: b.city,
                                District: b.district,
                                Township: b.street,
                                CommunityBuilding: b.position.replace(provinceCity, ""),
                            })
                        })

                    })
                }
            }
            sendAlarmToWxUserApi({
                templateId: 1,
                data: data,
                success: (d) => {
                    if (d[0].IsSucceed) {
                        openNotice({
                            type: "success",
                            text: "已推送至微信服务"
                        })
                    } else {
                        openNotice({
                            type: "error",
                            text: "推送失败，请联系管理人员"
                        })
                    }
                }
            })
        },
        loadData(start, end, _currentSiteInfo, type, datas = [], time) {
            var that = this;
            if (type == 'rcsmerge') {
                getPLSFIXDataApi({
                    id: _currentSiteInfo.site.siteId,
                    dataType: "rcsmerge",
                    params: {
                        LastDate: time,
                        PageSize: 1000,
                        StartTime: start,
                        EndTime: end,
                        MergeType: 0
                    }, success: (d) => {
                        let _datas = d.Result.Data.map(a => {
                            return {
                                dataType: transferDataType.rcsMerge.value,
                                siteId: a.DprSiteId,
                                periodId: a.DprPeriod,
                                scanType: a.DprScanMode,
                                dataTime: a.DprTime,
                                mergeType: a.DprMergeType,
                                angleOfPitch: a.DprPitch,
                                straightAngle: a.DprStraight,
                                distanceData: a.DprDis,
                                data: a.DprValue
                            }
                        })
                        let _ablDatas = d.ABL.Data.map(a => {
                            return {
                                dataType: transferDataType.ablMerge.value,
                                siteId: a.DpaSiteId,
                                periodId: a.DpaPeriod,
                                scanType: a.DpaScanMode,
                                dataTime: a.DpaTime,
                                angleOfPitch: a.DpaPitch,
                                straightAngle: a.DpaStraight,
                                dis: a.DpaDis,
                                height: a.DpaHeight
                            }
                        })
                        _currentSiteInfo.FIX.Rcs.abl = _currentSiteInfo.FIX.Rcs.abl.concat(_ablDatas)
                        datas = datas.concat(_datas)

                        if (new Date(d.Result.Data[d.Result.Data.length - 1].DprTime.replace('T', ' ')).getTime() <= new Date(start).getTime() || d.Result.Data.length < 1000) {
                            _currentSiteInfo.FIX.Rcs.data = datas;
                            that.draw(_currentSiteInfo.site, 'rcsmerge');//extmerge    rcsmerge                       
                        } else {
                            _currentSiteInfo.FIX.Rcs.data = datas;
                            that.loadData(start, end, _currentSiteInfo, type, datas, d.Result.Data[d.Result.Data.length - 1].DprTime)
                        }
                        if (_currentSiteInfo.site.siteId == 'TEST_370000_01_01') {
                            debugger;
                        }
                    }
                })
            } else if (type == 'extmerge') {
                getPLSFIXDataApi({
                    id: _currentSiteInfo.site.siteId,
                    dataType: "extmerge",
                    params: {
                        LastDate: time,
                        PageSize: 1000,
                        StartTime: start,
                        EndTime: end,
                        MergeType: 0
                    }, success: (d) => {                        
                        let _datas = d.Result.Data.map(a => {
                            return {
                                dataType: transferDataType.extMerge.value,
                                siteId: a.DpeSiteId,
                                periodId: a.DpePeriod,
                                scanType: a.DpeScanMode,
                                dataTime: a.DpeTime,
                                angleOfPitch: a.DpePitch,
                                mergeType: a.DpeMergeType,
                                straightAngle: a.DpeStraight,
                                distanceData: a.DpeDis,
                                data: a.DpeValue
                            }
                        })
                        datas = datas.concat(_datas)
                        let _ablDatas = d.ABL.Data.map(a => {
                            return {
                                dataType: transferDataType.ablMerge.value,
                                siteId: a.DpaSiteId,
                                periodId: a.DpaPeriod,
                                scanType: a.DpaScanMode,
                                dataTime: a.DpaTime,
                                angleOfPitch: a.DpaPitch,
                                straightAngle: a.DpaStraight,
                                dis: a.DpaDis,
                                height: a.DpaHeight
                            }
                        })
                        _currentSiteInfo.FIX.Ext.abl = _currentSiteInfo.FIX.Ext.abl.concat(_ablDatas)

                        if (new Date(d.Result.Data[d.Result.Data.length - 1].DpeTime.replace('T', ' ')).getTime() <= new Date(start).getTime() || d.Result.Data.length < 1000) {
                            _currentSiteInfo.FIX.Ext.data = datas;
                            that.draw(_currentSiteInfo.site, 'extmerge');//extmerge    rcsmerge
                        } else {
                            _currentSiteInfo.FIX.Ext.data = datas;
                            that.loadData(start, end, _currentSiteInfo, type, datas, d.Result.Data[d.Result.Data.length - 1].DpeTime)
                        }
                    }
                })
            }

        },
        loadDataRHI(_currentSiteInfo) {
            var that = this;
            getPlsRcsByLatestApi({
                id: _currentSiteInfo.site.siteId,
                success: (d) => {
                    let _datas = d.map(a => {
                        return {
                            angleOfPitch: a.DprPitch,
                            data: a.DprValue,
                            dataTime: new Date(a.DprTime.replace("T", " ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType: transferDataType.rcs,
                            distanceData: a.DprDis,
                            periodId: a.DprPeriod,
                            siteId: a.DprSiteId,
                            scanType: scanType[a.DprScanMode],
                            straightAngle: a.DprStraight
                        }

                    })
                    _currentSiteInfo.RHI.data = _datas
                    that.draw(_currentSiteInfo.site)
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
.contain {
    max-width: 100%;
}

.window {
    width: 100%;
    height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.selectSitesText {
    max-width: 520px;
    width: 100%;
    display: flex;
    align-items: center;

    :deep(.v-chip__content) {
        align-items: center;
        line-height: 2;
        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.selectSitesText::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px !important;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px !important;
}

.selectSitesText::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 2.5px !important;
    background-color: #336de3eb !important;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
}

.selectSitesText::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 5px;
}

.configBtns {
    position: absolute;
    left: 50px;
    bottom: 360px;
}

.weatherinfo {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 30px;
    bottom: 90px;
}

.weatherinfo i {
    color: #fff !important;
}

.weatherinfo div {
    margin: 0 10px;
    padding: 3px 10px;
    border-radius: 5px;
    background-color: var(--v-secondary-base); //色调
    color: #fff !important;
    border-bottom-color: #ccc;
    color: #fff;
    box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
    border: 1px solid #e2f1b4;
    display: flex;
    justify-content: space-around;
}

.warnBtn {
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    z-index: 1;
}

.warnInfo {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 20px;
    z-index: 1;

    :deep(.v-data-table) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.mapToolbar {
    position: absolute;
    z-index: 1;
    width: 100%;
}

//热图
.hotMapColorBar {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 160px;
}

.hotMapColorBarBox {
    width: 15px;
    border-radius: 5px;
    height: 100%;
    margin: 10px 0 0 0;
}

.hotMapColorBarText {
    display: flex;
    align-items: center;
    position: relative;
    left: 18px;
    height: 100%;
    white-space: nowrap
}

.mapColorBarConfig {
    position: absolute;
    top: 10%;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 50%;
}

.mapColorBar {
    z-index: 2;
    position: absolute;
    top: 62px;
    right: 30px;
}

//比例尺
:deep(.amap-scalecontrol) {
    bottom: 20px;
    left: 50px;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color: var(--v-rTheme-base);

    i {
        color: var(--v-rTheme-base);
    }
}

.map_marker {
    path {
        fill: var(--v-rTheme-base);
    }
}

:deep(.amap-ui-control-theme-myself) {
    .amap-ui-control-layer-slider {
        background: var(--v-fTheme-base);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    }

    .amap-ui-control-layer-expanded {
        color: var(--v-rTheme-base);
        background: var(--v-fTheme-base);
    }

    .amap-ui-control-layer-toggle {
        color: var(--v-rTheme-base);
    }

    ._slider {
        display: none;
        padding: 5px;
        color: var(--v-rTheme-base);
    }

    .opacity_box {
        display: flex;
    }

    .amap-ui-control-layer-slider:hover svg {
        display: none;
    }

    .amap-ui-control-layer-slider:hover ._slider {
        display: block;
    }

    path {
        fill: var(--v-rTheme-base);
    }
}

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer {
    border-radius: 5px;
    padding: 5px 0 !important;
    background: var(--v-fTheme-base);

    li {
        border-radius: 5px !important;
        margin: 5px;
        background: var(--v-fTheme-base);
        color: var(--v-rTheme-base);
    }

    //   li:hover{
    //     //background: var(--v-fTheme-base);
    //     color:var(--v-rTheme-base);
    //     border-radius: 5px !important;
    //   }
    svg {
        margin-right: 5px;
    }

    path {
        fill: var(--v-fTheme-base);
    }
}

.amap-info-content {
    background: var(--v-fTheme-base);
    color: var(--v-rTheme-base);
}

.amap-info-sharp {
    border-top-color: var(--v-rTheme-base);
}

:deep(.table-row-active) {
    background: var(--v-fTheme-lighten2);
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 80px;
}
</style>
<template>
    <v-container class="containChartBox height50 ma-0 pa-0">
    <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
    class="ma-16"
        absolute
        :value="noData"
        opacity="0.2"
        >
        {{dataShow}}
    </v-overlay>
    <v-card  class="width100 height100 ma-0 d-flex flex-column">
        <v-card-actions class="pa-0 ma-0">
            <v-row no-gutters class="ma-0 align-center">
                <!-- <v-icon style="min-width:32px;flex-shrink: 0;" class="ma-2" @mousedown="resize">mdi-arrow-all</v-icon> -->
                <div class="ml-4 mover" style="min-width:68px;flex-shrink: 0;"><v-icon>mdi-swap-vertical</v-icon>{{title}}[24小时]</div>
                <v-spacer></v-spacer>
                <v-col cols="1"><v-icon class="ma-2 move" title="移动">mdi-swap-horizontal</v-icon></v-col>
                <v-col cols="1" v-show="false"><ColorBarConfig/></v-col>
                <!-- <v-col cols="1" class="mr-2 pointer"><v-icon class="ma-2" @click="$emit('close',keyV)">mdi-close</v-icon></v-col>                 -->
            </v-row>
        </v-card-actions>
        <ColorBarConfig  @update="timerDraw" :dataKey='type=="rcsmerge"? 15:13' ref="colorBar" class="mapColorBarConfig"/> 
        <div ref="chart" class="width100 height100 plotychart" >
        </div>
    </v-card>
</v-container>
</template>
<script>
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { getPLSFIXDataApi} from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:"windowUnion.heatmap",
    components:{ColorBarConfig},
    props:{
        title:{
            type:String
        },
        type:{
            type:String
        },        
        currentSite:{
            type:Object
        }
    },
    data(){
        return {
            
            overlay:false,
            dataShow:'正在加载数据',
            chart:null,
            width:370,
            height:268,
            loadTodayDataOver:false,
            data:[],
            ablData:[],//边界层数据,
            currentWatch:null,
            timer:null
        }
    },
    computed:{       
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        }, 
    },
    watch:{
        currentSite:{
            handler(n,v){    
                let that = this;
                this.data = []
                this.$nextTick(()=>{   
                    that.watchData(n.siteId)
                    that.load();      
                })                           
            }
        },        
        "$store.state.global_relaMonitorState.widthHeight":{
            handler(n,v){    
                if(localStorage.getItem("monitorTimeHotHeight")){
                    this.height = localStorage.getItem("monitorTimeHotHeight")
                }
            
                if(localStorage.getItem("monitorTimeHotWidth")){
                    this.width = localStorage.getItem("monitorTimeHotWidth")
                }    
                if(this.chart){
                    this.chart.resize()
                }
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
               
            }
        },
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this; 
        setTimeout(() => {
            that.watchData(that.currentSite.siteId)      
            that.load()        
        }, 800);   
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{
                if(n.SiteId == that.currentSite.siteId && (n.ScanMode == "FIX" || n.ScanMode == 1)){                                                                        
                        var json = JSON.parse(JSON.stringify(n.jsonData));  
                        if(that.loadTodayDataOver){     
                            let len = that.data.length-json.length  
                            if(json[0].dataType == transferDataType.ablMerge.value && json[0].mergeType == 0){                                                        
                                that.ablData = json.concat(that.ablData)                      
                                that.ablData.slice(0,len)
                            }                                               
                            if(that.type == 'rcsmerge' && json[0].dataType == transferDataType.rcsMerge.value && json[0].mergeType ==0){                            
                                that.data = json.concat(that.data) 
                                that.data.slice(0,len)                                
                                that.loadChart()
                            } 
                            if(that.type == 'extmerge' && json[0].dataType == transferDataType.extMerge.value && json[0].mergeType ==0){                                                                                        
                                that.data = json.concat(that.data)
                                that.data.slice(0,len)
                                that.loadChart()                        
                            }
                        }                           
                    } 
            }, {immediate: true })
        },
        load(){         
            let siteId = this.currentSite.siteId;
            //this.loadColorConfig()
            let start = new Date (new Date ().getTime()-24*60*60*1000).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId)               
        },    
        loadData(start,end,siteId,datas = [],time){  
            var that = this;
            that.overlay=true;
            if(this.type == 'rcsmerge'){
                getPLSFIXDataApi({
                    id:siteId,  
                    dataType:"rcsmerge",                 
                    params:{
                        LastDate:time,
                        PageSize:1000,                        
                        StartTime:start,
                        EndTime:end,
                        MergeType:0
                    },success:(d)=>{   
                        that.overlay=false;
                        if(d.Result.Data.length == 0){
                            that.loadTodayDataOver = true;
                            that.dataShow='无数据';
                        }
                        let _datas = d.Result.Data.map(a=>{
                            return {
                                dataType:transferDataType.rcsMerge.value,
                                siteId:a.DprSiteId,
                                period:a.DprPeriod,
                                scanType:a.DprScanMode,
                                dataTime:a.DprTime.replace('T',' '),
                                mergeType:a.DprMergeType,
                                angleOfPitch:a.DprPitch,
                                straightAngle:a.DprStraight,
                                distanceData:a.DprDis,
                                data:a.DprValue
                            }})   
                        let _ablDatas = d.ABL.Data.map(a=>{
                            return {
                            dataType:transferDataType.ablMerge.value,
                            siteId:a.DpaSiteId,
                            period:a.DpaPeriod,
                            scanType:a.DpaScanMode,
                            dataTime:a.DpaTime.replace('T',' '),
                            angleOfPitch:a.DpaPitch,
                            straightAngle:a.DpaStraight,
                            dis:a.DpaDis,
                            height:a.DpaHeight
                        }})
                        that.ablData = that.ablData.concat(_ablDatas) 
                        datas =_datas.concat(datas)                       
                        if(new Date(d.Result.Data[d.Result.Data.length -1].DprTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Result.Data.length < 1000){                                   
                            that.data = datas;
                            that.loadTodayDataOver = true;
                             that.loadChart()        
                        }else{                              
                            that.data = datas;                  
                            that.loadChart() 
                            that.loadData(start,end,siteId,datas,d.Result.Data[d.Result.Data.length -1].DprTime)                                  
                        }    
                    }
                })
            }else if(this.type == 'extmerge'){
                getPLSFIXDataApi({
                    id:siteId,  
                    dataType:"extmerge",                 
                    params:{
                        LastDate:time,
                        PageSize:1000,                        
                        StartTime:start,
                        EndTime:end,
                        MergeType:0
                    },success:(d)=>{  

                        that.overlay=false;  
                        if(d.Result.Data.length == 0){                            
                             that.loadTodayDataOver = true;
                             that.dataShow='无数据';
                        } 
                        let _datas = d.Result.Data.map(a=>{
                            return {
                                dataType:transferDataType.extMerge.value,
                                siteId:a.DpeSiteId,
                                period:a.DpePeriod,
                                scanType:a.DpeScanMode,
                                dataTime:a.DpeTime.replace('T',' '),
                                angleOfPitch:a.DpePitch,
                                mergeType:a.DpeMergeType,
                                straightAngle:a.DpeStraight,
                                distanceData:a.DpeDis,
                                data:a.DpeValue
                            }})
                        datas = _datas.concat(datas) 
                        let _ablDatas = d.ABL.Data.map(a=>{
                            return {
                            dataType:transferDataType.ablMerge.value,
                            siteId:a.DpaSiteId,
                            period:a.DpaPeriod,
                            scanType:a.DpaScanMode,
                            dataTime:a.DpaTime.replace('T',' '),
                            angleOfPitch:a.DpaPitch,
                            straightAngle:a.DpaStraight,
                            dis:a.DpaDis,
                            height:a.DpaHeight
                        }})
                        that.ablData = that.ablData.concat(_ablDatas) 
                         
                        if(new Date(d.Result.Data[d.Result.Data.length -1].DpeTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Result.Data.length < 1000){     
                             that.data = datas; 
                             that.loadTodayDataOver = true;                                                                
                             that.loadChart()         
                        }else{                                
                            that.data = datas;
                            that.loadChart()                   
                            that.loadData(start,end,siteId,datas,d.Result.Data[d.Result.Data.length -1].DpeTime)                                  
                        }    
                    }
                })
            }
            
        },
        //重置大小
        resize(){
            var that = this;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = Number(that.width) - e.movementX ;
                let resetH = Number(that.height) + e.movementY;
                that.width = resetW > 0.4 * document.body.clientWidth ? 0.4 * document.body.clientWidth : resetW < 370 ? 370 : resetW;
                that.height = resetH > 0.4 * document.body.clientHeight ? 0.4 * document.body.clientHeight : resetH < 260 ?260 : resetH;
                localStorage.setItem("monitorTimeHotHeight",that.height)
                localStorage.setItem("monitorTimeHotWidth",that.width)
                that.$store.commit("global_relaMonitorState/updatewidthHeight",`${that.width},${that.height}`)          
            }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{                                   
                document.removeEventListener('mousemove',fun)
            });
        },               
       
        loadChart(){            
            let target = this.$refs.chart;  
            let that = this;
            if(!target){
                return
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            if(this.data.length == 0 || !this.$refs.colorBar){                
            var data = [ 
                {
                y: [],
                x: [],
                z: [],
                type: 'heatmap',           
                showscale: false,                                            
            },{
                type: 'scatter',               
                x: [],
                y: [],                              
                showlegend: false,                
            }
            ];

            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,  
                uirevision:'true',   
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.1,
                    font:{
                        color: fontColor
                    }
                },
                xaxis: {
                    color:fontColor,      
                    nticks:$help.getNtick(target.clientWidth)     
                },
                yaxis: {
        
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:10
                }
            };
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});   
                return
            }

            let _datas =[]; 
            let minSpan = 8;       
            for(let i in this.data.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.data[i].dataTime).getTime() - new Date(this.data[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){     
                            j+=minSpan;                     
                            _datas.push({
                                dataType:this.data[i].dataType,
                                siteId:this.data[i].siteId,
                                period:this.data[i].period,
                                scanType:this.data[i].scanType,
                                dataTime:new Date(new Date(this.data[i].dataTime).setMinutes(new Date(this.data[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                angleOfPitch:this.data[i].angleOfPitch,
                                mergeType:this.data[i].mergeType,
                                straightAngle:this.data[i].straightAngle,
                                distanceData:this.data[i].distanceData,
                                data:Array.from({length: this.data[i].data.length}, (val, i) => NaN) 
                            })                                          
                        }
                    }
                }
            }
            _datas = _datas.concat(this.data)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = [];               
            yData = [];     
            let distanceData =datas[0].distanceData;            
            let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离  
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];            
            let yAbl = [];                             
            datas.forEach((a,i)=>{                    
                xData.push(new Date(a.dataTime))                
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }          
                    let _index = j+noDataLength                   
                    zData[j].push(Number(a.data[_index]))
                    hoverText[j].push(`水平角：${a.straightAngle}<br />俯仰角：${a.angleOfPitch}<br />监控值：${a.data[_index]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }   
                let item = this.ablData.find(b=>b.dataTime == a.dataTime)                    
                if(item){
                    yAbl.push(item.height)
                }else{
                    yAbl.push(NaN)
                }                                                                        
            });  
            var data = [ 
                {
                    name:'',
                    y: yData,
                    x: xData,
                    z: zData,
                    type: 'heatmap',           
                    showscale: false,            
                    hovertext:hoverText,
                    colorscale:this.$refs.colorBar.colorScaleValue,
                    zmin:this.$refs.colorBar.rangeInfo[0].min,
                    zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                },{
                    name:'边界层',
                    type: 'scatter',               
                    x: xData,
                    y: yAbl,                              
                    showlegend: true,
                    text:yAbl
                }
            ];
            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,   
                uirevision:'true',   
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.11,
                    font:{
                        color: fontColor
                    }
                },
                xaxis: {
                    color:fontColor,      
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)       
                },
                yaxis: {
                    title:'距离(km)'
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:45,
                    l:32,
                    r:32,
                    b:22
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }              
        }, timerDraw(){  
            if(this.data.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }            
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
.height50{
    height:calc(50% - 18px);
}
.plotychart{
    :deep(.modebar){        
        padding-right:60px ;
    }
}
</style>
import help from './help'
function toRbgString(r,b,g){
    let rbgArr = help.hsvToRgb(r,b,g)
    let rgb = `rgb(${rbgArr[0]},${rbgArr[1]},${rbgArr[2]})`;
    return help.rgbToHex(rgb)
}
export default function getThemeConfig(primary){
    var rgb = help.hexToRgb(primary);
    var hsv = help.rgbToHsv(rgb[0],rgb[1],rgb[2])
    let _h = hsv[0],_s = hsv[1],_v = hsv[2];
    let r_h = Math.floor((360 -_h) /36)
    let success = {
        h:122,
        s:57,
        v:69
    }
    let error = {
        h:36,
        s:100,
        v:100
    }
    let warning = {
        h:4,
        s:78,
        v:96
    }
    let _p_l1 = 85; // 亮1
    let _p_l2 = 75; // 亮2 
    let _p_l3 = 65; // 亮3
    let _p_d1 = 25; // 暗1
    let _p_d2 = 35; // 暗2
    let _p_d3 = 45; // 暗3
    let _reverse = 180 + _h > 360 ? _h - 180 : 180 + _h; //相反色
    let _p1 = _h + 10 > 360 ? _h - 350 : _h + 10; //相近色
    let _p2 = _h - 10 < 0 ? _h + 350 : _h - 10; //相近色
    let secondly =  _h -100 < 0 ?_h + 260:_h -100; //辅色
    //根据主色调输出vuetify色彩配置
    let themeConfig = {                   
        "light":{
            "primary": primary,
            "secondary": toRbgString(secondly,_s,_p_l3),
            "accent": toRbgString(_p1,_s,_p_l3),
            "error": toRbgString(error.h+r_h,error.s,error.v),
            "warning": toRbgString(warning.h+r_h,warning.s,warning.v),
            "info": toRbgString(_p2,_s,_p_l3),
            "success": toRbgString(success.h+r_h,success.s,success.v),
            "white":{
                base:"#F7F7F7",
                lighten5: '#f5f5f5',
                lighten4: '#f1f1f1',
                lighten3: '#c7c7c7',
                lighten2: '#ededed',
                lighten1: '#e5e5e5',
                darken1: '#d7d7d7',
                darken2: '#c7c7c7',
                darken3: '#c5c5c5',
                darken4: '#c1c1c1',
            },
            "black":{
                base: '#1c1c1c',
                lighten5: '#919191',
                lighten4: '#787878',
                lighten3: '#5f5f5f',
                lighten2: '#585858',
                lighten1: '#474747',
                darken1: '#313131',
                darken2: '#363636',
                darken3: '#010101',
                darken4: '#000000',
            },
            fTheme:{
                base:"#F7F7F7",
                lighten5: '#f5f5f5',
                lighten4: '#f1f1f1',
                lighten3: '#c7c7c7',
                lighten2: '#ededed',
                lighten1: '#e5e5e5',
                darken1: '#d7d7d7',
                darken2: '#c7c7c7',
                darken3: '#c5c5c5',
                darken4: '#c1c1c1',
            },
            rTheme:{
                base: '#1c1c1c',
                lighten5: '#919191',
                lighten4: '#787878',
                lighten3: '#5f5f5f',
                lighten2: '#585858',
                lighten1: '#474747',
                darken1: '#313131',
                darken2: '#363636',
                darken3: '#010101',
                darken4: '#000000',
            }
            },
        "dark":{
            "primary": toRbgString(_h,_s,_p_d3),
            "secondary": toRbgString(secondly,_s,_p_d3),
            "accent": toRbgString(_p1,_s,_p_d3),
            "error": toRbgString(error.h+r_h,error.s,_p_d3),
            "warning": toRbgString(warning.h+r_h,warning.s,_p_d3),
            "info": toRbgString(_p2,_s,_p_d3),
            "success": toRbgString(success.h+r_h,success.s,_p_d3),
            "white":{
                base:"#F7F7F7",
                lighten5: '#f5f5f5',
                lighten4: '#f1f1f1',
                lighten3: '#c7c7c7',
                lighten2: '#ededed',
                lighten1: '#e5e5e5',
                darken1: '#d7d7d7',
                darken2: '#c3c3c3',
                darken3: '#ababab',
                darken4: '#9b9b9b',
            },
            "black":{
                base: '#1c1c1c',
                lighten5: '#919191',
                lighten4: '#787878',
                lighten3: '#5f5f5f',
                lighten2: '#585858',
                lighten1: '#474747',
                darken1: '#313131',
                darken2: '#363636',
                darken3: '#010101',
                darken4: '#000000',
            },
            fTheme:{
                base: '#1c1c1c',
                lighten5: '#919191',
                lighten4: '#787878',
                lighten3: '#5f5f5f',
                lighten2: '#585858',
                lighten1: '#474747',
                darken1: '#313131',
                darken2: '#363636',
                darken3: '#010101',
                darken4: '#000000',
            },
            rTheme:{
                base:"#F7F7F7",
                lighten5: '#f5f5f5',
                lighten4: '#f1f1f1',
                lighten3: '#c7c7c7',
                lighten2: '#ededed',
                lighten1: '#e5e5e5',
                darken1: '#d7d7d7',
                darken2: '#c3c3c3',
                darken3: '#ababab',
                darken4: '#9b9b9b',
            }
        }                            
    }
    return themeConfig
}
<template>
   <v-container class="d-flex flex-column container ">
        <div class="hidemap" ref="hidemap"></div>   
        <v-dialog  v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="d-flex flex-column height100">
                <v-toolbar color="primary" height="64px">
                    <v-btn icon dark @click="dialog= false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="color:white">{{$i18n.t('dailyEdit.title')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn dark text @click="replaceImg">保存</v-btn>
                </v-toolbar>
                   <v-radio-group v-model="checked" style="overflow:auto">
                        <v-card-text  class="height100 d-flex flex-wrap justify-center" >                        
                            <v-card @click="checked = i" v-for="i,_i in imgs" :key="_i" class="ma-2 pointer">
                                <v-card-subtitle class="d-flex align-center">
                                    <v-radio :value="i" ></v-radio>
                                    {{ `${i.time.substr(0,4)}年${i.time.substr(4,2)}月${i.time.substr(6,2)}日 ${i.time.substr(8,2)}:${i.time.substr(10,2)}:${i.time.substr(12,2)}`}}                            
                                </v-card-subtitle>
                                <v-img :src="i.path" width="500" height="500"></v-img>
                            </v-card>                                          
                        </v-card-text>
                    </v-radio-group>
                <!-- <v-card-actions >
                    <v-btn color="primary">{{$i18n.t('dailyEdit.btn.ok')}}</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog> 
        <v-card :elevation='0' class="cardFrom">            
            <v-form
                class="ma-6"
                ref="form"
                v-model="form.valid"                            
                >                               
                <v-row align="end" justify="space-between">
                    <v-card width="100%" elevation="0">
                         <v-row class="ma-0">
                            <v-text-field
                                v-model="form.body.RdTitle"                                
                                :label="$i18n.t('dailyEdit.label.form.title')"
                                required
                                :rules="rules.title"
                            ></v-text-field>  
                        </v-row>   
                        <v-row class="ma-0">
                            <v-text-field
                                v-model="form.body.Author"                                
                                :label="$i18n.t('dailyEdit.label.form.author')"
                                required
                                :rules="rules.author"
                            ></v-text-field>  
                        </v-row>                                                                               
                        <v-row class="ma-0">
                            <v-select
                                :rules="rules.siteId"
                                v-model="form.body.RdSiteId"
                                :items="sites"                                
                                :label="$i18n.t('dailyEdit.label.form.site')"
                                multiple
                                disabled
                                required
                            ></v-select>                             
                        </v-row >                       
                    </v-card>
                </v-row>                      
                <v-row>
                    <Editor ref="editor" :tinymceScriptSrc='url' :init="editConfig" :initialValue="form.body.RdContent" :value="form.body.RdContent" apiKey="isss3pi0uzaqpoowqf3e1ta7svhbmfm3hoy5ejh863kukv5p"></Editor>
                </v-row>                                                                                                                                                                                         
            </v-form>
        </v-card>
        <v-row class="ma-2 d-flex justify-end">
            <v-btn color="primary" @click="save(0)" class="ma-2"><v-icon>mdi-content-save</v-icon>{{$i18n.t('dailyEdit.btn.save')}}</v-btn>
            <v-btn color="primary" @click="save(1)" class="ma-2"><v-icon>mdi-content-save</v-icon>{{$i18n.t('dailyEdit.btn.publish')}}</v-btn>
            <v-btn color="primary" @click="save(2)" class="ma-2"><v-icon>mdi-content-save</v-icon>{{$i18n.t('dailyEdit.btn.publishpdf')}}</v-btn>
            <v-btn color="secondry" @click="$router.history.go(-1)" class="ma-2"><v-icon>mdi-close-circle-outline</v-icon>{{$i18n.t('dailyEdit.btn.cancel')}}</v-btn>
        </v-row>    
    </v-container> 
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import {upLoadApi,getDailyReportApi,editDailyReportApi} from '@/api'
import {uploadMinioURLServer,uploadMinioURL } from '@/api/url'
export default {
    components:{Editor},
    name:"report.dailyManage.edit",
    computed:{
        url(){
            return process.env.BASE_URL+ "tinymce/tinymce.min.js"
        }
    },
    data(){
        var that = this;
        return {
            map:null,//隐藏的地图 新和成时使用
            imgs:[],//新和成图片列表
            dialog:false,
            editor:null,
            checked:{},
            editorFiles:[],
        form:{
            valid:false,            
            body:{
                RdId:"",
                RdSiteId:[],
                RdLidarId:[],                                    
                RdTitle:"",
                Author:"系统",
                RdContent:"",                                                   
                RdPublishStatus:0, 
                RdReportId:"",  
                RdCreateTime:"",
                ReportDailyAttachments:[]         
            }                  
        },
        rules:{
            siteId: [
                    v => v.length > 0 || this.$i18n.t("dailyEdit.label.rule.siteid")
                ],           
            title:[ v => !!v || this.$i18n.t("dailyEdit.label.rule.title"),
                    v =>(v && v.length <= 64)|| this.$i18n.t("dailyEdit.label.rule.titleLength"),],  
            author:[ v => !!v || this.$i18n.t("dailyEdit.label.rule.author"),
                    v =>(v && v.length <= 32)|| this.$i18n.t("dailyEdit.label.rule.authorLength"),],                                
            article:[v => !!v || this.$i18n.t("dailyEdit.label.rule.article")],            
        },      
        sites:[],
        editConfig:{
            height:1000,
            selectImgSelf(img,editor){
                that.checked = {}
                that.imgs = []
               //var index = img.src.lastIndexOf('/');
                //var path = img.src.substr(0,index).replace(baseURL,"").replace(/\//g,"\\");
                let imgs = img.getAttribute("data-imgs").split(",");
                let lng =img.getAttribute("data-lng");
                let lat =img.getAttribute("data-lat");
                if(img.getAttribute("data-nopoint") == '1'){
                    that.map.setCenter([lng,lat])                
                    setTimeout(() => {                        
                        let mapImg = that.map.getScreenShot();  
                        let elImg = new Image();
                        elImg.crossOrigin ="anonymous" 
                        elImg.src = mapImg //.replace(uploadMinioURLServer,"http://124.133.246.59:13395/");
                        let imgParams = imgs[0].match(/(?<=\@).*?(?=\@)/g);                        
                        let w = 500,angle = 0;
                        if(!imgParams){                            
                            
                        }else{
                            w = Number(imgParams[0].split("_")[0]);
                            angle = Number(imgParams[0].split("_")[1]) - 90;                                                               
                        } 
                        let scale = Math.round(Math.sqrt(2) * w / 500 * Math.cos(angle * Math.PI / 180) * 530);                    
                        let lx = (800 - scale) / 2;
                        let ly = (800 - scale) / 2;
                        elImg.onload= a=>{     
                            let mapCurr = a.currentTarget;               
                            imgs.forEach(i=>{        
                                let canvas = document.createElement('canvas')
                                canvas.width = 800;
                                canvas.height = 800;
                                let ctx = canvas.getContext('2d');
                                var imgItemEl = new Image();
                                imgItemEl.crossOrigin ="anonymous"  
                                let _date = i.split('\/')                                                                                                                                     
                                let time = _date[6].split("_").find(a=>a.indexOf(_date[5])>=0);                            
                                imgItemEl.src = i;
                                imgItemEl.onload = b=>{
                                    ctx.drawImage(mapCurr,0,0,800,800) 
                                    ctx.globalAlpha = 0.5;
                                    ctx.drawImage(b.currentTarget,lx,ly,scale,scale)   
                                    ctx.font = '26px SimHei'                                    
                                    ctx.fillText(`${time.substr(0,4)}-${time.substr(4,2)}-${time.substr(6,2)} ${time.substr(8,2)}:${time.substr(10,2)}`,10,60)                                                                
                                    let imgbase64 =  canvas.toDataURL()                                    
                                    canvas.remove();                                                                     
                                    that.imgs.push({
                                        path:imgbase64,
                                        time:time,
                                        checked:false
                                    })                                   
                                    if(that.imgs.length == imgs.length){
                                        that.imgs = that.imgs.sort((a,b)=>{                                            
                                            let time1 = a.time
                                            let time2 = b.time
                                            return Number(time1) - Number(time2);
                                        });
                                    }
                                }                            
                            })                        
                            that.dialog = true;
                        } 
                    }, 800); 
                }else{
                    let x =img.getAttribute("data-x");
                    let y =img.getAttribute("data-y");                  
                    let scale = 15;                    
                    let span = 30;
                    let pointX = 800/2 + x/scale;
                    let pointY = 800/2 - y/scale;  
                    let startX = pointX -span;
                    let startY = pointY -span;             
                    that.map.setCenter([lng,lat])                
                    setTimeout(() => {
                        let mapImg = that.map.getScreenShot();  
                        let elImg = new Image();
                        elImg.crossOrigin ="anonymous" 
                        elImg.src = mapImg //.replace(uploadMinioURLServer,"http://124.133.246.59:13395/");
                        let imgParams = imgs[0].match(/(?<=\@).*?(?=\@)/g);                        
                        let w = 500,angle = 0;
                        if(!imgParams){                            
                            
                        }else{
                            w = Number(imgParams[0].split("_")[0]);
                            angle = Number(imgParams[0].split("_")[1]) - 90;                                                               
                        }
                        let scale = Math.round(Math.sqrt(2) * w / 500 * Math.cos(angle * Math.PI / 180) * 530);                    
                        let lx = (800 - scale) / 2;
                        let ly = (800 - scale) / 2;
                        elImg.onload= a=>{     
                            let mapCurr = a.currentTarget;               
                            imgs.forEach(i=>{        
                                let canvas = document.createElement('canvas')
                                canvas.width = 800;
                                canvas.height = 800;
                                let ctx = canvas.getContext('2d');
                                var imgItemEl = new Image();
                                imgItemEl.crossOrigin ="anonymous"  
                                let _date = i.split('\/')                                                                                                                                     
                                let time = _date[6].split("_").find(a=>a.indexOf(_date[5])>=0);                            
                                imgItemEl.src = i;
                                imgItemEl.onload = b=>{
                                    ctx.drawImage(mapCurr,0,0,800,800) 
                                    ctx.globalAlpha = 0.5;
                                    ctx.drawImage(b.currentTarget,lx,ly,scale,scale)                                
                                    //标记
                                    ctx.strokeStyle = 'red';
                                    ctx.lineWidth = 5;
                                    ctx.globalAlpha = 1;
                                    ctx.strokeRect(startX,startY,40,40)    
                                    ctx.save()
                                    ctx.font = '26px SimHei'                                    
                                    ctx.fillText(`${$help.periodToTimeStr(time)}`,10,60)                                                                
                                    let imgbase64 =  canvas.toDataURL()                                    
                                    canvas.remove();                                                                     
                                    that.imgs.push({
                                        path:imgbase64,
                                        time:time,
                                        checked:false
                                    })                                   
                                    if(that.imgs.length == imgs.length){
                                        that.imgs = that.imgs.sort((a,b)=>{                                            
                                            let time1 = a.time
                                            let time2 = b.time
                                            return Number(time1) - Number(time2);
                                        });
                                    }
                                }                            
                            })                        
                            that.dialog = true;
                        } 
                    }, 800);  
                }                                                                                                               
            },        
            menubar: false,
            convert_urls: false,
            toolbar_mode:"wrap",//工具栏按钮设置floating 多余的收进... 浮动在页面  / sliding 多余的收进... 点击撑开  / scrolling 多余滚动 / wrap
            //language_url:"../../../assets/langs/zh_CN.js",
            language: 'zh_CN',
            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
            branding: false,
            contextmenu: "bold copy",
            paste_data_images: true,//拖拽支持
            //images_file_types: 'jpeg,jpg,png,gif,bmp,webp',//拖拽支持
            fontsize_formats: "8pt 10pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 24pt 36pt",
            font_formats: "微软雅黑='Microsoft YaHei';宋体='SimSun';黑体='SimHei';仿宋='FangSong';楷体='KaiTi';隶书='LiSu';幼圆='YouYuan';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
            plugins: [
                'advlist autolink lists link image  charmap imageselect',//editimage
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table hr visualchars',
                'nonbreaking'
            ],
            //editimage_cors_hosts: ['127.0.0.1','localhost'],
            //editimage_proxy_service_url:['127.0.0.1'],
            //imagetools_credentials_hosts:['127.0.0.1'],
            //imagetools_proxy: '',
            toolbar:
                `imageselect | code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link unlink openlink anchor |
                alignleft aligncenter alignright alignjustify alignnone outdent indent | 
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | 
                table image rotateleft media charmap emoticons hr pagebreak insertdatetime print preview |searchreplace fullscreen | bdmap indent2em lineheight formatpainter axupimgs |
                newdocument visualblocks visualchars nonbreaking`,
                //images_reuse_filename:true,//是否重命名为唯一文件名
            images_upload_handler:function (blobInfo, succFun, failFun) {
                var url = URL.createObjectURL(blobInfo.blob()) 
                var ext = blobInfo.filename().replace(blobInfo.name(),"");   
                let filename = $help.guid()+ext;                
                that.editorFiles.push({
                    url:url,
                    file:blobInfo.blob(),
                    fileName:filename
                })   
                console.log(that.editorFiles)
                succFun(url)
            },//图片拖拽粘贴上传
            file_picker_types: 'file image media',//上传格式
            file_picker_callback:function (callback, value, meta) {           
                var input =document.createElement("input");
                input.setAttribute("type","file")
                switch(meta.filetype){
                    case "image":
                        input.setAttribute("accept",".bmp,.jpg,.png,.tif,.gif,.pcx,.tga,.exif,.fpx,.svg,.psd,.cdr,.pcd,.dxf,.ufo,.eps,.ai,.raw,.WMF,.webp,.avif,.apng")
                        break;
                    case "media":
                        input.setAttribute("accept",".wav,.mp1,.mp2,.mp3,.mp4,.wma,.ogg,.ape,.aac,.avi,.navi,.dvavi,.asf,.asx,.rm,.rmvb,.3gp,.mov,.m4v,.mkv,.flv,.vob,.mpeg,.mpg,.mod,.opus,.flac,.ape,.alac    ")
                        break;                
                }
                input.click()
                input.onchange = function() {
                    let file = this.files[0]
                    let url = URL.createObjectURL(file)
                    let ext = $help.getFileExtension(file.name); 
                    let filename = $help.guid()+'.'+ext;
                    that.editorFiles.push({
                        url:url,
                        file:file,
                        fileName:filename
                    })     
                    callback(url)
                }
            }
        }
    }
    },    
    watch:{    
        "form.body.RdSiteId":{
            handler(n){
                var sites =  this.$store.state.storage_user.sites;
                sites = sites.filter(a=>{
                    return  n.indexOf(a.siteId) >= 0
                })
                this.form.body.RdLidarId = sites.map(a=>{
                    return a.lidarInfo.lidarId
                });          
            }
        }    
    },
    mounted(){
        this.load()   
        this.loadMap()
        this.loadSites()  
        var that= this;
        setTimeout(() => {
            that.editor = that.$refs.editor.editor;
        }, 600);                
    },
    methods:{
        load(){
            var that = this;
            if(this.$route.query.id && this.$route.query.time){
                getDailyReportApi({
                    id: this.$route.query.time + '/'+ this.$route.query.id,
                    success:d=>{
                        that.form.body = {
                            RdId:d.RdId,
                            RdSiteId:d.RdSiteId,
                            RdLidarId:d.RdLidarId, 
                            Author:d.Author?d.Author:'系统',                                   
                            RdTitle:d.RdTitle,
                            RdContent:d.RdContent.replaceAll(uploadMinioURLServer,uploadMinioURL),                                                   
                            RdPublishStatus:d.RdPublishStatus,  
                            RdCreateTime:new Date(d.RdCreateTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"), 
                            ReportDailyAttachments:d.ReportDailyAttachments
                        }
                    }
                })
            }else{
                openNotice({
                    text:this.$i18n.t("dailyEdit.notice.noid"),
                    type:"error"
                })
                this.$router.push({
                    path:"/report/dailyManage/index"
                })                
            }
        },
        loadMap(){
            this.map = new AMap.Map(this.$refs.hidemap,{
                rotateEnable:false,
                pitchEnable:false,                
                pitch: 0,
                rotation: 0,//-55
                zoom:13,//级别
                zooms:[2,30],                
                viewMode:'3D',//使用3D视图                            
            });
        },
        loadSites(){
            var that = this;           
            that.sites = this.$store.state.storage_user.sites.map(a=>{
                return {
                    text:a.siteName,
                    value:a.siteId
                }
            })
        },   
        replaceImg(){            
            let el = this.editor.selection.getNode()
            el.src = this.checked.path;
            this.editor.selection.setContent(el.outerHTML)  
           
            this.dialog = false;
        },                   
        save(type){
            var that = this;
            var rel = this.$refs.form.validate();
            updateMask({
                show:true,
                content:type == 0 ?"提交中...请稍后...":"发布中...请稍后..."
            })
            if(rel){                
                this.form.body.RdPublishStatus = type
                let timer = new Date().pattern("yyyy-MM-dd")
                let article = this.editor.getContent()        
                for(var i of this.editorFiles){                    
                    if(article.indexOf(i.url) >=0){  
                        that.form.body.ReportDailyAttachments.push({
                            RdarBucketName:"report",
                            RdarObjectName:`daily/${that.form.body.RdSiteId}/${timer}/${i.fileName}`,
                            RdarReportId:that.form.body.RdId
                        }) 
                        article = article.replaceAll(i.url,`${uploadMinioURL}report/daily/${that.form.body.RdSiteId}/${timer}/${i.fileName}`) 
                        //上传            
                        upLoadApi(
                        {
                            url:`/1/report?objectName=daily/${that.form.body.RdSiteId}/${timer}/${i.fileName}`,
                            files:i.file,
                            success:(d)=>{
                                //succFun(`${d}case/editor/image/${timer}/${filename}`)                                            
                            }
                        })
                    }
                }    
                //let content = this.form.body.RdContent.replaceAll(uploadMinioURL,uploadMinioURLServer);                                               
                editDailyReportApi({
                    id:this.$route.query.time+"/" + this.$route.query.id,
                    data:{
                        RdId:that.form.body.RdId,
                        RdSiteId:that.form.body.RdSiteId,
                        RdLidarId:that.form.body.RdLidarId,                                    
                        RdTitle:that.form.body.RdTitle,
                        Author:that.form.body.Author,
                        RdContent:article,                                                   
                        RdPublishStatus:that.form.body.RdPublishStatus, 
                        RdReportId:that.form.body.RdReportId,                          
                        ReportDailyAttachments:that.form.body.ReportDailyAttachments                          
                    },
                    success:(d)=>{
                        updateMask({show:false})
                        if(d){
                            openNotice({
                                type:"success",
                                text:that.$i18n.t("sys.self.notice.success")
                            })                                                            
                        }else{
                            openNotice({
                                type:"error",
                                text:that.$i18n.t("sys.self.notice.fail")
                            })                                 
                        }
                        that.$router.push({path:"/report/dailyManage/index"})
                        that.editorFiles = [];
                    },
                    err:()=>{
                        updateMask({show:false})
                        that.editorFiles = [];
                    }
                })               
            }            
        }        
    }
}
</script>
<style scoped lang="scss">
.cardFrom{
    height: 100%;   
    overflow: auto;
}
:deep(.templateDialog){
    max-height: 600px !important;
    overflow: auto;
}
.templateCard{
    height: 400px;
    overflow: auto;
}
:deep(.caseCard .v-responsive__content){
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.authtext{
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}
.onlytext2{
    height: 34%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.hidemap{
    position: absolute;
    width: 800px;
    height: 800px;
    z-index: -1;
}
</style>
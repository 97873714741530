<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100 pa-0 ma-0">
        <alarmProfile class="mt-10" type="2" :start="timeRange[0]" :end="timeRange[1]" :selectSites="selectSites" ></alarmProfile>   
        <v-card class="width100 height100">
            <div ref="scanMap" class="map noevent"></div>                   
            <div class="configBtns">
                <v-switch title="显示时间" @change="drawText" v-model="showTime" class="mt-0" label="时间" hide-details dense>
                </v-switch>
                <v-switch title="显示天气" @change="setWeather" v-model="showWeather" class="mt-0" label="天气" hide-details dense>
                </v-switch>
                <v-switch title="显示国控点" @change="setMonitorPoint" class="mt-0" label="国控点" hide-details dense>
                </v-switch>
                <v-switch title="显示站点名" @click="drawText" v-model="showSiteName" class="mt-0" label="显示站点名" hide-details dense>
                </v-switch>
            </div> 
            <v-card class="mx-1 playBtns" elevation="0">
                <v-card-subtitle style="position: absolute;top: -25px;left: 0;">
                    {{processTime}}
                </v-card-subtitle>
                <v-card-actions class="d-flex justify-space-around ma-0 px-1">                    
                    <div class="px-1 d-flex justify-center align-center " style="width: calc(100% - 140px);position: relative;">
                        <v-hover v-slot="{ hover }">
                            <v-btn color="secondary" class="transWH" @mousedown="startMove" :style="`left:calc(${progressValue}% - 5px);`" :width="hover?35:25" :height="hover?35:25" absolute fab>{{ Math.ceil(progressValue) }}%</v-btn>
                        </v-hover>                        
                        <v-progress-linear
                        @change="setCurrent"
                        v-model="progressValue"
                        rounded
                        height="10"
                        color="primary"
                        >
                    </v-progress-linear>
                    </div>                    
                    <v-icon @click="play('pre')" title="前一个">mdi-skip-previous</v-icon>
                    <v-icon v-show="(playStatus != 'play') && (playStatus != 'replay')" @click="play('play')">mdi-play</v-icon>
                    <v-icon v-show="(playStatus == 'play') || (playStatus == 'replay')" @click="play('pause')" >mdi-pause</v-icon>
                    <v-icon @click="play('next')" title="后一个">mdi-skip-next</v-icon>   
                    <v-icon style="font-size:22px;padding:4px;" @click="play('replay')">mdi-replay</v-icon>                               
                </v-card-actions>
            </v-card>       
        </v-card>        
    </v-container>
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import alarmProfile from '@/components/monitoring/wls/alarm/alarm'
import barbsSvg from '@/plugins/helper/D3DrawHelper/barbsSvg.js'
export default {    
    name:'wls.realtime.shear',
    props:{
        selectSites:{
            type:Array
        },
        timeRange:{
            type:Array
        },
        dataBarbsList:{
            type:Array
        }
    },
    components: {alarmProfile},
    data(){
        return {            
            mapEchart:null,
            map:null,
            siteList:[],
            mapContextMenuPositon:null,
            playStatus:'pause',
            timmer:null,
            timeOut:null,
            timeList:[],
            currentIndex:0,
            showTime: true,
            showSiteName: false,
            showWeather: false,
            monitorPoints:[],
            monitorPointMarkers: [],
            drawTextTime:null,
            opacity:0.8,
            noticeTimeout:null,
            progressValue:0,    
            drag:false     
        }
    },
    watch:{
        timeRange:{
            handler(n){
                this.timeList = [];
                if(!n || n.length !=2){
                    return
                }
                let start = n[0].getTime()
                let end = n[1].getTime()
                while(start < end){
                    this.timeList.push({
                        time:new Date(new Date().setTime(start)),
                        data:[]
                    })                        
                    start = start + 1000 * 60                     
                }
            },
            deep:true
        },
        selectSites(n){
            let that = this;
            this.siteList.forEach(a=>{
                let curr = n.find(b=>b.siteId == a.site.siteId)
                if(!curr){
                    if(a.ctx){
                        a.ctx.clearRect(0,0,a.canvas.width,a.canvas.height)
                        a.canvasLayer.reFresh()                       
                    }
                    a = null
                }                                
            })
            this.siteList = this.siteList.filter(a=>a != null)
            this.resetSiteMaker()
        },
        dataBarbsList(n){
            this.currentIndex = 0;
            this.siteList.forEach(a=>{
                if(a.ctx){
                    a.ctx.clearRect(0,0,a.canvas.width,a.canvas.height)
                    a.canvasLayer.reFresh()
                    a = null
                }       
            })
            let _n = n.map(a=>{
                a._timeList = a.data.map(b=>new Date(b.dataTime).getTime())
                return a;
            })
            this.timeList.forEach(a=>{
                a.data = [];
                _n.forEach(b => {                   
                    let curr = $help.getClosest(a.time.getTime(),b._timeList,1000*60*10)
                    if(curr){
                        a.data.push({
                            siteId:b.siteId,
                            angle:b.data[curr].angle,
                            speed:b.data[curr].speed,
                            time:b.data[curr].dataTime,
                        })
                    }
                });
            })
            // this.timeList = []
            // n.forEach(a => {           
            //     a.data.forEach(b=>{
            //         let time = new Date(b.dataTime).pattern('yyyy-MM-dd HH:mm')
            //         let current = this.timeList.find(c=>c.time == time)
            //         if(!current){
            //             this.timeList.push({
            //                 time:time,
            //                 data:[{
            //                     siteId:a.siteId,
            //                     speed:b.speed,
            //                     angle:b.angle
            //                 }]
            //             })
            //         }
            //     })                
            // });
            // this.timeList = this.timeList.sort((a,b)=>{
            //     return new Date(a.time).getTime() - new Date(b.time).getTime()
            // })
            // if(this.timeList.length == 0){                           
            //     return;
            // }
            this.loadCurrent();
            this.drawText()
            // this.drawText()
            //this.play('pause')        
        } ,
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadMap()     
            }
        },          
    },
    computed:{        
        barbsHtml(){
            return (speed = 1,angle = 90,color='black')=>{
                if(speed <= 2){
                    color = '#ea80fc'
                }else if(speed > 2 && speed <= 4){
                    color = '#b75df5'
                }else if(speed > 4 && speed <= 6){
                    color = '#3220dc'
                }else if(speed > 6 && speed <= 8){
                    color = '#5efdfd'
                }else if(speed > 8 && speed <= 10){
                    color = '#23fe23'
                }else if(speed > 10 && speed <= 12){
                    color = '#6EAB42'
                }else if(speed > 12 && speed <= 14){
                    color = '#6EAB42' //FFF73E
                }else if(speed > 14 && speed <= 16){
                    color = '#FFF73E'//E4B16B
                }else if(speed > 16 && speed <= 18){
                    color = '#FFF73E'//FD5555
                }else if(speed > 18 && speed <= 20){
                    color = '#FD5555'//EF2D4E
                }else if(speed > 20 && speed <= 22){
                    color = '#FD5555'//72352B
                }else if(speed > 22 && speed <= 24){
                    color = '#969696'
                }else{
                    color = '#bd1084'
                }
                color = 'black'
                let windBarb = new barbsSvg(
                    speed * 5/2  /* wind speed in knots */,
                    angle /* wind direction angle */,      
                {
                    size:{
                        height:12,
                        width:28
                    },
                    triangle:{
                        fill:color,
                        stroke:color
                    },
                    circle:{
                        fill:color,
                        stroke:color
                    },
                    bar:{
                        stroke:color
                        }         
                    }
                    ).draw();  
                    windBarb.setAttribute("viewBox", "0 0 56 56")  
                    windBarb.setAttribute("height", "28")   
                    windBarb.firstChild.setAttribute("transform-origin", "14 14")
                    // let transform = windBarb.firstChild.getAttribute("transform")
                    // let transZRegex = /\.*translate\((.*)\)/i;                    
                    // let xy = transZRegex.exec(trans)[1]?.split(",")  
                    windBarb.firstChild.setAttribute("transform",`translate(14, 14)rotate(${angle})`)
                return windBarb;     
            }             
        },
        processTime(){
            if(!this.timeList || this.timeList.length == 0){
                return ''
            }
            if(!this.drag){
                let current = this.currentIndex -1 < 0 ? 0:this.currentIndex -1;
                return this.timeList[current].time.pattern("yyyy-MM-dd HH:mm")
            }
            let _index = Math.round(this.timeList.length  * this.progressValue / 100) -1;
            _index = _index < 0?0:_index;
            return this.timeList[_index].time.pattern("yyyy-MM-dd HH:mm")
        }
    },   
    mounted(){             
        this.loadMap();       
    },
    methods:{
        loadMap(){
            let target = this.$refs.scanMap;            
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            for(var i of this.siteList){
                if(i.canvasLayer){
                    this.map.remove(i.canvasLayer)
                    i.canvasLayer = null;                
                }                     
            }

            let amap = {
                    // 高德地图中心经纬度
                    rotateEnable: true,
                    pitchEnable: true,
                    isHotspot: false,
                    pitch: 0,
                    rotation: 0,//-55
                    zoom: zoom,//级别
                    zooms: [2, 30],
                    viewMode: '3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                };

            let option = {
                amap: amap,              
            }                
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            // //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl(); 
            var that = this;  
            this.map.on('zoomend',()=>{
                that.loadCurrent()
                that.drawText()
            })
            if(this.selectSites.length > 0){
                that.resetSiteMaker()
            }
            //添加站点位置         
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })     
            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //比例尺
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '60px',right: '10px' },
                    theme:'myself'
                }));

                // //3d指南针控件
                // var controlBar = new AMap.ControlBar({
                //     position:{bottom: '140px',left: '-70px' },
                //     theme:'myself'
                // });
                // that.map.addControl(controlBar) 
                
                //扫描图透明度         
                // let el = that.map.getContainer();
                // let styleO = 'cursor:pointer;bottom:60px;right:10px;';                       
                // if(that.canvasLayer || that.opacity){                    
                //     that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
                // }else{
                //     that.opacity = 0.7
                // }                      
                // var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                // <div class="amap-ui-control-layer-slider" style="display:flex;">
                //     <div class="opacity_box" >
                //         <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                //             <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                //         </svg>
                //     </div>                            
                //     <div class="_slider">
                //         <input type="range" min="1" max="10" value="${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                //         <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                //         <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                //         <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                //     </div>
                // </div>
                // </div>`;   
                // var html = $help.toDom(htmlstr)
                // var _slider = html[0].querySelector("[type='range']");                        
                // var _rest= html[0].querySelector("[data-type='reset']");
                // let _numdiv = html[0].querySelector("[data-type='slider_num']")
                // that.setOpacity = (value)=>{                   
                //     _slider.value = value;
                //     _numdiv.innerHTML = value;                    
                //     //重置maphelper
                //     that.opacity = value/10;
                //     if(that.canvasLayer){
                //         that.canvasLayer.setOpacity(value/10)
                //     }                    
                // }
                // _rest.addEventListener('click',(e)=>{                                                                          
                //     _numdiv.innerHTML = 7;
                //     _slider.value = 7
                //     //重置maphelper
                //     that.opacity = 0.7;
                //     if(that.canvasLayer){
                //         that.canvasLayer.setOpacity(0.7)
                //     }                      
                // })
                // _slider.addEventListener('change',(e)=>{
                //     let value = e.target.value;
                //     _slider.value = value;
                //     _numdiv.innerHTML = value;                    
                //     //重置maphelper
                //     that.opacity = value/10;
                //     if(that.canvasLayer){
                //         that.canvasLayer.setOpacity(value/10)
                //     }  
                // })
                // //_slider.onchange = that.opacityChange(event);
                // el.appendChild(html[0]);  

            })    
        },  
        resetSiteMaker(){
            var that = this;            
            if(this.timeOut){
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(function(){                    
                if(!that.map && that.siteList.length == 0){
                    return;
                }          
                //清空扫描区域信息    
                let oldmarks = that.siteList.map(a=>{
                    return a.siteMarker;
                }).filter(a=>{
                    return a!=null;
                })            
                if(oldmarks.length >0) {
                    that.map.remove(oldmarks);    
                }                    
                let markers = []; 
                that.siteList = that.siteList.filter(a=>{
                    let _item = that.selectSites.find(b=>{
                        return b.siteId == a.site.siteId
                    })
                    if(_item){
                        return  a;
                    }else{
                        a?.canvasLayer?.hide()  
                    }                
                })      
                for(var site of that.selectSites){
                    let currentSiteInfo;
                    that.getCurrentSiteInfo((d)=>{ currentSiteInfo = d },site);   
                    //添加marker
                    currentSiteInfo.siteMarker= new AMap.Marker({
                        content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                    </svg>`,  // 自定义点标记覆盖物内容
                        position:[site.lng,site.lat], // 基点位置
                        offset: new AMap.Pixel(-12, -16), // 相对于基点的偏移位置                                    
                    });                                          
                    currentSiteInfo.siteMarker.on('click',(e)=>{  
                        let _currsite = currentSiteInfo.site;       
                            var htm = `<div>
                            <div style="margin: 10px 5px;">${_currsite.siteName}</div>
                            <div style="margin: 10px 5px;">位置：${_currsite.localtion}</div> 
                            <div style="margin: 10px 5px;">经纬度：${_currsite.lng},${_currsite.lat}</div>   
                            <div style="margin: 10px 5px;">部署时间：${new Date(_currsite.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                            <div style="margin: 10px 5px;float:right"><a id='' href="javascript: void(0)">前往查看</a></div>  
                            </div>`
                        var _el = $help.toDom(htm);              
                        for(var a of _el[0].children){
                            if(a.children && a.children.length > 0){
                                let _link = a.children[0].tagName && (a.children[0].tagName == "a" || a.children[0].tagName == "A")?a.children[0]:null;                                    
                                if(_link){                                                                                
                                    _link.onclick=function(){                                            
                                        that.$store.commit('storage_global/updateCurrentSite',_currsite,{root:true}) 
                                        that.$router.push({path:'/monitoring/wls/realtime'})
                                    }
                                }
                            }                                                        
                        }               
                        new AMap.InfoWindow({
                            content: _el[0],                        
                        }).open(that.map,e.lnglat)                                    
                    })
                    that.map.add(currentSiteInfo.siteMarker)
                    markers.push(currentSiteInfo.siteMarker)                          
                }
                if(that.map){
                    let _markers = markers.filter(a=>{
                        return a != null && typeof(a) != 'undefined'
                    })
                    if(_markers.length > 0){
                        that.map.setFitView(_markers,true,null,13)
                    }                                           
                }else{
                    that.map.on('complete',()=>{                           
                        let _markers = markers.filter(a=>{
                            return a != null && typeof(a) != 'undefined'
                        })
                        if(_markers.length > 0){
                            that.map.setFitView(_markers,true,null,13)
                        }                                                                                         
                    })
                }                                     
            }, 600);             
        }, 
        getCurrentSiteInfo(cb,site){            
            var that = this;
            let _currentSiteInfo = this.siteList.find(a=>{
                return a.site.siteId == site.siteId;
            })         
            if(!_currentSiteInfo){
                let scanDistance = 10
                if(!$help.isNull(site?.extendInfo)){
                    let extendInfo = JSON.parse(site?.extendInfo)
                    scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:10
                }       
                _currentSiteInfo = {
                    site:site,  
                    scanDistance:scanDistance, 
                    canvas:null,
                    ctx:null,
                    canvasLayer:null,                                           
                    weatherInfo:{                        
                        position:"",
                        windPower:"",
                        temperature:"",
                        windDirection:'',
                        humidity:'',
                        weather:''
                    },
                    time:"",                                     
                }
                if(!_currentSiteInfo.site?.weatherInfo || (_currentSiteInfo.site.weatherUpdateTime.getTime() - new Date().getTime())/1000/60/60 > 1){
                    //获取当前站点天气                                        
                    getWeather(_currentSiteInfo.site.district,(data)=>{
                        let weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }
                        that.$store.commit('storage_user/updateSiteWeather',{
                            siteId:_currentSiteInfo.site.siteId,
                            weatherInfo:weatherInfo
                        },{root:true})   
                        _currentSiteInfo.weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }
                        that.siteList.push(_currentSiteInfo)                                            
                    }) 
                }else{
                    _currentSiteInfo.weatherInfo = site.weatherInfo
                    this.siteList.push(_currentSiteInfo)  
                }      
            }                      
            cb(_currentSiteInfo)
        },     
        refresh(siteIds,all){
            if(all){
                this.siteList.forEach(a=>a?.canvasLayer?.refresh)
            }else{
                for(let siteId of siteIds){
                    let _item = this.siteList.find(a=>a.site.siteId == siteId)
                    if(_item){
                        _item?.canvasLayer.refresh();
                    }
                }
            }            
        },      
        play(status){                               
            this.playStatus = status;            
            var that = this;                       
            if(this.timeList.length > 0){   
                if(this.playStatus == 'play'){
                    //播放                                                  
                    if(this.timmer!= null){                 
                        this.refresh(null,true);
                        clearInterval(this.timmer)                        
                    }     
                    this.timmer = setInterval(() => {                                                                         
                        if(that.$route.path.replace('/m/','/') !="/monitoring/wls/windowUnion"){
                            clearInterval(that.timmer)
                        }
                        if(that.currentIndex > that.timeList.length -1) {
                            that.currentIndex = that.timeList.length -1;                            
                            clearInterval(that.timmer)
                            this.playStatus = 'pause'   
                            openNotice({
                                type:"info",
                                text:that.$i18n.t("history.notice.loadend")
                            })                                                 
                        }
                        that.loadCurrent();
                        that.drawText()
                        that.currentIndex = that.currentIndex > that.timeList.length -1 ? 0: that.currentIndex;                        
                        that.currentIndex +=1;
                    }, 1000);                
                }else if(this.playStatus == 'replay'){
                    //重播
                    that.currentIndex = 0;
                    that.stopLoad()                 
                    that.timmer = setInterval(() => {                    
                        that.loadCurrent();
                        that.drawText()
                        that.currentIndex +=1;
                        if(that.currentIndex > that.timeList.length -1) {
                            that.currentIndex = 0;
                            clearInterval(that.timmer)
                            that.playStatus = 'pause'
                        }
                    }, 800);
                    
                }else if(this.playStatus == 'pause'){
                    //暂停
                    that.stopLoad()
                }else if(this.playStatus == 'next'){
                    //下一张
                    that.stopLoad()
                    if(that.currentIndex+1 > that.timeList.length -1) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgEnd")
                        })
                        return;
                    }
                    that.currentIndex +=1;
                    that.loadCurrent();
                    that.drawText()
                }else if(this.playStatus == 'pre'){
                    //前一张
                    that.stopLoad()
                    if(that.currentIndex-1 < 0) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgStart")
                        })
                        return;
                    }
                    that.currentIndex -=1;
                    that.loadCurrent();
                    that.drawText()
                }

            }else{
                openNotice({
                    type:'error',
                    text:'暂无可播放数据'
                })
                this.playStatus = 'pause'
                return;
            }               
        },
        loadCurrent(){                        
            var that = this;
            if(that.timeList.length == 0 || that.currentIndex >= that.timeList.length ){
                return 
            }            
            let currentTime =that.timeList[that.currentIndex];
            console.log(this.currentIndex,this.timeList.length - 1)
            this.progressValue= Math.round( this.currentIndex * 100 / (this.timeList.length - 1));
            this.selectSites.forEach(site=>{
                let currentTimeData = currentTime.data.find(a=>a.siteId == site.siteId)
                let currentSiteInfo;
                this.getCurrentSiteInfo((d)=>{ 
                    currentSiteInfo = d;
                    currentSiteInfo.time = currentTimeData?.time
                 },site); 
                let _width = 240;   
                let w = _width,h = _width;      
                if(currentTimeData && currentTimeData.speed != "NaN" && currentTimeData.angle != "NaN" && currentSiteInfo?.site){                       
                    let zoom = this.map.getZoom();   
                    let dis = 0.012 + (13-zoom)/100;
                    // let pos = this.map.lngLatToContainer([currentSiteInfo.site.lng,currentSiteInfo.site.lat]);                      
                    // pos.y = 274;
                    //let lnglat = this.map.containerToLngLat(pos); 
                    let arr =$help.getLayerMapPosition(90,14-zoom,[currentSiteInfo.site.lng,currentSiteInfo.site.lat+dis])                   
                    if(!currentSiteInfo.canvas){
                        let canvas = document.createElement('canvas')
                        //canvas = this.$refs.polar;
                        let ctx =canvas.getContext("2d"); 
                        canvas.width = w;
                        canvas.height = h;               
                        currentSiteInfo.canvas = canvas;
                        currentSiteInfo.ctx = ctx;
                    }
                    var bounds = new AMap.Bounds(arr[0],arr[1]) 
                    if(!currentSiteInfo.canvasLayer){
                        currentSiteInfo.canvasLayer = new AMap.CanvasLayer({
                            canvas: currentSiteInfo.canvas,
                            bounds: bounds,  
                            opacity:this.opacity,  
                            zooms: [2, 30],
                            zIndex:20           
                        });        
                        this.map.add(currentSiteInfo.canvasLayer);
                    }else{
                        if(currentSiteInfo.canvas.width != _width){
                            currentSiteInfo.canvas.width = w;
                            currentSiteInfo.canvas.height = h;
                            currentSiteInfo.canvasLayer.setCanvas(currentSiteInfo.canvas)
                        }                
                        currentSiteInfo.canvasLayer.setBounds(bounds)                                  
                    }   
                    consolelog(`速度${currentTimeData.speed},角度${currentTimeData.angle}`)
                    var serializer = new XMLSerializer();  
                    var source = '<?xml version="1.0" standalone="no"?>\r\n'+serializer.serializeToString(this.barbsHtml(currentTimeData.speed,currentTimeData.angle));  
                    var image = new Image(28,28);  
                    image.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source); 
                    // let dataUrl =  window.btoa(this.barbsHtml(a.speed,a.angle).outerHTML) 
                    // let img = new Image();
                    // img.src = 'data:image/svg+xml;base64,' + dataUrl;
                    image.crossOrigin ="anonymous"
                    image.onload=(f)=>{                                          
                        currentSiteInfo.ctx.clearRect(0,0,w,h)   
                        //currentSiteInfo.ctx.rect(0,0,w,h);
                        //currentSiteInfo.ctx.strokeWidth =2;
                        //currentSiteInfo.ctx.stroke()         
                        currentSiteInfo.ctx.drawImage(f.currentTarget,0,0,w,w);                            
                        currentSiteInfo.canvasLayer.reFresh();    
                    }        
                }else{
                    if(currentSiteInfo.ctx){                        
                        currentSiteInfo.ctx.clearRect(0,0,w,h)  
                        currentSiteInfo.canvasLayer.reFresh();  
                    }
                }   
            })                            
        },
        setCurrent(){
            if(this.dataBarbsList.length == 0){
                openNotice({
                    type:'error',
                    text:'暂无可播放数据'
                })
                $help.sleep(200).then(a=>{
                    this.progressValue = 0;
                })                
                return
            }
            this.currentIndex = Math.round(this.timeList.length  * this.progressValue / 100);
            this.play('pause');
            this.loadCurrent()
            this.drawText()
        },
        stopLoad(){
            if(this.timmer!= null){
                clearInterval(this.timmer)
            }  
        },
        setMonitorPoint(open) {
            if (open) {
                var that =this;
                this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                    that.monitorPoints = d;
                    if(that.monitorPointMarkers.length > 0){
                        that.map.remove(that.monitorPointMarkers)
                    }               
                    loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                        that.monitorPointMarkers.push(marker);
                    },(point)=>{
                        that.$refs.monitorPointDataCard.show = true
                        that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                        that.$refs.monitorPointDataCard.load()
                    })
                },{root:true})
            } else {
                this.map.remove(this.monitorPointMarkers)
            }
        },
        setWeather(open) {
            if (open) {
                for (var i of this.siteList) {
                    let Item = i;
                    getWeather(i.site.district, (data) => {
                        Item.weatherInfo = {
                            position: data.city,
                            windPower: data.windPower,
                            temperature: data.temperature,
                            windDirection: data.windDirection,
                            humidity: data.humidity,
                            weather: data.weather
                        }
                    })
                }
            }
            this.drawText();
        }, //周期时间显示
        async drawText() {
            var that = this;
            //500毫秒内请求合并
            if (this.drawTextTime) {
                clearTimeout(this.drawTextTime)
            } else {
                setTimeout(() => {
                    if (that.map) {
                        let dataTime = []
                        if (that.showTime) {
                            dataTime = that.siteList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 80) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.time]
                            })
                        }
                        let dataWeather = [];
                        if (that.showWeather) {
                            dataWeather = that.siteList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 40) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.weatherInfo]
                            })
                        }
                        let dataSiteName = []
                        if (that.showSiteName) {
                            dataSiteName = that.siteList.map(a => {
                                let pos = that.map.lngLatToContainer([a.site.lng, a.site.lat]);
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y - 80) / Math.cos(angle / 360)).toFixed(2);
                                let lnglat = that.map.containerToLngLat(pos);
                                return [lnglat.lng, lnglat.lat, a.site.siteName]
                            })
                        }
                        let opt = {
                            series: [{
                                type: 'scatter',
                                data: dataTime,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: '{@[2]}',
                                    fontSize: 24,
                                }
                            }, {
                                type: 'scatter',
                                data: dataSiteName,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: '{@[2]}',
                                    fontSize: 14,
                                    padding: 6,
                                    borderRadius: 5,
                                    fontSize: 18,
                                    align: 'center',
                                    verticalAlign: "middle",
                                    borderColor: '#5499d9',
                                    borderWidth: 1,
                                    backgroundColor: "#fff"
                                }
                            }, {
                                type: 'scatter',
                                data: dataWeather,
                                coordinateSystem: 'amap',
                                symbolSize: 0,
                                label: {
                                    show: true,
                                    formatter: (re) => {
                                        let weatherInfo = re.data[2]
                                        return `天气：${weatherInfo.weather} 风向：${weatherInfo.windDirection} 风力：${weatherInfo.windPower} 温度：${weatherInfo.temperature} 湿度：${weatherInfo.humidity}`
                                    },
                                    padding: 6,
                                    borderRadius: 5,
                                    lineHeight: 12,
                                    fontSize: 14,
                                    // color:'#fff',
                                    align: 'center',
                                    verticalAlign: "middle",
                                    borderColor: '#5499d9',
                                    borderWidth: 1,
                                    backgroundColor: "#b1d7a8"
                                }
                            }]
                        }
                        if (that.mapEchart && that.mapEchart.setOption) {
                            that.mapEchart.setOption(opt)
                        }
                    }
                }, 500);
            }
        },   
        startMove(ev){
            this.play('pause');
            ev.preventDefault()
            ev.stopPropagation()   
            let fun = (e)=>{
                e.preventDefault()
                e.stopPropagation()
                this.drag = true;
                this.progressValue = this.progressValue + (e.movementX > 0 ? 1 :e.movementX <0? -1:0)/1.5 ;
                this.progressValue = this.progressValue > 100 ? 100 :this.progressValue<0 ? 0:this.progressValue
            }
            let moveStop= (e)=>{
                e.preventDefault()
                e.stopPropagation()  
                document.removeEventListener('mousemove',fun)
                document.removeEventListener('mouseup',moveStop)
                this.drag = false;
                this.setCurrent()
            }
            document.addEventListener('mousemove',fun )
            document.addEventListener('mouseup', moveStop);

        }
    }
}
</script>
<style scoped lang="scss">
.map{
    height: calc(100% - 60px) ;
    width: 100%;
    position: relative;
}
//比例尺
:deep(.amap-scalecontrol){
    left: auto !important;
    right:5% !important;
}
.playBtns{
    position: absolute;
    bottom: 0px;
    z-index: 2;
    width: 100%;
    right: 0px;
}
.configBtns{
    width: 100px;
    bottom: 60px;
    position: absolute;
}
.transWH{
    font-size: 12px;
    transition: 0.15s linear;    
    transform-origin: center;
}
</style>


<template>
     <v-container fluid  class="d-flex height96 flex-column elevation-5 ma-4 pa-0">
        <v-card class="ma-0 pa-6 width100 height100" elevation="0">
        <v-row class="view-header">
            <v-icon @click="$router.history.go(-1)">mdi-chevron-left</v-icon>           
        </v-row>          
        <router-view class="r-view-box"></router-view>  
        </v-card>
    </v-container>            
</template>
<script>
export default {
    name:"manageLayout"
}
</script>
<style scoped lang="scss">
.view-header{
    height: 30px;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 5px 0;
}
.r-view-box{
    height: 96%;
    width: 100%;

}
.height96{
    height: 96% !important;
    width: 98% !important; 
}

</style>
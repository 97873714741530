<template>
    <svg xmlns="http://www.w3.org/2000/svg" :preserveAspectRatio="preserveAspectRatio" :viewBox="viewbox">     
        <use class="c1" :xlink:href="`#icon-${fname}`"></use>                
    </svg>
</template>
<script>
export default {
    name:"SvgHelper",
    props:{
        fname:{
            type:String
        },
        preserveAspectRatio:{
            type:String,
            default:"xMinYMin slice"
        },
        viewbox:{
            type:String,
            default:"0 0 100% 100%"
        }
    },
    data(){
        return {
           
        }
    },
    mounted(){        
        //console.log(fname)

    },
    methods:{

    }
}
</script>
<style scoped>
    /* .c1{
         width:100%;
        height: 100%; 
    } */
</style>
<template>
    <v-container  class="ma-0 pa-0 d-flex flex-column align-center">        
        <v-menu offset-x nudge-left="120" nudge-top="-40" bottom v-model="openMenu" 
        >
            <template v-slot:activator="{ on,attrs }">
                <v-btn 
                text
                fab 
                small
                v-bind="attrs"
                v-on="on"  
                :disabled="disabled"
                >
                <v-icon  :style="`width:100%;height:100%;-webkit-background-clip: text;background-image:linear-gradient(to bottom right,${colorList.join(',')});`" color="transparent">mdi-palette</v-icon>  
                </v-btn>                  
            </template>
            <!-- 色表卡设置 -->
            <v-card class="pa-2" :min-width="minWidth" max-height="500px" style="overflow: auto;">
                <v-card elevation="0" width="100%" max-height="420px" style="overflow: auto;">
                    <v-row v-show="piece.show" class="ma-0 pa-0" v-for="piece,k in pieceList" :key="k">
                    <v-col cols="1">{{k+1}}</v-col>
                    <v-col class="d-flex">                        
                        <v-text-field   
                        label="最小值："
                        @click.stop=""
                        type="number"
                        v-model="piece.gt"
                        dense
                        hide-details
                        hide-spin-buttons
                        ></v-text-field >                    
                    </v-col>                    
                    <v-col class="d-flex">
                        <v-text-field   
                        @click.stop=""
                        v-model="piece.lte"
                        label="最大值："
                        type="number"
                        dense
                        hide-details
                        hide-spin-buttons
                        ></v-text-field >    
                    </v-col>    
                    <v-col cols="1"  class="d-flex">
                        <v-menu v-model="piece.showMenu">
                                <template v-slot:activator="{ on,attrs }">                                  
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mx-2"
                                        width="20"
                                        height="20"
                                        fab
                                        dark
                                        small                                        
                                        :color="piece.color"
                                    >                                             
                                    </v-btn>                                       
                                </template>
                                <v-card>
                                    <div @click.stop="" >
                                        <v-color-picker
                                            hide-inputs
                                            v-model="piece.color"
                                            hide-mode-switch
                                            @click.stop=""
                                        ></v-color-picker>       
                                        <v-row class="justify-end d-flex ma-0 pa-0"> 
                                            <v-btn @click="piece.showMenu = !piece.showMenu" color="primary" text>确定</v-btn>           
                                        </v-row>                                            
                                    </div>
                                </v-card>
                        </v-menu>
                    </v-col>  
                    <v-col cols="1">
                        <v-btn @click.stop="del(k)" height="25" width="25"  fab small  text>
                            <v-icon>mdi-delete</v-icon>
                         </v-btn>  
                    </v-col>                    
                </v-row>  
                </v-card>                
                <v-row class="ma-0 pa-0 d-flex justify-space-between">   
                    <v-col class="ma-0 pa-0">
                         <v-btn @click="save" color="primary" class="mx-2" >
                            保存
                        </v-btn>       
                        <v-btn @click.stop="reset" color="secondary" class="mx-2" >
                            重置
                        </v-btn>                 
                        <v-btn >
                            取消
                        </v-btn>                   
                    </v-col>                     
                    <v-btn
                        @click.stop="addPiece()"
                        class="mx-1"
                        width="30"
                        height="30"
                        fab
                        color="primary"
                        small                        
                    >        
                    <v-icon>mdi-plus</v-icon>                    
                    </v-btn>   
                </v-row>               
            </v-card>
        </v-menu>
    </v-container>
</template>
<script>
import equipmentType from '@/plugins/datas/equipmentType'
export default {
    name:"AgtExternalColorBarConfig",
    props:{      
        dataKey:{

        },       
        disabled:{
            type:Boolean,
            default:false
        }        
    },    
    data(){
        return{
            unit:"",   
            openMenu:false,//菜单是否开启
            //色表卡            
            min:0,
            max:100,   
            pieceList:[]                          
        }
    },
    watch:{  
        openMenu(){
            this.getPieces()
        }
    },
    mounted(){        
        this.getPieces()
        //console.log(this.pieceList)
    },
    computed:{
        colorList(){
            return this.pieceList?.map(a=>a.color)
        },        
        minWidth:()=>{
            return $help.isMobile() ? '100%':420
        },
        pieces(){
            let pieces =  JSON.parse(JSON.stringify(this.pieceList))
            pieces.push({
                color:this.pieceList[this.pieceList.length -1].color,
                gt:this.pieceList[this.pieceList.length -1].lte
            })
            pieces.unshift({
                color:this.pieceList[0].color,
                lte:this.pieceList[0].gt
            })
            return pieces
        }  
    },
    methods:{ 
        getPieces(reset){
            if(localStorage.getItem('AgtExternalData'+this.dataKey) && !reset){
                this.pieceList = JSON.parse(localStorage.getItem('AgtExternalData'+this.dataKey))
            }else{
                var equipment = equipmentType.find(a=>{
                    return a.key == this.dataKey;
                })
                this.pieceList = equipment.colors.map((a,index)=>{
                    return {
                        color:a,
                        gt:index * equipment.max/equipment.colors.length,
                        lte:equipment.max/equipment.colors.length * (index + 1),
                        showMenu:false,
                        show:true
                    }
                })                  
            }            
        },
        addPiece(){
            if(this.pieceList.length >= 10){
                openNotice({
                    type:"error",
                    text:`已达到最大行数`
                })
                return;
            }
            let pieces = this.pieceList       
            pieces.push({
                color:'#fd0100',
                gt:Number(pieces[pieces.length -1].lte),
                lte:Number(pieces[pieces.length -1].lte)+100,
                showMenu:false,
                show:true
            })              
            this.pieceList = pieces
        },
        del(i){
            if(this.pieceList.length == 1){
                openNotice({
                    type:"error",
                    text:`最少保留一行`
                })
                return;
            }
            this.pieceList = this.pieceList.filter((a,_i)=>i!=_i)
        },
        save(){
            let pieces = this.pieceList;
            for(var i in pieces){
                pieces[i].gt = Number(pieces[i].gt)
                pieces[i].lte = Number(pieces[i].lte)
                let _item = pieces[i]
                if(_item.lte == _item.gt){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值最小值相等`
                    })
                    return;
                }
                if(_item.lte < _item.gt){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值小于最小值`
                    })
                    return;
                }
                if(i < (pieces.length -1)){
                    let _next = pieces[Number(i)+1];
                    if(Number(_item.lte)!= Number(_next.gt)){
                        openNotice({
                            type:"error",
                            text:`数据段必须连续，请确保第${Number(i)+1}行最大值为第${Number(i)+2}行最小值`
                        })
                        return
                    }                    
                }
            }           
            this.pieceList = pieces
            localStorage.removeItem('AgtExternalData'+this.dataKey)
            localStorage.setItem('AgtExternalData'+this.dataKey,JSON.stringify(this.pieceList))
            this.$emit('update')
        },
        reset(){
            //localStorage.removeItem('AgtExternalData'+this.dataKey)
            this.getPieces(true)
            //this.$emit('update',false)
        }
    }
}
</script>
<style scoped lang="scss">
.mapColorBar{
    z-index: 2;
    position:relative;
    top: 0px;
    right: 0px;  
    height: 100%;  
}
.v-otp-input{
    max-height: 32px;
    :deep(fieldset){
        max-height: 32px;
    }
    :deep(.v-text-field__slot){
        max-height: 32px;
    }
}
</style>
<template>
    <v-card class="width100 height100 containRhibox">  
        <v-overlay
            absolute
            :value="overlay"
            >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>  
        <v-overlay
        class="ma-3 mt-9"
            absolute
            :value="noData"
            opacity="0.2"
            >
        暂无数据
        </v-overlay>
        <v-card-title class="ma-0 pa-1 rhiTitle">RCS垂直扫描图谱</v-card-title>
        <v-card-actions class="mapToolbar "> 
            <v-card class="ma-0" style="z-index:2;position: absolute;right: 112px;">
                <v-card-actions class="d-flex justify-space-around mx-1 py-1 px-1">
                    <v-icon class="pt-1" @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                    <v-icon class="pt-1" @click="play('next')" title="下一帧">mdi-skip-next</v-icon>                                               
                </v-card-actions>
            </v-card>  
            <v-row class="ma-0 pa-0">
                <v-col class="pa-0">
                    <v-menu open-on-click offset-y bottom @input="timerDraw">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn    
                            class="mx-2 py-0"                                                                                      
                            v-bind="attrs"
                            v-on="on"
                            >
                            {{scaleConfig.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="scaleConfig">
                                <v-list-item
                                    v-for="(item, index) in scaleConfigList"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>                                   
                </v-col>
            </v-row>            
        </v-card-actions>
        <ColorBarConfig  @update="timerDraw" :dataKey="17" ref="colorBar" class="mapColorBarConfig" />       
        <div class="mx-1" style="position:absolute;top:32px;z-index: 200;">{{time}}</div>
        <!-- <canvas class="rhitab height100 width100" @mousewheel.stop="scaleCanvas" @mousedown.stop="drag" ref="canvas">        
        </canvas> -->
        <div ref="chart" class="rhitab width100 height100 plotychart" >

        </div>
    </v-card>
</template>
    
<script>
import transferDataType from '@/plugins/datas/transferDataType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import scanType from '@/plugins/datas/scanType'
import {getPlsRcsListApi} from '@/api'
import Plotly from 'plotly.js-dist'
export default {
      name: 'pls.history.RHI',
      props:{
        periodList:{
            type:Array
        },
        timeRange:{
            type:Array
        }
      },
      components: {
        ColorBarConfig
      },
      data(){
        return{
            overlay:false,
            datas:[],
            currentIndex:0,
            scaleConfig:{
                name:'1:1',
                value:1
            },
            scaleConfigList:[{
                name:'1:1',
                value:1
            },
            {
                name:'2:1',
                value:2
            },
            {
                name:'5:1',
                value:5
            },{
                name:'10:1',
                value:10
            },{
                name:'1:2',
                value:0.5
            }],            
            scanDistance:6,
            yaw:90,
            canvas:null,
            ctx:null,
            scale:1.1, //0.5,
            step:0.01,
            offsetX:-20,
            offsetY:160,
            timer:null,
            nextPeriod:'',
            prePeriod:'',
            time:''
        }
      },  
      computed:{
        noData(){
            return !this.overlay && (!this.datas || this.datas.length == 0) 
        },
      },   
      watch:{    
        "$store.state.storage_global.currentSite":{
            handler(n){
                if(n.extendInfo){            
                    let extendInfo = JSON.parse(n.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }   
                if(this.ctx){
                    this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)
                }
                this.datas = []
                this.nextPeriod = ''
                this.loadRHIData()
            }
        },    
        timeRange:{
            handler(n){                        
                if(n.length > 0){                       
                    if(this.ctx){
                        this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)
                    }
                    this.nextPeriod = ''
                    this.loadRHIData()
                }
            },
            deep:true
        }
      },
      mounted(){
        var that = this;       
        this.$nextTick(()=>{
            //that.load()
            that.loadRHIData()             
        })                       
      },
      methods:{
        loadRHIData(desc = false){            
            this.overlay = true;
            var that = this;
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //      
            let period = desc? this.prePeriod : this.nextPeriod       
            getPlsRcsListApi({
                id:this.$store.state.storage_global.currentSite.siteId,                
                params:{
                    Period:period,                  
                    Time:start,
                    Desc:desc                  
                },
                success:(d)=>{                        
                    that.nextPeriod = d.NextPeriod?.split("_")[0]
                    that.prePeriod = d.PrePeriod?.split("_")[0]                    
                    if(!d.Data || d.Data.length == 0){
                        that.overlay = false;
                        openNotice({
                            type:"info",
                            text:desc?'已加载到开始':'已加载到结尾'
                        })                        
                        return
                    }                    
                    that.time =  new Date(d.Data[0].DprTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                    that.datas = d.Data.map(a=>{
                        return {
                            angleOfPitch: a.DprPitch,
                            data:a.DprValue,                                                    
                            dataTime: new Date(a.DprTime.replace("T"," ")).pattern("yyyy-MM-ddTHH:mm:ss"),
                            dataType:transferDataType.rcs,
                            distanceData: a.DprDis,
                            period:a.DprPeriod,
                            radarId: a.DprSiteId,
                            scanType:scanType[a.DprScanMode],
                            straightAngle:a.DprStraight
                        }
                    })                                   
                    that.timerDraw()
                    that.overlay = false;
                }
            })            
        },       
        play(status){                   
            if(status == 'next'){
                if((!this.nextPeriod || Number(this.timeRange[1].pattern("yyyyMMddHHmmss")) - Number(this.nextPeriod) <= 0) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }   
                this.loadRHIData()        
            }else{
                if(!this.prePeriod || Number(this.timeRange[0].pattern("yyyyMMddHHmmss")) - Number(this.prePeriod) >= 0) {
                openNotice({
                    type:"info",
                    text:'已加载到开始'
                })                        
                return
            }
                this.loadRHIData(true)            
            }
        },
        loadChart(){   
            //处理数据
            //处理数据
            var that = this;
            let d = this.scanDistance //扫描公里
            let target = this.$refs.chart
            if(!this.$refs.colorBar || !target){
                return;
            }
            let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
            let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);                         
            let _datas =this.datas.map(a=>{
                return {
                    angle:a.angleOfPitch,
                    data:a.data,                    
                    dataTime:a.dataTime,
                    distanceData:a.distanceData,       
                }
            });      
            if(_datas.length == 0)     {
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                                
                var data = [
                {
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,                    
                    }
                ];
            
                var layout = {               
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,         
                    xaxis: {
                        constrain:'range',
                        color:fontColor,
                        title:'距离(km)',
                        dtick:1,
                        domain:5,
                        range:[0,8],
                        tickmode:'linear',
                        tick0:0,
                        scaleanchor:'y',
                        scaleratio:1
                    },
                    yaxis: {     
                        constrain:'range',
                        color:fontColor,       
                        dtick:1,
                        domain:5,
                        title:'距离(km)',
                        tickmode:'linear',
                        range:[-0.1,3],
                        tick0:0
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:48,
                        r:46,  
                        l:42  
                        // l:22,
                    }
                };                       
                Plotly.newPlot(target, data, layout, {responsive: true});
                return
            }            
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (distanceData[0])/distanceData[1]     //无效距离          
            let scanLength =distanceData[2]*distanceData[1]       
            let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
            let hoverText = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => ''));             
            $help.drawScan(_datas,(obj)=>{                
                zData[obj.y][obj.x]=obj.value;
                hoverText[obj.y][obj.x]= `角度：${(obj.angle).toFixed(2)}<br />距离：${(obj.magnitude/100).toFixed(4)}`
            },()=>{
                let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                          
                var data = [
                    {
                    y: yData,
                    x: xData,
                    z: zData,
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:hoverText,
                    colorscale:that.$refs.colorBar.colorScaleValue,
                    zmin:that.$refs.colorBar.rangeInfo[0].min,
                    zmax:that.$refs.colorBar.rangeInfo[that.$refs.colorBar.rangeInfo.length-1].max
                    }
                ];
        
                var layout = {               
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,         
                    xaxis: {
                        constrain:'range',
                        color:fontColor,
                        title:'距离(km)',
                        range:[0,8],
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        scaleanchor:'y',
                        scaleratio:this.scaleConfig.value
                    },
                    yaxis: {     
                        constrain:'range',
                        color:fontColor,   
                        range:[-0.1,3],    
                        dtick:1,
                        domain:5,
                        title:'距离(km)',
                        tickmode:'linear',
                        tick0:0
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:48,
                        r:46,  
                        l:42  
                        // l:22,
                    }
                };     
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true});               
                }       
            },noDataLength,scanLength,distanceData[2],{d:d})          
        },    
        timerDraw(){  
            if(this.datas.length == 0){
                return
            }
          var that = this;
          if(this.timer){
            clearTimeout(this.timer)
          }
          this.timer = setTimeout(() => {
            //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
            //that.drawHelper()
            that.loadChart()
          }, 1000/3);
        },
        }
    }
</script>
    
<style scoped lang="scss">
.mapToolbar{
    width:120px;
    right:85px;
    top:5px;
    right:40px;
    position: absolute;
    z-index: 1;
}
.rhitab{
    height:100%;
    position: relative;
}
.containRhibox{
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.rhiTitle{
    position: absolute;    
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.plotychart{
    :deep(.modebar){
        padding-top: 48px;
        padding-right:60px ;
    }
}
</style>
    
<template>
    <div class="inputItem align-center d-flex justify-center">
        <div class="label">{{label}}</div>
        <!-- <v-overflow-btn 
            :style="`width:${width}px`"
            class="selectItem mt-0"
            :multiple="multiple"
            dense            
            :items="options"      
            :placeholder="placeholder"
            hide-details            
            item-text="text"
            item-value="value"
            :no-data-text="noDataText"
            :value="value"
            :clearable="clearable"
            @change="commit"
        ></v-overflow-btn> -->
        <v-select
            :items="options"
            :style="`width:${width}px`"  
            dense 
            class="sinputBorder" 
            :multiple="multiple"                  
            :placeholder="placeholder"
            hide-details            
            item-text="text"
            item-value="value"
            :no-data-text="noDataText"
            :value="value"
            :clearable="clearable"
            @change="commit"
            solo
        ></v-select>
    </div>
</template>

<script>
export default {
    name:"SelectInput",
    props:{
        clearable:{
            type:Boolean,
            default:false
        },  
        noDataText:{
            type:String,
            default:""
        },
        width:{
            type:Number,
            default:138
        },
        label:{
            type:String,
            default:""
        },
        multiple:{
            type:Boolean,
            default:false
        },
        options:{
            type:Array,
            default:()=>{
                return new Array()
            }
        },    
        placeholder:{
            type:String,
            default:""
        },  
        value:{            
            default:""
        },             
    },     
    methods:{
        commit(e){
            this.$emit("update:value",e)
        }
    }
}
</script>


<style scoped lang="scss">
.inputItem{
    margin: 10px;
}
.label{
    margin: 5px;
}
.sinputBorder{
    /* border:1px solid var(--v-white-darken4); */
    box-shadow:0px 0px 0px 0px var(--v-white-darken2), 1px 0px 2px 0px var(--v-white-darken2), 0px 0px 6px 0px var(--v-white-darken2) !important;
    border-radius: 5px;   
    :deep(.v-input__slot){
        box-shadow:0px 0px 4px 0px rgba(0,0,0,0.1) !important;
    }
}
// .selectItem{
//     /* border:1px solid var(--v-white-darken4); */
//     box-shadow:1px 0px 2px -1px  var(--v-white-darken4), 1px 1px 4px 0px  var(--v-white-darken4), 0px 1px 6px 0px  var(--v-white-darken4) !important;
//     border-radius: 5px;   
//     min-width: 138px !important;     
// }
// .selectItem :deep(.v-select__slot){
//    width: 100%;
// }
// .selectItem :deep(.v-input__append-inner){
//    position: absolute;
//    right: 20px;
// }
// :deep(.v-autocomplete.v-select.v-input--is-focused input) {
//     min-width: 0;
// }
// :deep(.v-overflow-btn .v-input__append-inner){
//     height: 100% !important;
// }

// :deep(.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot){
//         box-shadow:none !important;
//     }   
// :deep(.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections + .v-input__append-inner){
//     width: 0 !important;

// }
</style>
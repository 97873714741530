<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">
        <v-overlay
          absolute
          :value="overlay"
          class="ma-3"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <ColorBarConfig @update="timerDraw"   :dataKey="51"  ref="colorBar" class="mapColorBarConfig"/>
        <v-card height="100%" width="100%" elevation="1">   
            <v-card-title class="ma-0 pa-1 heatmapTitle">风切变</v-card-title>
            <div class="mx-1" style="position:absolute;top:32px;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>                           
            <div ref="polar" class="width100 height100">

            </div>
        </v-card>                      
    </v-container>
</template>
<script>
import { getWlsShearApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:'wls.realtime.shear',
    components: {
        ColorBarConfig
      },
    data(){
        return {
            overlay:false,
            dataList:[],
            yaw:'',
            currentWatch:null,            
            timer:null
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){
                this.dataList = []   
                this.currentWatch()        
                let currentScanType = this.$store.state.storage_global.currentSite.scanType;   
                if(currentScanType == "RHI" || currentScanType == "PPI"){                        
                    this.watchData(n.siteId)     
                    this.load()                
                }    
            }
        },        
    },
    computed:{        
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0)
        },
        time(){
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            let period = this.dataList[0]?.period?.split('_')[0]            
            if(period){
                return `${ $help.periodToTimeStr(period)}`
            }else{
                return ''
            }  
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;  
        setTimeout(() => {     
            let currentScanType = that.$store.state.storage_global.currentSite.scanType;
            if(that.dataList.length == 0 && (currentScanType == "RHI" || currentScanType == "PPI")){                
                that.watchData(that.$store.state.storage_global.currentSite.siteId)   
                that.load()  
            }  
        }, 600);          
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                          
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == transferDataType.radialWindShear.value ){                         
                    let json = JSON.parse(JSON.stringify(n.jsonData))
                    that.dataList = json.map(a=>{
                        return {
                            dataTime:a.dataTime,
                            distanceData:a.distanceData,
                            angle:a.scanType == 'PPI' ? a.straightAngle:a.scanType == 'RHI'?a.angleOfPitch:a.straightAngle,                        
                            scanMode:scanType[a.scanType],
                            straightAngle:a.straightAngle,
                            angleOfPitch:a.angleOfPitch,   
                            data:a.data,
                            period:a.period
                        }
                    })
                    that.yaw= json[0].yaw;
                    that.loadChart()
                }
            }, {immediate: true })
        },     
        load(){
            var that = this;
            this.overlay = true;
            let _scanType = this.$store.state.storage_global.currentSite.scanType == null || typeof(this.$store.state.storage_global.currentSite.scanType) == 'undefined'? "PPI":this.$store.state.storage_global.currentSite.scanType
            getWlsShearApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                period:'latest',
                scanType:_scanType == "PPI"?0:_scanType == "FIX"?1:_scanType == "RHI"?2:_scanType == "Tri"?3:0,
                success:(d)=>{
                    that.overlay = false;             
                    that.dataList = d.map(a=>{
                        return {
                            dataTime:a.DwsTime.replace('T',' '),
                            distanceData:a.DwsDis,
                            angle:a.DwsScanMode == 0 ? a.DwsStraight:a.DwsScanMode == 2?a.DwsPitch:a.DwsStraight,                        
                            scanMode:scanType[a.DwsScanMode],
                            straightAngle:a.DwsStraight,
                            angleOfPitch:a.DwsPitch,   
                            data:a.DwsValue,
                            period:a.DwsPeriod
                        }
                    }); 
                    if(that.dataList.length>0)
                    {
                        let phj= that.dataList[0].period.split('_');
                        if(phj.length>1)
                        {
                          that.yaw=Number(phj[1])%360;
                        }

                    }
                    that.loadChart();
                }
            })
        },
        loadChart(){   
            let d = 3.3               
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;   
            var that = this;      
            let target = this.$refs.polar
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                                         
                let layout = { 
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                 
                    polar: {                    
                        radialaxis: {    
                            color:fontColor,                     
                            visible: true,
                            range: [0, d]
                        },
                        angularaxis: {
                            color:fontColor, 
                            tickfont: {
        
                            },
                            direction: "clockwise"
                        }
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    showlegend: false,
                    margin:{
                        t:38,
                        r:12,  
                        l:12                        
                    }
                }            
                Plotly.newPlot(target, [{
                        type: "scatterpolar",
                        r: [],
                        theta: [],
                        text: [],
                        mode: "markers",
                        name: "",
                        marker: {
                            color: `rgba(0,0,0,0)`,
                            size: 3,                         
                            opacity: 0.7
                        },
                        cliponaxis: false
                    }], layout,{responsive: true,scrollZoom: true})
                return;                
            }
            let polarData = []
            let noDataLength =  (this.dataList[0].distanceData[0])/this.dataList[0].distanceData[1] //无效距离
            let scanLength = this.dataList[0].distanceData[2]*this.dataList[0].distanceData[1]
            let pitch=(this.dataList[0].scanType == '0' || this.dataList[0].scanType == 'PPI' ) ? this.dataList[0].angleOfPitch:this.dataList[0].straightAngle;
            let scanType = this.dataList[0].scanType 
            $help.drawScan(this.dataList,(obj)=>{                
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                    
                    let r = that.dataList[0].distanceData[0] + that.dataList[0].distanceData[1] *  obj.dIndex                                        
                    polarData.push({
                        color: `rgba(${color.join(',')})`,
                        r:r,
                        angle:obj.angle,
                        value:obj.value,
                        pitch:pitch,
                        scanType:scanType
                    })                       
                })  
            },()=>{
                let group = $help.groupBy('color',polarData)
                let data = [];
                for(var i in group){
                    let item = group[i]
                    data.push({
                        type: "scatterpolargl",
                        r: item.map(a=>a.r.toFixed(2)),
                        theta: item.map(a=>a.angle.toFixed(2)),
                        text: item.map(a=>
                        `值：${a.value.toFixed(6)}<br />距离：${a.r.toFixed(2)}(Km)<br />${a.scanType == "PPI" || a.scanType == 0 ? "水平角":"俯仰角"}：${a.angle.toFixed(2)}°<br />${a.scanType == "PPI" || a.scanType == 0 ? "俯仰角":"水平角"}：${a.pitch}°`
                        ),
                        mode: "markers",
                        name: "",
                        marker: {
                            color: i,
                            size: 6,                         
                            opacity: 0.7
                        },
                        hoverinfo:'text',
                        cliponaxis: false
                    })
                }  
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                         
            let layout = { 
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                 
                polar: {                    
                    radialaxis: {    
                        color:fontColor,                     
                        visible: true,
                        range: [0, d]
                    },
                    angularaxis: {
                        color:fontColor, 
                        tickfont: {
      
                        },
                        direction: "clockwise"
                    }
                },
                showlegend: false,
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:38,
                    r:12,  
                    l:12                        
                }
            }            
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout);  
                }else{
                    Plotly.react(target, data,layout);
                }                               
            }else{
                Plotly.newPlot(target, data, layout);               
            }  
            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:3.3,yaw:yaw})                        
        },
        timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }         
    }
}
</script>
<style scoped lang="scss">
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
</style>
<template>
    <div class="inputItem align-center d-flex justify-center">
        <div class="label">{{label}}</div>
        <v-input class="sinputBorder d-flex align-center justify-center pa-2 elevation-5" dense hide-details >
            <input v-bind:value="value" v-on:input="$emit('update:value', $event.target.value)" :placeholder="placeholder"/>
        </v-input>
    </div>
</template>

<script>
export default {
    name:"SearchInput",
    props:{
        label:{
            type:String,
            default:""
        },
        placeholder:{
            type:String,
            default:""
        }, 
        value:{
            type:String,
            default:""
        },           
    },   
    methods:{
    }  
}
</script>


<style scoped lang="scss">
.inputItem{
    margin: 10px;
}
.label{
    margin: 5px;
}
.sinputBorder{
    /* border:1px solid var(--v-white-darken4); */
    box-shadow:0px 0px 0px 0px var(--v-white-darken2), 1px 1px 2px 0px var(--v-white-darken2), 0px 1px 6px 0px var(--v-white-darken2) !important;
    border-radius: 5px;    
}
:deep(.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections + .v-input__append-inner){
    width: 0 !important;

}

</style>
<template>
    <v-menu
        ref="menu"
        v-model="openMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="dateR"
        transition="scale-transition"
        offset-y
        :disabled="disabled"
        width="300px"
        max-width="300px"
        z-index="999"
      >
        <template v-slot:activator="{ attrs ,on }">
           <div class="inputItem align-center d-flex justify-center">
                <div class="label" v-if="label">{{label}}</div>                
                <v-input :disabled="disabled" class="sinputBorder d-flex align-center justify-center pa-2 elevation-5" dense hide-details >
                    <v-icon>mdi-clock-outline</v-icon>
                    <input :disabled="disabled" style="width:100%;" v-bind:value="dateText"
                        v-bind="attrs"
                        v-on="on"                                                
                       :placeholder="placeholder"/>
                </v-input>
            </div>
        </template>
        <v-card >
            <v-date-picker
                v-if="openMenu"
                :multiple="multiple"
                :range="range"
                v-model="date"                
                full-width               
            >
                <v-card elevation="0">
                    <v-row class="ma-0 pa-0">
                        <v-col class="mx-1 pa-0" cols="5">
                            <vue-timepicker 
                                hour-label="时" minute-label="分" second-label="秒"
                                manual-input
                                drop-direction='up'
                                auto-scroll 
                                hide-clear-button
                                class="timepicker"
                                v-model="starttime"
                                format="HH:mm:ss">
                            </vue-timepicker>
                            <!-- <v-menu
                                ref="startmenu"
                                top
                                v-model="startMenu"
                                :close-on-content-click="false"
                                :return-value.sync="starttime"
                                transition="scale-transition"
                                origin="center bottom"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="starttime"                                
                                    readonly
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                v-if="startMenu"
                                @input="setTime('0',$event)"
                                :value="starttime"
                                scrollable
                                use-seconds
                                format="24hr"
                                full-width                    
                                v-click-outside="()=>outset(0)"
                                @click:second="$refs.startmenu.save(starttime);"
                                >
                                </v-time-picker>
                            </v-menu> -->
                        </v-col>
                        <v-col class="mx-1 pa-0" v-if="range" cols="5">
                            <vue-timepicker
                            hour-label="时" 
                            minute-label="分" 
                            second-label="秒"
                            manual-input 
                            drop-direction='up' 
                            auto-scroll 
                            hide-clear-button class="timepicker" 
                            v-model="endtime" 
                            format="HH:mm:ss"></vue-timepicker>
                            <!-- <v-menu
                                ref="endmenu"
                                top
                                v-model="endMenu"
                                :close-on-content-click="false"
                                :return-value.sync="endtime"                           
                                origin="center bottom"
                                offset-y
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="endtime"                                
                                    readonly
                                    v-bind="attrs"
                                    dense
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                v-if="endMenu"
                                scrollable    
                                format="24hr"                        
                                use-seconds
                                :value="endtime"  
                                @input="setTime('1',$event)"                   
                                v-click-outside="()=>outset(1)"                                            
                                @click:second="$refs.endmenu.save(endtime);"
                                ></v-time-picker>
                            </v-menu> -->
                        </v-col>                        
                    </v-row>
                    <v-card-actions class="ma-0 pa-0 justify-end">
                        <v-btn @click="sub" class="ma-0 pa-0 primary--text" text>确定</v-btn>    
                        <v-btn @click="openMenu = false" class="ma-0 pa-0 secondary--text" text>取消</v-btn>                                           
                    </v-card-actions>
                </v-card>  
            </v-date-picker>                                                             
        </v-card>        
      </v-menu>
</template>
<script>
export default {
    name:"SearchDateTimePickerPlus",
    props:{
        placeholder:{
            type:String,
            default:""
        },
        label:{
            type:String,
            default:""
        },
        useSeconds:{
            type:Boolean,
            default:false
        },
        multiple:{
            type:Boolean,
            default:false
        },
        range:{
            type:Boolean,
            default:false
        },
        datetime:[Date,Array],
        maxRangeDay:{
            type:Number,
            default:0
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data:()=>({
        openMenu:false,
        date:"",
        dateR:"",
        dateText:"",
        startMenu:false,
        endMenu:false,
        starttime:"00:00",
        endtime:"23:59",
        _startTime:"00:00",
        _endTime:"23:59"
    }),
    watch:{
        openMenu(){
            this.startMenu=false;
            this.endMenu=false
            if(this.range){
                if(this.datetime[0] && this.datetime[1]){           
                    this.dateR = this.datetime[0] + "-" + this.datetime[1]
                    this.date = [this.datetime[0].pattern("yyyy-MM-dd"),this.datetime[1].pattern("yyyy-MM-dd")]
                    this.starttime =this.datetime[0].pattern("HH:mm:ss")
                    this.endtime = this.datetime[1].pattern("HH:mm:ss")
                }else{
                    this.date = []
                    this.dateR = "";
                }
            }else{
                this.dateR = this.datetime;            
            }
        },
        dateText(n){
            // var range = n.split(" - ");            
            // if(new Date(range[0]) > new Date(range[1])){
            //     let diff = new Date(range[0]).getTime() - new Date(range[1]).getTime();
            //     if(this.maxRangeDay > 0 && diff/(1000 * 60 * 60 * 24 ) > this.maxRangeDay ){
            //         let text = `选择天数过长，请选择${this.maxRangeDay}天内的时间`;
            //         if(this.maxRangeDay <1){
            //             text = `选择时间过长，请选择${this.maxRangeDay * 24}小时内的时间`;
            //         }
            //         openNotice({
            //             text:text,
            //             type:"error"
            //         })
            //         return;
            //     }
            //     //this.$emit('update:datetime',[new Date(range[1]),new Date(range[0])])
            // }else{
            //     let diff = new Date(range[1]).getTime() - new Date(range[0]).getTime();
            //     if(this.maxRangeDay > 0 && diff/(1000 * 60 * 60 * 24 ) > this.maxRangeDay ){
            //         let text = `选择天数过长，请选择${this.maxRangeDay}天内的时间`;
            //         if(this.maxRangeDay <1){
            //             text = `选择时间过长，请选择${this.maxRangeDay * 24}小时内的时间`;
            //         }
            //         openNotice({
            //             text:text,
            //             type:"error"
            //         })
            //         return;
            //     }
            //     this.$emit('update:datetime',[new Date(range[0]),new Date(range[1])])
            // }
            
        },
        endMenu(n){
            if(!n){
                //this.$refs.endmenu.save(this.endtime);
                //this._endTime=this.endtime
            }            
        },
        startMenu(n){          
            if(!n){
                //this.$refs.startMenu.save(this.starttime);
                //this._startTime=this.starttime
            }            
        },
        starttime(n,v){    
            if(this.range){ 
                this.date[0] = this.date[0] ? this.date[0] : new Date().pattern("yyyy-MM-dd")
                this.date[1] = this.date[1] ? this.date[1] : new Date().pattern("yyyy-MM-dd")                 
                this.dateText = `${this.date[0]} ${this.starttime} - ${this.date[1]} ${this.endtime}`
                this.dateR = [ `${this.date[0]} ${this.starttime}`,`${this.date[1]} ${this.endtime}`]
            }else{
                this.dateR = `${this.date} ${this.starttime}`
                this.dateText = `${this.date} ${this.starttime}`
            }          
        },
        endtime(n,v){      
            this.date[0] = this.date[0] ? this.date[0] : new Date().pattern("yyyy-MM-dd")
            this.date[1] = this.date[1] ? this.date[1] : new Date().pattern("yyyy-MM-dd")                           
            this.dateText = `${this.date[0]} ${this.starttime} - ${this.date[1]} ${this.endtime}`
            this.dateR = [`${this.date[0]} ${this.starttime}`,`${this.date[1]} ${this.endtime}`]     
        },
        date(n,v){
            if(v){
                if(this.range && this.date[0] && this.date[1]){                                
                    this.dateText = `${this.date[0]} ${this.starttime} - ${this.date[1]} ${this.endtime}`
                    this.dateR = [`${this.date[0]} ${this.starttime}`,`${this.date[1]} ${this.endtime}`]
                }else if(!this.range){
                    this.dateR = `${this.date} ${this.starttime}`
                    this.dateText = `${this.date} ${this.starttime}`
                }

            }
        },
        datetime(n){          
            if(this.range){
                if(n[0] && n[1]){           
                    this.dateR = n[0] + "-" + n[1]
                    this.date = [n[0].pattern("yyyy-MM-dd"),n[1].pattern("yyyy-MM-dd")]
                    this.starttime =n[0].pattern("HH:mm:ss")
                    this.endtime = n[1].pattern("HH:mm:ss")
                }else{
                    this.date = []
                    this.dateR = "";
                }
            }else{
                this.dateR = this.datetime;            
            }
        }
    },
    mounted(){  
        if(this.range){
            if(this.datetime[0] && this.datetime[1]){                
                this.dateR = this.datetime[0] + "-" + this.datetime[1]
                this.date = [this.datetime[0].pattern("yyyy-MM-dd"),this.datetime[1].pattern("yyyy-MM-dd")]
                this.starttime =this.datetime[0].pattern("HH:mm:ss")
                this.endtime = this.datetime[1].pattern("HH:mm:ss")
            }else{
                this.date = []
                this.dateR = "";
            }
        }else{
            this.dateR = this.datetime;            
        }
        
        
    },
    computed:{
    },
    methods:{    
        ok(){      
            if(this.dateR == ""){
                openNotice({
                    type:"error",
                    text:"请选择日期"
                })
                return
            }
            this.$refs.menu.save(this.dateR)
        },    
        setTime(type,value){
            if(type == '0'){
                this.starttime = value
                this._startTime = value
            }else{
                this.endtime = value;
                this._endtime = value
            }           
        },
        outset(val){          
            let that = this; 
            setTimeout(() => {
                if(val == 0){
                    that.starttime = that._startTime
                    that.$refs.startmenu?.save(that._startTime)
                }else{
                    that.endtime = that._endtime
                    that.$refs.endmenu?.save(that._endtime)
                }
            }, 20);
        },
        sub(){
            var range = this.dateText.split(" - ");            
            if(new Date(range[0]) > new Date(range[1])){
                let diff = new Date(range[0]).getTime() - new Date(range[1]).getTime();
                if(this.maxRangeDay > 0 && diff/(1000 * 60 * 60 * 24 ) > this.maxRangeDay ){
                    let text = `选择天数过长，请选择${this.maxRangeDay}天内的时间`;
                    if(this.maxRangeDay <1){
                        text = `选择时间过长，请选择${this.maxRangeDay * 24}小时内的时间`;
                    }
                    openNotice({
                        text:text,
                        type:"error"
                    })
                    return;
                }
                this.$emit('update:datetime',[new Date(range[1]),new Date(range[0])])
            }else{
                let diff = new Date(range[1]).getTime() - new Date(range[0]).getTime();
                if(this.maxRangeDay > 0 && diff/(1000 * 60 * 60 * 24 ) > this.maxRangeDay ){
                    let text = `选择天数过长，请选择${this.maxRangeDay}天内的时间`;
                    if(this.maxRangeDay <1){
                        text = `选择时间过长，请选择${this.maxRangeDay * 24}小时内的时间`;
                    }
                    openNotice({
                        text:text,
                        type:"error"
                    })
                    return;
                }
                this.$emit('update:datetime',[new Date(range[0]),new Date(range[1])])
            }
            this.openMenu = false;
        }
    }
}
</script>
<style scoped lang="scss">
.inputItem{
  min-width: 348px;
}
.label{
    margin: 5px;
}
.sinputBorder{
    /* border:1px solid var(--v-white-darken4); */
    box-shadow:1px 0px 2px -1px  var(--v-white-darken4), 1px 1px 4px 0px  var(--v-white-darken4), 0px 1px 6px 0px  var(--v-white-darken4) !important;
    border-radius: 5px;    
}
:deep(.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections + .v-input__append-inner){
    width: 0 !important;

}
:deep(.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span,.v-time-picker-title__time .v-picker__title__btn),:deep(.v-time-picker-title__time span){
    height: 56px !important;
    font-size: 56px !important;
}
.timepicker{
    width: 100% !important;    
    :deep(input){
        color:var(--v-rTheme-base);
        width: 100% !important;
    }
    :deep(.dropdown){        
        position: absolute;
        z-index: 20;
        bottom: 36px;        
    }
}
</style>
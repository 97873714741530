<template>
    <v-container class="contain ma-0 pa-0">    
        <div class="hotMapColorBar">
            公里(km)
            <div :style="`background-color:${i.color}`" v-for="i in levelConfig" class="hotMapColorBarBox" :key="i.key">
                <div class="hotMapColorBarText" >{{i.min}}-{{i.max}} [{{i.text}}]</div>
            </div>
        </div>    
        <v-row class="ma-0  px-2 pt-3 height50 width100">
            <Trendmap ref="trendmap"></Trendmap>
        </v-row>
        <v-row  class="ma-0 py-1 px-2 height50">
            <v-col class="pr-1 pl-0" cols="6">
                <Scanmap ref="scanmap" :dataList="lidarDataPPI" v-if="currentScanType == 'PPI'"></Scanmap>
                <RHI ref="rhi" :dataList="lidarDataRHI"  v-if="currentScanType == 'RHI'"></RHI>
                <Heatmap ref="heatmap" :dataList="lidarDataFIX" v-if="currentScanType == 'FIX'"></Heatmap>
            </v-col>
            <v-col class="pl-1 pr-0 height100" cols="6"><AlarmTable @clickRow="clickRow"></AlarmTable></v-col>
        </v-row>        
    </v-container>
</template>
<script>
import Scanmap from '@/components/monitoring/vls/realtime/Scanmap'
import RHI from '@/components/monitoring/vls/realtime/RHI'
import Heatmap from '@/components/monitoring/vls/realtime/Heatmap'
import Trendmap from '@/components/monitoring/vls/realtime/Trendmap'
import AlarmTable from '@/components/monitoring/vls/realtime/AlarmTable'
import transferDataType from '@/plugins/datas/transferDataType'
import {getVlsVizLatestApi,getVlsRcsByLatestApi,getVlsVizApi } from '@/api' 
import scanType from '@/plugins/datas/scanType'
export default {
    components:{Scanmap,RHI,Heatmap,Trendmap,AlarmTable},
    name:"monitoring.vls.realtime",
    data(){
        return {
            currentScanType:"PPI",
            lidarDataPPI:[],
            lidarDataFIX:[],
            lidarDataRHI:[],
            levelConfig:[                
                {
                    key:1,
                    min:1,
                    max:10,
                    text:"轻雾",
                    color:'rgb(202, 251, 235)'
                },
                {
                    key:2,
                    min:0.5,
                    max:1,
                    text:"大雾",
                    color:'rgb(104, 253, 250)'
                },
                {
                    key:3,
                    min:0.2,
                    max:0.5,
                    text:"浓雾",
                    color:'rgb(4, 204, 203)'
                },
                {
                    key:4,
                    min:0.05,
                    max:0.2,
                    text:"强浓雾",
                    color:'rgb(5, 152, 151)'
                },
                {
                    key:5,
                    min:0,
                    max:0.05,
                    text:"特强浓雾",
                    color:'rgb(6, 82, 72)'
                }
            ],
            timer:null,
            currentWatch:null
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){      
                this.lidarDataPPI = []                          
                this.lidarDataFIX = []                          
                this.lidarDataRHI = []                          
                var that = this;  
                //垂直扫描        
                if(!n.scanType){ 
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                               that.currentScanType = obj.scanType
                               that.load()
                            }
                        }
                    })
                }else{                    
                    that.currentScanType = n.scanType
                    that.load()
                }                               
                this.watchData(n.siteId)
            }
        },        
        "$store.state.storage_global.currentSite.scanType":{
            handler(n,v){        
                this.currentScanType = n
            }
        }
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;     
        this.currentScanType = this.$store.state.storage_global.currentSite.scanType   
        if(!this.currentScanType){
            this.$store.dispatch('storage_user/getLastestScanType',{
                site:this.$store.state.storage_global.currentSite,
                cb:(obj)=>{
                    if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                        that.currentScanType = obj.scanType
                        that.load()
                    }
                }
            })
        }else{
            that.load()
        }                          
        setTimeout(() => {            
            that.watchData(that.$store.state.storage_global.currentSite.siteId)
        }, 200);
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{            
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId){                  
                    var json =JSON.parse(JSON.stringify(n.jsonData))                                                                
                    if(n.DataType ==transferDataType['viz'].value && that.currentScanType == "PPI"){                          
                        that.lidarDataPPI = json.sort((a,b)=>{
                            return new Date(a.dataTime) - new Date(b.dataTime)
                        });                                            
                    }else if(n.DataType ==transferDataType['viz'].value && that.currentScanType == "FIX"){ 

                        var json = JSON.parse(JSON.stringify(n.jsonData));  
                         
                        if(that.lidarDataFIX.length == 0){
                            that.load(json)
                        }else{
                            let lidarDataFIX = that.lidarDataFIX.filter(a=>a.scanType == 1 || a.scanType == "FIX")
                            let len = lidarDataFIX.length-json.length                    
                            lidarDataFIX = json.sort((a,b)=>{
                                return new Date(b.dataTime.replace("T"," ")).getTime() - new Date(a.dataTime.replace("T"," ")).getTime()
                            }).concat(lidarDataFIX)    
                            lidarDataFIX = lidarDataFIX.slice(0,len)  
                            that.lidarDataFIX = lidarDataFIX;
                        }                        
                    }else if(n.DataType ==transferDataType['rcs'].value && that.currentScanType == "RHI"){
                        that.lidarDataRHI = json.sort((a,b)=>{
                            return new Date(a.dataTime) - new Date(b.dataTime)
                        });     
                    }

                }
            }, {immediate: true })
        },        
        async load(datas){
            let that = this;
            if(this.timer)         {
                clearTimeout(this.timer)
            }
            this.timer =setTimeout(() => {
                console.log(that.currentScanType )
                if(that.currentScanType == "PPI" || that.currentScanType == "ppi" ){    
                    that.loadDataPPI()
                }else if(that.currentScanType == "FIX" || that.currentScanType == "fix"){
                    that.loadDataFIX(datas)
                }else{
                    that.loadDataRcs()
                }
            },300)
        },
        loadDataFIX(datas){            
            let siteId = this.$store.state.storage_global.currentSite.siteId;            
            let start = new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 1)).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId,datas)                 
        },    
        loadData(start,end,siteId,datas = [],time){  
            var that = this;  
            this.$refs.heatmap.overlay = true;      
            getVlsVizApi({
                id:siteId,                  
                params:{
                    LastDate:time,
                    PageSize:1000,                        
                    StartTime:start,
                    EndTime:end,
                    ScanMode:1,
                    LastDate:time
                },success:(d)=>{ 
                    if(!d || d.length == 0){
                        this.$refs.heatmap.overlay = false;
                    }                          
                    let _datas = d.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: a.DvvTime.replace("T"," "),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }})   
                    datas = _datas.concat(datas)     
                    if(new Date(d[d.length -1].DvvTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.length < 1000){                                   
                        this.$refs.heatmap.overlay = false;
                        that.lidarDataFIX = datas; 
                    }else{                              
                        that.lidarDataFIX = datas;              
                        that.loadData(start,end,siteId,datas,d[d.length -1].DvvTime)                                  
                    }    
                }
            })                       
        },      
        loadDataPPI(){         
            var that = this; 
            getVlsVizLatestApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{                    
                    },
                success:(d)=>{       
                    that.lidarDataPPI = d?.filter(a=>a.DvvScanMode ==0)?.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: new Date(a.DvvTime.replace("T"," ")).pattern("yyyy-MM-ddTHH:mm:ss"),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }
                    })                                 
                }
            })           
        },
        loadDataRcs(){     
            var that = this; 
            this.$refs.rhi.overlay = true;  
            getVlsRcsByLatestApi({
                id:this.$store.state.storage_global.currentSite.siteId,               
                success:(d)=>{   
                    this.$refs.rhi.overlay = false;           
                    that.lidarDataRHI = d?.map(a=>{
                        return {
                            angleOfPitch: a.DvrPitch,
                            data:a.DvrValue,                                                    
                            dataTime: new Date(a.DvrTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.rcs,
                            distanceData: a.DvrDis,
                            period:a.DvrPeriod,
                            siteId: a.DvrSiteId,
                            scanType:scanType[a.DvrScanMode],
                            straightAngle:a.DvrStraight
                        }
                    })     
                }
            })          
        },
        clickRow(time,color){
            this.$refs.trendmap.clickTime(time,color)
        }
    }
    
}
</script>
<style scoped>
.contain{
    max-width: 100%;
}
.height50{
    height: 50%;
}
.hotMapColorBar{
    z-index: 2;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 80px;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
</style>
 <template>
 <v-container
        class="py-2 d-flex flex-column"
        fluid      
      >
      <v-row class="d-flex align-center flex-grow-0" dense>
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('noticeSendManage.label.name')"
                    :placeholder="$i18n.t('noticeSendManage.placeholder.name')"
                    v-bind:value.sync="name"
                />      
                 <SelectInput             
                    :label="$i18n.t('noticeSendManage.label.site')"
                    :options="sites"  
                    :placeholder="$i18n.t('noticeSendManage.placeholder.site')" 
                    :width='200'
                    v-bind:value.sync="siteId"
                    clearable
                    />         
                <SelectInput clearable            
                    :label="$i18n.t('noticeSendManage.label.isOpen')"
                    :options="openStatus"  
                    :placeholder="$i18n.t('noticeSendManage.placeholder.isOpen')" 
                    :width='200'
                    v-bind:value.sync="isOpen"
                    />                         
                <v-btn class="mx-2" @click="page=1;load()">{{$i18n.t('noticeSendManage.btn.search')}}</v-btn>
                <v-btn                
                color="primary"                        
                class="mx-2"
                @click="newForm"
                >{{$i18n.t('noticeSendManage.btn.new')}}</v-btn>
            </v-row>
        </v-col>                   
        <!-- <v-col  class="ma-0 d-flex align-end height100" cols="0" >            
            
        </v-col>               -->
      </v-row>       
      <v-row class="tableContainer ma-0">
          <v-data-table   
            :items="datas"         
            fixed-header
            show-group-by
            :headers="headers"
            group-by="NerName"
            :page.sync="page"
            :items-per-page="total"
            :loading="loadingData"
            hide-default-footer
          >
          <template v-slot:top>   
            <v-toolbar :elevation="0" height="0">     
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">                       
                    </template>
                <v-card height="100%">
                    <v-toolbar color="primary">
                        <v-btn icon dark @click="dialog= false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title style="color:white">{{$i18n.t('noticeSendManage.title')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="pa-0" style="height: calc(100% - 116px)">                      
                        <v-row justify="center" class="ma-0 height100">
                            <v-card width="100%" height="100%" class="pa-2 dialogContent" >
                                <v-form                               
                                class="ma-0 pt-3"
                                ref="form"
                                style="height: 100%;"
                                v-model="form.valid"                            
                                >
                                    <v-row class="ma-0" style="height:calc(100% - 258px)">
                                        <!-- <v-select
                                            v-model="form.body.NraSiteId"
                                            :items="selectSiteItemList"                                
                                            :label="$i18n.t('noticeSendManage.label.form.site')"
                                            :rules="rules.sites"                                            
                                            multiple
                                            required
                                        >
                                         <template v-slot:prepend-item>
                                            <v-text-field                                            
                                                v-model="search"
                                                label="搜索"
                                                flat
                                                full-width                                                
                                                hide-details                                                                                                
                                                dense
                                                clearable
                                                class="px-4"
                                                clear-icon="mdi-close-circle-outline"
                                            ></v-text-field>                                       
                                            <v-list-item
                                            ripple
                                            @click="selectAllSite"
                                            >
                                            <v-list-item-action>
                                                <v-icon :color="form.body.NraSiteId.length > 0 ? 'indigo darken-4' : ''">{{ form.body.NraSiteId.length == 0 ? 'mdi-checkbox-blank-outline': (sites.length > form.body.NraSiteId.length ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>选择全部</v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                            ripple
                                            @click="selectAllSite(true)"
                                            >
                                            <v-list-item-action>
                                                <v-icon :color="form.body.NraSiteId.length > 0 ? 'indigo darken-4' : ''">{{ form.body.NraSiteId.length == 0 ? 'mdi-checkbox-blank-outline': (isTransfer.length > form.body.NraSiteId.length ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>选择全部运行中</v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                        </template></v-select>  -->
                                        <v-card width="100%" height="100%" >
                                            <v-subheader>
                                                <v-text-field                                            
                                                    v-model="search"
                                                    label="搜索"
                                                    flat
                                                    full-width                                                
                                                    hide-details                                                                                                
                                                    dense
                                                    clearable
                                                    class="px-4"
                                                    clear-icon="mdi-close-circle-outline"
                                                ></v-text-field>
                                                <v-checkbox label="选择全部" @click="selectAllSite()">
                                                </v-checkbox>                                       
                                                <v-checkbox label="选择运行中" @click="selectAllSite(true)">
                                                </v-checkbox>      
                                                <v-checkbox label="全过滤" v-model="allFilter" @click="checkedFilterRule('all',allFilter)">
                                                </v-checkbox>                                                                                          
                                            </v-subheader>
                                            <v-card-text ref="scrollCard" style="height: calc(100% - 48px);overflow: auto;">
                                                <v-list>
                                                    <v-list-item v-for="site,k in selectSiteItemList" :key="k">
                                                        <v-list-item-action>
                                                            <v-icon ref="itemIndex" @click="pushSiteId(site.value)" :color="form.body.NraSiteId.indexOf(site.value) >=0 ? 'indigo darken-4 ' : ''">{{ form.body.NraSiteId.indexOf(site.value) <0? 'mdi-checkbox-blank-outline': 'mdi-close-box' }}</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{site.text}}</v-list-item-title>
                                                            <v-list-item-subtitle class="d-flex align-center">
                                                                <span v-show="site.alarmRule.length > 0">过滤条件：</span>
                                                                <v-checkbox v-for="i,_k in site.alarmRule" v-model="i.checked" :key="_k" dense hide-details hide-spin-buttons :label="i.name"></v-checkbox>                                                            
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>                                               
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-row>  
                                    <v-row class="ma-0">
                                        <v-row class="ma-0">
                                            <v-col>
                                                <v-text-field
                                                    v-model="form.body.NraReceiverInterval" 
                                                    :rules="rules.interval"                                                                               
                                                    :label="$i18n.t('noticeSendManage.label.form.interval')"
                                                    required
                                                ></v-text-field>  
                                            </v-col>
                                            <v-col>
                                               <v-switch 
                                                    v-model="form.body.NraReceiverAlarm" class="mx-2" 
                                                    :label="$i18n.t('noticeSendManage.label.form.isOpen')">
                                                </v-switch>
                                            </v-col>
                                            <v-col>
                                               <v-switch 
                                                    v-show="false"
                                                    v-model="form.body.NraReceiverFilter" class="mx-2" 
                                                   @change="form.body.NraReceiverAlarm = form.body.NraReceiverFilter?form.body.NraReceiverFilter:form.body.NraReceiverAlarm"
                                                    :label="$i18n.t('noticeSendManage.label.form.isFilter')">
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <v-select
                                            v-model="form.body.NraNerId"
                                            :items="recivers"     
                                            :rules="rules.users"                           
                                            :label="$i18n.t('noticeSendManage.label.form.reciver')"
                                            multiple
                                            required
                                        >
                                        <template v-slot:prepend-item>
                                                <v-list-item
                                                ripple
                                                @click="selectAllReciver"
                                                >                                               
                                                    <v-list-item-action>
                                                        <v-icon :color="form.body.NraNerId.length > 0 ? 'indigo darken-4' : ''">{{ form.body.NraNerId.length == 0 ? 'mdi-checkbox-blank-outline': (recivers.length > form.body.NraNerId.length ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>选择全部</v-list-item-title>
                                                    </v-list-item-content>                                                  
                                                </v-list-item>                                                
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select> 
                                    </v-row>                                                    
                                    <v-row class="ma-0">
                                        <v-row class="ma-0">
                                            <v-col>
                                                <v-menu
                                                    ref="startmenu"
                                                    v-model="starttime"                                                    
                                                    :nudge-right="40"          
                                                    :close-on-content-click="false"                                         
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.body.NraStartTime"
                                                        :label="$i18n.t('noticeSendManage.label.form.startTime')"                                                        
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    format="24hr"                                                    
                                                    v-model="form.body.NraStartTime"
                                                    v-if="starttime"                                                    
                                                    full-width                                                    
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="starttime = false">{{$i18n.t('noticeSendManage.btn.cancel')}}</v-btn>
                                                    <v-btn text color="primary" @click="$refs.startmenu.save(form.body.NraStartTime)">{{$i18n.t('noticeSendManage.btn.ok')}}</v-btn>
                                                    </v-time-picker>
                                                </v-menu>                                                
                                            </v-col>
                                            <v-col>
                                                <v-menu
                                                    ref="endmenu"
                                                    v-model="endtime"                                                    
                                                    :nudge-right="40"                                                       
                                                    :close-on-content-click="false"                                                
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="form.body.NraEndTime"
                                                            :label="$i18n.t('noticeSendManage.label.form.endTime')"                                                        
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                        format="24hr"
                                                        v-if="endtime"                                                    
                                                        v-model="form.body.NraEndTime"
                                                        full-width                                                    
                                                        >
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="endtime = false">{{$i18n.t('noticeSendManage.btn.cancel')}}</v-btn>
                                                        <v-btn text color="primary" @click="$refs.endmenu.save(form.body.NraEndTime);">{{$i18n.t('noticeSendManage.btn.ok')}}</v-btn>
                                                    </v-time-picker>
                                                </v-menu>                                                      
                                            </v-col>
                                        </v-row>
                                    </v-row>                               
                                </v-form>
                            </v-card>                           
                        </v-row>
                    </v-card-text>  
                    <v-card-actions class="bottomAction d-flex justify-end">                         
                        <v-btn class="mr-6" color="primary" @click="save">{{$i18n.t('noticeSendManage.btn.save')}}</v-btn>
                        <v-btn class="mr-6" @click="dialog=false">{{$i18n.t('noticeSendManage.btn.cancel')}}</v-btn>
                    </v-card-actions>                 
                </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.NraReceiverAlarm="{item,value}">
            <span :class="[value ?'success--text':'warning--text']">{{value ? "开启":"关闭"}}</span>
        </template>
        
        <template v-slot:item.NraReceiverFilter="{item,value}">
            <span :class="[value ?'success--text':'warning--text']">{{value ? "开启":"关闭"}}</span>
        </template>
        <!-- 分组 -->
        <template v-slot:group.header="{ group, headers,items,isOpen, toggle,remove }">
            <td :colspan="headers.length"> 
                <div class="d-flex justify-space-between" >
                <div class="v_table_group_header_self ">
                    <div class="v_group_span v-row-group__header">{{group}}{{items[0]?.UserIsOpen?"":$i18n.t('noticeSendManage.table.header.userClose') }}</div>                    
                    
                </div>
                <div class="v_table_group_header_btn">
                    <v-btn icon small @click="toggle">
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                    </v-btn>                    
                </div>                               
                </div>
                
            </td>    
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"                
                @click="edit(item)"
            >
                mdi-pencil
            </v-icon>            
            <v-icon
                small                
                @click="del(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>          
      </v-row>      
      </v-container>
</template>
<script>
import {getReciverConfigApi,addReciverConfigApi,editReciverConfigApi,deleteReciverConfigApi,getReciverApi } from '@/api'
export default {
    name:"noticeSendManage.index",
    data(){
        return{
            allFilter:true,
            datas:[],
            page:1,
            rows:10,
            total:0,
            search:'',
            loadingData:false,            
            openStatus:[{
                text:"全部",
                value:null
            },{
                text:"已开启",
                value:true
            },{
                text:"未开启",
                value:false
            }], 
            starttime:false, 
            endtime:false,
            name:"",
            siteId:"",
            isOpen:"",                               
            headers:[
                {
                    text:this.$i18n.t('noticeSendManage.table.header.name'),
                    value: 'NerName',
                    groupable:true,  
                    width:'24%'      
                },
                {
                    text:this.$i18n.t('noticeSendManage.table.header.isOpen'),
                    value: 'NraReceiverAlarm',
                    groupable:false,  
                },
                {
                    text:this.$i18n.t('noticeSendManage.table.header.site'),
                    value: 'SiteName',
                    groupable:false,
                },  
                {
                    text:this.$i18n.t('noticeSendManage.table.header.interval'),
                    value: 'NraReceiverInterval',
                     groupable:false,  
                },  
                // {
                //     text:this.$i18n.t('noticeSendManage.table.header.filter'),
                //     value: 'NraReceiverFilter',
                //      groupable:false,  
                // },  
                {
                    text:this.$i18n.t('noticeSendManage.table.header.startTime'),
                    value: 'StartTime',
                     groupable:false,  
                },    
                {
                    text:this.$i18n.t('noticeSendManage.table.header.endTime'),
                    value: 'EndTime',
                     groupable:false,  
                },                   
                {
                    text:this.$i18n.t('noticeSendManage.table.header.lastSendDate'),
                    value: 'LastSendDate',
                    groupable:false,
                },  
                {
                    text:this.$i18n.t('noticeSendManage.table.header.operation'),  
                    value: 'actions', sortable: false   ,   groupable:false,           
                },
            ],
            recivers:[],
            dialog:false,
            rules:{
                users: [
                    v => v.length > 0 || this.$i18n.t("noticeSendManage.label.rule.users"),                    
                    // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],  
                sites: [
                    v => v.length > 0 || this.$i18n.t("noticeSendManage.label.rule.sites"),                    
                    // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],            
                interval:[
                    v =>(!!v && (v >=6 && v <= 1440))|| this.$i18n.t("noticeSendManage.label.rule.interval")
                ]                
            },
            form:{
                valid:false,
                title:"",
                body:{
                    NraNerId:[],
                    NraReceiverAlarm:false,
                    NraReceiverFilter:false,
                    NraSiteId:[],
                    NraReceiverInterval:60,                    
                    NraStartTime:"09:00",
                    NraEndTime:"17:00",
                    NraAlarmTime:"",
                    NraFilterRule:[]                
                }
            }        
        }
    },
    watch:{   
        dialog(n){
            if(n){
                this.search = ''
            }
        } 
    },
    computed:{ 
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },
        sites(){
            let siteList = this.$store.state.storage_user.sites.map(a=>{
                return {
                    text:a.siteName,
                    alarmRule:$help.copyAsOther(a.alarmFilterRule),
                    value:a.siteId,
                    transStatus:a.transStatus
                }
            })
            return siteList;
        }, 
        selectSiteItemList(){            
            let siteList = this.$store.state.storage_user.sites.map(a=>{
                let alarmRule = $help.copyAsOther(a.alarmFilterRule)
                if(this.form.body.NraFilterRule && this.form.body.NraFilterRule?.length > 0){                    
                    let _c = this.form.body.NraFilterRule.find(c=>c.siteId == a.siteId)
                    alarmRule.forEach(b=>{
                        if(_c && _c.ruleList && _c.ruleList.length > 0){
                            b.checked = _c.ruleList.findIndex(e=>e==b.id) >=0
                        }else{
                            b.checked = false
                        }                        
                    })                  
                }
                return {
                    text:a.siteName,
                    value:a.siteId,
                    alarmRule:alarmRule,
                    transStatus:a.transStatus
                }
            }).filter(a=>a?.text?.toString().indexOf(this.search) >=0)
            return siteList
        },
        isTransfer(){
            return this.$store.state.storage_user.sites.filter(a=>a.transStatus == 0).map(a=>{
                return {
                    text:a.siteName,
                    value:a.siteId,
                    transStatus:a.transStatus
                }
            })
        }
    },
    mounted(){          
        this.loadReciver();    
        this.load() 
    },
    methods:{
        load(){         
            var that = this;             
            this.loadingData = true;
            getReciverConfigApi({
             params:{
                // PageIndex:this.page,
                // PageSize:0,
                ReciverName:this.name,
                SiteId:this.siteId,
                IsReceiverAlarm:this.isOpen,
                CompanyId:this.$store.state.storage_user.user.companyKey,
             },
             success:(d)=>{      
                that.loadingData = false;          
                that.datas = d.map(a=>{
                    a.SiteName = that.sites.find(b=>{return b.value == a.NraSiteId})?.text
                    a.NerName = a.NoticeExternalReceiver.NerName
                    a.IsOpen = a.NraReceiverAlarm ? '开启':"关闭"
                    a.IsFilter = a.NraReceiverFilter ? '开启':'关闭'                    
                    a.StartTime = new Date(a.NraStartTime.replace("T"," ")).pattern("HH:mm")                    
                    a.EndTime = new Date(a.NraEndTime.replace("T"," ")).pattern("HH:mm")
                    a.LastSendDate=a.NraAlarmTime?.replace("T"," ")
                    a.UserIsOpen=a?.NoticeExternalReceiver?.NerEnable==null ? false:a?.NoticeExternalReceiver?.NerEnable                    
                   return a;
                })
                that.total =  that.datas.length;
             }
           })
        },
        loadReciver(){
            var that = this;
            getReciverApi({
                id:this.$store.state.storage_user.user.companyKey,
                params:{
                    Name:""
                },
                success:(d)=>{
                    that.recivers = d.map(a=>{
                        return {
                            text:a.NerName,
                            value:a.NerId
                        }
                    })
                }
            })
        },
        pushSiteId(siteId){
            let _index = this.form.body.NraSiteId.indexOf(siteId)
            if(_index >= 0){
                this.form.body.NraSiteId = this.form.body.NraSiteId.filter((a,i)=>i!=_index)
            }else{
                this.form.body.NraSiteId.push(siteId)
            }            
        },
        edit(item){
            this.form.body = {
                NraNerId:[item.NraNerId],
                NraReceiverAlarm:item.NraReceiverAlarm,
                NraReceiverFilter:item.NraReceiverFilter,
                NraSiteId:[item.NraSiteId],
                NraReceiverInterval:item.NraReceiverInterval,                    
                NraStartTime:item.StartTime,
                NraEndTime:item.EndTime,
                NraFilterRule:[{
                    siteId:item.NraSiteId,
                    ruleList:item.NraFilterRule
                }]
                //BsiId:item.BsiId            
            }
            //this.attach = 
            if(this.$refs.form){
                this.$refs.form.resetValidation() 
            }
            this.dialog = true;
            let that = this;
            let _index = this.selectSiteItemList.findIndex(a=>a.value == item.NraSiteId)
            setTimeout(() => {        
                that.$vuetify.goTo(that.$refs.itemIndex[_index].$el,{container:that.$refs.scrollCard})
            }, 300);
        },
        del(item){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:()=>{
                    deleteReciverConfigApi({
                        id:item.NraId,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }                            
                        }
                    })
                },//确认回调
                noBack:null //取消回调
            })
        },
        newForm(){
            this.form.body={
                NraNerId:[],
                NraReceiverAlarm:false,
                NraReceiverFilter:false,
                NraSiteId:[],
                NraReceiverInterval:60,                    
                NraStartTime:"09:00",
                NraEndTime:"17:00"   
            };
            if(this.$refs.form){
                this.$refs.form.resetValidation() 
            }
            this.allFilter = true;
            this.dialog = true;
        },    
        selectAllSite(type){
            if(type){
                let isTransfer = this.sites.filter(a=>a.transStatus == 0);
                if(isTransfer.length == this.form.body.NraSiteId.length){
                    this.form.body.NraSiteId = []
                }else{
                    this.form.body.NraSiteId = isTransfer.map(a=>{
                        return a.value;
                    })
                }
            }else{
                if(this.sites.length == this.form.body.NraSiteId.length){
                    this.form.body.NraSiteId = []
                }else{
                    this.form.body.NraSiteId = this.sites.map(a=>{
                        return a.value;
                    })
                }
            }            
        },
        selectAllReciver(){
            if(this.recivers.length == this.form.body.NraNerId.length){
                this.form.body.NraNerId = []
            }else{
                this.form.body.NraNerId = this.recivers.map(a=>{
                    return a.value;
                })
            }
        },
        checkedFilterRule(id,_val,key){            
            if(id == 'all'){
                this.selectSiteItemList.forEach(site => {
                    site.alarmRule?.forEach(rule=>{
                        rule.checked = _val
                    })
                });
            }else{
                this.selectSiteItemList.forEach(site => {
                    if(site.value == id){
                        site.alarmRule?.forEach(rule=>{
                            if(key = rule.id){
                                rule.checked = _val
                            }                            
                        })
                    }                    
                });
            }
        },
        save(){
            var rel = this.$refs.form.validate();
            var that = this;   
            if(rel){           
                //修改        
                       
                if(this.form.body.NraId){  
                    //由于Body未有NraId 走的是新增，后端对新增进行的新增或更新操作 
                    //若要使用下面编辑代码，更新配置时保证上次发送报警时间不变
                    //下次版本小升级，修正此处                                        
                    editReciverConfigApi({
                        id: this.form.body.NraId,
                        data:{
                            ...this.form.body,
                            NraAlarmFilterRuleList:this.selectSiteItemList.filter(a=>this.form.body.NraSiteId.indexOf(a.value) >= 0).map(a=>{
                                return {
                                    siteId:a.value,
                                    ruleList:a.alarmRule.filter(b=>b.checked).map(b=>b.id)
                                }
                            })
                        },
                        success:(d)=>{
                            that.load()
                            that.dialog = false;                            
                        }
                    })                
                }
                //增加
                else{
                    addReciverConfigApi({
                        data:{
                            ...that.form.body,
                            NraAlarmFilterRuleList:this.selectSiteItemList.filter(a=>this.form.body.NraSiteId.indexOf(a.value) >= 0).map(a=>{
                                return {
                                    siteId:a.value,
                                    ruleList:a.alarmRule?.filter(b=>b.checked).map(b=>b.id)
                                }
                            })
                        },
                        success:(d)=>{
                            that.load()
                            that.dialog = false;                            
                        }                        
                    })                         
                }
            }             
        }
    }
    
}
</script>
<style scoped lang="scss">
.v-data-table{
    width: 100%;
    height: 100%;
}
.permissionItem{
    width: 100%;
}

</style>
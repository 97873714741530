<template>
    <v-container class="containChartBox ma-0 pa-0">
    <v-overlay absolute :value="overlay" opacity="0.2" >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
    class="ma-3"
        absolute
        :value="noData"
        opacity="0.2"
        >
        {{dataShow}}
    </v-overlay>
    <v-card  class="width100 height100 ma-0 d-flex flex-column">
        <v-card-actions class="pa-0 ma-0">
            <v-row no-gutters class="ma-0 align-center">
                <!-- <v-icon style="min-width:32px;flex-shrink: 0;" class="ma-2" @mousedown="resize">mdi-arrow-all</v-icon> -->
                <div class="ml-4 mover" style="min-width:68px;flex-shrink: 0;"><v-icon>mdi-swap-vertical</v-icon>{{title}}[24小时]</div>
                <v-spacer></v-spacer>
                <v-col cols="1"><v-icon class="ma-2 move" title="移动">mdi-swap-horizontal</v-icon></v-col>
                <v-col cols="1" v-show="false"><ColorBarConfig/></v-col>
                <!-- <v-col cols="1" class="mr-2 pointer"><v-icon class="ma-2" @click="$emit('close',keyV)">mdi-close</v-icon></v-col>                 -->
            </v-row>
        </v-card-actions>
        <ColorBarConfig @update="timerDraw" :dataKey="type == 'rcsmerge' ? 15:13 " ref="colorBar" class="mapColorBarConfig" /> 
        <div ref="chart" class="width100 plotychart height100">

        </div>
    </v-card>
</v-container>
</template>
<script>
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { getPLSFIXDataApi} from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:"realtime.heatmap",
    components:{ColorBarConfig},
    props:{
        title:{
            type:String
        },
        type:{
            type:String
        },
    },
    data(){
        return {
            overlay:false,
            dataShow:'正在加载数据',
            chart:null,
            width:370,
            height:268,            
            loadTodayDataOver:false,
            data:[],
            ablData:[],//边界层数据,
            currentWatch:null,
            timer:null
        }
    },
    computed:{   
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },     
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){   
                this.watchData(n.siteId) 
                this.data = []
                this.load();                            
            }
        },        
        "$store.state.global_relaMonitorState.widthHeight":{
            handler(n,v){    
                if(localStorage.getItem("monitorTimeHotHeight")){
                    this.height = localStorage.getItem("monitorTimeHotHeight")
                }
            
                if(localStorage.getItem("monitorTimeHotWidth")){
                    this.width = localStorage.getItem("monitorTimeHotWidth")
                }    
                if(this.chart){
                    this.chart.resize()
                }
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()
            }
        },        
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this; 
        if(this.$store.state.storage_global.currentSite.scanType == "FIX"){
            setTimeout(() => {
                that.watchData(that.$store.state.storage_global.currentSite.siteId)
                that.load()        
            }, 800);    
        }
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && (n.ScanMode == "FIX" || n.ScanMode == 1)){                                                                        
                    var json = JSON.parse(JSON.stringify(n.jsonData ));   
                    if(that.loadTodayDataOver){   
                        let len = that.data.length-json.length  
                        if(n.DataType == transferDataType.ablMerge.value && json[0].mergeType == 0){                                                        
                            that.ablData = json.concat(that.ablData)                      
                            that.ablData.slice(0,len)
                        }                                               
                        if(that.type == 'rcsmerge' && json[0].dataType == transferDataType.rcsMerge.value && json[0].mergeType ==0){                            
                            that.data = json.concat(that.data) 
                            that.data.slice(0,len)                                                                
                        } 
                        if(that.type == 'extmerge' && json[0].dataType == transferDataType.extMerge.value && json[0].mergeType ==0){                                                                                        
                            that.data = json.concat(that.data)
                            that.data.slice(0,len)                                                 
                        }
                        that.loadChart()
                    }                           
                } 
            }, {immediate: true })
        },   
        load(){            
            let siteId = this.$store.state.storage_global.currentSite.siteId;
            let start = new Date (new Date ().getTime()-24*60*60*1000).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId)             
        },        
        loadData(start,end,siteId,datas = [],time){  
            var that = this;
            that.loadTodayDataOver=false;
            that.overlay = true;
            if(this.type == 'rcsmerge'){
                getPLSFIXDataApi({
                    id:siteId,  
                    dataType:"rcsmerge",                 
                    params:{
                        LastDate:time,
                        PageSize:1000,                        
                        StartTime:start,
                        EndTime:end,
                        MergeType:0
                    },success:(d)=>{   
                        if(d.Result.Data.length == 0){
                            that.dataShow='暂无数据';
                            that.loadTodayDataOver = true;                           
                        }
                        that.overlay = false;
                        let _datas = d.Result.Data.map(a=>{
                            return {
                                dataType:transferDataType.rcsMerge.value,
                                siteId:a.DprSiteId,
                                period:a.DprPeriod,
                                scanType:a.DprScanMode,
                                dataTime:a.DprTime.replace("T"," "),
                                mergeType:a.DprMergeType,
                                angleOfPitch:a.DprPitch,
                                straightAngle:a.DprStraight,
                                distanceData:a.DprDis,
                                data:a.DprValue
                            }})   
                        let _ablDatas = d.ABL.Data.map(a=>{
                            return {
                            dataType:transferDataType.ablMerge.value,
                            siteId:a.DpaSiteId,
                            period:a.DpaPeriod,
                            scanType:a.DpaScanMode,
                            dataTime:a.DpaTime.replace("T"," "),
                            angleOfPitch:a.DpaPitch,
                            straightAngle:a.DpaStraight,
                            dis:a.DpaDis,
                            height:a.DpaHeight
                        }})
                        that.ablData = that.ablData.concat(_ablDatas) 
                        datas = datas.concat(_datas)                       
                        if(new Date(d.Result.Data[d.Result.Data.length -1].DprTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Result.Data.length < 1000){                                   
                            that.data = datas;
                            that.loadTodayDataOver = true;
                            that.loadChart()        
                        }else{                              
                            that.data = datas;                  
                            that.loadChart() 
                            that.loadData(start,end,siteId,datas,d.Result.Data[d.Result.Data.length -1].DprTime)                                  
                        } 
                        if(that.data.length == 0)   {
                            that.dataShow='暂无数据';
                        }
                    }
                })
            }else if(this.type == 'extmerge'){
                getPLSFIXDataApi({
                    id:siteId,  
                    dataType:"extmerge",                 
                    params:{
                        LastDate:time,
                        PageSize:1000,                        
                        StartTime:start,
                        EndTime:end,
                        MergeType:0
                    },success:(d)=>{  
                        if(d.Result.Data.length == 0){                            
                            that.loadTodayDataOver = true;
                            that.dataShow='暂无数据';
                        } 
                        that.overlay = false;
                        let _datas = d.Result.Data.map(a=>{
                            return {
                                dataType:transferDataType.extMerge.value,
                                siteId:a.DpeSiteId,
                                period:a.DpePeriod,
                                scanType:a.DpeScanMode,
                                dataTime:a.DpeTime.replace("T"," "),
                                angleOfPitch:a.DpePitch,
                                mergeType:a.DpeMergeType,
                                straightAngle:a.DpeStraight,
                                distanceData:a.DpeDis,
                                data:a.DpeValue
                            }})
                        datas = datas.concat(_datas) 
                        let _ablDatas = d.ABL.Data.map(a=>{
                            return {
                            dataType:transferDataType.ablMerge.value,
                            siteId:a.DpaSiteId,
                            period:a.DpaPeriod,
                            scanType:a.DpaScanMode,
                            dataTime:a.DpaTime.replace("T"," "),
                            angleOfPitch:a.DpaPitch,
                            straightAngle:a.DpaStraight,
                            dis:a.DpaDis,
                            height:a.DpaHeight
                        }})
                        that.ablData = that.ablData.concat(_ablDatas) 
                        if(new Date(d.Result.Data[d.Result.Data.length -1].DpeTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Result.Data.length < 1000){     
                             that.data = datas;   
                             that.loadTodayDataOver = true;                                                                          
                             that.loadChart()         
                        }else{                                
                            that.data = datas;
                            that.loadChart()                   
                            that.loadData(start,end,siteId,datas,d.Result.Data[d.Result.Data.length -1].DpeTime)                                  
                        }    
                    }
                })
            }
            
        },
        //重置大小
        resize(){
            var that = this;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = Number(that.width) - e.movementX ;
                let resetH = Number(that.height) + e.movementY;
                that.width = resetW > 0.4 * document.body.clientWidth ? 0.4 * document.body.clientWidth : resetW < 370 ? 370 : resetW;
                that.height = resetH > 0.4 * document.body.clientHeight ? 0.4 * document.body.clientHeight : resetH < 260 ?260 : resetH;
                localStorage.setItem("monitorTimeHotHeight",that.height)
                localStorage.setItem("monitorTimeHotWidth",that.width)
                that.$store.commit("global_relaMonitorState/updatewidthHeight",`${that.width},${that.height}`)          
            }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{                               
                document.removeEventListener('mousemove',fun)
            });
        },
        async loadChart(){                        
            let target = this.$refs.chart;             
            let datas = JSON.parse(JSON.stringify(this.data)).sort((a,b)=>{
                return new Date(a.dataTime.replace("T"," ")).getTime() - new Date(b.dataTime.replace("T"," ")).getTime()
            });
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            if(this.data.length == 0 || !this.$refs.colorBar){                
            var data = [ 
                {
                y: [],
                x: [],
                z: [],
                type: 'heatmap',           
                showscale: false,                                            
            },{
                type: 'scatter',               
                x: [],
                y: [],                              
                showlegend: false,                
            }
            ];

            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                uirevision:'true',      
                xaxis: {
                    color:fontColor,      
                    nticks:5 ,         
                },
                yaxis: {
                    title:'距离(km)'    
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:10
                }
            };
                Plotly.newPlot(target, data, layout,{responsive: true});   
                return
            }

            let yData = [];               
            yData = [];   
            let distanceData =datas[0].distanceData;   
            let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离     
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];
            let yAbl = [];            
            datas.forEach((a,i)=>{                    
                xData.push(new Date(a.dataTime.replace("T"," ")).pattern("HH:mm <br /> MM月dd日"))                
                for(var j=0;j< yData.length;j++){
                    if(j > noDataLength)
                    {
                        let _index = j-noDataLength-1
                        if(!zData[_index]){
                            zData[_index] = [];
                            hoverText[_index] = []
                        }
                        zData[_index].push(a.data[j])
                        hoverText[_index].push(`水平角：${a.straightAngle}<br />俯仰角：${a.angleOfPitch}<br />监控值：${a.data[j]}<br />距离：${yData[j]}<br />时间：${a.dataTime.replace("T"," ")}` )
                    }
                }   
                let item = this.ablData.find(b=>b.dataTime == a.dataTime)                    
                if(item){
                    yAbl.push(item.height)
                }else{
                    yAbl.push(NaN)
                }                                                                        
            });  
            var data = [ 
                {
                    name:'',
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,            
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
            },{
                name:'边界层',
                type: 'scatter',               
                x: xData,
                y: yAbl,                              
                showlegend: true,
                text:yAbl
            }
            ];

            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor, 
                uirevision:'true',     
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.11,                    
                    font:{
                        color: fontColor
                    }
                },
                xaxis: {
                    color:fontColor,      
                    nticks:5          
                },
                yaxis: {
                    title:'距离(km)',
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:45,
                    l:32,
                    r:42
                }
            };
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});                   
        },  
        timerDraw(){  
            if(this.data.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }           
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.plotychart{
    :deep(.modebar){
        padding-right:16px ;
    }
}
</style>
import AMapLoader from '@amap/amap-jsapi-loader';
import {i18n} from '@/plugins/helper/vuetify'
import monitorPointConfig from '@/plugins/datas/monitorPointConfig.json'
import help from '@/plugins/helper/help'
import customMarkList from '@/plugins/datas/customMarkList.json'
import { getWeather,getGeocode,getMarkInfoListApi} from '../../api';
import store from '@/store'
import baseType from '@/plugins/datas/baseType'
//import Vue from 'vue'
export default (callback)=>{  
    window._AMapSecurityConfig = null
    window._AMapSecurityConfig = {
        //serviceHost:'http://lbs.amap.com/_AMapService',     
        securityJsCode :"8686c88245e7161b96cfa5ad7cf3a5a9"//"d62eaceef38f760eedd9904bd21bdee4"//"8686c88245e7161b96cfa5ad7cf3a5a9"
        //serviceHost:'http://192.168.11.70:8082/_AMapService',  
        // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    }   
    AMapLoader.load({
        "key":"abeb6e0117950e3ebdb1f52518e9b471", //高德 309f07ac6bc48160e80b480ae511e1e9 //公司 7e8552b5ae69a84bba1ce68a46f39020   //abeb6e0117950e3ebdb1f52518e9b471           // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.ControlBar','AMap.DistrictSearch','AMap.DistrictLayer','AMap.MoveAnimation','AMap.Scale','AMap.Object3DLayer','AMap.Object3D'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {             // 是否加载 AMapUI，缺省不加载
           // "version": '1.1',   // AMapUI 缺省 1.1
            "plugins":[],       // 需要加载的 AMapUI ui插件
        },
        "Loca":{                // 是否加载 Loca， 缺省不加载
            //"version": '2.0'  // Loca 版本，缺省 1.3.2
        },
    }).then((AMap)=>{ 
        AMap.plugin('AMap.MoveAnimation', function(){
          
        });
        window.SatelliteTileLayer = new AMap.TileLayer.Satellite();
        AMap.plugin('AMap.Geocoder', function() {
            let geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode                    
                radius: 500, //范围，默认：500，
                batch :true,
                lang:"zh_cn"
            })
            window.getAddressByLngLat = function(config,callback){
                let lnglat = [config.data.lng,config.data.lat]  
                // getGeocode(lnglat,(d)=>{
                //     if(d.status == 1){
                //         d.regeocode.formattedAddress = d.regeocode.formatted_address
                //         d.regeocodes = d.regeocodes ? d.regeocodes.map(a=>a.formattedAddress = a.formatted_address):null
                //         callback([d.regeocode] || d.regeocodes) 
                //     }
                // })                  
                if(config.city){
                    geocoder.setCity(config.city)
                }                
                geocoder.getAddress(lnglat,function(status, result){
                    if (status === 'complete'){
                        callback(result.regeocode,config.data)
                    }
                    else{
                        openNotice({
                            text:i18n.t("sys.self.getPositionError"),
                            type:"error"
                        })
                    }            
                })
            }                   
            // 使用geocoder做地理/逆地理编码
            window.getAddressByLngLats = function(config,callback){
                //let lnglats = config.lnglats 
                // getGeocode(lnglats,(d)=>{
                //     if(d.status == 1){
                //         d.regeocode.formattedAddress = d.regeocode.formatted_address
                //         d.regeocodes = d.regeocodes ? d.regeocodes.map(a=>a.formattedAddress = a.formatted_address):null
                //         callback([d.regeocode] || d.regeocodes) 
                //     }
                // })      
                let lnglats = config.lnglats                   
                if(config.city){
                    geocoder.setCity(config.city)
                }                
                geocoder.getAddress(lnglats,function(status, result){
                    if (status === 'complete'){
                        callback(result.regeocodes,config)
                    }
                    else{
                        console.error("地理位置获取异常,请刷新后重试")
                        openNotice({
                            text:"地理位置获取异常,请刷新后重试",
                            type:"error"
                        })
                    }            
                })
            }     
            
            window.loadMonitorPointToMap = function(map,points,cb,btn){
                for(var a of points){  
                    let iconColor = a.BmpAttribute.indexOf("国控") >= 0? `#f12424`:
                    a.BmpAttribute.indexOf("省控") >= 0?`#f18824`:
                    a.BmpAttribute.indexOf("市控") >= 0?`#24a9f1`:
                    a.BmpAttribute.indexOf("镇控") >= 0?`#3624f1`:'#ccf124'
                    let content = `<div>名称：${a.BmpName}</div>
                    <div>控属性质：${a.BmpAttribute}</div>
                    <div>经纬度：${a.BmpLng}，${a.BmpLat}</div>
                    <div>位置：${a.BmpPosition}</div>`;

                    let extend = monitorPointConfig.find(b=>b.pointNo == a.BmpPointNo)                    
                    let circleEl = `<circle style="fill:${iconColor}" cx="196.85" cy="195.03" r="76.15"/>`
                    if(extend){
                        circleEl = `<circle class="moni_svg" style="fill:#59eb59;animation: onlinemarker 3s infinite ease-in-out;animation-delay:0.8s;"  cx="196.85" cy="195.03" r="76.15"/>`
                        let htm = `<div><div>名称：${a.BmpName}</div>
                        <div>控属性质：${a.BmpAttribute}</div>
                        <div>经纬度：${a.BmpLng}，${a.BmpLat}</div>
                        <div>位置：${a.BmpPosition}</div>
                        <div><a id='' href="javascript: void(0)">查看趋势图</a>
                        </div>
                        </div>`;                        
                        let _el = help.toDom(htm);     
                        let _item = a;         
                        for(var b of _el[0].children){
                            if(b.children && b.children.length > 0){
                                let _link = b.children[0].tagName && (b.children[0].tagName == "a" || b.children[0].tagName == "A")?b.children[0]:null;                                    
                                if(_link){                                                                              
                                    _link.onclick=function(){                                         
                                        if(btn){                                            
                                            btn(_item)
                                        }                                       
                                    }
                                }
                            }                                                        
                        } 
                        content = _el[0];         
                    }
                     let iconstr = `<svg xmlns="http://www.w3.org/2000/svg" style="width:24px;height:24px"  viewBox="0 0 391 483.42">
                        <defs><style>
                        @keyframes onlinemarker{
                            0%{
                                transform-origin: 50% 50%;
                                transform: scale(0.1);
                                opacity:0;
                            }
                            30%{
                                transform: scale(1.2);
                                opacity:1;
                                transform-origin: 50% 50%;
                            }
                            80%{
                                transform: scale(1);
                                opacity:1;
                                transform-origin: 50% 50%;
                            }
                            100%{
                                transform-origin: 50% 50%;
                                transform: scale(0.1);
                                opacity:0;
                            }
                        }
                        </style>
                        </defs>
                        <g data-name="图层 2">
                        <g data-name="图层 1">
                        <g style="transform-box:fill-box; transform-origin:center;" >
                        ${circleEl}
                        </g>
                        <path style="fill:${iconColor}" d="M195.5,0C87.53,0,0,87.53,0,195.5S87.53,391,195.5,391,391,303.47,391,195.5,303.47,0,195.5,0Zm-.38,336.86c-79.52,0-144-63.29-144-141.36s64.47-141.36,144-141.36,144,63.29,144,141.36S274.65,336.86,195.12,336.86Z"/>
                        <polygon style="fill:${iconColor}" points="327 441 66.39 437 105.86 340 283.14 340 327 441"/>
                        <rect style="fill:${iconColor}" x="25" y="428.58" width="329" height="54.83"/>
                        </g></g></svg>`
                                   
                    var marker = new AMap.Marker({
                        map:map,
                        content: iconstr,  // 自定义点标记覆盖物内容
                        position:[a.BmpLng,a.BmpLat], // 基点位置
                        anchor: 'bottom-center', 
                        offset: new AMap.Pixel(0, 15),// 相对于基点的偏移位置                                    
                        zIndex:1000
                    });
                   
                    marker.on('click',function(ev){                                     
                        let info = new AMap.InfoWindow({
                            content: content,                        
                        });
                        info.open(map,ev.lnglat)
                    })
                    if(cb){
                        cb(marker)
                    }
                }
            }

            window.loadCustomMarkerToMap = function(map,cb){
                store.dispatch('storage_type/getDicMenu',{
                    typeid:baseType.markType,
                    callback:(d)=>{
                        let types = d.map(a=>{
                            return {
                                text:a.BdValue,
                                value:a.BdCode
                            }
                        })
                        getMarkInfoListApi({
                            params:{
                                PageIndex:1,
                                PageSize:10000,
                            },
                            success:(d)=>{
                                let datas = d.Data.map(a=>{
                                    a.MiModifyTime =new Date(a.MiModifyTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss')
                                    a.type = types.find(b=>b.value == a.MiType).text
                                    return a
                                });
                                for(var a of datas){  
                                    let extendInfo = a.MiExtend ? JSON.parse(a.MiExtend) : []
                                    let content = `${a.MiName}</br>
                                    位置：${a.MiPosition}</br>
                                    类型：${a.type}</br>
                                    创建时间：${a.MiModifyTime}</br>                                    
                                    `;                      
                                    let extendText = ''
                                    extendInfo.forEach(extend => {
                                        extendText += `${extend.name}:${extend.value}</br>`
                                    });
                                    content += extendText;
                                    var marker = new AMap.Marker({
                                        map:map,
                                        content: a.MiIcon,  // 自定义点标记覆盖物内容
                                        position:[a.MiLng,a.MiLat], // 基点位置
                                        anchor: 'bottom-center', 
                                        offset: new AMap.Pixel(0, 15),// 相对于基点的偏移位置                                    
                                        zIndex:1000
                                    });
                                   
                                    marker.on('click',function(ev){                                     
                                        let info = new AMap.InfoWindow({
                                            content: content,                        
                                        });
                                        info.open(map,ev.lnglat)
                                    })
                                    if(cb){
                                        cb(marker)
                                    }
                                }
                            }
                       })
                    }
                }) 
                
            }
            
            //天气
            AMap.plugin('AMap.Weather', function() {
                //创建天气查询实例
                var weather = new AMap.Weather();
                window.getWeather = (city,cb,eb)=>{
                    // getWeather(city,(d=>{
                    //     cb({
                    //         province:d.lives[0].province,
                    //         city:d.lives[0].city,
                    //         adcode:d.lives[0].adcode,
                    //         weather:d.lives[0].weather,
                    //         temperature:d.lives[0].temperature,
                    //         windDirection:d.lives[0].winddirection,
                    //         windPower:d.lives[0].windpower,
                    //         humidity:d.lives[0].humidity,
                    //         reportTime:d.lives[0].reporttime, 
                    //     })
                    // }))
                    weather.getLive(city, function(err, data) {
                        if(err == null){
                            if(cb)
                            {
                                cb(data)
                            }
                        }else
                        {
                            if(eb){
                                eb(data)
                            }
                        }
                     })
                }                               
            });
        })  
        callback()      
    })
}
import Vue from 'vue'
const components = require.context('@/components', true, /.vue/);

const registerAllComponents = (paths)=>{
    components.keys().forEach(key => {
        let path = key.slice(2)
        let dir = path.split("/")[0];
        
        if(paths.indexOf(dir) >= 0){
            let component = components(key).default || components(key)            
            Vue.component(component.name,component)
        }
        
    });
        
}

const getSetValue= (dataSourceProp=[]) => {
    const computedProp = {};
    for (const prop of dataSourceProp) {
        computedProp[prop] = {
            get() {          
                return (this.$refs && this.$refs[prop]&&this.$refs[prop].value0) || '';
            }
        }
    }
    return computedProp;
};
export {
    getSetValue,
    registerAllComponents
}
<template>
    <v-card class="width100 height100">
        <v-overlay class="width100 height100"  v-model="imageOverlay.show">
        <v-icon style="position:absolute;top:-20px;right:-20px;z-index:90;" @click="imageOverlay.show=false">mdi-close</v-icon>
        <v-carousel height="100%" hide-delimiter-background show-arrows-on-hover >
            <v-carousel-item    
            class="width100 height100"               
            v-for="(item,i) in imageOverlay?.images"
            :key="i"                       
            >
            <v-img width="1280" height="760"  :src="item"></v-img>
            </v-carousel-item>
        </v-carousel>
        </v-overlay>
        <v-card-title class="ma-0 pa-1 alarmTableTitle">能见度报警</v-card-title>
        <v-data-table 
            height="100%"
            :loading="loadingWarnInfo"                               
            fixed-header  
            :headers="headers"
            show-group-by
            :items="data"
            item-key="_id"
            disable-pagination
            hide-default-footer        
            @click:row="clickRow"
            :item-class="()=>{return'_tableActive'}"
            calculate-widths>
            <template v-slot:item.minLevel="{ item }">
                <v-row class="ma-0 pa-0 d-flex  align-center">                   
                    {{item.minLevel}}<span  class="mx-1" v-show="item.minLevel" :style="`background:${item.minColor};width:15px;height:15px;border-radius:50%;`"></span>
                </v-row>
            </template>
            <template v-slot:item.avgLevel="{ item }">
                <v-row class="ma-0 pa-0 d-flex  align-center">                   
                    {{item.avgLevel}}<span class="mx-1" :style="`background:${item.color};width:15px;height:15px;border-radius:50%;`"></span>
                </v-row>
            </template>

            <template v-slot:item.image="{ item }">
                 <v-row class="ma-0 pa-0 d-flex">
                      <v-img class="ma-1" height="36" width="64" @click.stop="openImgList(item.image,imgsrc)" v-for="(imgsrc,i) in item.image?.slice(0,2)" :key="i" :src="imgsrc"></v-img>
                      <span v-show="item?.image?.length > 2">...</span>
                  </v-row>
            </template>
                <template v-slot:top>
                    <v-row v-show="false" dense no-gutters class="mx-2 mt-2 flex-shrink-0 flex-grow-0 ">
                        <v-col cols="1" class="d-flex align-start flex-grow-0 flex-shrink-0 pointer">
                           
                        </v-col>
                        <v-col cols="4" class="flex-grow-0 flex-shrink-0 d-flex">
                                            
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="sendDialog" persistent max-width="500px" hide-overlay>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                                                                              
                                class="mb-2 primary--text"
                                text
                                v-bind="attrs"
                                v-on="on"
                                >推送</v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline">选择发送人员</v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-select
                                                    v-model="wxUserIds"
                                                    :items="recivers"                                 
                                                    :label="$i18n.t('noticeSendManage.label.form.reciver')"
                                                    multiple
                                                    required
                                                >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                        ripple
                                                        @click="selectAllReciver"
                                                        >
                                                        <v-list-item-action>
                                                            <v-icon :color="wxUserIds.length > 0 ? 'indigo darken-4' : ''">{{ wxUserIds.length == 0 ? 'mdi-checkbox-blank-outline': (recivers.length > wxUserIds.length ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>选择全部</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>                                                                            
                                                    </template>
                                                </v-select> 
                                            </v-row>  
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="green darken-1" text @click="sendDialog = false">取消</v-btn>
                                        <v-btn color="green darken-1" text @click="sendDialog = false;sendMessage()">立即发送</v-btn>
                                    </v-card-actions>
                            </v-card>
                            </v-dialog>
                        <v-col cols="1" class="d-flex justify-end align-start flex-grow-0 flex-shrink-0 pointer" >
                        
                        </v-col>
                    </v-row>
            </template>
         </v-data-table>
    </v-card>
</template>
<script>
import {getReciverApi,sendAlarmToWxUserApi,getVlsAlarmApi,getImageApi} from '@/api' 
import { uploadMinioURL } from '@/api/url'
export default {
    props:{
        timeRange:{
            type:Array
        },
    },
    name:'vls.history.alarmTable',
    data(){
        return{            
            loadingWarnInfo:false,
            headers:[{
            text:this.$i18n.t('vls.realTime.table.header.time'),
            value: 'time',
            sortable: true,
            groupable:false,
            width:'15%'
            },            
            {
            text:this.$i18n.t('vls.realTime.table.header.avgValue'),
            value: 'avgValue',
            sortable: true,
            groupable:true,
            width:'13%'
            },
            {
            text:this.$i18n.t('vls.realTime.table.header.avgLevel'),
            value:'avgLevel',
            groupable:true,
            width:'13%'
            },
            {
            text:this.$i18n.t('vls.realTime.table.header.minValue'),
            value: 'minValue',
            sortable: true,
            groupable:true,
            width:'13%'
            },
            {
            text:this.$i18n.t('vls.realTime.table.header.minAngle'),
            value: 'minAngle',
            sortable: true,
            groupable:true,
            width:'13%'
            },{
            text:this.$i18n.t('vls.realTime.table.header.minLevel'),
            value: 'minLevel',
            groupable:true,
            width:'13%'
            },{
                text:this.$i18n.t('vls.realTime.table.header.img'),
                value: 'image',
                groupable:false,
                width:'13%'
            }],
            data:[],
            //注：大雾等级:   1 - 10 km为轻雾，等级1
            //0.5 - 1 km为大雾，等级2
            //0.2 - 0.5 km为浓雾，等级3
            //0.05 - 0.2 km为强浓雾，等级4
            //0.00 - 0.05 km为特强浓雾，等级5
            //结果用数字等级表示，界面展示用 “轻雾” 等标注 
            levelConfig:{
                0:{
                    min:10,
                    max:50,
                    text:"",
                    color:'rgb(227, 255, 246)'
                },
                1:{
                    min:1,
                    max:10,
                    text:"轻雾",
                    color:'rgb(202, 251, 235)'
                },
                2:{
                    min:0.5,
                    max:1,
                    text:"大雾",
                    color:'rgb(104, 253, 250)'
                },
                3:{
                    min:0.2,
                    max:0.5,
                    text:"浓雾",
                    color:'rgb(4, 204, 203)'
                },
                4:{
                    min:0.05,
                    max:0.2,
                    text:"强浓雾",
                    color:'rgb(5, 152, 151)'
                },
                5:{
                    min:0,
                    max:0.05,
                    text:"特强浓雾",
                    color:'rgb(6, 82, 72)'
                }
            }, 
            sendDialog:false,//推送弹窗
            wxUserIds:[],
            recivers:[],
            imageOverlay:{
                show:false,
                images:[],
                currentSrc:""   
            },
        }
    },
    watch:{
        timeRange:{
            handler(){
                this.loadAlarmData()
            }
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){                
                this.loadAlarmData()                            
            }
        },     
    },
    mounted(){
        this.loadReciver(); 
    },
    methods:{    
        loadAlarmData(){
            if(this.$store.state.storage_global.currentSite.siteId && this.timeRange.length > 1){
                let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
                let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //  
                let that = this;
                getVlsAlarmApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{
                        StartTime:start,
                        EndTime:end,
                        PageSize:1500,
                        PageIndex:1                  
                    }, 
                    success:(d)=>{
                        that.data = d.Data.map(a=>{
                            return{
                                time:new Date(a.DavTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                                minAngle:a.DavScanMode == 0 ? a.DavStraight:a.DavPitch,
                                avgValue:a.DavPeriodValue,
                                minValue:a.DavValue,
                                avgLevel:that.levelConfig[a.DavPeriodLevel].text,
                                minLevel:that.levelConfig[a.DavLevel].text,
                                level:a.DavLevel,
                                periodLevel:a.DavPeriodLevel,
                                color:that.levelConfig[a.DavPeriodLevel].color,
                                minColor:that.levelConfig[a.DavLevel].color,
                                image:a?.VaiImagePath?.map(a=>uploadMinioURL+a)
                            }
                        })
                    }
                })
            }
        },
        loadReciver(){
            var that = this;
            getReciverApi({
                id:this.$store.state.storage_user.user.companyKey,
                params:{
                    Name:""
                },
                success:(d)=>{
                    that.recivers = d.map(a=>{
                        return {
                            text:a.NerName,
                            value:a.NerWechatId
                        }
                    })
                }
            })
        },
        selectAllReciver(){
            if(this.recivers.length == this.wxUserIds.length){
                this.wxUserIds = []
            }else{
                this.wxUserIds = this.recivers.map(a=>{
                    return a.value;
                })
            }
        },
        sendMessage(){
            //发送消息
            let alarmList = this.alarmList;      
            let site = this.$store.state.storage_global.currentSite;                  
            if(this.wxUserIds.length == 0)     {
                openNotice({
                    type:"error",
                    text:"请选择微信接收人员"
                })
                return;
            }            
            let provinceCity = alarmList[0].province + alarmList[0].city;
            let time = new Date(alarmList[0].time.replace("T"," "))
            let start = new Date();
            let end = new Date();
            start.setTime(time.getTime() - 1000 * 60 * 4)
            end.setTime(time.getTime() + 1000 * 60 * 4)
            let data = [{
                ProvinceCity:provinceCity,
                WxUserId:this.wxUserIds,
                SiteName:site.siteName.indexOf("-") >= 0 ? site.siteName.split("-")[1]:site.siteName,
                StartTime:start.pattern("yyyy-MM-dd HH:mm:ss"),
                EndTime:end.pattern("yyyy-MM-dd HH:mm:ss"),
                WXMessageGisInfos:alarmList.map(a=>{
                    return {
                        Position:a.position.replace(provinceCity,""),
                        Count:a.count
                    }
                })
            }];       
            sendAlarmToWxUserApi({
                data:data,                
                success:(d)=>{
                    if(d[0].IsSucceed){
                        openNotice({
                            type:"success",
                            text:"已推送至微信服务"
                        })
                    }else{
                        openNotice({
                            type:"error",
                            text:"推送失败，请联系管理人员"
                        })
                    }
                }
            })

        },
        clickRow(item,data){
            this.$emit('clickRow',data.item.time,data.item.color)
        },
        openImgList(item){
            this.imageOverlay.images= item;
            this.imageOverlay.show = true;
        }
    }
}
</script>
<style scoped lang="scss">
:deep(.v-data-table){
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    position: relative;
}
.alarmTableTitle{   
    font-size: 16px;
    justify-content: left;
    font-weight: 600;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import * as Base64 from 'js-base64'
import store from '@/store'
import { loginApi } from '@/api' 
import menu from '@/plugins/datas/menu'
import help from '@/plugins/helper/help'
// 自动注入路由
const modules = require.context('@/views', true, /.vue/);

const autoRoutes = [{
  path:"/login",
  alias:'/',
  name: 'login',
  component: () => import('@/views/noLayout/login.vue'),
  meta:{
    activeName:'login'
    //title:"登录"
  }
},{
  path:"/m/login",  
  name: 'm.login',
  component: () => import('@/views/mobile/login.vue'),
  meta:{
    activeName:'m.login'
    //title:"登录"
  }
},{

  path:"/preview",  
  name: 'preview',
  component: () => import('@/views/noLayout/preview.vue'),
  meta:{
    //title:"预览"
    activeName:'preview'
  },
},{
  path:"/article",  
  name: 'article',
  component: () => import('@/views/noLayout/article.vue'),
  meta:{
    //title:"文章查看"
    activeName:'article'
  },
}
];

const getChildrenIndex= (path,viewName,parent,parentRoute)=>{
  var childrenIndex = path.indexOf("children");
  if(childrenIndex >= 0){
    let currentPath = path.slice(0,childrenIndex);  
    let itemRoute = path.slice(childrenIndex+8,path.length);
    parent = parent ? parent : autoRoutes;
    //添加父节点    
    let routerLayout = parent.find(a=>{
      return a.path == currentPath;
    })

    if(!routerLayout){      
      let names = currentPath.split("/").filter(a=>{ return a.toLocaleLowerCase().indexOf("layout") >=0 })            
      routerLayout = {
        path:currentPath,
        component: () => import(`@/views${ parentRoute ? parentRoute + currentPath:currentPath}index.vue`),
        name:names.join("."),
        meta:{
          activeName:names[0],
          auth:true
        },
        children:[]        
      }  
      parent.push(routerLayout);          
    }
    if(itemRoute.indexOf("children") >=0){    
      var pp =(parentRoute? parentRoute+currentPath:currentPath)+'children';
      getChildrenIndex(itemRoute,viewName,routerLayout.children,pp)
    }
    else{      
      if(itemRoute.indexOf("Layout")<0){
        let routeInfoSplit = itemRoute.split("/");
        let item = {
          path:itemRoute,
          meta:{
            activeName: getActiveName(itemRoute),
            auth:true
          },
          component:() => import(`@/views/${viewName}`),
          name:routeInfoSplit.filter(a=>{ return a!= ""}).join(".")
        }
        if(viewName.indexOf('mobile') >= 0){
          item.name = 'm.'+item.name;
          item.path = '/m'+item.path;
        }                
        routerLayout.children.push(item);   
      }      
    }  
  }
}
function getActiveName(itemRoute){
  let routeInfoSplit = itemRoute.split("/");
  if(itemRoute.indexOf('pls') >= 0 || itemRoute.indexOf('agt') >= 0 ||itemRoute.indexOf('vls') >= 0||itemRoute.indexOf('wls') >= 0||itemRoute.indexOf('vwls') >= 0){        
    return `${routeInfoSplit[routeInfoSplit.length -2]}.${routeInfoSplit[routeInfoSplit.length -1]}`
  }   
  if(itemRoute.indexOf('caseTemplate') >= 0){
    return 'caseManage'
  }
  return  routeInfoSplit[routeInfoSplit.length -2];
}
modules.keys().forEach((fileItem) => {
  const viewName = fileItem.slice(2);
  const path = fileItem.slice(1).replace('.vue', '');
  let childrenIndex = path.lastIndexOf("children") 
  if(childrenIndex >=0 ){
    getChildrenIndex(path,viewName);                 
  }  
});



const routes = autoRoutes;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const getShow = function(items,key,isShow = false){            
  items.forEach(a=>{
      if(a.key == key && a.show){
          isShow = true
          return isShow;
      }else{                   
          if(a.children){
              isShow = getShow(a.children,key,isShow)
          }
          return isShow;
      }
  })
  return isShow;
}
const notFilterPath = ["/m/login","/login","/preview",'/article','/case/caseManage/edit','/case/caseTemplate/edit','/report/dailyManage/edit','/case/caseTemplate/index','/userInfo/index','/noticeManage/index','/mobile/mainLayout']

function nextTo(toPath,to,next){
  consolelog(routes,'logroute')
  let isMobile = help.isMobile();
  if(isMobile && toPath.indexOf('/m/') < 0 ){
    next({path:'/m'+toPath})
  }
  else if(!isMobile && toPath.indexOf('/m/') >= 0){
    next({path:toPath.replace('/m/','/')})
  }else{   
    if(to.path!=toPath) {
      next({path:toPath})
    }else{
      next()
    }    
  }
}

router.beforeEach((to,from,next)=>{  
    var toPath = to.path.toString();
    toPath = toPath.replace('/m/','/')  
    
    if(notFilterPath.indexOf(toPath) >=0){            
      nextTo(to.path.toString(),to,next)
    }else{
      if(toPath == "/check"){
        if(!to.query.ack){
          nextTo(to.path.toString(),to,next)
        }
        if(to.query.theme){
          localStorage.setItem("currentTheme",decodeURI(to.query.theme))
        }
        var accPosition = to.query.ack[0];
        var accInfo = Base64.decode(to.query[accPosition].replace(/\_/g,"="));
        var acc = accInfo.split(" ")[0];
        var pd = Base64.decode(accInfo.split(" ")[1])      
        //登录且跳转
        loginApi({
            data:{
                UserName:acc,
                Password:pd
            },
            headers:{
              "Content-type":"application/json",
              "loginType":1
            },         
            success:(d)=>{       
                localStorage.setItem("checkLink",true)          
                localStorage.setItem("token",d.token)  
                localStorage.setItem("tokenTime",new Date().pattern("yyyy-MM-dd HH:mm:ss"))  
                store.dispatch('storage_user/getCurrentUser',function(){
                  nextTo("/home/index",to,next)                    
                });                                                                                     
            }
        })    
      }else{
        if(to.meta.auth&& store.state.storage_user?.role?.permission ){        
            let itemMenu = menu.client.find(a=>{
                return a.path?.indexOf(toPath)>=0 || a.children?.find(b=>{return b.path?.indexOf(toPath)>=0 || b.children?.find(c=>c.path?.indexOf(toPath)>=0)})
            })             
            //no pass
            var permission =JSON.parse(store.state.storage_user?.role?.permission) 
            let show;
            if(permission && itemMenu){                   
                var clientMenu = permission?.menu?.client
                var _menu = clientMenu.find(a=>{
                  show = a.show;
                  return a.key == itemMenu.key || a.children?.find(b=>{show = a.show; return b.key == itemMenu.key || b.children?.find(c=>{show = a.show; return c.key == itemMenu.key})})
                }) 
                if(show){
                  nextTo(to.path.toString(),to,next)
                }else if(toPath == '/home/index'){
                  let name;
                  for(var a of menu.client){
                    if(a.children){                    
                      for( var b of a.children){
                        let isShow = getShow(clientMenu,b.key)                      
                        if(isShow){                      
                          name = b.name  
                          break;                                                  
                        }
                      }
                      if(name){
                        break;
                      }                      
                    }else{
                      let isShow = getShow(clientMenu,a.key)                      
                      if(isShow){                                              
                        name=a.name
                        break;
                      }
                    }                    
                  };
                  if(name){
                    router.push({name:name})
                  }else{                    
                    router.push({path:"/login"})                                
                  }                     
                }            
            }      
          }else{          
            router.push({path:"/login"})                                
          }        
      }
    }    
  }
)
 
export default router

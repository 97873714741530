<template>
    <v-container class="contain ma-0 pa-0">           
        <v-row style="position: relative;" class="ma-0  px-2 pt-3 height50 width100">
            <div class="hotMapColorBar">
                公里(km)
                <div :style="`background-color:${i.color}`" v-for="i in levelConfig" class="hotMapColorBarBox" :key="i.key">
                    <div class="hotMapColorBarText" >{{i.max}} [{{i.text}}]</div>
                </div>
            </div>    
            <Trendmap ref="trendmap"></Trendmap>
        </v-row>
        <v-row  class="ma-0 py-1 px-2 height50">
            <Scanmap :dataList="lidarData" v-if="currentScanType == 'PPI'"></Scanmap>
            <RHI :dataList="lidarData"  v-if="currentScanType == 'RHI'"></RHI>
            <Heatmap :dataList="lidarData" v-if="currentScanType == 'FIX'"></Heatmap>     
        </v-row>  
        <v-expand-transition>
            <v-btn v-show="!showWarnList" class="mx-2 warnBtn pointer" @click="showWarnList=!showWarnList" fab large color="rgba(0,0,0,0)" >
                <v-icon title="还原窗口" color="primary" size="48px">mdi-android</v-icon>
            </v-btn>
        </v-expand-transition>
        <v-expand-transition origin="bottom bottom">
            <div class="warnInfo" v-show="showWarnList">
                <v-icon style="width: 20px;position: relative;top: 30px;z-index: inherit;left: calc(100% - 30px);" title="最小化" @click="showWarnList=!showWarnList">mdi-chevron-down-circle</v-icon>
                <AlarmTable   @clickRow="clickRow"></AlarmTable>
            </div>
        </v-expand-transition>
    </v-container>
</template>
<script>
import Scanmap from '@/components/mobile/monitoring/vls/realtime/Scanmap'
import RHI from '@/components/mobile/monitoring/vls/realtime/RHI'
import Heatmap from '@/components/mobile/monitoring/vls/realtime/Heatmap'
import Trendmap from '@/components/mobile/monitoring/vls/realtime/Trendmap'
import AlarmTable from '@/components/mobile/monitoring/vls/realtime/AlarmTable'
import transferDataType from '@/plugins/datas/transferDataType'
import {getVlsVizLatestApi } from '@/api' 
import scanType from '@/plugins/datas/scanType'
export default {
    components:{Scanmap,RHI,Heatmap,Trendmap,AlarmTable},
    name:"monitoring.vls.realtime",
    data(){
        return {
            currentScanType:"PPI",
            lidarData:[],
            showWarnList:true,
            levelConfig:[                
                {
                    key:1,
                    min:1,
                    max:10,
                    text:"轻雾",
                    color:'rgb(202, 251, 235)'
                },
                {
                    key:2,
                    min:0.5,
                    max:1,
                    text:"大雾",
                    color:'rgb(104, 253, 250)'
                },
                {
                    key:3,
                    min:0.2,
                    max:0.5,
                    text:"浓雾",
                    color:'rgb(4, 204, 203)'
                },
                {
                    key:4,
                    min:0.05,
                    max:0.2,
                    text:"强浓雾",
                    color:'rgb(5, 152, 151)'
                },
                {
                    key:5,
                    min:0,
                    max:0.05,
                    text:"特强浓雾",
                    color:'rgb(6, 82, 72)'
                }
            ],
            timer:null
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){                                
                var that = this;  
                //垂直扫描        
                if(!n.scanType){ 
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                               that.currentScanType = obj.scanType
                            }
                        }
                    })
                }else{
                    that.currentScanType = n.scanType
                } 
                
                this.loadLidarData()
            }
        },
        "$store.state.storage_global.siteDatas":{
            handler(n,v){  
                let json = n.jsonData
                if(n.SiteId == this.$store.state.storage_global.currentSite.siteId){ 
                   if(json[0].dataType == 9 || json[0].dataType == 17){
                        this.currentScanType = json[0].scanType
                   }                       
                }
            }
        },
        "$store.state.storage_global.currentSite.scanType":{
            handler(n,v){        
                this.currentScanType = n
            }
        }
    },
    mounted(){
        var that = this;     
        this.currentScanType = this.$store.state.storage_global.currentSite.scanType   
        if(!this.currentScanType){
            this.$store.dispatch('storage_user/getLastestScanType',{
                site:this.$store.state.storage_global.currentSite,
                cb:(obj)=>{
                    if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                        that.currentScanType = obj.scanType
                    }
                }
            })
        }                  
        this.$nextTick(()=>{
            that.loadLidarData()
        })        
    },
    methods:{
        loadLidarData(){         
            var that = this; 
            if(this.timer)         {
                clearTimeout(this.timer)
            }
            this.timer =setTimeout(() => {
                getVlsVizLatestApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{                    
                    },
                    success:(d)=>{           
                        that.lidarData = d.filter(a=>a.DvvScanMode ==0).map(a=>{
                            return {
                                angleOfPitch: a.DvvPitch,
                                data:a.DvvValue,                                                    
                                dataTime: new Date(a.DvvTime.replace("T"," ")).pattern("yyyy-MM-ddTHH:mm:ss"),
                                dataType:transferDataType.viz,
                                distanceData: a.DvvDis,
                                period:a.DvvPeriod,
                                siteId: a.DvvSiteId,
                                scanType:scanType[a.DvvScanMode],
                                straightAngle:a.DvvStraight
                            }
                        })                                 
                    }
                })
            }, 200);            
        },
        clickRow(time,color){
            this.$refs.trendmap.clickTime(time,color)
        }
    }
    
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
    overflow: auto;
}
.height50{
    height: 60%;
}
.hotMapColorBar{
    z-index: 2;
    left: 5%;
    width: 90%;
    font-size: 12px;
    display: flex;    
    position:absolute;
    top: calc(100% - 50px);
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;  
    top: 10px;  
}
.hotMapColorBarBox{
    width:100%;
    height: 10px;
}
.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 2;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    height: 60%;
    width: 100%;
    z-index: 2;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
</style>
<template>
   <v-container class="d-flex flex-column container ">
        <v-card :elevation='0' class="cardFrom">            
            <v-form
                class="ma-6"
                ref="form"
                v-model="form.valid"                            
                >                               
                <v-row align="end" justify="space-between">
                    <v-card width="60%" elevation="0">
                         <v-row class="ma-0">
                            <v-text-field
                                v-model="form.body.CiTitle"                                
                                :label="$i18n.t('caseEdit.label.form.title')"
                                required
                                :rules="rules.title"
                            ></v-text-field>  
                        </v-row>
                        <v-row class="ma-0">
                            <v-file-input :rules="rules.cover" accept="image/*" small-chips v-model="coverFile" prepend-icon="mdi-file-image" :label="$i18n.t('caseEdit.label.form.cover')"></v-file-input>
                        </v-row>
                        <v-row class="ma-0">
                            <v-switch 
                                :true-value="1"
                                :false-value="0" 
                                v-model="form.body.CiPublish" class="mx-2" 
                                :label="$i18n.t('caseEdit.label.form.publish')">
                            </v-switch>
                        </v-row>
                        <v-row class="ma-0">
                            <v-row>
                                <v-col>
                                    <v-select
                                        multiple
                                        :rules="rules.type"
                                        v-model="form.body.CiType"
                                        :items="types"                                
                                        :label="$i18n.t('caseEdit.label.form.type')"
                                        required
                                    ></v-select>                              
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :rules="rules.author"
                                        v-model="form.body.CiAuthor"                                
                                        :label="$i18n.t('caseEdit.label.form.author')"
                                        required
                                    ></v-text-field>  
                                </v-col>
                            </v-row>                       
                        </v-row>                               
                        <v-row class="ma-0">
                            <SitesSelect 
                            searchBtn
                            checkAllBtn
                            :rules="rules.siteId"
                            :value.sync="form.body.CiSiteId"
                            :options="sites"                                
                            :label="$i18n.t('caseEdit.label.form.site')"
                            multiple
                            required/>                           
                        </v-row >
                        <v-row class="ma-0">
                            <SitesSelect 
                            searchBtn
                            checkAllBtn
                            checkAllLimitBtn
                            :rules="rules.limit"
                            :value.sync="form.body.CiLimit"
                            :options="sites"                                
                            :label="$i18n.t('caseEdit.label.form.limit')"
                            multiple
                            required/>      
                            <!-- <v-select
                                :rules="rules.limit"
                                v-model="form.body.CiLimit"
                                :items="limits"   
                                @change="filterLimit"                             
                                :label="$i18n.t('caseEdit.label.form.limit')"
                                multiple
                                required
                            ></v-select>                              -->
                        </v-row>
                    </v-card>
                    <!-- <label for="input-151" class="v-label mb-4" :class="$vuetify.theme.isDark ? 'theme--dark':'theme--light'">{{$i18n.t('caseEdit.label.form.cover')}}</label> -->                    
                    <v-card class="caseCard flex-wrap flex-grow-0 flex-shrink-0 ma-4 pa-2">
                        <input ref="imgUpload" type='file' accept="image/*" hidden @change="uploadFile('load')" />
                        <v-img
                            @click="uploadFile('up')"
                            :src="cover"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="232px"
                            width="232px"
                        >
                        <v-card-title class="onlytext2" v-text="form.body.CiTitle"></v-card-title>
                        </v-img> 
                        <v-card-text class="pa-2 " style="width:232px">
                            <v-row class="ma-0 .body-1 authtext onlytext2">
                            作者：{{form.body.CiAuthor}}
                            </v-row>
                            
                            <v-row class="ma-0 authtext onlytext2">
                                类型：{{caseTypeText}}
                            </v-row> 
                            <v-row class="ma-0 authtext onlytext2">
                                站点：{{form.body.CiSiteId.join(',')}}
                            </v-row> 
                            <v-row class="ma-0 authtext onlytext2">
                                发布时间：{{new Date().pattern('yyyy-MM-dd HH:mm:ss')}}
                            </v-row>
                        </v-card-text>
                        
                        <v-card-actions class="justify-end">
                            <v-icon style="font-size: 22px;" title="复制链接" color="#00838f">mdi-content-copy</v-icon>                            
                        </v-card-actions>            
                    </v-card>                                
                </v-row>
          
                <v-row>
                    <v-dialog v-model="openTemplateDialog" width="600" content-class="templateDialog" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"                        
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            >{{$i18n.t('caseEdit.btn.openTemplate')}}</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <v-row>
                                    <v-col cols="9">
                                        {{$i18n.t('caseEdit.selectTemplate')}}
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn color="primary" @click="$router.push({name:'case.caseTemplate.index'})" class="ma-2">{{$i18n.t('caseEdit.btn.templateManage')}}</v-btn>
                                    </v-col>
                                </v-row>                                
                            </v-card-title>
                            <v-item-group>
                                <v-radio-group v-model="checkedModel">
                                    <v-container height="600" class="templateCard">
                                        <v-row>                                    
                                            <v-col
                                            v-for="n,key in templates"
                                            :key="key"
                                            cols="12"
                                            md="4"
                                            >
                                            <v-item v-slot:default="{ active, toggle }">                                        
                                                <v-card                                        
                                                class="d-flex align-center justify-center"
                                                dark
                                                height="262"
                                                width="242"
                                                @click="checkedModel = n;toggle"
                                                >
                                                <v-scroll-y-transition>                                            
                                                <div                                            
                                                    v-if="active"                                            
                                                    class="text-center templateMask d-flex align-center justify-center"            
                                                    >   
                                                    应用                                       
                                                </div>
                                                </v-scroll-y-transition>                                                                                
                                                <v-img 
                                                height="232px"
                                                width="232px" 
                                                class="white--text align-end pointer"
                                                :src="n.src">
                                                    <v-card-title class="onlytext2" v-text="n.CtName"></v-card-title>                                                    
                                                    <v-card-actions class="d-flex justify-end">
                                                    <v-radio :value="n"></v-radio>
                                                </v-card-actions>
                                                </v-img>                                        
                                                
                                                </v-card>
                                            </v-item>
                                            </v-col>                                   
                                        </v-row>
                                    </v-container>
                             </v-radio-group>
                        </v-item-group>
                        <v-card-actions>
                            <v-spacer></v-spacer>  
                            <v-btn color="primary" @click="form.body.CiArticle = checkedModel.CtContent;checkedModel = {};openTemplateDialog = false">
                                {{$i18n.t('caseEdit.btn.ok')}}       
                            </v-btn>                         
                            <v-btn
                                color="secondary"                                
                                @click="openTemplateDialog = false"
                            >                             
                            {{$i18n.t('caseEdit.btn.close')}}   
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row>
                    <Editor :tinymceScriptSrc='url' :init="editConfig" :initialValue="form.body.CiArticle" v-model="form.body.CiArticle" apiKey="isss3pi0uzaqpoowqf3e1ta7svhbmfm3hoy5ejh863kukv5p"></Editor>
                </v-row>                                                                                                                                                                                         
            </v-form>
        </v-card>
        <v-row class="ma-2 d-flex justify-end">
            <v-btn color="primary" @click="save" class="ma-2"><v-icon>mdi-content-save</v-icon>{{$i18n.t('caseEdit.btn.save')}}</v-btn>
            <v-btn color="secondry" @click="$router.history.go(-1)" class="ma-2"><v-icon>mdi-close-circle-outline</v-icon>{{$i18n.t('caseEdit.btn.cancel')}}</v-btn>
        </v-row>    
    </v-container> 
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import baseType from '@/plugins/datas/baseType'
import {upLoadApi,addCaseApi,getCaseApi,editCaseApi,getCaseTemplatesApi } from '@/api'
import { uploadMinioURL } from '@/api/url'
export default {
    components:{Editor},
    name:"case.caseManage.edit",
    data(){
        var that = this;
        return {
            checkedModel:{},//模板

        form:{
            valid:false,            
            body:{
                CiId:"",
                CiSiteId:[],
                CiLidarId:[],                                    
                CiLidarType:[],
                CiTitle:"",
                CiAuthor:"",                    
                CiArticle:"",
                CiLimit:[],
                CiType:[],
                CiPublish:0,
                //附件
                CaseAttachmentRelas:[]
            }                  
        },
        rules:{
            siteId: [
                    v => v.length > 0 || this.$i18n.t("caseEdit.label.rule.siteid")
                ],
            type:[v => v.length > 0 || this.$i18n.t("caseEdit.label.rule.type")],
            limit:[v => v.length > 0 || this.$i18n.t("caseEdit.label.rule.limit")],
            title:[ v => !!v || this.$i18n.t("caseEdit.label.rule.title"),
                    v =>(v && v.length <= 64)|| this.$i18n.t("caseEdit.label.rule.titleLength"),],
            author:[ v => !!v || this.$i18n.t("caseEdit.label.rule.author"),
                    v =>(v && v.length <= 32)|| this.$i18n.t("caseEdit.label.rule.authorLength"),],
            article:[v => !!v || this.$i18n.t("caseEdit.label.rule.article")],
            cover:[v => this.cover!="" || this.$i18n.t("caseEdit.label.rule.cover")]
        },
        cover:"",//附件临时地址
        coverFile:null,//附件
        editorFiles:[],
        templates:[],
        page:1,
        rows:10,
        total:0,
        openTemplateDialog:false,
        publishs:[{
            text:"未发布",
            value:0
        },{
            text:"已发布",
            value:1
        }],    
        types:[],//案例类型
        sites:[],
        editConfig:{
            height: 500,
            menubar: false,
            toolbar_mode:"wrap",//工具栏按钮设置floating 多余的收进... 浮动在页面  / sliding 多余的收进... 点击撑开  / scrolling 多余滚动 / wrap
            //language_url:"../../../assets/langs/zh_CN.js",
            language: 'zh_CN',
            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
            branding: false,
            contextmenu: "bold copy",
            images_upload_base_path:uploadMinioURL,
            paste_data_images: true,//拖拽支持
            //images_file_types: 'jpeg,jpg,png,gif,bmp,webp',//拖拽支持

            font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
            plugins: [
                'advlist autolink lists link image imagetools charmap',
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table hr visualchars',
                'nonbreaking'
            ],
            toolbar:
                `code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link unlink openlink anchor |
                alignleft aligncenter alignright alignjustify alignnone outdent indent | 
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | 
                table image rotateleft media charmap emoticons hr pagebreak insertdatetime print preview |searchreplace fullscreen | bdmap indent2em lineheight formatpainter axupimgs |
                newdocument visualblocks visualchars nonbreaking`,
                //images_reuse_filename:true,//是否重命名为唯一文件名
            images_upload_handler:function (blobInfo, succFun, failFun,progress) { 
                var url = URL.createObjectURL(blobInfo.blob()) 
                var ext = blobInfo.filename().replace(blobInfo.name(),"");   
                let filename = $help.guid()+ext;
                that.editorFiles.push({
                    url:url,
                    file:blobInfo.blob(),
                    fileName:filename
                })   
                succFun(url)       
            },//图片拖拽粘贴上传
            file_picker_types: 'file image media',//上传格式
            file_picker_callback:function (callback, value, meta) {           
                var input =document.createElement("input");
                input.setAttribute("type","file")
                switch(meta.filetype){
                    case "image":
                        input.setAttribute("accept",".bmp,.jpg,.png,.tif,.gif,.pcx,.tga,.exif,.fpx,.svg,.psd,.cdr,.pcd,.dxf,.ufo,.eps,.ai,.raw,.WMF,.webp,.avif,.apng")
                        break;
                    case "media":
                        input.setAttribute("accept",".wav,.mp1,.mp2,.mp3,.mp4,.wma,.ogg,.ape,.aac,.avi,.navi,.dvavi,.asf,.asx,.rm,.rmvb,.3gp,.mov,.m4v,.mkv,.flv,.vob,.mpeg,.mpg,.mod,.opus,.flac,.ape,.alac    ")
                        break;                
                }
                input.click()
                input.onchange = function() {
                    let file = this.files[0]
                    let url = URL.createObjectURL(file)
                    let ext = $help.getFileExtension(file.name); 
                    let filename = $help.guid()+ext;
                    that.editorFiles.push({
                        url:url,
                        file:file,
                        fileName:filename
                    })     
                    callback(url)
                }
            }
        }
    }
    },    
    watch:{
        coverFile:{
            handler(file){     
                if(file){
                    var url = window.URL.createObjectURL(file); // 得到bolb对象路径，可当成普通的文件路径一样使用，赋值给src;                    
                    this.cover = url;
                }else{
                    this.cover = ""
                }            
            },
            deep:true
        },    
        "form.body.CiSiteId":{
            handler(n){
                var sites =  this.$store.state.storage_user.sites;
                sites = sites.filter(a=>{
                    return  n.indexOf(a.siteId) >= 0
                })
                this.form.body.CiLidarId = sites.map(a=>{
                    return a.lidarInfo.lidarId
                });
                this.form.body.CiLidarType = sites.map(a=>{
                    return a.lidarInfo.bliTypeId
                });
            }
        }    
    },
    computed:{
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },       
        caseTypeText(){
            var item  = this.types.filter(a=>{
                return this.form.body.CiType.indexOf(a.value) >= 0
            })
            return item.map(a=>(a.text)).join(',')
        },
        url(){
            return process.env.BASE_URL+ "tinymce/tinymce.min.js"
        }
    },
    mounted(){
        let isManager = false;
        var permission = this.$store.state.storage_user.role?.permission;
        var perJson = JSON.parse(permission);
        var item = perJson?.action?.find(a=>{
            return a.key == "case_10"
        })
        if(item){
            var managerPermission = item.permissions?.find(a=>{
                return a.key == 'edit'
            })                
            isManager = managerPermission?.value;
        }        
        if(!isManager){
            openNotice({
                type:'error',
                text:"您不具备相关权限"
            })
            this.$router.push({
                path:'/case/caseManage/index'
            })            
        }
        this.editorFiles = []
        this.loadType()
        this.load()   
        this.loadSites() 
        this.loadTemplates()              
    },
    methods:{
        load(){            
            if(this.$route.query.id){
                var that = this;
                 getCaseApi({
                    id:this.$route.query.id,
                    success:(d)=>{                        
                        that.form.body = {
                            CiId:d.CiId,
                            CiSiteId:d.CiSiteId,
                            CiLidarId:d.CiLidarId,                                    
                            CiLidarType:d.CiLidarType,
                            CiTitle:d.CiTitle,
                            CiAuthor:d.CiAuthor,                    
                            CiArticle:d.CiArticle,
                            CiLimit:d.CiLimit,
                            CiType:d.CiType.map(a=>Number(a)),
                            CiPublish:d.CiPublish,
                            //附件
                            CaseAttachmentRelas:d.CaseAttachmentRelas
                        }
                        var rela = d.CaseAttachmentRelas.find(a=>{
                            return a.CarObjectName.indexOf('/cover/image/') >=0
                        });
                        that.cover =uploadMinioURL +rela.CarBucketName + rela?.CarObjectName
                        
                    }
                 })
            }
        },
        loadSites(){
            var that = this;                     
            that.sites = this.$store.state.storage_user.sites.map(a=>{
                return {
                    text:a.siteName,
                    value:a.siteId
                }
            })
        },
        loadType(){
            var that = this;
            this.$store.dispatch('storage_type/getDicMenu',{
                typeid:baseType.caseType,
                callback:(d)=>{
                    that.types = d.map(a=>{
                      return{
                        text:a.BdValue,
                        value:a.BdCode
                      }  
                    })
                    that.load()
                }
            })
        },
        loadTemplates(){
            var that = this;
            getCaseTemplatesApi({
                params:{
                    PageIndex:this.page,
                    PageSize:this.rows,            
                    Title:this.title,                    
                    // ModifyStartTime:this.editDateRange[0],
                    // ModifyEndTime:this.editDateRange[1],
                },success:(d)=>{
                    that.templates = d.Data.map(a=>{    
                        if(a.CaseTemplateAttachmentRela){
                            a.src = `${uploadMinioURL}${a.CaseTemplateAttachmentRela.CtarBucketName}${a.CaseTemplateAttachmentRela.CtarObjectName}`
                        }                                            
                        else{
                            a.src = require('@/assets/img/staff_1024.jpg')                        
                        }                                     
                        return a;
                    });   
                    that.total = d.Total                      
                }
            })
        },      
        uploadFile(type){
            var that = this;
            if(type == 'up'){                
                this.$refs.imgUpload.click();
            }
            if(type == 'load'){
                let file = this.$refs.imgUpload.files[0]                             
                if(file){            
                    if((file.type).indexOf("image/")==-1){  
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("caseEdit.notice.notImg")
                        })
                        return
                    }                      
                    this.coverFile = file;
                }
                else{
                    this.cover = "";
                    this.coverFile = "";
                }
            }
        },
        save(){
            var that = this;
            console.log(this.form.body.CiArticle)
            var rel = this.$refs.form.validate();                    
            if(rel){
                //去除删掉的文件
                let relaFliles = []
                for(var i of this.form.body.CaseAttachmentRelas){
                    if(i.CarObjectName.indexOf('cover') >=0){
                        //封面判断
                        if(this.cover.indexOf(i.CarObjectName) >=0){
                            //封面没换
                            relaFliles.push({
                                CarObjectName:i.CarObjectName,
                                CarBucketName:i.CarBucketName
                            })
                        }

                    }else{
                        //编辑器文件
                        if(this.form.body.CiArticle.indexOf(i.CarObjectName) >=0 ){
                            //文件还在
                             relaFliles.push({
                                CarObjectName:i.CarObjectName,
                                CarBucketName:i.CarBucketName
                            })
                        }

                    }

                }
                this.form.body.CaseAttachmentRelas = relaFliles;
                //查找需要保存的文件            
                let timer = new Date().pattern("yyyyMM")
                let article = this.form.body.CiArticle
                for(var i of this.editorFiles){
                    if(this.form.body.CiArticle.indexOf(i.url) >=0){  
                        that.form.body.CaseAttachmentRelas.push({
                            CarBucketName:"case",
                            CarObjectName:'editor/image/'+timer+'/'+i.fileName
                        }) 
                        article =article.replaceAll(i.url,`${uploadMinioURL}case/editor/image/${timer}/${i.fileName}`) 
                        //上传                
                        upLoadApi(
                        {
                            url:'/1/case?objectName=editor/image/'+timer+'/'+i.fileName,
                            files:i.file,
                            success:(d)=>{
                                //succFun(`${d}case/editor/image/${timer}/${filename}`)                                            
                            }
                        })
                    }
                }    

                if(!this.cover){
                    openNotice({
                        type:"error",
                        text:that.$i18n.t("caseEdit.notice.cover")
                    })
                    return;
                }
                if(this.form.body.CiArticle.length == 0){
                    openNotice({
                        type:"error",
                        text:that.$i18n.t("caseEdit.notice.article")
                    })
                    return;
                }
                if(this.coverFile){
                    let ext = $help.getFileExtension(this.coverFile.name);                    
                    let filename = $help.guid()+'.'+ext;
                    let timer = new Date().pattern("yyyyMM") 
                    upLoadApi(
                    {
                        url:'/1/case?objectName=cover/image/'+timer+'/'+filename,
                        files:this.coverFile,
                        success:(d)=>{        
                            that.form.body.CaseAttachmentRelas.push({
                                CarBucketName:"case",
                                CarObjectName:`/cover/image/${timer}/${filename}` 
                            })   
                            that.submit(article)                               
                            
                        }
                    })  
                }else{
                    that.submit(article)  
                }                             
            }            
        },
        submit(article){
            var that = this;
            if(this.form.body.CiId){
                editCaseApi({
                    id:this.form.body.CiId,
                    data:{
                        CiId:this.form.body.CiId,
                        CiSiteId:this.form.body.CiSiteId,
                        CiLidarId:this.form.body.CiLidarId,                                    
                        CiLidarType:this.form.body.CiLidarType,
                        CiTitle:this.form.body.CiTitle,
                        CiAuthor:this.form.body.CiAuthor,                    
                        CiArticle:article,
                        CiLimit:this.form.body.CiLimit,
                        CiType:this.form.body.CiType,
                        CiPublish:this.form.body.CiPublish,
                        //附件
                        CaseAttachmentRelas:this.form.body.CaseAttachmentRelas
                    },
                    success:()=>{
                        openNotice({
                            type:"success",
                            text:that.$i18n.t("caseEdit.notice.editSuccess")
                        })
                        that.$router.push({path:'/case/caseManage/index'})
                    }
                })
            }else{
                addCaseApi({
                    data:{                        
                        CiSiteId:this.form.body.CiSiteId,
                        CiLidarId:this.form.body.CiLidarId,                                    
                        CiLidarType:this.form.body.CiLidarType,
                        CiTitle:this.form.body.CiTitle,
                        CiAuthor:this.form.body.CiAuthor,                    
                        CiArticle:article,
                        CiLimit:this.form.body.CiLimit,
                        CiType:this.form.body.CiType,
                        CiPublish:this.form.body.CiPublish,
                        //附件
                        CaseAttachmentRelas:this.form.body.CaseAttachmentRelas
                    },
                    success:()=>{
                        openNotice({
                            type:"success",
                            text:that.$i18n.t("caseEdit.notice.addSuccess")
                        })
                        that.$router.push({path:'/case/caseManage/index'})
                    }
                })
            }
        }
    }
}
</script>
<style scoped lang="scss">
.cardFrom{
    height: 100%;   
    overflow: auto;
}
:deep(.templateDialog){
    max-height: 600px !important;
    overflow: auto;
}
.templateCard{
    height: 400px;
    overflow: auto;
}
:deep(.caseCard .v-responsive__content){
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.authtext{
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}
.onlytext2{
    height: 34%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.templateMask{
    width:100%;
    height:100%;
    position:absolute;
    z-index:1;
    background:rgba(0,0,0,0.5);
    font-size:24px;
}
</style>
<template>
    <v-menu
        ref="menu"
        v-model="openMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="datetimeR"
        transition="scale-transition"
        offset-y
        max-width="600px"
        min-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="datetimeR"
            :label="label"
            prepend-icon="mdi-clock-time-three-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-card >
            <v-row no-gutters>
            <v-col cols="6">
                <v-date-picker
                    v-if="openMenu"
                    v-model="date"
                    full-width
                    :min="minDate"
                    :max="maxDate"
                >
                </v-date-picker>
            </v-col>
            <v-col cols="6">
                <v-time-picker
                    :use-seconds="useSeconds"
                    ampm-in-title
                    v-if="openMenu"
                    v-model="time"
                    full-width   
                    :min="minTime"             
                    :max="maxTime"             
                ></v-time-picker>   
            </v-col>
            </v-row> 
            <v-card-actions>
                <v-btn @click="ok">{{$i18n.t("components.datatimepicker.btn.ok")}}</v-btn>
                <v-btn @click="close">{{$i18n.t("components.datatimepicker.btn.close")}}</v-btn>
            </v-card-actions>                                                   
        </v-card>        
      </v-menu>
</template>
<script>
export default {
    name:"DateTimePicker",
    props:{
        useSeconds:{
            type:Boolean,
            default:false
        },
        datetime:{
            type:Date,
            default:()=>{
                return new Date()
            }
        },
        min:{
            type:String
        },
        max:{
            type:String
        },
        label:{
            type:String,
            default:""
        }
    },
    computed:{
        minDate(){
            if(this.min){
                return new Date(this.min).pattern("yyyy-MM-dd")
            }     
            return ""       
        },
        maxDate(){
            if(this.max){
                return new Date(this.max).pattern("yyyy-MM-dd")
            }  
            return ""
        },
        minTime(){
            if(this.min){
                return new Date(this.min).pattern("hh:mm:ss")
            }   
            return ""
        },
        maxTime(){
            if(this.max){
                return new Date(this.max).pattern("hh:mm:ss")
            }  
            return ""
            
        }
    },
    data:()=>({
        openMenu:false,
        time:"",
        date:"",
        datetimeR:""
    }),
    watch:{
        datetime(n){
            if(n){                
                this.datetimeR = n.pattern('yyyy-MM-dd HH:mm:ss')  
            }            
        }
    },
    mounted(){
        this.datetimeR = this.datetime.pattern('yyyy-MM-dd HH:mm:ss')        
    },
    methods:{
        ok(){
            if(this.time == ""){
                openNotice({
                    type:"error",
                    text:"请选择时间"
                })
                return
            }
            
            if(this.date == ""){
                openNotice({
                    type:"error",
                    text:"请选择日期"
                })
                return
            }
            this.$refs.menu.save(this.date + ' ' + this.time)            
            this.$emit('update:datetime',new Date(this.date + ' ' + this.time))
        },
        close(){            
            this.$refs.menu.save(this.datetime)
            this.$emit('update:datetime',new Date(this.datetimeR))
        }
    }
}
</script>
<style scoped lang="scss">
:deep(.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span,.v-time-picker-title__time .v-picker__title__btn),:deep(.v-time-picker-title__time span){
    height: 56px !important;
    font-size: 56px !important;
}
</style>
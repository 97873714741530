<template>
    <div class="width100 height100">
        <v-hover close-delay="1000" v-slot="{ hover }">
            <div ref="btn" :style="hover? animeD(20):animeD(-20)" style="position: absolute;z-index:160;bottom:60px;right:-20px">
                <v-scale-transition>
                    <v-btn v-show="hover" @click="downloadImg('png')" absolute fab small style="right:30px;bottom:60px">
                        PNG
                    </v-btn>                    
                </v-scale-transition>
                <v-scale-transition>
                    <v-btn v-show="hover" @click="downloadImg('svg')" absolute fab small style="right:50px;bottom:20px">
                        SVG
                    </v-btn>
                </v-scale-transition>
                <v-scale-transition>
                    <v-btn v-show="hover" @click="downloadImg('jpg')" absolute fab small style="right:30px;bottom:-20px">
                        JPG
                    </v-btn>                    
                </v-scale-transition>
                <v-btn v-show="showDownload" @click="downloadImg('png')" absolute fab small style="right:0;bottom:20px">
                    <v-icon>mdi-camera</v-icon>
                </v-btn>
            </div>         
        </v-hover>
        <router-view class="width100 height100" ref="layout">        
        </router-view>
    </div>
</template>
<script>
import * as htmlToImage from 'html-to-image';
import anime from 'animejs'
export default {
    name:"showLayout",  
    data(){
        return {
            showDownload:false
        }
    },  
    watch:{ 
        "$route"(n,v){         
            this.showDownload =  n.path != '/home/index'
        }  
    },
    mounted(){
        this.showDownload =  this.$route.path != '/home/index'
    },
    methods:{
        animeD(right){
            anime({
                targets:this.$refs.btn,
                right:right + "px",
                easing: 'easeInOutQuad',
                duration: 500  
            })
        },
        downloadImg(type){
            let layout = this.$refs.layout.$el;
            updateMask({
                show:true,
                content:"处理中...",
                icon:true
            })
            // html2canvas(layout, {
            //     useCORS: true,
            //     logging:false,
            //     scrollY: 0
            // }).then((cvs) => {
            //     let link = cvs.toDataURL("image/png");
            //     updateMask({
            //         show:false,
            //         content:"",
            //         icon:true
            //     })
            //     $help.exportPicture(link,$help.guid() + '.png')
            // });  
            if(type == 'png'){
                htmlToImage.toPng(layout, { })
                    .then(function (dataUrl) {
                        /* do something */
                        updateMask({
                            show:false,
                            content:"",
                            icon:true
                        })
                        $help.exportPicture(dataUrl,$help.guid() + '.png')
                    });
            }
            if(type == 'jpg'){
                htmlToImage.toJpeg(layout, { })
                    .then(function (dataUrl) {
                        /* do something */
                        updateMask({
                            show:false,
                            content:"",
                            icon:true
                        })
                        $help.exportPicture(dataUrl,$help.guid() + '.jpg')
                    });
            }
            if(type == 'svg'){
                htmlToImage.toSvg(layout, { })
                    .then(function (dataUrl) {
                        /* do something */
                        updateMask({
                            show:false,
                            content:"",
                            icon:true
                        })
                        $help.exportPicture(dataUrl,$help.guid() + '.svg')
                    });
            }           
        }
    }
}
</script>
<style scoped>

</style>
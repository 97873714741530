<template>
    <v-container class="containChartBox height100 ma-0 pa-0">
    <v-overlay
        absolute
        :value="overlay"
        >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>  
    <v-overlay
    class="ma-3 mt-9"
        absolute
        :value="noData"
        opacity="0.2"
        >
    暂无数据
    </v-overlay>
    <v-card  class="width100 height100 ma-0 d-flex flex-column">
        <v-card-title class="ma-0 pa-1 heatmapTitle">能见度时间热图</v-card-title>
        <ColorBarConfig  @update="timerDraw" :dataKey="9" ref="colorBar" class="mapColorBarConfig" />       
        <div ref="chart" class="mt-12 width100 height100 plotychart">

        </div>
    </v-card>
</v-container>
</template>
<script>
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { getVlsVizApi,getVlsVizByPeriodApi } from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
import scanType from '@/plugins/datas/scanType'
export default {
    name:"vls.history.heatmap",
    components:{ColorBarConfig},
    props:{
        currentPeriod:{
            type:Object
        },
        timeRange:{
            type:Array
        }
    },
    data(){
        return {
            overlay:false,
            chart:null,
            width:370,
            height:268,            
            loadTodayDataOver:false,
            data:[],
            timer:null
        }
    },
    computed:{      
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },  
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
                let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") // 
                this.data = [] 
                this.loadData(start,end,this.$store.state.storage_global.currentSite.siteId)                           
            }
        },               
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart() 
            }
        },
        timeRange:{
            handler(n,v){
                if(this.$store.state.storage_global.currentSite.siteId){
                    let start = n[0].pattern("yyyy-MM-dd HH:mm:ss") //
                    let end = n[1].pattern("yyyy-MM-dd HH:mm:ss") //  
                    this.loadData(start,end,this.$store.state.storage_global.currentSite.siteId)
                } 
            }
        },   

    },
    mounted(){ 
        if(this.$store.state.storage_global.currentSite.siteId){
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //  
            this.loadData(start,end,this.$store.state.storage_global.currentSite.siteId)
        } 
    },
    methods:{      
        loadPeriodData(period){
            this.overlay = true;
            var that = this;
            getVlsVizByPeriodApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                period:period,
                success:(d)=>{
                    that.data = d.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: a.DvvTime.replace("T"," "),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }
                    }) 
                    that.loadChart()  
                    that.overlay = false;
                }
            })
        },
        loadData(start,end,siteId,datas = []){  
            var that = this;
            this.overlay = true;         
            //每三小时查询 避免数据量过大timeout
            let endTime =new Date(new Date().setTime(new Date(start).getTime() + 1000 * 60 * 60 * 6)) 
            let endTimeStr = endTime.getTime() > new Date(end).getTime() ? end:endTime.pattern("yyyy-MM-dd HH:mm:ss")
            getVlsVizApi({
                id:siteId,                  
                params:{
                    ScanMode:1,
                    PageSize:1000,                        
                    StartTime:start,
                    EndTime:endTimeStr,
                },success:(d)=>{   
                    that.overlay = false;                                        
                    let _datas = d.length == 0 ? []: d.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime:a.DvvTime.replace("T"," "),
                            dataType:transferDataType.viz.value,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }})                     
                    datas = _datas.concat(datas)                                      
                                                       
                    if(endTimeStr == end){                                   
                        that.data = datas;
                        that.loadTodayDataOver = true;
                        that.loadChart()        
                    }else{                              
                        that.data = datas;                  
                        that.loadChart() 
                        that.loadData(endTimeStr,end,siteId,datas)                                  
                    }     
                }
            })                       
        },            
        loadChart(){   
            let that = this;
            if(this.data.length == 0 || !this.$refs.colorBar){
                return;
            }         
            let target = this.$refs.chart; 

            let _datas =[]; 
            let minSpan = 1;       
            for(let i in this.data.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.data[i].dataTime).getTime() - new Date(this.data[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){       
                            j+=minSpan;                   
                            _datas.push({           
                                dataType:this.data[i].dataType,
                                siteId:this.data[i].siteId,
                                period:this.data[i].period,
                                scanType:this.data[i].scanType,
                                dataTime:new Date(new Date(this.data[i].dataTime).setMinutes(new Date(this.data[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                angleOfPitch:this.data[i].angleOfPitch,
                                straightAngle:this.data[i].straightAngle,
                                distanceData:this.data[i].distanceData,
                                data:Array.from({length: this.data[i].data.length}, (val, i) => NaN) 
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.data)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = [];               
            let distanceData =datas[0].distanceData;            
            let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离           
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];            
  
            datas.forEach((a,i)=>{                                      
                xData.push(new Date(a.dataTime))                    
                for(var j=0;j< yData.length;j++){                    
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }          
                    let _index = j+noDataLength                   
                    zData[j].push(a.data[_index])
                    hoverText[j].push(`水平角：${a.straightAngle}<br />俯仰角：${a.angleOfPitch}<br />监控值：${a.data[_index]}<br />距离：${yData[j]}<br />时间：${a.dataTime}` )
                }                    
            });  
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                        
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
               // title:"能见度时间热图",
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,              
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target?.clientWidth)    
                },
                yaxis: {
                    color:fontColor,  
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:42,
                    r:62,
                    l:22,
                }
            };
            if(target?.children?.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }       
        },timerDraw(){  
            if(this.data.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }         
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.plotychart{
    :deep(.modebar){
        padding-top: 40px;
        padding-right:60px ;
    }
}
</style>
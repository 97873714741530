
<template>
    <v-container ref="mibbox" class="monitorItemBox ma-0 pa-2 width100 height100" >        
        <v-card   class="width100 height100" min-height="280">     
            <v-card-actions class="mb-top">
                <v-row class="ma-0 pa-0 justify-space-between">
                    <v-menu open-on-click offset-y bottom max-height="400" z-index="450">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                            
                            class="ma-0 pa-0"                           
                            text                                                             
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon class="mx-1">mdi-map-marker-radius</v-icon>
                            {{currentSite.siteName}}{{currentSite.transStatus == 1 ? $i18n.t("layout.label.stop"):currentSite.commitStatus == 0 ? $i18n.t("layout.label.outline"):""}}
                            </v-btn>
                        </template>
                        <v-list shaped>
                            <v-list-item-group>
                                <v-list-item
                                    v-for="(item, index) in sites"
                                    :key="index" 
                                    :value="item"   
                                    @click="currentSite = item"                                     
                                >
                                <v-list-item-title :class="{'grey--text':item.commitStatus == 0}" class="width100 d-flex justify-space-between">
                                    {{ item.siteName }}<v-spacer class="mx-2"></v-spacer>{{ item.transStatus == 1 ? $i18n.t("layout.label.stop"): item.commitStatus == 0 ? $i18n.t("layout.label.outline"):""}}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                            
                </v-row>
            </v-card-actions> 
            <Scanmap  v-if="currentSite.scanType=='PPI'" :siteCount="siteCount" :sites="sites" :site="currentSite"/>
            <Heatmap  v-if="currentSite.scanType=='FIX'" :currentSite="currentSite" class="hotChart moveBox" title="RCS时间热图" type="rcsmerge"/>  
            <Heatmap  v-if="currentSite.scanType=='FIX'" :currentSite="currentSite" class="hotChart moveBox" title="消光系数时间热图" type="extmerge"/>  
            <RHI  v-if="currentSite.scanType=='RHI'" ref="rhichart" class="hotChartRHI" title="RCS图谱" :currentSite="currentSite" type="rcs" />  
        </v-card>
    </v-container>
</template>

<script>

import Scanmap from '@/components/mobile/monitoring/pls/windowUnion/Scanmap'
import Heatmap from '@/components/mobile/monitoring/pls/windowUnion/Heatmap'
import RHI from '@/components/mobile/monitoring/pls/windowUnion/RHI'
export default {
    name:"siteMonitorItem",
    components: {
        Scanmap,Heatmap,RHI
    },
    props:{
        siteCount:{
            type:Number
        },
        sites:{
            type:Array
        },        
        site:{
            type:Object
        }
    },
    watch:{
        currentSite:{
            handler(n){ 
            }
          
        }
    },
    data(){
        return {    
            currentSite:{
              siteName:"未选中",
              commitStatus:0,
            },        
        }
    },
    computed:{
      
    },
    beforeDestroy(){
    },
    mounted(){
        this.currentSite = this.site; 
                              
    },
    methods:{
  
    }
}
</script>

<style scoped lang="scss">
 
    .mb-top{
        height: 38px;
    }
</style>
<template>
    <v-container class="containChartBox ma-0 pa-0 height100">
    <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
    class="ma-3 mt-9"
        absolute
        :value="noData"
        opacity="0.2"
        >
    暂无数据
    </v-overlay>
    <v-card  class="width100 height100 ma-0">
        <v-card-title class="ma-0 pa-1 heatmapTitle">能见度时间热图</v-card-title>
        <ColorBarConfig @update="timerDraw" :dataKey="9" ref="colorBar" class="mapColorBarConfig"/>
        <div ref="chart" class="mt-0 width100 height100">

        </div>
    </v-card>
</v-container>
</template>
<script>
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { getVlsVizApi } from '@/api'
import Plotly from 'plotly.js-dist'
import transferDataType from '@/plugins/datas/transferDataType'
import scanType from '@/plugins/datas/scanType'
export default {
    name:"vls.windowUnion.heatmap",
    components:{ColorBarConfig},
    props:{
        currentSite:{
            type:Object
        }
    },
    data(){
        return {
            data:[],
            overlay:false,
            chart:null,
            width:370,
            height:268,
            loadTodayDataOver:false,
            currentWatch:null,
            timer:null
        }
    },
    computed:{        
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },
    },
    watch:{
        currentSite:{
            handler(n,v){    
                var that = this;
                this.data = []
                this.$nextTick(()=>{  
                    // that.lidarDatas = [];  
                    // that.loadLidarData(); 
                    that.watchData(n.siteId)               
                    that.load();
                })                           
            }
        },        
        "$store.state.global_relaMonitorState.widthHeight":{
            handler(n,v){    
                if(localStorage.getItem("monitorTimeHotHeight")){
                    this.height = localStorage.getItem("monitorTimeHotHeight")
                }
            
                if(localStorage.getItem("monitorTimeHotWidth")){
                    this.width = localStorage.getItem("monitorTimeHotWidth")
                }    
                if(this.chart){
                    this.chart.resize()
                }
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart() 
            }
        },      
        data:{
            handler(n){                
                this.loadChart()
            },deep:true
        }
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;
        this.loadColorConfig() 
        setTimeout(() => {            
            that.watchData(that.currentSite.siteId)
            that.load()
        }, 200);                        
        
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                            
                if(n.SiteId == that.currentSite.siteId &&n.DataType == transferDataType.viz.value&& (n.ScanMode == "FIX" || n.ScanMode == 1)){
                        var json = JSON.parse(JSON.stringify(n.jsonData));                                                                          
                        that.data = json.sort((a,b)=>{
                            return new Date(b.dataTime) - new Date(a.dataTime)
                        }).concat(that.data)                        
                    } 
            }, {immediate: true })
        },   
        load(){            
            let siteId = this.currentSite.siteId;            
            let start = new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 1)).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId)              
        },     
        loadData(start,end,siteId,datas = [],time){  
            var that = this;
            getVlsVizApi({
                id:siteId,                  
                params:{
                    LastDate:time,
                    PageSize:1000,                        
                    StartTime:start,
                    EndTime:end,
                    ScanMode:1,//FIX
                },success:(d)=>{   
                    if(d.length == 0){
                        that.loadTodayDataOver = true;
                        this.loadChart();
                    }            
                    let _datas = d.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: new Date(a.DvvTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }})   
             
                    datas = datas.concat(_datas)                                 
                    if(new Date(d[d.length -1].DvvTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.length < 1000){                                   
                        that.data = datas;
                        that.loadTodayDataOver = true;
                        that.loadChart()        
                    }else{                              
                        that.data = datas;                  
                        that.loadChart() 
                        that.loadData(start,end,siteId,datas,d[d.length -1].DvvTime)                                  
                    }    
                  
                }
            })                       
        },           
        loadChart(){            
            let target = this.$refs.chart; 
            if(this.data.length == 0 || !this.$refs.colorBar){
                return
            }
            let yData = [];               
            yData = [];  
            let datas = JSON.parse(JSON.stringify(this.data)).sort((a,b)=>{
                return new Date(a.dataTime.replace("T"," ")).getTime() - new Date(b.dataTime.replace("T"," ")).getTime()
            }); 
            let distanceData =datas[0].distanceData;            
            let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离     
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];           
            datas.forEach((a,i)=>{     
                xData.push(new Date(a.dataTime.replace("T"," ")).pattern("HH:mm <br /> MM月dd日"))
                for(var j=0;j< yData.length;j++){
                    if(j > noDataLength)
                    {
                        let _index = j-noDataLength-1
                        if(!zData[_index]){
                            zData[_index] = [];
                            hoverText[_index] = []
                        }
                        zData[_index].push(a.data[j])
                        hoverText[_index].push(`水平角：${a.straightAngle}<br />俯仰角：${a.angleOfPitch}<br />监控值：${a.data[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                    }  
                }      
            });  
                       
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                }
            ];

            var layout = {
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor, 
                uirevision:'true', 
                xaxis: {
                    color:fontColor, 
                    nticks:5      
                },
                yaxis: {
                    color:fontColor,  
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:42,
                    r:62,
                    l:22, 
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }   
        },
        timerDraw(){  
            if(this.data.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }      
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
}
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.heatmapTitle{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
</style>
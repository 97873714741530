<template>
    <v-container @visibilitychange="vchange">
        <div class="mapLegend white--text" v-show="showLegend">              
            <div class="d-flex align-center" v-for="i in siteTypeList" :key="i">
                <img class="mx-1" :src="siteIcon(i,false)">{{i == 'pls'?'颗粒物监测站':i == 'pmls'?'偏振比监测站':i=='vls'?'能见度监测站':i=='agt'?'走航车':i == 'wls'?"测风监测站":''}}          
            </div>                                  
        </div>
        <div ref="radarInfoBox" class="radarInfoBox" v-if="siteInfo">
            <div class="radarInfo">
                <!-- <img class="bgImg" src="~@/assets/infobg.jpg" /> -->
                <SvgHelper class="border_lt" fname="border_left_1" />
                <SvgHelper class="border_rb" fname="border_left_1" />
                <div class="labelText" style="font-size:16px;color:#fff700">{{siteInfo.infos.siteName}}</div>
                <div class="infoItem">经纬度:<div class="labelText">{{siteInfo.infos.lnglat[0]}},{{siteInfo.infos.lnglat[1]}}</div></div>
                <div class="labelText">{{siteInfo.infos.location}}</div>
                <div class="infoItem" v-if="siteInfo.infos.status == 0 || siteInfo.infos.status == 1">运行时长：<div class="labelText">{{siteInfo.infos.runtime}}</div>天</div>
                <div class="infoItem" v-show="siteInfo.infos.status == 0 || siteInfo.infos.status == 1 || siteInfo.infos.status == 2"><div style='width:16px;height:16px;border-radius:50%;margin:4px;' :style="siteInfo.infos.status == 0 ? 'background:#0fdf0f;color:#0fdf0f':siteInfo.infos.status == 1?'background:#ff9800;color:#ff9800':'background:#0fa7ffe8;color:#0fa7ffe8'"></div><div class="labelText">{{siteInfo.infos.status == 0 ? "正在运行...":siteInfo.infos.status == 1?"雷达安装调试":"已停用"}}</div></div>
                <div class="nav" @click="navTo(siteInfo.infos.link)" v-if="siteInfo.infos.status == 0 || siteInfo.infos.status == 1">点击前往</div>
            </div>
            <div class="light">
                <img class="marker" src="~@/assets/img/mark_2.png"/>
                <div class="lightCircle"></div>
            </div>            
        </div>

        <div ref="map" class="mainMap">
        </div>

        <!-- 统计信息 -->
        <!-- 左边 -->
        <div class="left_chart ">    
            <div class="bg"></div>            
                <div class="position_info chartbox">
                <SvgHelper class="cls-topborder" fname="左上边框"   />
                <SvgHelper class="cls-borderline" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <SvgHelper class="cls-borderBottomline" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <SvgHelper class="cls-borderleftline"  style="bottom: 0;height: 20%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <div class="m_info">
                    <SvgHelper class="bg_s" fname="六边形背景1"/>
                    <div class="infoItems bgcolor">
                    <div class="infoItem_line" style="display:flex;">
                        <v-icon class="m_icon">mdi-bank</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.provinceCount}}个</span><span class="signText">省</span></div>
                    </div>
                    <div class="infoItem_line" style="display:flex;">
                        <v-icon class="m_icon">mdi-city</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.cityCount}}个</span><span class="signText">市</span></div>
                    </div>
                    </div>
                    <div class="infoItems bgcolor">
                    <div class="infoItem_line">
                        <div class="item_text">
                        <v-icon class="m_icon">mdi-map-marker</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.siteCount}}个</span><span class="signText">雷达站点</span></div>
                        </div>                
                    </div>
                    <div class="infoItem_line">
                        <div class="item_text">
                        <v-icon class="m_icon">mdi-chart-pie</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.monitoringArea}}km²</span><span class="signText">监测面积</span></div>
                        </div>                
                    </div>
                    </div>
                    <div class="infoItems bgcolor">
                    <div class="infoItem_line">
                        <div class="item_text">
                        <v-icon class="m_icon">mdi-clipboard-file</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.caseCount}}个</span><span class="signText">案例</span></div>
                        </div>                
                    </div>
                    <div class="infoItem_line">
                        <div class="item_text">
                        <v-icon class="m_icon">mdi-file-chart</v-icon>
                        <div class="info_item_text"><span class="fontColor">{{infoData.reportCount}}个</span><span class="signText">报告</span></div>
                        </div>                
                    </div>
                    </div>
                </div>
                </div>

                <div class="device_info chartbox" >
                <SvgHelper class="cls-leftborder"  preserveAspectRatio="xMinYMin meet" fname="左边框"/>
                <SvgHelper class="cls-borderleftline"  style="top: 0;height: 10%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <SvgHelper class="cls-borderleftline"  style="top: 42%;height: 50%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <div class="d_info bgcolor">
                    <div class="device_title"><div>设备健康状况</div><div class="enfont">Device Health</div></div>
                    <div class="deviceInfoChart" ref="deviceInfo"></div>
                    <div class="deviceInfoList">
                    <div style="display:flex;padding-left:10px;color: #0ffff4e8 !important;justify-content: space-between;padding:0 10px;" v-for="(i,_i) in sitesList" :key="_i">
                        <span style="margin-right:8px;">{{i.name}}</span><span>已监测{{i.createTime}}天</span>
                    </div>
                    </div>
                </div>
                
                </div>


                <div class="article_info chartbox">
                <SvgHelper class="cls-borderline2" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <SvgHelper class="cls-bottomborder" fname="左上边框"   />
                <SvgHelper class="cls-borderleftline" style="top: 22%;height: 50%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                <div class="a_info bgcolor" >
                    <div class="device_title"><div>数据报告统计Top10</div><div class="enfont">Data Report Statistics</div></div>
                    <div class="drs_echart" ref="articleInfo"></div>
                </div>
                </div>
            </div> 
            <!-- 右边 -->
            <v-switch
                    title="显示图例"                   
                    v-model="showLegend"
                    class="openLegend"
                    dark
                    label="显示图例"
                    hide-details
                    dense
                ></v-switch>
            <v-switch
                    title="显示站点名" 
                    @change="loadMap"                   
                    v-model="openSiteName"
                    class="openSiteName"
                    dark
                    label="显示站点名"
                    hide-details
                    dense
                ></v-switch>
            <div class="right_chart ">    
                <v-card-actions class="winbtn justify-space-between">
                    <v-item-group
                        v-model="currentWindow"                        
                        class="text-center d-flex flex-column "
                        mandatory
                    >
                        <v-item
                        v-for="n in windowList"
                        :key="`btn-${n}`"
                        :value="n"
                        v-slot="{ active, toggle }"
                        >
                        <v-btn
                            :input-value="active"                        
                            icon
                            color="primary"
                            @click="toggle;currentWindow = n"
                        >
                            <v-icon>mdi-record</v-icon>
                        </v-btn>
                        </v-item>
                    </v-item-group>
                </v-card-actions>            
                <v-window
                    :value="currentWindow"
                    class="elevation-1 overshow"                    
                >           
                    <v-window-item class="overshow" value="pls">                
                        <SvgHelper class="bg_circle" fname="科技圈3" />
                        <div class="warn_radar chartbox">        
                            <SvgHelper class="cls-topborder" fname="右上边框"   />
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:48%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderrightline" style="top:30px;height:10%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                            <SvgHelper class="cls-borderleftline" style="top:30px;height:10%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderrightline" style="bottom:30px;height:10%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />   
                            <SvgHelper class="cls-borderleftline" style="top:70px;height:40%;right:0" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />             
                            <!-- <SvgHelper class="cls-border" style="right:-2px;width: 5px;height: 120px;top:30%;" fname="竖边框" preserveAspectRatio="xMinYMin slice" /> -->
                            <SvgHelper class="cls-borderBottomline" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                            <div class="warnEChart_box">
                            <div class="device_title"><div>24小时站点报警top5</div><div class="enfont">24 Hours Site Alarm Top</div></div>
                            <div class="warnEchart" ref="wEchart">            

                            </div>
                            </div>
                        </div>
                        <div class="warn_township chartbox">
                            <SvgHelper class="cls-borderlongline" style="top:20px;height:80%;"  fname="边框长" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:48%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <div class="warnEChart_box">
                            <div class="device_title"><div>24小时街道报警top5</div><div class="enfont">24 Hours Street Alarm Top</div></div>
                            <div class="warnEchart" ref="sEchart">
                            </div>
                            </div>
                        </div>
                        <div class="warn_warnmsg chartbox" >   
                            <div class="warnEChart_box"> 
                            <div class="reportTitle">最新报警</div>         
                            <div class="warnReport" ref="warnReport">
                                <transition-group @before-enter="beforeEnter" @enter="enter" name="list-complete" tag="div">
                                    <div class="warnItem" v-for="i,k in warnInfos" :key="i.id+k">{{i.msg}}</div>
                                </transition-group>
                            </div>
                            <SvgHelper class="circle_self" fname="自转"   />  
                            </div>  
                            <SvgHelper class="cls-circle-b" fname="科技圈"   />
                            <SvgHelper class="cls-btopborder" fname="边框上"   />
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:30%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:30%;right:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="bottom:10%;height:10%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="bottom:10%;height:10%;right:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-bbborder" fname="边框上"   />             
                        </div>                
                    </v-window-item>
                    <v-window-item class="overshow" value="vls">   
                        <SvgHelper class="bg_circle" fname="科技圈3" />
                        <div class="warn_radar chartbox">        
                            <SvgHelper class="cls-topborder" fname="右上边框"   />
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:48%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderrightline" style="top:30px;height:10%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                            <SvgHelper class="cls-borderleftline" style="top:30px;height:10%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderrightline" style="bottom:30px;height:10%;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />   
                            <SvgHelper class="cls-borderleftline" style="top:70px;height:40%;right:0" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />             
                            <!-- <SvgHelper class="cls-border" style="right:-2px;width: 5px;height: 120px;top:30%;" fname="竖边框" preserveAspectRatio="xMinYMin slice" /> -->
                            <SvgHelper class="cls-borderBottomline" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />
                            <div class="warnEChart_box">
                            <div class="device_title"><div>24小时能见度报警top5</div><div class="enfont">24 Hours Site Alarm Top</div></div>
                            <div class="warnEchart" ref="vlswEchart">            

                            </div>
                            </div>
                        </div>
                        <!-- <div class="warn_township chartbox">
                            <SvgHelper class="cls-borderlongline" style="top:20px;height:80%;"  fname="边框长" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:48%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <div class="warnEChart_box">
                            <div class="device_title"><div>24小时能见度浓雾报警top5</div><div class="enfont">24 Hours Street Alarm Top</div></div>
                            <div class="warnEchart" ref="vlssEchart">
                            </div>
                            </div>
                        </div> -->
                        <div class="warn_warnmsg chartbox" >   
                            <div class="warnEChart_box"> 
                            <div class="reportTitle">最新报警</div>         
                            <div class="warnReport" ref="warnReport">
                                <transition-group @before-enter="beforeEnter" @enter="enter" name="list-complete" tag="div">
                                    <div class="warnItem" v-for="(i,k) in vlsWarnInfo" :key="i.periodValue+k" :style="i.periodLevel < 3 ? 'color:#f4f917':'color:#ff9c31'">{{`${i.siteName}${i.time}时间，${i.sitePosition}发现${i.levelText}报警，最低角度${i.minAngle}，最低能见度${i.value}`}}</div>
                                </transition-group>
                            </div>
                            <SvgHelper class="circle_self" fname="自转"   />  
                            </div>  
                            <SvgHelper class="cls-circle-b" fname="科技圈"   />
                            <SvgHelper class="cls-btopborder" fname="边框上"   />
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:30%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="top:30%;height:30%;right:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="bottom:10%;height:10%;left:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-borderleftline" style="bottom:10%;height:10%;right:0;" viewbox="0 0 1 1" fname="左边框线" preserveAspectRatio="xMinYMin slice" />        
                            <SvgHelper class="cls-bbborder" fname="边框上"   />             
                        </div> 
                    </v-window-item>
                </v-window>
        </div>
    </v-container>
</template>
<script>
import * as echarts from "echarts"
import anime from "animejs"
import SvgHelper from '@/components/svg/SvgHelper.vue'
import "echarts-extension-amap"
import siteTypeWithRoute from '@/plugins/datas/siteTypeWithRoute'
import {jinanMaker,homemarkerActive,homemarker} from '@/plugins/datas/mapmark.js';
import {getReportCaseStatisticsApi,getRoadStatisticsApi,getSiteStatisticsApi,getAlarmPointStatisticsApi,getAlarmVlsStatisticsApi} from '@/api'
export default {
    name:"home.index",
    components: { SvgHelper },
    data(){
        return{
            currentWindow:"pls",
            alarmInfoList:[],
            vlsWarnInfo:[],
            map:null,
            echart:null,
            openSiteName:false,
            currentSite:'',
            setTimeoutMove:null,
            siteInfo:{
                x:0,
                y:0,
                infos:{
                    siteName:"",
                    location:"",
                    lnglat:[],
                    runtime:"",
                    link:"",
                    status:0
                }
            },
            infoData:{
                provinceCount: 0,
                cityCount:0,
                siteCount:0,
                monitoringArea:0,
                caseCount:0,
                reportCount:0
                },
            sitesList:[],
            warnInfos:[],
            currentMessageIndex:0,
            clearChartFun:null,
            timer:null,
            windowList:[],
            showLegend:true,
            siteTypeList:[]
        }
    },
    beforeDestroy(){
        if(this.timer){clearInterval(this.timer)}
        this.echart = echarts.getInstanceByDom(this.$refs.map);
        if(this.echart != null){                    
            this.echart.clear()
            this.echart = null;
        } 
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    mounted(){   
        var that = this;        
        this.windowList = this.$store.state.storage_user.siteTypes.filter(a=>{
                let sites = this.$store.state.storage_user.sites.filter(b=>{                                        
                    return b.type == a.code
                })
                if(sites.length > 0){
                    //权限判断
                    var permission =JSON.parse(this.$store.state.storage_user?.role?.permission) 
                    if(permission){                                
                        var clientMenu = permission?.menu?.client                       
                        if((a.code == '0' && clientMenu.find(b=>b.key == 'monitoring.pls')?.show)||
                        (a.code == '1' && clientMenu.find(b=>b.key == 'monitoring.pmls')?.show)||
                        (a.code == '2' && clientMenu.find(b=>b.key == 'monitoring.vls')?.show)||
                        (a.code == '3' && clientMenu.find(b=>b.key == 'monitoring.agt')?.show)||
                        (a.code == '4' && clientMenu.find(b=>b.key == 'monitoring.agt')?.show)){                           
                            return a && a.code != '3' && a.code != '4'
                        }
                    }                    
                }
            }).map(a=>{
            return siteTypeWithRoute.find(b=>b.type == a.code)?.name
        })
        this.siteTypeList = this.$store.state.storage_user.siteTypes.filter(a=>{
                let sites = this.$store.state.storage_user.sites.filter(b=>{                                        
                    return b.type == a.code
                })
                if(sites.length > 0){
                    //权限判断
                    var permission =JSON.parse(this.$store.state.storage_user?.role?.permission) 
                    if(permission){                                
                        var clientMenu = permission?.menu?.client                       
                        if((a.code == '0' && clientMenu.find(b=>b.key == 'monitoring.pls')?.show)||
                        (a.code == '1' && clientMenu.find(b=>b.key == 'monitoring.pmls')?.show)||
                        (a.code == '2' && clientMenu.find(b=>b.key == 'monitoring.vls')?.show)||
                        (a.code == '3' && clientMenu.find(b=>b.key == 'monitoring.agt')?.show)||
                        (a.code == '4' && clientMenu.find(b=>b.key == 'monitoring.wls')?.show)
                        ){                           
                            return a
                        }
                    }                    
                }
            }).map(a=>{
            return siteTypeWithRoute.find(b=>b.type == a.code)?.name
        })
        that.currentWindow =this.windowList[0]
        if(that.currentWindow == 'vls'){
            setTimeout(() => {
                that.loadVlsSiteChart();
            }, 200);            
        }
        let _index = 0
        if(this.windowList.length > 1){
            if(this.timer){
                clearInterval(this.timer)}
            this.timer = setInterval(() => {
                that.currentWindow = that.windowList[_index]
                if(that.currentWindow == 'vls'){
                    setTimeout(() => {
                        that.loadVlsSiteChart();
                    // that.loadVlsLevelSiteChart()
                    }, 100);                
                }
                _index= _index+1 == that.windowList.length ? 0:_index +1;
            }, 11000);
        }       
        var target = that.$refs.map
        let openSiteName = localStorage.getItem("openSiteName")        
        this.$nextTick(()=>{
            if(typeof(openSiteName)!='undefined'){                
                this.openSiteName =openSiteName == 'true';
            }    
            setTimeout(() => {
                that.load()
            }, 300);                    
        })   
        this.clearChartFun = () => {
            if (document.hidden) {
                // 清除      
                that.$nextTick(()=>{                
                    that.echart = echarts.getInstanceByDom(target);
                    if(that.echart != null){
                        that.echart.clear();                   
                    } 
                })     
                
            } else {
                // 开启 
                that.$nextTick(()=>{
                    that.echart = echarts.getInstanceByDom(target);
                    if(that.echart != null){
                        that.loadMap()                 
                    } 
                })                                 
            }
        };
        document.addEventListener("visibilitychange", this.clearChartFun);     
    },
    watch:{       
        "$store.state.storage_global.message":{
            handler(n){
                this.loadWarnning(n)
            },
        },
        openSiteName(n){
            localStorage.setItem("openSiteName",n)
        }
    },
    computed:{
        siteIcon(){
            return (type,active = true,stop = false)=>{
                if(type == 0 || type == 'pls'){
                    if(active && !stop){
                        return homemarkerActive.replaceAll('%23b3e0ff','%23ffc431b0').replaceAll('%2339f','%23ffc431')
                    }else if(!stop){
                        return homemarker.replaceAll('%233399ff54','%23ffc431b0').replaceAll('%2339f','%23ffc431');
                    }else{
                        return homemarker.replaceAll('%233399ff54','%29d7c2ab0').replaceAll('%2339f','%29d7c2ab0');
                    }
                }else if(type == 1 || type == 'pmls'){
                    if(active && !stop){
                        return homemarkerActive.replaceAll('%23b3e0ff','%23ffc431b0').replaceAll('%2339f','%23ffc431')
                    }else if(!stop){
                        return homemarker.replaceAll('%233399ff54','%23ffc431b0').replaceAll('%2339f','%23ffc431');
                    }else{
                        return homemarker.replaceAll('%233399ff54','%22278244f').replaceAll('%2339f','%24a7a166e');
                    }
                }else if(type == 2|| type == 'vls'){
                    if(active && !stop){
                        return homemarkerActive.replaceAll('%23b3e0ff','%2312cd5db3').replaceAll('%2339f','%2312cd5d');
                    }else if(!stop){
                        return homemarker.replaceAll('%233399ff54','%2312cd5db3').replaceAll('%2339f','%2312cd5d');
                    }else{
                        return homemarker.replaceAll('%233399ff54','%233216ead').replaceAll('%2339f','%21c137e5d');
                    }
                }else if(type == 3 || type == 'agt'){
                    if(active && !stop){
                        return homemarkerActive.replaceAll('%23b3e0ff','%231e41e9ba').replaceAll('%2339f','%231e41e9')
                    }else if(!stop){
                        return homemarker.replaceAll('%233399ff54','%231e41e9ba').replaceAll('%2339f','%231e41e9');
                    }else{
                        return homemarker.replaceAll('%233399ff54','%2121f5b8a').replaceAll('%2339f','%212155b61');
                    }
                }else if(type == 4 || type == 'wls'){
                    if(active && !stop){
                        return homemarkerActive
                    }else if(!stop){
                        return homemarker;
                    }else{
                        return homemarker.replaceAll('%233399ff54','%21d216475');
                    }
                }else if(type == 5){
                    if(active && !stop){
                        return homemarkerActive.replaceAll('%23b3e0ff','%23ffc431b0').replaceAll('%2339f','%23ffc431')
                    }else if(!stop){
                        return homemarker.replaceAll('%233399ff54','%23ffc431b0').replaceAll('%2339f','%23ffc431');
                    }else{
                        return homemarker.replaceAll('%233399ff54','%224822661').replaceAll('%2339f','%2216f2266');
                    }
                }
            }            
        }
    },    
    methods:{
        load(){            
            //加载地图
            var that = this;
             this.loadMap() 
             setTimeout(() => {
                that.loadDevice();  
             }, 200);
             setTimeout(() => {
                that.loadInfoBox();  
             }, 300); 
             setTimeout(() => {
                that.loadVlsAlarmInfo();
             }, 400);                                      
             this.loadReportStatistics();  
             this.loadRoadStatistics();
             this.loadSiteStatistics()              
             //加载消息报警      
             if(this.$store.state.storage_global.messageList.length == 0){
                getAlarmPointStatisticsApi({
                    success:(d)=>{
                        let datas = d.map(a=>{
                            return {
                                siteId:a.DapSiteId,                                                
                                date:new Date(a.DapTime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm:ss"),
                                level:a.DapLevel,
                                count:a.DapCount,    
                                lng:a.DapLng,
                                lat:a.DapLat,
                                position:{
                                    formatted_address:a.Formatted_Address
                                }                                                    
                            }
                        })
                        for(var msg of datas){
                            this.loadWarnning(msg)
                        }                        
                    }
                })
             }else{
                for(var msg of this.$store.state.storage_global.messageList){
                    this.loadWarnning(msg)
                }
             }             
        },
        loadMap(){        
            let mapTheme = "amap://styles/a62ba40ff87fc3fff3feee60b0050e00"//"amap://styles/c3f4ab22d35ff8a21b7066517911e48b"; 
            var that = this;            
            var companyInfo = this.$store.state.storage_user.user?.company;
            if(!companyInfo){
                return
            }            
            var sites = this.$store.state.storage_user.sites;
            let center = [companyInfo?.lng,companyInfo?.lat];           
            this.echart = echarts.getInstanceByDom(this.$refs.map);
            if(this.echart == null){
                this.echart = echarts.init(this.$refs.map);
            } 
            this.echart.clear();
            let pdata = sites.map((a,i)=>{                                
                return {  
                    siteId:a.siteId,                          
                    name:a.transStatus == 0&& a.type != 3 ? a.commitStatus == 0 && a.type != 3?"安装调试":"正常运行" : "服务结束",
                    coords:[[a.lng,a.lat],center],//路径点集合            
                    lineStyle:{
                        color: a.transStatus == 0 ? '#e4ff00' : '#b1b1b1',// '#07E8E4' : '#b1b1b1',
                    }
                }
            })
            let data_ok = pdata.filter(a=>{return a.name == "正常运行"});
            let data_outLine = pdata.filter(a=>{return a.name == "安装调试"}); 
            let data_stop = pdata.filter(a=>{return a.name == "服务结束"}); 
            let pointData = sites.map(a=>{
                let _status = a.transStatus == 0 ? a.commitStatus == 0?1:0:2 //0正常运行 1 安装调试 2停用 
                return {
                    name:a.siteName,
                    value:[a.lng,a.lat,_status,a.deploytime,a.localtion,a.siteId,a.type]
                }
            })     
            pointData.push({
                name:companyInfo?.localName,
                value:[center[0],center[1],-99,null,companyInfo?.localName]
            })     
            let mainShowSiteId = this.$store.state.storage_user.user.extendInfo?.mainShowSiteId                        
            //雷达地点标记          
            let series = [{
                name:'正常运行',
                type: 'lines',
                symbol:"circle",
                symbolSize: 8,
                coordinateSystem: 'amap',
                selectedMode:"single",
                //polyline: true,
                effect: {
                    symbol:"arrow",
                    show: true,
                    trailLength: 0.7,
                    color: '#fff',          //#2196f3a1
                    symbolSize: 8,
                    constantSpeed:100
                },
                data: data_ok,
                silent: true,
                lineStyle:{          
                    width:3,
                    curveness: 0.2 
                },   
                progressiveThreshold: 500,
                progressive: 200,
                silent:true
            },
            {
                name:'安装调试',
                type: 'lines',
                animation:false,
                coordinateSystem: 'amap',
                selectedMode:"single",   
                data: data_outLine,
                silent: true,      
                lineStyle:{
                   width:3,
                    curveness: 0.2     
                },
                silent:true,
                progressiveThreshold: 500,
                progressive: 200
            },
            {
                name:'服务结束',
                type: 'lines',
                animation:false,
                coordinateSystem: 'amap',
                selectedMode:"single",   
                data: data_stop,
                silent: true,      
                lineStyle:{
                    color: '#fff',
                    width:3,
                    opacity:0.2,
                    curveness: 0.2       
                },
                silent:true,
                progressiveThreshold: 500,
                progressive: 200
            },{
                type:"custom",
                coordinateSystem: "amap",
                data:pointData,
                animation:true,        
                renderItem:(params,api)=>{
                let status = api.value(2, params.dataIndex)
                let siteType = api.value(6, params.dataIndex)                    
                let marker = jinanMaker
                let siteName = pointData[params.dataIndex].name;                
                let animate = {};          
                if(status == 0){
                    marker = that.siteIcon(siteType,true)    
                }else if(status == 1 || status == 2){
                    marker = that.siteIcon(siteType,false)  
                }

                if(status == 0 || status == -99){            
                    animate = {
                    // 旋转
                    duration: 5000,
                    loop: true,              
                    keyframes: [
                    {
                        percent:1,
                        easing: 'linear',
                        rotation:30
                    }]
                    }
                }          
                const coord = api.coord([
                    api.value(0, params.dataIndex),
                    api.value(1, params.dataIndex)
                ]);
                if(that.currentSite == "" && status == 0 ){
                    if(mainShowSiteId){
                        console.log(api.value(5, params.dataIndex))
                        if(mainShowSiteId == api.value(5, params.dataIndex)){
                            that.currentSite = api.value(5, params.dataIndex)
                        }                        
                    }else{
                        that.currentSite = api.value(5, params.dataIndex)
                    }                    
                }
                if(that.currentSite!= "" && api.value(5, params.dataIndex) == that.currentSite){
                    let infos = {
                        siteName:siteName,
                        lnglat:[api.value(0, params.dataIndex),api.value(1, params.dataIndex)],
                        status:api.value(2, params.dataIndex),
                        runtime:!api.value(3, params.dataIndex)? "" : Math.floor((new Date()- new Date(api.value(3, params.dataIndex)))/ 1000 / 60 / 60 / 24),
                        location:api.value(4, params.dataIndex),
                        link:api.value(5, params.dataIndex)
                    }   
                    
                    let x = coord[0];//res.event.event.offsetX;
                    let y = coord[1];//res.event.event.offsetY;          
                    that.siteInfo ={
                        x:x,
                        y:y,
                        infos:infos
                    }  
                    that.moveTo(x,y)         
                }
                let style = api.style()
                style.image = marker;
                style.x = -10;
                style.y = -10; 
                style.width = 20;
                style.height = 20;
                let nStyle = api.style();
                nStyle.image = marker;
                nStyle.x = -13;
                nStyle.y = -13; 
                nStyle.width= 26;          
                nStyle.height=26;
                nStyle.text = siteName
                let _siteName =that.openSiteName? siteName:'';
                return {                    
                    type: 'image',
                    x: coord[0],
                    y: coord[1],
                    style:style,
                    transition: 'style',
                    silent:false,  
                    textContent:{
                    silent:true,
                    style:{
                        text:_siteName,
                        font:'italic bolder 14px "Microsoft YaHei", sans-serif',
                        fill:"#0ff9e3",
                        stroke:"#fff"
                    }
                    },     
                    keyframeAnimation: [
                        animate                 
                    ]
                }
                },        
                labelLayout:(params)=>{                 
                return{
                    x: params.rect.x + 20,
                    y: params.rect.y + params.rect.height / 2 -10,
                    verticalAlign: 'middle',
                    align: 'left',
                    silent:true
                }
                }
                
            },           
            ]
        let option = {
            // 加载 amap 组件
            amap: {
                // 高德地图中心经纬度
                center:center,
                rotateEnable:true,
                pitchEnable:true,   
                isHotspot:false,             
                pitch: 0,
                rotation: 0,//-55
                zoom:5,//级别
                zooms:[2,30],
                viewMode:'3D',//使用3D视图                        
                // 启用resize
                resizeEnable: true,
                // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                renderOnMoving: false,
                echartsLayerInteractive: true,
                largeMode: false,
                returnMapCameraState: true,
                // 自定义地图样式主题
                //53dd45a35fd6d89a2303f51cad6cce14
                mapStyle:mapTheme
            },                     
            series:series         
        }        
        this.echart.setOption(option);
        var map = this.echart.getModel().getComponent('amap').getAMap();
        this.map = map;   
        //自适应大小
        this.fitView(center,sites)            
        //添加监听
        this.echart.on("mouseover",function(res){ 
            
            that.currentSite = res.data.value[5];        
            let infos = {
                siteName:res.data.name,
                lnglat:[res.data.value[0],res.data.value[1]],
                status:res.data.value[2],
                runtime:!res.data.value[3]? "" : Math.floor((new Date()- new Date(res.data.value[3]))/ 1000 / 60 / 60 / 24),
                location:res.data.value[4],
                link:res.data.value[5]
            }
            res.event.stop();  
            let x = res.event.target.x;//res.event.event.offsetX;
            let y = res.event.target.y;//res.event.event.offsetY;          
            that.siteInfo ={
                x:x,
                y:y,
                infos:infos
            }   
            that.moveTo(x,y)                   
        })

        },
        navTo(link){
            var that =this;
            this.$store.dispatch("storage_global/updateCurrentSiteById",
            {
                data:link,
                callback:()=>{
                    that.$router.push({
                        path:"/m/monitoring/pls/realtime"
                    })
                }
            }
            ,{root:true})            
        },
        fitView(center,sites){
            let ctp = new AMap.Marker({
                position: center,
                content:" ",  //markerhelp.jinanMaker,
                offset:new AMap.Pixel(0,2),
                anchor:"center"
                //icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',          
            })
            let fit = []
            this.map.add(ctp);
            fit.push(ctp)

            sites.forEach(a=>{                
                let content = " "; // a.status == 0 ? markerhelp.homemarkerActive:markerhelp.homemarker;
                let m = new AMap.Marker({
                position: new AMap.LngLat(a.lng,a.lat),
                content: content,
                offset:new AMap.Pixel(0,2),
                anchor:"center"
                //icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',          
                })               
                this.map.add(m)
                if(a.transStatus == 0){
                    fit.push(m)
                }
            })          
            setTimeout(() => {
                this.map.setFitView(fit,false,[160,30,278,278])    
            }, 500);  
            
        },
       //行政区边界获取
        districtSearch(district,level){
        var that = this;
        var opts = {
            subdistrict: 0,   //返回下一级行政区 否
            extensions: 'all',//返回具体信息
            level:level,
            showbiz:false  //最后一级返回街道信息
        };
        if(!this.district || !this.district.search){
            this.district = new AMap.DistrictSearch(opts);
        }
        this.district.search(district,function(status, result) {
            if(that.polygons.length > 0){
                that.map.remove(that.polygons)//清除上次结果
            }
            
            that.polygons = [];
            var bounds = result.districtList[0].boundaries;
            if (bounds) {
                for (var i = 0, l = bounds.length; i < l; i++) {
                    //生成行政区划polygon
                    var polygon = new AMap.Polygon({
                        strokeWeight: 4,
                        path: bounds[i],
                        fillOpacity: 0.01,
                        fillColor: '#80d8ff',
                        strokeColor: '#0091ea'
                    });
                    that.polygons.push(polygon);
                }
            }
            that.map.add(that.polygons)        
        });
        },
       //移动
        moveTo(x,y){
            var that = this;
            if(this.setTimeoutMove!=null){
                clearTimeout(this.setTimeoutMove)
                this.setTimeoutMove = null;
            }
            this.setTimeoutMove = setTimeout(() => {
                let target = that.$refs.radarInfoBox;                                          
                anime({
                    targets: target,
                    left:x - target.offsetWidth/2,
                    top:y - target.offsetHeight-10,                
                    easing: 'easeInOutQuad',
                    duration:500,
                });    
            }, 100);
            
        },
        //左边   
        loadInfoBox(){
            let sites = this.$store.state.storage_user.sites; 
            let city = [];
            let province = []
            let msCount = 0;
            this.infoData.siteCount = 0;
            sites.forEach(a=>{                          
                if(a.lidarInfo){
                    this.infoData.siteCount +=1;
                    if(a.type != 3){
                        msCount += 1;                    
                    }
                    if(city.indexOf(a.city) < 0){
                        city.push(a.city)
                    }
                    if(province.indexOf(a.province) < 0){
                        province.push(a.province)
                    }
                }
            })      
            this.infoData.cityCount = city.length;
            this.infoData.provinceCount = province.length;            
            this.infoData.monitoringArea = (msCount * Math.PI * 36).toFixed(2);                             

        },     
        //加载统计数据
        loadDevice(){
            var chartDom = this.$refs.deviceInfo   
            let myChart =  echarts.getInstanceByDom(chartDom);
            if(myChart == null){
                myChart = echarts.init(chartDom);
            }                
            let data = [{
                name:"正常运行",
                value:0,
                label:{
                    color:"#00c853"
                },
                itemStyle:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#00c853' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#00c85300' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                    }
                }
            },{
                name:"安装调试",
                value:0,
                label:{
                    color:"#ff9800"
                },
                itemStyle:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#ff9800' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#78726400' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                    }
                }
            },
            {
                name:"服务结束",
                value:0,
                label:{
                    color:"#0fa7ffe8"
                },
                itemStyle:{
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: "#0fa7ff"// 0% 处的颜色
                    }, {
                        offset: 1, color: "#0fa7ffe8"// 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                    }
                }
            }      
            ]            
            var sites = this.$store.state.storage_user.sites.filter(a=>{
                return a.lidarInfo != null
            });
            this.sitesList = sites.map(a=>{                                
                if((a.transStatus == 0 && a.commitStatus == 1) || a.type ==3){
                    data[0].value +=1;
                }                               
                else if(a.transStatus == 0 && a.commitStatus == 0 ){
                    data[1].value +=1;
                }
                else{
                    data[2].value +=1;
                }
                return {
                name:a.siteName,
                status:a.transStatus,
                createTime: Math.floor((new Date()- new Date(a.deploytime.replace("T"," ")))/ 1000 / 60 / 60 / 24)
                }
            })
                    
            var option = {
                title: {
                //text: '设备运行详情',
                left: 'center',
                textStyle: {
                    color: '#999',
                    fontWeight: 'normal',
                    fontSize: 14
                }
                },
                tooltip: {},
            //   grid: {
            //     top:'1%',
            //     left:'1%',
            //     right:'1%',
            //     bottom:'1%'

            // },
                series: [{
                type: 'pie',
                    radius: [20, 60],
                    //top: top + '%',
                    //height: '33.33%',
                    left: 'center',
                    width: '100%',
                    itemStyle: {
                    //borderColor: '#fff',
                    //borderWidth: 1
                    },
                    label: {
                    alignTo: 'edge',
                    formatter: '{name|{b} {c}}',
                    minMargin: 25,
                    edgeDistance: 25,
                    lineHeight: 15,
                    fontSize:12,
                    color: '#fff',
                    textBorderWidth:0,
                    rich: {                
                        time: {
                        fontSize: 12,
                        //color: '#999'
                        }
                    }
                    },
                    labelLine: {
                    length: 2,
                    length2: 3,
                    maxSurfaceAngle: 80
                    },
                    // labelLayout: function (params) {              
                    //   const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                    //   const points = params.labelLinePoints;
                    //   // Update the end point.
                    //   points[2][0] = isLeft
                    //     ? params.labelRect.x
                    //     : params.labelRect.x + params.labelRect.width;
                    //   return {
                    //     labelLinePoints: points
                    //   };
                    // },
                    data: data
                }]
            };
            myChart.setOption(option)            
        },
        async loadReportStatistics(){            
            var that = this;
            getReportCaseStatisticsApi({
                success:(d)=>{                    
                    that.infoData.caseCount = d.UserCaseCount;
                    that.infoData.reportCount = d.UserReportCount;
                    //echart
                    let xAxis = [];
                    let series0 = [];//报告数值
                    let series1 = [];//案例数值
                    let reportCaseCount = [];
                    let sites = that.$store.state.storage_user.sites.filter(a=>{
                        return a.type != 3
                    })
                    for(var i of sites){
                       // xAxis.push(i.siteName.replace("站点","").split("").join('\n'))                                                
                        let _d0 = d.SiteReport.find(a=>a.SiteId == i.siteId);
                        let _d1 = d.SiteCase.find(a=>a.SiteId == i.siteId);
                        let _v0 = _d0 ? _d0.Count : 0;
                        let _v1 = _d1 ? _d1.Count : 0;
                        if(_v0 + _v1 != 0){
                            reportCaseCount.push({
                                siteId:i.siteId,
                                siteName:i.siteName,
                                v0:_v0,
                                v1:_v1                            
                            })
                        }
                        //series0.push(_v0)
                        //series1.push(_v1)
                    }
                    let list = reportCaseCount.sort((a,b)=>{
                        return (b.v0 + b.v1) - (a.v0 + a.v1)
                    }).slice(0,10)
                    list.forEach(a=>{
                        xAxis.push(a.siteName.replace("站点","").split("").join('\n'))                                                
                        series0.push(a.v0)
                        series1.push(a.v1)
                    })

                    var option = {
                        title: {
                            //text: 'Rainfall vs Evaporation',
                            //subtext: '案例 报告'
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['案例', '报告'], 
                            textStyle:{
                            color:"#fff"
                            }   
                        },
                        toolbox: {
                            show: false,
                            feature: {
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['line', 'bar'] },
                            restore: { show: true },
                            saveAsImage: { show: true }
                            }
                        },
                        calculable: false,
                        xAxis: [
                            {
                            name:"站点",
                            type: 'category',
                            // prettier-ignore
                            data: xAxis,
                            nameLocation:"center",
                            nameTextStyle:{
                                color:"#fff"
                            },
                            position:"bottom",
                            z: 10,
                            axisLabel: { 
                                interval:0,
                                //rotate:-15,
                                //width:40,
                                overflow:"break",
                                verticalAlign:"bottom",
                                align:"top",  
                                hideOverlap:false,                    
                                textStyle: { 
                                color: '#fff',//字体颜色
                                fontSize: 12 //字体大小
                                }
                            },
                                    
                            }
                        ],
                        yAxis: [
                            {
                            type: 'value',
                            nameTextStyle:{
                                color:"#fff"
                            },
                            axisLabel: { 
                                align:"center",  
                                hideOverlap:false,                    
                                textStyle: { 
                                color: '#fff',//字体颜色
                                fontSize: 12 //字体大小
                                }
                            },
                            }
                        ],          
                        dataZoom: [
                            {
                            type: 'inside'
                        }],
                        grid: {
                            top:'15%',
                            left:'10%',
                            right:'1%',
                            bottom:"10%"
                        },
                        series: [
                            {
                            name: '报告',
                            type: 'bar',
                            data: series0,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#188df0' },
                                { offset: 1, color: '#188df0' }
                                ])
                            },
                            emphasis: {
                                itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#2378f7' },
                                    { offset: 0.7, color: '#2378f7' },
                                    { offset: 1, color: '#83bff6' }
                                ])
                                }
                            },
                            // markPoint: {
                            //   data: [
                            //     { type: 'max', name: 'Max' },
                            //     { type: 'min', name: 'Min' }
                            //   ]
                            // },
                            // markLine: {
                            //   data: [{ type: 'average', name: 'Avg' }]
                            // }
                            },
                            {
                            name: '案例',
                            type: 'bar',
                            data:series1,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#36af91' },
                                { offset: 1, color: '#36af91' }
                                ])
                            },
                            emphasis: {
                                itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#2378f7' },
                                    { offset: 0.7, color: '#2378f7' },
                                    { offset: 1, color: '#83bff6' }
                                ])
                                }
                            },
                            // markPoint: {
                            //   data: [
                            //     { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
                            //     { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
                            //   ]
                            // },
                            // markLine: {
                            //   data: [{ type: 'average', name: 'Avg' }]
                            // }
                            }
                        ]
                        };
                        var chartDom = that.$refs.articleInfo 
                        let myChart =  echarts.getInstanceByDom(chartDom);
                        if(myChart == null){
                        myChart = echarts.init(chartDom);
                        }          
                        myChart.setOption(option)
                }
            })
        },
        async loadRoadStatistics(){
            var that = this;
            getRoadStatisticsApi({
                date:$help.getDateByDay(-1).pattern("yyyy-MM-dd"), //new Date().setDate(new Date().getDate() -1)
                success:(d)=>{
                    let echart =  echarts.getInstanceByDom(that.$refs.sEchart);
                    if(echart == null){
                        echart = echarts.init(that.$refs.sEchart);
                    } 
                    let option = {
                        // legend: {
                        //   textStyle:{
                        //     color:"#fff",
                        //     fontSize:"10px"
                        //   } 

                        // },
                        tooltip: {},
                        grid: {
                            top:'5%',
                            left:'2%',
                            right:'5%',
                            bottom:'10%'
                        },
                        dataZoom: [
                            {
                                type: 'inside'
                            }
                            ],
                            visualMap: [{
                            show:false,
                            //type:"piecewise",
                            //orient: 'horizontal',
                            //left: 'center',
                            min: 30,
                            max: 500,
                            //text: ['High Score', 'Low Score'],
                            // Map the score column to color
                            dimension: '报警次数',
                            inRange: {
                                color: ['#65B581', '#FFCE34', '#FD665F']
                            },
                            seriesIndex:0
                            },{
                            show:false,
                            //type:"piecewise",
                            //orient: 'horizontal',
                            //left: 'center',
                            min: 30,
                            max: 500,
                            //text: ['High Score', 'Low Score'],
                            // Map the score column to color
                            dimension: '报警点数量',
                            inRange: {
                                color: ['#65B500', '#FFCE34', '#FD665F']
                            },
                            seriesIndex:1
                            },
                            ],
                        dataset: [{
                            // 提供一份数据。
                            dimensions: ['name',  '报警次数', '报警点数量'],
                            source: [
                            // { name: 'a街道',   '报警次数': 85.8, '报警点数量': 93.7 },
                            // { name: 'a街道2',  '报警次数': 73.4, '报警点数量': 55.1 },
                            // { name: 'a街道3',  '报警次数': 65.2, '报警点数量': 82.5 },
                            // { name: 'a街道4',  '报警次数': 53.9, '报警点数量': 39.1 }
                            ]                                    
                        },{
                            transform: {
                            type: 'sort',
                            config: { dimension: '报警次数', order: 'asc' }
                            }
                        }
                        ],
                        // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
                        xAxis: {
                            axisLabel:{
                            textStyle: { 
                                color: 'rgba(255,255,255,0.6)',//字体颜色
                                fontSize: 12 //字体大小
                            }, 
                            }
                        },
                        // 声明一个 Y 轴，数值轴。
                        yAxis: { 
                            type: 'category',
                            zleve:2,
                            z:5, 
                            position:"right", 
                            axisLabel:{                                  
                            margin:1,                 
                            align:"right",
                            overflow:"break",  
                            hideOverlap:false,
                            color: '#fff',
                            interval:0,
                            textStyle: { 
                                color: '#fff',//字体颜色
                                fontSize: 12 //字体大小
                            }, 
                        },
                        },
                        // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
                        series: [{
                            type: 'bar',
                            datasetIndex: 1,
                            label:{
                                show:true, 
                                textStyle: { 
                                color: 'rgba(255,255,255,0.6)',//字体颜色
                                fontSize: 12 //字体大小
                                }, 
                                //align:"left",
                                position:"right"
                            }
                            },
                        { 
                            type: 'bar',
                            datasetIndex: 1 
                        }]
                    };
                    let data = d.map(a=>{
                        return {
                            name:`${a.SraDistrict+a.SraRoad}`,
                            "报警点数量":a.SraPositionCount,
                            "报警次数":a.SraAlarmCount,
                            siteId:a.SraSiteId
                        }
                    })
                     option.dataset[0].source = data;
                     echart.setOption(option)            
                }
            })
        },
        async loadSiteStatistics(){
            var that = this;
            getSiteStatisticsApi({
                date:$help.getDateByDay(-1).pattern("yyyy-MM-dd"), //new Date().setDate(new Date().getDate() -1)
                success:(d)=>{                    
                    let echart = echarts.getInstanceByDom(that.$refs.wEchart);
                    if(echart == null){
                        echart = echarts.init(that.$refs.wEchart);
                    }        
                    let option = {
                        legend: {
                            data: ['报警点数量', '报警次数'], 
                            textStyle:{
                            color:"#fff",
                            fontSize:"10px"
                            }   
                        },
                        tooltip: {},
                        xAxis: {
                            type:"category",
                            name:"站点",
                            //data: xaxisLabel,
                            nameLocation:"center",
                            nameTextStyle:{
                            color:"#fff"
                            },
                            position:"bottom",                
                            axisLabel: {
                            //inside: true,
                            margin:0,
                            color: '#fff',
                            interval:0,
                            align:"top",
                            //rotate:-15,
                            //width:40,
                            overflow:"break",
                            verticalAlign:"bottom",
                            // align:"center",  
                            hideOverlap:false,                    
                            textStyle: { 
                                color: '#fff',//字体颜色
                                fontSize: 12 //字体大小
                            },
                            formatter:(a)=>{
                                return a.replace("站点","").split("").join("\n")
                            }
                            },
                        axisTick: {
                            //show: false
                        },
                        axisLine: {
                            show: false
                        },
                        z: 10
                        },
                        yAxis: {
                        type: 'value',                               
                        axisLabel:{   
                            margin:1,                 
                            align:"right",
                            overflow:"break",  
                            //hideOverlap:false,
                            color: '#fff',
                            interval:0,
                            textStyle: { 
                            color: '#fff',//字体颜色
                            fontSize: 12 //字体大小
                            },
                            formatter: function (value, index) {                
                            if(value >= 1000 && value <10000 ){
                                value = value / 1000 + '千'
                            }
                            else if (value >= 10000 && value < 10000000) {
                                value = value / 10000 + "万";
                            } else if (value >= 10000000) {
                                value = value / 10000000 + "千万";
                            }
                            return value;
                        }
                        }
                        },
                        grid: {
                        top:'15%',
                        left:'13%',
                        right:'1%',
                        bottom:'12%'
                    },
                        dataZoom: [
                        {
                            type: 'inside'
                        }
                        ],
                        dataset:[{
                        // 提供一份数据。
                        dimensions: ['name',  '报警次数', '报警点数量'],
                        source: [
                            // { name: 'a街道',   '报警次数': 85.8, '报警点数量': 93.7 },
                            // { name: 'a街道2',  '报警次数': 73.4, '报警点数量': 55.1 },
                            // { name: 'a街道3',  '报警次数': 65.2, '报警点数量': 82.5 },
                            // { name: 'a街道4',  '报警次数': 53.9, '报警点数量': 39.1 }
                        ]                                    
                        },{
                        transform: {
                            type: 'sort',
                            config: { dimension: '报警次数', order: 'desc' }
                        }
                        }
                        ],
                        series: [
                        {               
                            type: 'bar',
                            showBackground: true,
                            
                            itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#df9d0a' },
                                { offset: 1, color: '#df9d0a' }
                            ])
                            },
                            emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#fd7963' },
                                { offset: 0.7, color: '#fd7963' },
                                { offset: 1, color: '#83bff6' }
                                ])
                            }
                            },
                            datasetIndex: 1,
                        },
                        {            
                            type: 'bar',
                            showBackground: true,
                            itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#FFCE34' },
                                { offset: 1, color: '#FFCE34' }
                            ])
                            },
                            emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#2378f7' },
                                { offset: 0.7, color: '#2378f7' },
                                { offset: 1, color: '#83bff6' }
                                ])
                            }
                            },
                            datasetIndex: 1,
                        },
                        ]
                    }
                    let data = [];      
                    let sites = that.$store.state.storage_user.sites;
                    for(var i of d){
                        let siteid = i.SsaSiteId;
                        let _site = sites.find(a=>{
                            return a.siteId == siteid;
                        })
                        if(_site){
                            data.push({
                                name:_site.siteName,
                                "报警点数量":i.SsaPositionCount,
                                "报警次数":i.SsaAlarmCount
                            })   
                        }
                    }
                    option.dataset[0].source =data.sort((a,b)=>{
                        return b["报警次数"] - a["报警次数"]
                    }).slice(0,5);
                    echart.setOption(option)
                }
            })
        },
        loadWarnning(data){
            let that = this;            
            setTimeout(() => {   
                let warnText= this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls ?this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls: '污染物'             
                that.warnInfos.unshift({
                    id:$help.guid(),
                    lng:data.lng,
                    lat:data.lat,
                    date:data.date,
                    msg:`${data.position.formatted_address}发现${warnText}报警${data.count}次`
                })
                that.warnInfos = that.warnInfos.slice(0,10); 
                that.currentMessageIndex -= 1;
            }, this.currentMessageIndex * 2000)          
            this.currentMessageIndex +=1;
        },
        loadVlsAlarmInfo(){
            var that = this;
            let levelConfig = {
                0:"",
                1:"轻雾",
                2:"大雾",
                3:"浓雾",
                4:"强浓雾",
                5:"特强浓雾"
            }
            getAlarmVlsStatisticsApi({
                date:$help.getDateByDay(0).pattern("yyyy-MM-dd"), //new Date().setDate(new Date().getDate() -1)
                success:(d)=>{
                    that.alarmInfoList = d.AlarmInfoList;
                    let sites = this.$store.state.storage_user.sites;                                                        
                    that.vlsWarnInfo = d.LatestAlarm.filter(a=>a.DavLevel > -1).map(a=>{
                        let siteid = a.DavSiteId;
                        let _site = sites.find(a=>{
                            return a.siteId == siteid;
                        })
                        return {
                            siteId:a.DavSiteId,
                            siteName:_site.siteName,
                            sitePosition:_site.localtion,
                            level:a.DavLevel,
                            value:a.DavValue,
                            periodLevel:a.DavPeriodLevel,
                            levelText:levelConfig[a.DavPeriodLevel],
                            periodValue:a.DavPeriodValue,
                            time:new Date(a.DavTime.replace("T"," ")).pattern("MM月dd日 HH:mm:ss"),
                            minAngle:a.DavScanMode == 0 ? a.DavStraight:a.DavPitch
                        }
                    })  
                                      
                }
            })
        },
        loadVlsSiteChart(){            
            let echart = echarts.getInstanceByDom(this.$refs.vlswEchart);            
            if(echart == null){
                echart = echarts.init(this.$refs.vlswEchart);
            }        
            let option = {
                legend: {
                    data: ['报警数量'], 
                    textStyle:{
                    color:"#fff",
                    fontSize:"10px"
                    }   
                },
                tooltip: {},
                xAxis: {
                    type:"category",
                    name:"站点",
                    //data: xaxisLabel,
                    nameLocation:"center",
                    nameTextStyle:{
                    color:"#fff"
                    },
                    position:"bottom",                
                    axisLabel: {
                    //inside: true,
                    margin:0,
                    color: '#fff',
                    interval:0,
                    align:"top",
                    //rotate:-15,
                    //width:40,
                    overflow:"break",
                    verticalAlign:"bottom",
                    // align:"center",  
                    hideOverlap:false,                    
                    textStyle: { 
                        color: '#fff',//字体颜色
                        fontSize: 12 //字体大小
                    },
                    formatter:(a)=>{
                        return a.replace("站点","").split("").join("\n")
                    }
                    },
                axisTick: {
                    //show: false
                },
                axisLine: {
                    show: false
                },
                z: 10
                },
                yAxis: {
                type: 'value',                               
                axisLabel:{   
                    margin:1,                 
                    align:"right",
                    overflow:"break",  
                    //hideOverlap:false,
                    color: '#fff',
                    interval:0,
                    textStyle: { 
                    color: '#fff',//字体颜色
                    fontSize: 12 //字体大小
                    },
                    formatter: function (value, index) {                
                    if(value >= 1000 && value <10000 ){
                        value = value / 1000 + '千'
                    }
                    else if (value >= 10000 && value < 10000000) {
                        value = value / 10000 + "万";
                    } else if (value >= 10000000) {
                        value = value / 10000000 + "千万";
                    }
                    return value;
                }
                }
                },
                grid: {
                top:'15%',
                left:'13%',
                right:'1%',
                bottom:'12%'
            },
                dataZoom: [
                {
                    type: 'inside'
                }
                ],
                dataset:[{
                // 提供一份数据。
                dimensions: ['name',  '报警数量'],
                source: [
                    // { name: 'a街道',   '报警次数': 85.8, '报警点数量': 93.7 },
                    // { name: 'a街道2',  '报警次数': 73.4, '报警点数量': 55.1 },
                    // { name: 'a街道3',  '报警次数': 65.2, '报警点数量': 82.5 },
                    // { name: 'a街道4',  '报警次数': 53.9, '报警点数量': 39.1 }
                ]                                    
                },{
                transform: {
                    type: 'sort',
                    config: { dimension: '报警数量', order: 'desc' }
                }
                }
                ],
                series: [
                {               
                    type: 'bar',
                    showBackground: true,
                    
                    itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#83bff6' },
                        { offset: 0.5, color: '#df9d0a' },
                        { offset: 1, color: '#df9d0a' }
                    ])
                    },
                    emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#fd7963' },
                        { offset: 0.7, color: '#fd7963' },
                        { offset: 1, color: '#83bff6' }
                        ])
                    }
                    },
                    datasetIndex: 1,
                }                
                ]
            }
            let data = [];      
            let sites = this.$store.state.storage_user.sites;    
            for(var i of this.alarmInfoList){                
                let siteid = i.SiteId;
                let _site = sites.find(a=>{
                    return a.siteId == siteid;
                })
                if(_site){
                    data.push({
                        name:_site.siteName,
                        "报警数量":i.Count,
                    })   
                }
            }
            option.dataset[0].source =data.sort((a,b)=>{
                return b["报警数量"] - a["报警数量"]
            }).slice(0,5);
            echart.setOption(option)
            
        },
        loadVlsLevelSiteChart(){
            let echart =  echarts.getInstanceByDom(this.$refs.vlssEchart);
            if(echart == null){
                echart = echarts.init(this.$refs.vlssEchart);
            } 
            let option = {
                // legend: {
                //   textStyle:{
                //     color:"#fff",
                //     fontSize:"10px"
                //   } 

                // },
                tooltip: {},
                grid: {
                    top:'5%',
                    left:'2%',
                    right:'5%',
                    bottom:'10%'
                },
                dataZoom: [
                    {
                        type: 'inside'
                    }
                    ],
                    visualMap: [{
                    show:false,
                    //type:"piecewise",
                    //orient: 'horizontal',
                    //left: 'center',
                    min: 30,
                    max: 500,
                    //text: ['High Score', 'Low Score'],
                    // Map the score column to color
                    dimension: '浓雾以下报警数量',
                    inRange: {
                        color: ['#65B581', '#FFCE34', '#FD665F']
                    },
                    seriesIndex:0
                    },{
                    show:false,
                    //type:"piecewise",
                    //orient: 'horizontal',
                    //left: 'center',
                    min: 30,
                    max: 500,
                    //text: ['High Score', 'Low Score'],
                    // Map the score column to color
                    dimension: '浓雾以上报警数量',
                    inRange: {
                        color: ['#65B500', '#FFCE34', '#FD665F']
                    }, 
                    seriesIndex:1
                    },
                    ],
                dataset: [{
                    // 提供一份数据。
                    dimensions: ['name',  '浓雾以下报警数量', '浓雾以上报警数量'],
                    source: [
                    // { name: 'a街道',   '报警次数': 85.8, '报警点数量': 93.7 },
                    // { name: 'a街道2',  '报警次数': 73.4, '报警点数量': 55.1 },
                    // { name: 'a街道3',  '报警次数': 65.2, '报警点数量': 82.5 },
                    // { name: 'a街道4',  '报警次数': 53.9, '报警点数量': 39.1 }
                    ]                                    
                },{
                    transform: {
                    type: 'sort',
                    config: { dimension: '浓雾以上报警数量', order: 'asc' }
                    }
                }
                ],
                // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
                xAxis: {
                    axisLabel:{
                    textStyle: { 
                        color: 'rgba(255,255,255,0.6)',//字体颜色
                        fontSize: 12 //字体大小
                    }, 
                    }
                },
                // 声明一个 Y 轴，数值轴。
                yAxis: { 
                    type: 'category',
                    zleve:2,
                    z:5, 
                    position:"right", 
                    axisLabel:{                                  
                    margin:1,                 
                    align:"right",
                    overflow:"break",  
                    hideOverlap:false,
                    color: '#fff',
                    interval:0,
                    textStyle: { 
                        color: '#fff',//字体颜色
                        fontSize: 12 //字体大小
                    }, 
                },
                },
                // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
                series: [{
                    type: 'bar',
                    datasetIndex: 1,
                    label:{
                        show:true, 
                        textStyle: { 
                        color: 'rgba(255,255,255,0.6)',//字体颜色
                        fontSize: 12 //字体大小
                        }, 
                        //align:"left",
                        position:"right"
                    }
                    },
                { 
                    type: 'bar',
                    datasetIndex: 1 
                }]
            };
            let sites = this.$store.state.storage_user.sites; 
            let data = this.alarmInfoList.map(a=>{
                let siteid = a.SiteId;
                let _site = sites.find(a=>{
                    return a.siteId == siteid;
                })
                return {
                    name:_site.siteName,
                    "浓雾以下报警数量":a.LightFog,
                    "浓雾以上报警数量":a.ThickFog,
                    siteId:siteid
                }
            })
                option.dataset[0].source = data;
                echart.setOption(option) 
        },
        enter(el){
            anime({
                targets: [el],
                opacity:[0,1],
                easing: 'easeInOutQuad',
                duration:800,            
            });
        },
        beforeEnter(el){
            el.style.opacity = 0;
        },
        //后台时停止echart
        vchange(){

        }
    }
}
</script>
<style scoped lang="scss">
.mainMap{
    width: 100%;
    height: 100%;
}

.radarInfoBox{
    position:absolute;
    z-index:100;
    display: flex;
    width: fit-content; 
    flex-direction: column;    
    .radarInfo{        
        font-size: 12px;
        display: flex;
        flex-direction: column;
        background: rgba(17,149,209,0.15);
        //background-image: url(../../assets/infobg.jpg) no-repeat 100% 100%;
        color: #10e9e1;
        width: fit-content;        
        padding: 5px;
        //box-shadow: 2px -2px 1px #00f3ff, 0px -2px 4px #00f3ff, 0px -5px 13px 1px #00f3ff;
        .labelText{
            display: flex;
            flex-wrap: wrap;
            white-space: pre-wrap;
            max-width: 200px;
            width: fit-content;
        }
        .bgImg{
            position: absolute;
            opacity:0.5;
            width: 100%;
            height: 100%;
            z-index:-1;
        }
        .border_lt{
            position: absolute;
            height: 20px;
            width: 20px;
            top: -5px;
            left: -5px;
        }
        .border_rb{
            position: absolute;
            height: 20px;
            width: 20px;
            bottom: 22px;
            right: -5px;
            transform: rotate(180deg)
        }
        .nav{
            color:#eaff3f;
            cursor:pointer;
            text-align:right;
        }
        .nav:hover{
            color:#ffa93f;
        }
    }
    .light{
        width: 100%;
        display: flex;
        justify-content: center
    }
    .marker{
        height: 28px;    
        filter: drop-shadow(0px 12px 10px #10e9e1);  
    }
    .infoItem{
        display: flex;
        flex-wrap: nowrap;
    }

}

//左边
.bg_s{
    top:10px;
    width: 100%;
    right: 0;
    z-index: -1;
    position:absolute;
    opacity :0.8;
  }
  .enfont{
    font-size: 10px;
    font-style: italic;
    font-weight: 300;
    opacity:0.8;
  }
.cls-topborder{
    position:absolute;
    top:0;
    height: 20px;
    width: 100%;
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
 }
 .cls-borderBottomline{
    position:absolute;
    bottom:5px;
    left: 80px;
    height: 2px;
    width: 50%;    
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
   }
 .cls-borderline{
   position:absolute;
    top:20px;
    height: calc(100% - 20px);
    width: 2px;    
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
 }
 .cls-leftborder{
    position:absolute;    
    height: 100%;
    width: 8px;    
    fill:none;    
    stroke-miterlimit:10;
    stroke-width:2px;
    stroke:#366fa5;
 }
.m_info{
  color:#fff !important;
}
.m_icon{
  background-image: linear-gradient(to bottom, #00a4ff, #0070ff);
  -webkit-background-clip: text;
  color: rgba(238,255,5,0.69) !important;
  text-shadow: 1px -1px 1px #00f3ff, 0px -2px 20px #00f3ff, 0px 2px 20px #00f3ff;
}
.fontColor{
  color:#0ff9e3;
  font-style:italic;
  font-size:16px;
}
 .left_chart{
    left: 45px;
    position: absolute;
    top: 2%; 
    z-index: 5;
    width: 30vw;         
 }
 
 
 .chartbox{        
    width: 100%;    
    position :relative;
 }
 .signText{
  display: flex;
  font-size: 12px;
  margin: 0 5px;
  justify-content: center;
  }
  .m_info{
    width: 100%;    
    flex-direction:column;    
    padding: 10px 5px 5px 5px;
    }
  .infoItem_line{
    font-size: 12px;
    width: 50%;    
    padding:5px;    
  }
  .item_text{    
    display: flex;
  }
  .info_item_text{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 2px;
    justify-content: space-between;
    }
  .infoItems{    
      height:6vh; 
      min-height: 24px;
    }
  .infoItems,.m_info{
    display: flex;
  }
  .d_info{
    margin:0 5px;
    padding:5px;
 }
 .device_title{
   color:#fff !important;
   font-size:12px;
   padding-left:5px;
   border-left:solid #12cd5d 4px;
   border-radius: 2px;
 }
  .deviceInfoChart{
    width: 100%;
    height: 8vh;   
    }
  .deviceInfoList{
    color:#fff !important;
    font-size: 12px;
    height: 6vh;
    min-height: 48px;
    overflow: auto;    
    }
    /* 滚动条样式修改 */
    .deviceInfoList::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 4px !important;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px !important;
      }
    .deviceInfoList::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius   : 2.5px !important;
      background-color: #336de3eb !important;
      background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
      );
      }
    .deviceInfoList::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #ededed;
      border-radius: 5px;
      }


/*  */
  
  .cls-borderline2{
   position:absolute;
    top:20px;
    height: calc(100% - 20px);
    width: 2px;    
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
 }
 .cls-bottomborder{
    position:absolute;
    bottom:-20px;
    transform: rotateX(180deg);
    height: 20px;
    width: 100%;
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
 }
 .cls-borderleftline{
   position: absolute;
    right: 0;
    width: 4px;
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke: #36af91;
   }

   .a_info{
    padding:10px;
    margin: 10px;
    }
    .bgcolor{
      background: rgba(29,161,201,0.1)
    } 
    .bg{
      position:absolute;
      margin:20px 10px 0px 10px;
      width:calc(100% - 20px );
      height: calc(100% - 30px);
      background: rgba(28,83,125,0.4) 
    }
  .drs_echart{
    width:100%;
    height: 13vh;
  }
//右边
.bg_circle{
    position: absolute;
    top:18%;
    z-index:-5;
    right: 0;
    width: 150px;
    height: 150px;
    opacity:0.3;
    transform: scale(0.8)
  }
  .cls-topborder{
    position:absolute;
    top:0;
    right: 0;
    height: 20px;
    width: 100%;    
    stroke-width:2px; 
 }
 .cls-btopborder{
    position:absolute;
    top:0;
    right: 0;
    height: 20px;
    width: 100%;    
    stroke-width:2px; 
 }
 .cls-bbborder{
    position:absolute;
    bottom:0;
    right: 0;
    height: 20px;
    width: 100%;    
    stroke-width:2px; 
 }
 .cls-bottomborder{
    position:absolute;
    bottom:0;
    right: 0;
    height: 20px;
    width: 100%;    
    stroke-width:2px; 
    transform: rotateX(180deg);
 }
 .cls-borderleftline{
   position: absolute;    
    width: 4px;
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke: #36af91;
   }
   .cls-borderlongline{
    position: absolute;
    right: -5px;
    width: 16px;        
   }
   .cls-borderBottomline{
    position:absolute;
    bottom:5px;
    left: 80px;
    height: 2px;
    width: 50%;    
    fill:none;
    stroke-miterlimit:10;
    stroke-width:4px;
    stroke:#366fa5;
   }
   .cls-circle-b{
     position: absolute;
     width:150px;
     right: 10%;
     top:10%;
     height: 150px;
     opacity:0.15;
     z-index: -1;
   }
   .cls-borderrightline{
      position: absolute;
      right: 0;
      width: 1px;
      fill: none;
      stroke-miterlimit: 10;
      stroke-width: 2px;
      stroke: #366fa5;
   }
   .cls-border{
   position: absolute;       
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke: #366fa5;
   }
 .device_title{
   color:#fff !important;
   font-size:14px;
   padding-left:6px;
   border-left:solid #12cd5d 2px;
   border-radius: 2px;
 }
 .enfont{
    font-size: 10px;
    font-style: italic;
    font-weight: 300;
    opacity:0.8;
  }
  .right_chart{
    right: 45px;
    top: 2%; 
    position: absolute;
    z-index: 2;
    width: 30vw;    
 }
  .chartbox{            
    width: 100%;    
    position :relative;
 }
 .warn_radar{
   padding:10px 5px 5px 5px;   
 }
 .warn_township{
   padding:5px;   
 }
 .warn_warnmsg{
  padding:5px;   
 }
 .warnEChart_box{
  padding: 5px;
  background: rgba(28,83,125,0.5)  
 }
 .warnEchart{
   width: 100%;
   height: 13vh;      
 }
 .warnReport{
   height: 9vh;
   overflow: auto;
   color:#ffd331 !important;;
   z-index: 10;
 }
 .circle_self{
   position: relative;
    height: 1vh;
    width: 1vh;
    left: 80%;
 }
 .warnReport div{
   font-size:12px;
   margin: 5px;
 }
 .reportTitle{
   color:#fff !important;;
   font-size:12px;
   text-align: center;
 }
 /* 滚动条样式修改 */
    .warnReport::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 4px !important;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px !important;
      }
    .warnReport::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius   : 2.5px !important;
      background-color: #336de3eb !important;
      background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
      );
      }
    .warnReport::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #ededed;
      border-radius: 5px;
      }
      .warnItem{
        transition: all 0.8s ease;
      }
      .warnItem-enter-from,
      .warnItem-leave-to {
        opacity: 0;        
      }
    .openSiteName{
        position: absolute;
        bottom: 20px;
        right: calc(1vw );
        width: 200px;
    }
    .openLegend{
        position: absolute;
        display: none;
        bottom: 50px;
        right: 260px;
        width: 200px;
    }
    .overshow{
        position: relative;
        overflow: visible;
    }
    .winbtn{
        position: absolute;
        right: calc(17vw + 10px);
        top: calc( 100vh - 240px);
    }
    .mapLegend{
        font-size: 14px;      
        z-index: 5;
        position: absolute;
        right: calc(17vw + 55px);
        top: 80px
    }
</style>




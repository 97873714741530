 <template>
 <v-container
        class="py-2 d-flex flex-column"
        fluid      
      >
      <v-row class="d-flex align-center flex-grow-0" dense>
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('reportInfo.label.title')"
                    :placeholder="$i18n.t('reportInfo.placeholder.title')"
                    v-bind:value.sync="title"
                />
                <SearchDatePicker 
                    :range="true"
                    :label="$i18n.t('reportInfo.label.editDateRange')"
                    :placeholder="$i18n.t('reportInfo.placeholder.editDateRange')"
                    v-bind:date.sync="editDateRange"      
                    clearable              
                />
                <SelectInput clearable            
                    :label="$i18n.t('reportInfo.label.province')"
                    :options="provinceList"  
                    :placeholder="$i18n.t('reportInfo.placeholder.province')" 
                    :width='200'
                    v-bind:value.sync="province"
                    />         
                <SelectInput clearable v-bind:value.sync="city" :label="$i18n.t('reportInfo.label.city')" :options="cityList" :placeholder="$i18n.t('reportInfo.placeholder.city')" :width='198' /> 
                <v-btn class="mx-2" @click="page=1;load()">{{$i18n.t('reportInfo.btn.search')}}</v-btn>
                <v-btn
                v-if="isManager"
                color="primary"                        
                class="mx-2"
                @click="newForm"
                >{{$i18n.t('reportInfo.btn.new')}}</v-btn>
            </v-row>
        </v-col>                   
        <!-- <v-col  class="ma-0 d-flex align-end height100" cols="0" >            
            
        </v-col>               -->
      </v-row>       
      <v-dialog width="600px" v-model="dialogSiteName" hide-overlay >
        <v-card>
            <v-card-title class="text-h5 primary white--text">
                站点名
            </v-card-title>

            <v-card-text style=" max-height:300px;overflow: auto;">
                <v-chip class="ma-1" v-for="i,k in currentSiteName" :key="k">
                    {{i}}
                </v-chip>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialogSiteName = false;currentSiteName = []"
            >
                确定
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
      <v-row class="tableContainer ma-0">
          <v-data-table   
            :items="datas"         
            fixed-header
            :headers="headers"
            :page.sync="page"
            :items-per-page="rows"
            :loading="loadingData"
            hide-default-footer
          >
          <template v-slot:top>   
            <v-toolbar :elevation="0" height="0">     
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">                       
                    </template>
                <v-card>
                    <v-toolbar color="primary">
                        <v-btn icon dark @click="dialog= false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title style="color:white">{{$i18n.t('reportInfo.title')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="pa-0">                      
                        <v-row justify="center" class="ma-5">
                            <v-card width="100%" class="pa-6 dialogContent" >
                                <v-form
                                class="ma-6"
                                ref="form"
                                v-model="form.valid"                            
                                >
                                    <input hidden v-model="form.body.RiId" />
                                    <v-row>
                                        <v-text-field
                                            :rules="rules.title"
                                            v-model="form.body.RiTitle"                                
                                            :label="$i18n.t('reportInfo.label.form.title')"
                                            required
                                        ></v-text-field>  
                                    </v-row>
                                    <v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    :rules="rules.author"
                                                    v-model="form.body.RiAuthor"                                
                                                    :label="$i18n.t('reportInfo.label.form.author')"
                                                    required
                                                ></v-text-field>  
                                            </v-col>
                                            <v-col>
                                                <v-file-input :rules="rules.attach" v-model="attach" chips :label="$i18n.t('reportInfo.label.form.file')"></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row>
                                        <v-select
                                            v-model="form.body.RiType"
                                            :rules="rules.type"
                                            :items="types"                                
                                            :label="$i18n.t('reportInfo.label.form.type')"
                                            multiple
                                            required
                                        ></v-select> 
                                    </v-row>  
                                                                          
                                    <v-row>
                                        <SitesSelect 
                                            ref="siteSelect"
                                            searchBtn
                                            checkAllBtn
                                            :rules="rules.siteId"
                                            :value.sync="form.body.RiSiteId"                               
                                            :label="$i18n.t('reportInfo.label.form.site')"
                                            multiple
                                            required/>                                   
                                    </v-row>  
                                    <v-row>
                                        <v-row>
                                            <v-col>
                                                 <v-select
                                                    v-model="form.body.RiProvince"
                                                    :rules="rules.province"
                                                    :items="provinceList"                                
                                                    :label="$i18n.t('reportInfo.label.form.province')"                                                    
                                                    required
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    v-model="form.body.RiCity"
                                                    :rules="rules.city"
                                                    :items="formCityList"                                
                                                    :label="$i18n.t('reportInfo.label.form.city')"                                                    
                                                    required
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-row>                               
                                </v-form>
                            </v-card>                           
                        </v-row>
                    </v-card-text>  
                    <v-card-actions class="bottomAction d-flex justify-end">                         
                        <v-btn class="mr-6" color="primary" @click="save">{{$i18n.t('reportInfo.btn.save')}}</v-btn>
                        <v-btn class="mr-6" @click="dialog=false">{{$i18n.t('reportInfo.btn.cancel')}}</v-btn>
                    </v-card-actions>                 
                </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.RiSiteName="{ item }">
            <div class="pa-1 d-flex justify-space-between">
                <div style="max-width: 185px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{item.RiSiteName?.join(' , ')}}
                </div>                
                <v-btn @click="dialogSiteName = true;currentSiteName = item.RiSiteName;" fab width="30" height="30" color="primary ma-0 pa-1" text><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </div>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                v-if="isManager"
                @click="edit(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="preview(item)"
            >
                mdi-eye
            </v-icon>
            <v-icon
                small
                @click="download(item)"
            >
                mdi-download
            </v-icon>
            <v-icon
                small
                v-if="isManager"
                @click="del(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>          
      </v-row>      
      <v-row justify="center" align="center" class="ma-0 flex-grow-0">
          <v-pagination
            v-model="page"
            :length="length"
            @input="load"
            circle
            :total-visible="8"
            ></v-pagination>
            {{$i18n.t("reportInfo.pageTotal",[total])}}
      </v-row>
      </v-container>
</template>
<script>
import cityJson from '@/plugins/datas/cityJson';
import baseType from '@/plugins/datas/baseType'
import {getReportsApi,addReportApi,editReportApi,deleteReportApi,upLoadApi,downloadApi } from '@/api'
export default {
    name:"reportInfo.index",
    data(){
        return{
            datas:[],
            page:1,
            rows:10,
            total:0,
            loadingData:false,
            attach:null,
            title:"",
            siteIds:"",
            province:"",
            city:"",
            isEdit:false,
            editDateRange:[],
            provinceList:cityJson.map(a=>{
                return {
                    value:a.text,
                    text:a.text
                }
            }),
            cityList:[], 
            formCityList:[],
            sites:[],                    
            headers:[
                {
                    text:this.$i18n.t('reportInfo.table.header.title'),
                    value: 'RiTitle',      
                    width:'18%'      
                },
                {
                    text:this.$i18n.t('reportInfo.table.header.type'),
                    value: 'RType',
                    width:'8%'
                },
                {
                    text:this.$i18n.t('reportInfo.table.header.site'),
                    value: 'RiSiteName',
                    
                },  
                {
                    text:this.$i18n.t('reportInfo.table.header.province'),
                    value: 'RiProvince',
                    width:'12%'
                },  
                {
                    text:this.$i18n.t('reportInfo.table.header.city'),
                    value: 'RiCity',
                    width:'12%'
                },  
                {
                    text:this.$i18n.t('reportInfo.table.header.author'),
                    value: 'RiAuthor',
                },    
                {
                    text:this.$i18n.t('reportInfo.table.header.editDate'),
                    value: 'RiModifyTime',
                },                   
                {
                    text:this.$i18n.t('reportInfo.table.header.operation'),  
                    value: 'actions', sortable: false              
                },
            ],
            types:[],
            dialog:false,
            form:{
                valid:false,
                title:"",
                body:{
                    RiId:"",
                    RiAuthor:"",
                    RiSiteId:[],
                    RiSiteName:[],                    
                    RiLidarId:[],
                    RiLidarType:[],
                    RiTitle:"",
                    RiType:[],
                    RiCity:"",
                    RiProvince:"",
                    //附件  
                    ObjectName:"",
                    BucketName:""                 
                }
            },
            rules:{
                siteId: [
                        v => v.length > 0 || this.$i18n.t("reportInfo.label.rule.siteid")
                    ],
                type:[v => v.length > 0 || this.$i18n.t("reportInfo.label.rule.type")],
                province:[v => v.length > 0 || this.$i18n.t("reportInfo.label.rule.province")],
                city:[v => v.length > 0 || this.$i18n.t("reportInfo.label.rule.city")],                
                title:[ v => !!v || this.$i18n.t("reportInfo.label.rule.title"),
                        v =>(v && v.length <= 64)|| this.$i18n.t("reportInfo.label.rule.titleLength"),],
                author:[ v => !!v || this.$i18n.t("reportInfo.label.rule.author"),
                        v =>(v && v.length <= 32)|| this.$i18n.t("reportInfo.label.rule.authorLength"),],                                
                attach:[v =>(this.isEdit || this.attach!=null) || this.$i18n.t("reportInfo.label.rule.attach")]
            },  
            dialogSiteName:false,
            currentSiteName:[]        
        }
    },
    watch:{   
        province(n,v){            
            this.city = ""
            if(!n){
                 this.cityList = [];                 
            }else{
                this.cityList = cityJson.find(a=>a.text == n).children.map(a=>{
                    return {
                        text:a.text,
                        value:a.text
                    }
                })
            }
        },
        "form.body.RiProvince"(n,v){            
            if(!n){
                 this.formCityList = [];
            }else{
                this.formCityList = cityJson.find(a=>a.text == n).children.map(a=>{
                    return {
                        text:a.text,
                        value:a.text
                    }
                })
            }
        },
        "form.body.RiSiteId"(n){
            if(this.$refs.siteSelect){
                this.form.body.RiSiteName = this.$refs.siteSelect.siteNames
            }            
        }
    },
    computed:{ 
        isManager(){
            var permission = this.$store.state.storage_user.role?.permission;
            var perJson = JSON.parse(permission);
            var item = perJson?.action?.find(a=>{
                return a.key == "report_08"
            })
            if(item){
                var managerPermission = item.permissions?.find(a=>{
                    return a.key == 'edit'
                })                
                return managerPermission?.value;
            }
            return false;
        },
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        }          
    },
    mounted(){
        this.getType();        
        this.loadSites() 
    },
    methods:{
        getType(){
            var that = this;
            this.$store.dispatch('storage_type/getDicMenu',{
                typeid:baseType.reportType,
                callback:(d)=>{
                    that.types = d.map(a=>{
                        return {
                            text:a.BdValue,
                            value:a.BdCode
                        }
                    })
                    that.load()
                }
            })  
        },
        loadSites(){
            var that = this;                      
            that.sites = this.$store.state.storage_user.sites.map(a=>{
                return {
                    text:a.siteName,
                    value:a.siteId
                }
            })
        },
        load(){         
            var that = this; 
            let sites = this.$store.state.storage_user.sites;
            this.loadingData = true;
            getReportsApi({
             params:{
                PageIndex:this.page,
                PageSize:this.rows,
                Title:this.title,
                SiteIds:this.siteIds,
                Province:this.province,
                City:this.city,
                ModifyStartTime:this.editDateRange[0]?this.editDateRange[0] + " 00:00:00":this.editDateRange[0],
                ModifyEndTime:this.editDateRange[1]?this.editDateRange[1]+ " 23:59:59":this.editDateRange[1]
             },
             success:(d)=>{      
                that.loadingData = false;          
                that.datas = d.Data.map(a=>{
                    a.RiCreateTime = new Date(a.RiCreateTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                    a.RiModifyTime = new Date(a.RiModifyTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                    a.RiType = a.RiType.map(b=>{
                        return Number(b)
                    })
                    a.RType = a.RiType.map(b=>{
                        var item  = that.types.find(c=>{
                            return c.value == b
                        })
                        if(item){
                            return item.text
                        }
                    })
                    return a;
                })
                that.total = d.Total;
             }
           })
        },
        edit(item){
            this.form.body = {
                RiId:item.RiId,
                RiAuthor:item.RiAuthor,
                RiSiteId:item.RiSiteId,
                RiSiteName:item.RiSiteName,                    
                RiLidarId:item.RiLidarId,
                RiLidarType:item.RiLidarType,
                RiTitle:item.RiTitle,
                RiType:item.RiType,  
                RiCity:item.RiCity,
                RiProvince:item.RiProvince,
                RiCreateTime:item.RiCreateTime,
                ObjectName:"",
                BucketName:""                                                  
                //BsiId:item.BsiId            
            }
            this.isEdit = true;
            if(this.$refs.form){
                this.$refs.form.resetValidation() 
            }
            this.dialog = true;
        },
        del(item){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:()=>{
                    deleteReportApi({
                        id:item.RiId,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }                            
                        }
                    })
                },//确认回调
                noBack:null //取消回调
            })
        },
        newForm(){
            this.isEdit = false;
            this.form.body={
                RiId:"",
                RiAuthor:"",
                RiSiteId:[],
                RiSiteName:[],                    
                RiLidarId:[],
                RiLidarType:[],
                RiTitle:"",
                RiType:[],
                RiProvince:"",
                RiCity:"",
                ObjectName:"",
                BucketName:""
            };          
            if(this.$refs.form){
                this.$refs.form.resetValidation() 
            }
            this.dialog = true;
        },    
        preview(item){            
            let url=`/0/${item.ReportAttachmentRela.RarBucketName}?objectName=${item.ReportAttachmentRela.RarObjectName}`;                       
            var {href} = this.$router.resolve({
                path:'/preview',
                query:{
                    url:url
                }
            })
             window.open(href,'_blank')
        },
        download(item){
            if(!item.ReportAttachmentRela || !item.ReportAttachmentRela.RarBucketName || !item.ReportAttachmentRela.RarObjectName){
                openNotice({
                    type:"error",
                    text:"文件已删除，请联系管理员"
                }) 
                return;
            }
            //window.open(`http://124.133.246.59:13395/${item.ReportAttachmentRela.RarBucketName}/${item.ReportAttachmentRela.RarObjectName}`)
            downloadApi(
            {
                url:`/0/${item.ReportAttachmentRela.RarBucketName}?objectName=${item.ReportAttachmentRela.RarObjectName}`,
                fileName:item.RiTitle+ "."+$help.getFileExtension(item.ReportAttachmentRela.RarObjectName.split('/').pop()) 
            })
        },  
        save(){
            var rel = this.$refs.form.validate();
            var that = this;   
            if(rel){           
                //修改                
                if(this.form.body.RiId){                                           
                    if(this.attach){
                        //上传minio
                        let ext = $help.getFileExtension(this.attach.name);                    
                        let filename = $help.guid().replaceAll("-","")+'.'+ext;//that.form.body.RiSiteId.join("_")+"_"+
                        let timer = new Date().pattern("yyyyMM/dd") 
                        console.log(this.attach)                        
                        upLoadApi(
                        {
                            url:'/1/report?objectName=file/'+timer+'/'+filename,
                            files:this.attach,
                            success:(d)=>{                                             
                                that.form.body.BucketName = 'report'
                                that.form.body.ObjectName = `/file/${timer}/${filename}`    
                                editReportApi({
                                    id: this.form.body.RiId,
                                    data:{
                                        ...this.form.body
                                    },
                                    success:(d)=>{
                                        that.load()
                                        that.dialog = false;                            
                                    }
                                })         
                            }
                        })    
                    }else{
                        editReportApi({
                            id: this.form.body.RiId,
                            data:{
                                ...this.form.body
                            },
                            success:(d)=>{
                                that.load()
                                that.dialog = false;                            
                            }
                        })
                    }
                    
                }
                //增加
                else{
                    let ext = $help.getFileExtension(this.attach.name);                    
                    let filename =that.form.body.RiSiteId.join("_")+"_"+ $help.guid().replaceAll("-","")+'.'+ext;
                    let timer = new Date().pattern("yyyyMM/dd") 
                    upLoadApi(
                    {
                        url:'/1/report?objectName=file/'+timer+'/'+filename,
                        files:this.attach,
                        success:(d)=>{                                               
                            that.form.body.BucketName = 'report'
                            that.form.body.ObjectName = `/file/${timer}/${filename}`    
                            addReportApi({
                                data:{
                                    ...that.form.body
                                },
                                success:(d)=>{
                                    that.load()
                                    that.dialog = false;                            
                                }                        
                            })               
                        }
                    })                    
                }
            }             
        }
    }
    
}
</script>
<style scoped lang="scss">
.v-data-table{
    width: 100%;
    height: 100%;
}
.permissionItem{
    width: 100%;
}

</style>
<template>
    <div  class="_attention_box_header">
        <v-icon v-if="show" class="_attention_icon_header" @click="hidden">mdi-close</v-icon>
        <div ref="_attention" class="_attention_box_header_shows">
            <div v-show="show" v-for="(msg,_i) in currentMsgs" :key="_i" class="_attention_header" v-html="msg"></div>               
        </div>                    
    </div>
</template>
<script>
import anime from 'animejs';
export default {
    name:"scrollNoticeTop",
    mounted(){        
    },
    data(){
        return {
            show:false,
            currentMsgs:[],
            timeoutItem:null,
            allMsgs:[]
        }
    },
    watch:{
        "$store.state.sites_warnPointStore.newWarnPoints":{
            handler(n,o){
                let sites = this.$store.state.sites_siteStore.sites;   
                let msgs = n.map(a=>{
                    let site = sites.find(b=>{
                        return b.siteId == a.lidarSN
                    })
                    if(site){
                        return `警告：${a.date.replace('.000',"")}${site.name},${site.radarType}发现污染物，报警${a.count}次`;
                    }
                    else{
                        return ""
                    }                    
                }).filter(a=>{
                    if(a!= ""){
                        return a;
                    }
                })  
                if(this.show){                                       
                    this.allMsgs =this.allMsgs.concat(msgs)
                    console.log(n.length,this.allMsgs.length)
                    this.timeoutSet()
                }
                else{               
                    this.show = true;
                    this.currentMsgs = msgs;
                    this.animate()           
                }

            } 
        }  
    },
    methods:{
        timeoutSet(){
             console.log(this.show)  
            if(this.show){
                var that = this;
                if(this.timeoutItem!=null){
                    clearTimeout(this.timeoutItem)
                }
                this.timeoutItem = setTimeout(() => {
                    that.timeoutSet()
                }, 1000);
            }else{                
                this.show = true;
                this.currentMsgs = this.allMsgs.slice(0,10);                
                this.allMsgs= [];
                this.animate()   
            }
        },
        animate(){
            let easy = (el,i,total)=>{
                //el 元素 //i第几个 //total 总共有几个
                //t 时刻 0.33  1/3秒 
                return function(t) { 
                    //改变当前t刻                   
                    return t;
                }
            }                   
            let target = this.$refs["_attention"]
            let targets = [];
            targets.push(target)
            targets.concat(target.children)
                        
            anime({
                    targets:targets,
                    height:"30px",
                    easing: easy,
                    duration: 500  
                }).finished.then(()=>{
                     anime({
                        targets:target.children,
                        keyframes: [                    
                            {right: anime.stagger(80,{start:50}),opacity:1,easing: easy,duration: 500,},
                            {left: 100,easing: easy,duration: 12000},
                            {left:0, opacity:0 ,easing: easy,duration: 500},                                                       
                        ],
                        loop:2,
                        delay:function(el, i, l) {
                            return i * 12000;
                        },
                    }).finished.then(()=>{                                                
                        anime({
                            targets:targets,
                            height:0,
                            easing: easy,
                            duration: 500  
                        })                        
                        target.children.forEach(a => {
                            a.style.removeProperty("left")
                        });
                        this.show = false;
                        this.currentMsgs = [];
                    })
                })
        },
        hidden(){
            let target = this.$refs["_attention"]            
            let easy = (el,i,total)=>{
                //el 元素 //i第几个 //total 总共有几个
                //t 时刻 0.33  1/3秒 
                return function(t) { 
                    //改变当前t刻                   
                    return t;
                }
            }         
             let targets = [];
            targets.push(target)
            targets.concat(target.children)
            
            anime({
                targets:targets,
                height:0,
                easing: easy,
                duration: 500              
            })
            target.children.forEach(a => {
                a.style.removeProperty("left")
            });
            this.show = false;
            this.currentMsgs = [];
            this.allMsgs = [];
            if(this.timeoutItem!=null){
                clearTimeout(this.timeoutItem)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
._attention_box_header{
    position:relative;
    width: 100%;
    height: 0;    
}
._attention_box_header_shows{
    position :relative;
    z-index:999;
    width: 100%;
    height: 0px; 
    background: rgba(10,10,10,0.5)
}
._attention_header{
    display: inline-block;   
    position :absolute;    
    right: 0;
    opacity: 0;
    height: 100%;
    line-height: 30px;
    font-size:16px;
    color:#ff8a37;
    text-overflow: clip;
    white-space: nowrap;
}
._attention_icon_header{
    line-height: 30px !important;
    z-index:10000 !important;
    position:absolute !important;
    color:white !important;
    right: 10px;
}

</style>
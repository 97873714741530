<template>
    <v-container class="contain ma-0 pa-0">
        <div class="hotMapColorBar">
            公里(km)
            <div :style="`background-color:${i.color}`" v-for="i in levelConfig" class="hotMapColorBarBox" :key="i.key">
                <div class="hotMapColorBarText" >{{i.min}}-{{i.max}} [{{i.text}}]</div>
            </div>
        </div>        
        <v-card width="600px" >
            <v-card-actions class="mapToolbar mt-0 d-flex flex-nowrap">
                <v-row class="ma-0 align-center justify-start">                 
                    <v-card elevation="0" class="my-0 mx-4 pa-0" style="min-width:360px;">
                        <SearchDateTimePickerPlus :maxRangeDay='7' range v-bind:datetime.sync="timeRange" />
                    </v-card>
                    <v-menu open-on-hover offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                                                        
                            v-bind="attrs"
                            v-on="on"
                            class="ma-3"
                            >
                            {{currentTime.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="(item, index) in menuTime"
                                :key="index"
                                @click="setTime(item)"
                            >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>                          
                    </v-menu>             
                </v-row>
            </v-card-actions>
        </v-card>      
        <v-row class="ma-0 px-2 pt-3 height50 width100">  
            <Trendmap ref="trendmap" :dataList="trendDataList" :overlay="overlay" :noData="noData" @updateScanMode="setCurrentMode" class="width100"></Trendmap>                   
        </v-row>
        <v-row  class="ma-0 py-1 px-2 height50">
            <v-col class="pr-1 pl-0" cols="6" style="position: relative;">
                <v-menu open-on-hover offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                                                        
                            v-bind="attrs"
                            v-on="on"
                            style="top:10px;position:absolute;z-index: 2;right:20px"
                            class="mx-3"
                            >
                            {{currentViewMode.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                 @click.stop="currentViewMode = item;"
                                v-for="(item, index) in viewMode"
                                :key="index"
                            >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>                          
                    </v-menu>                
                <Scanmap v-if="currentViewMode.value == 'PPI'" :timeRange="timeRange"></Scanmap>
                <RHI v-if="currentViewMode.value == 'RHI'" :timeRange="timeRange"></RHI>                 
                <Heatmap v-if="currentViewMode.value == 'FIX'" :timeRange="timeRange"></Heatmap>
            </v-col>
            <v-col class="pl-1 pr-0 height100" cols="6"><AlarmTable @clickRow="clickRow" :timeRange="timeRange"></AlarmTable></v-col>
        </v-row>        
    </v-container>
</template>

<script>
import SearchDateTimePickerPlus from '@/components/form/SearchDateTimePickerPlus'
import Scanmap from '@/components/monitoring/vls/history/Scanmap'
import RHI from '@/components/monitoring/vls/history/RHI'
import Heatmap from '@/components/monitoring/vls/history/Heatmap'
import Trendmap from '@/components/monitoring/vls/history/Trendmap'
import AlarmTable from '@/components/monitoring/vls/history/AlarmTable'
import transferDataType from '@/plugins/datas/transferDataType'
import {getVlsAveApi,getLastestRawApi} from '@/api'
export default {
    components:{Scanmap,RHI,Heatmap,Trendmap,AlarmTable,SearchDateTimePickerPlus},
    name:"monitoring.vls.history",
    data(){
        return {
            overlay:false,
            noData:false,
            timeRange:[],
            trendDataList:[],
            scanModeDataList:[],
            scanModePeriodIdList:[],
            currentViewMode:{
                text:"水平扫描",
                value:'PPI'
            }, 
            viewMode:[{
                text:"水平扫描",
                value:'PPI'
            },{
                text:"定点扫描",
                value:'FIX'
            },{
                text:"垂直扫描",
                value:'RHI'
            }],
            levelConfig:[                
                {
                    key:1,
                    min:1,
                    max:10,
                    text:"轻雾",
                    color:'rgb(202, 251, 235)'
                },
                {
                    key:2,
                    min:0.5,
                    max:1,
                    text:"大雾",
                    color:'rgb(104, 253, 250)'
                },
                {
                    key:3,
                    min:0.2,
                    max:0.5,
                    text:"浓雾",
                    color:'rgb(4, 204, 203)'
                },
                {
                    key:4,
                    min:0.05,
                    max:0.2,
                    text:"强浓雾",
                    color:'rgb(5, 152, 151)'
                },
                {
                    key:5,
                    min:0,
                    max:0.05,
                    text:"特强浓雾",
                    color:'rgb(6, 82, 72)'
                }
            ],
            //快捷
            menuTime:[
                {
                    text:this.$i18n.t('history.btn.btn24'),
                    value:'btn24'
                },
                {
                    text:this.$i18n.t('history.btn.btn1'),
                    value:'btn1'
                },
                {
                    text:this.$i18n.t('history.btn.btn3'),
                    value:'btn3'
                },
                {
                    text:this.$i18n.t('history.btn.btn1d'),
                    value:'btn1d'
                },
                {
                    text:this.$i18n.t('history.btn.btn2d'),
                    value:'btn2d'
                }
            ],
            currentTime:{
                text:this.$i18n.t('history.btn.btn24'),
                value:'btn24'
            },
            currentPeriod:null,
            period:'',
            timerLimit:null,
            timer:null
        }       
    }, 
    watch:{   
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.load(true)                
            }
        },       
        currentTime:{
            handler(n){
                let end = new Date();
                let start = new Date();        
                if(n.value == 'btn24'){
                    start.setTime(start.getTime() - 3600 * 1000 * 24); 
                }else if(n.value == 'btn1'){
                    start.setTime(start.getTime() - 3600 * 1000 * 1);
                }else if(n.value == 'btn3'){
                    start.setTime(start.getTime() - 3600 * 1000 * 3);
                }else if(n.value == 'btn1d'){    
                    end = $help.getDateNowStartOrEnd('start');
                    start = $help.getDateNowStartOrEnd('start');
                    start.setTime(start.getTime() - 3600 * 1000 * 24);
                }else if(n.value == 'btn2d'){
                    end = $help.getDateNowStartOrEnd('start');
                    start = $help.getDateNowStartOrEnd('start');
                    end.setTime(start.getTime() - 3600 * 1000 * 24* 1);
                    start.setTime(start.getTime() - 3600 * 1000 * 24* 2);
                }
                this.timeRange = [start,end]                             
            }
        },
        timeRange(n){
            if(this.$store.state.storage_global.currentSite && n.length > 1){
                let start = n[0].pattern("yyyy-MM-dd HH:mm:ss") //
                let end = n[1].pattern("yyyy-MM-dd HH:mm:ss") //  
                this.loadData(start,end,this.$store.state.storage_global.currentSite.siteId)        
            }            
        },
        trendDataList(n){
            this.scanModePeriodIdList = $help.groupBy("scanType",this.trendDataList.map(a=>{
                return{
                    scanType:a.scanType,
                    periodId:a.periodId
                }
            }))            
            this.scanModePeriodIdList[0] = this.scanModePeriodIdList[0]?this.scanModePeriodIdList[0]:[]
            this.scanModePeriodIdList[1] = this.scanModePeriodIdList[1]?this.scanModePeriodIdList[1]:[]
            this.scanModePeriodIdList[2] = this.scanModePeriodIdList[2]?this.scanModePeriodIdList[2]:[]
        }        
    },
    beforeDestroy(){
        if(this.timer){
            clearInterval(this.timer)
        }        
    },
    mounted(){       
        let that = this; 
        setTimeout(() => {
            that.load()   
        }, 200);       
    },
    methods:{
        load(update){
            let that = this;            
            getLastestRawApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    siteType:this.$store.state.storage_global.currentSite.type
                },
                success:(d)=>{                                                              
                    if(d){          
                        if(d.ScanType == 0){
                            that.currentViewMode = that.viewMode[0]
                        }else if(d.ScanType == 1){
                            that.currentViewMode = that.viewMode[1]
                        }else if(d.ScanType == 2){
                            that.currentViewMode = that.viewMode[2]
                        }
                        if(!update){
                            that.timeRange =[new Date(new Date(d?.DateTime?.replace('T',' ')).setDate(new Date(d?.DateTime?.replace('T',' ')).getDate() -1)),new Date(d?.DateTime?.replace('T',' '))]   
                        }                        
                    }else{
                        if(!update){
                            that.timeRange =[new Date(new Date().setDate(new Date().getDate() -1)),new Date()]
                        }                        
                    }
                    // if(that.timeRange.length > 1){                                                    
                    //     let start = that.timeRange[0]?.pattern("yyyy-MM-dd HH:mm:ss") //
                    //     let end = that.timeRange[1]?.pattern("yyyy-MM-dd HH:mm:ss") //  
                    //     if(start && end){
                    //         that.loadData(start,end,n.siteId)                            
                    //     }
                    // }
                }
            })            
        },
        //快捷时间选项
        setTime(item){
            this.currentTime = Object.assign({},item);             
        },
        loadData(start,end,siteId,datas = [],time){  
            var that = this;
            this.overlay = true;
            this.noData = false 
            getVlsAveApi({
                    id:siteId,                  
                    params:{
                        LastDate:time,
                        PageSize:1000,                        
                        StartTime:start,
                        EndTime:end
                    },success:(d)=>{                                                 
                        let _datas = d.Data.map(a=>{
                            return {
                                dataType:transferDataType.vizPeriod.value,
                                siteId:a.DvaSiteId,
                                periodId:a.DvaPeriod,
                                scanType:a.DvaScanMode,
                                dataTime:a.DvaTime,
                                angleOfPitch:a.DvaPitch,
                                straightAngle:a.DvaStraight,
                                data:a.DvaValue
                            }})                 
                        
                        datas = datas.concat(_datas)    
                        that.trendDataList = datas;  
                        if(that.trendDataList.length == 0){                            
                            that.noData = true;
                            that.overlay = false;
                            return
                        }                 
                        if(d.Data.length > 0 && new Date(d.Data[d.Data.length -1].DvaTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Data.length < 1000){                                                                                       
                            that.overlay = false;
                            that.loadChart()                                                  
                             //that.loadChart();       
                        }else{                                                                      
                            that.loadData(start,end,siteId,datas,d.Data[d.Data.length -1].DvaTime)                                  
                        }    
                        
                    }
                })
            
        },
        setCurrentMode(name){
            if(this.timer){
                clearTimeout(this.timer)
            }   
            var that = this; 
            this.timer = setTimeout(() => {                
                that.currentViewMode = that.viewMode.find(a=>a.text == name)
            }, 100);
        },  
        clickRow(time,color){
            this.$refs.trendmap.clickTime(time,color)
        }
    }
}
</script>
<style scoped>
.contain{
    max-width: 100%;
}
.height50{
    height: 50%;
}
.mapToolbar{
    position: absolute;
    z-index: 3;
    width: 100%;
    left: 120px;
}
.hotMapColorBar{
    z-index: 2;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 80px;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}

</style>
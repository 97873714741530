<template>
    <v-container class="ma-0 pa-0">
        <v-menu offset-x nudge-left="0" nudge-top="0" bottom v-model="openMenu" >
            <template v-slot:activator="{ on,attrs }">
                <v-btn 
                text
                fab 
                small
                v-bind="attrs"
                v-on="on"                
                >
                <v-icon  :style="`width:100%;height:100%;`">mdi-cog</v-icon>  
                </v-btn>  
            </template>
            <v-card @click.stop="" color="pa-2" min-width="420" >
                <h4>刷新设置</h4>
                <v-form >                    
                    <v-text-field @click.stop="" v-model="itemTimeRange" label="时间热图默认加载时长(分钟)"></v-text-field>
                    <v-select                        
                        :items="loadOptionList"
                        label="刷新间隔"
                        item-text="text"
                        item-value="key"
                        return-object
                        v-model="itemTimeSpan"
                        ></v-select>
                </v-form>
            </v-card>
        </v-menu>
    </v-container>    
</template>
<script>
export default {
    name:"realtimeconfig",
    props:{
        timeRange:{
            type:Number,
            default:15
        },
        timeSpan:{
            type:Number,
            default:0
        },
    },
    watch:{
        timeRange:{
            handler(n){
                console.log(n)
                this.itemTimeRange = n; 
            }           
        },
        timeSpan:{
            handler(n){
                console.log(n)
                this.itemTimeSpan = this.loadOptionList.find(a=>a.key == n); 
            }
        },
        itemTimeRange(n){
            this.$emit("updateRange",n)
        },
        itemTimeSpan(n){
            this.$emit("updateSpan",n.key)
        }
    },
    mounted(){
        this.itemTimeRange = this.timeRange; 
        this.itemTimeSpan = this.loadOptionList.find(a=>a.key == this.timeSpan); 
    },
    data(){
        return {
            openMenu:false,//菜单是否开启
            loadOptionList:[{
                text:"实时",
                key:'0'
            },{
                text:"5秒",
                key:'5'
            },{
                text:"10秒",
                key:'10'
            },{
                text:"30秒",
                key:'30'
            }],
            itemTimeRange:15,
            itemTimeSpan:{
                text:"实时",
                key:'0'
            }
        }
    }
}
</script>
<style scoped lang="scss">

</style>
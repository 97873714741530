<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">
        <v-overlay
          absolute
          :value="overlay"
          class="ma-3"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <ColorBarConfig @update="timerDraw"  :dataKey="currentView == 3 ? 51:533" ref="colorBar" class="mapColorBarConfig"/>       
        <v-card height="100%" width="100%" elevation="1">   
            <v-card-title class="ma-0 pa-1 heatmapTitle">风切变</v-card-title>             
            <v-card-title v-show="currentView == 3" class="ma-0 pa-1 btns">                    
                    <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                    <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>                                                              
                </v-card-title>  
            <div v-show="currentView == 3" class="mx-1" style="position:absolute;top:32px;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>              
            <div ref="polar" class="width100 height100 plotychart">
            </div>
        </v-card>                      
    </v-container>
</template>
<script>
import { getWlsShearByTimeApi,getWlsShearByScanModeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
export default {
    name:'wls.realtime.shear',
    props:{
        timeRange:{
            type:Array
        },
        currentScanType:{
            type:String
        },
        currentView:{
            type:Number,
            default:3
        }
    },
    components: {
        ColorBarConfig
    },
    data(){
        return {
            overlay:false,
            dataList:[],
            nextPeriod:'',
            prePeriod:'',
            timer:null
        }
    },
    watch:{
        timeRange:{
            handler(n){                        
                if(n.length > 0){  
                    this.dataList = []                                       
                    this.nextPeriod = ''
                    this.load()
                }
            },
            deep:true
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(this.currentView == 3){
                    this.loadShear(false)
                }else{
                    this.loadHeatmap()
                }   
            }
        },   
        currentScanType(n){
            setTimeout(() => {
                this.dataList = []
                this.nextPeriod = ''
                this.prePeriod = ''
                this.load()
            }, 200);           
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.dataList = []
                this.nextPeriod = ''
                this.prePeriod = ''
                this.load()                       
            }
        },     
    },
    computed:{
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        },
        time(){
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            let period = this.dataList[0]?.period?.split('_')[0]
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }  
        },
        yaw()
        {
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            return this.dataList[0]?.period?.split('_')[1]
        }
    },
    mounted(){
        var that = this;
        setTimeout(() => {
            that.load()    
        }, 600);        
    },
    methods:{
        load(desc = false){
            if(this.currentView == 3){
              this.loadShear(desc)
            }else{
              this.loadHeatmap()
            }
        },
        loadShear(desc){
            var that = this;
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }
            this.overlay = true;
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end =desc?start: this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //      
            let period = desc? this.prePeriod : this.nextPeriod                   
            getWlsShearByTimeApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    Period:period,                  
                    Time:start,
                    LimitTime:end,
                    Desc:desc,
                    ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0
                },
                success:(d)=>{  
                    that.overlay = false;                                
                    if(!d.Data || d.Data.length == 0)  {          
                        that.loadChart();
                    }        
                    if(d.Data[0].DwsSiteId != that.$store.state.storage_global.currentSite.siteId){
                        return;
                    }   
                    that.nextPeriod = d.NextPeriod?.split("_")[0]
                    that.prePeriod = d.PrePeriod?.split("_")[0]                                           
                    that.dataList = d.Data?.map(a=>{
                        return {
                            dataTime:a.DwsTime.replace('T',' '),
                            distanceData:a.DwsDis,
                            angle:a.DwsScanMode == 0 ? a.DwsStraight:a.DwsScanMode == 2?a.DwsPitch:a.DwsStraight,                        
                            scanMode:scanType[a.DwsScanMode],
                            straightAngle:a.DwsStraight,
                            angleOfPitch:a.DwsPitch,   
                            data:a.DwsValue,
                            period:a.DwsPeriod
                        }
                    }); 
                    that.loadChart();
                }
            })
        },
        loadChart(){   
            let d = 3.3     
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;   
            var that = this;
            let target = this.$refs.polar
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                                         
                let layout = { 
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                 
                    polar: {                    
                        radialaxis: {    
                            color:fontColor,                     
                            visible: true,
                            range: [0, d]
                        },
                        angularaxis: {
                            color:fontColor, 
                            tickfont: {
        
                            },
                            direction: "clockwise"
                        }
                    },
                    modebar:{
                        add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                    },
                    showlegend: false,
                    margin:{
                        t:38,
                        r:12,  
                        l:12                        
                    }
                }            
                Plotly.newPlot(target, [{
                        type: "scatterpolargl",
                        r: [],
                        theta: [],
                        text: [],
                        mode: "markers",
                        name: "",
                        marker: {
                            color: `rgba(0,0,0,0)`,
                            size: 6,                         
                            opacity: 0.7
                        },
                        cliponaxis: false
                    }], layout,{responsive: true,scrollZoom: true})
                return;                
            }
            let polarData = []
            let noDataLength =  (this.dataList[0].distanceData[0])/this.dataList[0].distanceData[1]//无效距离
            let scanLength = this.dataList[0].distanceData[2]*this.dataList[0].distanceData[1]
            $help.drawScan(this.dataList,(obj)=>{         
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                       
                    let r = that.dataList[0].distanceData[0] + that.dataList[0].distanceData[1] *  obj.dIndex
                    let pitch=that.dataList[0].angleOfPitch;
                    polarData.push({
                        color: `rgba(${color.join(',')})`,
                        r:r,
                        angle:obj.angle,
                        value:obj.value,
                        pitch:pitch
                    })                       
                })
            },()=>{               
                let group = $help.groupBy('color',polarData)
                let data = [];
                for(var i in group){
                    let item = group[i]
                    data.push({
                        type: "scatterpolargl",
                        r: item.map(a=>a.r.toFixed(2)),
                        theta: item.map(a=>a.angle.toFixed(2)),
                        text: item.map(a=>
                        `值：${a.value.toFixed(6)}<br />距离：${a.r.toFixed(2)}(Km)<br />水平角：${a.angle.toFixed(2)}°<br />俯仰角：${a.pitch}°`
                        ),
                        mode: "markers",
                        name: "",
                        marker: {
                            color: i,
                            size: 6,                         
                            opacity: 0.7
                        },
                        hoverinfo:'text',
                        cliponaxis: false
                    })
                }  
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                         
            let layout = { 
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                 
                polar: {                    
                    radialaxis: {    
                        color:fontColor,                     
                        visible: true,
                        range: [0, d]
                    },
                    angularaxis: {
                        color:fontColor, 
                        tickfont: {
      
                        },
                        direction: "clockwise"
                    }
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                showlegend: false,
                margin:{
                    t:38,
                    r:12,  
                    l:12                        
                }
            }            
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:d,yaw:yaw})                        
        },
        play(status){                   
            if(status == 'next'){
                if((!this.nextPeriod || Number(this.timeRange[1].pattern("yyyyMMddHHmmss")) - Number(this.nextPeriod) <= 0) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }   
                this.load()        
            }else{
                if(!this.prePeriod || Number(this.timeRange[0].pattern("yyyyMMddHHmmss")) - Number(this.prePeriod) >= 0) {
                    openNotice({
                        type:"info",
                        text:'已加载到开始'
                    })                        
                    return
                }
                this.load(true)            
            }
        },
        timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                if(that.currentView == 3){
                    that.loadChart()
                    }else{
                        that.loadHeatChart()
                    }
               
            }, 1000/3);
        },
        loadHeatmap(){
            let that = this;
            this.overlay = true;
            getWlsShearByScanModeApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                scanType:this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:this.currentScanType == "Qua"?4:0 ,
                params:{
                    StartTime:this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss"),//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    EndTime:this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss"),//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                    PageSize:1000, 
                    ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:this.currentScanType == "Qua"?4:0                   
                },
                success:(d)=>{
                    that.overlay = false;
                    if(!d.Data || d.Data.length == 0)  {          
                        that.loadHeatChart();
                    }        
                    if(d.Data[0].DwsSiteId != that.$store.state.storage_global.currentSite.siteId){
                        return;
                    }   
                    that.dataList = d.Data.filter(a=>a.DwsScanMode == 3 || a.DwsScanMode == 4).map(a=>{
                        return {
                            dataTime:a.DwsTime.replace('T',' '),
                            distanceData:a.DwsDis,                     
                            scanMode:scanType[a.DwsScanMode],
                            straightAngle:a.DwsStraight,
                            angleOfPitch:a.DwsPitch,   
                            data:a.DwsValue,
                            period:a.DwsPeriod.split('_')[0],
                            yaw:Number(a.DwsPeriod.split('_')[1]),
                        }
                    });
                    that.loadHeatChart()
                }
            })
        },
        loadHeatChart(){
            var that = this;      
            let target = this.$refs.polar
            if(!this.$refs.colorBar || !target){
                return;
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            if(this.dataList.length == 0){
                var data = [{
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[]
                }];
                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,  
                    uirevision:'true',                              
                    xaxis: {
                        color:fontColor,  
                        nticks:$help.getNtick(target.clientWidth)  
                    },
                    yaxis: {
                        color:fontColor,  
                        title:"距离(km)"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,       
                        b:22
                    }
                };
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true})
                return
            }
            let _datas =[]; 
            let dataList = this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;    
            for(let i in dataList){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){  
                            j+=minSpan;                        
                            _datas.push({   
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),                                
                                distanceData:this.dataList[i].distanceData,                     
                                scanMode:this.dataList[i].scanMode,
                                straightAngle:this.dataList[i].straightAngle,
                                angleOfPitch:this.dataList[i].angleOfPitch,   
                                data:Array.from({length:this.dataList[i].data.length},(val, i) => NaN),
                                period:this.dataList[i].period
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });
            let yData = this.dataList[0].distanceData;
            let xData = []; 
            let zData = [];
            let hoverText = [];

            datas.forEach((a,i)=>{                                     
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(Number(a.data[j]))
                    hoverText[j].push(`垂直风切变值：${a.data[j]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }                       
            });   
            var data = [{
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
            }];  
            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                             
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            htmlToImage.toPng(target, { })
                            .then(function (dataUrl) {
                                /* do something */
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            });                              
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                    b:42
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }      
        }  
    }
}
</script>
<style scoped lang="scss">
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 64px;
    right: 20px;    
}.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}.btns{
    position: absolute;
    z-index: 2;
    right: 60px;
    top: 10px;
}
.plotychart{
    :deep(.modebar){
        padding-top:32px;
        padding-right:32px ;
    }
}
</style>
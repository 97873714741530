import ScanTypeList from "@/plugins/datas/scanType";
let exceptType = [19000]
export default (Jdata)=>{    
    let type = Jdata.DataType;
    let data = JSON.parse(Jdata.Json);
    let dataTime = Jdata.DataTime?.replace('T',' ');
    let siteId = Jdata.SiteId;
    let scanType = Jdata.ScanMode
    let period = Jdata.Period?.split('_')[0];
    let yaw = Jdata.Period?.split('_')[1]
    let jsonData;    
    //边界层
    if(type == 16){       
        jsonData = data.map(a=>{
            return {                
                distanceData:a.Dis,
                height:a.Height,
                mergeType:a.MergeType,
                pitch:a.Pitch,
                straight:a.Straight,
                dataTime:dataTime,
                dataType:type,
                period:a.PeriodId,
                scanType:a.ScanMode,
                siteId:a.SiteId
            }
        })
    }
    //测风radial
    else if(type == 50){
        jsonData = data.map(a=>{
            let _scanType =typeof(a.DwrScanMode) == 'string'? a.DwrScanMode?a.DwrScanMode: ScanTypeList[a.DwrScanMode]? ScanTypeList[a.DwrScanMode]:scanType:scanType
            return {
                angleOfPitch: a.DwrPitch,
                data: a.DwrValue,                                                    
                dataTime:a.DwrTime? a.DwrTime.replace('T',' '):dataTime,
                dataType:type,
                distanceData: a.DwrDis,
                period:a.DwrPeriod?a.DwrPeriod?.split('_')[0]:period,
                yaw:yaw,
                siteId: a.DwrSiteId?a.DwrSiteId:siteId,
                scanType:_scanType,
                straightAngle:a.DwrStraight
            }
        })
    }
    //测风风切变数据
    else if(type == 51){        
        jsonData = data.map(a=>{
            let _scanType =typeof(a.DwsScanMode) == 'string'? a.DwsScanMode?a.DwsScanMode: ScanTypeList[a.DwsScanMode]? ScanTypeList[a.DwsScanMode]:scanType:scanType
            return {
                angleOfPitch: a.DwsPitch,
                data: a.DwsValue,                                                    
                dataTime:a.DwsTime?a.DwsTime.replace('T',' '):dataTime,
                dataType:type,
                distanceData: a.DwsDis,
                period:a.DwsPeriod?a.DwsPeriod?.split('_')[0]:period,
                yaw:a.DwsPeriod?a.DwsPeriod?.split('_')[1]:yaw,
                siteId: a.DwsSiteId?a.DwsSiteId:siteId,
                scanType:_scanType,
                straightAngle:a.DwsStraight
            }
        })
    }
    //测风矢量风
    else if(type == 52){  
        let _scanType =typeof(data.ArrowData.DwqScanMode) == 'string'? data.ArrowData.DwqScanMode?data.ArrowData.DwqScanMode: ScanTypeList[data.ArrowData.DwqScanMode]? ScanTypeList[data.ArrowData.DwqScanMode]:scanType:scanType      
        jsonData = [{
            siteId:data.ArrowData.DwqSiteId?data.ArrowData.DwqSiteId:siteId,
            scanType:_scanType, 
            quiver:{
                localAngle:data.ArrowData.DwqLocAngle,
                locDis:data.ArrowData.DwqLocDis,
                period:data.ArrowData.DwqPeriod?data.ArrowData.DwqPeriod?.split('_')[0]:period,
                yaw:yaw,
                quiverAngle:data.ArrowData.DwqQuiverAngle,
                quiverValue:data.ArrowData.DwqQuiverValue,                               
                dataTime:data.ArrowData.DwqTime?data.ArrowData.DwqTime.replace('T',' '):dataTime
            },
            back:{
                localAngle:data.BackData.DwqbLocAngle,
                locDis:data.BackData.DwqbLocDis,
                period:data.BackData.DwqbPeriod?data.BackData.DwqbPeriod?.split('_')[0]:period,
                yaw:yaw,
                quiverValue:data.BackData.DwqbQuiverValue,                
                siteId:data.BackData.DwqbSiteId?data.BackData.DwqbSiteId:siteId,
                dataTime:data.BackData.DwqbTime?data.BackData.DwqbTime.replace('T',' '):dataTime
            }
        }]
    }
    //测风风廓线
    else if(type == 53){        
        jsonData = data.map(a=>{            
            let _scanType =typeof(a.DwpScanMode) == 'string'? a.DwpScanMode?a.DwpScanMode: ScanTypeList[a.DwpScanMode]? ScanTypeList[a.DwpScanMode]:scanType:scanType
            return {                
                height:a.DwpHeight,
                straightValue:a?.DwpStraightValue ? a?.DwpStraightValue:0,
                pitchValue: a.DwpPitchValue,                                                  
                dataTime:a.DwpTime?a.DwpTime.replace('T',' '):dataTime,
                dataType:type,
                period:a.DwpPeriod?a.DwpPeriod?.split('_')[0]:period,
                yaw:yaw,
                siteId: a.DwpSiteId?a.DwpSiteId:siteId,
                scanType:_scanType,
                straightAngle:a.DwpStraightAngle
            }
        })
    }    
    //测风能见度 viz
    else if (type == 96){
        console.log(data,type)
        jsonData = data.map(a=>{            
            let _scanType =typeof(a.scanType) == 'string'? a.scanType?a.scanType: ScanTypeList[a.scanType]? ScanTypeList[a.scanType]:scanType:scanType
            return {                
                angleOfPitch:a.angleOfPitch,
                data:a.data,//
                data2:a.data2,//
                distanceData:a.distanceData,//                                          
                dataTime:a.dataTime?a.dataTime.replace('T',' '):dataTime,
                dataType:type,
                period:a.periodId?a.periodId?.split('_')[0]:period,
                yaw:yaw,
                siteId:siteId,
                scanType:_scanType,
                straightAngle:a.straightAngle
            }
        })
    }
    //rcs
    else if (type == 97){
        console.log(data,type)
        jsonData = data.map(a=>{            
            let _scanType =typeof(a.scanType) == 'string'? a.scanType?a.scanType: ScanTypeList[a.scanType]? ScanTypeList[a.scanType]:scanType:scanType
            return {                
                angleOfPitch:a.angleOfPitch,
                data:a.data,//
                data2:a.data2,//
                distanceData:a.distanceData,//                                          
                dataTime:a.dataTime?a.dataTime.replace('T',' '):dataTime,
                dataType:type,
                period:a.periodId?a.periodId?.split('_')[0]:period,
                yaw:yaw,
                siteId:siteId,
                scanType:_scanType,
                straightAngle:a.straightAngle
            }
        })
    }
     //云厚
    else if (type == 100){
        console.log(data,type,siteId)
        jsonData = data.map(a=>{            
            let _scanType =typeof(a.ScanType) == 'string'? a.ScanType?a.ScanType: ScanTypeList[a.ScanType]? ScanTypeList[a.ScanType]:scanType:scanType
            return {                
                blh:a.BLH,//边界层
                cb:a.CB,//云底
                ct:a.CT,//云高
                ch:a.CTh,//云厚
                angleOfPitch: a.VAngle,                                                  
                dataTime:a.Time?a.Time.replace('T',' '):dataTime,
                dataType:type,
                period:a.PeriodId?a.PeriodId?.split('_')[0]:period,
                yaw:yaw,
                siteId:siteId,
                scanType:_scanType,
                straightAngle:a.HAngle
            }
        })
    }
    else if(!data?.map || exceptType.indexOf(type) >=0){
        return null
    }
    //默认
    else{           
        jsonData = data.map(a=>{
            let _scanType = typeof(a.scanType) == 'string'? a.scanType?a.scanType: ScanTypeList[a.scanType]? ScanTypeList[a.scanType]:scanType:scanType
            a.siteId = a.radarId?a.radarId:a.siteId?a.siteId:siteId;
            a.scanType = _scanType;
            a.dataTime = a.dataTime?a.dataTime.replace('T',' '):dataTime;
            a.dataType = a.dataType?a.dataType:type;
            a.period = a.period ? a.period?.split('_')[0]:a.periodId?a.periodId?.split('_')[0]:period
            a.yaw = a.period ? a.period?.split('_')[1]:a.periodId?a.periodId?.split('_')[1]:yaw
            return a;
        });               
    }
    Jdata.ScanMode =$help.isNull(scanType)? jsonData[0]?.scanType:scanType;  
    Jdata.jsonData = jsonData;
    return Jdata;
}

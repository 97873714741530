

function quiver2(angle = 0,speed = 1,size= {width:30,height:30},config={svgId:null,strokeWidth:1,stroke:'#000',fill: '#000',rootClassName:'wind-quiver'}){
    var d3_selection_1 = require("d3-selection");   
    var svg = d3_selection_1.create('svg')
    .attr('viewBox', `-${size.width * speed/4}  -${size.height * speed/4}  ${size.width * speed} ${size.height * speed}`)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('width', size.width * speed)
    .attr('height', size.height * speed)
    .attr('overflow', 'visible');
    if (config.svgId) {
        svg.attr('id', config.svgId);
    }
    
    var container = svg.append('g');
    container
        .append('line')
        .attr('x1', 4.76)
        .attr('y1', 6.04)
        .attr('x2', 4.76)
        .attr('y2',28)
        .attr('stroke-width', config.strokeWidth)
        .attr('stroke', config.stroke)
        .attr('class', config.rootClassName);
    container
    .append('path')
    .attr('d','M4.76,0A30.59,30.59,0,0,1,0,8.55L4.76,6.83,9.51,8.55A30.31,30.31,0,0,1,4.76,0Z')
    .attr('stroke', config.stroke)
    .attr('fill', config.fill)
    .attr('x',size.width/2);
    //画箭头
    container
    
    .attr('transform-origin',`${size.width * speed/4}px  ${size.height * speed/4}px`)
    .attr('transform', `rotate(${angle})scale(${speed})`);
    return svg.node();

}

function quiver(target,scale = 1,a){
    var d3_selection = require("d3-selection");     
    let container = d3_selection.select(target)
    let points = container.selectAll('g.points');
    points.selectAll('g').remove();
    let pointsPath=  points.selectChildren('path');   
    pointsPath.each((d,i,n)=>{   
        if(a){
            if(d.x < a['xaxis.range[0]'] || d.x > a['xaxis.range[1]'] || d.y < a['yaxis.range[0]'] || d.y > a['yaxis.range[1]'])
            return;
        }     
        let node = d3_selection.select(n[i])        
        let data = node.data()[0]  
        if(data.data.speed * scale<0.4 || data.data.speed * scale > 1){
            
        }else{
            let trans = node.node().getAttribute('transform') 
            let c = points.append('g')
            c.attr('transform', `${trans}rotate(${data.data.angle})scale(${data.data.speed * scale})`)             
            c.append('line')
            .attr('x1', 4.76)
            .attr('y1', 6.04)
            .attr('x2', 4.76)
            .attr('y2',28)
            .attr('stroke-width', 1)
            .attr('stroke', 'black')

            c.append('path')
            .attr('d','M4.76,0A30.59,30.59,0,0,1,0,8.55L4.76,6.83,9.51,8.55A30.31,30.31,0,0,1,4.76,0Z')
            .attr('stroke', 'black')
            .attr('fill','black')
            .attr('x',6);   
        }        
    })
}

export default quiver;
<template>
    <v-card v-if="show" color="pa-2" width="600" height="362" min-width="540" min-height="360">
        <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-3 mt-12"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <v-row style="height: 48px;" class="ma-0 pa-0 d-flex justify-space-between">
            <h4 class="ma-1">{{title}}</h4>
            <v-icon class="pointer" @click="show=false">mdi-close</v-icon>            
            <v-card-actions class="ma-0 pa-0  btns d-flex align-end">
                <SearchDateTimePickerPlus  :maxRangeDay='7' range v-bind:datetime.sync="timeRange" />
               <v-text-field type="number" v-model="angle" style="width:64px;margin:0 4px;" @change="load" hide-details hide-spin-buttons dense label="角度(°)"></v-text-field>   
               <v-text-field  type="number" v-model="distance" style="width:64px;margin:0 4px;" @change="load" hide-details hide-spin-buttons dense label="距离(m)"></v-text-field>   
            </v-card-actions>
        </v-row>        
        <v-row  style="height: calc(100% - 48px);position: relative;" class="ma-0 pa-0  width100">       
            <div ref="chart"   class="plotychart width100 height100"></div>
        </v-row>
    </v-card>
</template>
<script>
import Plotly from 'plotly.js-dist'
import {getPlsPointDataApi ,getVlsPointDataApi } from '@/api'
export default {
    name:"monitorPointDataCard",
    props:{
        title:{
            type:String,
            default:"趋势图"
        },
        type:{
            type:String,
            default:"0"
        }
    },    
    data(){
        return{
            overlay:false,
            angle:0,
            distance:1,
            scanDistance:6,
            dataList:[],
            show:false,
            pointNo:'',
            siteId:'',
            siteName:'',
            timeRange:[]
        }
    },
    computed:{
        noData(){
            return !this.dataList||this.dataList.length == 0
        }
    },
    watch:{
       timeRange(n){
            this.load()
       },
       angle(){
        this.angle = this.angle < 0 ? 0:this.angle > 360 ? 360:this.angle
       },
       distance(){
            this.distance = this.distance <= 0 ? 1:this.distance > this.scanDistance * 1000 ? this.scanDistance * 1000:this.distance;
       }
    },
    mounted(){           
        this.timeRange = [new Date(new Date().setTime(new Date().getTime() - 24 * 60 * 60 * 1000)),new Date()]          
    },
    methods:{ 
       load(){    
        if(!this.siteId)   return;
        let that = this;
        this.overlay = true;
        let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss")
        let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss")
        if(this.type == 0){
            getPlsPointDataApi({
                id:this.siteId,
                params:{
                    scanDistance:this.scanDistance,
                    start:start,
                    end:end,
                    angle:this.angle,
                    distance:this.distance
                },
                success:(d)=>{
                    that.overlay = false;
                    that.dataList = d.map(a=>{
                        return {
                            time:a.DpsTime,
                            value:a.DpsValue
                        }
                    })
                    that.loadChart()
                }
            })
        }else{
            getVlsPointDataApi({
                id:this.siteId,
                params:{
                    scanDistance:this.scanDistance,
                    start:start,
                    end:end,
                    angle:this.angle,
                    distance:this.distance
                },
                success:(d)=>{
                    that.overlay = false;
                    that.dataList = d.map(a=>{
                        return {
                            time:a.DvvTime,
                            value:a.DvvValue
                        }
                    })
                    that.loadChart()
                }
            })
        }        
       },
       loadChart(){
            let target = this.$refs.chart;                              
            let xData = []; 
            let datas = this.dataList.sort((a,b)=>{                
                return new Date(a.time).getTime() - new Date(b.time).getTime()
            });
            
            datas.forEach((a,i)=>{                                            
                xData.push(new Date(a.time).pattern("HH:mm (dd日)"))                           
            });              
            var data = []
            data.push({
                type: 'scatter',
                y: this.dataList.map(a=>a.value),  
                x:xData,
                showlegend: true,
                mode: 'lines',
                name:'点位趋势图'
            })
            if(data.length == 0){
                data.push({
                    type: 'scatter',
                        y: [],  
                        x:[],
                        showlegend: true,
                        mode: 'lines',
                })
            }
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {  
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.1,
                    font:{
                        color: fontColor
                    }
                },     
                uirevision:'true',           
                xaxis: {  
                    color:fontColor,
                    nticks:5
                },
                yaxis: {
                    color:fontColor,
                    title:"值"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,  
                    l:42                        
                },
                
            };   
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});                     
       },
    }
}
</script>
<style scoped lang="scss">
.btns{
    width: 490px;
    position: absolute;
    float: right;
    right: 40px;
    top: 5px;
    z-index: 2;
}
.plotychart{
    :deep(.modebar){
        padding-right:20px ;
    }
}
</style>
<template>
    <router-view></router-view>
</template>
<script>
export default {
    name:"showLayout"
}
</script>
<style scoped>

</style>
<template>
    <v-container class="contain width100 height100 ma-0 pa-0 d-flex">   
        <v-card height="100%" width="50%" class="d-flex flex-column">
            <vizHeatmap style="height: 40%" v-if="currentScanType == 'FIX' || currentScanType == 'Qua'"></vizHeatmap>
            <prrHeatmap :style="currentScanType == 'FIX' || currentScanType == 'Qua' ? 'height: 60%':'height: 100%'" ></prrHeatmap>
        </v-card>
        <v-card height="100%"  width="50%">
            <v-tabs :value="tabValue" style="width: 100%;" align-with-title class="px-3 mt-1" center-active dark background-color="primary">
                <v-tabs-slider color="secondary"></v-tabs-slider>
                <v-tab @click="setShowChart(i)" v-show="getShowStatus(i)" v-for="i in showTypeList" :key="i.value">{{i.text}}</v-tab>
            </v-tabs>
            <v-row class="ma-0 pa-0 width100" style="height: calc(100% - 48px);">                
                <shear class="pt-0" :currentView="currentShowType" v-if="currentShowType == 3 || currentShowType == 9"></shear>    
                <quiver class="pt-0" v-if="currentShowType == 4"></quiver>
                <profile class="pt-0" :currentView="currentShowType"  v-if="currentShowType == 5 || currentShowType == 6 || currentShowType == 7 || currentShowType == 8"></profile>          
                <radial class="pt-0" :currentView="currentShowType" v-if="currentShowType == 0 || currentShowType == 1 || currentShowType == 2"></radial>
            </v-row>     
        </v-card>        
    </v-container>
</template>
<script>
import radial from '@/components/monitoring/vwls/realtime/radial.vue'
import shear from '@/components/monitoring/vwls/realtime/shear.vue'
import quiver from '@/components/monitoring/vwls/realtime/quiver.vue'
import profile from '@/components/monitoring/vwls/realtime/profile.vue'
import vizHeatmap from '@/components/monitoring/vwls/realtime/vizHeatmap.vue'
import prrHeatmap from '@/components/monitoring/vwls/realtime/prrHeatmap.vue'
export default {
   name:"vwls.realtime",
   components:{radial,shear,quiver,profile,vizHeatmap,prrHeatmap},data(){
        return {
            currentScanType:'',
            showShear:false,
            showTypeList:[
            // {
            //     text:"风切变",
            //     value:3,
            //     scanType:[0,2]
            // },{
            //     text:"矢量风",
            //     value:4,
            //     scanType:[0,2]
            // },
            {
                text:"风廓线风羽图",
                value:5,
                scanType:[3,4]
            },{
                text:"风廓线垂直风速时间热图",
                value:6,
                scanType:[3,4]
            },{
                text:"风廓线水平风速时间热图",
                value:7,
                scanType:[3,4]
            },{
                text:"风廓线水平风向时间热图",
                value:8,
                scanType:[3,4]
            },
            // {
            //     text:"风廓线垂直风切变时间热图",
            //     value:9,
            //     scanType:[3,4]
            // },
            {
                text:"径向风极地图",
                value:0,
                scanType:[0,2]
            },{
                text:"径向风时间热图",
                value:1,
                scanType:[0,1,2,3,4]
            },{
                text:"径向风折线图",
                value:2,
                scanType:[0,1,2,3,4]
            },],
            currentShowType:0,
            tabValue:0
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){                                
                var that = this;              
                //垂直扫描        
                if(!n.scanType){                     
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){                               
                               that.currentScanType = obj.scanType
                            }
                        }
                    })
                }else{
                    that.currentScanType = n.scanType
                }                 
            }
        },       
        "$store.state.storage_global.currentSite.scanType":{
            handler(n,v){        
                this.currentScanType = n       
            }
        },
        currentScanType(n){
            let tabs = this.showTypeList.filter(a=>this.getShowStatus(a));
            this.currentShowType = tabs[0]?.value;
        },
        currentShowType(n){
            this.tabValue = this.showTypeList.findIndex(a=>a.value == n)
        }
    },
    mounted(){
        var that = this;     
        if(this.$store.state.storage_global.currentSite.siteId){
            this.currentScanType = this.$store.state.storage_global.currentSite.scanType               
            if(!this.currentScanType){
                this.$store.dispatch('storage_user/getLastestScanType',{
                    site:this.$store.state.storage_global.currentSite,
                    cb:(obj)=>{                    
                        if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                            that.currentScanType = obj.scanType
                        }
                    }
                })
            }               
        }
        this.$nextTick(()=>{           
            that.showShear = !that.$store.state.storage_user?.user?.extendInfo?.wls?.shearHide
        })    
    },
    methods:{
        getShowStatus(item){      
            if(item.value == 3 && !this.showShear) {
                return false
            }
            if(this.currentScanType == "PPI" || this.currentScanType == 0) {
                if(item.scanType.indexOf(0) >= 0){
                    return true
                }                
            }
            else if(this.currentScanType == "FIX" || this.currentScanType == 1) {
                if(item.scanType.indexOf(1) >= 0){
                    return true
                }     
            }
            else if(this.currentScanType == "RHI" || this.currentScanType == 2) {
                if(item.scanType.indexOf(2) >= 0){
                    return true
                }     
            }
            else if(this.currentScanType == "Tri" || this.currentScanType == 3) {
                if(item.scanType.indexOf(3) >= 0){
                    return true
                }     
            }
            else if(this.currentScanType == "Qua" || this.currentScanType == 4) {
                if(item.scanType.indexOf(4) >= 0){
                    return true
                }     
            }    
            return false       
        },
        setShowChart(item){
            this.currentShowType = item.value    
        }
    }
}
</script>
<style scoped lang="scss">

</style>
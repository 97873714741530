<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">  
        <v-card class="width100 height100">
            <v-row class="ma-0 pa-0 d-flex justify-space-between">
                <v-card-title class="ma-0 pa-1 heatmapTitle">径向风</v-card-title>
                <v-card-actions>
                    <v-menu open-on-hover offset-y bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn     
                            v-show="false"                                                                                     
                            v-bind="attrs"
                            v-on="on"
                            >
                            {{currentMap.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentMap">
                                <v-list-item
                                    v-for="(item, index) in mapConfigList"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>  
                    <v-radio-group v-show="false" class="mt-0" dense hide-details hide-spin-buttons row v-model="sampling" label="采样程度：">
                        <v-radio label="流畅" :value="100"></v-radio>
                        <v-radio label="中等" :value="400"></v-radio>
                        <v-radio label="详细" :value="800"></v-radio>
                    </v-radio-group>
                </v-card-actions>
                <ColorBarConfig @update="timerDraw" :dataKey="50" ref="colorBar" class="mapColorBarConfig"/>                    
            </v-row>        
        <v-row class="rowHeight ma-0" v-show="currentMap.value == 0">
            <v-card height="100%" width="100%" elevation="1">                
                <v-overlay
                    absolute
                    :value="overlay"
                    >
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-overlay
                    class="ma-3 mt-11"
                        absolute
                        :value="noData"
                        opacity="0.2"
                        >
                    暂无数据
                </v-overlay>
                <v-card-title class="ma-0 pa-1 btns">                    
                    <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                    <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>                                                              
                </v-card-title>  
                <div class="mx-1" style="position:absolute;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>              
                <div ref="polar" class="width100 height100 plotychart">
                </div>
            </v-card>
        </v-row>        
        <v-row class="rowHeight ma-0" v-show="currentMap.value ==1">
            <v-card width="100%" height="100%">
                <v-overlay
                    absolute
                    :value="overlay"
                    >
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <v-overlay
                        class="ma-3 mt-11"
                            absolute
                            :value="noData"
                            opacity="0.2"
                            >
                        暂无数据
                    </v-overlay>
                <div ref="heatmap" class="width100 height100 "></div>
            </v-card>
        </v-row>        
        <v-row class="rowHeight ma-0" v-show="currentMap.value ==2">
            <v-card width="100%" height="100%">
                <v-overlay
                    absolute
                    :value="overlay"
                    >
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-overlay
                    class="ma-3 mt-11"
                        absolute
                        :value="noData"
                        opacity="0.2"
                        >
                    暂无数据
                </v-overlay>
                <div class="mx-1" style="position:absolute;z-index: 2;">{{lineData?.dataTime}} 偏航角:{{yaw}}°</div>              
                <v-card-title class="ma-0 pa-1 btns">                    
                    <v-icon @click="playLine('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                    <v-icon @click="playLine('next')" title="下一帧">mdi-skip-next</v-icon>                                                              
                </v-card-title>
                <div ref="line" class="width100 height100  plotychart">

                </div>
            </v-card>
        </v-row> 
        </v-card>           
    </v-container>
</template>
<script>
import { getWlsRadialByTimeApi,getWlsRadialByTimeRangeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:'wls.realtime.radial',
    props:{
        timeRange:{
            type:Array
        },
        currentScanType:{
            type:String
        },
        currentView:{
            type:Number,
            default:0
        }
    },
    components: {
        ColorBarConfig
      },
    data(){
        return { 
            mapConfigList:[{
                name:"极地图",
                value:0
            },
            {
                name:"时间热图",
                value:1
            },
            {
                name:"折线图",
                value:2
            }],
            overlay:false,
            dataList:[],
            lineData:null,            
            nextPeriod:'',
            prePeriod:'',
            currentMap:{
                value:0,
                name:"极地图"
            },
            timer:null,
            sampling:100
        }
    },
    watch:{
        timeRange:{
            handler(n){                        
                if(n.length > 0){                                         
                    this.nextPeriod = ''
                    this.dataList = [];
                    this.lineData = null;
                    this.load()
                }
            },
            deep:true
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.dataList = []                
                this.lineData = null;
                this.load()                       
            }
        }, 
        currentScanType(n){
            this.nextPeriod = ''
            this.prePeriod = ''
            // if(n == "FIX" || n=='Tri'){
            //     this.mapConfigList = [                   
            //     {
            //         name:"时间热图",
            //         value:1
            //     },
            //     {
            //         name:"折线图",
            //         value:2
            //     }]
            //     if(this.currentMap.value != 1){
            //         this.currentMap = {
            //             name:"时间热图",
            //             value:1
            //         }
            //     }
            // }else{
            //     this.mapConfigList = [{
            //         name:"极地图",
            //         value:0
            //     },
            //     {
            //         name:"时间热图",
            //         value:1
            //     },
            //     {
            //         name:"折线图",
            //         value:2
            //     }]
            // }
            setTimeout(() => {
                this.dataList = [];
                this.lineData = null;
                this.load()
            }, 200);           
        },
        currentMap(n){
            let that = this;
            if(n.value == 1){                
                setTimeout(() => {
                    that.load()
                }, 400);                
            }
            if(n.value == 2){
                setTimeout(() => {                    
                    that.loadLine();
                }, 400);
            }
            if(n.value == 0){
                setTimeout(() => {                    
                    that.loadChart();
                }, 200);
            }         
        },
        currentView(n){
            this.currentMap = this.mapConfigList[n]
        },
        sampling(){
            this.load()
        }
    },
    computed:{
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        },
        time(){
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            let period = this.dataList[0]?.period?.split('_')[0]
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }  
        },
        yaw()
        {
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            return this.dataList[0]?.yaw
        }
    },
    mounted(){
        var that = this;
        this.currentMap = this.mapConfigList[this.currentView]
        if(this.currentScanType == "FIX"){
            // this.mapConfigList = [                   
            //     {
            //         name:"时间热图",
            //         value:1
            //     },
            //     {
            //         name:"折线图",
            //         value:2
            //     }]
            if(this.currentMap.value != 1){
                this.currentMap = {
                    name:"时间热图",
                    value:1
                }
            }
        }
        setTimeout(() => {  
            that.dataList = [];
            that.lineData = null;          
            that.load()    
        }, 600);        
    },
    methods:{
        load(desc = false,start,end,datas=[]){
            var that = this;
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }
            this.overlay = true;      
            start = start ? start: this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            end =end ? end:desc?start: this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") 
            let period = desc? this.prePeriod : this.nextPeriod      
            if(this.currentScanType == 'FIX' || this.currentMap.value == 1 || this.currentScanType == "Tri"){
                let endTimeStr =  end 
                getWlsRadialByTimeRangeApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{
                        StartTime:start,                  
                        EndTime:endTimeStr,
                        PageSize:7000 ,//* (800/this.sampling)/2, 
                        //PageLoadSize:this.sampling,
                        ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0                                 
                    },
                    success:(d)=>{ 
                        that.overlay = false; 
                        if(!d.Data || d.Data.length == 0)  {
                            if(that.currentMap.value == 1){                                                       
                                that.loadHeatmap();
                            }else if(that.currentMap.value == 0){
                                if(that.dataList.length > 1){
                                    that.loadChart();
                                }                        
                            }else if(that.currentMap.value == 2){
                                that.loadLine();                     
                            }                 
                        }                                                                  
                        let _datas = d.Data?.map(a=>{
                            return {
                                dataTime:a.DwrTime.replace('T',' '),
                                distanceData:a.DwrDis,
                                angle:a.DwrScanMode == 0 ? a.DwrStraight:a.DwrScanMode == 2?a.DwrPitch:a.DwrStraight,                        
                                scanMode:scanType[a.DwrScanMode],
                                straightAngle:a.DwrStraight,
                                angleOfPitch:a.DwrPitch,
                                data:a.DwrValue,
                                period:a.DwrPeriod.split('_')[0],   
                                yaw:a.DwrPeriod.split('_')[1],
                            }
                        });  
                        datas = _datas.concat(datas)    
                        if(endTimeStr == end){     
                            that.dataList = datas;
                            let _dataList = datas.sort((a,b)=>{
                                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime() 
                            })
                            let currentPeriod = _dataList[0].period;
                            for(let p of _dataList){
                                if(p.period !=currentPeriod ){
                                    that.nextPeriod = p.period
                                    break;
                                }
                            }
                            that.lineData = that.dataList[0]        
                            if(that.currentMap.value == 1){                                                       
                                that.loadHeatmap();
                            }else if(that.currentMap.value == 0){
                                if(that.dataList.length > 1){
                                    that.loadChart();
                                }                        
                            }else if(that.currentMap.value == 2){
                                that.loadLine();                     
                            }         
                        }else{                            
                            that.dataList = datas;
                            if(that.currentMap.value == 1){                                                       
                                that.loadHeatmap();
                            }else if(that.currentMap.value == 0){
                                if(that.dataList.length > 1){
                                    that.loadChart();
                                }                        
                            }else if(that.currentMap.value == 2){
                                that.loadLine();                     
                            }                             
                            that.load(desc,endTimeStr,end,datas) 
                        }                        
                    }
                })
            }else{
                getWlsRadialByTimeApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{
                        Period:period,                  
                        Time:start,
                        LimitTime:end,
                        Desc:desc,
                        ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0                  
                    },
                    success:(d)=>{                        
                        that.overlay = false;   
                        if(!d.Data || d.Data.length == 0)  {    
                            if(that.currentMap.value == 1){                                                       
                                that.loadHeatmap();
                            }else if(that.currentMap.value == 0){
                                if(that.dataList.length > 1){
                                    that.loadChart();
                                }                        
                            }else if(that.currentMap.value == 2){
                                that.loadLine();                     
                            }         
                        }                       
                        that.nextPeriod = d?.NextPeriod?.split("_")[0]
                        that.prePeriod = d?.PrePeriod?.split("_")[0]  
                        
                        that.dataList = d.Data?.map(a=>{
                            return {
                                dataTime:a.DwrTime.replace('T',' '),
                                distanceData:a.DwrDis,
                                angle:a.DwrScanMode == 0 ? a.DwrStraight:a.DwrScanMode == 2?a.DwrPitch:a.DwrStraight,                        
                                scanMode:scanType[a.DwrScanMode],
                                straightAngle:a.DwrStraight,
                                angleOfPitch:a.DwrPitch,    
                                data:a.DwrValue,
                                period:a.DwrPeriod.split('_')[0],
                                yaw:a.DwrPeriod.split('_')[1]
                            }
                        }).sort((a,b)=>{
                            return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime() 
                        });      
                        that.lineData = that.dataList[0]           
                        if(that.currentMap.value == 1){                                                       
                            that.loadHeatmap();
                        }else if(that.currentMap.value == 0){
                            if(that.dataList.length > 1){
                                that.loadChart();
                            }                        
                        }else if(that.currentMap.value == 2){
                            that.loadLine();                     
                        }         
                    }
                })
            }             
        },
        async loadHeatmap(){
            let target = this.$refs.heatmap; 
            if(!target || this.currentMap.value != 1 || !this.$refs.colorBar){
                return
            }
            if(!this.dataList||this.dataList?.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                    {
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[]
                    }
                ];

                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                                
                    xaxis: {
                        color:fontColor, 
                        nticks:$help.getNtick(target.clientWidth)    
                    },
                    yaxis: {
                        color:fontColor,  
                        title:"距离(km)"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                    }
                };
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});
                return;
            }
            
            let _datas =[]; 
            let spanTime = (this.timeRange[1].getTime() - this.timeRange[0].getTime()) / 1000 / 60//            
            let samplingValue =spanTime >= 60 && spanTime <120 ? 3: spanTime >= 120 && spanTime <720 ? 4 : spanTime >= 720 && spanTime <1440 ? 6 :spanTime >= 1440 ? 11:1            
            let minSpan = 1 * samplingValue;                  
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){     
                            j+=minSpan;                     
                            _datas.push({
                                distanceData:this.dataList[i].distanceData,
                                angle:NaN,                        
                                scanMode:this.dataList[i].scanMode,
                                straightAngle:NaN,
                                angleOfPitch:this.dataList[i].angleOfPitch,       
                                data:Array.from({length:this.dataList[i].data.length},(val, i) => NaN),
                                period:this.dataList[i].period,                                
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),                                                                                                                   
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = []; 
            let noDataLength = -(datas[0].distanceData[0])/datas[0].distanceData[1]//无效距离            
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];    
            // let ym = 40;
            // let therodsy =Math.floor((datas[0].data.length -2) / ym);            
            // //降采样y
            // for(var i in datas){
            //     //对y轴降采样
            //     datas[i].arrSampling = $help.sampling(datas[i].data,therodsy)             
            // }

            datas.forEach((a,i)=>{                                    
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    let _index = j+noDataLength
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    //if(a.arrSampling.indexOf(j)){
                        zData[j].push(a.data[_index])
                    //}else{
                    //    zData[j].push(NaN)
                    //}                    
                    hoverText[j].push(`值：${a.data[_index]}<br />距离：${yData[j]}(Km)<br />水平角：${a.straightAngle}°<br />偏航角：${this.yaw}°<br />俯仰角：${a.angleOfPitch}°<br />时间：${new Date(a.dataTime).pattern("HH:mm:ss")}` )                            
                }     
            });              
        
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max,
                hoverinfo:'text'
                }
            ];

            var layout = {
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                                
                xaxis: {
                    color:fontColor, 
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)    
                },
                yaxis: {
                    color:fontColor,  
                    title:"距离(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },
        loadLine(){
            let target = this.$refs.line            
            if(!target || this.currentMap.value != 2){
                return
            }
            if(!this.lineData){                
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                
                Plotly.newPlot(target, [{
                    type: 'line',               
                    y: [],    
                    x:[],                          
                    showlegend: false,
                    hovertext:[],
                    mode: 'lines',
                    name:'径向',                   
                }], {  
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,
                    legend: {
                        orientation:"h",
                        x: 1,
                        xanchor: 'right',
                        y: 1,
                        font:{
                            color: fontColor
                        }
                    },
                    xaxis: {  
                        color:fontColor,
                        nticks:20
                    },
                    yaxis: {
                        color:fontColor,
                        title:"距离(km)"
                    },
                    margin:{
                        t:48,
                        r:42,  
                        l:42,
                        b:42                        
                    }
                }, {responsive: true,scrollZoom: true});
                return;
            }
            let yData = [];                   
            for(let i = 0;i< this.dataList[0].distanceData[2];i++){                
                let y = this.dataList[0].distanceData[0] + i* this.dataList[0].distanceData[1]
                yData.push(y.toFixed(2))
            }     
            let xData =  this.lineData.data;
            let data = [{
                type: 'line',               
                y: yData,    
                x:xData,                          
                showlegend: false,
                hovertext:[],
                mode: 'lines',
                name:'径向',                   
            }]
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = {  
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1,
                    font:{
                        color: fontColor
                    }
                },
                xaxis: {  
                    color:fontColor,
                    nticks:20,
                    range:[-5,5]
                },
                yaxis: {
                    color:fontColor,
                    title:"距离(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:48,
                    r:42,  
                    l:42,
                    b:42                        
                }
            }; 
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },
        loadChart(){   
            let d = 3.3              
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;   
            let target = this.$refs.polar
            if(!target||this.currentMap.value != 0){
                return;                
            }
            if(this.dataList.length == 0){        
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                           
                Plotly.newPlot(target, [{
                    type: "scatterpolargl",
                    r: [],
                    theta: [],
                    text: [],
                    mode: "markers",
                    name: "",
                    marker: {
                        color: `rgba(0,0,0,0)`,
                        size: 3,                         
                        opacity: 0.7
                    },
                    cliponaxis: false
                    }], 
                    {  
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,              
                        polar: {                    
                            radialaxis: {   
                                color:fontColor,                     
                                visible: true,
                                range: [0, d]
                            },
                            angularaxis: {
                                color:fontColor,
                                tickfont: {
            
                                },
                                direction: "clockwise"
                            }
                        },
                        showlegend: false,
                        margin:{
                            t:42,
                            r:42,
                            l:42,
                            b:32                        
                        }
                    },{responsive: true,scrollZoom: true})
                return;
            }
            var that = this;
            let polarData = []
            let noDataLength =  (this.dataList[0].distanceData[0])/this.dataList[0].distanceData[1]//无效距离
            let scanLength = this.dataList[0].distanceData[2]*this.dataList[0].distanceData[1]
            $help.drawScan(this.dataList,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{
                    let r = that.dataList[0].distanceData[0] + that.dataList[0].distanceData[1] *  obj.dIndex
                    let pitch=that.dataList[0].angleOfPitch;
                    polarData.push({
                        color: `rgba(${color.join(',')})`,
                        r:r,
                        angle:obj.angle,
                        value:obj.value,
                        pitch:pitch
                    })                       
                })
            },()=>{
                let group = $help.groupBy('color',polarData)
                let data = [];
                for(var i in group){
                    let item = group[i]
                    data.push({
                        type: "scatterpolargl",
                        r: item.map(a=>a.r.toFixed(2)),
                        theta: item.map(a=>a.angle.toFixed(2)),
                        text: item.map(a=>
                        `值：${a.value.toFixed(6)}<br />距离：${a.r.toFixed(2)}(Km)<br />水平角：${a.angle.toFixed(2)}°<br />俯仰角：${a.pitch}°`
                        ),
                        mode: "markers",
                        name: "",
                        marker: {
                            color: i,
                            size: 3,                         
                            opacity: 0.7
                        },
                        hoverinfo:'text',
                        cliponaxis: false
                    })
                }  
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                         
            let layout = { 
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                 
                polar: {                    
                    radialaxis: {    
                        color:fontColor,                     
                        visible: true,
                        range: [0, d]
                    },
                    angularaxis: {
                        color:fontColor, 
                        tickfont: {
      
                        },
                        direction: "clockwise"
                    }
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                showlegend: false,
                margin:{
                    t:38,
                    r:56,  
                    l:56                        
                }
            }            
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});           
            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:3.3,rp:50,yaw:yaw})           
        },        
        play(status){                   
            if(status == 'next'){
                if((!this.nextPeriod || Number(this.timeRange[1].pattern("yyyyMMddHHmmss")) - Number(this.nextPeriod) <= 0) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }  
                
                this.dataList = [];
                this.lineData = null;   
                this.load()        
            }else{
                if(!this.prePeriod || Number(this.timeRange[0].pattern("yyyyMMddHHmmss")) - Number(this.prePeriod) >= 0) {
                    openNotice({
                        type:"info",
                        text:'已加载到开始'
                    })                        
                    return
                }
                this.dataList = [];
                this.lineData = null;
                this.load(true)            
            }
        },
        playLine(status){
            if(this.dataList.length == 0){
                this.lineData = null;
                return;
            }
            let _currentIndex = this.dataList.findIndex(a=>a.dataTime == this.lineData.dataTime)
            if(status == 'next'){
                if((_currentIndex+1> this.dataList.length - 1) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }       
                this.lineData = this.dataList[_currentIndex+1]           
                this.loadLine();
            }else{
                if(_currentIndex-1< 0) {
                    openNotice({
                        type:"info",
                        text:'已加载到开始'
                    })                        
                    return
                }
                this.lineData = this.dataList[_currentIndex-1]           
                this.loadLine();
            }
        },timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                if(that.currentMap.value == 0){
                    that.loadChart()
                }else if(that.currentMap.value == 1){
                    that.loadHeatmap()
                }                
            }, 1000/3);
        }       
    }
}
</script>
<style scoped lang="scss">
.rowHeight{
    height: calc(100% - 40px);
    width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 10%;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 70%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 82px;
    right: 20px;    
}.heatmapTitle{
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.btns{
    position: absolute;
    z-index: 2;
    right: 60px;
    top: 10px;
}
.plotychart{
    :deep(.modebar){
        padding-top:32px;
        padding-right:32px ;
    }
}
</style>
<template>
    <v-card class="width100 height100">  
        <v-card-title class="ma-0 pa-1 scanmapTitle">能见度扫描</v-card-title>
        <v-card class="mx-1" style="top:-2px;z-index:2;position: absolute;right: 120px;">
            <v-card-actions class="d-flex justify-space-around ma-0 pa-1">
                <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>
                <v-icon v-show="(playStatus != 'play') && (playStatus != 'replay')" @click="play('play')">mdi-play</v-icon>
                <v-icon v-show="(playStatus == 'play') || (playStatus == 'replay')" @click="play('pause')" >mdi-pause</v-icon>
                <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>   
                <v-icon style="font-size:22px;padding:4px;" @click="play('replay')">mdi-replay</v-icon>       
                <v-icon style="font-size:22px;padding:4px;"  @click="downloadImgToVideo()">mdi-download</v-icon>       
            </v-card-actions>
        </v-card>
        <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />     
        <pointDataCard type="1" ref="monitorpointCard" class="monitorpointCard" ></pointDataCard>     
        <div ref="scanMap" class="map noevent">                            
        </div>                                                                                                                              
        <ColorBarConfig v-show="false" :dataKey="9" ref="colorBar" disabled  class="mapColorBarConfig" />                                                                
    </v-card>
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import weatherType from '@/plugins/datas/weatherType.json'
import transferDataType from '@/plugins/datas/transferDataType'
import {getHistoryImagesApi,getImageApi,getVlsPositionDataApi} from '@/api' 
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { uploadMinioURL } from '@/api/url'
import customProtocolCheck from "custom-protocol-check";
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
import pointDataCard from '@/components/cardMenu/pointDataCard'
import sysconfig from '@/plugins/datas/sysconfig'
export default {
    name:"component.monitoring.vls.realtime",
    props:{
        timeRange:{
            type:Array
        },
        settime:{
            type:Date
        }
    },
    components:{ColorBarConfig,monitorPointDataCard,pointDataCard},
    data(){
        return {            
            scanDistance:6,
            onboarding:'relaScanMap',
            map:null,
            mapEchart:null,
            center:[],
            mapContextMenuPositon:[],
            weatherInfo:{
            },        
            alarmList:[],
            warnMakerList:[],

            siteMarker:null,
            //画图
            imageList:[],
            canvas:null,
            ctx:null,
            canvasLayer:null,
            opacity:0.7,        
            vAngle:0,
            setOpacity:null,
            timeLoadImg:null,//防重复加载
            monitorPoints:[],//控点
            monitorPointMarkers:[],    //控点标记    
            timmer:null,
            playStatus:'pause',// 播放状态
            movePlayStatus:false,
            moveTimeout:null,
            currentImageIndex:0,
            //按钮
            PPI_ScanTypes:[],//当前站点水平数据类型        
            dataType:9,
            currentPeriod:''
    }},
    beforeUnmount(){
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
    }, 
    computed:{
        icon:()=>{
            return(key)=>{
                var item = weatherType.find(a=>a.name == key);
                if(item){
                    return item.value
                }else{
                    return 'mdi-biohazard'
                }
            }
        },        
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },  
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                var that = this;  
                if(this.$store.state.storage_global.currentSite.extendInfo){            
                    let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }                  
                this.$nextTick(()=>{   
                    //that.play('paluse')
                    that.imageList = [];
                    that.currentImageIndex = 0  
                    that.load();                                                                        
                })                        
            }
        },   
        timeRange:{
            handler(){           
                this.loadImgs();                     
                this.currentImageIndex = 0       
            }
        },
    },
    mounted(){     
        var that = this;  
        var target = that.$refs.scanMap   
        if(this.$store.state.storage_global.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
        } 
        if(this.$store.state.storage_global.currentSite.siteId){                        
            this.$nextTick(()=>{ 
                //检查画图情况       
                that.load(that.settime)                
            })
        }                   
        
    },
    methods:{       
        load(t){                                         
            var that = this;            
            if(this.timeLoadImg){
                clearTimeout(that.timeLoadImg)
            }            
            this.timeLoadImg = setTimeout(() => {  
                that.loadMap(); 
                if(that.timmer){
                    clearInterval(that.timmer)
                }
                that.loadImgs(t);                                   
            }, 100);       
        },
        loadMap(){
            let target = this.$refs.scanMap;          
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;        
            if(!this.map){
                this.map = mapEchart.getModel().getComponent('amap').getAMap();
                //添加右键菜单
                this.addContextMenu();
                //加载控件 
                this.addControl(); 
            }      
            this.map = mapEchart.getModel().getComponent('amap').getAMap();       
            var that = this;            
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
            }); 
            that.siteMarker.on('click',(e)=>{                                
                    new AMap.InfoWindow({
                        content: `
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${e.lnglat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>                     
                       `,                        
                    }).open(that.map,e.lnglat)
                })
            that.map.add(that.siteMarker)
            that.map.on("movestart",function(a,b,c,d,e){   
                if(that.moveTimeout){
                    clearTimeout(that.moveTimeout)
                }
                that.moveTimeout = setTimeout(() => {
                    if(that.playStatus == 'play' || that.playStatus == 'replay'){
                        that.movePlayStatus = true;                                  
                    }else{
                        that.movePlayStatus = false;
                    }                              
                    that.play('pause');
                }, 200);                
            })
            that.map.on("moveend",function(a,b,c,d,e){                    
                if(that.movePlayStatus){
                    that.play('play');
                }                                                            
            })
             that.map.on("zoomend",function(a,b,c,d,e){                    
                setTimeout(() => {
                    if(that.movePlayStatus){
                        that.play('play');
                    }
                }, 220);                                                                            
            })
            
            this.loadMapOver = true;
            //加载国控点
            this.loadMonitPoint()
        },
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
        },
        loadImgs(t){
            let _width = this.scanDistance * 100
            if(this.ctx && this.canvasLayer) {                
                this.ctx.clearRect(0,0,_width,_width)
                this.canvasLayer.reFresh();
            }    
            let site = this.$store.state.storage_global.currentSite;
            if(!this.timeRange[0]){
                return
            }
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //    
            var that = this;   
            this.imageList = [];                       
            //获取图片
            getHistoryImagesApi({
                id:site.siteId,
                params:{
                    DirTypeId:this.dataType,
                    StartTime:start,
                    EndTime:end,
                    PageIndex:1,
                    PageSize:2000
                },success:(d)=>{
                    let imgInfoList = d.Data.map(a=>{
                        return{
                            path:`${uploadMinioURL}${a.DirBucketName}/${a.DirObjectName}`,
                            siteId:a.DirSiteId,
                            period:a.DirPeriod,
                            time:new Date(a.DirTime.replace("T"," ")),
                            type:a.DirTypeId,
                            uploadTime:new Date(a.DirUploadTime.replace("T"," "))
                        } 
                    }).sort((a,b)=>{
                        return a.time - b.time
                    })
                    if(imgInfoList.length <= 0){
                        openNotice({
                            type:"error",
                            text:this.$i18n.t("history.notice.noImg")
                        })
                        return;
                    }    
                    that.imageList = imgInfoList;                                          
                    if(that.imageList.length > 0){
                        if(t){
                            let _currTime = 0
                            for(let i in that.imageList){
                                if(i == 0 || Math.abs(that.imageList[i].time - t) < _currTime ){
                                    _currTime = Math.abs(that.imageList[i].time - t) 
                                    that.currentImageIndex = Number(i);                                                                                                                      
                                }
                            }   
                            that.loadCurrentImage();                             
                        }else{                            
                           that.play('play')                                
                        }                        
                    }                    
                }            
            })
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //比例尺
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '160px',left: '50px' },
                    theme:'myself'
                }));

                //3d指南针控件
                var controlBar = new AMap.ControlBar({
                    position:{bottom: '150px',left: '-70px' },
                    theme:'myself'
                });
                that.map.addControl(controlBar) 
                
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:100px;left:50px;';                       
                if(that.canvasLayer || that.opacity){                    
                    that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
                }else{
                    that.opacity = 0.7
                }                      
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min="1" max="10" value="${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = (value)=>{                   
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }                    
                }
                _rest.addEventListener('click',(e)=>{                                                                          
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(0.7)
                    }                      
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }  
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);  

                //地点定位                
                let styleP = 'cursor:pointer;bottom:50px;left:55px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);    
            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.35 16 12.69 15.97 13.03 15.95C13 15.8 13 15.65 13 15.5C13 14.96 13.09 14.44 13.24 13.94C12.83 14 12.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V10.03C18.17 10 18.33 10 18.5 10C19 10 19.5 10.08 20 10.22V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C13.06 21 14.07 20.89 15 20.71C14.62 20.14 14.25 19.5 13.93 18.85C13.34 18.94 12.71 19 12 19M12 5C15.87 5 18 6.5 18 7S15.87 9 12 9 6 7.5 6 7 8.13 5 12 5M18.5 12C16.6 12 15 13.6 15 15.5C15 18.1 18.5 22 18.5 22S22 18.1 22 15.5C22 13.6 20.4 12 18.5 12M18.5 16.8C17.8 16.8 17.3 16.2 17.3 15.6C17.3 14.9 17.9 14.4 18.5 14.4S19.7 15 19.7 15.6C19.8 16.2 19.2 16.8 18.5 16.8Z" fill="black"/>
            </svg>
            位置数据</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                let site = that.$store.state.storage_global.currentSite;
                let siteCenter = [site.lng,site.lat]
                let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                if(dis < that.scanDistance * 1000){
                    let angle = $help.bearing(siteCenter,lnglat)
                    angle = angle >= 0 ? angle :angle +360                    
                    angle = angle - that.$store.state.storage_global.currentSite.yaw < 0 ? angle - that.$store.state.storage_global.currentSite.yaw + 360 : angle - that.$store.state.storage_global.currentSite.yaw;
                    if(!that.currentPeriod){
                        openNotice({
                            type:'error',
                            text:"未选择周期"
                        })
                    }
                    getVlsPositionDataApi({
                        id:site.siteId,
                        period:that.currentPeriod,
                        params:{
                            angle:angle.toFixed(2),
                            distance:dis,
                            scanDistance:that.scanDistance * 1000
                        },
                        success:(d)=>{
                            let msg = `<div>点位值：${d}</div>`                     
                                new AMap.InfoWindow({
                                    content: msg,                        
                                }).open(that.map,lnglat);
                        }
                    })
                }                
                else{
                    openNotice({
                        type:'error',
                        text:"所选区域超出范围"
                    })
                }
            }, 1);  
            // contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            // align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            //     <path d="M4 19V20H22V22H2V2H4V17C7 17 10 15 12.1 11.4C15.1 6.4 18.4 4 22 4V6C19.2 6 16.5 8.1 13.9 12.5C11.3 16.6 7.7 19 4 19Z" fill="black"/>
            // </svg>
            // 位置趋势</div>`, function () {
            //     let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
            //     let site = that.$store.state.storage_global.currentSite;
            //     let siteCenter = [site.lng,site.lat]
            //     let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
            //     if(dis < that.scanDistance * 1000){
            //         let angle = $help.bearing(siteCenter,lnglat)
            //         angle = angle >= 0 ? angle :angle +360                    
            //         angle = angle - that.$store.state.storage_global.currentSite.yaw < 0 ? angle - that.$store.state.storage_global.currentSite.yaw + 360 : angle - that.$store.state.storage_global.currentSite.yaw;                   
            //         that.$refs.monitorpointCard.angle = (angle).toFixed(2)
            //         that.$refs.monitorpointCard.distance = dis.toFixed(2)
            //         that.$refs.monitorpointCard.scanDistance = that.scanDistance * 1000
            //         that.$refs.monitorpointCard.siteId = that.$store.state.storage_global.currentSite.siteId
            //         that.$refs.monitorpointCard.show = true     
            //         setTimeout(()=>{
            //             that.$refs.monitorpointCard.load();
            //         },200)          
            //     }                
            //     else{
            //         openNotice({
            //             type:'error',
            //             text:"所选区域超出范围"
            //         })
            //     }
            // }, 2);   
            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },        
        play(status){                                    
            this.playStatus = status;            
            var that = this;                       
            if(this.imageList.length > 0){   
                if(this.playStatus == 'play'){
                    //播放                               
                    if(this.timmer!= null){                 
                        this.canvasLayer.reFresh();
                        clearInterval(this.timmer)                        
                    }     
                    this.timmer = setInterval(() => {                                                                             
                        if(that.$route.path.replace('/m/','/') !="/monitoring/vls/history"){
                                clearInterval(that.timmer)
                            }
                            if(that.currentImageIndex > that.imageList.length -1) {
                                that.currentImageIndex = that.imageList.length -1;                            
                                clearInterval(that.timmer)
                                this.playStatus = 'pause'   
                                openNotice({
                                    type:"info",
                                    text:that.$i18n.t("history.notice.loadend")
                                })                                                 
                            }
                            that.loadCurrentImage();
                            that.currentImageIndex = that.currentImageIndex > that.imageList.length -1 ? 0: that.currentImageIndex;                        
                            that.currentImageIndex +=1;
                    }, 1000);
                
                }else if(this.playStatus == 'replay'){
                    //重播
                    that.currentImageIndex = 0;
                    if(that.timmer!= null){
                        clearInterval(that.timmer)
                    }                    
                    that.timmer = setInterval(() => {                    
                        that.loadCurrentImage();
                        that.currentImageIndex +=1;
                        if(that.currentImageIndex > that.imageList.length -1) {
                            that.currentImageIndex = 0;
                            clearInterval(that.timmer)
                            that.playStatus = 'pause'
                        }
                    }, 800);
                    
                }else if(this.playStatus == 'pause'){
                    //暂停
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  

                }else if(this.playStatus == 'next'){
                    //下一张
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  
                    if(that.currentImageIndex+1 > that.imageList.length -1) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgEnd")
                        })
                        return;
                    }
                    that.currentImageIndex +=1;
                    that.loadCurrentImage();
                }else if(this.playStatus == 'pre'){
                    //前一张
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  
                    if(that.currentImageIndex-1 < 0) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgStart")
                        })
                        return;
                    }
                    that.currentImageIndex -=1;
                    that.loadCurrentImage();
                }

            }else{
                return;
            }       
        
        },
        loadCurrentImage(){                        
            var that = this;
            if(that.imageList.length == 0 || that.currentImageIndex >= that.imageList.length){
                return 
            }            
            let imgInfo =that.imageList[that.currentImageIndex];
            let imgPath = imgInfo.path;                          
            let time = imgInfo.time.pattern('yyyy-MM-dd HH:mm:ss')
            let imgParams = imgPath.match(/(?<=\@).*?(?=\@)/g);
            let arr=[]  
            let _width = this.scanDistance * 100;   
            let w = _width,h = _width;  //图片长宽为600       
            if(!imgParams){
                arr =$help.getLayerMapPosition(90,this.scanDistance,this.center)   
            }else{
                let splitValue = imgParams[0].split("_")
                let _widthImage = Number(splitValue[0]);
                let vAngle = Number(splitValue[1]);   
                let baseW = Number(splitValue[2])
                let _scanDistance = $help.isNull(splitValue[3]) ? this.scanDistance:Number(splitValue[3]);
                w = h = _width= _scanDistance * 100                                    
                arr =arr =$help.getLayerMapPosition(vAngle,_scanDistance,this.center,_widthImage/baseW) 
                let _info = imgPath.split(this.$store.state.storage_global.currentSite.siteId);
                this.currentPeriod = _info[1].replace(imgParams,'').replace('_9@@.png','').replace('_','')
            }                                   
            if(!this.canvas){
                let canvas = document.createElement('canvas')
                //canvas = this.$refs.polar;
                let ctx =canvas.getContext("2d"); 
                canvas.width = w;
                canvas.height = h;               
                this.canvas = canvas;
                this.ctx = ctx;
            }      
           
            //更新图
            var bounds = new AMap.Bounds(arr[0],arr[1]) 
            if(!this.canvasLayer){
                this.canvasLayer = new AMap.CanvasLayer({
                    canvas: this.canvas,
                    bounds: bounds,  
                    opacity:this.opacity,  
                    zooms: [2, 30]            
                });        
                this.map.add(this.canvasLayer);
            }else{
                if(this.canvas.width != _width){
                    this.canvas.width = w;
                    this.canvas.height = h;
                    this.canvasLayer.setCanvas(this.canvas)
                }                
                this.canvasLayer.setBounds(bounds)                                  
            }       
            getImageApi(imgPath,(d)=>{               
                let imgsrc = URL.createObjectURL(d.data)
                let img = new Image(w,h)
                img.alt = imgPath;
                img.crossOrigin ="anonymous"
                img.src = imgsrc;
                img.onload=(f)=>{                    
                    if(f.currentTarget.alt.indexOf(that.$store.state.storage_global.currentSite.siteId) > 0){
                        //更新显示时间     
                        that.drawTimeText(time)                            
                        that.ctx.clearRect(0,0,w,h)            
                        that.ctx.drawImage(f.currentTarget,0,0,w,h);                            
                        that.canvasLayer.reFresh();    
                    }
                }               
            })                      
        },
        //周期时间显示
        drawTimeText(text){
            if(this.map){
                let pos = this.map.lngLatToContainer(this.center); 
                let angle = this.map.getPitch()
                pos.y = (170/ Math.cos(angle/360)).toFixed(2);            
                let lnglat = this.map.containerToLngLat(pos); 
                let datas = [
                    [lnglat.lng,lnglat.lat,text]
                ]                      
                let opt = {series:[{
                    type:'scatter',
                    data:datas,
                    coordinateSystem:'amap',
                    symbolSize:0,
                    label:{
                        show:true,
                        formatter:'{@[2]}',
                        fontSize:24,                
                    }
                }]}
                if(this.mapEchart && this.mapEchart.setOption){
                    this.mapEchart.setOption(opt) 
                }             
            }
        },
        downloadImgToVideo(){            
            var that = this;  
            let site = this.$store.state.storage_global.currentSite;
            var hostHref = `web/videohelp/视频合成工具.msi`;            
            var params = encodeURI(`${site.siteId},${this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss")},${this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss")},${this.$store.state.storage_user.user.account},${this.$store.state.storage_user.user.pwd},${this.dataType},${sysconfig.videodownloadversion}`);
            var str = encodeURI($help.compress(params))
            console.log("GuoYaoVideoTool://" + str)
            //检测是否有插件            
            customProtocolCheck(
            "GuoYaoVideoTool://" + str,
            () => {
                //失败
                var that = this;
                openDialog({
                    title:"提示",
                    content:"您还没有安装下载合成视频插件, 是否立即下载插件?",
                    okBack:()=>{
                        //下载插件
                        // downloadApi(
                        // {
                        //     url:`/0/web?objectName=videohelp/VDHAPP2.msi`,
                        //     fileName:"VDHAPP.msi" 
                        // })
                        window.open(uploadMinioURL+ hostHref)
                    }
                })                          
            },
            () => {
                //成功
                console.log("Custom protocol found and opened the file successfully.");
            }, 5000
            );  

        },
               
    },
   
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.window{
    width: 100%;
    height: 100%;
}
.map{
    width: 100%;
    top: 45px;
    position: relative;
    height: calc(100% - 45px);
}
.showDataBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.weatherinfo{
     display: flex;
     //flex-direction: column;
     position: absolute;
     //left: 20px;
     //bottom: 400px
     right: 50px;
     top: 30px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 10px;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 24px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
.hotchart{
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
}

.mapToolbar{
    position: absolute;
    z-index: 1;
    width: 100%;
}

//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 160px;
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
//比例尺
:deep(.amap-scalecontrol){
    left:50px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
//垂直热图
.externalDatasBox{    
    flex-shrink: 0;
    position: relative;
    top:0;
    right:0px;  
    overflow: auto;
    max-height: 100%;
    width: 6
    0%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 100%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 50%;
    height: 100%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}
.rowscale{
    height: 100%;
}
.scanmapTitle{
    position: absolute;   
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 0px;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
//import { getField, updateField } from 'vuex-map-fields'
import  modules from './modules'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    ...modules    
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : []
})
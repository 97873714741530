<template>
    <v-container class="d-flex flex-column">
      <v-row class="ma-0 mb-4">
         <v-tabs
          fixed-tabs                
          v-model="tab"    
          >
              <v-tab v-for="item in tabs"
                  :key="item" 
                  @click="load(item)"               
                  > 
              {{$i18n.t(`notice.label.${item}`)}}
              </v-tab>            
          </v-tabs> 
      </v-row>
      <v-row class="width100 ma-0" style="height:92%">
          <v-tabs-items v-model="tab" class="width100 height100">
              <v-tab-item  key="notice" class="width100 height100">  
                  <!--通知消息  -->
                  <v-card  class="width100 height100  d-flex flex-column" elevation="0">
                      <v-row class="ma-0" style="flex:0 0 10%">
                          <v-col>    
                              <v-row align="center" justify="center">     
                                  <SearchInput 
                                      :label="$i18n.t('notice.label.title')"
                                      :placeholder="$i18n.t('notice.placeholder.title')"
                                      v-bind:value.sync="title"
                                  />
                                   <SearchDatePicker 
                                      :range="true"
                                      :label="$i18n.t('notice.label.dateRange')"
                                      :placeholder="$i18n.t('notice.placeholder.dateRange')"
                                      v-bind:date.sync="noticeDateRange"  
                                      clearable                  
                                  />
                                  <v-btn color="primary" @click="noticeData.page=1;load('notice')">{{$i18n.t('notice.btn.search')}}</v-btn>                                  
                              </v-row>                              
                          </v-col>                           
                          <v-progress-linear
                              v-show="!loadOver"
                              indeterminate
                              color="primary"
                              ></v-progress-linear>
                      </v-row>
                      <v-row class="ma-0 d-flex flex-column flex-nowrap align-center" style="flex:1 1 100%;overflow:auto">
                          <v-card v-if="noticeData.total == 0 && loadOver" elevation="0" class="width100 d-flex justify-center align-center">
                              {{$i18n.t('notice.text.noData')}}
                          </v-card>
                          <v-hover  v-for="i,key in noticeData.data" :key="key"
                              v-slot:default="{ hover }"
                              open-delay="10"
                          >                       
                              <v-card class="my-2" width="80%" :elevation="hover ? 16 : 4" >
                                  <v-badge                                
                                  color="pink"                            
                                  dot
                                  :value="i.IsNew"
                                  offset-x="5"
                                  offset-y="5"                                
                                  class="width100"
                                  >
                                      <v-row class="ma-0 pa-0">
                                          <v-col cols="10">                                        
                                              <v-card-title>{{i.NmTitle}}</v-card-title>
                                              <v-row class="justify-space-between">
                                                  <v-card-subtitle><v-icon class="ml-2">mdi-account-box</v-icon> {{i.NmUser}}</v-card-subtitle>
                                                  <v-card-subtitle><v-icon>mdi-clock-outline</v-icon> {{new Date(i.NmPublishTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")}}</v-card-subtitle>                                            
                                              </v-row>                                        
                                          </v-col>
                                          <v-col cols="2">
                                              <v-card-actions class="pa-0 justify-end align-end height100">                                            
                                                  <v-btn color="primary" @click="dialog = { open:true,content:i.NmContent,title:i.NmTitle}" text>查看</v-btn>
                                              </v-card-actions>
                                          </v-col>
                                      </v-row> 
                                  </v-badge>                               
                              </v-card>
                              
                          </v-hover>
                      </v-row>
                      <v-dialog width="800"  v-model="dialog.open" >
                          <v-card height="400px">
                              <v-card-title
                              class="headline grey lighten-2"
                              primary-title
                              >
                              {{dialog.title}}
                              </v-card-title>
                              <v-card-text v-html="dialog.content">
  
                              </v-card-text>
                          </v-card>                       
                      </v-dialog>
                      <v-row justify="center" align="center"  style="flex:1 1 10%"  class="ma-0">
                           <v-pagination
                              @input="load('notice')"
                              v-model="noticeData.page"
                              :length="noticeDataLength"
                              circle
                              :total-visible="8"
                              ></v-pagination>
                              {{$i18n.t("notice.pageTotal",[noticeData.total])}}
                      </v-row>
                  </v-card>
              </v-tab-item>
              <v-tab-item key="warning" class="width100 height100">          
                  <!--报警信息  -->
                  <v-card class="width100 height100 d-flex flex-column" elevation="0">
                      <v-row class="ma-0 d-flex" style="flex:0 0 10%">   
                          <v-col cols="10">    
                              <v-row align="center" justify="center">                 
                                  <SelectInput             
                                      :label="$i18n.t('notice.label.site')"
                                      :options="sites"  
                                      :placeholder="$i18n.t('notice.placeholder.site')" 
                                      :width='200'
                                      :clearable="true"
                                      v-bind:value.sync="siteId"/>  
                                  <SearchDatePicker 
                                      :range="true"
                                      :label="$i18n.t('notice.label.dateRange')"
                                      :placeholder="$i18n.t('notice.placeholder.dateRange')"
                                      v-bind:date.sync="warningDateRange"   
                                      clearable                  
                                  />
                                  <v-btn color="primary" @click="warningData.page=1;load('warning')">{{$i18n.t('notice.btn.search')}}</v-btn>
                              </v-row>
                          </v-col> 
                          <v-progress-linear
                              v-show="!loadOver"
                              indeterminate
                              color="primary"
                              ></v-progress-linear>
                      </v-row>                    
                      <v-row class="ma-0 d-flex flex-column flex-nowrap align-center" style="flex:1 1 100%;overflow:auto;">
                          <v-card elevation="0" v-if="warningData.total== 0 && loadOver" class="width100 height100 d-flex justify-center align-center">
                              {{$i18n.t('notice.text.noData')}}
                          </v-card>
                          <v-hover  v-for="i,key in warningData.data" :key="key"
                              v-slot:default="{ hover }"
                              open-delay="10"
                          >
                          <v-card class="my-2" width="80%" :elevation="hover ? 16 : 4" >
                              <v-card-title>{{i.title}}</v-card-title>
                              <v-card-text>{{i.message}}</v-card-text>
                              <v-card-subtitle class="ma-0 pa-0 px-4">{{i.time}}</v-card-subtitle>
                              <v-card-actions class="d-flex justify-end ma-0 mx-2 pa-0 pb-2"><v-btn text color="primary" @click="go(i)">前往</v-btn></v-card-actions>
                          </v-card> 
                          </v-hover> 
                      </v-row>
                      <v-row justify="center" align="center" style="flex:1 1 10%"  class="ma-0">
                           <v-pagination
                              @input="load('warning')"
                              v-model="warningData.page"
                              :length="warningDataLength"
                              circle
                              :total-visible="8"
                              ></v-pagination>
                              {{$i18n.t("notice.pageTotal",[warningData.total])}}
                      </v-row>
                  </v-card>
              </v-tab-item> 
          </v-tabs-items>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import {getAlarmMessagesApi,getNoticeMessagesApi} from '@/api'
  export default {
      name:"notice.index",
      data(){
          return {
              loadOver:false,
              tabs:[
                  "notice","warning"                
              ],
              tab:0,
              warningDateRange:[new Date(new Date().setDate(new Date().getDate() -1)).pattern('yyyy-MM-dd'),new Date().pattern('yyyy-MM-dd')],
              noticeDateRange:[],
              title:"",
              siteId:"",
              warningData:{
                  data:[],
                  page:1,
                  rows:10,
                  total:0
              },
              noticeData:{
                  data:[],
                  page:1,
                  rows:10,
                  total:0
              },
              dialog:{
                  open:false,
                  content:"",
                  title:""
              }
          }
      },
      computed:{
          warningDataLength(){
              let _len = Math.ceil(this.warningData.total/this.warningData.rows);
              _len = _len == 0 ? 1:_len;
              return  _len
          },
          noticeDataLength(){
              let _len = Math.ceil(this.noticeData.total/this.noticeData.rows);
              _len = _len == 0 ? 1:_len;
              return  _len
          },
          sites(){
              let sites = this.$store.state.storage_user.sites;
              return sites.map(a=>{
                  return {                        
                      text:a.siteName,
                      value:a.siteId
                  }
              })
          }
      },
      mounted(){        
          if(this.$route.params.toRoute){
              this.tab = 1;   
              this.load(this.$route.params.toRoute)
          }else{
              this.load('notice')
          }        
      },
      methods:{
          load(type){
              var that = this;
              this.loadOver = false;
              if(type == 'warning'){                
                  let start =this.warningDateRange[0] ?this.warningDateRange[0] + " 00:00:00":"";
                  let end = this.warningDateRange[1]?this.warningDateRange[1]  + " 23:59:59":""
                  //报警点消息
                  getAlarmMessagesApi({
                      params:{
                          StartTime:start,
                          EndTime:end,
                          PageIndex:that.warningData.page,
                          PageSize:that.warningData.rows,
                          SiteId:that.siteId
                      },success:(d)=>{     
                          that.loadOver = true;                                       
                          that.warningData.data = d.Data.map(a=>{
                              let item = JSON.parse(a.NmContent);
                              let site= that.sites.find(b=>{
                                  return b.value == item.DapSiteId
                              })                            
                              let msginfo = {
                                  title:site?site.text + "站点报警" :"报警",
                                  message: site ? `${site.text}站点发现污染物，报警等级${item.DapLevel},报警数量${item.DapCount}个`:`发现污染物，报警等级${item.DapLevel},报警数量${item.DapCount}个`,
                                  siteId:item.DapSiteId,
                                  time:new Date(item.DapTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss"),
                                  lng:item.DapLng,
                                  lat:item.DapLat,
                                  DapTime:item.DapTime
                              }
                              return msginfo
                          })
                          that.warningData.total = d.Total
                      }
                  })
              }else if(type == 'notice'){
                  let start = this.noticeDateRange[0] ? this.noticeDateRange[0]+ " 00:00:00":"";
                  let end = this.noticeDateRange[1]  ? this.noticeDateRange[1] + " 23:59:59":"";
                  //用户消息                
                  getNoticeMessagesApi({
                      params:{
                          StartTime:start,
                          EndTime:end,
                          PageIndex:that.noticeData.page,
                          PageSize:that.noticeData.rows,
                          Title:that.title
                      },success:(d)=>{    
                          that.loadOver = true;                
                          that.noticeData.data = d.Data;
                          that.noticeData.total = d.Total
                      }
                  })

                  
              }
          },
          go(item){
              this.$store.dispatch("storage_global/updateCurrentSiteById",{data:item.siteId},{root:true})
              this.$router.push({
                  name:"m.monitoring.pls.history",
                  params:{
                      lng:item.lng,
                      lat:item.lat,
                      time:item.DapTime
                  }
              })
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
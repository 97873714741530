<template>
<v-container class="scrollBar">
    <v-expand-transition>
        <div v-show="animateNotice" ref="noticeScroll" class="width100 height100 scrollCanvas">
            <p style="text-wrap: nowrap;white-space: nowrap;" class="ma-0 pa-0">{{messageInfo}}</p>
        </div>        
    </v-expand-transition>
    <v-icon v-show="animateNotice" class="closeIcon white--text" @click="close">mdi-close</v-icon>
</v-container>    
</template>
<script>
export default {
    name:"scrollNoticeTopPlusTwo",
    data(){
        return {
            animateNotice:false,           
            messages:[],
            interval:null,
            messageInfo:'',                
        }
    },
    watch:{
        "$store.state.storage_global.message":{
            handler(d){
                let warnText= this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls ?this.$store.state.storage_user.user?.extendInfo?.alias?.alarm?.pls: '污染物'
                let _msg = `${new Date(d.date).pattern('dd日 HH:mm')}${d.position?.formatted_address.replace(d.position?.addressComponent?.province,'')}发现${warnText}报警点`                
                this.messages.push(_msg)   
                this.animateNotice = true;   
                var that = this;      
                var funScroll = ()=>{
                    if(that.animateNotice){                        
                        if(that.messages.length > 0){
                            that.updateMessage();                            
                        }else{                            
                            that.close()
                        }
                    }
                    return funScroll;
                }       
                if(this.interval == null){
                    this.interval = setInterval(funScroll(),15000)           
                }
            }
        }
    },
    mounted(){
        var that = this;       
       // this.load()
    },
    methods:{
        load(){ 
             let _msg = "测试报警，报警点是一号店，报警次数无数次"
           this.messages.push(_msg)   
                this.animateNotice = true;   
                var that = this;             
                if(this.interval == null){
                    this.interval = setInterval(()=>{
                        if(that.animateNotice){
                            if(that.messages.length > 0){
                                that.messageInfo = that.messages[0]
                                //消除已发送的消息                    
                                that.messages = that.messages.slice(1)
                            }else{
                                that.close()
                            }
                        }

                    },10000)           
                }                              
        },
        updateMessage(){
            this.messageInfo = this.messages[0]                            
            //消除已发送的消息                    
            this.messages = this.messages.slice(1)
        },
        close(){
            clearInterval(this.interval)
            this.interval = null;
            this.messages = []
            this.animateNotice = false;
        }
    }
}
</script>
<style scoped lang="scss">
.scrollBar{
    position: absolute;
    width:100%;    
    max-width: 100%;
    top: 100%;
    padding: 0;
    left: 0;
}
.scrollCanvas{
    height: 30px; 
    width: 100%;
    color: #e9691e;
    font-size: '18px';
    font-family: ' STheiti SimHei';
    background-color: rgba(0,0,0,0.3);
    p{
        width: 100%;
        line-height: 30px;
        animation: scrollTop 10s infinite;
        transform-origin: right;
        animation-timing-function:linear;
    }
}
@keyframes scrollTop {
    0% {
        transform: translateX(100%) ;
        opacity: 0;
    }
    1%{
        opacity: 1;
    }
    99%{
        opacity: 1;
    }
    100%{
        transform: translateX(-100%) ;
        opacity: 0;
    }
}
.closeIcon{
    position: absolute;
    right: 10px;
    top: 5px;
}
</style>
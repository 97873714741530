<template>
    <v-card class="pa-0 width100 height100">
        <v-card-title class="ma-0 pa-1 scanmapTitle">能见度扫描</v-card-title>
        <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
        <div ref="scanMap" class="map noevent">                            
            </div>                                                                                                                              
            <ColorBarConfig @update="draw" :dataKey="9" ref="colorBar" class="mapColorBarConfig" />            
    </v-card>
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import transferDataType from '@/plugins/datas/transferDataType'
import {getVlsVizLatestApi } from '@/api' 
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
export default {
    name:"component.monitoring.vls.realtime.item",
    components:{ColorBarConfig,monitorPointDataCard},
    props:{
        dataList:{
            type:Array
        },
        currentSite:{
            type:Object
        }
    },
    data(){
        return {
        lidarDatas:[],
        scanDistance:6,
        onboarding:'relaScanMap',
        map:null,
        mapEchart:null,
        center:[],
        mapContextMenuPositon:[],
       

        siteMarker:null,
        //画图        
        canvas:null,
        ctx:null,
        canvasLayer:null,
        opacity:0.7,
        noDateCount:0,
        vAngle:0,
        setOpacity:null,
        monitorPoints:[],//控点
        monitorPointMarkers:[],    //控点标记    
        timmer:null,
        clearChartFun:null,
        hiddenDom:false,

        //按钮
        PPI_ScanTypes:[],//当前站点水平数据类型        
        currentScanMapType:"",
        currentWatch:null
    }},
    computed:{
        icon:()=>{
            return(key)=>{
                var item = weatherType.find(a=>a.name == key);
                if(item){
                    return item.value
                }else{
                    return 'mdi-biohazard'
                }
            }
        },        
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },      
        currentSite:{
            handler(n,v){                                
                var that = this;  
                if(this.currentSite?.extendInfo){            
                    let extendInfo = JSON.parse(this.currentSite?.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }            
                this.$nextTick(()=>{   
                    //that.watchData(that.currentSite.siteId)                             
                    that.load();
                })                
            }
        },
        currentScanMapType:{
            handler(){            
                this.lidarDatas = [];  
                this.configCurrentSiteTypeInfo();                          
            }
        },
        dataList:{
            handler(n){
                this.lidarDatas = n;                
            },
            deep:true
        },
        lidarDatas:{
            handler(n){            
                if(this.currentSite?.siteId){
                    if(this.ctx){                                
                        this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)                    
                        if(this.canvasLayer){
                            this.canvasLayer.reFresh()
                        }                              
                    }     
                    let that = this;                         
                    setTimeout(() => {
                        that.draw()                                        
                    }, 800);                            
                }
            },  
            deep:true          
        }        
    },
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    mounted(){     
        var that = this;  
        var target = that.$refs.scanMap   
        if(this.currentSite?.extendInfo){            
            let extendInfo = JSON.parse(this.currentSite?.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
        } 
        if(this.currentSite.siteId){
           // this.watchData(this.currentSite.siteId)  
        }else{
            // setTimeout(() => {
            //     that.watchData(that.currentSite.siteId)              
            // }, 200);    
        }
                               
        setTimeout(() => {
            that.load();
        }, 500); 
    },
    methods:{     
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                            
                if(!n?.jsonData){
                    return;
                }
                var json = JSON.parse(JSON.stringify(n?.jsonData))                                                        
                if(n.SiteId == that.currentSite?.siteId && n.DataType == transferDataType['viz'].value &&(n.ScanMode == "PPI" || n.ScanMode == 0)){                                                                              
                    that.lidarDatas = json.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    });                                          
                }
            }, {immediate: true })
        },     
        load(){                             
            this.loadMap();                    
        },       
        loadMap(){
            let target = this.$refs.scanMap;   
            if(!target){
                return;
            }
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 11.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            if(this.canvasLayer){
                this.map.remove(this.canvasLayer)
                this.canvasLayer = null;
            }
            var that = this;
            let site = this.currentSite;            
                             
            let center = [site?.lng,site?.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            //添加右键菜单
            this.addContextMenu();            
            //加载控件 
            this.addControl(); 
            var that = this;
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
            }); 
            that.siteMarker.on('click',(e)=>{                                
                    new AMap.InfoWindow({
                        content: `
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${e.lnglat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                       `,                        
                    }).open(that.map,e.lnglat)
                })
            that.map.add(that.siteMarker)           
              //加载国控点
            this.loadMonitPoint()     
        },
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
        },
        loadLidarData(){            
            var that = this;          
            if(this.currentScanMapType.value == "undefined"){                 
                return;
            }
            getVlsVizLatestApi({
                id:this.currentSite?.siteId,
                params:{                    
                },
                success:(d)=>{              
                    that.lidarDatas = d.filter(a=>a.DvvScanMode ==0).map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: new Date(a.DvvTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }
                    }) 
                    that.lidarDatas = json.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    });
                    that.draw();               
                }
            })
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //比例尺
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '160px',left: '50px' },
                    theme:'myself'
                }));
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:100px;left:50px;';                       
                if(that.canvasLayer || that.opacity){                    
                    that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
                }else{
                    that.opacity = 0.7
                }                      
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min="1" max="10" value="${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = (value)=>{                   
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }                    
                }
                _rest.addEventListener('click',(e)=>{                                                                          
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(0.7)
                    }                      
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }  
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);  

                //地点定位                
                let styleP = 'cursor:pointer;bottom:50px;left:55px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);    
                if(that.hotChartStatus){
                    that.moveControll(true,true)

                }
            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);
            
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.35 16 12.69 15.97 13.03 15.95C13 15.8 13 15.65 13 15.5C13 14.96 13.09 14.44 13.24 13.94C12.83 14 12.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V10.03C18.17 10 18.33 10 18.5 10C19 10 19.5 10.08 20 10.22V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C13.06 21 14.07 20.89 15 20.71C14.62 20.14 14.25 19.5 13.93 18.85C13.34 18.94 12.71 19 12 19M12 5C15.87 5 18 6.5 18 7S15.87 9 12 9 6 7.5 6 7 8.13 5 12 5M18.5 12C16.6 12 15 13.6 15 15.5C15 18.1 18.5 22 18.5 22S22 18.1 22 15.5C22 13.6 20.4 12 18.5 12M18.5 16.8C17.8 16.8 17.3 16.2 17.3 15.6C17.3 14.9 17.9 14.4 18.5 14.4S19.7 15 19.7 15.6C19.8 16.2 19.2 16.8 18.5 16.8Z" fill="black"/>
            </svg>
            位置数据</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                let site = that.currentSite;
                let siteCenter = [site.lng,site.lat]
                let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                if(dis < that.scanDistance * 1000){
                    let angle = $help.bearing(siteCenter,lnglat)
                    angle = angle >= 0 ? angle :angle +360
                    angle = angle - that.currentSite.yaw < 0 ? angle - that.currentSite.yaw + 360 : angle - that.currentSite.yaw;                                        
                    if(that.lidarDatas.length > 0){
                        let min = that.lidarDatas[0]
                        let minAngle = Number(min.straightAngle) < 0 ? Number(min.straightAngle) + 360 :Number(min.straightAngle);
                        that.lidarDatas.forEach(a=>{
                            let _straightAngle = Number(a.straightAngle) < 0 ?  Number(a.straightAngle) + 360 :  Number(a.straightAngle);
                            if(Math.abs(_straightAngle - angle) < Math.abs(minAngle - angle)){
                                min = a;
                                minAngle = _straightAngle
                            }                            
                        })
                        if(Math.abs(minAngle - angle)>1){
                            let msg = `<div>点位值： </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }else{    
                            let all = min.distanceData[2] * min.distanceData[1];                         
                            let scanD = that.scanDistance/all * min.data.length;  
                            let _index = Math.round(scanD * dis/(that.scanDistance * 1000));                                            
                            _index = _index - min.distanceData[0]/min.distanceData[1];
                            if(_index > min.data.length -1){
                                let msg = `<div>点位值： </div>`                     
                                new AMap.InfoWindow({
                                    content: msg,                        
                                }).open(that.map,lnglat);
                                return
                            }
                            let _value = min.data[_index]
                            let msg = `<div>点位值：${_value} </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }
                        
                    }                                        
                }                
                else{
                    openNotice({
                        type:'error',
                        text:"所选区域超出范围"
                    })
                }
            }, 1);  

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },        
        //设置报警点在地图位置
        setCurrentPoint(event,data){ 
            //设置当前活动
            let childrenList = event.target?.parentElement?.parentElement?.children;
            for(var i in childrenList){
                if(i == "length"){
                    continue;
                }
                if(i == data.index){
                    childrenList[i].className = 'table-row-active'
                }else{                    
                    childrenList[i].className = ''
                }

            }
            let item = data.item;           
             var msg = `<div style="font-size:16px;font-weight:600;">报警点信息</div>
            <div>位置：${item.position}</div>
            <div>经纬度：${item?.lng},${item?.lat}</div>
            <div>次数：${item.count}</div>`

            let InfoWindowMap = new AMap.InfoWindow({                
                position:[item?.lng,item?.lat],
                content: msg,                        
            });            
             InfoWindowMap.open(this.map)

             //设置中心
            let pix = this.map.lngLatToContainer([item?.lng,item?.lat])
            let conSize = this.map.getSize();
            if(pix.x <= 0 || pix.y <= 0 || pix.x >= conSize.width || pix.y >= conSize.height){
                this.map.setCenter([item?.lng,item?.lat])
            }       
        },          
        draw(){        
            var that = this;   
            if(!this.map){
                return;
            }
            if(this.loadTimer){
                clearInterval(this.loadTimer)
            }           
            this.loadTimer = setTimeout(() => {
                if(that.lidarDatas.length == 0){                
                    return
                }
                let _width = 400;//包含设置像素

                if(!that.canvas){
                    let canvas = document.createElement('canvas')
                    //canvas = this.$refs.polar;
                    let ctx =canvas.getContext("2d"); 
                    canvas.width = _width;
                    canvas.height = _width;               
                    that.canvas = canvas;
                    that.ctx = ctx;
                }                           
                //let site = this.$store.state.storage_global.currentSite;            
                let arr= $help.getLayerMapPosition(Number(that.lidarDatas[0]?.angleOfPitch),this.scanDistance,this.center)            
                var bounds = new AMap.Bounds(arr[0],arr[1])   
                if(!that.canvasLayer){
                    that.canvasLayer = new AMap.CanvasLayer({
                        canvas: that.canvas,
                        bounds: bounds,  
                        opacity:that.opacity,  
                        zooms: [2, 30]            
                    });        
                    that.map.add(that.canvasLayer);
                }else{
                    if(that.canvas.width != _width){                    
                        that.canvas.width = _width;
                        that.canvas.height = _width;
                        that.canvasLayer.setCanvas(that.canvas)
                    }
                    that.canvasLayer.setBounds(bounds)                
                }            
                //插值画法                                    
                that.drawHelper(_width/2/this.scanDistance);    
                if(that.canvasLayer){
                    that.canvasLayer.reFresh()
                }                                 
            }, 1000);          
        },
        drawHelper(rp){     
            let yaw =this.currentSite.yaw;                         
            let _datas =this.lidarDatas.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });  
            if(_datas.length == 0 || !this.$refs.colorBar)   {
                return;
            }
            let d = this.scanDistance;           
            var that = this;
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (distanceData[0])/distanceData[1]  //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                that.ctx.putImageData(imageData,0,0)  
            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:d,rp:rp,yaw:yaw})               
            
        },
        //周期时间显示
        drawTimeText(text){
            if(this.map){
                let pos = this.map.lngLatToContainer(this.center); 
                let angle = this.map.getPitch()
            
                pos.y = (170/ Math.cos(angle/360)).toFixed(2);            
                let lnglat = this.map.containerToLngLat(pos); 
                let datas = [
                    [lnglat?.lng,lnglat?.lat,text]
                ]                      
                let opt = {series:[{
                    type:'scatter',
                    data:datas,
                    coordinateSystem:'amap',
                    symbolSize:0,
                    label:{
                        show:true,
                        formatter:'{@[2]}',
                        fontSize:24,                
                    }
                }]}
                if(this.mapEchart && this.mapEchart.setOption){
                    this.mapEchart.setOption(opt) 
                }             
            }
        },        
        //热图
        setHotLayer(show){               
            let color = this.color;              
            if(this.mapHotLayer){
                this.map.remove(this.mapHotLayer)                 
            }            
            this.mapHotLayer = null;          
            //1.14版本
            this.mapHotLayer = this.map.getLayers().find(a=>{
                return a.CLASS_NAME == 'Loca.HeatmapLayer';
            });        
            if(!this.mapHotLayer){            
                this.mapHotLayer = new Loca.HeatmapLayer({
                    map:this.map
                })                
            }        
            if(show){             
                if(this.mapHotDatas && this.mapHotDatas.length > 0){                           
                    this.mapHotLayer.setData(this.mapHotDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                }        

                let level = this.map.getZoom();
                let r = level <= 12 ? 16 : 16 + (level -12) * 5;            
                this.mapHotLayer.setOptions({ 
                    map:this.map,                  
                    style: {
                        //altitude:500000,
                        radius: r,
                        color: color
                    }
                });
                this.mapHotLayer.render();
                this.mapHotLayer.show();
            }
            else{
                this.mapHotLayer.hide();
            }
        }         
    },
   
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.window{
    width: 100%;
    height: 100%;
}
.map{
    width: 100%;
    top: 40px;
    position: relative;
    height: calc(100% - 40px);
}
.showDataBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
 

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
.hotchart{
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
}

.mapToolbar{
    position: absolute;
    z-index: 1;
    width: 100%;
}

//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 160px;
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}

//比例尺
:deep(.amap-scalecontrol){
    left:50px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
//垂直热图
.externalDatasBox{    
    flex-shrink: 0;
    position: relative;
    top:0;
    right:0px;  
    overflow: auto;
    max-height: 100%;
    width: 6
    0%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 100%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 50%;
    height: 100%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}
.rowscale{
    height: 100%;
}
.scanmapTitle{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 0px;
}
</style>

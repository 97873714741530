<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">
        <v-overlay
          absolute
          :value="overlay"
          class="ma-3"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <ColorBarConfig @update="timerDraw"  :dataKey="51" ref="colorBar" class="mapColorBarConfig"/>       
        <v-card height="100%" width="100%" elevation="1">   
            <v-card-title class="ma-0 pa-1 heatmapTitle">风切变</v-card-title>             
            <v-card-title class="ma-0 pa-1 btns">                    
                    <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                    <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>                                                              
                </v-card-title>  
            <div class="mx-1" style="position:absolute;top:32px;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>              
            <div ref="polar" class="width100 height100 plotychart">

            </div>
        </v-card>                      
    </v-container>
</template>
<script>
import { getWlsShearByTimeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:'wls.realtime.shear',
    props:{
        timeRange:{
            type:Array
        },
        currentScanType:{
            type:String
        }
    },
    components: {
        ColorBarConfig
      },
    data(){
        return {
            overlay:false,
            dataList:[],
            nextPeriod:'',
            prePeriod:'',
            timer:null
        }
    },
    watch:{
        timeRange:{
            handler(n){                        
                if(n.length > 0){  
                    this.dataList = []                                       
                    this.nextPeriod = ''
                    this.load()
                }
            },
            deep:true
        },
        currentScanType(n){
            setTimeout(() => {
                this.dataList = []
                this.nextPeriod = ''
                this.prePeriod = ''
                this.load()
            }, 200);           
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.dataList = []
                this.nextPeriod = ''
                this.prePeriod = ''
                this.load()                       
            }
        },     
    },
    computed:{
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0) 
        },
        time(){
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            let period = this.dataList[0]?.period?.split('_')[0]
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }  
        },
        yaw()
        {
            if(!this.dataList||this.dataList.length == 0){
                return ''
            }
            return this.dataList[0]?.period?.split('_')[1]
        }
    },
    mounted(){
        var that = this;
        setTimeout(() => {
            that.load()    
        }, 600);        
    },
    methods:{
        load(desc = false){
            var that = this;
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }
            this.overlay = true;
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end =desc?start: this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //      
            let period = desc? this.prePeriod : this.nextPeriod                   
            getWlsShearByTimeApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    Period:period,                  
                    Time:start,
                    LimitTime:end,
                    Desc:desc,
                    ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0
                },
                success:(d)=>{  
                    that.overlay = false; 
                    if(!d.Data || d.Data.length == 0)  {          
                        that.loadChart();
                    }        
                    that.nextPeriod = d.NextPeriod?.split("_")[0]
                    that.prePeriod = d.PrePeriod?.split("_")[0]                  
                         
                    that.dataList = d.Data?.map(a=>{
                        return {
                            dataTime:a.DwsTime.replace('T',' '),
                            distanceData:a.DwsDis,
                            angle:a.DwsScanMode == 0 ? a.DwsStraight:a.DwsScanMode == 2?a.DwsPitch:a.DwsStraight,                        
                            scanMode:scanType[a.DwsScanMode],
                            straightAngle:a.DwsStraight,
                            angleOfPitch:a.DwsPitch,   
                            data:a.DwsValue,
                            period:a.DwsPeriod
                        }
                    }); 
                    that.loadChart();
                }
            })
        },
        loadChart(){   
            let d = 3.3     
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;   
            var that = this;
            let target = this.$refs.polar
            if(this.dataList.length == 0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                                         
                let layout = { 
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                 
                    polar: {                    
                        radialaxis: {    
                            color:fontColor,                     
                            visible: true,
                            range: [0, d]
                        },
                        angularaxis: {
                            color:fontColor, 
                            tickfont: {
        
                            },
                            direction: "clockwise"
                        }
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    showlegend: false,
                    margin:{
                        t:38,
                        r:12,  
                        l:12                        
                    }
                }            
                Plotly.newPlot(target, [{
                        type: "scatterpolargl",
                        r: [],
                        theta: [],
                        text: [],
                        mode: "markers",
                        name: "",
                        marker: {
                            color: `rgba(0,0,0,0)`,
                            size: 6,                         
                            opacity: 0.7
                        },
                        cliponaxis: false
                    }], layout,{responsive: true,scrollZoom: true})
                return;                
            }
            let polarData = []
            let noDataLength =  (this.dataList[0].distanceData[0])/this.dataList[0].distanceData[1]//无效距离
            let scanLength = this.dataList[0].distanceData[2]*this.dataList[0].distanceData[1]
            $help.drawScan(this.dataList,(obj)=>{         
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                       
                    let r = that.dataList[0].distanceData[0] + that.dataList[0].distanceData[1] *  obj.dIndex
                    let pitch=that.dataList[0].angleOfPitch;
                    polarData.push({
                        color: `rgba(${color.join(',')})`,
                        r:r,
                        angle:obj.angle,
                        value:obj.value,
                        pitch:pitch
                    })                       
                })
            },()=>{               
                let group = $help.groupBy('color',polarData)
                let data = [];
                for(var i in group){
                    let item = group[i]
                    data.push({
                        type: "scatterpolargl",
                        r: item.map(a=>a.r.toFixed(2)),
                        theta: item.map(a=>a.angle.toFixed(2)),
                        text: item.map(a=>
                        `值：${a.value.toFixed(6)}<br />距离：${a.r.toFixed(2)}(Km)<br />水平角：${a.angle.toFixed(2)}°<br />俯仰角：${a.pitch}°`
                        ),
                        mode: "markers",
                        name: "",
                        marker: {
                            color: i,
                            size: 6,                         
                            opacity: 0.7
                        },
                        hoverinfo:'text',
                        cliponaxis: false
                    })
                }  
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                         
            let layout = { 
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                 
                polar: {                    
                    radialaxis: {    
                        color:fontColor,                     
                        visible: true,
                        range: [0, d]
                    },
                    angularaxis: {
                        color:fontColor, 
                        tickfont: {
      
                        },
                        direction: "clockwise"
                    }
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                showlegend: false,
                margin:{
                    t:38,
                    r:12,  
                    l:12                        
                }
            }            
            Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:d,yaw:yaw})                        
        },
        play(status){                   
            if(status == 'next'){
                if((!this.nextPeriod || Number(this.timeRange[1].pattern("yyyyMMddHHmmss")) - Number(this.nextPeriod) <= 0) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }   
                this.load()        
            }else{
                if(!this.prePeriod || Number(this.timeRange[0].pattern("yyyyMMddHHmmss")) - Number(this.prePeriod) >= 0) {
                    openNotice({
                        type:"info",
                        text:'已加载到开始'
                    })                        
                    return
                }
                this.load(true)            
            }
        },timerDraw(){  
            if(this.dataList.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        } 
    }
}
</script>
<style scoped lang="scss">
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 64px;
    right: 20px;    
}.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}.btns{
    position: absolute;
    z-index: 2;
    right: 60px;
    top: 10px;
}
.plotychart{
    :deep(.modebar){
        padding-top:32px;
        padding-right:32px ;
    }
}
</style>
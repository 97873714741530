<template>
    <v-card class="width100 height100">
        <v-card-title class="ma-0 pa-1 scanmapTitle">能见度扫描</v-card-title>
        <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
        <div ref="scanMap" class="map noevent">                            
            </div>                                                                                                                              
            <ColorBarConfig :dataKey="9" ref="colorBar_viz" class="mapColorBarConfig" @update="configColorBar($event,'viz')"/>
            <ColorBarConfig  style="z-index: -1;" :dataKey="17"  ref="colorBar_rcs" class="mapColorBarConfig" @update="configColorBar($event,'rcs')"/>            
            <div class="configBtns">
                <v-switch title="显示时间"                                
                    v-model="showTime" 
                    @change="drawText()"                              
                    class="mt-0"                                
                    label="时间"
                    hide-details
                    dense>
                    </v-switch>
                <v-switch title="显示天气"
                    @change="setWeather" 
                    v-model="showWeather"                               
                    class="mt-0"                                
                    label="天气"
                    hide-details
                    dense>
                    </v-switch>
                    <v-switch title="显示国控点"
                    @change="setMonitorPoint"                                
                    class="mt-0"                                
                    label="国控点"
                    hide-details
                    dense>
                    </v-switch>
                    <v-switch title="显示站点名" 
                    v-model="showSiteName"  
                    @change="drawText()"                                                       
                    class="mt-0"                                
                    label="显示站点名"
                    hide-details
                    dense>
                    </v-switch>
            </div>                                                             
    </v-card>
</template>
<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import transferDataType from '@/plugins/datas/transferDataType'
import {getVlsVizLatestApi } from '@/api' 
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import Plotly  from "plotly.js-dist"
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
export default {
    name:"component.monitoring.vls.realtime",
    components:{ColorBarConfig,monitorPointDataCard},
    props:{
        selectSites:{
            type:Array
        },
    },
    data(){
        return {
            loadOver:false,
            showTime:true,
            showSiteName:false,
            showWeather:false,
            zoomLimit:10.16,
            siteScanInfoList:[],       
            map:null,
            mapEchart:null,
            center:[],
            mapContextMenuPositon:[],       
        
            opacity:0.7,
            vAngle:0,
            setOpacity:null,
            monitorPoints:[],//控点
            monitorPointMarkers:[],    //控点标记    
            timmer:null,
            clearChartFun:null,
            hiddenDom:false,
            setLocalTimeOut:null
    }},
    computed:{
       
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },      
        selectSites:{
            handler(n){
                if(this.loadOver&& this.map){                    
                    this.resetSiteMaker(n);   
                }  
            },deep:true
        },                
    },
    beforeDestroy(){
        this.siteScanInfoList.forEach(a=>a.watch())
        if(this.timmer){
            clearTimeout(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    beforeUnmount(){
        this.siteScanInfoList.forEach(a=>a.watch())
        if(this.timmer){
            clearTimeout(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    mounted(){     
        var that = this;         
        setTimeout(() => {
            that.loadMap();        
        }, 200);             
    },
    methods:{  
        async drawItem(_currentSiteInfo){ 
            if(!this.hiddenDom ){ 
                this.draw(_currentSiteInfo.site)                                                                                                               
            }                
            this.drawText()                    
        },
        getCurrentSiteInfo(cb,site){            
            var that = this;
            let _currentSiteInfo = this.siteScanInfoList.find(a=>{
                return a.site.siteId == site.siteId;
            })           
            if(!_currentSiteInfo){
                let scanDistance = 6
                if(!$help.isNull(site.extendInfo)){
                    let extendInfo = JSON.parse(site.extendInfo)
                    scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6
                }       
                _currentSiteInfo = {
                    watch:null,
                    playStatus:false,
                    site:site,  
                    scanDistance:scanDistance, 
                    PPI:{
                        data:[],
                        canvas:null,
                        ctx:null,
                        canvasLayer:null,
                    },                                
                    FIX:{
                        data:[],
                        el:null,
                        canvas:null,
                        ctx:null,
                        canvasLayer:null,
                    },
                    RHI:{
                        data:[],
                        el:null,
                        canvas:null,
                        ctx:null,
                        canvasLayer:null,
                    },               
                    weatherInfo:{                        
                        position:"",
                        windPower:"",
                        temperature:"",
                        windDirection:'',
                        humidity:'',
                        weather:''
                    },
                    time:"",
                    selectAllReciverMarker:null,//站点标记                                        
                }
                if(!_currentSiteInfo.site.weatherInfo || (_currentSiteInfo.site.weatherUpdateTime.getTime() - new Date().getTime())/1000/60/60 > 1){
                    //获取当前站点天气                                        
                    getWeather(_currentSiteInfo.site.district,(data)=>{
                        let weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }
                        that.$store.commit('storage_user/updateSiteWeather',{
                            siteId:_currentSiteInfo.site.siteId,
                            weatherInfo:weatherInfo
                        },{root:true})   
                        that.weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }   
                        _currentSiteInfo.weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }                                          
                    }) 
                }else{
                    _currentSiteInfo.weatherInfo = site.weatherInfo
                }  
                _currentSiteInfo.watch = that.$watch(`$store.state.storage_global.siteData.${site.siteId}`,(n,v)=>{
                    let site = that.selectSites.find(a=>a.siteId ==n.SiteId)                                                                                                   
                    if(site){  
                        var json = JSON.parse(JSON.stringify(n.jsonData));  
                        that.getCurrentSiteInfo((currentSiteInfo)=>{    
                            let period =json[0].period?.split('_')[0]                                                 
                            currentSiteInfo.time = $help.periodToTimeStr(period);
                            if(json[0].scanType == "PPI" && n.DataType ==transferDataType.viz.value){                                                                                                                                                                                 
                                currentSiteInfo.PPI.data = json;     
                                that.drawItem(currentSiteInfo)    
                            }else if(json[0].scanType == "FIX" && n.DataType ==transferDataType.viz.value ){
                                currentSiteInfo.FIX.data = json;            
                                that.drawItem(currentSiteInfo) 
                            } else if(json[0].scanType == "RHI" && n.DataType ==transferDataType.rcs.value){
                                currentSiteInfo.RHI.data = json;                                            
                                that.drawItem(currentSiteInfo)  
                            }                                                                                 
                        },site)                                                                                                                                            
                    }
                }) 
                if(!_currentSiteInfo.site.scanType){    
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:_currentSiteInfo.site,
                        cb:(obj)=>{
                            if(obj.siteId == _currentSiteInfo.site.siteId){
                                if(_currentSiteInfo.site.scanType == 'PPI'&&_currentSiteInfo.PPI.data.length == 0){                            
                                    that.loadData(_currentSiteInfo,0)  
                                }                               
                                if(_currentSiteInfo.site.scanType == 'FIX' &&_currentSiteInfo.FIX.data.length == 0){                    
                                    let start = new Date (new Date ().setHours (0, 0, 0, 0)).pattern('yyyy-MM-dd HH:mm:ss');
                                    let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')                                                             
                                    that.loadData(_currentSiteInfo,1)  
                                }          
                                if(_currentSiteInfo.site.scanType == 'RHI'&&_currentSiteInfo.RHI.data.length == 0){                            
                                    that.loadData(_currentSiteInfo,2)  
                                }             
                                that.siteScanInfoList.push(_currentSiteInfo)
                            }
                        }
                    })  
                    cb(_currentSiteInfo)                      
                   return
                }else{
                    if(_currentSiteInfo.site.scanType == 'PPI'&&_currentSiteInfo.PPI.data.length == 0){                            
                        this.loadData(_currentSiteInfo,0)  
                    }                               
                    if(_currentSiteInfo.site.scanType == 'FIX' &&_currentSiteInfo.FIX.data.length == 0){                    
                        let start = new Date (new Date ().setHours (0, 0, 0, 0)).pattern('yyyy-MM-dd HH:mm:ss');
                        let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')                 
                        this.loadData(_currentSiteInfo,1)  
                    }          
                    if(_currentSiteInfo.site.scanType == 'RHI'&&_currentSiteInfo.RHI.data.length == 0){                            
                        this.loadData(_currentSiteInfo,2)  
                    }             
                    this.siteScanInfoList.push(_currentSiteInfo)
                }                    
            }             
            cb(_currentSiteInfo)
        },    
        configColorBar(item,type){            
            if(type == 'viz'){
                for (var i of this.siteScanInfoList) {                    
                    this.drawItem(i);
                }
            }      
        },        
        setLocal(item){
            if(this.map){
                this.map.setCenter([item.lng,item.lat])
                this.map.setZoom(11)     
                var that = this;  
                if(this.setLocalTimeOut){
                    clearTimeout(this.setLocalTimeOut)
                }
                this.setLocalTimeOut = setTimeout(() => {
                    that.siteScanInfoList.forEach(a=>{
                     if(a.site.siteId == item.siteId)  {
                        a.playStatus = true;
                        if(a.PPI.canvasLayer){
                            a.PPI.canvasLayer.show()
                        }
                        if(a.FIX.canvasLayer){
                            a.FIX.canvasLayer.show()
                        }
                        if(a.RHI.canvasLayer){
                            a.RHI.canvasLayer.show()
                        }
                     }
                })           
                }, 500);  
                                      
            }
        },
        resetSiteMaker(){
            var that = this;            
            if(this.timeOut){
                clearTimeout(this.timeOut)
            }
            this.timeOut = setTimeout(function(){                    
                if(!that.map && that.siteScanInfoList.length == 0){
                    return;
                }            
                //清空扫描区域信息    
                let oldmarks = that.siteScanInfoList.map(a=>{
                    return a.siteMarker;
                }).filter(a=>{
                    return a!=null;
                })            
                if(oldmarks.length >0) {
                    that.map.remove(oldmarks);    
                }                    
                let markers = []; 
                that.siteScanInfoList = that.siteScanInfoList.filter(a=>{
                    let _item = that.selectSites.find(b=>{
                        return b.siteId == a.site.siteId
                    })
                    if(_item){
                        return  a;
                    }else{
                        a?.FIX?.canvasLayer?.hide()
                        a?.PPI?.canvasLayer?.hide()
                        a?.RHI?.canvasLayer?.hide()   
                        a?.watch()
                        console.log(a)
                    }                
                })      
                for(var site of that.selectSites){
                    let currentSiteInfo;
                    that.getCurrentSiteInfo((d)=>{ currentSiteInfo = d },site);   
                    //添加marker
                    currentSiteInfo.siteMarker= new AMap.Marker({
                        content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                    </svg>`,  // 自定义点标记覆盖物内容
                        position:[site.lng,site.lat], // 基点位置
                        offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
                    });    
                                                                
                    currentSiteInfo.siteMarker.on('click',(e)=>{  
                        let _currsite = currentSiteInfo.site;       
                            var htm = `<div>
                            <div style="margin: 10px 5px;">${_currsite.siteName}</div>
                            <div style="margin: 10px 5px;">位置：${_currsite.localtion}</div> 
                            <div style="margin: 10px 5px;">经纬度：${_currsite.lng},${_currsite.lat}</div>   
                            <div style="margin: 10px 5px;">部署时间：${new Date(_currsite.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                            <div style="margin: 10px 5px;float:right"><a id='' href="javascript: void(0)">前往查看</a></div>  
                            </div>`
                        var _el = $help.toDom(htm);              
                        for(var a of _el[0].children){
                            if(a.children && a.children.length > 0){
                                let _link = a.children[0].tagName && (a.children[0].tagName == "a" || a.children[0].tagName == "A")?a.children[0]:null;                                    
                                if(_link){                                                                                
                                    _link.onclick=function(){                                            
                                        that.$store.commit('storage_global/updateCurrentSite',_currsite,{root:true}) 
                                        that.$router.push({path:'/monitoring/vls/realtime'})
                                    }
                                }
                            }                                                        
                        }               
                        new AMap.InfoWindow({
                            content: _el[0],                        
                        }).open(that.map,e.lnglat)                                    
                    })
                    that.map.add(currentSiteInfo.siteMarker)
                    markers.push(currentSiteInfo.siteMarker)                          
                }
                if(that.map){
                    let _markers = markers.filter(a=>{
                        return a != null && typeof(a) != 'undefined'
                    })
                    if(_markers.length > 0){
                        that.map.setFitView(_markers,true,null,13)
                    }                        
                    setTimeout(() => {                                                                                    
                        that.loadOver = true;
                    }, 500); 
                }else{
                    that.map.on('complete',()=>{   
                        let _markers = markers.filter(a=>{
                            return a != null && typeof(a) != 'undefined'
                        })
                        if(_markers.length > 0){
                            that.map.setFitView(_markers,true,null,13)
                        }                            
                        setTimeout(() => {
                            that.loadOver = true;
                        }, 200);                                             
                    })
                }                                     
            }, 200);             
        },
        loadMap(){            
            if(this.selectSites.length == 0){
                return;
            }
            let target = this.$refs.scanMap;            
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            for(var i of this.siteScanInfoList){
                if(i.PPI.canvasLayer){
                    this.map.remove(i.PPI.canvasLayer)
                    i.PPI.canvasLayer = null;                
                }
                if(i.FIX.canvasLayer){
                    this.map.remove(i.FIX.canvasLayer)
                    i.FIX.canvasLayer = null;         
                }  
                if(i.RHI.canvasLayer){
                    this.map.remove(i.RHI.canvasLayer)
                    i.RHI.canvasLayer = null;         
                }                
            }

            let amap = {
                    // 高德地图中心经纬度
                    center:[this.selectSites[0].lng,this.selectSites[0].lat] ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 0,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                };

            let option = {
                amap: amap
            }            
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl(); 
            var that = this;  
            setTimeout(() => {
                that.resetSiteMaker()                              
            }, 200);                                       
            //添加站点位置
            this.map.on('zoomend',(el)=>{
                let _zoom = el?.target?.view?.P[4] 
                if(_zoom && _zoom > that.zoomLimit){
                    that.siteScanInfoList.forEach(a=>{
                        a.playStatus = true;
                        if(a.PPI.canvasLayer){
                            a.PPI.canvasLayer.show()
                        }
                        if(a.FIX.canvasLayer){
                            a.FIX.canvasLayer.show()
                        }
                        if(a.RHI.canvasLayer){
                            a.RHI.canvasLayer.show()
                        }
                    })
                }else{  
                    that.siteScanInfoList.forEach(a=>{
                        a.playStatus = false;
                        if(a.PPI.canvasLayer){
                            a.PPI.canvasLayer.hide()
                        } 
                        if(a.FIX.canvasLayer){
                            a.FIX.canvasLayer.hide()
                        }
                        if(a.RHI.canvasLayer){
                            a.RHI.canvasLayer.hide()
                        }                       
                    })                                                         
                }               
            })  
            this.map.on('moveend',(el)=>{
                let elmap = that.map.getContainer();
                let _currentZoom = that.map.getZoom();
                if(_currentZoom > that.zoomLimit){
                    that.siteScanInfoList.forEach(a=>{                                        
                        let _position = that.map.lngLatToContainer([a.site.lng,a.site.lat]);
                        if(_position.x >0 && _position.y > 0 && _position.x < elmap.clientWidth && _position.y < elmap.clientHeight){
                            a.playStatus = true                           
                        }else{
                            a.playStatus = false;
                        }  
                        that.draw(a.site)                  
                    })
                }                      
                that.drawText();       
                  
            })          
        },
        setMonitorPoint(open){
            if(open){
                var that =this;
                this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                    that.monitorPoints = d;
                    if(that.monitorPointMarkers.length > 0){
                        that.map.remove(that.monitorPointMarkers)
                    }               
                    loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                        that.monitorPointMarkers.push(marker);
                    },(point)=>{
                        that.$refs.monitorPointDataCard.show = true
                        that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                        that.$refs.monitorPointDataCard.load()
                    })
                },{root:true})
            }else{
                this.map.remove(this.monitorPointMarkers)
            }
           
        },  
        setWeather(open){
            if(open){
                for(var i of this.siteScanInfoList){
                    let Item = i;
                    getWeather(i.site.district,(data)=>{
                        Item.weatherInfo = {
                            position:data.city,
                            windPower:data.windPower,
                            temperature:data.temperature,
                            windDirection:data.windDirection,
                            humidity:data.humidity,
                            weather:data.weather
                        }
                    }) 
                }                                               
            }
            this.drawText();            
        },
        loadData(_currentSiteInfo,type){            
            var that = this;               
            getVlsVizLatestApi({
                id:_currentSiteInfo.site.siteId,
                params:{                    
                },
                success:(d)=>{  
                    let data = d.filter(a=>a.DvvScanMode ==type).map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: new Date(a.DvvTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }
                    })                       
                    data = data.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    });                       
                    if(data.length > 0){
                        let period =data[0].period?.split('_')[0]                                                 
                        _currentSiteInfo.time = $help.periodToTimeStr(period);
                    }                             
                    if(_currentSiteInfo.site && _currentSiteInfo.site.scanType != data[0].scanType){   
                        _currentSiteInfo[data[0].scanType].data = data;                                                                                       
                        that.$store.commit("storage_user/updateSiteScanType",{
                            siteId:_currentSiteInfo.site.siteId,
                            scanType:data[0].scanType,
                            cb:()=>{
                                that.drawItem(_currentSiteInfo) 
                            }
                        },{root:true})                                           
                    } else{
                        _currentSiteInfo[data[0].scanType].data = data;
                        that.drawItem(_currentSiteInfo)
                    }                              
                }
            })
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //比例尺
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '120px',left: '10px' },
                    theme:'myself'
                }));

                // //3d指南针控件
                // var controlBar = new AMap.ControlBar({
                //     position:{bottom: '140px',left: '-70px' },
                //     theme:'myself'
                // });
                // that.map.addControl(controlBar) 
                
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:60px;left:10px;';                       
                if(that.canvasLayer || that.opacity){                    
                    that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
                }else{
                    that.opacity = 0.7
                }                      
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min="1" max="10" value="${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = (value)=>{                   
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }                    
                }
                _rest.addEventListener('click',(e)=>{                                                                          
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(0.7)
                    }                      
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }  
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);  

            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },             
        draw(site){  
            var that = this;
            let currentSiteInfo = this.siteScanInfoList.find(a=>{
                return a.site.siteId == site.siteId
            }) 
            if(!currentSiteInfo?.playStatus || !this.map){
                return;
            }
            if(currentSiteInfo?.site?.scanType == "PPI"){
                if(!currentSiteInfo || currentSiteInfo.PPI.data.length == 0){                
                    return
                }
                let _w = 400;
                if (!currentSiteInfo.PPI.canvas) {
                    let canvas = document.createElement('canvas')
                    //canvas = this.$refs.polar;
                    let ctx = canvas.getContext("2d");
                    canvas.width = _w;
                    canvas.height = _w;
                    currentSiteInfo.PPI.canvas = canvas;
                    currentSiteInfo.PPI.ctx = ctx;
                }
                //let site = this.$store.state.storage_global.currentSite;            
                let arr = $help.getLayerMapPosition(Number(currentSiteInfo.PPI.data[0]?.angleOfPitch),currentSiteInfo.scanDistance,[currentSiteInfo.site.lng, currentSiteInfo.site.lat])                            
                var bounds = new AMap.Bounds(arr[0], arr[1]) 
                if(!currentSiteInfo.PPI.canvasLayer){
                    currentSiteInfo.PPI.canvasLayer = new AMap.CanvasLayer({
                        canvas: currentSiteInfo.PPI.canvas,
                        bounds: bounds,  
                        opacity:this.opacity,  
                        zooms: [2, 30]            
                    });        
                    this.map.add(currentSiteInfo.PPI.canvasLayer);
                }else{
                    currentSiteInfo.PPI.canvasLayer.setBounds(bounds)  
                    currentSiteInfo.PPI.canvasLayer.reFresh()              
                }            
                //插值画法                                                    
                this.drawHelper(currentSiteInfo,_w/2/currentSiteInfo.scanDistance);                 
            }else if(currentSiteInfo.site.scanType == "RHI"){                
                if(!currentSiteInfo || currentSiteInfo.RHI.data.length == 0){                
                    return
                }
                let d = currentSiteInfo.scanDistance
                let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
                let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);  
                let _datas =currentSiteInfo.RHI.data.map(a=>{
                    return {
                        angle:a.angleOfPitch,
                        data:a.data,                    
                        dataTime:a.dataTime,
                        distanceData:a.distanceData,       
                    }
                }); 
                if(_datas.length == 0)     {
                    return
                }   
                if (!currentSiteInfo.RHI.el) {
                    let div = document.createElement('div')
                    div.style.width = '600px'
                    div.style.height = '300px'
                    currentSiteInfo.RHI.el = div                     
                }   
                let target = currentSiteInfo.RHI.el;
                let distanceData =_datas[0].distanceData;
                let noDataLength =  (distanceData[0])/distanceData[1] //无效距离   
                let scanLength =distanceData[2]*distanceData[1]       
                let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
                $help.drawScan(_datas,(obj)=>{                
                    zData[obj.y][obj.x]=obj.value;                   
                },()=>{
                    let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                    let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                                             
                    var data = [
                    {
                        y: yData,
                        x: xData,
                        z: zData,
                        type: 'heatmap',           
                        showscale: false,
                        colorscale:that.$refs.colorBar_rcs.colorScaleValue,
                        zmin:that.$refs.colorBar_rcs.rangeInfo[0].min,
                        zmax:that.$refs.colorBar_rcs.rangeInfo[that.$refs.colorBar_rcs.rangeInfo.length-1].max
                        }
                    ];
            
                    var layout = {               
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,         
                        xaxis: {
                            constrain:'range',
                            color:fontColor,
                            title:"(km)",
                            dtick:1,
                            domain:5,
                            tickmode:'linear',
                            tick0:0,
                            range:[0,8],
                            scaleanchor:'y',
                            scaleratio:1
                        },
                        yaxis: {     
                            constrain:'range',
                            color:fontColor,       
                            dtick:1,
                            domain:5,
                            title:"(km)",
                            tickmode:'linear',
                            range:[-0.1,3],
                            tick0:0
                        },
                    
                        margin:{
                            t:48,
                            r:46,  
                            l:42  
                            // l:22,
                        }
                    };   
                    let arr = $help.getLayerMapPosition(90,currentSiteInfo.scanDistance, [currentSiteInfo.site.lng, currentSiteInfo.site.lat])
                    var bounds = new AMap.Bounds(arr[0], arr[1])  
                    if(target.children.length> 0){
                        let mainSvg = target.querySelector('.main-svg');                   
                        if(target.clientHeight != mainSvg.clientHeight || 
                        target.clientWidth != mainSvg.clientWidth ) {                                            
                            Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 300, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;  
                        }else{
                            Plotly.react(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });
                        }                               
                    }else{
                        Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.RHI.canvasLayer) {
                                            currentSiteInfo.RHI.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.RHI.canvasLayer);
                                        } else {
                                            currentSiteInfo.RHI.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.RHI.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;               
                    }       
                },noDataLength,scanLength,distanceData[2],{d:d})                                                                        
            }else if(currentSiteInfo.site.scanType == "FIX"){
                if (!currentSiteInfo || currentSiteInfo.FIX.data.length == 0) {
                    return
                }
                let d = currentSiteInfo.scanDistance
                if(!currentSiteInfo.FIX.el){
                    let div = document.createElement('div')
                    div.style.width = '600px'
                    div.style.height = '300px'
                    currentSiteInfo.FIX.el = div                                  
                }                
                let target = currentSiteInfo.FIX.el;
                data =JSON.parse(JSON.stringify(currentSiteInfo.FIX.data)).sort((a,b)=>{
                    return new Date(a.dataTime.replace("T"," ")).getTime() - new Date(b.dataTime.replace("T"," ")).getTime()
                });

                let yData = [];               
                yData = [];       
                
                let distanceData =data[0].distanceData;            
                let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离
                for(let i = noDataLength;i< data[0].distanceData[2];i++){                       
                    let y = data[0].distanceData[0] + i* data[0].distanceData[1]
                    yData.push(y.toFixed(2))
                }          
                let xData = []; 
                let zData = [];
                let hoverText = [];
                data.forEach((a,i)=>{    
                    xData.push(new Date(a.dataTime.replace("T"," ")).pattern("HH:mm:ss"))
                    for(var j=0;j< yData.length;j++){
                        if(j > noDataLength)
                        {
                            let _index = j-noDataLength-1
                            if(!zData[_index]){
                                zData[_index] = [];
                                hoverText[_index] = []
                            }
                            zData[_index].push(a.data[j])                        
                        }   
                    }       
                });  
                                
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                    {
                    y: yData,
                    x: xData,
                    z: zData,
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:hoverText,
                    colorscale:this.$refs.colorBar_viz.colorScaleValue,
                    zmin:this.$refs.colorBar_viz.rangeInfo[0].min,
                    zmax:this.$refs.colorBar_viz.rangeInfo[this.$refs.colorBar_viz.rangeInfo.length -1].min
                    }
                ];

                var layout = {
                    title:"能见度时间热图",  
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                   
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:42,
                        r:62,
                        l:22, 
                    }
                };
                let arr = $help.getLayerMapPosition(90,currentSiteInfo.scanDistance, [currentSiteInfo.site.lng, currentSiteInfo.site.lat])
                var bounds = new AMap.Bounds(arr[0], arr[1])  
                if(target.children.length> 0){
                        let mainSvg = target.querySelector('.main-svg');                   
                        if(target.clientHeight != mainSvg.clientHeight || 
                        target.clientWidth != mainSvg.clientWidth ) {                                            
                            Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 300, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.FIX.canvasLayer) {
                                            currentSiteInfo.FIX.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.FIX.canvasLayer);
                                        } else {
                                            currentSiteInfo.FIX.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.FIX.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;  
                        }else{
                            Plotly.react(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.FIX.canvasLayer) {
                                            currentSiteInfo.FIX.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.FIX.canvasLayer);
                                        } else {
                                            currentSiteInfo.FIX.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.FIX.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });
                        }                               
                    }else{
                        Plotly.newPlot(target, data, layout,{responsive: true}).then(gd => {
                                Plotly.toImage(gd, { height: 250, width: 600 })
                                    .then(function (url) {
                                        if (!currentSiteInfo.FIX.canvasLayer) {
                                            currentSiteInfo.FIX.canvasLayer = new AMap.ImageLayer({                        
                                                url:url,
                                                bounds: bounds,
                                                opacity: that.opacity,
                                                zooms: [2, 30]
                                            });
                                            that.map.add(currentSiteInfo.FIX.canvasLayer);
                                        } else {
                                            currentSiteInfo.FIX.canvasLayer.setImageUrl(url)
                                            currentSiteInfo.FIX.canvasLayer.setBounds(bounds)
                                        }    
                                    })
                            });;               
                    } 
            }                                                                             
        },
        async drawHelper(currentSiteInfo,rp){     
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;                                
            let _datas = currentSiteInfo.PPI.data.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });
            if(_datas.length == 0 || !this.$refs.colorBar_viz)   {
                return;
            }
            let d = currentSiteInfo.scanDistance;  
            //273
            var that = this;
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (distanceData[0])/distanceData[1]  //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar_viz.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                currentSiteInfo.PPI.ctx.putImageData(imageData,0,0)  
                if (currentSiteInfo.PPI.canvasLayer) {
                    currentSiteInfo.PPI.canvasLayer.reFresh()
                }
            },noDataLength,scanLength,_datas[0].distanceData[2],{d:d,rp:rp,yaw:yaw})                                          
        },
        //周期时间显示
        //周期时间显示
        async drawText(){
            if(!this.loadOver){
                return;
            }
            var that = this;
            //500毫秒内请求合并
            if(this.drawTextTime){
                clearTimeout(this.drawTextTime)
            }else{
                setTimeout(() => {
                    if(that.map){                                  
                        let dataTime =[]
                        if(that.showTime){
                            dataTime = that.siteScanInfoList.map(a=>{
                                let pos = that.map.lngLatToContainer([a.site.lng,a.site.lat]); 
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y -60)/ Math.cos(angle/360)).toFixed(2);            
                                let lnglat = that.map.containerToLngLat(pos); 
                                return [lnglat.lng,lnglat.lat,a.time]
                            }) 
                        }  

                        let dataWeather = [];
                        
                        if(this.showWeather){
                            dataWeather = that.siteScanInfoList.map(a=>{
                                let pos = that.map.lngLatToContainer([a.site.lng,a.site.lat]); 
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y -120)/ Math.cos(angle/360)).toFixed(2);            
                                let lnglat = that.map.containerToLngLat(pos); 
                                return [lnglat.lng,lnglat.lat,a.weatherInfo]
                            })       
                        }
                        let dataSiteName = []
                        if(this.showSiteName){
                            dataSiteName = that.siteScanInfoList.map(a=>{
                                let pos = that.map.lngLatToContainer([a.site.lng,a.site.lat]); 
                                let angle = that.map.getPitch()
                                pos.y = ((pos.y -10)/ Math.cos(angle/360)).toFixed(2);            
                                let lnglat = that.map.containerToLngLat(pos);                                 
                                return [lnglat.lng,lnglat.lat,a.site.siteName]
                            }) 
                        }

                                        
                        let opt = {
                            series:[{
                            type:'scatter',
                            data:dataTime,
                            coordinateSystem:'amap',
                            symbolSize:0,
                            label:{
                                show:true,
                                formatter:'{@[2]}',
                                fontSize:24,                
                            }
                        },{
                            type:'scatter',
                            data:dataSiteName,
                            coordinateSystem:'amap',
                            symbolSize:0,
                            label:{
                                show:true,
                                formatter:'{@[2]}',
                                fontSize:16,  
                                padding: 6,
                                borderRadius:5,
                                fontSize:18,  
                                // color:'#fff',
                                align:'center',
                                verticalAlign :"middle",
                                borderColor:'#5499d9',
                                borderWidth:1,
                                backgroundColor:"#fff"              
                            }
                        },{
                            type:'scatter',
                            data:dataWeather,
                            coordinateSystem:'amap',
                            symbolSize:0,
                            label:{
                                show:true,
                                formatter:(re)=>{
                                    let weatherInfo = re.data[2]                            
                                    return `天气：${weatherInfo.weather} 风向：${weatherInfo.windDirection} 风力：${weatherInfo.windPower} 温度：${weatherInfo.temperature} 湿度：${weatherInfo.humidity}`
                                },
                                padding: 6,
                                borderRadius:5,
                                lineHeight:18,
                                fontSize:18,  
                                // color:'#fff',
                                align:'center',
                                verticalAlign :"middle",
                                borderColor:'#5499d9',
                                borderWidth:1,
                                backgroundColor:"#b1d7a8"
                            }
                        },]}                        
                                           
                        if(that.mapEchart && that.mapEchart.setOption){                    
                            that.mapEchart.setOption(opt) 
                        }             
                    }
                }, 500);
            }                        
        },       
    },
   
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.window{
    width: 100%;
    height: 100%;
}
.map{
    width: 100%;
    top: 40px;
    position: relative;
    height: calc(100% - 40px);
}
.showDataBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.weatherinfo{
     display: flex;
     //flex-direction: column;
     position: absolute;
     //left: 20px;
     //bottom: 400px
     right: 50px;
     top: 45px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 10px;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 24px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
.hotchart{
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
}

.mapToolbar{
    position: absolute;
    z-index: 1;
    width: 100%;
}

//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 160px;
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
//比例尺
:deep(.amap-scalecontrol){
    left:10px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
//垂直热图
.externalDatasBox{    
    flex-shrink: 0;
    position: relative;
    top:0;
    right:0px;  
    overflow: auto;
    max-height: 100%;
    width: 6
    0%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 100%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 50%;
    height: 100%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}
.rowscale{
    height: 100%;
}
.scanmapTitle{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
.configBtns{
    position: absolute;
    right: 15px;
    bottom: 0px;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 0px;
}
</style>

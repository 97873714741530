<template>
    <v-container class="ma-0 pa-0 bell">
        <v-menu offset-x open-on-hover >
            <template v-slot:activator="{ on,attrs }">
                <v-btn 
                text
                fab 
                small
                v-show="alarmList.length > 0"
                v-bind="attrs"
                v-on="on"  
                >
                <v-badge overlap  color="red" :value="alarmList.length != 0" :content="type == 0 ? alarmList.length : total">
                    <v-icon  :style="`z-index:9999;-webkit-background-clip: text;`" :color="alarmList.length > 0 ? 'red':'var(--v-rTheme-base)'">mdi-bell</v-icon>  
                </v-badge>                
                </v-btn>                  
            </template>
            <v-card width="380" height="420">
                <v-list   @click.stop style="height: calc(420px - 60px);overflow: auto;">
                    <v-list-item   @click.stop class="my-2" v-for="(alarm,k) in alarmList" :key="k">
                        {{`${new Date(alarm.DapTime).pattern("MM月dd日 HH时mm分")}，${alarm.DapHeight.toFixed(2)}至${alarm.DapEndHeight.toFixed(2)}公里处发生大风报警，风力${alarm.DapStraightValue.toFixed(2)}，风向${alarm.DapStraightAngle.toFixed(2)}`}}
                    </v-list-item>
                </v-list>
                <v-card-actions @click.stop v-show="type != 0">
                    <v-pagination 
                    @input="load"
                    @click.stop
                    v-model="page"
                    :length="length"
                    :total-visible="5"></v-pagination>
                </v-card-actions>                
            </v-card>
        </v-menu>
    </v-container>    
</template>
<script>
import { getProfileAlarmApi,getSitesProfileAlarmApi } from '@/api' 
export default {
    name:"wls.alarm",
    props:{
        type:0,
        selectSites:{
            type:Array,
            default:()=>{
                return []
            }
        },
        start:{
            Type:Object,
            default:()=>{
                let s = new Date();
                s.setTime(s.getTime() - 3600 * 1000 * 3)
                return s
            }
        },
        end:{
            Type:Object,
            default:()=>{               
                return new Date()
            }
        }        
    },
    data(){
        return{
            alarmList:[],
            page:1,
            rows:10,
            total:0,
        }
    },
    computed:{
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){ 
                this.load();                            
            }
        },   
        "start":{
            handler(){
                this.load()
            }
        },
        "end":{
            handler(){
                this.load()
            }
        },
        "$store.state.storage_global.alarmProfileList":{
            handler(d){
                if(d.dataType == 54 && this.type !='1'){   
                    if(this.type == 0 && d.siteId == this.$store.state.storage_global.currentSite.siteId)   {
                        this.alarmList =  d.data;
                    }                    
                }
            }
        },selectSites:{
            handler(n){                
                if(this.type == 2){
                    this.load()
                }
            }            
        }
    },
    mounted(){
        this.load()
    },
    methods:{
        load(){
            let that = this;
            if(this.type != 2){
                getProfileAlarmApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{
                        StartTime:this.start,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                        EndTime:this.end,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                        AlarmType:0, 
                        PageSize:this.rows,
                        PageIndex:this.page,                      
                    },success:(d)=>{
                        if(that.type == 1){
                            that.alarmList = d.Data                    
                        }   
                        else                 
                        {
                            that.alarmList = d.Data.filter(a=>a.DapTime == d.Data[0].DapTime)
                        }
                        that.total = d.Total
                    }
                })
            }else{
                if(this.selectSites.length == 0 ){return;}
                getSitesProfileAlarmApi({
                    params:{
                        siteIds:this.selectSites.map(a=>a.siteId),
                        StartTime:this.start,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                        EndTime:this.end,//new Date().pattern('yyyy-MM-dd HH:mm:ss'),
                        AlarmType:0, 
                        PageSize:this.rows,
                        PageIndex:this.page,   
                    },
                    success:(d)=>{
                        that.alarmList = d.Data     
                        that.total = d.Total
                    }
                })
            }            
        }
    }
}
</script>

<style lang="scss" scoped>
.bell{
    position: absolute;
    z-index: 99;
    right: 30px;
    top: 20px;
    float: right;
    width: 30px;
}
</style>

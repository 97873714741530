<template>
  <v-container class="ma-0 pa-0" style="overflow:auto;max-width:100%;">
    <div class="btnsRow">
        <v-row no-gutters class="ma-0 flex-wrap px-2">
            <v-col class="my-1 ">   
                <v-card elevation="0" class="my-0 pa-0" style="min-width:360px;max-width: 400px;">
                    <SearchDateTimePickerPlus class="" :maxRangeDay='7' range v-bind:datetime.sync="timeRange" />
                </v-card>
            </v-col>
            <v-col style="max-width: 140px;" class="my-1 mx-1">                                           
                <SitesSelectFab                 
                searchBtn
                checkAllBtn
                :value.sync="sites"
                :options="allSites"                                             
                multiple
                required
                :online="false"
                />                                                                       
            </v-col>
            <!-- <v-col md="auto" class="my-1 mx-1">                                           
                <v-menu open-on-hover offset-y bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn                                                              
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        >                        
                        {{distance == 0 ? '请选择':distance + '公里'}}
                        </v-btn>
                    </template>
                    <v-card max-height="320">
                        <v-list dense>
                            <v-list-item-group v-model="distance">
                                <v-list-item
                                    v-for="(item) in distanceLimit"
                                    :key="'k' + item" 
                                    :value="item/10"                                                    
                                >
                                <v-list-item-title>{{ item/10 }}公里</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>                    
                </v-menu>                                                                      
            </v-col> -->
            <v-col md="auto" class="my-1">
                <v-menu open-on-hover offset-y bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn                                                              
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0"
                        >
                        {{currentViewMode.text}}
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item-group v-model="currentViewMode">
                            <v-list-item
                                v-for="(item, index) in viewMode"
                                :key="index" 
                                :value="item"                                                    
                            >
                            <v-list-item-title>{{item.text}}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu> 
            </v-col>            
        </v-row>
    </div>
    <v-card class="distanceBar" elevation="0">
        <div style="min-width: 80px;text-align: center;">高度[km]</div>
        <v-slider          
            v-model="distance"
            vertical   
            :tick-labels="tickLabels"
            min="0"
            step="0.5"
            :max="distanceLimit/10"       
            hide-details    
        ></v-slider>
    </v-card>
    <v-row class="ma-0 pa-0 height100">
        <v-col class="ma-0 pa-0 height100" cols="4">            
            <!-- map -->
            <barbsMap :timeRange="timeRange" ref="barbsMap" class="width100 height100" :selectSites="sites" :dataBarbsList="distanceDataList" />
        </v-col>
        <v-col  class="ma-0 pa-0"  cols="8">
            <v-tabs  style="width: 100%;" align-with-title class="px-3 mt-1" center-active dark background-color="primary">
                <v-tabs-slider color="secondary"></v-tabs-slider>
                <v-tab @click="tabValue = i.value" v-for="i in showTypeList" :key="i.value" :tab="i.value">{{i.text}}</v-tab>
            </v-tabs>
            <v-card class="d-flex flex-wrap"  ref="monitorBox" style="height: calc(100% - 92px); width: calc(100% - 24px);left: 12px;overflow: auto;">
                <shear 
                 v-show="tabValue == 9" :style="`max-width: ${getWidth(index)};max-height: ${sites.length == 1 ? 100 : 50}%;`" 
                 v-for="(i,index) in sites" 
                 :key="'s_'+i.siteId"  
                 :currentSite="i" 
                 :timeRange="timeRange" 
                 :currentView="tabValue"
                 :currentViewMode="currentViewMode">
                 </shear> 
                <profile
                 v-show="tabValue == 5 || tabValue == 6 || tabValue == 7 || tabValue == 8"
                 @update="update(i.siteId,$event)" 
                 :timeRange="timeRange" 
                 ref="profile" :currentView="tabValue" 
                 :style="`max-width: ${getWidth(index)};max-height: ${sites.length == 1 ? 100 : 50}%;`" 
                 :sites="sites" 
                 v-for="(i,index) in sites" 
                 :key="i.siteId"  
                 :currentSite="i" 
                 :currentViewMode="currentViewMode">
                </profile>               
            </v-card> 
            <div class="barbsTab mx-2 pt-1">
                <v-row class="ma-0 pa-0 width100 flex-nowrap">
                    <v-col class="ma-0 pa-0 pt-1 d-flex"  v-for="i in barbsLevel" :key="i.level">
                        <div v-html="barbsHtml(i.level,0)"></div>
                        <span style="font-size: 12px;line-height: 24px;"> {{i.level == 24 ? '>=24':i.level}}</span>
                    </v-col>
                </v-row>
            </div>          
        </v-col>
    </v-row>   
  </v-container>
</template>
<script>
import barbsMap from '@/components/monitoring/wls/windowUnion/barbsMap'
import profile from '@/components/monitoring/wls/windowUnion/profile'
import shear from '@/components/monitoring/wls/windowUnion/shear.vue'
import siteTypeWithRoute from '@/plugins/datas/siteTypeWithRoute'
import barbsSvg from '@/plugins/helper/D3DrawHelper/barbsSvg.js'
export default {
    name:"monitoring.wls.windowUnion",
    components:{
        barbsMap,
        profile,
        shear
    },
    data(){
        return {
            timeRange:[],
            allSites:[],
            sites:[],
            mbw:0,
            mbh:0,
            updateTime:0,
            grid:{
                row:[],
            },
            tabValue:5,
            currentViewMode:{
                text:"三轴",
                value:'Tri'
            }, 
            viewMode:[{
                text:"三轴",
                value:'Tri'
            },{
                text:"四轴",
                value:'Qua'
            }],
            showTypeList:[{
                text:"风廓线风羽图",
                value:5,
                scanType:[3,4]
            },{
                text:"风廓线垂直风速时间热图",
                value:6,
                scanType:[3,4]
            },{
                text:"风廓线水平风速时间热图",
                value:7,
                scanType:[3,4]
            },{
                text:"风廓线水平风向时间热图",
                value:8,
                scanType:[3,4]
            },
            {
                text:"风廓线垂直风切变时间热图",
                value:9,
                scanType:[3,4]
            }
        ],
            barbsLevel:[{
                level:2,
                color:''
            },{
                level:4,
                color:''
            },{
                level:6,
                color:''
            },{
                level:8,
                color:''
            },{
                level:10,
                color:''
            },{
                level:12,
                color:''
            },{
                level:14,
                color:''
            },{
                level:16,
                color:''
            },{
                level:18,
                color:''
            },{
                level:20,
                color:''
            },{
                level:22,
                color:''
            },{
                level:24,
                color:''
            }],
            distance:1,
            distanceDataList:[],
            playStatus:'pause',
            distanceLimit:100
        }
    },
    watch:{
        sites:{
            handler(n){
                if(n.length > 0){
                    this.distanceDataList = [];
                    this.updateTime = 0;
                    let profileList = this.$refs.profile
                    if(profileList){
                        for(let profile of profileList){
                            profile.reload();
                        }
                    }
                }                                
            }
        },
        distance:{
            handler(n,v){                
                console.log(n)
                this.updateTime = 0;
                this.distanceDataList = [];                
                if(this.sites.length == 0 && n!=0){
                    openNotice({
                        type:'error',
                        text:"请先选择站点"
                    })
                    this.distance = 0
                    return
                }
                if(this.sites.length > 0 && n!=0){
                    console.log(this.sites)
                    this.getDistanceData(n)
                }                
            }
        },
        currentViewMode(n){
            this.distanceDataList = [];
            if(n.text == '三轴'){
                this.distanceLimit = 100
                if(this.distance > 10){
                    this.distance = 10
                }
            }
            if(n.text == '四轴'){
                this.distanceLimit = 100
            }
        }
    },
    computed:{    
        barbsHtml(){
            return (speed = 1,angle = 90,color='black')=>{
                if(speed > 1 && speed <= 2){
                    color = '#ea80fc'
                }else if(speed > 2 && speed <= 4){
                    color = '#b75df5'
                }else if(speed > 4 && speed <= 6){
                    color = '#3220dc'
                }else if(speed > 6 && speed <= 8){
                    color = '#5efdfd'
                }else if(speed > 8 && speed <= 10){
                    color = '#23fe23'
                }else if(speed > 10 && speed <= 12){
                    color = '#6EAB42'
                }else if(speed > 12 && speed <= 14){
                    color = '#6EAB42' //FFF73E
                }else if(speed > 14 && speed <= 16){
                    color = '#FFF73E'//E4B16B
                }else if(speed > 16 && speed <= 18){
                    color = '#FFF73E'//FD5555
                }else if(speed > 18 && speed <= 20){
                    color = '#FD5555'//EF2D4E
                }else if(speed > 20 && speed <= 22){
                    color = '#FD5555'//72352B
                }else if(speed > 22 && speed <= 24){
                    color = '#969696'
                }else{
                    color = '#bd1084'
                }
                let windBarb = new barbsSvg(
                    speed * 5/2  /* wind speed in knots */,
                    angle /* wind direction angle */,      
                {
                    size:{
                        height:12,
                        width:28
                    },
                        triangle:{
                        fill:color,
                        stroke:color
                        },
                        circle:{
                        fill:color,
                        stroke:color
                        },
                        bar:{
                        stroke:color
                        }         
                    }
                    ).draw();        
                return windBarb.outerHTML     
            }             
        },
        getWidth(){
            return (i)=>{
                return this.sites.length <= 2 || (i == this.sites.length -1 && (i+1) %2 != 0) ? '100%':'50%'
            }
        },
        tickLabels(){
            let labels = []
            for(let i = 0;i <= this.distanceLimit;i+=5)
                labels.push(i/10 + '')
            return labels
        }       
    },
    mounted(){    
        this.timeRange =[new Date(new Date().setDate(new Date().getDate() -1)),new Date()]    
        $help.sleep(300).then(a=>{
            this.load();  
        })        
    },        
    methods:{
        load(){
            let sites = this.$store.state.storage_user.sites.filter(a=>{                               
                return a.type == siteTypeWithRoute.find(a=>a.name == 'wls').type && a.transStatus == 0;
            })               
            this.allSites = sites;
            this.sites = sites.slice(0,4)      
            if(sites.length == 0){
                openNotice({
                    text:"暂无实时站点数据",
                    type:"error"
                })                
                this.sites = this.$store.state.storage_user.sites.filter(a=>{                               
                    return a.type == type;
                })             
            }     
        }, 
        getDistanceData(n){
            let profileList = this.$refs.profile
            let distanceDataList = []
            for(let profile of profileList){
                let dataList = profile.dataList;             
                if(dataList && dataList.length > 0){
                    let index =$help.getClosest(this.distance,dataList[0].height,0.5);
                    if(!$help.isNull(index)){
                        distanceDataList.push({
                            siteId:dataList[0].siteId,
                            data:dataList?.filter(a=>a?.straightValue[index] != 'NaN' && a?.straightAngle[index]!="NaN")?.map(a=>{
                                return{
                                    dataTime:a?.dataTime,
                                    angle:a?.straightAngle[index],
                                    speed:a?.straightValue[index]
                                }
                            })                    
                        })
                    }                    
                }                
            }
            this.distanceDataList = distanceDataList.filter(a=>a.data.length >0)
            if(this.distanceDataList.length == 0){                
                openNotice({
                    type:"error",
                    text:"暂无可展示数据，请重新选择"
                })
            }
        },
        update(siteId,dataList){
            let init = false
            this.updateTime+=1;
            let distanceDataList = this.distanceDataList            
            distanceDataList.forEach(a=>{
                if(a.siteId == siteId){
                    init = true;
                    if(dataList && dataList.length > 0){
                        let index =$help.getClosest(this.distance,dataList[0].height,0.5);
                        if(!$help.isNull(index)){
                            a.data = dataList?.filter(a=>a?.straightValue[index] != 'NaN' && a?.straightAngle[index]!="NaN")?.map(a=>{
                                return{
                                    dataTime:a?.dataTime,
                                    angle:a?.straightAngle[index],
                                    speed:a?.straightValue[index]
                                }
                            })  
                        }else{
                            a = null
                        }  
                    }                
                }
            })
            if(!init){
                if(dataList && dataList.length > 0){
                    let index =$help.getClosest(this.distance,dataList[0].height,0.5);
                    if(!$help.isNull(index)){
                        distanceDataList.push({
                            siteId:siteId,
                            data:dataList?.filter(a=>a?.straightValue[index] != 'NaN' && a?.straightAngle[index]!="NaN")?.map(a=>{
                                return{
                                    dataTime:a?.dataTime,
                                    angle:a?.straightAngle[index],
                                    speed:a?.straightValue[index]
                                }
                            })                    
                        })
                    }
                }     
            }
            this.distanceDataList = distanceDataList.filter(a=>a!=null)            
            if(this.distanceDataList.length == 0 && this.updateTime == this.sites.length){    
                console.log(1)
                openNotice({
                    type:"error",
                    text:"存在无可展示数据站点，请重新选择该站点"
                })
            }
        }     
    }
}
</script>

<style scoped lang="scss">
:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}
:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 
.btnsRow{
    width: calc(33.3%);
    position: absolute;
    z-index: 1;
    top: 10px;
}
.distanceBar{
    position: absolute;
    z-index: 1;
    height: 60%;
    top: 18%;
    background: rgba(0,0,0,0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    :deep(.v-slider){
        height: 320px;
    }
}
</style>


<template>
  <v-card height="100%" width="100%">
    <v-overlay class="width100 height100"  v-model="imageOverlay.show">
        <v-card >
            <v-card-actions class="d-flex justify-end">
                <v-icon @click="open">mdi-download</v-icon>
                <v-icon @click="imageOverlay.show=false">mdi-close</v-icon>
            </v-card-actions>                
            <v-carousel v-model="currentImg" height="100%" hide-delimiter-background show-arrows-on-hover >
                <v-carousel-item    
                class="width100 height100"               
                    v-for="(item,i) in imageOverlay?.images"
                    :key="i"                       
                >
                    <v-img width="1280" height="760"  :src="item"></v-img>                        
                </v-carousel-item>
            </v-carousel>
        </v-card>
    </v-overlay>
       <v-card-actions class="mapToolbar mt-4 mx-4">
                <v-row class="ma-0 align-center justify-start">
                    <!-- <v-menu open-on-hover offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn     
                                v-show="false"                                                         
                                v-bind="attrs"
                                v-on="on"
                                    class="ma-3"
                                >
                                {{currentScanMapType.name}}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item-group v-model="currentScanMapType">
                                    <v-list-item
                                        v-for="(item, index) in PPI_ScanTypes"
                                        :key="index" 
                                        :value="item"                                                    
                                    >
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                    </v-menu>                          -->
                    <v-card class="mx-4">
                        <v-card-actions class="d-flex justify-space-around ma-0 pa-1">
                            <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>
                            <v-icon v-show="(playStatus != 'play') && (playStatus != 'replay')" @click="play('play')">mdi-play</v-icon>
                            <v-icon v-show="(playStatus == 'play') || (playStatus == 'replay')" @click="play('pause')" >mdi-pause</v-icon>
                            <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>   
                            <v-icon style="font-size:22px;padding:4px;" @click="play('replay')">mdi-replay</v-icon>       
                            <v-icon style="font-size:22px;padding:4px;" v-show="permissionUser.downloadVideoShow" @click="downloadImgToVideo()">mdi-download</v-icon>       
                        </v-card-actions>
                    </v-card>
                </v-row>
            </v-card-actions>
            <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />    
            <pointDataCard  ref="monitorpointCard" class="monitorpointCard" ></pointDataCard>        
        <div ref="scanMap" class="map noevent">                            
        </div>
        <ColorBarConfig v-show="false" :dataKey="2" disabled  ref="colorBar" class="mapColorBarConfig" />        
        <div class="hotMapColorBar" v-show="hotLayer">
            <div :style="`background-color:${i.value}`" v-for="i in hotMapColorBarColor" class="hotMapColorBarBox" :key="i.key">
                <div class="hotMapColorBarText">{{i.numStr}}</div>
            </div>
        </div>
        <v-expand-transition>
            <v-btn v-show="!showWarnList && (permissionUser.alarmImg || permissionUser.alarmPoint || permissionUser.alarmTimeChart)" class="mx-2 warnBtn pointer" @click="showWarnList=!showWarnList" fab large color="rgba(0,0,0,0)" >
                <v-icon title="还原窗口" color="primary" size="48px">mdi-android</v-icon>
            </v-btn>
        </v-expand-transition>
        <v-expand-transition origin="bottom bottom">
            <v-card v-show="showWarnList && (permissionUser.alarmImg || permissionUser.alarmPoint || permissionUser.alarmTimeChart)" :width="width"  :style="`height:${height}px;`" class="warnInfo">
                <v-overlay absolute v-model="loadingAlarmGis">
                    加载中...
                </v-overlay>             
                <v-card-actions class="pa-0">
                    <v-row dense class="pa-0 ma-0">
                        <v-col cols="1" class="d-flex align-center justify-center">
                            <v-icon class="pointer" title="设置大小" @touchmove.stop="resize($event)" @mousedown.stop="resize()">mdi-arrow-all</v-icon>
                        </v-col>
                        <v-col>
                            <v-tabs class="alarmImage" v-model="tab" hide-slider prev-icon="" next-icon="" :show-arrows="false">
                                <v-tab v-show="permissionUser?.alarmPoint?.show" :disabled="permissionUser.alarmPoint.disabled" >报警点</v-tab>
                                <v-tab v-show="permissionUser?.alarmTimeChart?.show"  :disabled="permissionUser.alarmTimeChart.disabled">  报警时间强度图</v-tab>
                                <v-tab v-show="permissionUser?.alarmImg?.show" :disabled="permissionUser.alarmImg.disabled" >报警拍摄</v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="1" class="d-flex align-center justify-center">
                            <v-icon class="pointer" title="最小化" @click.stop="showWarnList=!showWarnList">mdi-chevron-down-circle</v-icon>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-window :value="tab" class="height100" >
                    <v-window-item    
                        class="height100"                   
                        :value="0"
                    >
                        <v-data-table                                 
                            height="100%"
                            :loading="loadingWarnInfo"                               
                            fixed-header  
                            :headers="headers"
                            show-group-by
                            :custom-group="groupNew"
                            :custom-sort="sortNew"
                            :items="alarmList"
                            item-key="_id"
                            disable-pagination
                            hide-default-footer        
                            @click:row="setCurrentPoint"                                                                   
                            calculate-widths>
                            <template v-slot:top>
                                <v-row dense no-gutters class="mx-2 mt-2 flex-shrink-0 flex-grow-0 ">
                                    <!-- <v-col cols="1" class="d-flex align-start flex-grow-0 flex-shrink-0 pointer">
                                        <v-icon title="设置大小" @mousedown="resize()">mdi-arrow-all</v-icon>
                                    </v-col> -->                           
                                    <v-col cols="2" style="min-width:96px" class="flex-grow-0 flex-shrink-0 ">
                                        <v-switch
                                            title="开启热力图"
                                            @change="hotLayerComputed = false;setHotLayer($event);if($event){setOpacity(5)}else{setOpacity(7)};"
                                            v-model="hotLayer"
                                            v-show="permissionUser.alarmPoint.hotChartShow"
                                            class="mt-0"
                                            :label="$i18n.t('history.table.topTool.hotBar')"
                                            hide-details
                                            dense
                                        ></v-switch>                                                
                                        <!-- <v-switch
                                            title="合并热力图"
                                            @change="hotLayer = false;setHotLayer($event);if($event){setOpacity(5)}else{setOpacity(7)};"
                                            v-model="hotLayerComputed"
                                            class="mt-0"
                                            label="合并热力图"
                                            hide-details
                                            dense
                                        ></v-switch>     -->
                                    </v-col>
                                    <v-col cols="2" style="min-width:112px">
                                        <v-switch      
                                            title="关联地图"         
                                            v-show="permissionUser.alarmPoint.attachMapShow"                                     
                                            v-model="attachMap"
                                            :disabled="permissionUser.alarmPoint.attachMapDisabled"
                                            class="mt-0"
                                            :label="$i18n.t('history.table.topTool.attachMapBar')"
                                            hide-details
                                            dense
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="4" class="flex-grow-0 flex-shrink-0 d-flex">
                                        <v-item-group multiple @change="loadAlarmPoints" class="d-flex">
                                            <v-item
                                            v-for="(i,k) in alarmFilterRule" :key="k"
                                            v-slot="{ active, toggle }"
                                            >
                                            <v-checkbox :input-value="active"
                                                @click="toggle" v-model="i.checked" dense hide-details hide-spin-buttons :label="i.name "></v-checkbox>
                                            </v-item>
                                        </v-item-group>                                     
                                    </v-col>
                                    <v-col cols="1" class="mx-2">
                                        <v-icon v-show="permissionUser.alarmPoint.downloadShow" @click="downloadAlarmInfo" title="导出">mdi-download</v-icon>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <!-- <v-col cols="1" class="d-flex justify-end align-start flex-grow-0 flex-shrink-0 pointer" >
                                        <v-icon title="最小化" @click="showWarnList=!showWarnList">mdi-chevron-down-circle</v-icon>
                                    </v-col> -->
                                </v-row>
                            </template>
                            <!-- 分组 -->
                            <template v-slot:item.date="{ item }">
                                <div  style="display:flex;flex-direction:column;height:100%;align-items:center;justify-content: space-around">
                                    <span  :class="[item.date.length > 1 ?'listDateInfoItem':'',i!=item.count.length -1?'listDateInfoItemBorder':'']" v-for="(tqt,i) in item.date">{{tqt}}</span>
                                </div>
                                </template>
                                <template v-slot:item.count="{ item }">
                                <div style="display:flex;flex-direction:column;height:100%;align-items:center;justify-content: space-around">
                                    <span :class="[item.count.length > 1 ?'listDateInfoItem':'',i!=item.count.length -1?'listDateInfoItemBorder':'']" v-for="(c,i) in item.count">{{c}}</span>
                                </div>
                                </template>
                                <template v-slot:group.header="{ group, headers,items,isOpen, toggle,remove }">
                                <td :colspan="headers.length"> 
                                    <div class="v_table_group_selfC " >
                                        <div class="v_table_group_header_self ">
                                            <div class="v_group_span v-row-group__header">{{group}}</div>
                                            <div class="v_group_span v-row-group__header">{{'报警总数：'+getGroupHeader({data:items,type:'warncount'}) + '次'}}</div>
                                            <div class="v_group_span v-row-group__header">{{'报警地点：'+getGroupHeader({data:items,type:'positionCount'})+ '个'}}</div>                                    
                                        </div>
                                        <div class="v_table_group_header_btn">
                                            <v-btn icon small @click="toggle">
                                            <v-icon v-if="isOpen">mdi-minus</v-icon>
                                            <v-icon v-else>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-btn icon small @click="remove">
                                            <v-icon>mdi-close</v-icon>
                                            </v-btn>                  
                                        </div>                                                           
                                    </div>                                            
                                </td>    
                            </template>
                        </v-data-table>
                    </v-window-item>
                    <v-window-item                       
                        :value="1"
                        class="height100"
                        @click.stop=""
                    >
                    <v-card class="width100 height100 pointChart d-flex flex-column align-center" style="overflow:auto">
                        <v-overlay absolute :value="alarmList.length == 0 && !loadingAlarmGis">
                         暂无数据
                        </v-overlay>
                        <v-card-actions>
                            <v-select
                            :items="chartTypeList"                        
                            v-model="chartType"
                            hide-details
                            hide-spin-buttons
                            label="图表类型"
                            @change="loadPoitTimeChart();"
                            solo
                            ></v-select>
                            <div class="d-flex flex-nowarp mx-2">
                                小时统计:
                                <v-switch 
                                class="mx-2 pa-0 my-0"
                                hide-details
                                hide-spin-buttons                           
                                v-model="isHour"
                                :label="isHour?'开启':'关闭'"
                                @change="loadChart"
                                ></v-switch>
                            </div>

                        </v-card-actions>
                        <v-card  class="width100" style="overflow:auto;height:calc(100% - 64px);">
                            <div  v-show="alarmList.length > 0" ref="alarmChartPoint" class="alarmChart"></div>
                            <div  v-show="alarmList.length > 0" ref="alarmChartTownship" class="alarmChart"></div>
                            <div  v-show="alarmList.length > 0" ref="alarmChartDistrict" class="alarmChart"></div>
                        </v-card>                       
                    </v-card>
                    </v-window-item>                    
                    <v-window-item                       
                        :value="2"
                        class="height100"
                    >
                       <v-data-table                                 
                            style="height:calc(100% - 43px)"                            
                            fixed-header  
                            :headers="headersImg"
                            show-group-by
                            :items="alarmImgList"
                            item-key="_id"
                            disable-pagination
                            hide-default-footer  
                            @click:row="setCurrentImgPoint"                                                                        
                            calculate-widths>
                            <template v-slot:item.image="{ item }">
                                <v-row class="ma-0 pa-0 d-flex">
                                    <v-img class="ma-1" height="36" width="64" @click.stop="openImgList(item.image,imgsrc)" v-for="(imgsrc,i) in item.image.slice(0,2)" :key="i" :src="imgsrc"></v-img>
                                    <span v-show="item.image.length > 2">...</span>
                                </v-row>
                            </template>
                       </v-data-table>
                       <v-row justify="center" align="center" class="ma-0 flex-grow-0">
                        <v-pagination
                            @input="loadAlarmImages"
                            v-model="page"
                            :length="length"
                            circle
                            ></v-pagination>
                            {{$i18n.t("history.pageTotal",[total])}}
                        </v-row>
                    </v-window-item>
                </v-window>         
            </v-card>
        </v-expand-transition>                                              
    </v-card> 
</template>

<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import * as XLSX from 'xlsx/xlsx.mjs';
import { new_marker } from '@/plugins/datas/mapmark.js';
import { getAlarmPointGisListApi,getSiteAlarmImageApi,getAlarmPointComputedsApi,getGisApi,getHistoryImagesApi,getImageApi,getPlsPositionDataApi } from '@/api'
import customProtocolCheck from "custom-protocol-check";
import { uploadMinioURL } from '@/api/url'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
import pointDataCard from '@/components/cardMenu/pointDataCard'
import Plotly from 'plotly.js-dist'
import sysconfig from '@/plugins/datas/sysconfig'
export default {
    name:'scanmap_pls',
    components:{ColorBarConfig,monitorPointDataCard,pointDataCard},
    props:{
        timeRange:{
            type:Array
        },
        currentWindow:{
            type:String
        },
        settime:{
            type:Date
        }
    },
    data(){
        return {
            permissionUser:{
                pointTrendShow:true,
                downloadVideoShow:true,
                tabDisabled:false,
                alarmPoint:{
                    show:true,
                    hotChartShow:true,
                    attachMapShow:true,
                    attachMapDisabled:false,
                    downloadShow:true,                                    
                },
                alarmTimeChart:{
                    show:true,
                   
                },
                alarmImg:{
                    show:true,
                }
            },   
            tab:0,
            scanDistance:6,
            map:null,
            mapEchart:null,
            mapContextMenuPositon:[],
            center:[],
            loadMapOver:false,
            taskId:'',
            taskIdImage:'',
            //热力图
            mapHotLayer:null,
            mapHotDatas:[],
            mapHotComputedDatas:[],
            hotLayer:false,
            hotLayerComputed:false,
            color:{
                0.2: '#5e04fb',
                0.5: '#a0ff2f',
                0.7: '#fde468',
                1.0: '#d7191c'
            },
            attachMap:false,
            //报警列表
            showWarnList:true,
            loadingWarnInfo:false,
            headers:[{
                text:this.$i18n.t('history.table.header.city'),
                value: 'city',
                sortable: true,
                groupable:false,
                width:'13%'
                },
                {
                text:this.$i18n.t('history.table.header.district'),
                value: 'district',
                sortable: true,
                groupable:true,
                width:'13%'
                },
                {
                text:this.$i18n.t('history.table.header.street'),
                value: 'street',
                sortable: true,
                groupable:true,
                width:'13%'
                },{
                text:this.$i18n.t('history.table.header.position'),
                value: 'position',
                sortable: true,
                groupable:false,
                width:'36%'
                },{
                text:this.$i18n.t('history.table.header.total'),
                value:'total',
                groupable:false,
                width:'5%'
                },{
                text:this.$i18n.t('history.table.header.count'),
                value:'count',
                groupable:false,
                width:'5%'
                },{
                text:this.$i18n.t('history.table.header.time'),
                value: 'time',
                groupable:false,
                width:'20%'
            }],
            alarmList:[],
            width:0,
            height:0,
            chartHeight:300,
            chartWidth:500,
            //点标记
            marker:null,
            siteMarker:null,
            //信息窗体
            winInfo:null,

            monitorPointMarkers:[],//国控点标记

            //历史扫描图  
            imgObj:null,      
            imageList:[],
            canvas:null,
            ctx:null,
            canvasLayer:null,
            opacity:0.7,        
            timmer:null,
            playStatus:'pause',// 播放状态
            movePlayStatus:false,
            moveTimeout:null,
            currentImageIndex:0,
            setOpacity:null,
            timeLoadImg:null,//防重复加载
            //按钮
            PPI_ScanTypes:[],//当前站点水平数据类型
            // currentScanMapType:"",
            dataType:2,
            headersImg:[{
                text:this.$i18n.t('history.table.header.city'),
                value: 'city',
                sortable: true,
                groupable:false,
                width:'10%'
                },
                {
                text:this.$i18n.t('history.table.header.district'),
                value: 'district',
                sortable: true,
                groupable:true,
                width:'10%'
                },
                {
                text:this.$i18n.t('history.table.header.street'),
                value: 'street',
                sortable: true,
                groupable:true,
                width:'10%'
                },{
                text:this.$i18n.t('history.table.header.position'),
                value: 'position',
                sortable: true,
                groupable:false,
                width:'30%'
                },{
                text:this.$i18n.t('history.table.header.total'),
                value:'total',
                groupable:false,
                width:'5%'
                },{
                text:this.$i18n.t('history.table.header.time'),
                value: 'time',
                groupable:false,
                width:'20%'
            },{
                text:this.$i18n.t('history.table.header.img'),
                value: 'image',
                groupable:false,
                width:'40%'
            }], 
            alarmImgList:[],            
            page:1,
            rows:10,
            total:0,
            imageOverlay:{
                show:false,
                images:[],
                currentSrc:""   
            },
            currentImg:null,
            alarmChartList:[],     
            loadingAlarmGis:false,
            chartTypeList:[{
                text:"折线图",
                value:0
            },{
                text:"柱状图",
                value:1
            }],
            chartType:1,
            pointChartTimer:null,
            currentPeriod:'',
            isHour:false,
            alarmFilterRule:[]
        }        
    }, 
    beforeUnmount(){
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
    }, 
    computed:{
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },
        checkedId(){
            if(!this.alarmFilterRule) return [];
            return this.alarmFilterRule.filter(a=>a.checked).map(a=>a.id)  
        },
        hotMapColorBarColor(){
            let _color = [];
            if(this.color){
                //最大值
                let max = 0;
                this.mapHotDatas.map(a=>{
                    if(a.count > max) max = a.count;
                });                                                                               
                for(var i in this.color){
                    _color.push({
                        numkey:Number(i),
                        value:this.color[i]
                    })            
                }                
                _color = _color.sort((a,b)=>{
                    return b.numkey -a.numkey;
                })
                var _colorInfos = _color.map((a,i)=>{                    
                    return {
                        key:i,
                        value:a.value,
                        numkey:a.numkey,
                        numStr:i== _color.length -1?'0': Math.floor(_color[Number(i)+1].numkey * max)+'-'+ 
                                Math.floor(a.numkey * max) 
                    }
                })                
                return _colorInfos
            }
            return []
        },
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                var that = this;  
                that.$refs.monitorpointCard.show = false  
                this.alarmFilterRule =$help.copyAsOther(n.alarmFilterRule)
                if(this.$store.state.storage_global.currentSite.extendInfo){            
                    let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }                  
                setTimeout(()=>{
                    that.loadAlarmImages()  
                    //that.play('paluse')
                    that.imageList = [];
                    that.currentImageIndex = 0  
                    that.load(that.settime);         
                },600)                  
            }
        },    
        timeRange:{
            handler(){                 
                if(this.loadMapOver && this.currentWindow == 'scanmap'){  
                    this.currentImageIndex = 0                   
                    this.load();                              
                }  
            },
            deep:true
        },
        tab(n){
            if(n==1){
                this.loadPoitTimeChart();
            }
            if(n==2){
                this.loadAlarmImages();
            }
        }
    },
    mounted(){
        var that = this;  
        if(this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsHistory?.scanMap){
            this.permissionUser = this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsHistory.scanMap
        }
        if(this.$store.state.storage_global.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
        } 
        this.alarmFilterRule = $help.copyAsOther(this.$store.state.storage_global.currentSite.alarmFilterRule)
        if(this.currentWindow== 'mix'){
            this.showWarnList= false
        }     
        this.resetWarnTableSize()  
        if(this.$store.state.storage_global.currentSite.siteId){                        
           setTimeout(() => {
            that.load(that.settime) 
           }, 100);
        }                                
    },
    methods:{
        load(t,range){                        
            var that = this;            
            if(this.timeLoadImg){
                clearTimeout(that.timeLoadImg)
            }            
            this.timeLoadImg = setTimeout(() => {  
                if(!range || !this.map){
                    that.loadMap(); 
                }                
                if(that.timmer){
                    clearInterval(that.timmer)
                }
                that.loadImgs(t);                
                that.loadAlarmPoints();                                                                 
                that.loadAlarmImages()                           
            }, 500); 
            
        },
        loadMap(){
            let target = this.$refs.scanMap;          
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;        
            if(!this.map){
                this.map = mapEchart.getModel().getComponent('amap').getAMap();
                //添加右键菜单
                this.addContextMenu();
                //加载控件 
                this.addControl(); 
            }      
            this.map = mapEchart.getModel().getComponent('amap').getAMap();       
            var that = this;            
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置   ,                                 
            }); 
            that.siteMarker.on('click',(e)=>{                                
                    new AMap.InfoWindow({
                        content: `
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${e.lnglat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>                     
                       `,                        
                    }).open(that.map,e.lnglat)
                })
            that.map.add(that.siteMarker)
            that.map.on("movestart",function(a,b,c,d,e){   
                if(that.moveTimeout){
                    clearTimeout(that.moveTimeout)
                }
                that.moveTimeout = setTimeout(() => {
                    if(that.playStatus == 'play' || that.playStatus == 'replay'){
                        that.movePlayStatus = true;                                  
                    }else{
                        that.movePlayStatus = false;
                    }                              
                    that.play('pause');
                }, 200);                
            })
            that.map.on("moveend",function(a,b,c,d,e){                    
                if(that.movePlayStatus){
                    that.play('play');
                }                                                            
            })
             that.map.on("zoomend",function(a,b,c,d,e){                    
                setTimeout(() => {
                    if(that.movePlayStatus){
                        that.play('play');
                    }
                }, 220);                                                                            
            })
            
            this.loadMapOver = true;
            //加载国控点
            this.loadMonitPoint()
        },
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
            loadCustomMarkerToMap(that.map)
        },
        loadImgs(t){
            let _width = this.scanDistance * 100
            if(this.ctx && this.canvasLayer) {                
                this.ctx.clearRect(0,0,_width,_width)
                this.canvasLayer.reFresh();
            }    
            let site = this.$store.state.storage_global.currentSite;
            if(!this.timeRange[0]){
                return;
            }
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //                           
            let type = this.dataType//this.currentScanMapType.value;            
            var that = this;   
            this.imageList = [];                       
            //获取图片
            getHistoryImagesApi({
                id:site.siteId,
                params:{
                    DirTypeId:type,
                    StartTime:start,
                    EndTime:end,
                    PageIndex:1,
                    PageSize:2000
                },success:(d)=>{
                    let imgInfoList = d.Data.map(a=>{
                        return{
                            path:`${uploadMinioURL}${a.DirBucketName}/${a.DirObjectName}`,
                            siteId:a.DirSiteId,
                            period:a.DirPeriod,
                            time:new Date(a.DirTime.replace("T"," ")),
                            type:a.DirTypeId,
                            uploadTime:new Date(a.DirUploadTime.replace("T"," "))
                        } 
                    }).sort((a,b)=>{
                        return a.time - b.time
                    })
                    if(imgInfoList.length <= 0){
                        openNotice({
                            type:"error",
                            text:this.$i18n.t("history.notice.noImg")
                        })
                        return;
                    }    
                    that.imageList = imgInfoList;                      
                    if(that.imageList.length > 0){
                        if(t){
                            let _currTime = 0
                            for(let i in that.imageList){
                                if(i == 0 || Math.abs(that.imageList[i].time - t) < _currTime ){
                                    _currTime = Math.abs(that.imageList[i].time - t) 
                                    that.currentImageIndex = Number(i);                                                                                                                      
                                }
                            }   
                            that.loadCurrentImage();                             
                        }else{                            
                           that.play('play')                                
                        }                        
                    }                    
                }            
            })
        },
        addControl(){
            var that = this;
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                //添加一个缩放控件
                // map.addControl(new BasicControl.Zoom({
                //     theme:"dark",
                //     position: 'lb'
                // }));
        
                //缩放控件，显示Zoom值
                // that.map.addControl(new BasicControl.Zoom({
                //     position: config.zoom.position,
                //     theme:"myself",
                //     showZoomNum: true
                // }));
                
                //比例尺
                
                var scale = new AMap.Scale();
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '160px',left: '50px' },
                    theme:'myself'
                }));

                //3d指南针控件
                var controlBar = new AMap.ControlBar({
                    position:{bottom: '180px',left: '-70px' },
                    theme:'myself'
                });
                that.map.addControl(controlBar) 
                
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:100px;left:50px;';
                                                  
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min = "1" max = "10" value = "${7}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>7</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = function(a){                                        
                    _numdiv.innerHTML = a;
                    _slider.value = a;
                    //重置maphelper
                    that.opacity = a/10;                    
                    if(that.canvasLayer){                       
                        that.canvasLayer.setOpacity(a/10);                       
                    }        
                }
                _rest.addEventListener('click',(e)=>{                                                                          
                    //重置maphelper
                    that.opacity = 0.7;
                    if(that.setOpacity){
                        that.setOpacity(7)
                    }                  
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;                  
                    //重置maphelper
                    that.opacity = value/10;
                    that.setOpacity(value)              
                })                
                el.appendChild(html[0]);  

                //地点定位                
                let styleP = 'cursor:pointer;bottom:50px;left:55px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]); 
            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            位置信息</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]             
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;   
                    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);     

            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.35 16 12.69 15.97 13.03 15.95C13 15.8 13 15.65 13 15.5C13 14.96 13.09 14.44 13.24 13.94C12.83 14 12.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V10.03C18.17 10 18.33 10 18.5 10C19 10 19.5 10.08 20 10.22V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C13.06 21 14.07 20.89 15 20.71C14.62 20.14 14.25 19.5 13.93 18.85C13.34 18.94 12.71 19 12 19M12 5C15.87 5 18 6.5 18 7S15.87 9 12 9 6 7.5 6 7 8.13 5 12 5M18.5 12C16.6 12 15 13.6 15 15.5C15 18.1 18.5 22 18.5 22S22 18.1 22 15.5C22 13.6 20.4 12 18.5 12M18.5 16.8C17.8 16.8 17.3 16.2 17.3 15.6C17.3 14.9 17.9 14.4 18.5 14.4S19.7 15 19.7 15.6C19.8 16.2 19.2 16.8 18.5 16.8Z" fill="black"/>
            </svg>
            位置数据</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                let site = that.$store.state.storage_global.currentSite;
                let siteCenter = [site.lng,site.lat]
                let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                if(dis < that.scanDistance * 1000){
                    let angle = $help.bearing(siteCenter,lnglat)
                    angle = angle >= 0 ? angle :angle +360                    
                    angle = angle - that.$store.state.storage_global.currentSite.yaw < 0 ? angle - that.$store.state.storage_global.currentSite.yaw + 360 : angle - that.$store.state.storage_global.currentSite.yaw;
                    if(!that.currentPeriod){
                        openNotice({
                            type:'error',
                            text:"未选择周期"
                        })
                    }
                    getPlsPositionDataApi({
                        id:site.siteId,
                        period:that.currentPeriod,
                        params:{
                            angle:angle.toFixed(2),
                            distance:dis,
                            scanDistance:that.scanDistance * 1000
                        },
                        success:(d)=>{
                            let msg = `<div>点位值：${d}</div>`                     
                                new AMap.InfoWindow({
                                    content: msg,                        
                                }).open(that.map,lnglat);
                        }
                    })
                }                
                else{
                    openNotice({
                        type:'error',
                        text:"所选区域超出范围"
                    })
                }
            }, 1);    
            if(that.permissionUser?.pointTrendShow){
                contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
                align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 19V20H22V22H2V2H4V17C7 17 10 15 12.1 11.4C15.1 6.4 18.4 4 22 4V6C19.2 6 16.5 8.1 13.9 12.5C11.3 16.6 7.7 19 4 19Z" fill="black"/>
                </svg>
                位置趋势</div>`, function () {
                    let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                    let site = that.$store.state.storage_global.currentSite;
                    let siteCenter = [site.lng,site.lat]
                    let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                    if(dis < that.scanDistance * 1000){
                        let angle = $help.bearing(siteCenter,lnglat)
                        angle = angle >= 0 ? angle :angle +360                    
                        angle = angle - that.$store.state.storage_global.currentSite.yaw < 0 ? angle - that.$store.state.storage_global.currentSite.yaw + 360 : angle - that.$store.state.storage_global.currentSite.yaw;                   
                        that.$refs.monitorpointCard.angle = (angle).toFixed(2)
                        that.$refs.monitorpointCard.distance = dis.toFixed(2)
                        that.$refs.monitorpointCard.scanDistance = that.scanDistance * 1000
                        that.$refs.monitorpointCard.siteId = that.$store.state.storage_global.currentSite.siteId
                        that.$refs.monitorpointCard.show = true     
                        setTimeout(()=>{
                            that.$refs.monitorpointCard.load();
                        },200)          
                    }                
                    else{
                        openNotice({
                            type:'error',
                            text:"所选区域超出范围"
                        })
                    }
                }, 2);   
            }            

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        }, 
        //报警列表
        setCurrentPoint(event,data){ 
            //设置当前活动
            let childrenList = event?.target?.parentElement?.parentElement?.children;
            for(var i in childrenList){
                if(i == "length"){
                    continue;
                }
                if(i == data.index){
                    childrenList[i].className = 'table-row-active'
                }else{                    
                    childrenList[i].className = ''
                }

            }
            var that = this;  
            let item = data.item;
             //设置中心
            let pix = this.map.lngLatToContainer([item.lng,item.lat])
            let conSize = this.map.getSize();
            if(pix.x <= 0 || pix.y <= 0 || pix.x >= conSize.width || pix.y >= conSize.height){
                this.map.setCenter([item.lng,item.lat])
            } 
            //设置标记
            if(!this.marker){
                this.marker = new AMap.Marker({
                    map:this.map,
                    content: new_marker,  // 自定义点标记覆盖物内容
                    position:[item.lng,item.lat], // 基点位置
                    anchor: 'bottom-center', 
                    offset: new AMap.Pixel(0, 15),// 相对于基点的偏移位置                                    
                    zIndex:1000
                });                
            }
            var msg = `<div style="font-size:16px;font-weight:600;">报警点信息</div>
                <div>位置：${item.position}</div>
                <div>经纬度：${item.lng},${item.lat}</div>
                <div>次数：${$help.sum(item.count)}</div>`
            this.marker.setPosition([item.lng,item.lat])
                   
            this.marker.on('click',(ev)=>{                
                if(!that.winInfo){                                         
                    that.winInfo = new AMap.InfoWindow({  
                        position:new AMap.LngLat(item.lng,item.lat),                                      
                        content: msg,                        
                    });            
                    that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat))
                }else{
                    that.winInfo.setContent(msg)
                    that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat));
                }
            })

            if(this.attachMap){
                //设置当前图
                let _currTime = item.time[0].split("至")[0];                 
                if(this.timmer!= null){
                    clearInterval(this.timmer)
                }  
                let currTime = new Date(_currTime);
                let currSpan = 0;

                for(var i in this.imageList){
                    let _currImgInfo = this.imageList[i];
                    let time = _currImgInfo.time;
                    let _span = Math.abs(currTime - time);
                    if(i == 0 || _span < currSpan){
                        currSpan = _span;
                        this.currentImageIndex = Number(i);
                    }
                }        
                this.playStatus = 'pause'             
                this.loadCurrentImage();                        
            }            

            if(that.winInfo && that.winInfo.getIsOpen()){
                that.winInfo.setContent(msg)
                that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat));
            }     

        },
        setCurrentImgPoint(event,data){
            //设置当前活动
            let childrenList = event.target.parentElement.parentElement.children;
            for(var i in childrenList){
                if(i == "length"){
                    continue;
                }
                if(i == data.index){
                    childrenList[i].className = 'table-row-active'
                }else{                    
                    childrenList[i].className = ''
                }

            }
            var that = this;  
            let item = data.item;
             //设置中心
            let pix = this.map.lngLatToContainer([item.lng,item.lat])
            let conSize = this.map.getSize();
            if(pix.x <= 0 || pix.y <= 0 || pix.x >= conSize.width || pix.y >= conSize.height){
                this.map.setCenter([item.lng,item.lat])
            } 
            //设置标记
            if(!this.marker){
                this.marker = new AMap.Marker({
                    map:this.map,
                    content: new_marker,  // 自定义点标记覆盖物内容
                    position:[item.lng,item.lat], // 基点位置
                    anchor: 'bottom-center', 
                    offset: new AMap.Pixel(0, 15),// 相对于基点的偏移位置                                    
                    zIndex:1000
                });                
            }
            var msg = `<div style="font-size:16px;font-weight:600;">报警点信息</div>
                <div>位置：${item.position}</div>
                <div>经纬度：${item.lng},${item.lat}</div>`
            this.marker.setPosition([item.lng,item.lat])
                   
            this.marker.on('click',(ev)=>{                
                if(!that.winInfo){                                         
                    that.winInfo = new AMap.InfoWindow({  
                        position:new AMap.LngLat(item.lng,item.lat),                                      
                        content: msg,                        
                    });            
                    that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat))
                }else{
                    that.winInfo.setContent(msg)
                    that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat));
                }
            })
            if(that.winInfo && that.winInfo.getIsOpen()){
                that.winInfo.setContent(msg)
                that.winInfo.open(that.map, new AMap.LngLat(item.lng,item.lat));
            }     
        },
        loadAlarmPoints(){
            var that = this;
            this.alarmList = [];
            if(!this.timeRange[0]){
                return;
            }
            this.timeRange = this.timeRange == null ? [] :this.timeRange;
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //  
            if(this.marker && this.map){
                this.map.remove(this.marker)
                this.marker = null;
            }
            getAlarmPointComputedsApi({
                filter:that.checkedId.length == 0 ? null :that.checkedId.join(','),  
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    StartTime:start,
                    EndTime:end,
                    DapTypeId:6,                    
                }, 
                success:(d)=>{
                    //报警点热图                               
                    let data = d.Data.sort((a,b)=>{
                        return new Date(b.DapTime.replace("T"," ")) - new Date(a.DapTime.replace("T"," "))
                    }).filter(a=>{                                           
                        let rel = $help.alarmDistanceFilter(that.$store.state.storage_global.currentSite.lng,that.$store.state.storage_global.currentSite.lat,a.DapLng,a.DapLat,that.$store.state.storage_global.currentSite.scanDistance)                                                
                        return rel
                    }) ;
                    that.alarmChartList = data;
                    if(that.tab == 1){
                        that.loadPoitTimeChart()
                    }
                     let group = []
                    for(var dt of data){
                        let item = group.find(a=>{
                            return a.DapSiteId == dt.DapSiteId && a.DapLng == dt.DapLng && a.DapLat == dt.DapLat
                        })
                        if(item){
                            item.DapLevel += dt.DapLevel;
                            item.DapCount+= dt.DapCount;
                        }   
                        else{
                            group.push(JSON.parse(JSON.stringify(dt)));
                        }
                    }     
                    
                    that.mapHotDatas = group.map(a=>{
                        return {
                            lnglat:[a.DapLng,a.DapLat],
                            count:a.DapCount
                        }
                    })    
                    //报警列表
                    let computedData = d.ComputedData.filter(a=>{                                           
                        let rel = $help.alarmDistanceFilter(that.$store.state.storage_global.currentSite.lng,that.$store.state.storage_global.currentSite.lat,a.lng,a.lat,that.$store.state.storage_global.currentSite.scanDistance)                                                
                        return rel
                    })
                    that.mapHotComputedDatas = computedData.map(a=>{
                        return{
                            lnglat:[a.lng,a.lat],
                            count:$help.sum(a.intensity)
                        }
                    }) 
                    //如果热力图打开则重新展示
                    if(that.hotLayer){
                        that.setHotLayer(that.hotLayer)
                    }             
                    
                    this.taskId = $help.guid()
                    for(let j = 0;j< computedData.length;){
                        let _computedData = computedData.slice(j,j+=20);                        
                        let lnglatstr= _computedData.map(a=>{
                            return `${a.lng},${a.lat}`
                        }).join('|')                         
                        getGisApi({
                            params:{
                                location:lnglatstr,
                                taskId: this.taskId 
                            },
                            success:(f,taskId)=>{
                                if(taskId == that.taskId){
                                    _computedData.forEach(a=>{                                    
                                        var gisInfo = f.find(b=>{
                                            var lnglat = b.GrLngLat.split(',');                                        
                                            return Math.abs(Number(lnglat[0])*10000 - Math.round(a.lng*10000)) <= 1 && Math.abs(Number(lnglat[1]) * 10000 - Math.round(a.lat*10000))<=1
                                        }) 
                                        if(gisInfo){                                   
                                            var position = JSON.parse(gisInfo.GrLoc)  
                                            if(that.alarmList.findIndex(b=>b.lng == a.lng.toFixed(4) && b.lat ==a.lat.toFixed(4) && b.time ==a.timequantum.map(b=>{
                                                    return `${$help.periodToTimeStr(b[0])} 至 ${$help.periodToTimeStr(b[1])}`;
                                                }) ) < 0){
                                                that.alarmList.push({                                            
                                                    lng:a.lng.toFixed(4),
                                                    lat:a.lat.toFixed(4),
                                                    position:position?.formatted_address
                                                    .replace(position?.addressComponent.province,'')
                                                    .replace(position?.addressComponent.city,'')
                                                    .replace(position?.addressComponent.township,'')
                                                    .replace(position?.addressComponent.district,''),
                                                    province:position?.addressComponent.province,
                                                    city:position?.addressComponent.city == "" ? position?.addressComponent.province:position?.addressComponent.city,
                                                    street:position?.addressComponent.township,
                                                    district:position?.addressComponent.district,
                                                    count:a.intensity,
                                                    time:a.timequantum.map(b=>{
                                                        return `${$help.periodToTimeStr(b[0])} 至 ${$help.periodToTimeStr(b[1])}`;
                                                    }),
                                                    total:$help.sum(a.intensity),
                                                })
                                            }                                                                                       
                                        }else{
                                            consolelog(a,f)
                                        }
                                    })
                                }                                
                            }
                        })
                    }
                }
            })
        },
        updateAlarmInfo(){
            let that = this;
            if(this.alarmChartList.length > 0){                
                if(!this.alarmChartList[0].Formatted_Address){
                    this.loadingAlarmGis = true;
                    getAlarmPointGisListApi({
                        id:this.$store.state.storage_global.currentSite.siteId,
                        data:this.alarmChartList,
                        success:(d)=>{
                            let gisInfo = d;
                            that.alarmChartList.forEach(a=>{
                                a.DapTime = a.DapTime.replace("T",' ')
                                let _gis = gisInfo.find(b=>{
                                    return b.GrLngLat == `${a.DapLng},${a.DapLat}`
                                })?.GrLoc
                                let gis = _gis? JSON.parse(_gis) :''
                                a.Formatted_Address = gis
                            })
                            that.alarmChartList = that.alarmChartList.filter(a=>a)
                            
                            that.loadingAlarmGis = false;
                            that.loadPoitTimeChart(true)
                        }
                    })                    
                }else{                    
                    this.loadingAlarmGis = false;
                    this.loadPoitTimeChart(true)
                }
            }            
            //this.loadingAlarmGis = false;
        },
        async loadPoitTimeChart(pass){           
            if(this.alarmChartList.length == 0){
                let targetTownship = this.$refs.alarmChartTownship;  
                let targetDistrict = this.$refs.alarmChartDistrict;  
                let targetPoint = this.$refs.alarmChartPoint;  
                if(!targetTownship || !targetDistrict || !targetPoint){                   
                    return
                }
                Plotly.purge(targetTownship);
                Plotly.purge(targetDistrict);
                Plotly.purge(targetPoint);
                return;
            }
            let that = this
            if(this.pointChartTimer){
                clearTimeout(this.pointChartTimer)
            }
            this.pointChartTimer = setTimeout(() => {
                that.loadChart(pass)
            }, 600);
        },
        loadChart(pass){
            let that = this;
            let targetTownship = this.$refs.alarmChartTownship;  
            let targetDistrict = this.$refs.alarmChartDistrict;  
            let targetPoint = this.$refs.alarmChartPoint;  
            if(!targetTownship || !targetDistrict || !targetPoint){
                return
            }
           
            if(!pass){
                this.updateAlarmInfo()
            }else{
                let xData = []; 
                let dataByTime = []
                let dataPropTownship =$help.unique(this.alarmChartList.map(a=>a.Formatted_Address?.addressComponent?.township))
                let dataPropDistrict =$help.unique(this.alarmChartList.map(a=>a.Formatted_Address?.addressComponent?.district))
                let dataPropPointList = JSON.parse(JSON.stringify(this.alarmList));
                let dataPropPoint = []
                dataPropPointList.forEach(a=>{
                    if(a?.position){
                        let position = a?.position?.replace(a.province,'')?.replace(a.city,'')?.replace(a.district,'')?.replace(a.street,'')                                                       
                        if(position == ''){
                            position = a.street
                        }
                        a.position = position;
                        if(dataPropPoint.findIndex(b=>b.position == position) < 0){
                            dataPropPoint.push(a)
                        }                    
                    }                    
                });
                for(let i = this.timeRange[0].getTime();i<this.timeRange[1].getTime();i+= 1000* 60 *60){
                    let xtime = new Date(new Date(i).pattern("yyyy-MM-dd HH:00:00"));
                    let ntime = new Date(new Date(i+1000*60*60).pattern("yyyy-MM-dd HH:00:00"));                   
                    let timeRangeData = this.alarmChartList.filter(a=>new Date(a.DapTime).getTime() >=xtime.getTime() &&  new Date(a.DapTime).getTime() <=ntime.getTime());                                
                    if(this.isHour){
                        let hTime =  new Date(i).pattern("HH:00:00");
                        let _itemTime = xData.find(a=>a == hTime)
                        if(!_itemTime){
                            xData.push(hTime)    
                        }                        
                        let _item = dataByTime.find(a=>a.time == hTime)
                        if(!_item){
                            dataByTime.push({
                                time:hTime,
                                dataListTownship:dataPropTownship.map(a=>{
                                    return {
                                        township:a,
                                        count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.township == a)
                                        .map(a=>a.DapCount))
                                    }
                                }),
                                dataListDistrict:dataPropDistrict.map(a=>{
                                    return {
                                        district:a,
                                        count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.district == a)
                                        .map(a=>a.DapCount))
                                    }
                                }),
                                dataListPoint:dataPropPoint.map(a=>{                                    
                                    return{
                                        point:a.position,
                                        count:$help.sum(timeRangeData.filter(b=>{
                                            return AMap.GeometryUtil.distance(new AMap.LngLat(a.lng, a.lat),new AMap.LngLat(b.DapLng, b.DapLat)) <= 500
                                        }).map(a=>a.DapCount))
                                    }
                                })
                            })
                        }else{
                          dataPropTownship.forEach(a=>{                                
                                let _it = _item.dataListTownship.find(b=>b.township == a);
                                if(_it){
                                    _it.count += $help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.township == a)
                                        .map(a=>a.DapCount))
                                }else{
                                        _item.dataListTownship.push({                                        
                                            township:a,
                                            count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.township == a)
                                            .map(a=>a.DapCount))
                                        })
                                    }
                                })
                            dataPropDistrict.forEach(a=>{
                                let _it = _item.dataListDistrict.find(b=>b.district == a);
                                if(_it){
                                    _it.count += $help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.district == a)
                                    .map(a=>a.DapCount))
                                }else{
                                    _item.dataListDistrict.push({                                        
                                        district:a,
                                        count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.district == a)
                                        .map(a=>a.DapCount))
                                    })
                                }
                            })
                            dataPropPoint.forEach(a=>{
                                let _it = _item.dataListPoint.find(b=>b.point == a.position);
                                if(_it){
                                    _it.count += $help.sum(timeRangeData.filter(b=>{
                                        return AMap.GeometryUtil.distance(new AMap.LngLat(a.lng, a.lat),new AMap.LngLat(b.DapLng, b.DapLat)) <= 500
                                    }).map(a=>a.DapCount))
                                }else{
                                    _item.dataListPoint.push({                                        
                                        point:a.position,
                                        count:$help.sum(timeRangeData.filter(b=>{
                                            return AMap.GeometryUtil.distance(new AMap.LngLat(a.lng, a.lat),new AMap.LngLat(b.DapLng, b.DapLat)) <= 500
                                        }).map(a=>a.DapCount))
                                    })
                                }                               
                            })
                        }                          
                    }else{
                        xData.push(xtime) 
                        dataByTime.push({
                            time:xtime,
                            dataListTownship:dataPropTownship.map(a=>{
                                return {
                                    township:a,
                                    count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.township == a)
                                    .map(a=>a.DapCount))
                                }
                            }),
                            dataListDistrict:dataPropDistrict.map(a=>{
                                return {
                                    district:a,
                                    count:$help.sum(timeRangeData.filter(b=>b.Formatted_Address?.addressComponent?.district == a)
                                    .map(a=>a.DapCount))
                                }
                            }),
                            dataListPoint:dataPropPoint.map(a=>{
                                return{
                                    point:a.position,
                                    count:$help.sum(timeRangeData.filter(b=>{
                                        return AMap.GeometryUtil.distance(new AMap.LngLat(a.lng, a.lat),new AMap.LngLat(b.DapLng, b.DapLat)) <= 500
                                    }).map(a=>a.DapCount))
                                }
                            })
                        })  
                    }                                     
                }                                            
                let dataPoint = dataPropPoint.map(a=>{
                    return {
                        type:that.chartType == 0 ? 'scatter':'bar',
                        x: xData,
                        y: dataByTime.map(c=>c.dataListPoint.find(b=>b.point == a.position)?.count),
                        name:a.position.length != 0 ? a?.position?.replace(a.province,'')?.replace(a.city,'')?.replace(a.district,'')?.replace(a.township,'') : '',
                        showlegend: true, 
                        //mode: 'lines', 
                    }
                }) 
                let dataTownship = dataPropTownship.filter(a=>a!='').map(a=>{
                    return {
                        type:that.chartType == 0 ? 'scatter':'bar',
                        x: xData,
                        y: dataByTime.map(c=>c.dataListTownship.find(b=>b.township == a)?.count),
                        name:a,
                        showlegend: true,  
                        //mode: 'lines', 
                    }
                })   
                let dataDistrict = dataPropDistrict.filter(a=>a!='').map(a=>{
                    return {
                        type:that.chartType == 0 ? 'scatter':'bar',
                        x: xData,
                        y: dataByTime.map(c=>c.dataListDistrict.find(b=>b.district == a)?.count),
                        name:a,
                        showlegend: true,  
                        //mode: 'lines', 
                    }
                })                          
                                
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {  
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,
                    legend: {
                        itemsizing:'constant',
                        orientation:"h",   
                        y:-0.2,  
                        //entrywidth:120, 
                        font:{
                            color: fontColor,
                            size:12
                        },
                        tracegroupgap:5,
                        valign:'top',
                        bgcolor:'rgba(0,0,0,0)'
                    },   
                    colorway:['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'
                    , '#e377c2', '#7f7f7f', '#bcbd22', '#17becf','#1fa9b4','#b4851f','#2eeab9',
                    '#4d2eea','#2eeae1','#2eea43','#eade2e','#ffd566','#ddaa00','#b000dd',
                    '#6c0c0c','#6c660c','#2a6c0c','#0c696c','#2c0c6c','#00b50a','#87b500',
                    '#b58800','#b50091','#b5002e','#00a3b5','#00b583'],  
                    uirevision:'true',           
                    xaxis: {  
                        color:fontColor,
                        nticks:4,
                        tickformat:"%H点 <br /> %m月%d日 ",
                    },
                    yaxis: {
                        color:fontColor,
                        title:"报警强度（次）"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{
                        t:10,
                        b:0,
                        l:32,
                        r:22                     
                    }
                };       
                let _list = [targetPoint,targetTownship,targetDistrict];                
                for(let _t in _list){
                    let target = _list[_t]
                    let data = _t == 0 ? dataPoint: _t == 1 ? dataTownship:dataDistrict;
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});    
                }                
            }            
        },
        loadAlarmImages(){
            var that = this;
            this.alarmImgList = [];
            this.timeRange = this.timeRange == null ? [] :this.timeRange;
            if(!this.timeRange[0]){
                return
            }
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") //          
            getSiteAlarmImageApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    PageIndex:this.page,
                    PageSize:this.rows,
                    StartTime:start,
                    EndTime:end,                 
                }, 
                success:(d)=>{                         
                    that.total = d.Total;               
                    that.taskIdImage = $help.guid()
                    let lnglatstr= d.Data.map(a=>{
                        return `${a.DaiLng},${a.DaiLat}`
                    }).join('|')
                    
                    if(d.Data.length > 0){
                        getGisApi({
                            params:{
                                location:lnglatstr,
                                taskId: that.taskIdImage 
                            },
                            success:(f,taskIdImage)=>{
                                if(taskIdImage == that.taskIdImage){
                                    d.Data.forEach(a=>{                                    
                                        var gisInfo =  f.find(b=>{
                                            var lnglat = b.GrLngLat.split(',');                                          
                                            return Math.abs(Number(lnglat[0])*10000 - Math.round(a.DaiLng*10000)) <= 1 && Math.abs(Number(lnglat[1]) * 10000 - Math.round(a.DaiLat*10000))<=1
                                        }) 
                                        if(gisInfo){                                        
                                            var position = JSON.parse(gisInfo.GrLoc)                                       
                                            that.alarmImgList.push({                                            
                                                lng:a.DaiLng.toFixed(4),
                                                lat:a.DaiLat.toFixed(4),
                                                time:`${new Date(a.DaiStartTime.replace("T"," ")).pattern("MM月dd日 HH:mm")}至${new Date(a.DaiEndTime.replace("T"," ")).pattern("MM月dd日 HH:mm")}`,
                                                position:position?.formatted_address
                                                .replace(position?.addressComponent.province,'')
                                                .replace(position?.addressComponent.city,'')
                                                .replace(position?.addressComponent.township,'')
                                                .replace(position?.addressComponent.district,''),
                                                province:position?.addressComponent.province,
                                                city:position?.addressComponent.city == "" ? position?.addressComponent.province:position?.addressComponent.city,
                                                street:position?.addressComponent.township,
                                                district:position?.addressComponent.district,     
                                                total:a.DaiCount,
                                                image:a.DaiImagePath.map(a=>uploadMinioURL+a)
                                            })     
                                        }else{
                                            consolelog(a,f)
                                        }
                                    })
                                }                                
                            }
                        })  
                    }                    
                }                
            })
        },        
        sortNew(data,sortBy,sortDesc,local,customSorters){            
            if(data.length == 0){
            return data;
            }
            if(sortBy.length == 0){
            return data;
            }        
            else{
            if(sortBy.length == 1){
                this.groupSort = false;
                if(typeof(data[0][sortBy[0]]) == "string"){                          
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[0]){              
                    return b[sortBy[0]].localeCompare(a[sortBy[0]],'zh-CN')                         
                    }else{              
                    return a[sortBy[0]].localeCompare(b[sortBy[0]],'zh-CN')  
                    }            
                })
                return _data;
                }else if(typeof(data[0][sortBy[0]]) == "object"){
                //数组
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[0]){              
                    if(typeof(a[sortBy[0]][0]) == "number"){
                        return $help.sum(b[sortBy[0]]) - $help.sum(a[sortBy[0]])
                    }else if(sortBy[0] == "date"){                    
                        let starta = a[sortBy[0]][0].split("至")[1]
                        let startb = b[sortBy[0]][0].split("至")[1]
                        return new Date(startb) - new Date(starta)
                    }
                    return data;                       
                    }else{  
                    if(typeof(a[sortBy[0]][0]) == "number"){
                        return $help.sum(a[sortBy[0]]) - $help.sum(b[sortBy[0]])
                    }            
                    else if(sortBy[0] == "date"){                    
                        let starta = a[sortBy[0]][0].split("至")[1]
                        let startb = b[sortBy[0]][0].split("至")[1]
                        return new Date(starta) - new Date(startb)
                    }
                    return data;  
                    }            
                })
                return _data;
                }
                else{
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[0]){              
                    return b[sortBy[0]] - a[sortBy[0]]                         
                    }else{              
                    return a[sortBy[0]] - b[sortBy[0]]
                    }            
                })
                return _data;
                }            
            }
            //group
            else{   
                this.groupSort = true;
            if(typeof(data[0][sortBy[sortBy.length-1]]) == "string"){                          
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[sortDesc.length -1]){              
                    return b[sortBy[sortBy.length-1]].localeCompare(a[sortBy[sortBy.length-1]],'zh-CN')                         
                    }else{              
                    return a[sortBy[sortBy.length-1]].localeCompare(b[sortBy[sortBy.length-1]],'zh-CN')  
                    }            
                })
                return _data;
                }else if(typeof(data[0][sortBy[sortBy.length-1]]) == "object"){
                //数组
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[sortDesc.length -1]){              
                    if(typeof(a[sortBy[sortBy.length-1]][0]) == "number"){
                        return $help.sum(b[sortBy[sortBy.length-1]]) - $help.sum(a[sortBy[sortBy.length-1]])
                    }else if(sortBy[sortBy.length-1] == "date"){                    
                        let starta = a[sortBy[sortBy.length-1]][0].split("至")[1]
                        let startb = b[sortBy[sortBy.length-1]][0].split("至")[1]
                        return new Date(startb) - new Date(starta)
                    }
                    return data;                       
                    }else{  
                    if(typeof(a[sortBy[sortBy.length-1]][0]) == "number"){
                        return $help.sum(a[sortBy[sortBy.length-1]]) - $help.sum(b[sortBy[sortBy.length-1]])
                    }            
                    else if(sortBy[sortBy.length-1] == "date"){                    
                        let starta = a[sortBy[sortBy.length-1]][0].split("至")[1]
                        let startb = b[sortBy[sortBy.length-1]][0].split("至")[1]
                        return new Date(starta) - new Date(startb)
                    }
                    return data;  
                    }            
                })
                return _data;
                }
                else{
                let _data = data.sort((a,b)=>{
                    if(!sortDesc[sortDesc.length -1]){              
                    return b[sortBy[sortBy.length-1]] - a[sortBy[sortBy.length-1]]                         
                    }else{              
                    return a[sortBy[sortBy.length-1]] - b[sortBy[sortBy.length-1]]
                    }            
                })
                return _data;
                }               
            }                    
            }
            
        },
        groupNew(data,groupBy,desc){      
            if(data.length == 0){
            return data;
            }
            let _rowGroup = $help.unique(data.map(a=>{          
            return a[groupBy[0]];
            }))
            let newArr =  [];        
            for(var i of _rowGroup){
            let items = data.filter(a=>{
                return a[groupBy[0]] == i
            })          
            if(!this.groupSort){
                items = items.sort((e,f)=>{
                return $help.sum(f.count.toString().split(",").map(d=>{
                    return Number(d)
                })) - $help.sum(e.count.toString().split(",").map(d=>{
                    return Number(d)
                }));
                })
            }          
            newArr.push({name:i,items:items})
            }
            if(!this.groupSort){
            newArr.sort((a,b)=>{
            let count1 = 0;
            let count2 = 0;
            a.items.forEach(c=>{
                count1 += $help.sum(c.count.toString().split(",").map(d=>{
                return Number(d)
                }));
            })
            b.items.forEach(c=>{
                count2 += $help.sum(c.count.toString().split(",").map(d=>{
                return Number(d)
                }))
            })
            return count2 -count1
            })    
            }        
            newArr = newArr.map((a,i)=>{
            a.name =  `${parseInt(i)+1}. ${a.name}`
            return a;
            })
            return newArr;        
        },
        getGroupHeader(data){        
            //获取分组头    
            if(data.type == 'warncount'){
                let warnCount = 0
                data.data.forEach(a => {
                    warnCount += $help.sum(a.count.toString().split(",").map(d=>{
                    return Number(d)
                }));
                });                
                return warnCount;
            }
            else{            
                let norepeat = $help.unique(data.data.map(a=>{
                    return a.position;
                }))                
                return norepeat.length
            }

        },   
        //重置大小
        resize(touch){      
            let that = this;        
            if(touch){
                touch.preventDefault()
                touch.stopPropagation()               
                // let moveEndX = e.changedTouches[0].pageX,moveEndY = e.changedTouches[0].pageY;
                // let ox = moveEndX - startX,oy = moveEndY - startY;
                let resetW = document.body.clientWidth - touch.changedTouches[0].pageX //this.width //(ox/10);
                let resetH = document.body.clientHeight - touch.changedTouches[0].pageY//this.height -1//(oy/10);        
                this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                this.height = resetH > 0.86 * document.body.clientHeight ? 0.86 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                localStorage.setItem("warnTableMHeight",this.height)
                localStorage.setItem("warnTableMWidth",this.width)   

            }else{
                //监听鼠标移动
                let fun = (e)=>{ 
                    e.preventDefault()
                    e.stopPropagation()               
                    //重设表格大小
                    let resetW = this.width - e.movementX ;
                    let resetH = this.height - e.movementY
                    this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                    this.height = resetH > 0.86 * document.body.clientHeight ? 0.86 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                    localStorage.setItem("warnTableMHeight",this.height)
                    localStorage.setItem("warnTableMWidth",this.width)          
                }
                document.addEventListener('mousemove',fun )
                let move= (e)=>{
                    e.preventDefault()
                    e.stopPropagation()  
                    that.loadPoitTimeChart()
                    document.removeEventListener('mousemove',fun)
                    document.removeEventListener('mouseup',move)
                }
                document.addEventListener('mouseup', move);
            }                                     
        },
        resetWarnTableSize(){
            if(localStorage.getItem("warnTableHeight")){
            this.height = localStorage.getItem("warnTableHeight")
            }
            else{
            this.height = document.body.clientHeight * 0.38
            }
            if(localStorage.getItem("warnTableWidth")){
            this.width = localStorage.getItem("warnTableWidth")
            } 
            else{
            this.width = document.body.clientWidth* 0.46;
            }      
        },       
        //热图
        setHotLayer(show){               
            let color = this.color;            
            if(this.mapHotLayer){
                this.map.remove(this.mapHotLayer)
            }            
            this.mapHotLayer = null;          
            //1.14版本
            this.mapHotLayer = this.map.getLayers().find(a=>{
                return a.CLASS_NAME == 'Loca.HeatmapLayer';
            });        
            if(!this.mapHotLayer){            
                this.mapHotLayer = new Loca.HeatmapLayer({
                    map:this.map
                })                
            }        
            if(show){
                if(this.hotLayerComputed && this.mapHotComputedDatas && this.mapHotComputedDatas.length > 0){
                    this.mapHotLayer.setData(this.mapHotComputedDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                }
                if(this.hotLayer && this.mapHotDatas && this.mapHotDatas.length > 0){                           
                    this.mapHotLayer.setData(this.mapHotDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                }        
              
                let level = this.map.getZoom();
                let r = level <= 12 ? 16 : 16 + (level -12) * 5;            
                this.mapHotLayer.setOptions({
                    style: {
                        //altitude:500000,
                        radius: r,
                        color: color
                    }
                });
                this.mapHotLayer.render();
                this.mapHotLayer.show();
                //减淡扫描图                
                if(this.setOpacity){
                    if(this.currentImageIndex == 0){
                        this.loadCurrentImage();         
                    }
                    this.play('pause')                 
                }
            }
            else{
                this.mapHotLayer.hide();
                if(this.canvasLayer){
                    this.play('play')
                }
            }
        },
      
        play(status){                                    
            this.playStatus = status;            
            var that = this;                       
            if(this.imageList.length > 0){   
                if(this.playStatus == 'play'){
                    //播放                               
                    if(this.timmer!= null){                        
                        //this.ctx.clearRect(0,0,600,600)
                        this.canvasLayer?.reFresh();
                        clearInterval(this.timmer)                        
                    }     

                    this.timmer = setInterval(() => {                                                     
                        if(that.currentWindow == 'scanmap' || that.currentWindow == 'mix'){                                                    
                            if(that.$route.path !="/monitoring/pls/history"){
                                clearInterval(that.timmer)
                            }
                            if(that.currentImageIndex > that.imageList.length -1) {
                                that.currentImageIndex = that.imageList.length -1;                            
                                clearInterval(that.timmer)
                                this.playStatus = 'pause'   
                                openNotice({
                                    type:"info",
                                    text:that.$i18n.t("history.notice.loadend")
                                })                                                 
                            }
                            that.loadCurrentImage();
                            that.currentImageIndex = that.currentImageIndex > that.imageList.length -1 ? 0: that.currentImageIndex;                        
                            that.currentImageIndex +=1;
                        }
                    }, 800);
                
                }else if(this.playStatus == 'replay'){
                    //重播
                    that.currentImageIndex = 0;
                    if(that.timmer!= null){
                        clearInterval(that.timmer)
                    }                    
                    that.timmer = setInterval(() => {                    
                        that.loadCurrentImage();
                        that.currentImageIndex +=1;
                        if(that.currentImageIndex > that.imageList.length -1) {
                            that.currentImageIndex = 0;
                            clearInterval(that.timmer)
                            that.playStatus = 'pause'
                        }
                    }, 800);
                    
                }else if(this.playStatus == 'pause'){
                    //暂停
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  

                }else if(this.playStatus == 'next'){
                    //下一张
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  
                    if(that.currentImageIndex+1 > that.imageList.length -1) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgEnd")
                        })
                        return;
                    }
                    that.currentImageIndex +=1;
                    that.loadCurrentImage();
                }else if(this.playStatus == 'pre'){
                    //前一张
                    if(this.timmer!= null){
                        clearInterval(this.timmer)
                    }  
                    if(that.currentImageIndex-1 < 0) {                    
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("history.notice.imgStart")
                        })
                        return;
                    }
                    that.currentImageIndex -=1;
                    that.loadCurrentImage();
                }

            }else{
                // openNotice({
                //     type:"info",
                //     text:that.$i18n.t("history.notice.noImg")
                // })
                return;
            }       
        
        },
        loadCurrentImage(){                        
            var that = this;
            if(that.imageList.length == 0 || that.currentImageIndex >= that.imageList.length){
                return 
            }            
            let imgInfo =that.imageList[that.currentImageIndex];
            let imgPath = imgInfo.path;                          
            let time = imgInfo.time.pattern('yyyy-MM-dd HH:mm:ss')
            let imgParams = imgPath.match(/(?<=\@).*?(?=\@)/g);
            let arr=[]  
            let _width = this.scanDistance * 100;   
            let w = _width,h = _width;  //图片长宽为600       
            if(!imgParams){
                arr =$help.getLayerMapPosition(90,this.scanDistance,this.center)  
            }else{
                let splitValue = imgParams[0].split("_")
                let _widthImage = Number(splitValue[0]);
                let vAngle = Number(splitValue[1]);   
                let baseW = Number(splitValue[2])
                let _scanDistance = $help.isNull(splitValue[3]) ? this.scanDistance:Number(splitValue[3]);
                if(window.backdoors){
                    _scanDistance = window.scanDistance ? window.scanDistance:_scanDistance;
                    consolelog(_scanDistance)
                }
                w = h = _width= _scanDistance * 100                            
                arr =$help.getLayerMapPosition(vAngle,_scanDistance,this.center,_widthImage/baseW)  
                console.log(vAngle);
                console.log(_scanDistance);
                console.log(this.center);
                console.log(_widthImage/baseW);
                console.log(arr);
                let _info = imgPath.split(this.$store.state.storage_global.currentSite.siteId);
                this.currentPeriod = _info[1]?.replace(imgParams,'')?.replace('_2@@.png','')?.replace('_','')
            }                                   
            if(!this.canvas){
                let canvas = document.createElement('canvas')
                //canvas = this.$refs.polar;
                let ctx =canvas.getContext("2d"); 
                canvas.width = w;
                canvas.height = h;               
                this.canvas = canvas;
                this.ctx = ctx;
            }      
           
            //更新图
            var bounds = new AMap.Bounds(arr[0],arr[1]) 
            if(!this.canvasLayer){
                this.canvasLayer = new AMap.CanvasLayer({
                    canvas: this.canvas,
                    bounds: bounds,  
                    opacity:this.opacity,  
                    zooms: [2, 30]            
                });        
                this.map.add(this.canvasLayer);
            }else{
                if(this.canvas.width != _width){
                    this.canvas.width = w;
                    this.canvas.height = h;
                    this.canvasLayer.setCanvas(this.canvas)
                }                
                this.canvasLayer.setBounds(bounds)                                  
            }       
            getImageApi(imgPath,(d)=>{               
                let imgsrc = URL.createObjectURL(d.data)
                let img = new Image(w,h)
                img.alt = imgPath;
                img.crossOrigin ="anonymous"
                img.src = imgsrc;
                img.onload=(f)=>{                    
                    if(f.currentTarget.alt.indexOf(that.$store.state.storage_global.currentSite.siteId) > 0){
                        //更新显示时间     
                        that.drawTimeText(time)                            
                        that.ctx.clearRect(0,0,w,h)            
                        that.ctx.drawImage(f.currentTarget,0,0,w,h);                            
                        that.canvasLayer.reFresh();    
                    }
                }               
            })
                  
        },
        //周期时间显示
        drawTimeText(text){
            if(this.map){
                let pos = this.map.lngLatToContainer(this.center); 
                let angle = this.map.getPitch()
                pos.y = (170/ Math.cos(angle/360)).toFixed(2);            
                let lnglat = this.map.containerToLngLat(pos); 
                if(!lnglat.lng){
                    return;
                }
                let datas = [
                    [lnglat.lng,lnglat.lat,text]
                ]                      
                let opt = {series:[{
                    type:'scatter',
                    data:datas,
                    coordinateSystem:'amap',
                    symbolSize:0,
                    label:{
                        show:true,
                        formatter:'{@[2]}',
                        fontSize:24,                
                    }
                }]}
                if(this.mapEchart?.setOption){
                    this.mapEchart.setOption(opt) 
                }             
            }
        },
        downloadAlarmInfo(){
            let data = this.alarmList.map(a=>{
            return {
                "省":a.province,
                "市":a.city,
                "区":a.district,
                "街道":a.street,
                "位置":a.position,
                "合计":a.total,
                "报警次数":a.count.join(','),
                "报警时间":a.time.join(','),
                "经度":a.lng,
                "纬度":a.lat
            }           
            });
            var worksheet = XLSX.utils.json_to_sheet(data, { bookType:"xlsx", bookSST:false, type:"array" });
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "污染点报警数据");
            XLSX.writeFile(workbook, "污染点报警数据.xlsx");
        },
        downloadImgToVideo(){            
            var that = this;  
            let site = this.$store.state.storage_global.currentSite;
            var hostHref = `web/videohelp/视频合成工具.msi`;            
            var params = encodeURI(`${site.siteId},${this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss")},${this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss")},${this.$store.state.storage_user.user.account},${this.$store.state.storage_user.user.pwd},${this.dataType},${sysconfig.videodownloadversion}`);
            var str = encodeURI($help.compress(params))
            consolelog("GuoYaoVideoTool://" + str)       
            //检测是否有插件            
            customProtocolCheck(
            "GuoYaoVideoTool://" + str,
            () => {
                //失败
                var that = this;
                openDialog({
                    title:"提示",
                    content:"您还没有安装下载合成视频插件, 是否立即下载插件?",
                    okBack:()=>{
                        //下载插件
                        // downloadApi(
                        // {
                        //     url:`/0/web?objectName=videohelp/VDHAPP2.msi`,
                        //     fileName:"VDHAPP.msi" 
                        // })
                        window.open(uploadMinioURL+ hostHref)
                    }
                })                          
            },
            () => {
                //成功
                consolelog("Custom protocol found and opened the file successfully.");
            }, 5000
            );  

        },
        openImgList(item){
            this.imageOverlay.images= item;
            this.imageOverlay.show = true;
        }, 
        open(){
            //window.open(src)
            window.open(this.imageOverlay.images[this.currentImg])
        }

    },
}
</script>

<style lang="scss" scoped>
.map{
    width: 100%;
    height: 100%;
}

.weatherinfo{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 30px;
    bottom: 90px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 10px;
  padding: 3px 10px;
  border-radius: 5px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    right: 52px;
    z-index: 2;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
.hotchart{
    position: relative;
    z-index: 1;
}

.mapToolbar{
    position: absolute;
    z-index: 2;
    width: 160px;    
    left: 680px;
    top: 12px;
}
//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    right: 160px;
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

//自定义分组

.v_table_group_selfC{ 
  width:100%;
  display: flex;
}
.v_table_group_header_self{
  flex-grow: 1;
  width:100%;
  display: flex;
  flex-wrap: nowrap;
}
.v_group_span{
  display: flex;
  margin-right: 16px;
  align-items: center;
}
.v_table_group_header_btn{
  display: flex;
  align-items: center;
}
.listDateInfoItem{
  height: 100%;
  width:100%;    
  display: flex;
  align-items: center;
  justify-content:center;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
.alarmImage{
    :deep(.v-slide-group__prev){
        display: none;
    }
    :deep(.v-slide-group__next){
        display: none;
    }
}
//比例尺
:deep(.amap-scalecontrol){
    left:50px !important;
}

.amap-controlbar {
    //filter: brightness(calc(.6 + var(--s--theme--l)/50*0.2));
}

:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }



.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 

.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    transition: bottom 0.2s linear;
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.mapColorBarConfig{
    position: absolute;
    top: 10%;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 45%;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 80px;
}
.alarmChart{
    width: 98%;    
    min-height: 360px;
}
::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px !important;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px !important;
    }
</style>
<template>
    <v-container
        class="py-2 d-flex flex-column"
        fluid      
      >
      <v-row class="d-flex align-center flex-grow-0" dense>  
        <v-col cols="12">
            <v-row class="align-center pa-0 ma-0">
                <SearchInput 
                    :label="$i18n.t('caseManage.label.title')"
                    :placeholder="$i18n.t('caseManage.placeholder.title')"
                    v-bind:value.sync="title"
                />
                <SearchDatePicker 
                    :range="true"
                    :label="$i18n.t('caseManage.label.publishRange')"
                    :placeholder="$i18n.t('caseManage.placeholder.publishRange')"
                    v-bind:date.sync="publishRange"                    
                    clearable
                />
                <SearchDatePicker v-if="isManager"
                    :label="$i18n.t('caseManage.label.editDateRange')"
                    :placeholder="$i18n.t('caseManage.placeholder.editDateRange')"
                    v-bind:date.sync="editDateRange"
                    range
                    clearable
                />
                <SelectInput             
                    :label="$i18n.t('caseManage.label.site')"
                    :options="sites"                       
                    :placeholder="$i18n.t('caseManage.placeholder.site')" 
                    :width='200'
                    v-bind:value.sync="siteId"
                    clearable/>  
                <SelectInput v-if="isManager"            
                    :label="$i18n.t('caseManage.label.publish')"
                    :options="publishs"  
                    :placeholder="$i18n.t('caseManage.placeholder.publish')" 
                    :width='200'                                        
                    v-bind:value.sync="publish"
                    
                    />  
                    <v-btn class="mx-2" @click="page=1;load()">{{$i18n.t('caseManage.btn.search')}}</v-btn>
                    <v-btn
                    color="primary"                        
                    class="mx-2"
                    v-if="isManager"
                    @click="$router.push({path:'/case/caseManage/edit'})"
                    >{{$i18n.t('caseManage.btn.new')}}
                    </v-btn>
            </v-row> 
        </v-col>                              
      </v-row>   
      <v-row class="tableContainer ma-0">
          <v-card height="100%" width="100%" elevation="0" class=" d-flex cardContain flex-wrap pa-4">
            <v-card class="caseCard flex-wrap flex-grow-0 flex-shrink-0 ma-4 pa-2" width="fit-content" height="fit-content" v-for="data in datas" :key="data.id">
                <v-switch
                    v-model="data.CiPublish"
                    :true-value="1"
                    :false-value="0"
                    v-if="isManager"
                    dense
                    hide-details
                    class="ma-1" 
                    @change="publishCase($event,data)"                   
                    :label="$i18n.t('caseManage.btn.publish')"
                ></v-switch>
                <v-img
                    @click="openWindow(data.CiId)"
                    :src="data.src"
                    class="white--text align-end pointer"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="232px"
                    width="232px"
                >
            <v-card-title class="onlytext2" v-text="data.CiTitle"></v-card-title>
            </v-img>
            <v-card-text class="pa-2 " style="width:232px">
                <v-row class="ma-0 .body-1 authtext onlytext2">
                {{$i18n.t('caseManage.label.author')}}{{data.CiAuthor}}
                </v-row>
                
                <v-row class="ma-0 authtext onlytext2">
                {{$i18n.t('caseManage.label.type')}}{{data.TypeText}}
                </v-row> 
                <v-row class="ma-0 authtext onlytext2">
                {{$i18n.t('caseManage.label.site')}}{{data.SiteNames.join(',')}}
                </v-row> 
                <v-row class="ma-0 authtext onlytext2">
                    {{$i18n.t('caseManage.label.publishTime')}}{{data.publish}}
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-icon class="pointer" v-clipboard:copy="data.href"
            v-clipboard:success="function(){notice({text:'复制成功',type:'success'})}"
            v-clipboard:error="function(){notice({text:'复制失败请重试',type:'error'})}" style="font-size: 22px;" title="复制链接" color="#00838f">mdi-content-copy</v-icon>                            
                <v-spacer></v-spacer> 
                <v-icon v-if="isManager" @click="$router.push({path:'/case/caseManage/edit',query:{id:data.CiId}})" color="#ff6f00" class="pointer" title="修改">mdi-circle-edit-outline</v-icon>                            
                <v-icon v-if="isManager" @click="del(data.CiId)" color="#e64a19" class="pointer"  title="删除">mdi-trash-can</v-icon>    
                <v-icon v-if="isManager" @click="addTemplate(data)"  class="pointer"  title="添加为模板">mdi-book-cross</v-icon>    
            </v-card-actions>
            </v-card>   
            <div v-if="datas.length == 0 && loadOver">
                暂无您可查看的案例
            </div>   
        </v-card>          
      </v-row>    
     
      <v-row justify="center" align="center" class="ma-0 flex-grow-0">
          <v-pagination
          @input="load"
            v-model="page"
            :length="length"
            circle
            :total-visible="8"
            ></v-pagination>
            {{$i18n.t("caseManage.pageTotal",[total])}}
      </v-row>         
      </v-container>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import baseType from '@/plugins/datas/baseType'
import {deleteCaseApi,getCasesApi,publishCaseApi,addCaseToTemplate} from '@/api'
import { uploadMinioURL} from '@/api/url'
export default {
    name:"caseManage.index",
    components:{Editor},
    data(){
        return{
            datas:[],
            page:1,
            rows:10,
            total:0,
            loadOver:false,
            publishRange:[],
            editDateRange:[],            
            publishs:[{
                text:"未发布",
                value:0
            },{
                text:"已发布",
                value:1
            },{
                text:"全部",
                value:null
            }],
            siteId:'',
            publish:null,
            title:"",
            types:[]                        
        }
    },
    computed:{  
        length(){
            let _len = Math.ceil(this.total/this.rows);
            _len = _len == 0 ? 1:_len;
            return  _len
        },
        isManager(){
            var permission = this.$store.state.storage_user.role?.permission;
            var perJson = JSON.parse(permission);
            var item = perJson?.action?.find(a=>{
                return a.key == "case_10"
            })
            if(item){
                var managerPermission = item.permissions?.find(a=>{
                    return a.key == 'edit'
                })                
                return managerPermission?.value;
            }
            return false;
        },
        sites(){
            let sites = this.$store.state.storage_user.sites;
            return sites.map(a=>{
                return {                        
                    text:a.siteName,
                    value:a.siteId
                }
            })
        }               
    },
    watch:{  
        
    },
    mounted(){       
        var that = this;
        this.$store.dispatch('storage_type/getDicMenu',{
            typeid:baseType.caseType,
            callback:(d)=>{
                that.types = d.map(a=>{
                    return{
                    text:a.BdValue,
                    value:a.BdCode
                    }  
                })
                that.load()
            }
        })
    },
    methods:{
        load(){
            this.loadOver = false;
            var that = this;            
            let logo = (this.$store.state.storage_user.user && this.$store.state.storage_user.user.company.logo) ? this.$store.state.storage_user.user.company.logo:"guoyao"
            getCasesApi({
                params:{
                    PageIndex:this.page,
                    PageSize:this.rows,
                    Publish:this.publish,
                    SiteIds:this.siteId,
                    Title:this.title,
                    PublishStartTime:this.publishRange[0],
                    PublishEndTime:!this.publishRange[1] ? '' : new Date(this.publishRange[1])?.pattern("yyyy-MM-dd 23:59:59"),
                    ModifyStartTime:this.editDateRange[0],
                    ModifyEndTime:this.editDateRange[1],
                },success:(d)=>{
                    that.loadOver = true;
                    that.datas = d.Data.map(a=>{  
                        var {href} = that.$router.resolve({
                            path:'/article',
                            query:{
                                id:a.CiId,
                                logo:logo
                            }
                        })                        
                        a.TypeText = that.types.filter(b=>a.CiType.indexOf(b.value.toString())>=0)?.map(b=>b.text)?.join(',')
                        a.SiteNames = [];
                        a.CiSiteId.forEach(b=>{
                            let siteName = that.sites.find(c=>{
                                return c.value == b
                            })
                            if(siteName){
                                a.SiteNames.push(siteName.text)
                            }
                        })                        
                        a.href = location.host + href 
                        if(a.CaseAttachmentRela){
                            a.src = `${uploadMinioURL}${a.CaseAttachmentRela.CarBucketName}${a.CaseAttachmentRela.CarObjectName}`
                        }                                            
                        else{
                            a.src = require('@/assets/img/staff_1024.jpg')                        
                        }
                        if(a.CiPublish ==1 &&a.CiPublishTime){
                            a.publish = new Date(a.CiPublishTime.replace("T"," ")).pattern('yyyy年MM月dd日 HH:mm:ss')
                        }else{
                            a.publish = "未发布"
                        }                        
                        return a;
                    });   
                    that.total = d.Total                      
                }
            })
        },      
        publishCase(event,item){    
            var that = this      
            publishCaseApi({
                id:item.CiId,
                data:{
                    ...item
                },
                success:()=>{
                    that.load()
                }
            })
        },
        del(id){
            var that = this;
            openDialog({
                title:"提示",
                content:"是否删除",
                okBack:(d)=>{
                    deleteCaseApi({
                        id:id,
                        success:(d)=>{
                            if(d){
                                openNotice({
                                    type:"success",
                                    text:that.$i18n.t("sys.self.notice.success")
                                })
                                that.load()
                            }else{
                                openNotice({
                                    type:"error",
                                    text:that.$i18n.t("sys.self.notice.fail")
                                }) 
                                that.load()
                            }     
                        }
                    })                    
                },//确认回调
                noBack:null //取消回调
            })           
        },
        addTemplate(item){
            addCaseToTemplate({
                id:item.CiId,
                success:()=>{
                   openNotice({
                    text:"添加成功",
                    type:"success"
                   }) 
                }
            })
        },
        notice(item){
            openNotice(item)
        },
        openWindow(id){
            let logo = (this.$store.state.storage_user.user && this.$store.state.storage_user.user.company.logo) ? this.$store.state.storage_user.user.company.logo:"guoyao"
            var {href} = this.$router.resolve({
                path:'/article',
                query:{
                    id:id,
                    logo:logo
                }
            })
            window.open(href,'_blank')
        }
    }
    
}
</script>
<style scoped lang="scss">
.cardContain{
    height: 100%;
    overflow: auto;
}
:deep(.caseCard .v-responsive__content){
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.authtext{
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}
.onlytext2{
    height: 34%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

</style>
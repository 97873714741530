
/** * 对Date的扩展，将 Date 转化为指定格式的String * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)
 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) * eg: * (new
 Date()).pattern("yyyy-MM-dd hh:mm:ss.S")==> 2006-07-02 08:09:04.423      
 * (new Date()).pattern("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04      
 * (new Date()).pattern("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04      
 * (new Date()).pattern("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04      
 * (new Date()).pattern("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18      
 */
 import html2canvas from 'html2canvas';
 window.consolelog= function(info,el = 'consolelog'){
  if(window.logstart){
    if(window[el]){
      console.log(info)
    }    
  }
}  
 Date.prototype.pattern = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份         
    "d+": this.getDate(), //日         
    "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时         
    "H+": this.getHours(), //小时         
    "m+": this.getMinutes(), //分         
    "s+": this.getSeconds(), //秒         
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度         
    "S": this.getMilliseconds()
    //毫秒         
  };
  var week = {
    "0": "/u65e5",
    "1": "/u4e00",
    "2": "/u4e8c",
    "3": "/u4e09",
    "4": "/u56db",
    "5": "/u4e94",
    "6": "/u516d"
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "")
      .substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt
      .replace(
        RegExp.$1,
        ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" :
            "/u5468") :
          "") +
        week[this.getDay() + ""]);
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) :
        (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
};

//获取前n天
function getDateByDay(n){
  let curDate = new Date();
  return new Date(curDate.getTime() + 24*60*60*1000 *n); //前一天  
}

//获取某天0点或23.59
function getDateNowStartOrEnd(tim,date = new Date()){
  if(tim == 'start'){
    return new Date(date.setHours(0, 0, 0, 0))
  }
  else if(tim = 'end'){
    return new Date(date.setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1);
  }
  
}
//周期id转时间字符串
function periodToTimeStr(period){
  if(period.length == 14)
    return `${period?.slice(0,4)}-${period?.slice(4,6)}-${period?.slice(6,8)} ${period?.slice(8,10)}:${period?.slice(10,12)}:${period?.slice(12,14)}`
  if(period.length == 17)
    return `${period?.slice(0,4)}-${period?.slice(4,6)}-${period?.slice(6,8)} ${period?.slice(8,10)}:${period?.slice(10,12)}:${period?.slice(12,14)}.${period?.slice(14,17)}`
  return ''
}

function isMobile(){
  if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true; // 移动端
  }else{
    return false; // PC端
  }
}

/**
 * 判断参数日期是否从左到右升序
 * @param date1
 * @param date2
 */
function isDateAscending(date1, date2) {
  var d1 = new Date(date1.replace('-', '/'));
  var d2 = new Date(date2.replace('-', '/'));
  return d1.getTime() < d2.getTime();
}

function isDateAscending2(date1, date2) {
  var d1 = new Date(date1.replace('-', '/'));
  var d2 = new Date(date2.replace('-', '/'));
  return d1.getTime() <= d2.getTime();
}

function toDom(str){
  var objE = document.createElement("div");
  　　 objE.innerHTML = str;
  　　 return objE.childNodes;
  var parser = new DOMParser();
  return parser.parseFromString(str, "text/html")
}


/*************** 一些通 DataGrid 通用的format方法 *****************************/

/**
 * 把.Net输出的 /Date(1445421250444)/ 转化为js 的Date格式
 */
function getDateByDotNet(txt) {
  return eval('new ' + (txt.replace(/\//g, '')))
}

/**
 * 日期格式化
 */
function dateFormatter(value) {
  if (value == undefined || value == null || value == '')
    return value;
  return new Date(value).pattern("yyyy-MM-dd");
}
function datePattern(date,str){
  if(typeof(date) != 'object'){
    return null
  }
  else{
    return date.pattern(str)
  }
}
/**
 * 日期时间格式化
 */
function dateTimeFormatter(value) {
  if (value == undefined || value == null || value == '')
    return value;
  return new Date(value).pattern("yyyy-MM-dd HH:mm:ss");
}

/**
 * 日期时间格式化,根据.Net输出的时间
 */
function dateTimeFormatterByDotNet(txt) {
  if (value == undefined || value == null || value == '')
    return value;
  return dateTimeFormatter(getDateByDotNet(txt));
}

function changeURLArg(url, arg, arg_val) {
  var pattern = arg + '=([^&]*)';
  var replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
    var tmp = '/(' + arg + '=)([^&]*)/gi';
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    if (url.match('[\?]')) {
      return url + '&' + replaceText;
    } else {
      return url + '?' + replaceText;
    }
  }
}


/**
 * 生成GUID
 */
function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

/*************** 密码相关的校验方法 start *****************************/

/**
 * model校验对象 0 旧密码 1 新密码 2 重复新密码
 * 密码复杂度校验
 * 新密码不能和旧密码相同
 * 新密码和重复新密码要一致
 * 至少包含数字、大写字母、小写字母、符号中的三种
 * 新密码不能和以往n次密码相同-后台校验
 */
function pwdCheck(pwdold, pwdnew1, pwdnew2, model) {

  if (model == 0) { //旧密码校验
    if (!pwdold) {
      return { state: '0', txt: '请输入旧密码' };
    } else if (!pwdFuZa(pwdold)) {
      return { state: '0', txt: '至少包含数字、大写字母、小写字母、符号中的三种' };
    } else {
      return { state: '1', txt: '' };
    }
  } else if (model == 1) { //新密码校验
    if (!pwdold) {
      return { state: '0', txt: '请先输入旧密码' };
    } else if (!pwdnew1) {
      return { state: '0', txt: '请输入新密码' };
    } else if (!pwdFuZa(pwdnew1)) {
      return { state: '0', txt: '至少包含数字、大写字母、小写字母、符号中的三种' };
    } else if (pwdnew1 == pwdold) {
      return { state: '0', txt: '不能和旧密码相同' };
    } else {
      return { state: '1', txt: '' };
    }
  } else if (model == 2) { //重复新密码校验
    if (!pwdold) {
      return { state: '0', txt: '请先输入旧密码' };
    } else if (!pwdnew1) {
      return { state: '0', txt: '请先输入新密码' };
    } else if (!pwdFuZa(pwdnew2)) {
      return { state: '0', txt: '至少包含数字、大写字母、小写字母、符号中的三种' };
    } else if (pwdnew2 == pwdold) {
      return { state: '0', txt: '不能和旧密码相同' };
    } else if (pwdnew1 != pwdnew2) {
      return { state: '0', txt: '两次输入的密码不一致' };
    } else {
      return { state: '1', txt: '' };
    }
  } else {
    return { state: '0', txt: '输入的校验模式有误' };
  }
}

/**
 * 密码复杂度校验
 * 至少包含数字、大写字母、小写字母、符号中的三种
 */
function pwdFuZa(pwd) {
  var str = "^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,}$";
  var patt = new RegExp(str);

  return patt.test(pwd);
}

/**
 * 邮箱校验
 */
function checkEmail(str){
  var re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/; 

  return re.test(str);
}


/*************** 密码相关的校验方法 end *****************************/


//随机位置
function randomSort(arr){
  for (let i = 0, l = arr.length; i < l; i++) {
        let rc = parseInt(Math.random() * l );
  // 让当前循环的数组元素和随机出来的数组元素交换位置
        const empty = arr[i];
        arr[i] = arr[rc];
        arr[rc] = empty;       
    }
    return arr
}
///生成n个随机字符
function randomCoding(n){
  var result = [];
  //这个值可以改变的，对应的生成多少个字母，根据自己需求所改
  for(var i=0;i<n;i++){
      //生成一个0到25的数字
      var ranNum = Math.ceil(Math.random() * 25);
      //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;
      //然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
      result.push(String.fromCharCode(65+ranNum));
  }
  return result.join('');
}

/**
 * 生成一个随机数
 */
function randomNum(min, max){
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * 生成一个随机的颜色
 */
function randomColor(min=0, max=255,a=1) {
  var r = randomNum(min, max);
  var g = randomNum(min, max);
  var b = randomNum(min, max);    
  return `rgba(${r},${g},${b},${a})`;//'rgba(' + r + ',' + g + ',' + b + ')';
};

//非空判断
function stringIsNullOrEmpty(str){
  var _type = typeof(str)
  if(_type == 'undefined')
  {
    return true;
  }
  if(str == '')
  {
    return true;
  }
  if(str == null)
  {
    return true;
  }
  else{
    return false;
  }
}

//经纬度判断
function isLngLat(lngOrLat,type){
  if(type == 'lng'){
    var longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
    if(!longrg.test(lngOrLat)){
      return {
        state:false,
        msg:'经度整数部分为0-180,小数部分为0到6位!'
      };
    }    
  }else{
    var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
    if(!latreg.test(lngOrLat)){ 
        return {
          state:false,
          msg:'纬度整数部分为0-90,小数部分为0到6位!'
        };
    }
  }
  return {
    state:true,
    msg:"格式正确"
  };
  
}
//默认返回数组
function splitDefault(str,params){
  if(str == ""){
    return []
  }
  else{
    return str.split(params)
  }
}



//求和
function sum(arr){
  let sum = 0;
  for(var i of arr){
    sum += i;
  }
  return sum
}

//数组去重
function unique(arr){
  //Set数据结构，它类似于数组，其成员的值都是唯一的
  return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
 }
function getRepeat(arr){
    var tmp = [];
    arr.sort().sort(function (a,b) {
        if(a === b && tmp.indexOf(a) === -1){
            tmp.push(a)
        }
    })
    return tmp
}

//求数组交集
function getIntersection(a,b)
{
return a.filter(function(v){ return b.indexOf(v) > -1 })
}
//并集
function getUnion(a,b)
{
return a.concat(b.filter(function(v){ return !(a.indexOf(v) > -1)}));
}
//补集
function getComplement(a,b){
return a.filter(function(v){ return !(b.indexOf(v) > -1) })
.concat(b.filter(function(v){ return !(a.indexOf(v) > -1)}))
}
//差集
function getSubtraction(a,b){
return a.filter(function(v){ return b.indexOf(v) == -1 })
}

//byte字符串转字符串
function binaryAgent(str) {
  let res = [];
  const arr = str.split('');  
  return arr.map(item => {    
      let asciiCode = parseInt(item, 2);
      let charValue = String.fromCharCode(asciiCode);
      return charValue
  }).join('');
}

function byteToString(arr) {
  if(typeof arr === 'string') {
      return arr;
  }
  var str = '',
      _arr = arr;
  for(var i = 0; i < _arr.length; i++) {
      var one = _arr[i].toString(2),
          v = one.match(/^1+?(?=0)/);
      if(v && one.length == 8) {
          var bytesLength = v[0].length;
          var store = _arr[i].toString(2).slice(7 - bytesLength);
          for(var st = 1; st < bytesLength; st++) {
              store += _arr[st + i].toString(2).slice(2);
          }
          str += String.fromCharCode(parseInt(store, 2));
          i += bytesLength - 1;
      } else {
          str += String.fromCharCode(_arr[i]);
      }
  }
  return str;
}

function stringToByte(str) {
  var bytes = new Array();
  var len, c;
  len = str.length;
  for (var i = 0; i < len; i++) {
      c = str.charCodeAt(i);
      if (c >= 0x010000 && c <= 0x10FFFF) {
          bytes.push(((c >> 18) & 0x07) | 0xF0);
          bytes.push(((c >> 12) & 0x3F) | 0x80);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
      } else if (c >= 0x000800 && c <= 0x00FFFF) {
          bytes.push(((c >> 12) & 0x0F) | 0xE0);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
      } else if (c >= 0x000080 && c <= 0x0007FF) {
          bytes.push(((c >> 6) & 0x1F) | 0xC0);
          bytes.push((c & 0x3F) | 0x80);
      } else {
          bytes.push(c & 0xFF);
      }
  }
  return bytes;
}


//datauri转byte
function convertDataURIToBinary(dataURI,num = 23){
  var base64 = dataURI.substring(num);
  var raw = window.atob(base64);
  var rawLength = raw.length;

  var array = new Uint8Array(new ArrayBuffer(rawLength));
  for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
  }
  return array;
}

//动画配置
function animateConfig(z=29.9){
  var stepSet = function(timestamp, elapsed){
    //设置帧率
    if (elapsed > 1000 / z) {
        elapsed = 0
    }        
    window.requestAnimaFrame(
        _timestamp =>stepSet(elapsed + _timestamp - timestamp)
    )
  }  
  window.requestAnimaFrame = 
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function(callback) {
      window.setTimeout(callback, 1000 / z);
  };
  window.requestAnimaFrame(timestamp => stepSet(timestamp, 0)) 

}

//颜色转换
//rgb 转hsl
// r,g,b范围为[0,255],转换成h范围为[0,360]
// s,l为百分比形式，范围是[0,100],可根据需求做相应调整
function rgbToHsl(r,g,b){
	r=r/255;
	g=g/255;
	b=b/255;

	var min=Math.min(r,g,b);
	var max=Math.max(r,g,b);
	var l=(min+max)/2;
	var difference = max-min;
	var h,s,l;
	if(max==min){
		h=0;
		s=0;
	}else{
		s=l>0.5?difference/(2.0-max-min):difference/(max+min);
		switch(max){
			case r: h=(g-b)/difference+(g < b ? 6 : 0);break;
			case g: h=2.0+(b-r)/difference;break;
			case b: h=4.0+(r-g)/difference;break;
		}
		h=Math.round(h*60);
	}
	s=Math.round(s*100);//转换成百分比的形式
	l=Math.round(l*100);
	return [h,s,l];
}

//rgb 转 hsv
// r,g,b范围为[0,255],转换成h范围为[0,360]
// s,v为百分比形式，范围是[0,100],可根据需求做相应调整
function rgbToHsv(r,g,b){
	r=r/255;
	g=g/255;
	b=b/255;
	var h,s,v;
	var min=Math.min(r,g,b);
	var max=v=Math.max(r,g,b);
	var l=(min+max)/2;
	var difference = max-min;
	
	if(max==min){
		h=0;
	}else{
		switch(max){
			case r: h=(g-b)/difference+(g < b ? 6 : 0);break;
			case g: h=2.0+(b-r)/difference;break;
			case b: h=4.0+(r-g)/difference;break;
		}
		h=Math.round(h*60);
	}
	if(max==0){
		s=0;
	}else{
		s=1-min/max;
	}
	s=Math.round(s*100);
	v=Math.round(v*100);
	return [h,s,v];
}

//hsl 转 rgb
//输入的h范围为[0,360],s,l为百分比形式的数值,范围是[0,100] 
//输出r,g,b范围为[0,255],可根据需求做相应调整
function hslToRgb(h,s,l){
	var h=h/360;
	var s=s/100;
	var l=l/100;
	var rgb=[];

	if(s==0){
		rgb=[Math.round(l*255),Math.round(l*255),Math.round(l*255)];
	}else{
		var q=l>=0.5?(l+s-l*s):(l*(1+s));
		var p=2*l-q;
		var tr=rgb[0]=h+1/3;
		var tg=rgb[1]=h;
		var tb=rgb[2]=h-1/3;
		for(var i=0; i<rgb.length;i++){
			var tc=rgb[i];		
			if(tc<0){
				tc=tc+1;
			}else if(tc>1){
				tc=tc-1;
			}
			switch(true){
				case (tc<(1/6)):
					tc=p+(q-p)*6*tc;
					break;
				case ((1/6)<=tc && tc<0.5):
					tc=q;
					break;
				case (0.5<=tc && tc<(2/3)):
					tc=p+(q-p)*(4-6*tc);
					break;
				default:
					tc=p;
					break;
			}
			rgb[i]=Math.round(tc*255);
		}
	}
	
	return rgb;
}

//hsv转rgb
//输入的h范围为[0,360],s,l为百分比形式的数值,范围是[0,100] 
//输出r,g,b范围为[0,255],可根据需求做相应调整
function hsvToRgb(h,s,v){
	var s=s/100;
	var v=v/100;
	var h1=Math.floor(h/60) % 6;
	var f=h/60-h1;
	var p=v*(1-s);
	var q=v*(1-f*s);
	var t=v*(1-(1-f)*s);
	var r,g,b;
	switch(h1){
		case 0:
			r=v;
			g=t;
			b=p;
			break;
		case 1:
			r=q;
			g=v;
			b=p;
			break;
		case 2:
			r=p;
			g=v;
			b=t;
			break;
		case 3:
			r=p;
			g=q;
			b=v;
			break;
		case 4:
			r=t;
			g=p;
			b=v;
			break;
		case 5:
			r=v;
			g=p;
			b=q;
			break;
	}
	return [Math.round(r*255),Math.round(g*255),Math.round(b*255)];
}

//rgb 转16进制颜色
function rgbToHex(color){
  var that = color;
  //十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 如果是rgb颜色表示
  if (/^(rgb|RGB)/.test(that)) {
      var aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
      var strHex = "#";
      for (var i=0; i<aColor.length; i++) {
          var hex = Number(aColor[i]).toString(16);
          if (hex.length < 2) {
              hex = '0' + hex;    
          }
          strHex += hex;
      }
      if (strHex.length !== 7) {
          strHex = that;    
      }
      return strHex;
  } else if (reg.test(that)) {
      var aNum = that.replace(/#/,"").split("");
      if (aNum.length === 6) {
          return that;    
      } else if(aNum.length === 3) {
          var numHex = "#";
          for (var i=0; i<aNum.length; i+=1) {
              numHex += (aNum[i] + aNum[i]);
          }
          return numHex;
      }
  }
  return that;
}

//16进制颜色转rgb
function hexToRgb(color){
  color = color.toLowerCase();
  //十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 如果是16进制颜色
  if (color && reg.test(color)) {
      if (color.length === 4) {
          var colorNew = "#";
          for (var i=1; i<4; i+=1) {
              colorNew += color.slice(i, i+1).concat(color.slice(i, i+1));    
          }
          color = colorNew;
      }
      //处理六位的颜色值
      var colorChange = [];
      for (var i=1; i<7; i+=2) {
          colorChange.push(parseInt("0x"+color.slice(i, i+2)));    
      }
      return colorChange;
  }
  else{
    return [];
  }  
};

//计算渐变色 传入rgb对象
function getGradientColorColor(start,end,step){  
   let colors = [];
   let i = 0;
   let _r = (end.r - start.r) / step;
   let _g = (end.g - start.g) / step;
   let _b = (end.b - start.b) / step;
   while(i <step){
    colors.push({
      r:Math.round(start.r + _r * i) ,
      g:Math.round(start.g + _g * i),
      b:Math.round(start.b + _b * i),
    })
    i++;
   }
   return colors;
}

function getColorList(){
  let _colors = [
    "#000000",
    "#ffffff",
    "#ff0000"                
    ];
  let colors = _colors.map(a=>{      
  let _c = $help.hexToRgb(a);
  return {
    r:_c[0],
    g:_c[1],
    b:_c[2],
  }
  })
  for(var i in colors){
  if(i == colors.length -1){

  }else{
  var arrs = $help.getGradientColorColor(colors[i],colors[Number(i)+1],500/colors.length)
  var str = arrs.map(j=>{
    return `"rgb(${j.r},${j.g},${j.b})"`
  }).join(",")
  console.log(str)
  }      
  }
}


//获取扩展名
function getFileExtension(filename){
  // get file extension
  var extension = filename.split('.').pop();
  return extension;
}


//分组
function groupBy(key,list){
  return list.reduce(function(prev, cur) {    
    (prev[cur[key]] = prev[cur[key]] || []).push(cur);
    return prev;
}, {});
}

///获取两条线段夹角
function getAngle(x1,y1,x2,y2){
  var x = x1 - x2,
      y = y1 - y2;    
  if (!x && !y) {
      return 0;
  }
  //相对x轴
  let angle = Math.atan2(y, x) * 180 / Math.PI;
  if(angle < 0)  
  {
    angle = angle + 360 
  }
  return angle;
  //绝对值
  //var angle = (180 + Math.atan2(-y, -x) * 180 / Math.PI + 360) % 360;
  //return 360 - angle;
}


function compress(strNormalString) {
  console.log(" 压缩前长度： " + strNormalString.length);
  var strCompressedString = "";
  var ht = new Array();
  for (i = 0; i < 128; i++) {
    ht[i] = i;
  }

  var used = 128;
  var intLeftOver = 0;
  var intOutputCode = 0;
  var pcode = 0;
  var ccode = 0;
  var k = 0;

  for (var i = 0; i < strNormalString.length; i++) {
    ccode = strNormalString.charCodeAt(i);
    k = (pcode << 8) | ccode;
    if (ht[k] != null) {
      pcode = ht[k];
    } else {
      intLeftOver += 12;
      intOutputCode <<= 12;
      intOutputCode |= pcode;
      pcode = ccode;
      if (intLeftOver >= 16) {
        strCompressedString += String.fromCharCode(intOutputCode >> (intLeftOver - 16));
        intOutputCode &= (Math.pow(2, (intLeftOver - 16)) - 1);
        intLeftOver -= 16;
      }
      if (used < 4096) {
        used++;
        ht[k] = used - 1;
      }
    }
  }

  if (pcode != 0) {
    intLeftOver += 12;
    intOutputCode <<= 12;
    intOutputCode |= pcode;
  }

  if (intLeftOver >= 16) {
    strCompressedString += String.fromCharCode(intOutputCode >> (intLeftOver - 16));
    intOutputCode &= (Math.pow(2, (intLeftOver - 16)) - 1);
    intLeftOver -= 16;
  }

  if (intLeftOver > 0) {
    intOutputCode <<= (16 - intLeftOver);
    strCompressedString += String.fromCharCode(intOutputCode);
  }
  return strCompressedString;
}

/* *
 * 解压缩字符串
 */
function decompress(strCompressedString) {
  var strNormalString = "";
  var ht = new Array();

  for (i = 0; i < 128; i++) {
    ht[i] = String.fromCharCode(i);
  }

  var used = 128;
  var intLeftOver = 0;
  var intOutputCode = 0;
  var ccode = 0;
  var pcode = 0;
  var key = 0;

  for (var i = 0; i < strCompressedString.length; i++) {
    intLeftOver += 16;
    intOutputCode <<= 16;
    intOutputCode |= strCompressedString.charCodeAt(i);
    while (1) {
      if (intLeftOver >= 12) {              
        ccode = intOutputCode >> (intLeftOver - 12);       
        if (typeof(key = ht[ccode]) != "undefined") {
          strNormalString += key;
          if (used > 128) {
            ht[ht.length] = ht[pcode] + key.substr(0, 1);            
          }
          pcode = ccode;
        } else {
          key = ht[pcode] + ht[pcode].substr(0, 1);
          strNormalString += key;
          ht[ht.length] = ht[pcode] + key.substr(0, 1);
          pcode = ht.length - 1;
        }
        used++;
        intLeftOver -= 12;
        intOutputCode &= (Math.pow(2, intLeftOver) - 1);
      } else {
        break;
      }
    }
  }
  return strNormalString;
}

function interPolation(ii,jj,m,n,data){
  ii = ii-0.5 ;
  jj = jj-0.5 ;
  //console.log(ii,jj,data)
  //debugger;
  var r = Math.floor(ii);
  var c = Math.floor(jj);
  //console.log(r,c)

  let r0 = r > 0 ? r : 0;
  let r1 = r + 1 < m ? r + 1 : r;
  let c0 = c > 0 ? c : 0;
  let c1 = c + 1 <n ? c + 1 : c;
  r0 = r0 > m - 1 ? m - 1 : r0;
  r1 = r1 > m - 1 ?m - 1 : r1;
  c0 = c0 > n - 1 ? n - 1 : c0;
  c1 = c1 > n - 1 ? n - 1 : c1; 
  //console.log(r0,r1,c0,c1)
  if(!data[r0] || !data[r1]){
      return null;
  }
  let v00 = data[r0].data[c0];
  let v01 = data[r0].data[c1];
  let v10 = data[r1].data[c0];
  let v11 = data[r1].data[c1];
  let di = ii - r; //实际与画板点位偏差 角度
  let dj = jj - c; //距离

  let v0 = (v00 * (1 - dj)) + (v01 * dj); 
  let v1 = (v10 * (1 - dj)) + (v11 * dj);

  return (v0 * (1 - di)) + (v1 * di);
}


//dh 数据处理函数  cb 回调  noDataLength 无数据距离   scanLength 扫描长度 dataDis 数据长度
function drawScan(_datas,dh,cb,noDataLength,scanLength,dataDis,_config){  
  if(typeof(noDataLength) == 'undefined'){
    console.error('noDataLength is undefined')
    return 
  }
  if(typeof(scanLength) == 'undefined'){
    console.error('scanLength is undefined')
    return 
  }
  if(typeof(dataDis) == 'undefined'){
    console.error('dataDis is undefined')
    return 
  }
  let config = {
     d:typeof(_config.d) == 'undefined'? 6:_config.d, //d:绘制的扫描距离
     rp:typeof(_config.rp) == 'undefined'? 30:_config.rp,// rp:像素
     mode:typeof(_config.mode) == 'undefined'? 0:_config.mode, // mode:0 水平  1垂直 
     yaw:typeof(_config.yaw) == 'undefined'? 0:_config.yaw,// yaw:偏航角  
     span: typeof(_config.span) == 'undefined'? 0:_config.span //span:补全阈值（秒）
    }
  if(_datas.length == 0 || !cb){
    return;
  }    
  let cA = 360,preHange = config.mode == 0 ? config.yaw:0;
  let datas = []
  for(var i in _datas){
      datas.push(_datas[i])      
      if(i > 0 && config.span){
          let _c = new Date(_datas[i].dataTime)
          let _n = new Date(_datas[i-1].dataTime)
          let span = Math.abs((_c - _n)/1000)
          if(span > config.span){                        
              for(var j = 1;j < span;j++){
                  datas.push(null);
              }
          }
      }                
  }  
  let m = datas.length //角度长度 
  let v = config.d/(scanLength); //绘制的数据长度 
  let n = dataDis * v; //绘制数据长度
  let startD = false;
  if(_datas.length == 1){
      startD = Number(_datas[0].angle) < 0;
  }else if(_datas.length > 1){
    let preA = Number(_datas[0].angle);
    let nextA = Number(_datas[1].angle);
    if(preA > nextA){
      startD = false
    }else if(preA == nextA && _datas.length >2){
      nextA = Number(_datas[2].angle)
      if(preA > nextA){
        startD = false
      }else{
        startD = true;
      }
    }else{
      startD = true;
    }
  }
  
  let width = config.rp * config.d * 2;
  let height =config.rp * config.d * 2; 
  let sqv =n / (config.rp * config.d)   
  let minAngle = Number(_datas[0].angle);let maxAngle = Number(_datas[0].angle);          
  _datas.forEach(a=>{
      if(a.angle > maxAngle){
          maxAngle = Number(a.angle) 
      }
      if(a.angle < minAngle){
          minAngle = Number(a.angle)
      }
  })
  minAngle = minAngle + preHange > cA ? minAngle+ preHange -cA:minAngle + preHange < -cA? -cA -(minAngle + preHange) :minAngle + preHange;            
  maxAngle = maxAngle +preHange > cA? maxAngle + preHange -cA:maxAngle + preHange <-cA? -cA -(maxAngle+ preHange)  :maxAngle + preHange;                  
  let range =  maxAngle -  minAngle; // 数据扇形角度        -285                         
  if(range < 0){
      range += cA; //75
      if(startD){
          minAngle = minAngle - cA; //-61 mx 14
      }else{
          maxAngle = maxAngle + cA 
      }                
  }
  for(let y =0;y<height;y++){              
      for(let x =0;x<width;x++){                                        
          let angle = 0;                       
          let px = (x-width/2);
          let py = (height/2-y);                     
          if(px == 0){
              if(py == 0){                            
                  continue
              }else{
                  if(py > 0){
                      angle = 0;
                  }else{
                      angle = 180;
                  }
              }
          }else if(py==0){
              if(px > 0){
                  angle = 90
              }else{
                  angle = 270;
              }                        
          }else{                       
              let tan = Math.atan2(px,py) * 180 / Math.PI;
              angle = tan > 0 ? tan : cA + tan;                                                     
          }                                                    
          let magnitude = Math.sqrt(px * px + py * py) //点位长度
          if(magnitude * sqv > n){
              continue;
          }         
          var ii = 0;var jj =0;let value = null;                
          if(range == 0){
              //只有一条
              ii = angle - minAngle;                            
          }else{
              let angleScanMin = angle - minAngle > cA ? angle - minAngle -cA :angle -minAngle;
              let angleScanMax =maxAngle -angle >cA ? maxAngle -angle -cA :maxAngle -angle < 0 ?cA + maxAngle -angle : maxAngle -angle;                          
              let sr = startD ? angleScanMin:angleScanMax;            
              ii = (sr / range) * m;
              jj = magnitude * sqv - noDataLength 
              if((Math.floor(jj)-1) <= noDataLength) {                          
                  continue
              }
              if (ii >= 0 && ii < m && jj >= 0 && jj < n)
              {    
                  value = interPolation(ii, jj,m,n,datas);  
                  if(dh){
                    dh({
                      value:value,
                      index:ii,
                      dIndex:jj,
                      angle:angle,
                      x:x,
                      y:y,
                      width:width,
                      height:height,
                      magnitude:magnitude
                    })
                  }
              }   
          }                                   
      }      
    }
  cb()
}

function GetPointByRawData(origin, distance, vAngle, hAngle)
{                      
    let num = 6371.137;
    let d = origin.lat * Math.PI / 180;
    let num2 = origin.lng * Math.PI / 180;
    let d2 = (Math.round(distance * Math.cos(vAngle * Math.PI / 180) * 1000)/1000) /num;
    let mapPoint = {};
    let d3 = hAngle * Math.PI / 180;
    mapPoint.lat = Math.asin(Math.sin(d) * Math.cos(d2) + Math.cos(d) * Math.sin(d2) * Math.cos(d3));
    mapPoint.lng = (num2 + Math.atan2(Math.sin(d3) * Math.sin(d2) * Math.cos(d), Math.cos(d2) - Math.sin(d) * Math.sin(mapPoint.lat))) * 180 / Math.PI;
    mapPoint.lat = mapPoint.lat * 180 / Math.PI;                      
    return mapPoint;
}

// vAngle  scanDis:扫描距离 center 中心位置 经纬度
function getLayerMapPosition(vAngle,scanDis,center,r = 600/600){  
  if(isNull(vAngle) || isNaN(vAngle)){
    return;
  }
  let dis = scanDis * Math.sqrt(2) *  r// 斜边长度(公里)  
  let leftTop = GetPointByRawData({
    lng:center[0],lat:center[1]
  },dis,vAngle -90,225)
  let rightBottom = GetPointByRawData({
      lng:center[0],lat:center[1]
  },dis,vAngle -90,45)
  let bounds = [[leftTop.lng,leftTop.lat],[rightBottom.lng,rightBottom.lat]]
  return bounds;
}

function isNull(val){
  if(typeof(val) == 'undefined' || val == null){
    return true
  }
  if(typeof(val) == 'string' && val.replaceAll(' ') == ''){    
    return true
  }
  if(typeof(val) == 'object'){
    return val.length == 0 
  }
  return false
}

function getNtick(width,textWidth=100){
  let num = width/textWidth;
  let valueArr = [3,4,6]
  let v = 6;
  let value = 6;
  for(var i of valueArr){
    if(Math.abs(num - i) < v){
      value = i;
      v = Math.abs(num - i);
    }  
  }
  return value
}
//降采样算法
function sampling(data,therodsy){
  let newArr = []
  for(let i = 0;i< data.length -2;i+=therodsy){
      let bucket1 = data.slice(i+1,i+therodsy+1)
      let end = i+(2 * therodsy)+1 >= data.length ? data.length -1 : i+(2 * therodsy)+1
      let bucket2 = data.slice(i+therodsy+1,end)
      let avgy = $help.sum(bucket2.map(a=>a=="" || a == NaN || a == "NaN" ? 0 : a))/bucket2.length
      let avgx = i+therodsy+1 + 2/therodsy
      let startx = i;
      let starty = data[i] == "" || data[i] == "NaN" || isNaN(data[i])? 0:data[i]
      let max = -1
      let pj = 0;
      for(let j in bucket1){
          let area = Math.abs((startx - avgx) * (bucket1[j] - starty)
              - (startx - j) * (avgy - starty)
          );
          if(area > max && !(bucket1[j] == "" || bucket1[j] == "NaN" || isNaN(bucket1[j]))){
              max = area;
              pj = j
          }
      }
      newArr.push(Number(i)+Number(pj));                    
  }
  return newArr;
}

function imgDownLoad(dataUrl,w,h,colorBarTarget,bgColor = '#fff',rightSpan = 40,ht = 0,hb = 0){
  updateMask({
    show:true,
    content:"处理中...",
    icon:true
  })
  let canvas = document.createElement('canvas')
    canvas.width = w + 30;
    canvas.height = h;                              
    let img = new Image(w,h)
    img.crossOrigin ="anonymous"
    img.src = dataUrl;
    let ctx = canvas.getContext('2d')                                
    img.onload=function(){
        ctx.drawImage(img,0,0,w,h)
        let colorBar = colorBarTarget;
        html2canvas(colorBar, {
            useCORS: true,
            logging:false,
            width:120,
            backgroundColor:bgColor
        }).then((cvs) => {
            ctx.drawImage(cvs,w-rightSpan,0 + ht,80,h -hb)
            let link = canvas.toDataURL("image/png");
            updateMask({
              show:false,
              content:"",
              icon:true
            })
            exportPicture(link,guid() + '.png')
        });                                  
    }
}

// 导出图片
function exportPicture(link, filename) {
  const file = document.createElement("a");
  file.style.display = "none";
  file.href = link;
  file.download = decodeURI(filename);
 // document.body.appendChild(file);
  file.click();
 // document.body.removeChild(file);
}

/*
 * 计算两点对于正北方向的朝向角度 [0,360]
 * @param {*} start format:{'latitude': 30, 'longitude': 120 }
 * @param {*} end
 */
function bearing(start, end) {
	  let rad = Math.PI / 180,
    lat1 = start[1] * rad,
    lat2 = end[1] * rad,
    lon1 = start[0] * rad,
    lon2 = end[0] * rad;
    const a = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const b = Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
   	return radiansToDegrees(Math.atan2(a, b));
}

/*
 * 弧度转换为角度
 */
function radiansToDegrees(radians) {
    const degrees = radians % (2 * Math.PI);
    return degrees * 180 / Math.PI;
}

/*地图缩放等级对应米数*/
function getSizeZoom(scanDistance,imageScanDistance=null)
{
    var sizeNum = 520;
    var zoom = 14;
    switch (scanDistance)
    {
      case 1:
        sizeNum = 520;
        zoom = 14;
        break;
      case 2:
          sizeNum = 520;
          zoom = 13;
          break;
      case 3:
          sizeNum = 780;
          zoom = 13;
          break;
      case 4:
          sizeNum = 520;
          zoom = 12;
          break;
      case 5:
          sizeNum = 640;
          zoom = 12;
          break;
      case 6:
          sizeNum = 780;
          zoom = 12;
          break;
      case 7:
          sizeNum = 900;
          zoom = 12;
          break;
      case 8:
          sizeNum = 520;
          zoom = 11;
          break;
      case 9:
          sizeNum = 580;
          zoom = 11;
          break;
      case 10:
          sizeNum = 660;
          zoom = 11;
          break;
      case 11:
          sizeNum = 720;
          zoom = 11;
          break;
      case 12:
          sizeNum = 780;
          zoom = 11;
          break;
      case 13:
          sizeNum = 840;
          zoom = 11;
          break;
      case 14:
          sizeNum = 900;
          zoom = 11;
          break;
      case 15:
          sizeNum = 960;
          zoom = 11;
          break;
    }
    sizeNum = imageScanDistance == null ? sizeNum + 40 : sizeNum;
    var imageSize = 0;
    if (imageScanDistance)
    {
        imageSize = sizeNum / scanDistance * imageScanDistance;
    }
    return {
      size:sizeNum +40 ,
      zoom:zoom+1,
      imageSize:imageSize,
      scanDistance:scanDistance
    };
}
function getZoomScale(zoom) {
  switch (zoom) {
      case 20:
          return 0.06;
          break;
      case 19:
          return 0.1;
          break;
      case 18:
          return 0.2;
          break;
      case 17:
          return 0.5;
          break;
      case 16:
          return 0.9;
          break;
      case 15:
          return 1.8;
          break;
      case 14:
          return 3.6;
          break;
      case 13:
          return 7.2;
          break;
      case 12:
          return 14.4;
          break;
      case 11:
          return 28.8;
          break;


      case 10:
          return 57.7;
          break;
      case 9:
          return 115.6;
          break;
      case 8:
          return 231.1;
          break;
      case 7:
          return 462.2;
          break;
      case 6:
          return 924.4;
          break;
      case 5:
          return 1848.9;
          break;
      case 4:
          return 3697.9;
          break;
      case 3:
          return 7395.7;
          break;
      default:
          return 3.6;
  }
}

//会卡ui
function sleepDelay(timestamp){
  for(var t = Date.now();Date.now() - t <= timestamp;);
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function getClosest(value,list,maxSpan = null){
  let index = 0;
  let minSpan = Math.abs(list[0] - value)
  for(let i in list){
    let _minSpan = Math.abs(list[i] - value)
    if(_minSpan < minSpan){
      minSpan = _minSpan
      index = i
    }    
  }
  if(maxSpan && minSpan > maxSpan){
    return null
  }
  return index
}

//获取经纬度两点间距离
function getDistance( lng1,  lat1,  lng2,  lat2){
  var radLat1 = lat1*Math.PI / 180.0;
  var radLat2 = lat2*Math.PI / 180.0;
  var a = radLat1 - radLat2;
  var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
  Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
  s = s *6378.137 ;// EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000;
  return s;
}

function alarmDistanceFilter(siteLng,siteLat,pointLng,pointLat,d){
  let dis = getDistance(siteLng,siteLat,pointLng,pointLat)  
  if(d >= dis){
    return true
  }else{
    return false
  }
}

function copyAsOther(obj){
  if(!obj)return obj
  return JSON.parse(JSON.stringify(obj))
}


export default {
  getDistance,
  copyAsOther,
  alarmDistanceFilter,
  getClosest,
  sleepDelay,
  sleep,
  getZoomScale,
  getSizeZoom,
  bearing,
  radiansToDegrees,
  imgDownLoad,
  exportPicture,
  sampling,
  getNtick,
  periodToTimeStr,
  stringToByte,
  byteToString,
  binaryAgent,
  isNull,
  getLayerMapPosition,
  GetPointByRawData,
  drawScan,
  interPolation,
  getAngle,
  groupBy,
  sum,
  stringIsNullOrEmpty,
  splitDefault,
  getDateByDotNet,
  dateFormatter,
  dateTimeFormatter,
  dateTimeFormatterByDotNet,
  changeURLArg,
  isLngLat,
  guid,
  getDateByDay,
  datePattern,
  getDateNowStartOrEnd,
  pwdFuZa,
  pwdCheck,
  checkEmail,
  convertDataURIToBinary,
  randomSort,
  randomCoding,
  randomNum,
  randomColor,
  getIntersection,
  getUnion,
  unique,
  getRepeat,
  getComplement,
  getSubtraction,
  isDateAscending,
  isDateAscending2,
  toDom,
  animateConfig,
  rgbToHsl,
  rgbToHsv,
  hslToRgb,
  hsvToRgb,
  rgbToHex,
  hexToRgb,
  getGradientColorColor,
  getFileExtension,
  compress,
  decompress,
  isMobile
}

<template>
    <div class="d-flex flex-column">
        <v-app-bar width="100%" max-width="100%" min-width="1200px" clipped-left dense  app :color="$vuetify.theme.currentTheme.primary"> 
            <v-row class="white--text flex-nowrap">
               <v-col cols="2" class="justify-left d-flex align-center" style="min-width:216px;">
                <div class="d-flex align-end flex-grow-1 flex-shrink-0" style="line-height:10px"><v-img class="flex-shrink-0 flex-grow-0"  max-width="12vw" min-width="36px" max-height="30px" :src="logoPath"></v-img>{{$i18n.t('layout.title')}}</div>
            </v-col>      
                <v-spacer></v-spacer>    
            </v-row>
        </v-app-bar>
        <v-main class="width100"  style="height:100vh">
            <div class="height100" ref="mainbox" style="overflow:auto;" @scroll="scroll">
                 <v-container class="justify-center d-flex">
                    <v-card width="1000" class="d-flex flex-column" v-show="articleInfo.content">
                        <v-card-title>
                            {{articleInfo.title}}
                        </v-card-title>
                        <v-row class="ma-0 pa-0 justify-space-between">
                            <v-card-subtitle>作者：{{articleInfo.author}}</v-card-subtitle>
                            <v-card-subtitle>{{articleInfo.datetime}}</v-card-subtitle>
                        </v-row>
                        <v-divider></v-divider>
                        <v-card-text class="width100" style="overflow:auto;" v-html="articleInfo.content">

                        </v-card-text>
                        <v-card-actions class="justify-space-between">
                            <v-btn text @click="nextCase(pre)">{{"前一篇："+(pre?pre.CiTitle:"没有了")}}</v-btn>
                            <v-btn text @click="nextCase(next)">{{"后一篇："+(next?next.CiTitle:"没有了")}}</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-scale-transition origin="center">
                    <v-btn  v-show="showUp" fab absolute bottom fixed right class="ma-10" @click="goto" color="primary"><v-icon>mdi-chevron-up</v-icon></v-btn>
                    </v-scale-transition>
                </v-container>        
            </div>              
        </v-main>
    </div>
</template>
<script>
import {getCaseApi,getCaseNextApi} from '@/api'
export default {
    name:"article",   
    mounted(){        
        this.loadNext();
        this.load();                                
    },
    computed:{
        logoPath(){
            if(this.$route.query.logo){                           
                return this.$route.query.logo;
            }       
            return require("@/assets/img/logo/guoyao.png")       
        },
        pre(){
            if(this.articleInfo.publishTime){
                return this.caseNext.find(a=>{
                    return new Date(a.CiPublishTime.replace("T"," ")) > this.articleInfo.publishTime 
                })
            }
        },
        next(){
            if(this.articleInfo.publishTime){
                return this.caseNext.find(a=>{
                    return new Date(a.CiPublishTime.replace("T"," ")) < this.articleInfo.publishTime 
                })
            }
        }
    },
    data(){
        return{
            showUp:false,
            articleInfo:{
                title:"",
                content:"",
                author:"",
                datetime:"",
                publishTime:""
            },
            caseNext:[]
        }
    },
    methods:{
        load(){            
            let id = this.$route.query.id
            if(!id){
                openNotice({
                    type:"error",
                    text:"未找到案例id"
                })
            }else{                
                var that = this;
                
                 getCaseApi({
                    id:id,
                    success:(d)=>{                        
                       that.articleInfo = {
                            title:d.CiTitle,
                            content:d.CiArticle,
                            author:d.CiAuthor,
                            publishTime:new Date(d.CiPublishTime.replace("T"," ")),
                            datetime: new Date(d.CiPublishTime.replace("T"," ")).pattern("yyyy年MM月dd日")
                       }                      
                    }
                })
            }
        },
        loadNext(){
            var that = this;            
            getCaseNextApi({
                id:this.$route.query.id,
                success:(d)=>{
                    that.caseNext = d;
                }
            })
        },
        goto(){                           
            this.$vuetify.goTo(0,{
                container:this.$refs.mainbox,
                duration:200
            })            
        },
        scroll(e){            
            this.showUp = e.target.scrollTop > 50
        },
        nextCase(a){
            if(a && a.CiId){
                this.$route.query.id = a.CiId;
                this.load()
                this.loadNext();
            }else{
              
            }
        }
    }
}
</script>
<style scoped lang="scss">

</style>
const range = (length, from = 0) => Array.from(new Array(length), (_, i) => from + i);
const ConversionFactors = {
    KmhToKnot: 0.539,
    MpsToKnot: 1.944,
    None: 1,
};

const DEFAULT_CONFIG = {
    size: {
        height: 33,
        width: 80,
    },
    rootBarClassName: 'wind-barb-root',
    svgId: '',
    bar: {
        angle: 20,
        padding: 3,
        stroke: '#000',
        width: 2,
        fullBarClassName: 'wind-barb-bar-full',
        shortBarClassName: 'wind-barb-bar-half',
    },
    conversionFactor: ConversionFactors.None,
    triangle: {
        fill: '#000',
        stroke: '#000',
        padding: 3,
        className: 'wind-barb-triangle',
    },
    circle: {
        fill: '#FFFFFF00',
        stroke: '#000',
        radius: 3,
        strokeWidth: 2,
        className: 'wind-barb-zero-knots-circle',
    },
    baseCircle: undefined,
};

const DEFAULT_CIRCLE_CONFIG = {
    baseCircle: {
        className: 'wind-barb-base-circle',
        fill: '#000',
        radius: 5,
        stroke: '#000',
        strokeWidth: 1,
    },
};


class Barbs2{
    /**
     */
    constructor(svg,speed, angle, options) {
        this.speed = speed;
        this.angle = angle;
        this.options = options;
        this.fullOptions = this.mergeOptions();
        this.svg = svg;
    }
    createSvg() {
        const { width, height } = this.fullOptions.size;
        const svg = d3_selection_1.create('svg')
            .attr('viewBox', `${-width/4} ${-height/4} ${width} ${height}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('width', width)
            .attr('height', height)
            .attr('overflow', 'visible');
        if (this.fullOptions.svgId) {
            svg.attr('id', this.fullOptions.svgId);
        }
        return svg;
    }
    mergeOptions() {        
        const { bar: pBar, conversionFactor: pConversionFactor, size: pSize, triangle: pTriangle, circle: pCircle, svgId: pSvgId, baseCircle: pBaseCircle, rootBarClassName: pRootBarClassName, } = this.options || {};
        const { bar, conversionFactor, size, triangle, circle, svgId, rootBarClassName, } = DEFAULT_CONFIG;
        const privateOptions = {
            bar: Object.assign(Object.assign({}, bar), pBar),
            conversionFactor: pConversionFactor !== null && pConversionFactor !== void 0 ? pConversionFactor : conversionFactor,
            rootBarClassName: pRootBarClassName !== null && pRootBarClassName !== void 0 ? pRootBarClassName : rootBarClassName,
            svgId: pSvgId !== null && pSvgId !== void 0 ? pSvgId : svgId,
            size: Object.assign(Object.assign({}, size), pSize),
            triangle: Object.assign(Object.assign({}, triangle), pTriangle),
            circle: Object.assign(Object.assign({}, circle), pCircle),
            baseCircle: !pBaseCircle
                ? undefined
                : 'object' === typeof pBaseCircle
                    ? Object.assign(Object.assign({}, DEFAULT_CIRCLE_CONFIG.baseCircle), pBaseCircle) : DEFAULT_CIRCLE_CONFIG.baseCircle,
        };
        const dims = this.getSizes(privateOptions);        
        return Object.assign(Object.assign({}, privateOptions), dims);
    }
    getSizes(options) {        
        const height = options.size.height;
        const C = ((90 - options.bar.angle) * Math.PI) / 180;
        const b = height * Math.sin(C);
        const ct = height * Math.cos(C);
        const triangleHeight = b;
        const triangleWidth = ct;
        let z = {
            barHeight: height,
            triangleHeight,
            triangleWidth,
        }
        return {
            dims: {
                barHeight: height,
                triangleHeight,
                triangleWidth,
            },
        };
    }
    getBarbs() {
        const knots = Number((this.speed * this.fullOptions.conversionFactor).toFixed());
        const res = {
            50: 0,
            10: 0,
            5: 0,
        };
        if (knots < 5) {
            return undefined;
        }
        for (let k = knots; k > 0;) {
            if (k - 50 >= 0) {
                res[50] += 1;
                k -= 50;
            }
            else if (k - 10 >= 0) {
                res[10] += 1;
                k -= 10;
            }
            else if (k - 5 >= 0) {
                res[5] += 1;
                k -= 5;
            }
            else {
                break;
            }
        }
        return res;
    }
    drawCircle() {
        const { size: { width, height }, circle, } = this.fullOptions;
        this.svg
            .append('circle')
            .attr('r', circle.radius)
            .attr('cx', width / 2)
            .attr('cy', height / 2)
            .attr('stroke', circle.stroke)
            .attr('fill', circle.fill)
            .attr('stroke-width', circle.strokeWidth)
            .attr('class', circle.className);
        return this;
    }
    drawBarbs(barbs) {
        const { size: { height, width }, rootBarClassName, bar: { width: barWidth, stroke, padding: barPadding }, dims: { triangleWidth }, triangle: { padding: trianglePadding }, } = this.fullOptions;
        const container = this.svg.append('g');
        container
            .append('line')
            .attr('x1', 0)
            .attr('y1', height)
            .attr('x2', width)
            .attr('y2', height)
            .attr('stroke-width', barWidth)
            .attr('stroke', stroke)
            .attr('class', rootBarClassName);
        this.drawBaseCircle(container);
        if (barbs && barbs[50] !== 0) {
            this.drawTriangles(barbs[50], container);
        }
        if (barbs && barbs[10] !== 0) {
            const paddingR = barbs[50] * (triangleWidth + trianglePadding);
            this.drawBars(barbs[10], container, paddingR, 'full');
        }
        if (barbs &&  barbs[5] !== 0) {
            const paddingR = barbs[50] * (triangleWidth + trianglePadding) +
                barbs[10] * (barPadding + barWidth);
            this.drawBars(barbs[5], container, paddingR === 0 ? (barbs[10] === 0 && barbs[50] ===0)?0:  barPadding * 2 : paddingR, 'half');
        }
        container
            .attr('transform-origin', `${-2}px ${height-2}px`)
            .attr('transform', `translate(0, ${-height / 2})`)
            .attr('transform', `translate(0, ${-height / 2})rotate(${-90 + this.angle})`);
        return container;
    }
    drawBaseCircle(container) {        
        if (!this.fullOptions.baseCircle) {
            return;
        }
        const { baseCircle, size: { height }, } = this.fullOptions;
        container
            .append('g')
            .append('circle')
            .attr('class', baseCircle.className)
            .attr('radius', baseCircle.radius)
            .attr('stroke', baseCircle.stroke)
            .attr('fill', baseCircle.fill)
            .attr('stroke-width', baseCircle.strokeWidth)
            .attr('cx', 0)
            .attr('cy', height)
            .attr('r', baseCircle.radius);
    }
    drawTriangles(q, container) {        
        const { size: { height, width }, triangle: { padding, stroke, fill, className }, dims: { triangleWidth, triangleHeight }, } = this.fullOptions;
        const drawPath = (index) => {
            const initialX = width - (triangleWidth + padding) * index;
            return `M${initialX}, ${height}, ${initialX - triangleWidth}, ${height}, ${initialX}, ${height + triangleHeight}z`;
        };
        const data = range(q);
        container
            .append('g')
            .selectAll('path')
            .data(data)
            .enter()
            .append('path')
            .attr('d', (_, i) => drawPath(i))
            .attr('stroke', stroke)
            .attr('fill', fill)
            .attr('class', className);
    }
    drawBars(q, container, right, type) {
        const { size: { width, height }, bar: { width: barWidth, padding, angle, stroke, fullBarClassName, shortBarClassName, }, dims: { barHeight }, } = this.fullOptions;
        const data = range(q);
        container
            .append('g')
            .selectAll('line')
            .data(data)
            .enter()
            .append('line')
            .attr('x1', (_, i) => width - (right + i * (barWidth + padding)))
            .attr('y1', height)
            .attr('x2', (_, i) => width - (right + i * (barWidth + padding)))
            .attr('y2', height - (type === 'full' ? barHeight : barHeight / 2))
            .attr('stroke', stroke)
            .attr('stroke-width', barWidth)
            .attr('class', type === 'full' ? fullBarClassName : shortBarClassName)
            .attr('transform-origin', (_, i) => `${width - (right + i * (barWidth + padding))} ${height}`)
            .attr('transform', `rotate(${angle + (2 * (90 - angle))})`);
    }
   
    draw(container) {
        var _a;
        const barbs = this.getBarbs();
        if (barbs === undefined) {
            this.drawBarbs();
        }
        else {
            this.drawBarbs(barbs);
        }
        if (container) {
            (_a = d3_selection_1.select(container).node()) === null || _a === void 0 ? void 0 : _a.appendChild(this.svg.node());
        }
        //return this.svg.node();
    }
}



function getBarbs(speed) {
    let knots =speed * 1; //平衡系数
    let res = {
        50: 0,
        10: 0,
        5: 0,
    };
    if (knots < 5) {
        return undefined;
    }
    for (let k = knots; k > 0;) {
        if (k - 50 >= 0) {
            res[50] += 1;
            k -= 50;
        }
        else if (k - 10 >= 0) {
            res[10] += 1;
            k -= 10;
        }
        else if (k - 5 >= 0) {
            res[5] += 1;
            k -= 5;
        }
        else {
            break;
        }
    }
    return res;
}

// function draw(speed,mainContainer,color = 'black'){
//     let width = 20,height = 6;
//     let res = getBarbs(speed)  
//     let container = mainContainer.append('g')     
//     if(res === undefined){
//             //drawCircle()
//         container
//         .append('line')
//          .attr('x1', 0)
//          .attr('y1', height)
//          .attr('x2', width)
//          .attr('y2', height)
//          .attr('stroke-width', 1)
//          .attr('stroke', color)
//          .attr('class', 'barbs-line');
//     }else{        
//          container
//          .append('line')
//          .attr('x1', 0)
//          .attr('y1', height)
//          .attr('x2', width)
//          .attr('y2', height)
//          .attr('stroke-width', 1)
//          .attr('stroke', color)
//          .attr('class', 'barbs-line');
//          let barAngle = 30;
//          let c = ((90 - barAngle) * Math.PI) / 180;
//         let triangleWidth =  height * Math.cos(c);
//         let triangleHeight = height * Math.sin(c);
//         let trianglePadding = 3;
//         let barPadding = 3;
//         let barWidth = 2;
//         if (res[50] !== 0) {            
//             let data = range(res[50])            
//             let drawPath = (index) => {
//                 var initialX = width - (triangleWidth + trianglePadding) * index;
//                 return `M${initialX}, ${height}, ${initialX - triangleWidth}, ${height}, ${initialX}, ${height - triangleHeight}z`;
//             };
//             container
//             .append('g')
//             .selectAll('path')
//             .data(data)
//             .enter()
//             .append('path')
//             .attr('d', (_, i) => drawPath(i))
//             .attr('stroke', color)
//             .attr('fill', color)            
//         }
//         if (res[10] !== 0) {
//             let paddingR = res[50] * (triangleWidth + barPadding);
//             let data = range(res[10])
//             container
//             .append('g')
//             .selectAll('line')
//             .data(data)
//             .enter()
//             .append('line')
//             .attr('x1', (_, i) => width - (paddingR + i * (barWidth + barPadding)))
//             .attr('y1', height)
//             .attr('x2', (_, i) => width - (paddingR + i * (barWidth + barPadding)))
//             .attr('y2', 0)
//             .attr('stroke', color)
//             .attr('stroke-width', barWidth)            
//             .attr('transform-origin', (_, i) => `${width - (paddingR + i * (barWidth + barPadding))} ${height}`)
//             .attr('transform', `rotate(${barAngle})`);           
//         }
//         if (res[5] !== 0) {
//             let paddingR = res[50] * (triangleWidth + trianglePadding) +
//                 res[10] * (barPadding + barWidth);
//             let data = range(res[10])
//             let right = paddingR === 0 ? (res[10] === 0 && res[50] ===0)?0:  barPadding * 2 : paddingR
//             container
//             .append('g')
//             .selectAll('line')
//             .data(data)
//             .enter()
//             .append('line')
//             .attr('x1', (_, i) => width - (right + i * (barWidth + barPadding)))
//             .attr('y1', height)
//             .attr('x2', (_, i) => width - (right + i * (barWidth + barPadding)))
//             .attr('y2', height -  height / 2)
//             .attr('stroke', color)
//             .attr('stroke-width', barWidth)
//             .attr('transform-origin', (_, i) => `${width - (right + i * (barWidth + barPadding))} ${height}`)
//             .attr('transform', `rotate(${barAngle})`);
//         }
//         container
//         .attr('transform-origin', `${width/2}px ${height/2}px`)        
//         .attr('transform', `translate(${-width/2}, ${-height/2})rotate(${-90})`);         
//     }
// }

function barbs(target,scale = 1,a){    
    var d3_selection = require("d3-selection");     
    let container = d3_selection.select(target)
    let points = container.selectAll('g.points');
    points.selectAll('g').remove();
    let pointsPath=  points.selectChildren('path');   
    pointsPath.each((d,i,n)=>{    
        let node = d3_selection.select(n[i])         
        let data = node.data()[0] 
        if(typeof(data?.data?.speed) == 'undefined' || typeof(data?.data?.angle) == 'undefined' || isNaN(data?.data.angle) || isNaN(data?.data.speed)){
            return
        }
        if(a){
            if(d.x -8*1000*60*60 < new Date(a['xaxis.range[0]']).getTime() ||d.x  -8*1000*60*60> new Date( a['xaxis.range[1]']).getTime() || d.y < a['yaxis.range[0]'] || d.y > a['yaxis.range[1]'])
            return;
        }                     
        let speed = Math.round(data.data.speed)
        speed = speed > 24 ? 24:speed;
        let color = '#969696' 
        // if(speed <= 1 ){
        //     color = '#bd1084'
        // }else 
        speed = speed < 2 ? 2:speed;
        if( speed  <= 3){
            color = '#EA80FC'
        }else if(speed  > 3 && speed  <= 5){
            color = '#B75DF5'
        }else if(speed  > 5 && speed  <= 7){
            color = '#3220DC'
        }else if(speed  > 7 && speed  <= 9){
            color = '#5EFDFD'
        }else if(speed  > 9 && speed  <= 11){
            color = '#23FE23'
        }else if(speed  > 11 &&speed  <= 13){
            color = '#6EAB42'
        }else if(speed  > 13 &&speed  <= 15){
            color = '#6EAB42'
        }else if(speed  > 15 &&speed  <= 17){
            color = '#FFF73E'
        }else if(speed  > 17 &&speed  <= 19){
            color = '#FFF73E'
        }else if(speed  > 19 &&speed  <= 21){
            color = '#FD5555'
        }else if(speed  > 21 &&speed  <= 23){
            color = '#FD5555'
        }else if(speed  > 23 &&speed  <= 24){
            color = '#969696'
        }else{
            color = '#969696'
        }      
        let angle = data.data.angle; 
        let trans = node.node().getAttribute('transform') 
        let transZRegex = /\.*translate\((.*)\)/i;
        let xy = transZRegex.exec(trans)[1]?.split(",")                 
        let c = points.append('g')
        c.attr('transform', `translate(${Number(xy[0]) +','+Number(xy[1])+10})rotate(${angle})scale(${0.9 * scale})`)                  
        //draw(speed * 5/2,c,color)
        new Barbs2(c,speed * 5/2,0,
        {
            size:{
                height:12,
                width:24
            },
            triangle:{
                fill:color,
                stroke:color
            },
            circle:{
                fill:color,
                stroke:color
            },
            bar:{
                stroke:color
            }         
        }).draw();  
    })
   
}

export default barbs;
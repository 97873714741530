<template>
    <v-container class="contain ma-0 pa-0">  
        <v-card width="100%">
            <v-card-actions class="mapToolbar mt-4 mx-0 px-0 d-flex flex-nowrap">
                <v-row class="ma-0 align-center justify-start">                                    
                    <v-card elevation="0" class="my-0 mx-2 pa-0" style="min-width:360px;">
                        <SearchDateTimePickerPlus :maxRangeDay='3' range v-bind:datetime.sync="timeRange" />
                    </v-card>
                    <v-menu open-on-click offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                                                        
                            v-bind="attrs"
                            v-on="on"
                            class="ma-1 my-3"
                            >
                            {{currentTime.text}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="(item, index) in menuTime"
                                :key="index"
                                @click="setTime(item)"
                            >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>                          
                    </v-menu>
                    <v-menu open-on-click offset-y bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                              
                            v-bind="attrs"
                            v-on="on"
                                class="ma-1 my-3"
                            >
                           {{$i18n.t(`history.btn.${onboarding}`)}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="onboarding">
                                <v-list-item
                                    v-for="(item, index) in ['ppi','fix','rhi']"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{$i18n.t(`history.btn.${item}`)}}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-menu open-on-click offset-y bottom v-if="onboarding =='fix'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                                                              
                            v-bind="attrs"
                            v-on="on"
                                class="ma-1 my-3"
                            >
                            {{currentMergeType.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentMergeType">
                                <v-list-item
                                    v-for="(item, index) in mergeTypes"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>        
                </v-row>
            </v-card-actions>
        </v-card>   
        <Scanmap v-if="onboarding=='ppi'" :timeRange='timeRange' :settime="settime" :currentWindow="onboarding"/>   
        <v-card v-if="onboarding=='fix'" elevation="0" class="pa-0 ma-0 width100 height100">    
            <v-row key="row0" class="ma-0 pa-0 width100" style="height: 33.3%;">  
                    <ScanmapFix :currentWindow="onboarding"/>
                </v-row>
                <v-row key="row1" class=" ma-0 pa-0 width100 " style="height: 66.6%;" v-if="onboarding=='fix'">                
                    <Heatmap  class="hotChart moveBox" title="RCS时间热图" type="rcsmerge" belong='fix' :mType="currentMergeType" :timeRange='timeRange' :currentWindow="onboarding" />  
                    <Heatmap  class="hotChart moveBox" title="消光系数时间热图" type="extmerge" belong='fix' :mType="currentMergeType" :timeRange='timeRange' :currentWindow="onboarding" />   
                </v-row>       
        </v-card> 
        <v-card v-if="onboarding=='rhi'" elevation="0" class="pa-0 ma-0 width100 height100">    
            <v-row key="row0" class="ma-0 pa-0 width100" style="height: 33.3%;">  
                <ScanmapFix :currentWindow="onboarding"/>
            </v-row>
            <v-row key="row1" class=" ma-0 pa-0 width100 " style="height: 66.6%;" v-if="onboarding=='rhi'">                
                <RHI  class="rcsChart moveBox width100 height100"  :timeRange="timeRange"  ></RHI>     
            </v-row>      
        </v-card>               
    </v-container>
</template>
<script>
import Heatmap from '@/components/mobile/monitoring/pls/history/Heatmap'
import Scanmap from '@/components/mobile/monitoring/pls/history/Scanmap'
import RHI from '@/components/mobile/monitoring/pls/history/RHI'
import ScanmapFix from '@/components/mobile/monitoring/pls/history/ScanmapFix'
import SearchDateTimePickerPlus from '@/components/form/SearchDateTimePickerPlus'
import Draggable from "vuedraggable"
import {getLastestRawApi} from '@/api'
export default {
    name:"monitoring.pls.history",
    components:{SearchDateTimePickerPlus,Heatmap,Draggable,RHI,Scanmap,ScanmapFix},
    data(){
        return {
            settime:null,
        onboarding:'ppi', 
        //showRadio:true,       
        //时间热图  垂直使用
        hotChart:null,
        hotChartStatus:false,
        moveHeight:320,
        hotChartData:[],        
        timeRange:[],
        //快捷
        menuTime:[
            {
                text:this.$i18n.t('history.btn.btn24'),
                value:'btn24'
            },
            {
                text:this.$i18n.t('history.btn.btn1'),
                value:'btn1'
            },
            {
                text:this.$i18n.t('history.btn.btn3'),
                value:'btn3'
            },
            {
                text:this.$i18n.t('history.btn.btn1d'),
                value:'btn1d'
            },
            {
                text:this.$i18n.t('history.btn.btn2d'),
                value:'btn2d'
            }
        ],
        currentTime:{
            text:this.$i18n.t('history.btn.btn24'),
            value:'btn24'
        },
                
        FIX_ScanTypes:[],//固定扫描类型
        RHI_ScanTypes:[],//垂直扫描类型
        vertical_ScanTypes:[],//垂直扫描选项
        currentScanmapType:"",
        currentVerticalType:"",//垂直扫描选择
        mergeTypes:[{
            value:0,
            name:"周期数据"
        },{
            value:50,
            name:"50径向合并数据"
        }],
        currentMergeType:{
            value:0,
            name:"周期数据"
        },
    }},
    beforeDestroy(){       
    }, 
    computed:{
    },
    watch:{   
        "$store.state.storage_global.currentSite":{
            handler(n){
                this.load(true);
            }
        },
        currentMergeType(n){
            
        },     
        currentTime:{
            handler(n){
                let end = new Date();
                let start = new Date();        
                if(n.value == 'btn24'){
                    start.setTime(start.getTime() - 3600 * 1000 * 24); 
                }else if(n.value == 'btn1'){
                    start.setTime(start.getTime() - 3600 * 1000 * 1);
                }else if(n.value == 'btn3'){
                    start.setTime(start.getTime() - 3600 * 1000 * 3);
                }else if(n.value == 'btn1d'){    
                    end = $help.getDateNowStartOrEnd('start');
                    start = $help.getDateNowStartOrEnd('start');
                    start.setTime(start.getTime() - 3600 * 1000 * 24);
                }else if(n.value == 'btn2d'){
                    end = $help.getDateNowStartOrEnd('start');
                    start = $help.getDateNowStartOrEnd('start');
                    end.setTime(start.getTime() - 3600 * 1000 * 24* 1);
                    start.setTime(start.getTime() - 3600 * 1000 * 24* 2);
                }
                this.timeRange = [start,end]                             
            }
        }        
    },
    mounted(){
        if(this.$route.params.time){
            //如果是从消息转过来的 显示转来时间的图像，报警点 查询时间前后一小时
            let _currtime = new Date(this.$route.params.time)
            //设置当前查询时间            
            let start = new Date((_currtime.getTime() - 3600 * 1000 * 1));
            let end = new Date((_currtime.getTime() + 3600 * 1000 * 1));            
            this.timeRange = [start,end]    
            this.settime = _currtime                   
        }   
        else{
            let that = this;
            setTimeout(() => {
                that.load()
            }, 300);       
        }                                   
    },
    methods:{
        load(update){
            let that = this;            
            getLastestRawApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    siteType:this.$store.state.storage_global.currentSite.type
                },
                success:(d)=>{                                                        
                    if(d){          
                        if(d.ScanType == 0){
                            that.onboarding = 'ppi'
                        }else if(d.ScanType == 1){
                            that.onboarding = 'fix'
                        }else if(d.ScanType == 2){
                            that.onboarding = 'rhi'
                        }
                        if(!update){
                            that.timeRange =[new Date(new Date(d?.DateTime?.replace('T',' ')).setDate(new Date(d?.DateTime?.replace('T',' ')).getDate() -1)),new Date(d?.DateTime?.replace('T',' '))]
                        }                        
                    }else{
                        if(!update){
                            that.timeRange =[new Date(new Date().setDate(new Date().getDate() -1)),new Date()]
                        }                        
                    }
                }
            })            
        },
        //快捷时间选项
        setTime(item){
            this.currentTime = Object.assign({},item);             
        },
    },
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.window{
    width: 100%;
    height: 100%;
}
.mapToolbar{
    position: absolute;
    z-index: 2;
    width: 100%;
}
.checkRadio{    
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    bottom: 50%;
}
.leftRadio{
    position: absolute;
    z-index: 2;
    left: -15px;
    display: flex;
    flex-direction: column;
    bottom: calc(50% + 40px);
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 50%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 100%;
    height: 50%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}


</style>
<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">   
        <v-card class="width100 height100">
            <v-row class="ma-0 pa-0 d-flex justify-space-between">
                <v-card-title class="ma-0 pa-1 heatmapTitle">径向风<span v-show="currentMap.value == 1">[一小时]</span></v-card-title>
                <v-card-actions>
                    <realTimeConfig style="display: none;" :timeRange="15" :timeSpan="5"/>
                    <v-menu open-on-hover offset-y bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn          
                            v-show="false"                                                                                
                            v-bind="attrs"
                            v-on="on"
                            >
                            {{currentMap.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentMap">
                                <v-list-item
                                    v-for="(item, index) in mapConfigList"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>   
                </v-card-actions>
                <ColorBarConfig  :dataKey="50"  ref="colorBar" class="mapColorBarConfig" @update="configColorBar"/>                    
            </v-row>            
            <v-row class="rowHeight ma-0" v-show="currentMap.value == 0">
                <v-card height="100%" width="100%" elevation="1">                
                    <v-overlay
                        absolute
                        :value="overlay"
                        >
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <v-overlay
                        class="ma-3 mt-11"
                            absolute
                            :value="noData"
                            opacity="0.2"
                            >
                        暂无数据
                    </v-overlay>                                   
                    <div class="mx-1" style="position:absolute;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>              
                    <div ref="polar" class="width100 height100">
                    </div>
                </v-card>
            </v-row>        
            <v-row class="rowHeight ma-0" v-show="currentMap.value == 1">
                <v-card width="100%" height="100%">
                    <v-overlay
                        absolute
                        :value="overlay"
                        >
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>
                        <v-overlay
                            class="ma-3 mt-11"
                                absolute
                                :value="noData"
                                opacity="0.2"
                                >
                            暂无数据
                        </v-overlay>                   
                    <div ref="heatmap" class="width100 height100 "></div>
                </v-card>
            </v-row>        
            <v-row class="rowHeight ma-0" v-show="currentMap.value ==2">
                <v-card width="100%" height="100%">
                    <v-overlay
                        absolute
                        :value="overlay"
                        >
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <v-overlay
                        class="ma-3 mt-11"
                            absolute
                            :value="noData"
                            opacity="0.2"
                            >
                        暂无数据
                    </v-overlay>            
                    <div class="mx-1" style="position:absolute;z-index: 2;">{{this.dataList[this.dataList.length -1]?.dataTime}} 偏航角:{{yaw}}°</div>                              
                    <div ref="line" class="width100 height100">
                    </div>
                </v-card>
            </v-row>       
        </v-card>          
    </v-container>
</template>
<script>
import { getWlsRadialApi,getWlsRadialByTimeRangeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import realTimeConfig from '@/components/cardMenu/realTimeConfig'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:'wls.realtime.radial',
    props:{
        currentView:{
            type:Number,
            default:0
        }
    },
    components: {
        ColorBarConfig,realTimeConfig
    },
    data(){
        return {
            mapConfigList:[{
                name:"极地图",
                value:0
            },
            {
                name:"时间热图",
                value:1
            },
            {
                name:"折线图",
                value:2
            }],
            overlay:false,
            dataList:[],
            currentMap:{
                value:0,
                name:"极地图"
            },
            time:'',
            yaw:'',
            currentWatch:null
        }
    },
    computed:{        
        noData(){
            return !this.overlay&&(!this.dataList || this.dataList.length == 0)
        }
    },
    watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                var that = this;
                this.dataList = []
                this.watchData(n.siteId)      
                if(n.scanType == "FIX" || n.scanType == "Tri"||this.currentMap.value == 1){          
                    this.load()
                }else{
                    setTimeout(() => {                    
                        if(that.dataList.length == 0){                
                            that.load()  
                        }  
                    }, 30000);                
                }                
            }
        },       
        "$store.state.storage_global.currentSite.scanType":{
            handler(n){
                // if(n == "FIX" || n == "Tri" || n == 1 || n == 4){
                //     this.mapConfigList = [                   
                //     {
                //         name:"时间热图",
                //         value:1
                //     },
                //     {
                //         name:"折线图",
                //         value:2
                //     }]
                //     if(this.currentMap.value != 1){
                //         this.currentMap = {
                //             name:"时间热图",
                //             value:1
                //         }
                //     }
                //     this.load()
                // }else{
                //     this.mapConfigList = [{
                //         name:"极地图",
                //         value:0
                //     },
                //     {
                //         name:"时间热图",
                //         value:1
                //     },
                //     {
                //         name:"折线图",
                //         value:2
                //     }]
                // }
            }
        },
        currentMap(n){
            let that = this;
            if(n.value == 1){                
                setTimeout(() => {
                    that.load()
                }, 400);                
            }
            if(n.value == 2){
                setTimeout(() => {                    
                    that.loadLine();
                }, 400);
            }
            if(n.value == 0){
                setTimeout(() => {                    
                    that.loadChart();
                }, 200);
            }
        },
        currentView(n){
            this.currentMap = this.mapConfigList[n]
        }
    },
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
    },
    mounted(){
        var that = this;
        this.currentMap = this.mapConfigList[this.currentView]
        setTimeout(() => {
            if(!that.$store.state.storage_global.currentSite.siteId){
                return
            }
            that.watchData(that.$store.state.storage_global.currentSite.siteId)
            if($help.isNull(that.$store.state.storage_global.currentSite.scanType)){            
                that.$store.dispatch('storage_user/getLastestScanType',{
                    site:that.$store.state.storage_global.currentSite,
                    cb:(obj)=>{
                        if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                            if(that.$store.state.storage_global.currentSite.scanType == "FIX" || that.$store.state.storage_global.currentSite.scanType == "Tri"){
                                // that.mapConfigList = [                   
                                //     {
                                //         name:"时间热图",
                                //         value:1
                                //     },
                                //     {
                                //         name:"折线图",
                                //         value:2
                                //     }]
                                if(that.currentMap.value != 1 && that.currentMap.value != 2){
                                    that.currentMap = {
                                        name:"时间热图",
                                        value:1
                                    }
                                }
                                that.load()
                            }else{
                                setTimeout(() => {
                                    if(that.dataList.length == 0){                
                                        that.load()  
                                    }  
                                }, 30000);   
                            }                          
                        }
                    }
                })
            }else{
                if(that.$store.state.storage_global.currentSite.scanType == "FIX" || this.$store.state.storage_global.currentSite.scanType == "Tri"){
                    // that.mapConfigList = [                   
                    //     {
                    //         name:"时间热图",
                    //         value:1
                    //     },
                    //     {
                    //         name:"折线图",
                    //         value:2
                    //     }]
                    if(that.currentMap.value != 1 && that.currentMap.value != 2){
                        that.currentMap = {
                            name:"时间热图",
                            value:1
                        }
                    }
                    that.load()
                }else{
                    setTimeout(() => {
                        if(that.dataList.length == 0){                
                            that.load()  
                        }  
                    }, 30000);   
                }                  
            }  
        }, 200);                     
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                                                               
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == transferDataType.radialWind.value ){                                                                                                                                                                                
                    let json = JSON.parse(JSON.stringify(n.jsonData))       
                    let len = that.dataList.length;
                    len = len< 100 ? 100:len
                    that.yaw= json[0].yaw;                    
                    if(that.dataList.length > 0 && (json[0].period!=that.dataList[0].period)){
                        if(that.currentMap.value != 1){
                            that.dataList = [];
                        }                        
                    }                 
                    let dataJson = json.map(a=>{
                        return {
                            dataTime:a.dataTime,
                            distanceData:a.distanceData,
                            angle:a.scanType == "PPI" ? a.straightAngle:a.scanType == "RHI"?a.angleOfPitch:a.straightAngle,      
                            straightAngle:a.straightAngle,
                            angleOfPitch:a.angleOfPitch,                  
                            scanMode:scanType,
                            data:a.data,
                            period:a.period
                        }
                    }).concat(that.dataList).sort((a,b)=>{
                        return new Date(a.dataTime.replace('T',' ')) - new Date(b.dataTime.replace('T',' '))
                    })   
                    that.dataList = dataJson;                
                    that.time = that.dataList[that.dataList.length -1]?.dataTime                                        
                    if(that.currentMap.value == 1){                        
                        that.dataList = that.dataList.slice(-len)
                        that.loadHeatmap();
                    }else if(that.currentMap.value == 0){
                        if(that.dataList.length > 1){
                            that.loadChart();
                        }                        
                    }else if(that.currentMap.value == 2){
                        that.loadLine();                     
                    }                                                            
                }
            }, {immediate: true })
        },   
        configColorBar(){            
            if(this.$store.state.storage_global.currentSite.siteId && this.dataList.length > 0){                                        
                if(this.$refs.line){
                    this.loadLine()
                }                    
                if(this.$store.state.storage_global.currentSite.scanType == "FIX"||this.$store.state.storage_global.currentSite.scanType == "RHI"){                        
                    if(this.$refs.heatmap){
                        this.loadHeatmap()
                    }                        
                }else{
                    if(this.$refs.polar){
                        this.loadChart()
                    }                        
                }                                       
            }
        },        
        load(){
            var that = this;       
            this.overlay = true;
            let _scanType = this.$store.state.storage_global.currentSite.scanType == null || typeof(this.$store.state.storage_global.currentSite.scanType) == 'undefined'? "PPI":this.$store.state.storage_global.currentSite.scanType
            if(_scanType == 'FIX'|| this.currentMap.value == 1 || _scanType == "Tri"){
                let start = new Date (new Date ().setTime(new Date ().getTime() -60 *60 * 1000)).pattern('yyyy-MM-dd HH:mm:ss');
                let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
                getWlsRadialByTimeRangeApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    params:{
                        StartTime:start,                  
                        EndTime:end,
                        PageSize:1000, 
                        ScanMode: _scanType == "PPI"?0:_scanType == "FIX"?1:_scanType == "RHI"?2:_scanType == "Tri"?3:0                                 
                    },
                    success:(d)=>{  
                        that.overlay = false;                          
                        if(!d.Data || d.Data.length == 0)  {                            
                            if(that.currentMap.value == 1){                
                                that.loadHeatmap();
                            }else if(that.currentMap.value == 0){
                                if(that.dataList.length > 1){
                                    that.loadChart();
                                }                        
                            }else if(that.currentMap.value == 2){
                                that.loadLine();                     
                            }                                     
                            return;
                        }    
                        if(d.Data[0].DwrSiteId != that.$store.state.storage_global.currentSite.siteId)   {
                            return;
                        }
                        that.dataList = d.Data?.map(a=>{
                            return {
                                dataTime:a.DwrTime.replace('T',' '),
                                distanceData:a.DwrDis,
                                angle:a.DwrScanMode == 0 ? a.DwrStraight:a.DwrScanMode == 2?a.DwrPitch:a.DwrStraight,                        
                                scanMode:scanType[a.DwrScanMode],
                                straightAngle:a.DwrStraight,
                                angleOfPitch:a.DwrPitch,  
                                data:a.DwrValue,
                                period:a.DwrPeriod
                            }
                        });  
                        let phj= that.dataList[0].period.split('_');
                        if(phj.length>1)
                        {
                          that.yaw=Number(phj[1])%360;
                        }
                        that.lineData = that.dataList[that.dataList.length -1]           
                        if(that.currentMap.value == 1){                
                            that.loadHeatmap();
                        }else if(that.currentMap.value == 0){
                            if(that.dataList.length > 1){
                                that.loadChart();
                            }                        
                        }else if(that.currentMap.value == 2){
                            that.loadLine();                     
                        }  
                    }
                }) 
            }else{
                getWlsRadialApi({
                    id:this.$store.state.storage_global.currentSite.siteId,
                    period:'latest',
                    scanType:_scanType == "PPI"?0:_scanType == "FIX"?1:_scanType == "RHI"?2:_scanType == "Tri"?3:0,
                    success:(d)=>{                       
                        if(d.length == 0)  {
                            that.overlay = false;  
                            return;
                        }
                        that.overlay = false;                          
                        if(d.Data[0].DwsSiteId != that.$store.state.storage_global.currentSite.siteId)   {
                            return;
                        }
                        that.dataList = d.map(a=>{
                            return {
                                dataTime:a.DwrTime.replace('T',' '),
                                distanceData:a.DwrDis,
                                angle:a.DwrScanMode == 0 ? a.DwrStraight:a.DwrScanMode == 2?a.DwrPitch:a.DwrStraight,                        
                                scanMode:scanType[a.DwrScanMode],
                                straightAngle:a.DwrStraight,
                                angleOfPitch:a.DwrPitch,       
                                data:a.DwrValue,
                                period:a.DwrPeriod
                            }
                        });  
                        let phj= that.dataList[0].period.split('_');
                        if(phj.length>1)
                        {
                          that.yaw=Number(phj[1])%360;
                        }
                        if(that.dataList[0].scanMode == 1 || that.dataList[0].scanMode == 3){                            
                            if(that.heatmap){
                                that.loadHeatmap()
                            }                            
                        }else{
                            if(that.$refs.polar){
                                that.loadChart();    
                            }                    
                        }
                        if(that.$refs.line){
                            that.loadLine();                    
                        }                        
                    }
                })
            }            
        },
        async loadHeatmap(){
            var that=this;
            let target = this.$refs.heatmap;             
            if(!target){
                return
            }
            if(this.dataList.length==0){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
                var data = [ 
                    {
                    y: [],
                    x: [],
                    z: [],
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[]
                    }
                ];

                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                                
                    xaxis: {
                        color:fontColor, 
                        nticks:5      
                    },
                    yaxis: {
                        color:fontColor,  
                        title:"距离(km)"
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                    }
                };
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
                } 
                return
            }
     
            let _datas =[]; 
            let minSpan = 3;       
            for(let i in this.dataList.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.dataList[i].dataTime).getTime() - new Date(this.dataList[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){                                 
                            j+=minSpan;                     
                            _datas.push({
                                distanceData:this.dataList[i].distanceData,
                                angle:NaN,                        
                                scanMode:this.dataList[i].scanMode,
                                straightAngle:NaN,
                                angleOfPitch:this.dataList[i].angleOfPitch,       
                                data:Array.from({length:this.dataList[i].data.length},(val, i) => NaN),
                                period:this.dataList[i].period,                                
                                dataTime:new Date(new Date(this.dataList[i].dataTime).setMinutes(new Date(this.dataList[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),                                                                                                                   
                            })                                           
                        }
                    }
                }
            }
            _datas = _datas.concat(this.dataList)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = []; 
            let noDataLength =  -(datas[0].distanceData[0])/datas[0].distanceData[1]//无效距离    
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }          
            let xData = []; 
            let zData = [];
            let hoverText = [];
            
            datas.forEach((a,i)=>{    
                xData.push(new Date(a.dataTime))
                for(var j=0;j< yData.length;j++){
                    let _index = j+noDataLength
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }
                    zData[j].push(a.data[_index])
                    hoverText[j].push(`值：${a.data[_index]}<br />距离：${yData[j]}(Km)<br />水平角：${a.straightAngle}°<br />偏航角：${this.yaw}°<br />俯仰角：${a.angleOfPitch}°<br />时间：${new Date(a.dataTime).pattern("HH:mm:ss")}` )                
                }                 
            });              
            if(!this.$refs.colorBar){
                return;
            }
            
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            var data = [ 
                {
                y: yData,
                x: xData,
                z: zData,
                type: 'heatmap',           
                showscale: false,
                hovertext:hoverText,
                colorscale:this.$refs.colorBar.colorScaleValue,
                zmin:this.$refs.colorBar.rangeInfo[0].min,
                zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max,
                hoverinfo:'text'
                }
            ];

            var layout = {
                uirevision:'true',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                                
                xaxis: {
                    color:fontColor,  
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:5   
                },
                yaxis: {
                    color:fontColor, 
                    title:"距离(km)" 
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{                    
                    t:42,
                    r:42,
                    l:42,
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            } 
        },
        loadLine(){
            let target = this.$refs.line     
            if(this.currentMap.value != 2){
                return;
            }
            if(!target){
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                
                Plotly.newPlot(target, [{
                    type: 'line',               
                    y: [],    
                    x:[],                          
                    showlegend: false,
                    hovertext:[],
                    mode: 'lines',
                    name:'径向',                   
                }], {  
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,
                    legend: {
                        orientation:"h",
                        x: 1,
                        xanchor: 'right',
                        y: 1,
                        font:{
                            color: fontColor
                        }
                    },
                    xaxis: {  
                        color:fontColor,
                        nticks:20
                    },
                    yaxis: {
                        color:fontColor,
                        title:"距离(km)"
                    },
                    margin:{
                        t:48,
                        r:42,  
                        l:42,
                        b:42                        
                    }
                }, {responsive: true});
                return
            }
            if(this.dataList.length==0){
                return
            }
            let yData = [];       
            for(let i = 0;i< this.dataList[0].distanceData[2];i++){                
                let y = this.dataList[0].distanceData[0] + i* this.dataList[0].distanceData[1]
                yData.push(y.toFixed(2))
            }     
            let xData =  this.dataList[this.dataList.length -1].data;
            let data = [{
                type: 'line',               
                y: yData,    
                x:xData,                          
                showlegend: false,
                hovertext:[],
                mode: 'lines',
                name:'径向',                   
            }]
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
            var layout = { 
                hovermode:false,  
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1,
                    font:{
                        color: fontColor
                    }
                },
                xaxis: {  
                    color:fontColor,
                    nticks:20,
                    range:[-5,5]
                },
                yaxis: {
                    color:fontColor,
                    title:"距离(km)"
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:38,
                    r:42,  
                    l:42,
                    b:42                        
                }
            };                
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true});
                }                               
            }else{
                setTimeout(() => {
                    Plotly.newPlot(target, data, layout,{responsive: true});               
                }, 200);                
            } 
        },
        loadChart(){   
            let d = 3.3                   
            let yaw =this.$store.state.storage_global.currentSite.scanType == "RHI" || this.$store.state.storage_global.currentSite.scanType == 2
            ?0: this.$store.state.storage_global.currentSite.yaw;                             
            let target = this.$refs.polar
            if(!target){
                return
            }
            if(this.dataList.length == 0){        
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black                           
                Plotly.newPlot(target, [{
                    type: "scatterpolargl",
                    r: [],
                    theta: [],
                    text: [],
                    mode: "markers",
                    name: "",
                    marker: {
                        color: `rgba(0,0,0,0)`,
                        size: 3,                         
                        opacity: 0.7
                    },
                    cliponaxis: false
                    }], 
                    {  
                        plot_bgcolor: bgColor,
                        paper_bgcolor: bgColor,              
                        polar: {                    
                            radialaxis: {   
                                color:fontColor,                     
                                visible: true,
                                range: [0, d]
                            },
                            angularaxis: {
                                color:fontColor,
                                tickfont: {
            
                                },
                                direction: "clockwise"
                            }
                        },
                        showlegend: false,
                        margin:{
                            t:42,
                            r:32,  
                            l:32,
                            b:32                        
                        }
                    })
                return;
            }
            var that = this;
            let polarData = []
            let noDataLength =  (-this.dataList[0].distanceData[0])/this.dataList[0].distanceData[1]//无效距离
            let scanLength = this.dataList[0].distanceData[2]*this.dataList[0].distanceData[1]
            $help.drawScan(this.dataList,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{
                    let r = that.dataList[0].distanceData[0] + that.dataList[0].distanceData[1] *  obj.dIndex;
                    let pitch=that.dataList[0].angleOfPitch;
                    polarData.push({
                        color: `rgba(${color.join(',')})`,
                        r:r,
                        angle:obj.angle,
                        value:obj.value,
                        pitch:pitch
                    })                                   
                })
            },()=>{
                let group = $help.groupBy('color',polarData)
                let data = [];
                let that=this;
                for(var i in group){
                    let item = group[i]
                    data.push({
                        type: "scatterpolar",
                        r: item.map(a=>a.r.toFixed(2)),
                        theta: item.map(a=>a.angle.toFixed(2)),
                        text: item.map(a=>
                        `值：${a.value.toFixed(6)}<br />距离：${a.r.toFixed(2)}(Km)<br />水平角：${a.angle.toFixed(2)}°<br />俯仰角：${a.pitch}°`
                        ),
                        mode: "markers",
                        name: "",
                        marker: {
                            color: i,
                            size: 3,                         
                            opacity: 0.7
                        },
                        hoverinfo:'text',
                        cliponaxis: false
                    })
                }  
            let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black                                         
            let layout = { 
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,                 
                polar: {                    
                    radialaxis: {    
                        color:fontColor,                     
                        visible: true,
                        range: [0, d]
                    },
                    angularaxis: {
                        color:fontColor, 
                        tickfont: {
      
                        },
                        direction: "clockwise"
                    }
                },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                showlegend: false,
                margin:{
                    t:38,
                    r:56,  
                    l:56                        
                }
            }       
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout);  
                }else{
                    Plotly.react(target, data,layout);
                }                               
            }else{
                Plotly.newPlot(target, data, layout);               
            }      

            },noDataLength,scanLength,this.dataList[0].distanceData[2],{d:3.3,rp:50,yaw:yaw})                        
        },         
    }
}
</script>
<style scoped lang="scss">
.rowHeight{
    height:calc(100% - 40px);
    width: 100%;
}
.mapColorBarConfig{
    position: absolute;
    top: 16%;
    right: 5px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 70%;
}
.heatmapTitle{
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}
</style>
<template>
    <v-container ref="mibbox" class="monitorItemBox ma-0 pa-2 width100 height100" >        
        <v-card class="width100 height100" min-height="280"  min-width="420">    
            <v-card-actions class="mb-top">
                <v-row class="ma-0 pa-0 justify-space-between">
                    <v-menu open-on-hover offset-y bottom max-height="400" z-index="10">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn                            
                            class="ma-0 pa-0"                           
                            text                                                             
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon class="mx-1">mdi-map-marker-radius</v-icon>
                            {{currentSite.siteName}}{{currentSite.transStatus == 1 ? $i18n.t("layout.label.stop"):currentSite.commitStatus == 0 ? $i18n.t("layout.label.outline"):""}}
                            </v-btn>
                        </template>
                        <v-list shaped>
                            <v-list-item-group >
                                <v-list-item
                                    v-for="(item, index) in sites"
                                    :key="index" 
                                    :value="item"                                        
                                    @click="currentSite = item"
                                >
                                <v-list-item-title :class="{'grey--text':item.commitStatus == 0}" class="width100 d-flex justify-space-between">
                                    {{ item.siteName }}<v-spacer class="mx-2"></v-spacer>{{ item.transStatus == 1 ? $i18n.t("layout.label.stop"): item.commitStatus == 0 ? $i18n.t("layout.label.outline"):""}}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-menu open-on-hover offset-y bottom z-index="10">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn    
                            v-show="false"                                                          
                            v-bind="attrs"
                            v-on="on"
                            text
                            >
                            {{currentType.name}}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentType">
                                <v-list-item
                                    v-for="(item, index) in PPI_ScanTypes"
                                    :key="index" 
                                    :value="item"                                                    
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>              
                </v-row>
            </v-card-actions> 
            <div class="titleweather">
                <div class="weather" v-show="timeAndWeather">                
                    <div class="mx-1" title="风向" ><v-icon>mdi-windsock</v-icon>{{weatherInfo.windDirection}}</div>
                    <div class="mx-1" title="风力"><v-icon>mdi-weather-windy</v-icon>{{weatherInfo.windPower}}</div>            
                    <div class="mx-1" title="天气"><v-icon>{{icon(weatherInfo.weather)}}</v-icon>{{weatherInfo.weather}}</div> 
                    <div class="mx-0" v-show="alarmData.avgLevel" title="能见度报警(显示一小时之内最近的一次报警)"><v-icon>{{icon(alarmData.avgLevel)}}</v-icon>{{alarmData.avgLevel}}</div>                                 
                </div>
            <div class="timeText" v-show="timeAndWeather">{{timeText}}</div>   
            </div> 

            <div class="hotBtn">
                <v-switch title="显示天气报警"                    
                    v-model="timeAndWeather"
                    class="mt-0"
                    label="天气报警"
                    hide-details
                    dense>
                    </v-switch>
            </div>
            <div style="height: calc(100% - 48px ) ;">
                <v-row class="ma-0  px-2  height50 width100">
                    <Trendmap ref="trendMap" :currentSite="currentSite"></Trendmap>
                </v-row>
                <v-row  class="ma-0 pt-2 px-2  height50">
                <v-col class="pa-0" cols="12">
                    <Scanmap ref="scanmap" :currentSite="currentSite" :dataList="lidarDataPPI" v-if="currentScanType == 'PPI'"></Scanmap>
                    <RHI ref="rhi" :currentSite="currentSite" :dataList="lidarDataRHI" v-if="currentScanType == 'RHI'"></RHI>
                    <Heatmap ref="heatmap" :dataList="lidarDataFIX" :currentSite="currentSite"   v-if="currentScanType  == 'FIX'"></Heatmap>
                </v-col>
                </v-row>     
            </div>     
        </v-card>
    </v-container>
</template>

<script>
import transferDataType from '@/plugins/datas/transferDataType'
import Scanmap from '@/components/monitoring/vls/windowUnion/Scanmap'
import RHI from '@/components/monitoring/vls/windowUnion/RHI'
import Heatmap from '@/components/monitoring/vls/windowUnion/Heatmap'
import Trendmap from '@/components/monitoring/vls/windowUnion/Trendmap'
import weatherType from '@/plugins/datas/weatherType.json'
import scanType from '@/plugins/datas/scanType'
import {getVlsVizLatestApi,getVlsRcsByLatestApi,getVlsAlarmApi,getVlsVizApi } from '@/api' 
export default {
    name:"siteMonitorItem",
    components: {
        Scanmap,RHI,Trendmap,Heatmap,weatherType
    },
    props:{
        siteCount:{
            type:Number
        },
        sites:{
            type:Array
        },        
        site:{
            type:Object
        },
        types:{
            type:Array
        }
    },
    data(){
        return {
            currentScanType:"PPI",
            timeAndWeather:true,//天气时间
            timeText:"",   
            lidarDataPPI:[],
            lidarDataFIX:[],
            lidarDataRHI:[],
            currentSite:{
              siteName:"未选中",
              commitStatus:0,
            },        
            weatherInfo:{
            },          
            //按钮
            currentType:{
                name:"没有可选类型",
                value:"undeifined",
                key:""
            },
            PPI_ScanTypes:[],//水平类型
            //注：大雾等级:   1 - 10 km为轻雾，等级1
            //0.5 - 1 km为大雾，等级2
            //0.2 - 0.5 km为浓雾，等级3
            //0.05 - 0.2 km为强浓雾，等级4
            //0.00 - 0.05 km为特强浓雾，等级5
            //结果用数字等级表示，界面展示用 “轻雾” 等标注 
            levelConfig:{
                0:"",
                1:"轻雾",
                2:"大雾",
                3:"浓雾",
                4:"强浓雾",
                5:"特强浓雾"
            },            
            ScanTypeConfig:{
                0:"PPI",
                1:"FIX",
                2:"RHI",
                3:"UPI",
                4:"MFX"
            },
            alarmData:{
                time:"",
                avgLevel:""
        },
        }
    },
    watch:{
        site:{
            handler(n){             
                this.currentSite = n;                
            }
        },                 
        siteCount(n){           
            console.log(n);
            var that = this;
            setTimeout(() => {
                that.$refs.trendMap.loadChart()
            }, 200);                        
        },
        currentSite:{
            handler(n){  
                this.loadWeather();
                this.LoadAlarm();
                if(!n.scanType){
                    this.LoadScanType();
                }                    
                else
                {
                    this.currentScanType=n.scanType;
                    this.load();
                }   
                this.watchData(n.siteId)                   
            }
        }           
    },
    computed:{
        icon:()=>{
            return(key)=>{
                var item = weatherType.find(a=>a.name == key);
                if(item){
                    return item.value
                }else{
                    return 'mdi-biohazard'
                }
            }
        }      
    },
    mounted(){
        this.currentSite = this.site; 
        let that = this;  
        if(!this.currentSite.scanType)
            this.LoadScanType();
        else
        {
            this.currentScanType=this.currentSite.scanType;
            this.load();
        }        
        setTimeout(() => {
            that.watchData(that.currentSite.siteId)
        }, 200);     
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = that.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{    
                if(!n.jsonData){
                    return;
                }                       
                if(n.SiteId == that.currentSite?.siteId){
                    var json = JSON.parse(JSON.stringify(n.jsonData))  
                    if(json[0]?.scanType){
                        that.currentScanType=json[0]?.scanType; 
                    }
                    setTimeout(() => {
                        if(n.DataType == transferDataType.vizAlarm.value){                                                                            
                            let tmpData =json.map(a=>{
                                return{
                                    time:a.dataTime,
                                    avgLevel:that.levelConfig[a.periodLevel]
                                }
                            })[0];                    
                            that.alarmData.time =tmpData?.time;
                            that.alarmData.avgLevel=tmpData?.avgLevel;                                                                                     
                        } 
                        //VIZ 支持PPI 与 FIX 支持更新扫描方式
                        if( n.DataType == transferDataType.viz.value && json[0].scanType == "PPI"){                                                                    
                            that.lidarDataPPI = json.sort((a,b)=>{
                                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
                            });                                        
                            let period =json[0].period?.split('_')[0]       
                            that.timeText = $help.periodToTimeStr(period)                                        
                        } 
                        if( n.DataType == transferDataType.viz.value && json[0].scanType == "FIX"){                              
                            that.currentScanType=json[0]?.scanType; 
                            let lidarDataFIX = that.lidarDataFIX
                            if(that.lidarDataFIX.length == 0){
                                that.load(json)
                            }else{                                                                                
                                let period =json[0].period?.split('_')[0]       
                                that.timeText = $help.periodToTimeStr(period)                             
                                let len = lidarDataFIX.length-json.length                    
                                lidarDataFIX = json.sort((a,b)=>{
                                    return new Date(b.dataTime.replace("T"," ")).getTime() - new Date(a.dataTime.replace("T"," ")).getTime()
                                }).concat(lidarDataFIX)    
                                lidarDataFIX = lidarDataFIX.slice(0,len)  
                                that.lidarDataFIX = lidarDataFIX;
                            }                                        
                        } 
                        //RCS 支持 RHI   更新扫描方式
                        if( n.DataType == transferDataType.rcs.value  && json[0].scanType == "RHI"){                                            
                            that.lidarDataRHI = json.sort((a,b)=>{
                                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
                            });                       
                            let period =json[0].period?.split('_')[0]       
                            that.timeText = $help.periodToTimeStr(period)                                                                        
                        }      
                    }, 200);                        
                }                              
            }, {immediate: true })
        },     
        loadWeather(){        
            let site = this.currentSite;       
            var that = this;
          
             //获取天气情况
             if(!site.weatherInfo || (site.weatherUpdateTime.getTime() - new Date().getTime())/1000/60/60 > 1){
                //获取当前站点天气                                        
                getWeather(site.district,(data)=>{
                    let weatherInfo = {
                        position:data.city,
                        windPower:data.windPower,
                        temperature:data.temperature,
                        windDirection:data.windDirection,
                        humidity:data.humidity,
                        weather:data.weather
                    }
                  
                    that.$store.commit('storage_user/updateSiteWeather',{
                        siteId:site.siteId,
                        weatherInfo:weatherInfo
                    },{root:true})   
                    that.weatherInfo = {
                        position:data.city,
                        windPower:data.windPower,
                        temperature:data.temperature,
                        windDirection:data.windDirection,
                        humidity:data.humidity,
                        weather:data.weather
                    }                                             
                }) 
            }else{                               
                that.weatherInfo = {
                    ...site.weatherInfo
                }                
            }          
        },
        LoadAlarm(){            
            var that = this;
            getVlsAlarmApi({
                id:that.currentSite.siteId,
                params:{
                    Latest:true,
                    PageSize:1,
                    PageIndex:1                     
                },
                success:(d)=>{      
                   let tmpData = d.Data.map(a=>{
                        return{
                            time:new Date(a.DavTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            minAngle:a.DavScanMode == 0 ? a.DavStraight:a.DavPitch,
                            avgValue:a.DavPeriodValue,
                            minValue:a.DavValue,
                            avgLevel:that.levelConfig[a.DavPeriodLevel],
                            minLevel:that.levelConfig[a.DavLevel]
                        }
                    })[0];  
                    that.alarmData.time =tmpData?.time;
                    let diff=(new Date()-new Date(tmpData?.time))/1000/60;
                    //console.log(  that.alarmData.time  +"  "  +diff);
                    //60分钟
                    if(diff<=60)
                    {
                        that.alarmData.avgLevel=tmpData?.avgLevel;
                    }
                    else
                    {
                        that.alarmData.avgLevel="";
                    }                                          
                }       
            })   
        },
        LoadScanType(){
            var that = this; 
            this.$store.dispatch('storage_user/getLastestScanType',{
                site:that.currentSite,
                cb:(obj)=>{          
                    if(obj.siteId == that.currentSite.siteId){
                        that.currentScanType = obj.scanType
                    }
                    that.load();  
                }
            })
        },
        async load(datas){
            let that = this;
            if(this.timer)         {
                clearTimeout(this.timer)
            }
            this.timer =setTimeout(() => {
                console.log(that.currentScanType )
                if(that.currentScanType == "PPI" || that.currentScanType == "ppi" ){    
                    that.loadDataPPI()
                }else if(that.currentScanType == "FIX" || that.currentScanType == "fix"){
                    that.loadDataFIX(datas)
                }else{
                    that.loadDataRcs()
                }
            },300)
        },
        loadDataFIX(datas){            
            let siteId = this.currentSite.siteId;            
            let start = new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 1)).pattern('yyyy-MM-dd HH:mm:ss');
            let end = new Date().pattern('yyyy-MM-dd HH:mm:ss')
            this.loadData(start,end,siteId,datas)                 
        },    
        loadData(start,end,siteId,datas = [],time){  
            var that = this;  
            this.$refs.heatmap.overlay = true;      
            getVlsVizApi({
                id:siteId,                  
                params:{
                    LastDate:time,
                    PageSize:1000,                        
                    StartTime:start,
                    EndTime:end,
                    ScanMode:1,
                    LastDate:time
                },success:(d)=>{ 
                    if(!d){
                        this.$refs.heatmap.overlay = false;
                    }                          
                    let _datas = d.map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: a.DvvTime.replace("T"," "),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }})   
                    datas = _datas.concat(datas)     
                    if(new Date(d[d.length -1].DvvTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.length < 1000){                                   
                        that.$refs.heatmap.overlay = false;
                        that.lidarDataFIX = datas; 
                        let period =datas[0].period?.split('_')[0]       
                        that.timeText = $help.periodToTimeStr(period)
                    }else{                              
                        that.lidarDataFIX = datas;              
                        that.loadData(start,end,siteId,datas,d[d.length -1].DvvTime)                                  
                    }    
                }
            })                       
        },      
        loadDataPPI(){         
            var that = this; 
            getVlsVizLatestApi({
                    id:that.currentSite.siteId,
                    params:{                    
                    },
                success:(d)=>{     
                    that.lidarDataPPI = d.filter(a=>a.DvvScanMode ==0).map(a=>{
                        return {
                            angleOfPitch: a.DvvPitch,
                            data:a.DvvValue,                                                    
                            dataTime: a.DvvTime.replace("T"," "),
                            dataType:transferDataType.viz,
                            distanceData: a.DvvDis,
                            period:a.DvvPeriod,
                            siteId: a.DvvSiteId,
                            scanType:scanType[a.DvvScanMode],
                            straightAngle:a.DvvStraight
                        }
                    }) 
                    let period =that.lidarDataPPI[0].period?.split('_')[0]       
                    that.timeText = $help.periodToTimeStr(period)                                
                }
            })           
        },
        loadDataRcs(){     
            var that = this; 
            this.$refs.rhi.overlay = true;  
            getVlsRcsByLatestApi({
                id:that.currentSite.siteId,               
                success:(d)=>{   
                    this.$refs.rhi.overlay = false;           
                    that.lidarDataRHI = d.map(a=>{
                        return {
                            angleOfPitch: a.DvrPitch,
                            data:a.DvrValue,                                                    
                            dataTime: new Date(a.DvrTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                            dataType:transferDataType.rcs,
                            distanceData: a.DvrDis,
                            period:a.DvrPeriod,
                            siteId: a.DvrSiteId,
                            scanType:scanType[a.DvrScanMode],
                            straightAngle:a.DvrStraight
                        }
                    })  
                    let period =that.lidarDataRHI[0].period?.split('_')[0]       
                    that.timeText = $help.periodToTimeStr(period)        
                }
            })          
        },
   
    }
}
</script>

<style scoped lang="scss">
.titleweather{
    position: absolute;
    min-width: 400px;
    top: calc(50% + 62px);
    left: 20px;
}
.timeText{
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 5px;
 }
 .hotBtn{
        position: absolute;
        z-index: 1;
        bottom: 5px;
        right: 65px;
 }

    .monitorItemBox{
        // width: fit-content !important;
    }
    .mb-top{
        height: 38px;
    } 
    .weather{
        position: absolute;
        display: flex;
        z-index: 1;
        left: 0px
    }

      
  path{
    fill:var(--v-rTheme-base);
  }
  

</style>
<template>
    <v-container class="contain ma-0 pa-0">               
        <v-card height="100%" style="overflow: auto;">     
            <v-card-actions class="mapToolbar mx-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col class="pa-0 pl-10 ma-1">                                           
                        <v-menu open-on-click offset-y nudge-left="140" bottom max-height="400" z-index="10" :close-on-content-click='false'>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn    
                                class="ma-0 pl-2 pr-4 ml-10"                                                                                                                            
                                v-bind="attrs"
                                v-on="on"
                                ><v-icon class="mx-1">mdi-map-marker-radius</v-icon>
                                <div class="selectSitesText">     
                                    选择站点
                                </div>                                                          
                                </v-btn>
                            </template>
                            <v-card>
                                <v-sheet class="pa-4 primary">
                                    <v-text-field
                                        v-model="search"
                                        label="搜索"
                                        dark
                                        flat
                                        solo-inverted
                                        hide-details
                                        dense
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                    <v-checkbox
                                        dark
                                        @click="selectAll"
                                        :input-value="checkedAll"
                                        dense
                                        hide-details
                                        label="全选"
                                    ></v-checkbox>
                                    </v-sheet>
                                <v-treeview
                                    item-text="siteName"
                                    item-key="siteId"
                                    multiple-active
                                    selectable
                                    dense
                                    selected-color="primary"
                                    :items="sites"
                                    :search="search"
                                    :filter="filter"
                                    return-object
                                    @input="selectSite"
                                    :value="selectSites"
                                ><template v-slot:append="{ item, open }">
                                    <v-icon v-if="!item.children" class="pointer" color="primary" @click="setLocal(item)">
                                        mdi-map-marker
                                    </v-icon>
                                    </template>
                                </v-treeview>
                            </v-card>                                                                                                             
                        </v-menu>                                                                   
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-row style="position: relative;height: 60%;" class="ma-0  px-2 pt-3  width100">
                <div class="hotMapColorBar">
                    公里(km)
                    <div :style="`background-color:${i.color}`" v-for="i in levelConfig" class="hotMapColorBarBox" :key="i.key">
                        <div class="hotMapColorBarText" >{{i.max}} [{{i.text}}]</div>
                    </div>
                </div>       
                <Trendmap ref="trendmap" :selectSites="selectSites"></Trendmap>
            </v-row>
            <v-row  class="ma-0 py-1 px-2" style="height: 60%;">
                <Scanmap ref="scanMap" :selectSites="selectSites" ></Scanmap>             
            </v-row>        
        </v-card> 
        <v-expand-transition>
            <v-btn v-show="!showWarnList" class="mx-2 warnBtn pointer" @click="showWarnList=!showWarnList" fab large color="rgba(0,0,0,0)" >
                <v-icon title="还原窗口" color="primary" size="48px">mdi-android</v-icon>
            </v-btn>
        </v-expand-transition>
        <v-expand-transition origin="bottom bottom">
            <div class="warnInfo" v-show="showWarnList">
                <v-icon style="width: 20px;position: relative;top: 30px;z-index: inherit;left: calc(100% - 50px);" title="最小化" @click="showWarnList=!showWarnList">mdi-chevron-down-circle</v-icon>
                <AlarmTable @clickRow="clickRow" :selectSites="selectSites"></AlarmTable>
            </div>
        </v-expand-transition>    
    </v-container>
</template>
<script>
import Scanmap from '@/components/mobile/monitoring/vls/siteUnion/Scanmap'
import Trendmap from '@/components/mobile/monitoring/vls/siteUnion/Trendmap'
import AlarmTable from '@/components/mobile/monitoring/vls/siteUnion/AlarmTable'
export default {
    name:"monitoring.vls.siteUnion",
    components:{Trendmap,AlarmTable,Scanmap},
    data(){
        return {                    
            selectSites:[],//选中的站点id
            showWarnList:true,
            levelConfig:[                
                {
                    key:1,
                    min:1,
                    max:10,
                    text:"轻雾",
                    color:'rgb(202, 251, 235)'
                },
                {
                    key:2,
                    min:0.5,
                    max:1,
                    text:"大雾",
                    color:'rgb(104, 253, 250)'
                },
                {
                    key:3,
                    min:0.2,
                    max:0.5,
                    text:"浓雾",
                    color:'rgb(4, 204, 203)'
                },
                {
                    key:4,
                    min:0.05,
                    max:0.2,
                    text:"强浓雾",
                    color:'rgb(5, 152, 151)'
                },
                {
                    key:5,
                    min:0,
                    max:0.05,
                    text:"特强浓雾",
                    color:'rgb(6, 82, 72)'
                }
            ],
            search:""
        }
    },
    computed:{
        sites(){
            var sites = []
            this.$store.dispatch("storage_global/getSitesByCurrentType",(d)=>{
                sites= d;
            },{root:true}); 
            var _siteList = $help.groupBy("province",sites);
            var siteList = [];
            for(var i in _siteList){
                // siteList.push({
                //     key:i,
                //     sites:_siteList[i]
                // })
                siteList.push(
                    {
                        siteId:i,
                        siteName:i,
                        children:_siteList[i]
                    }
                )
            }                       
            return siteList;
        },
        checkedAll(){
            let selectSites = [];
            this.sites.forEach(a=>{
                selectSites = selectSites.concat(a.children)
            })
            return selectSites.length == this.selectSites.length 
        },
        filter () {
            return (item, search, textKey) => item[textKey].indexOf(search) > -1
        }       
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                
            }
        },        
        selectSites:{
            handler(n){   
                if(this.loadOver&& this.map){                    
                    this.resetSiteMaker(n);   
                }                
            }
        },        
        "$store.state.storage_global.currentType":{
            handler(n){ 
                var that= this;  
                this.$store.dispatch("storage_global/getSitesByCurrentType",(d)=>{                     
                    that.selectSites = d;                  
                },{root:true});
            }
        }        
    },
    beforeDestroy(){
        
    },
    mounted(){     
        var that = this;         
        this.$nextTick(()=>{
            let sites = [];
            this.$store.dispatch("storage_global/getSitesByCurrentType",(d)=>{
                sites= d; 
                that.selectSites = sites;                                                                    
            },{root:true});
             
        })                                         
    },
    methods:{        
        selectSite(sites){
            if(!this.changeSiteType){
                this.selectSites = sites;
            }            
        },
        selectAll(){
            let selectSites = [];
            this.sites.forEach(a=>{
                selectSites = selectSites.concat(a.children)
            })
            if(this.selectSites.length == selectSites.length){
                this.selectSites= []
            }else{                
                this.selectSites = selectSites;
            }
        },
        setLocal(item){
            this.$refs.scanMap.setLocal(item)
        },
        clickRow(siteId,time,color){
            this.$refs.trendmap.clickTime(siteId,time,color)
        }        
    }
}
</script>
<style scoped lang="scss">
.contain{
    max-width: 100%;
    overflow: auto;
}
.window{
    width: 100%;
    height: 100%;
}
.map{
    width: 100%;
    height: 100%;
}
.selectSitesText{
    max-width: 520px;   
    width: 100%;
    display: flex;
    align-items: center;
    :deep(.v-chip__content){
        align-items: center;
        line-height: 2;
        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;        
    }
}
.selectSitesText::-webkit-scrollbar {
      /*滚动条整体样式*/
    width : 4px !important;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px !important;
    }
.selectSitesText::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius   : 2.5px !important;
    background-color: #336de3eb !important;
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
    }
.selectSitesText::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    background   : #ededed;
    border-radius: 5px;
    }
.configBtns{
    position: absolute;
    left: 50px;
    bottom: 360px;
}

.weatherinfo{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 30px;
    bottom: 90px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 10px;
  padding: 3px 10px;
  border-radius: 5px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.mapToolbar{
    position: absolute;
    z-index: 3;
    left: 140px;
    width: 120px;
}

//热图


.mapColorBarConfig{
    position: absolute;
    top: 360px;
    right: 25px;
    width: 30px;
    height: 30px;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}
:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}

:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}
.hotMapColorBar{
    z-index: 2;
    left: 5%;
    width: 90%;
    font-size: 12px;
    display: flex;    
    position:absolute;
    top: calc(100% - 30px);
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;  
    top: 10px;  
}
.hotMapColorBarBox{
    width:100%;
    height: 10px;
}
.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 2;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    height: 60%;
    width: 100%;
    z-index: 2;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}
</style>
<template>
   <v-container class="d-flex flex-column container ">
        <v-card :elevation='0' class="cardFrom">            
            <v-form
                class="ma-6"
                ref="form"
                v-model="form.valid"                            
                >                               
                <v-row align="end" justify="space-between">
                    <v-card width="60%" elevation="0">
                         <v-row class="ma-0">
                            <v-text-field
                                v-model="form.body.CtName"                                
                                :label="$i18n.t('caseEdit.label.form.title')"
                                required
                                :rules="rules.title"
                            ></v-text-field>  
                        </v-row>
                        <v-row class="ma-0">
                            <v-file-input :rules="rules.cover"  accept="image/*" small-chips v-model="coverFile" prepend-icon="mdi-file-image" :label="$i18n.t('caseEdit.label.form.cover')"></v-file-input>
                        </v-row>                                                                         
                    </v-card>
                    <!-- <label for="input-151" class="v-label mb-4" :class="$vuetify.theme.isDark ? 'theme--dark':'theme--light'">{{$i18n.t('caseEdit.label.form.cover')}}</label> -->                    
                    <v-card class="caseCard flex-wrap flex-grow-0 flex-shrink-0 ma-4 pa-2">
                        <input ref="imgUpload" type='file' accept="image/*" hidden @change="uploadFile('load')" />
                        <v-img
                            @click="uploadFile('up')"
                            :src="cover"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="232px"
                            width="232px"
                        >
                        <v-card-title class="onlytext2" v-text="form.body.CiTitle"></v-card-title>
                        </v-img> 
                        <v-card-text class="pa-2 " style="width:232px">                           
                        </v-card-text>
                        
                        <v-card-actions class="justify-end">                           
                        </v-card-actions>            
                    </v-card>                                
                </v-row>                    
                <v-row>
                    <Editor :tinymceScriptSrc='url' :init="editConfig" :initialValue="form.body.CtContent" v-model="form.body.CtContent" apiKey="isss3pi0uzaqpoowqf3e1ta7svhbmfm3hoy5ejh863kukv5p"></Editor>
                </v-row>                                                                                                                                                                                         
            </v-form>
        </v-card>
        <v-row class="ma-2 d-flex justify-end">
            <v-btn color="primary" @click="save" class="ma-2"><v-icon>mdi-content-save</v-icon>{{$i18n.t('caseEdit.btn.save')}}</v-btn>
            <v-btn color="secondry" @click="$router.history.go(-1)" class="ma-2"><v-icon>mdi-close-circle-outline</v-icon>{{$i18n.t('caseEdit.btn.cancel')}}</v-btn>
        </v-row>    
    </v-container> 
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import {upLoadApi,addCaseTemplateApi,getCaseTemplateApi,editCaseTemplateApi} from '@/api'
import { uploadMinioURL } from '@/api/url'
export default {
    components:{Editor},
    name:"case.caseTemplate.edit",
    computed:{
        url(){
            return process.env.BASE_URL+ "tinymce/tinymce.min.js"
        }
    },
    data(){
        var that = this;
        return {
        form:{
            valid:false,            
            body:{
                CtName:"",
                CtContent:"",                    
                //附件
                CaseTemplateAttachments:[]
            }                  
        },
        rules:{
            title:[ v => !!v || this.$i18n.t("caseEdit.label.rule.title"),
                    v =>(v && v.length <= 64)|| this.$i18n.t("caseEdit.label.rule.titleLength"),],
            article:[v => !!v || this.$i18n.t("caseEdit.label.rule.article")],
            cover:[v => true || this.$i18n.t("caseEdit.label.rule.cover")]
        },
        cover:"",//附件临时地址
        coverFile:null,//附件
        editorFiles:[],
        templates:[],        
        editConfig:{
            height: 500,
            menubar: false,
            toolbar_mode:"wrap",//工具栏按钮设置floating 多余的收进... 浮动在页面  / sliding 多余的收进... 点击撑开  / scrolling 多余滚动 / wrap
            //language_url:"../../../assets/langs/zh_CN.js",
            language: 'zh_CN',
            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
            branding: false,
            contextmenu: "bold copy",
            images_upload_base_path:uploadMinioURL,
            paste_data_images: true,//拖拽支持
            //images_file_types: 'jpeg,jpg,png,gif,bmp,webp',//拖拽支持

            font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
            plugins: [
                'advlist autolink lists link image imagetools charmap',
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table hr visualchars',
                'nonbreaking'
            ],
            toolbar:
                `code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link unlink openlink anchor |
                alignleft aligncenter alignright alignjustify alignnone outdent indent | 
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | 
                table image rotateleft media charmap emoticons hr pagebreak insertdatetime print preview |searchreplace fullscreen | bdmap indent2em lineheight formatpainter axupimgs |
                newdocument visualblocks visualchars nonbreaking`,
                //images_reuse_filename:true,//是否重命名为唯一文件名
            images_upload_handler:function (blobInfo, succFun, failFun,progress) { 
                var url = URL.createObjectURL(blobInfo.blob()) 
                var ext = blobInfo.filename().replace(blobInfo.name(),"");   
                let filename = $help.guid()+'.'+ext;
                that.editorFiles.push({
                    url:url,
                    file:blobInfo.blob(),
                    fileName:filename
                })   
                succFun(url)       
                // var ext = blobInfo.filename().replace(blobInfo.name(),"");                
                // let filename = $help.guid()+'.'+ext;
                // let timer = new Date().pattern("yyyyMM")                                  
                // upLoadApi({
                //     url:'/1/case?objectName=editor/image/'+ timer +'/'+filename,
                //     files:blobInfo.blob(),
                //     success:(d)=>{
                //         succFun(`${d}case/editor/image/${timer}/${filename}`)   
                //         that.form.body.CaseAttachmentRelas.push({
                //             CarBucketName:"case",
                //             CarObjectName:'editor/image/'+ timer +'/'+filename
                //         })                     
                //     }
                // })
            },//图片拖拽粘贴上传
            file_picker_types: 'file image media',//上传格式
            file_picker_callback:function (callback, value, meta) {           
                var input =document.createElement("input");
                input.setAttribute("type","file")
                switch(meta.filetype){
                    case "image":
                        input.setAttribute("accept",".bmp,.jpg,.png,.tif,.gif,.pcx,.tga,.exif,.fpx,.svg,.psd,.cdr,.pcd,.dxf,.ufo,.eps,.ai,.raw,.WMF,.webp,.avif,.apng")
                        break;
                    case "media":
                        input.setAttribute("accept",".wav,.mp1,.mp2,.mp3,.mp4,.wma,.ogg,.ape,.aac,.avi,.navi,.dvavi,.asf,.asx,.rm,.rmvb,.3gp,.mov,.m4v,.mkv,.flv,.vob,.mpeg,.mpg,.mod,.opus,.flac,.ape,.alac    ")
                        break;                
                }
                input.click()
                input.onchange = function() {
                    let file = this.files[0]
                    let url = URL.createObjectURL(file)
                    let ext = $help.getFileExtension(file.name); 
                    let filename = $help.guid()+'.'+ext;
                    that.editorFiles.push({
                        url:url,
                        file:file,
                        fileName:filename
                    })     
                    callback(url)
                   
                   
                    // let file= this.files[0];                          
                    // let ext = $help.getFileExtension(file.name);                    
                    // let filename = $help.guid()+'.'+ext;
                    // let timer = new Date().pattern("yyyyMM") 
                    // upLoadApi(
                    // {
                    //     url:'/1/case?objectName=editor/image/'+timer+'/'+filename,
                    //     files:file,
                    //     success:(d)=>{
                    //         succFun(`${d}case/editor/image/${timer}/${filename}`)  
                    //         that.form.body.CaseAttachmentRelas.push({
                    //         CarBucketName:"case",
                    //         CarObjectName:'editor/image/'+timer+'/'+filename
                    //     })                        
                    //     }
                    // })
                }
            }
        }
    }
    },    
    watch:{
        coverFile:{
            handler(file){     
                if(file){
                    var url = window.URL.createObjectURL(file); // 得到bolb对象路径，可当成普通的文件路径一样使用，赋值给src;                    
                    this.cover = url;
                }                
            },
            deep:true
        }        
    },
    mounted(){
        this.load()      
    },
    methods:{
        load(){            
            if(this.$route.query.id){
                var that = this;
                 getCaseTemplateApi({
                    id:this.$route.query.id,
                    success:(d)=>{                                            
                        that.form.body = {
                            CtId:d.CtId,
                            CtName:d.CtName,                          
                            CtContent:d.CtContent,            
                            CtModifyTime:d.CtModifyTime,
                            //附件
                            CaseTemplateAttachments:d.CaseTemplateAttachments
                        }
                        var rela = d.CaseTemplateAttachments.find(a=>{
                            return a.CtarObjectName.indexOf('/cover/image/') >=0
                        });
                        that.cover =uploadMinioURL +rela?.CtarBucketName + rela?.CtarObjectName
                        
                    }
                 })
            }
        },        
        uploadFile(type){
            if(type == 'up'){                
                this.$refs.imgUpload.click();
            }
            if(type == 'load'){
                let file = this.$refs.imgUpload.files[0]                
                if(file){            
                    if((file.type).indexOf("image/")==-1){  
                        openNotice({
                            type:"error",
                            text:that.$i18n.t("caseEdit.notice.notImg")
                        })
                        return
                    }                      
                    this.coverFile = file;
                }
                else{
                    this.cover = "";
                    this.coverFile = "";
                }
            }
        },
        save(){
            var that = this;            
            var rel = this.$refs.form.validate();                    
            if(rel){
                //去除删掉的文件
                let relaFliles = []
                for(var i of this.form.body.CaseTemplateAttachments){
                    if(i.CtarObjectName.indexOf('cover') >=0){
                        //封面判断
                        if(this.cover.indexOf(i.CtarObjectName) >=0){
                            //封面没换
                            relaFliles.push({
                                CtarObjectName:i.CtarObjectName,
                                CtarBucketName:i.CtarBucketName
                            })
                        }

                    }else{
                        //编辑器文件
                        if(this.form.body.CtContent.indexOf(i.CtarObjectName) >=0 ){
                            //文件还在
                             relaFliles.push({
                                CtarObjectName:i.CtarObjectName,
                                CtarBucketName:i.CtarBucketName
                            })
                        }

                    }

                }
                this.form.body.CaseTemplateAttachments = relaFliles;
                //查找需要保存的文件            
                let timer = new Date().pattern("yyyyMM")
                let article = this.form.body.CtContent
                for(var i of this.editorFiles){
                    if(this.form.body.CtContent.indexOf(i.url) >=0){  
                        that.form.body.CaseTemplateAttachments.push({
                            CtarBucketName:"template",
                            CtarObjectName:'/editor/image/'+timer+'/'+i.fileName
                        }) 
                        article = article.replaceAll(i.url,`${uploadMinioURL}template/editor/image/${timer}/${i.fileName}`) 
                        //上传                
                        upLoadApi(
                        {
                            url:'/1/template?objectName=/editor/image/'+timer+'/'+i.fileName,
                            files:i.file,
                            success:(d)=>{
                                //succFun(`${d}case/editor/image/${timer}/${filename}`)                                            
                            }
                        })
                    }
                }    

                if(!this.cover){
                    openNotice({
                        type:"error",
                        text:that.$i18n.t("caseEdit.notice.cover")
                    })
                    return;
                }
                if(this.form.body.CtContent.length == 0){
                    openNotice({
                        type:"error",
                        text:that.$i18n.t("caseEdit.notice.article")
                    })
                    return;
                }
                if(this.coverFile){
                    let ext = $help.getFileExtension(this.coverFile.name);                    
                    let filename = $help.guid()+'.'+ext;
                    let timer = new Date().pattern("yyyyMM") 
                    upLoadApi(
                    {
                        url:'/1/template?objectName=/cover/image/'+timer+'/'+filename,
                        files:this.coverFile,
                        success:(d)=>{        
                            that.form.body.CaseTemplateAttachments.push({
                                CtarBucketName:"template",
                                CtarObjectName:`/cover/image/${timer}/${filename}` 
                            })   
                            that.submit(article)                               
                            
                        }
                    })  
                }else{
                    that.submit(article)  
                }                             
            }            
        },
        submit(article){
            var that = this;
            if(this.form.body.CtId){
                editCaseTemplateApi({
                    id:this.form.body.CtId,
                    data:{
                        CtId:this.form.body.CtId,
                        CtName:this.form.body.CtName,
                        CtContent:article,                                    
                        //附件
                        CaseTemplateAttachments:this.form.body.CaseTemplateAttachments
                    },
                    success:()=>{
                        openNotice({
                            type:"success",
                            text:that.$i18n.t("caseEdit.notice.editSuccess")
                        })
                        that.$router.push({path:'/case/caseTemplate/index'})
                    }
                })
            }else{
                addCaseTemplateApi({
                    data:{                        
                        CtName:this.form.body.CtName,
                        CtContent:article,
                        //附件
                        CaseTemplateAttachments:this.form.body.CaseTemplateAttachments
                    },
                    success:()=>{
                        openNotice({
                            type:"success",
                            text:that.$i18n.t("caseEdit.notice.addSuccess")
                        })
                        that.$router.push({path:'/case/caseTemplate/index'})
                    }
                })
            }
        }
    }
}
</script>
<style scoped lang="scss">
.cardFrom{
    height: 100%;   
    overflow: auto;
}
:deep(.templateDialog){
    max-height: 600px !important;
    overflow: auto;
}
.templateCard{
    height: 400px;
    overflow: auto;
}
:deep(.caseCard .v-responsive__content){
    height: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.authtext{
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}
.onlytext2{
    height: 34%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
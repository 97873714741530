import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/plugins/lang/i18n'
import zhHans from 'vuetify/lib/locale/zh-Hans'
import '@/assets/mdi/css/materialdesignicons.css' 

Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang:{
    locales: { zhHans},
    current: 'zhHans',
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'mdiSvg', // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {    
      "light": {
          "primary": "#007BFF",
          "secondary": "#19a600",
          "accent": "#0034a6",
          "error": "#ffaa00",
          "warning": "#f54f36",
          "info": "#006ca6",
          "success": "#4cb056",
          "white": "#F5F5F5",
          "black": "#1C1C1C",
          rTheme:'#1c1c1c',
          fTheme:"#F7F7F7"
      },
      "dark": {
          "primary": "#003773",
          "secondary": "#117300",
          "accent": "#002473",
          "error": "#734d00",
          "warning": "#732519",
          "info": "#004b73",
          "success": "#317338",
          "white": "#F5F5F5",
          "black": "#1C1C1C",
          fTheme:'#1c1c1c',
          rTheme:"#F7F7F7"
      } 
    },
  },
});
export { vuetify, i18n }

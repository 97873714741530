<template>
    <v-container style="max-width: 100%;position: relative;" class="width100 height100">
        <v-overlay
          absolute
          :value="overlay"
          class="ma-3"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            class="ma-6 mt-11"
                absolute
                :value="noData"
                opacity="0.2"
                >
            暂无数据
            </v-overlay>
        <ColorBarConfig @update="timerDraw" :dataKey="52"  ref="colorBar"  class="mapColorBarConfig" />       
        <v-card height="100%" width="100%" elevation="1"> 
            <v-card-title class="ma-0 pa-1 heatmapTitle">矢量风</v-card-title>
            <v-card-title class="ma-0 pa-1 btns">                    
                <v-icon @click="play('pre')" title="上一帧">mdi-skip-previous</v-icon>                                        
                <v-icon @click="play('next')" title="下一帧">mdi-skip-next</v-icon>                                                              
            </v-card-title>  
            <div class="mx-1" style="position:absolute;top:32px;z-index: 2;">{{time}} 偏航角:{{yaw}}°</div>                             
            <div ref="chart" style="height:calc(100% - 32px);top: 32px;position: relative;" class="width100 plotychart">
            </div>
        </v-card>                      
    </v-container>
</template>
<script>
import { getWlsQuiverByTimeApi } from '@/api' 
import Plotly  from 'plotly.js-dist';
import scanType from '@/plugins/datas/scanType'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import transferDataType from '@/plugins/datas/transferDataType'
import quiver from '@/plugins/helper/D3DrawHelper/quiver';
export default {
    name:'wls.realtime.quiver',
    props:{
        timeRange:{
            type:Array
        },
        currentScanType:{
            type:String
        }
    },
    components: {
        ColorBarConfig
    },
    data(){
        return {
            overlay:false,
            dataQuiver:[],
            dataQuiverBack:[],
            nextPeriod:'',
            prePeriod:'',
            quiverbkScale:1,
            timer:null,
        }
    },
    watch:{
        timeRange:{
            handler(n){                        
                if(n.length > 0){                                         
                    this.nextPeriod = ''
                    this.prePeriod = ''
                    this.dataQuiver = [];
                    this.dataQuiverBack = []
                    this.load()
                }
            },
            deep:true
        },  
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.nextPeriod = ''
                this.prePeriod = ''
                this.dataQuiver = [];
                this.dataQuiverBack = []
                this.load()                     
            }
        }, 
        currentScanType(n){
            this.nextPeriod = ''
            this.prePeriod = ''
            let that = this;
            setTimeout(() => {
                that.dataQuiver = [];
                that.dataQuiverBack = []
                that.load()
            }, 200);           
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()     
            }
        },   
    },
    computed:{
        noData(){
            return !this.overlay&&(!this.dataQuiver|| this.dataQuiver.length == 0)  
        },
        time(){
            if(!this.dataQuiver||this.dataQuiver.length == 0){
                return ''
            }
            let period = this.dataQuiver[0]?.period
            if(period){
                return `${$help.periodToTimeStr(period)}`
            }else{
                return ''
            }            
        },
        yaw()
        {
            if(!this.dataQuiver||this.dataQuiver.length == 0){
                return ''
            } 
            if(this.dataQuiver.length>0)
            {
                return Number(this.dataQuiver[0].yaw)%360;
            }
        }
    },
    mounted(){
        var that = this;
        setTimeout(() => {
            that.load()    
        }, 600);        
    },
    methods:{
        load(desc = false){
            var that = this;
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }
            this.overlay = true;
            this.timeRange = this.timeRange == null ? [] :this.timeRange;               
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") //
            let end =desc?start: this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") // 
            let period = desc? this.prePeriod : this.nextPeriod  
            getWlsQuiverByTimeApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:[{
                    Period:period,                  
                    Time:start,
                    LimitTime:end,
                    Desc:desc,
                    ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0              
                },{
                    Period:period,  
                    LimitTime:end,                
                    Time:start,
                    Desc:desc,
                    ScanMode: this.currentScanType == "PPI"?0:this.currentScanType == "FIX"?1:this.currentScanType == "RHI"?2:this.currentScanType == "Tri"?3:0              
                },],
                success:(d)=>{  
                    that.overlay = false;                                                                                
                    if(d.length == 0 || !d[0].Data||d[0].Data.length == 0)  {                      
                        that.loadChart();
                    }
                    if(d[0]?.Data[0].DwqSiteId!= that.$store.state.storage_global.currentSite.siteId){
                        return;
                    }  
                    that.nextPeriod = d[0]?.NextPeriod?.split("_")[0]
                    that.prePeriod = d[0]?.PrePeriod?.split("_")[0]  
                    that.overlay = false;             
                    that.dataQuiver = []
                    that.dataQuiverBack = []
                    let quiver = d[0]?.Data[0];
                    let localAngle = quiver.DwqLocAngle.sort((a,b)=>{
                        return a - b
                    })
                    let back = d[1]?.Data[0];                    
                    for(var i in localAngle){
                        let _item = that.dataQuiver.find(a=>a.angle ==quiver.DwqLocAngle[i])
                        if(!_item){                   
                            _item = {
                                siteId:quiver.DwqSiteId,
                                period:quiver.DwqPeriod.split("_")[0],
                                yaw:quiver.DwqPeriod.split("_")[1],
                                dataTime:quiver.DwqTime.replace("T"," "),
                                scanMode:scanType[quiver.DwqScanMode],
                                angle:quiver.DwqLocAngle[i],
                                data:[],
                                distance:[],
                                quiverAngle:[],
                            };
                            that.dataQuiver.push(_item)
                        }
                        _item.distance.push(quiver.DwqLocDis[i])
                        _item.quiverAngle.push(quiver.DwqQuiverAngle[i])
                        _item.data.push(quiver.DwqQuiverValue[i])
                    }

                    for(var i in back.DwqbLocAngle){
                        let _item = that.dataQuiverBack.find(a=>a.angle == back.DwqbLocAngle[i])
                        if(!_item){
                            _item = {
                                siteId:back.DwqbSiteId,
                                period:back.DwqbPeriod.replace("_")[0],
                                yaw:back.DwqbPeriod.replace("_")[1],
                                dataTime:back.DwqbTime.replace("T"," "),
                                scanMode:scanType[back.DwqbScanMode],
                                angle:back.DwqbLocAngle[i],
                                data:[],
                                distance:[]
                            };
                            that.dataQuiverBack.push(_item)
                        }
                        _item.data.push(back.DwqbQuiverValue[i])
                        _item.distance.push(back.DwqbLocDis[i])
                    }                
                    that.loadChart();
                }
            })
        },
        loadChart(){   
            //处理数据      
            let target = this.$refs.chart;   
            if(!this.$refs.colorBar || !target){
                return;
            }         
            var that = this;      
            let d = 3.3      
            let yData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);
            let xData =  Array.from({length: d * 30 *2}, (val, i)=>i/30-d);     
            let _datas =this.dataQuiverBack;              
            if(_datas.length == 0)     {                   
                let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black
                var layout = {
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                                
                    xaxis: {
                        scaleanchor:'y',
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,  
                        scaleratio:1    
                    },
                    yaxis: {     
                        title:"距离(km)",               
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,                      
                    },
                    modebar:{
                        add:[],
                        remove:['select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                        b:22
                    }
                };
                Plotly.newPlot(target, [       
                    {
                    name:'',
                    y: [],
                    x: [],
                    z:[],            
                    type: 'heatmap',           
                    showscale: false,
                    hovertext:[],
                    }                
                ], layout,{responsive: true});
                return
            }            
            let distanceData = [_datas[0].distance[0],_datas[0].distance[1] - _datas[0].distance[0],_datas[0].distance.length]
            let noDataLength =  (distanceData[0])/distanceData[1]  //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let zData = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => NaN)); 
            let hoverText = Array.from({length: d * 30 *2}, (val, i) =>  Array.from({length: d * 30 *2}, (val, i) => '')); 
            let w =  d * 30 *2;
            // //角度风抽样桶数量
            let therodsy = 10;
            
            //         let arrSampling2 = $help.sampling(i.data,therodsy)   
            //         debugger    
            let minSpanAngle = 0.025
            $help.drawScan(_datas,(obj)=>{
                zData[obj.x][w-obj.y]=obj.value;
                hoverText[obj.x][w-obj.y]= `风向：${(obj.angle).toFixed(2)}<br />距离：${(obj.magnitude/100).toFixed(4)}`
            },()=>{
                let quiverIconX = [],quiverIconY = [],customData = [];
                let lastAngle = 0;
                for(var i in that.dataQuiver) {                       
                    if(Math.abs(that.dataQuiver[i].angle -lastAngle) <= minSpanAngle || that.dataQuiver[i].distance.length < 3){
                        lastAngle = that.dataQuiver[i].angle
                        continue;
                    }
                    if(that.dataQuiver[i].data.length > 5){
                        let arrSampling = $help.sampling(that.dataQuiver[i].data,therodsy < that.dataQuiver[i].data.length? therodsy:that.dataQuiver[i].data.length)
                        for(let j in that.dataQuiver[i].distance){
                            if(arrSampling.indexOf(j) >= 0){
                                if(that.dataQuiver[i].data[j]!=0){
                                    quiverIconX.push(-1 *that.dataQuiver[i].distance[j] * Math.cos((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                    quiverIconY.push(that.dataQuiver[i].distance[j] * Math.sin((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                    customData.push({angle:that.dataQuiver[i].quiverAngle[j],speed:that.dataQuiver[i].data[j]/10})                             
                                }                    
                            }
                        }      
                    }else{
                        for(let j in that.dataQuiver[i].distance){
                            if(that.dataQuiver[i].data[j]!=0){
                                quiverIconX.push(-1 *that.dataQuiver[i].distance[j] * Math.cos((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                quiverIconY.push(that.dataQuiver[i].distance[j] * Math.sin((that.dataQuiver[i].angle + 90) * Math.PI / 180))
                                customData.push({angle:that.dataQuiver[i].quiverAngle[j],speed:that.dataQuiver[i].data[j]/10})                             
                            }                    
                        }      
                    }                     
                                           
                }
                var data = [                    
                    {
                        name:'',
                        y: yData,
                        x: xData,
                        z:zData,            
                        type: 'heatmap',           
                        showscale: false,
                        hoverinfo:'none',
                        hovertext:hoverText,
                        colorscale:that.$refs.colorBar.colorScaleValue,
                        zmin:that.$refs.colorBar.rangeInfo[0].min,
                        zmax:that.$refs.colorBar.rangeInfo[that.$refs.colorBar.rangeInfo.length-1].max
                    },   
                    {
                        name:'',
                        type:'scatter',
                        mode: 'markers',
                        marker:{
                            size:0.001
                        },
                        hovertext:customData.map(a=>`角度：${a.angle},风速：${a.speed.toFixed(2)}`),
                        x:quiverIconX,
                        y:quiverIconY, 
                        customdata:customData,
                        // hoverinfo:'none'
                    },             
                ];                    
                let bgColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.black:that.$vuetify.theme.defaults.light.white// ? 'black':'white';            
                let fontColor = that.$vuetify.theme.isDark? that.$vuetify.theme.defaults.dark.white:that.$vuetify.theme.defaults.light.black
                var layout = {                    
                    //hovermode:false,  
                    uirevision:'true',
                    plot_bgcolor: bgColor,
                    paper_bgcolor: bgColor,                                
                    xaxis: {
                        title:'距离(km)',
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,    
                        scaleanchor:'y',
                        scaleratio:1  
                    },
                    yaxis: {
                        title:'距离(km)',
                        dtick:1,
                        domain:5,
                        tickmode:'linear',
                        tick0:0,
                        color:fontColor,  
                    },
                    modebar:{
                        add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                    },
                    margin:{                    
                        t:42,
                        r:42,
                        l:42,
                        b:32
                    }
                };
            
                if(target.children.length> 0){
                    let mainSvg = target.querySelector('.main-svg');                   
                    if(target.clientHeight != mainSvg.clientHeight || 
                    target.clientWidth != mainSvg.clientWidth ) {                                            
                        Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                    }else{
                        Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                    }                               
                }else{
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
                }   
                quiver(target)  
                let rab = (target.layout.xaxis.range[1] - target.layout.xaxis.range[0]) 
                target.on('plotly_relayout', (a,b,c)=>{
                    if(a['xaxis.autorange']){
                        that.quiverbkScale = 1;
                    }
                    let rar = a['xaxis.range[1]'] - a['xaxis.range[0]']
                    if(rar){
                        let scale = rab/rar ? rab/rar:1;
                        that.quiverbkScale = scale;
                        quiver(target,scale,a)
                    }else{
                        quiver(target,that.quiverbkScale,a )
                    }                
                })                        
            },noDataLength,scanLength,distanceData[2],{d:d})                        
        }, 
        timerDraw(){  
            if(this.dataQuiverBack.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }, 
        play(status){                   
            if(status == 'next'){
                if((!this.nextPeriod || Number(this.timeRange[1].pattern("yyyyMMddHHmmss")) - Number(this.nextPeriod) <= 0) ){
                    openNotice({
                        type:"info",
                        text:'已加载到结尾'
                    })                        
                    return
                }   
                this.load()        
            }else{                
                if(!this.prePeriod || Number(this.timeRange[0].pattern("yyyyMMddHHmmss")) - Number(this.prePeriod) >= 0) {
                    openNotice({
                        type:"info",
                        text:'已加载到开始'
                    })                        
                    return
                }
                this.load(true)            
            }
        },
    }
}
</script>
<style scoped lang="scss">
.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 64px;
    right: 20px;    
}.heatmapTitle{
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 600;
}.btns{
    position: absolute;
    z-index: 2;
    right: 60px;
    top: 10px;
}
.plotychart{
    :deep(.modebar){
        padding-top:32px;
        padding-right:64px ;
    }
}
</style>
<template>
  <v-app>
    <v-overlay z-index="999999" :value="overlay.show">
      <div v-html="overlay.content"></div>
      <div class="loader" v-show="overlay.icon">
          <div class="outer"></div>
          <div class="middle"></div>
          <div class="inner"></div>
      </div>
    </v-overlay>
    <!-- 加载进度提示 横条 -->
    <v-progress-linear v-show="progress.show" app :color="$vuetify.theme.currentTheme.secondary" style="z-index:9999" :value="progress.value"></v-progress-linear>
    <!-- 文件下载进度提示 -->
    <v-scale-transition origin="center center">
      <div class="fileDownProgress" v-show="downloadThread.length > 0">
          <v-progress-circular                   
          :rotate="360"
          :size="72"
          :width="10"
          :value="downloadProgressValue"
          color="teal"       
        >
        <div class="d-flex flex-column align-center justify-center">
           <v-badge                    
              :value="downloadThread.length > 1"                   
              color="pink"
              overlap                                            
              :content="downloadThread.length"
            >
            <v-icon color="orange" large>mdi-file-download-outline</v-icon>
           </v-badge>
            <span style="font-size:12px;">{{ downloadProgressValue }}%</span>
        </div>
        </v-progress-circular>
      </div>
    </v-scale-transition>
    <!-- 消息提示 -->
    <div class="noticeTop d-flex align-center justify-center">
        <v-alert
        transition="scale-transition"      
        dense
        :icon="false"
        :type="notice.type"
        dismissible      
        :value="notice.show"
        elevation="8"    
        @input="closeNotice"  
        >{{notice.text}}
        </v-alert>             
    </div>
    <!-- 对话框 -->
    <v-dialog v-model="showDialog" persistent max-width="290">      
      <v-card>
        <v-card-title class="headline">{{dialog.title}}</v-card-title>
        <v-card-text>{{dialog.content}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :color="$vuetify.theme.currentTheme.info" @click="closeDialog('no')">{{$i18n.t('layout.dialog.btnNo')}}</v-btn>
          <v-btn text :color="$vuetify.theme.currentTheme.success" @click="closeDialog('yes')">{{$i18n.t('layout.dialog.btnOk')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <router-view/>  
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    notice:{
          show:false,
          type:"info",
          text:""
      },
      showDialog:false,
      dialog:{
          title:"",
          content:"",
          okBack:null,//确认回调
          noBack:null //取消回调
      },
      overlay:{
          show:false,
          content:"",
          icon:true,
      },     
      progress:{
          show:false,
          value:0
      },
      downloadThread:[]
    //
  }),
  computed:{
    downloadProgressValue(){
      let totalAll = 0;
      let loaded = 0;
      let max = 0;
      this.downloadThread.forEach(a=>{
        max = a.total > max ? a.total:max        
      })
      totalAll = max * this.downloadThread.length
      this.downloadThread.forEach(a=>{
        loaded += (a.loaded * (max / a.total));       
      })
      if(totalAll == 0){
        return 0;
      }
      return Math.floor((loaded/totalAll)*100);
    }
  },
  mounted(){
     this.config();         
  },
  methods:{
     config(){
        //全局方法
        var that = this;
        window.openNotice = function(obj){
            that.notice = {
                type:obj.type,
                text:obj.text,
                show:true
            }
            setTimeout(() => {
                that.notice = {
                    show:false
                }
            }, 2000);
        }
        window.openDialog = function(obj){
            that.dialog = obj
            that.showDialog = true;
        }
        window.updateMask = function(obj){    
            that.overlay = obj;                     
        }    
        window.updateFileProcess = function(id,closed,loaded = 0,total = 0){          
          if(!closed){
            //添加
            let currentThread = that.downloadThread.find(a=>{
              return a.id == id;
            })
            if(!currentThread){
              that.downloadThread.push({
                id:id,
                total:total,
                loaded:loaded
              })
            }else{
              currentThread.loaded = loaded;
            }
          }else{
            that.downloadThread = that.downloadThread.filter(a=>{
              return a.id != id;
            })
          }
        }       
    },
    closeNotice(){
        this.notice = {
            show:false
        }
    },
    closeDialog(type){            
        this.showDialog = false;            
        if(this.dialog.okBack && type == 'yes'){
            this.dialog.okBack()
        }
        if(this.dialog.noBack && type == 'no'){
            this.dialog.noBack()
        }
    },
  }
};
</script>
<style lang="scss">
html,body,.v-application{
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;  
}

/* 滚动条样式修改 */
::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 6px ;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px ;
}
::-webkit-scrollbar-thumb {
      cursor: pointer !important;
      /*滚动条里面小方块*/
      border-radius   : 2.5px ;
      background-color: var(--v-primary-base) ;
      background-image: -webkit-linear-gradient(
          45deg,
          var(--v-primary-base) 25%,
          transparent 25%,
          transparent 50%,
          var(--v-primary-base) 50%,
          var(--v-primary-base) 75%,
          transparent 75%,
          transparent
      );
  }
  ::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      cursor: pointer !important;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : var(--v-fTheme-base);
      border-radius: 5px;
      }

/* 全局插件样式 */
.noticeTop{
    width: 100%;
    z-index: 9999;
    top: 30px;
    position: absolute;
}


.amap-copyright,.amap-logo{
  display: none !important;
}
.amap-controlbar-zoom{
  display: none; 
}
.loader {
  height: 100px;
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.width100{
  width: 100%;  
  transition: width 0.6s linear;
}
.height100{
  height: 100%;
  transition: height 0.6s linear;
}
.height50{
  height: 50%;
}
.font-size14{
  font-size: 14px !important;
}

.font-size16{
  font-size: 16px !important;
}

.font-size18{
  font-size: 18px !important;
}
.font-weight{
  font-weight: 600 !important;
}
.pointer{
  cursor: pointer;
}
//解决标记图层事件不响应
.noevent .amap-maps{
  >div:last-child{
    pointer-events: none !important;
  }
}

//去除tinymce警告提示
.tox-notification--warning{
  display: none;
  }
.bottomAction{
 width: 50%;
 min-width: 780px;
 margin: auto;
}
.dialogContent{
  width: 50% !important;
  min-width: 780px;
  margin: auto;
}
.tableContainer{
    flex-shrink: 1 !important;
    flex-grow: 0 !important;
    height: 100%;
    margin: 10px 0 !important;
    overflow: auto;
}
.amap-info-content{
  background:var(--v-fTheme-base)
}
.amap-info-sharp{
  border-top: 8px solid var(--v-fTheme-base) !important;
}
.amap-menu{
  li:hover{
    background:var(--v-fTheme-base) !important;
  }
}
.amap-menu-outer{
  background:var(--v-fTheme-base) !important;
  padding-left: 0 !important;
  path{
      fill:var(--v-anchor-base)
    }
}
//地图站点标记
.map_marker{
  path{
      fill:var(--v-anchor-base)
    }
}
.fileDownProgress{
  position: absolute;
  top: 70px;
  right: 40px;
  z-index: 10000;
}

//chart logo 隐藏
.modebar-btn--logo{
  display: none;
}
</style>
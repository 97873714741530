<template>
  <v-container ref="monitorBox" class="ma-0 pa-0" style="overflow:auto;max-width:100%;">
    <v-hover v-slot:default="{ hover }">
        <v-expand-transition>
        <div class="numConfig transition-fast-in-fast-out" :style="hover?'left:0px;z-index:12;':'left:-18px;z-index:2;'">        
            <v-menu offset-x z-index="12" open-on-click >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  
                    v-show="sites.length > 1"
                    fab  
                    class="ma-0 pa-0"                                                                                                        
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-badge :value="true" :content="siteCount" overlap color="pink"
                                >
                        <v-icon class="mx-1">mdi-map-marker-radius</v-icon>                
                    </v-badge>
                    </v-btn>
                </template>               
                <v-list shaped  class="pa-0">                   
                    <span class="d-flex pt-2  px-2 width100 justify-center">展示站点数量</span>   
                        <v-card max-height="300"  class="ma-0 px-2 " style="overflow: auto;">                                                                                                                    
                            <v-list-item
                            @click="siteCount = Number(index)+1"
                                v-for="(item, index) in sites"
                                :key="index+1" 
                                :value="Number(index)+1"     
                                v-show="index+1 >1 && index+1<9"                                   
                            >                            
                                <v-list-item-title class="d-flex">                            
                                {{ index+1 }}<v-spacer class="mx-2"></v-spacer><span class="warning--text" v-show="index >= 6">{{"影响性能!"}}</span>
                                </v-list-item-title>                                
                            </v-list-item>                                                               
                        </v-card>   
                </v-list>                          
            </v-menu>                
        </div>
        </v-expand-transition>
    </v-hover> 
    <v-row class="ma-0 pa-0 flex-wrap width100" no-gutters v-for="r,kr in grid.row" :key="kr+'kr'" :style="`height:${100/grid.row.length}%`">
        <v-col class="ma-0 pa-0" v-for="c,kc in r.cols" :key="kc+'kc'">
            <siteMonitorItem class="width100" :siteCount="siteCount" :sites="sites" :site="sites[c.index]"></siteMonitorItem>   
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import siteMonitorItem from '@/components/mobile/monitoring/pls/windowUnion/siteMonitorItem'
import siteTypeWithRoute from '@/plugins/datas/siteTypeWithRoute'
export default {
    name:"monitoring.pls.windowUnion",
    components:{
        siteMonitorItem
    },
    data(){
        return {
            siteCount:2,
            sites:[],
            mbw:0,
            mbh:0,
            grid:{
                row:[],
            }
        }
    },
    watch:{
        siteCount(n){
            localStorage.setItem("siteCount",n)
            this.loadGrid();
        }
    },
    computed:{

    },
    mounted(){  
        let that = this;  
        setTimeout(() => {
            if(typeof(that.$store.state.storage_global.currentType.code)!="undefined"){
                that.load();               
            }
        }, 300);  
       
    },        
    methods:{
        load(){
            let sites = this.$store.state.storage_user.sites.filter(a=>{                               
                return a.type == siteTypeWithRoute.find(a=>a.name == 'pls').type && a.transStatus == 0 && a.commitStatus == 1;
            })
            this.sites = sites;  
            let siteCount = (localStorage.getItem("siteCount") && localStorage.getItem("siteCount")!=0 ) ?Number(localStorage.getItem("siteCount")):2;           
            this.siteCount = sites.length < siteCount? sites.length : siteCount;   
            if(sites.length == 0){
                openNotice({
                    text:"暂无实时站点数据",
                    type:"error"
                })                
                this.sites = this.$store.state.storage_user.sites.filter(a=>{                               
                    return a.type == type;
                })
                this.siteCount = this.sites.length < siteCount? this.sites.length : siteCount;              
                this.loadGrid()
            }else{
                this.loadGrid()
            }        
        },
        loadGrid(){
            this.grid={
                row:[]
            }
            //计算布局                
            //窗口宽
            let mbw = this.mbw = this.$refs.monitorBox.clientWidth;
            //窗口长
            let mbh = this.mbh = this.$refs.monitorBox.clientHeight;
            //计算行列最大
            let rmax = Math.floor(1);
            let cmax = Math.floor(1);
            if(this.siteCount >= rmax * cmax){
                let itemCount = Math.floor(this.siteCount / rmax) //每行几个
                let mCount = this.siteCount % rmax //余的几个   
                let _index = 0;         
                for(var i = 0;i< rmax;i++){
                    let cols = []                
                    for(var j = 0;j< itemCount;j++){                    
                        cols.push({
                            index:_index,
                            visit:false
                        });
                        _index +=1
                        if(mCount > 0 && (j == itemCount -1)){                        
                            cols.push({
                                index:_index,
                                visit:false
                            });                        
                            _index +=1
                            mCount-=1;
                        }
                    }
                    this.grid.row.push({
                        index:j,
                        cols:cols
                    })
                }
            }else if(rmax * cmax > this.siteCount){            
                let _r =Math.floor(Math.sqrt(this.siteCount))
                let _rows = _r > rmax? rmax:_r;                
                let _cols = Math.floor(this.siteCount / _rows)
                let _mCount = this.siteCount % _rows;
                let _index = 0;
                for(var i = 0;i< _rows;i++ ){
                    let cols = []
                    for(var j = 0;j< _cols;j++){                    
                        cols.push({
                            index:_index,
                            visit:false
                        });
                        _index +=1
                        if(_mCount > 0 && (j == _cols -1)){                        
                            cols.push({
                                index:_index,
                                visit:false
                            });                        
                            _index +=1
                            _mCount-=1;
                        }
                    }
                    this.grid.row.push({
                        index:j,
                        cols:cols
                    })
                }
            } 
        }
    }
}
</script>

<style scoped lang="scss">
.numConfig{
    position: absolute;
    z-index:12;
    top: calc(50% - 32px);
}
</style>
import { getField, updateField } from 'vuex-map-fields'
import { getBaseDicApi } from '@/api'
const state ={
    baseDicList:[]
}
const getters = {
    getField,
}

const mutations = {
    updateField,
    updateBaseDic(state,data){
        state.baseDicList.push(data)        
    } 
}


const actions = {
    //获取基本类型的子类型下拉框
    getDicMenu({commit,state},{ typeid,callback }){
        var item = state.baseDicList.find(a=>{return a.typeid == typeid });
        if(!item){
            getBaseDicApi({
                typeId:typeid,
                success:(d)=>{
                    let item = {
                        typeid:typeid,
                        datas:d
                    }
                    commit('updateBaseDic',item);
                    callback(d)
                }
            })
        }else{
            callback(item.datas)
        }
    }
}

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
    <v-container class="containMapBox" :style="`height:${height}px;width:${width}px`">
    <v-overlay
    class="ma-3 mt-11"
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-overlay
        class="ma-3 mt-11"
            absolute
            :value="noData"
            opacity="0.2"
            >
        暂无数据
        </v-overlay>
    <v-card class="width100 height100 ma-0 d-flex flex-column">
        <v-card-actions class="pa-0 ma-0">
            <v-row no-gutters class="ma-0 pa-0 align-center">
                <v-icon style="min-width:32px;flex-shrink: 0;" class="ma-2" @mousedown="resize">mdi-arrow-all</v-icon>
                <div style="min-width:68px;flex-shrink: 0;">{{title}}</div>
                <v-spacer></v-spacer>
                <v-col cols="1"><v-icon class="ma-2 move" title="移动">mdi-pan</v-icon></v-col>
                <v-col cols="1" v-show="false"><ColorBarConfig/></v-col>
                <v-col cols="1" class="mr-2 pointer"><v-icon class="ma-2" @click="$emit('close',keyV)">mdi-close</v-icon></v-col>                
            </v-row>
        </v-card-actions>
        <div ref="chart" class="width100 height100">

        </div>
    </v-card>
</v-container>
</template>
<script>
import * as echarts from "echarts"
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import equipmentType from '@/plugins/datas/equipmentType.json'
import { getAgtExternalApi} from '@/api'
export default {
    name:"ExternalDataMap",
    components:{ColorBarConfig},
    props:{
        keyV:{
            type:String
        }
    },
    data(){
        return {
            overlay:false,
            chart:null,
            width:370,
            height:268,
            data:[],
        }
    },
    computed:{
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },
        title(){            
            let title = equipmentType.find(a=>{
                return a.key == this.keyV
            }).name                        
            return title
        }
    },
    watch:{
        // "$store.state.storage_global.currentSite":{
        //     handler(n,v){    
        //         this.load();                            
        //     }
        // },
        "$store.state.storage_agt.agtId":{
            handler(n,v){                    
                this.load();                            
            }
        },
        "$store.state.storage_agt.widthHeight":{
            handler(n,v){    
                if(localStorage.getItem("monitorCarExternalHeight")){
                    this.height = localStorage.getItem("monitorCarExternalHeight")
                }
            
                if(localStorage.getItem("monitorCarExternalWidth")){
                    this.width = localStorage.getItem("monitorCarExternalWidth")
                }    
                if(this.chart){
                    this.chart.resize()
                }
            }
        },
        // "$store.state.storage_agt.loadKey":{
        //     handler(n,v){                   
        //         if(this.keyV == n){
        //             this.load()                
        //         }                 
        //     }
        // }, 
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.data.length == 0){                
                        this.chart.showLoading({
                            text: '暂无数据',
                            showSpinner: false,    // 隐藏加载中的转圈动图
                            textColor: '#9d9d9d',
                            maskColor: 'rgba(0, 0, 0, 0.8)',
                            fontSize: '25px',
                            fontWeight: 'bold',
                            fontFamily: 'Microsoft YaHei'
                        });
                        return
                    }
                }else{
                    if(this.data.length == 0){                
                        this.chart.showLoading({
                            text: '暂无数据',
                            showSpinner: false,    // 隐藏加载中的转圈动图
                            textColor: '#9d9d9d',
                            maskColor: 'rgba(255, 255, 255, 0.8)',
                            fontSize: '25px',
                            fontWeight: 'bold',
                            fontFamily: 'Microsoft YaHei'
                        });
                        return
                    }
                }
            }
        },
    },
    mounted(){
        this.load()        
    },
    methods:{
        load(){
            if(localStorage.getItem("monitorCarExternalHeight")){
                this.height = localStorage.getItem("monitorCarExternalHeight")
            }
        
            if(localStorage.getItem("monitorCarExternalWidth")){
                this.width = localStorage.getItem("monitorCarExternalWidth")
            } 
            let siteId = this.$store.state.storage_global.currentSite.siteId;
            let agtId = this.$store.state.storage_agt.agtId;
            console.log(agtId)
            let key = this.keyV;
            var dataInfo = this.$store.state.storage_agt.externalDatas.find(a=>{
                return a.siteId == siteId && a.agtId == agtId && a.key == this.keyV;
            })            
            if(!dataInfo){
                var that = this;
                this.$store.commit("storage_agt/updateExternalDataInfo",{
                    siteId:siteId,
                    agtId:agtId,
                    key:key,
                    datas:[]
                },{root:true}) 
                that.loadData(key,siteId,agtId);
            }   
            else{
                this.data = dataInfo.datas;
                var that = this;
                this.$nextTick(a=>{
                    that.loadChart();
                })                                
            }                    
        },
        loadData(key,siteId,agtId,page=1,datas = [],time){           
            var that = this;
            this.overlay = true
            getAgtExternalApi({
                    id:siteId,
                    agtId:agtId,
                    params:{
                        LastDate:time,
                        PageSize:1000,
                        DadTypeId:key
                    },
                    success:(d)=>{   
                        datas = datas.concat(d.Data)                                            
                        if(datas.length == d.Total){ 
                            that.overlay = false;    
                            let _datas = datas.reverse();                       
                            that.$store.commit("storage_agt/updateExternalDatas",{
                                siteId:siteId,
                                agtId:agtId,
                                key:key,
                                datas:_datas
                            },{root:true})  
                            that.data = _datas
                            that.loadChart();
                        }else{                            
                            that.loadData(key,siteId,agtId,page+1,datas,d.Data[d.Data.length -1].DaeTime)
                        }                                            
                    }
                })
        },
        loadChart(){
            let target = this.$refs.chart;            
            let myChart = echarts.getInstanceByDom(target);
            if(myChart == null){
                myChart = echarts.init(target);
            }              
            this.chart = myChart;
            myChart.clear();
            myChart.hideLoading();            
            var equipment = equipmentType.find(a=>{
                return a.key == this.keyV;
            })
            let pieces = equipment.colors.map((a,index)=>{
                return {
                    color:a,
                    gt:index * equipment.max/5,
                    lte:equipment.max/5 * (index + 1)
                }
            })            
            let data = this.data.map(a=>{
                return {
                    "时间":new Date(a.DaeTime).pattern("yyyy年MM月dd HH:mm:ss"),
                    "值":a.DaeValue1,
                    "经度":Number(a.DaeLng).toFixed(6),
                    "纬度":Number(a.DaeLat).toFixed(6),
                    "单位":a.DaeUnit
                    }
            });
            let option = {
                tooltip: {
                    show:true,
                    trigger: 'axis',
                    className:'env_tooltip',
                    appendToBody:true,
                    formatter:(params,ticket,callback)=>{
                        let str = `
                        <div>时间：${params[0].data["时间"]}</div>
                        <div>经纬度：${params[0].data["经度"]},${params[0].data["纬度"]}</div>
                        <div>值：${params[0].data["值"]}/${params[0].data["单位"]}</div>
                        `                        
                        return str;
                       // callback(ticket, str);
                    }
                },
                visualMap: {
                    top: 50,
                    right: 10,
                    textStyle:{
                        color:()=>{},
                        textBorderType:'solid',
                        textBorderColor:"#fff",
                        textBorderWidth:1
                    },
                    pieces:pieces,
                    dimension:["值"],
                    outOfRange: {
                        color: '#AA069F'
                    }
                },
                 grid: {
                    top:'5%',
                    left: '10%',
                    right: '1%',
                    bottom: '10%'
                },
                dataset:[{
                    dimensions:["时间","值","经度","纬度","走航批次","单位"],
                    source:data,
                }]
                ,
                dataZoom: [
                    // {
                    // startValue: '2014-06-01'
                    // },
                    {
                        type: 'inside'
                    }
                ],
                xAxis: {
                    type:"category",
                    axisLabel:{}
                    //axisLabel:{interval :0},
                    // data: this.data.map(a=>{
                    //     return a.ValueTime
                    // })
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        datasetIndex: 0,
                        type: 'line',                      
                    }
                ]
            };
            myChart.setOption(option)
        },
        //重置大小
        resize(){
            var that = this;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = Number(that.width) - e.movementX ;
                let resetH = Number(that.height) + e.movementY;
                that.width = resetW > 0.4 * document.body.clientWidth ? 0.4 * document.body.clientWidth : resetW < 370 ? 370 : resetW;
                that.height = resetH > 0.4 * document.body.clientHeight ? 0.4 * document.body.clientHeight : resetH < 260 ?260 : resetH;
                localStorage.setItem("monitorCarExternalHeight",that.height)
                localStorage.setItem("monitorCarExternalWidth",that.width)
                that.$store.commit("storage_agt/updatewidthHeight",`${that.width},${that.height}`)          
            }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{
                    if(that.chart){
                        that.chart.resize()
                    }                    
                document.removeEventListener('mousemove',fun)
            });
        },
    }
}
</script>
<style scoped lang="scss">
.containMapBox{
    min-width:306px;
    min-height: 204px;   
}
</style>
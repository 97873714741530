<template>
    <v-container class="contain ma-0 pa-0">        
        <v-card class="width100 height100">           
            <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
            <div ref="scanMap" class="map noevent">                            
            </div>                                                                                                                              
            <ColorBarConfig :dataKey="2" ref="colorBar" class="mapColorBarConfig" @update="configColorBar"/>            
            <div class="hotMapColorBar" v-show="hotLayer">
                <div :style="`background-color:${i.value}`" v-for="i in hotMapColorBarColor" class="hotMapColorBarBox" :key="i.key">
                    <div class="hotMapColorBarText" >{{i.numStr}}</div>
                </div>
            </div>
            <v-expand-transition>
                <v-btn v-show="!showWarnList && $store.state.storage_global.currentSite.scanType == 'PPI'" class="mx-2 warnBtn pointer" @click="showWarnList=!showWarnList" fab large color="rgba(0,0,0,0)" >
                    <v-icon title="还原窗口" color="primary" size="48px">mdi-android</v-icon>
                </v-btn>
            </v-expand-transition>
            <v-expand-transition origin="bottom bottom">
                <v-card v-show="showWarnList && $store.state.storage_global.currentSite.scanType == 'PPI'"   :style="`height:40%;width:100%;z-index:299`" class="warnInfo">
                    <v-data-table 
                    dense
                    height="100%"                    
                    :loading="loadingWarnInfo"                               
                    fixed-header  
                    :headers="headers"
                    show-group-by
                    :items="alarmList"
                    item-key="_id"
                    disable-pagination
                    hide-default-footer        
                    @click:row="setCurrentPoint"
                    :item-class="()=>{return'_tableActive'}"
                    calculate-widths>
                        <template v-slot:top>
                            <v-row dense no-gutters class="mx-2 mt-2 flex-shrink-0 flex-grow-0 ">                      
                                <v-col cols="4" class="flex-grow-0 flex-shrink-0 d-flex">
                                    <v-switch
                                        title="开启热力图"
                                        @change="setHotLayer($event);if($event){setOpacity(5)}else{setOpacity(7)}"
                                        v-model="hotLayer"
                                        class="mt-0"
                                        :label="$i18n.t('realTimeMonitor.table.topTool.hotBar')"
                                        hide-details
                                        dense
                                    ></v-switch>                   
                                </v-col>
                                <v-col cols="4" class="flex-grow-0 flex-shrink-0 d-flex">
                                    <v-item-group multiple @change="loadAlarmPoints" class="d-flex">
                                        <v-item
                                        v-for="(i,k) in alarmFilterRule" :key="k"
                                        v-slot="{ active, toggle }"
                                        >
                                        <v-checkbox :input-value="active"
                                            @click="toggle" v-model="i.checked" dense hide-details hide-spin-buttons :label="i.name "></v-checkbox>
                                        </v-item>
                                    </v-item-group>                                     
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="sendDialog" persistent max-width="500px" hide-overlay>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn                                                                                              
                                        class="mb-2 primary--text"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        >推送</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="headline">选择发送人员</v-card-title>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-select
                                                            v-model="wxUserIds"
                                                            :items="recivers"                                 
                                                            :label="$i18n.t('noticeSendManage.label.form.reciver')"
                                                            multiple
                                                            required
                                                        >
                                                        <template v-slot:prepend-item>
                                                            <v-list-item
                                                                ripple
                                                                @click="selectAllReciver"
                                                                >
                                                                <v-list-item-action>
                                                                    <v-icon :color="wxUserIds.length > 0 ? 'indigo darken-4' : ''">{{ wxUserIds.length == 0 ? 'mdi-checkbox-blank-outline': (recivers.length > wxUserIds.length ? 'mdi-minus-box':'mdi-close-box') }}</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>选择全部</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>                                                                            
                                                            </template>
                                                        </v-select> 
                                                    </v-row>  
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="green darken-1" text @click="sendDialog = false">取消</v-btn>
                                                <v-btn color="green darken-1" text @click="sendDialog = false;sendMessage()">立即发送</v-btn>
                                            </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                <v-col cols="1" class="d-flex justify-end align-start flex-grow-0 flex-shrink-0 pointer" >
                                    <v-icon title="最小化" @click="showWarnList=!showWarnList">mdi-chevron-down-circle</v-icon>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-expand-transition>
            <div class="weatherinfo pointer">                
                <div class=" my-1" title="风向" ><v-icon >mdi-windsock</v-icon>{{weatherInfo.windDirection}}</div>
                <div class=" my-1" title="风力"><v-icon >mdi-weather-windy</v-icon>{{weatherInfo.windPower}}</div>
                <div class=" my-1" title="温度"><v-icon >mdi-thermometer</v-icon>{{weatherInfo.temperature}}<v-icon>mdi-temperature-celsius</v-icon></div>
                <div class=" my-1" title="湿度"><v-icon >mdi-water-percent</v-icon>{{weatherInfo.humidity}}</div>
                <div class=" my-1" title="天气"><v-icon>{{icon(weatherInfo.weather)}}</v-icon>{{weatherInfo.weather}}</div>          
                <v-badge    
                    v-show="extendDevice.pm25"                        
                    color="#ffc531"
                    inline                                    
                    overlap
                    content="pm2.5"
                    tile
                    top     
                    style="bottom:5px;"
                    class="my-1"                                                                   
                >     
                    <div style="position:relative;left:10px;top:5px;" title="PM2.5" class="height100 mx-0" v-show="extendDevice.pm25">{{extendDevice.pm25}} μg/m³</div>
                </v-badge>
            </div>                                                                
        </v-card> 
    </v-container>
</template>

<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import weatherType from '@/plugins/datas/weatherType.json'
import transferDataType from '@/plugins/datas/transferDataType'
import windAngle from '@/plugins/datas/windAngle'
import {getAlarmPointsApi,getHistoryImagesApi,getGisApi,getReciverApi,sendAlarmToWxUserApi,getAlarmPointComputedsApi} from '@/api' 
import {new_marker_y} from '@/plugins/datas/mapmark.js';
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
import { uploadMinioURL } from '@/api/url'
import Draggable from "vuedraggable"
export default {
    name:"monitoring.pls.realtime",
    components:{ColorBarConfig,Draggable,monitorPointDataCard},
    data(){
        return {
            permissionUser:{
                weatherShow:true,
                hotLayerShow:true,
                alarmPointShow:true,
                pushMessageShow:true
            },   
            scanDistance:6,
            map:null,
            mapEchart:null,
            center:[],
            mapContextMenuPositon:[],
            weatherInfo:{
            },
            extendDevice:{
                pm25:0
            },

            //时间热图  垂直使用
            hotChart:null,
            hotChartStatus:false,
            hotChartData:[],

            //热力图
            mapHotLayer:null,
            mapHotDatas:[],
            hotLayer:false,
            color:{
                0.2: '#5e04fb',
                0.5: '#a0ff2f',
                0.7: '#fde468',
                1.0: '#d7191c'
            },
            alarmFilterDialog:false,
            sendDialog:false,//推送弹窗
            wxUserIds:[],
            recivers:[],

            //报警列表
            showWarnList:true,
            loadingWarnInfo:false,
            headers:[{
            text:this.$i18n.t('realTimeMonitor.table.header.city'),
            value: 'city',
            sortable: true,
            groupable:false,
            width:'13%'
            },
            {
            text:this.$i18n.t('realTimeMonitor.table.header.district'),
            value: 'district',
            sortable: true,
            groupable:true,
            width:'13%'
            },
            {
            text:this.$i18n.t('realTimeMonitor.table.header.street'),
            value: 'street',
            sortable: true,
            groupable:true,
            width:'13%'
            },{
            text:this.$i18n.t('realTimeMonitor.table.header.position'),
            value: 'position',
            sortable: true,
            groupable:false,
            width:'36%'
            },{
            text:this.$i18n.t('realTimeMonitor.table.header.count'),
            value:'count',
            groupable:false,
            width:'5%'
            },{
            text:this.$i18n.t('realTimeMonitor.table.header.time'),
            value: 'time',
            groupable:false,
            width:'20%'
            }],
            alarmList:[],
            warnMakerList:[],
            width:0,
            height:0,        
            siteMarker:null,
            //画图
            lidarDatas:[],
            canvas:null,
            ctx:null,
            canvasLayer:null,
            opacity:0.7,
            noDateCount:0,
            setOpacity:null,
            monitorPoints:[],//控点
            monitorPointMarkers:[],    //控点标记    
            timmer:null,
            clearChartFun:null,
            hiddenDom:false,
            loadTimer:null,
            currentWatch:null,
            alarmFilterRule:[]
    }},
    computed:{
        icon:()=>{
            return(key)=>{
                var item = weatherType.find(a=>a.name == key);
                if(item){
                    return item.value
                }else{
                    return 'mdi-biohazard'
                }
            }
        },
        checkedId(){
            if(!this.alarmFilterRule) return [];
            return this.alarmFilterRule.filter(a=>a.checked).map(a=>a.id)  
        },
        hotMapColorBarColor(){
            let _color = [];
            if(this.color){
                //最大值
                let max = 0;
                this.mapHotDatas.map(a=>{
                    if(a.count > max) max = a.count;
                });                                                                               
                for(var i in this.color){
                    _color.push({
                        numkey:Number(i),
                        value:this.color[i]
                    })            
                }                
                _color = _color.sort((a,b)=>{
                    return b.numkey -a.numkey;
                })
                var _colorInfos = _color.map((a,i)=>{                    
                    return {
                        key:i,
                        value:a.value,
                        numkey:a.numkey,
                        numStr:i== _color.length -1?'0': Math.floor(_color[Number(i)+1].numkey * max)+'-'+ 
                                Math.floor(a.numkey * max) 
                    }
                })                
                return _colorInfos
            }
            return []
        }
    },
    watch:{
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){                     
                this.watchData(n.siteId)                          
                var that = this; 
                this.alarmFilterRule =$help.copyAsOther(n.alarmFilterRule)
                //垂直扫描        
                if(!n.scanType){
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                                          
                            }
                        }
                    })
                }    
                if(this.$store.state.storage_global.currentSite.extendInfo){            
                    let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }            
                this.extendDevice.pm25 = 0    
                this.$nextTick(()=>{  
                    if(that.lidarDatas.length > 0 && n.siteId != that.lidarDatas[0].siteId){
                        that.lidarDatas = [];          
                    }                  
                    that.load();
                })                
            }
        },   
        "$store.state.storage_global.message":{
            handler(n){   
                let that = this;                
                if(n.siteId == this.$store.state.storage_global.currentSite.siteId){     
                    //过滤       
                    let _filterRel = JSON.stringify(n.pointFilter);
                    let _rel = false;
                    for(let filterRule in this.checkedId){
                        if(_filterRel.indexOf(Number(filterRule)+1+":false") >= 0){
                            _rel = true
                        }else{
                            _rel = false
                        }
                    }             
                    if(_rel){
                        this.addAlarmPonitToMap(n);                             
                    }                    
                }
            }
        },
        "$store.state.storage_user.devices":{
            handler(d){               
                let city = this.$store.state.storage_global.currentSite.city;
                let _currentCityDevice = d.find(a=>{
                    return a.city == city;
                })                
                if(_currentCityDevice){
                    this.extendDevice.pm25 = _currentCityDevice.average
                }else{
                    this.extendDevice.pm25 = 0
                }                
            },
            deep:true
        }    
    },     
    beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },  
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },    
    mounted(){     
        var that = this;   
        if(this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsRealtime?.scanMap){
            this.permissionUser = this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsRealtime?.scanMap
        }        
        var target = that.$refs.scanMap   
        if(this.$store.state.storage_global.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;            
        } 
        //加载过滤规则
        this.alarmFilterRule = $help.copyAsOther(this.$store.state.storage_global.currentSite.alarmFilterRule)
        setTimeout(() => {
            that.watchData(that.$store.state.storage_global.currentSite.siteId)                    
            that.$nextTick(()=>{            
                that.load();
            })
        }, 200);                             
        this.resetWarnTableSize()    
        this.loadReciver();    
        //检查画图情况
        this.timmer = setInterval(()=>{
            if(that.lidarDatas.length == 0 &&  that.$store.state.storage_global.currentSite.scanType=="PPI"){
                setTimeout(() => {
                    if(that.lidarDatas.length == 0){
                        console.log("加载历史")
                        that.loadLidarData();
                    }
                }, 3000);           
            }
        },1000 * 5)

        this.clearChartFun = () => {
            if (document.hidden) {
                that.hiddenDom = true;
                // 清除      
                that.$nextTick(()=>{                
                    that.mapEchart = echarts.getInstanceByDom(target);                    
                    if(that.mapEchart != null){
                        that.mapEchart.clear();                   
                    } 
                })     
                
            } else {
                that.hiddenDom = false;
                // 开启 
                that.$nextTick(()=>{
                    that.mapEchart = echarts.getInstanceByDom(target);
                    if(that.mapEchart != null){
                        that.loadMap()      
                        that.addWarnPointToMap();  
                        if(that.hotLayer){
                            that.setHotLayer(that.hotLayer)
                        }
                    }
                })                                 
            }
        };
        document.addEventListener("visibilitychange", this.clearChartFun);     
    },
    methods:{    
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                            
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == transferDataType['sps'].value && (n.ScanMode == 0 || n.ScanMode == "PPI")){                           
                    var json =JSON.parse(JSON.stringify(n.jsonData)); 
                    if(that.ctx){                                
                        that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)
                    }            
                    if(window.log){
                        console.log(json[0].straightAngle,json,that.$store.state.storage_global.currentSite)                          
                    }                    
                    if(that.lidarDatas.length > 0 &&(json[0].period != that.lidarDatas[0].period || n.DataType != that.lidarDatas[0].dataType)){
                        that.lidarDatas = [];                                    
                    }                                                                                                
                    that.lidarDatas = that.lidarDatas.concat(json) 
                    that.lidarDatas = that.lidarDatas.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    })
                                
                    if(!that.hiddenDom){                                        
                        that.draw()              
                    }                                       
                }
            }, {immediate: true })
        },   
        load(){               
            var that = this;              
            this.loadMap();  
            if(this.$store.state.storage_global.currentSite?.scanType!=null && this.$store.state.storage_global.currentSite?.scanType=='PPI')
            {                  
                //加载地图
                this.loadAlarmPoints(); 
            } else{
                this.$store.dispatch('storage_user/getLastestScanType',{
                site:this.$store.state.storage_global.currentSite,
                cb:(obj)=>{
                    if(obj.scanType == 'PPI'){
                        that.loadAlarmPoints();
                    }
                }
            })
            }  
        },
        configColorBar(item){            
            if(this.$store.state.storage_global.currentSite.scanType == "PPI"){
                this.draw();
            }         
        },
        loadMap(){
            let target = this.$refs.scanMap; 
            if(!target){
                return
            }
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 12.5;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            if(this.canvasLayer){
                this.map.remove(this.canvasLayer)
                this.canvasLayer = null;
            }
            var that = this;
            let site = this.$store.state.storage_global.currentSite;
             //获取天气情况
             if(!site.weatherInfo || (site.weatherUpdateTime.getTime() - new Date().getTime())/1000/60/60 > 1){
                //获取当前站点天气                                        
                getWeather(site.district,(data)=>{
                    let weatherInfo = {
                        position:data.city,
                        windPower:data.windPower,
                        temperature:data.temperature,
                        windDirection:data.windDirection,
                        humidity:data.humidity,
                        weather:data.weather
                    }
                    that.$store.commit('storage_user/updateSiteWeather',{
                        siteId:site.siteId,
                        weatherInfo:weatherInfo
                    },{root:true})   
                    that.weatherInfo = {
                        position:data.city,
                        windPower:data.windPower,
                        temperature:data.temperature,
                        windDirection:data.windDirection,
                        humidity:data.humidity,
                        weather:data.weather
                    }                                             
                }) 
            }else{                               
                that.weatherInfo = {
                    ...site.weatherInfo
                }                
            }                  
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 50,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            //添加右键菜单
            this.addContextMenu();            
            //加载控件 
            this.addControl(); 
            var that = this;
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
            }); 
            that.siteMarker.on('click',(e)=>{                                
                    new AMap.InfoWindow({
                        content: `
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${e.lnglat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                       `,                        
                    }).open(that.map,e.lnglat)
                })
            that.map.add(that.siteMarker)           
              //加载国控点
            this.loadMonitPoint()     
        },
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})
        },
        loadLidarData(){
            var that = this; 
            let _width = 100 * this.scanDistance;           
            getHistoryImagesApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    PageIndex:1,
                    PageSize:1,
                    DirTypeId:2   
                },
                success:(d)=>{                
                    var imgPath =  `${uploadMinioURL}${d.Data[0].DirBucketName}/${d.Data[0].DirObjectName}`
                    let time = new Date(d.Data[0].DirTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                    if(!that.canvas){
                        let canvas = document.createElement('canvas')
                        //canvas = this.$refs.polar;
                        let ctx =canvas.getContext("2d"); 
                        canvas.width = _width;
                        canvas.height = _width;               
                        that.canvas = canvas;
                        that.ctx = ctx;
                    }
                    that.canvas.width = _width;
                    that.canvas.height = _width; 
                    let img = new Image(_width,_width)
                    img.crossOrigin ="anonymous"
                    let arr=[]
                    let imgParams = imgPath.match(/(?<=\@).*?(?=\@)/g);
                    if(!imgParams){
                        arr =$help.getLayerMapPosition(90,that.scanDistance,that.center)                                      
                    }else{
                        let w = Number(imgParams[0].split("_")[0]);
                        let vAngle = Number(imgParams[0].split("_")[1]);                                        
                        arr =$help.getLayerMapPosition(vAngle,that.scanDistance,that.center,w/500)                                                              
                    }
                    img.src = imgPath;
                    var bounds = new AMap.Bounds(arr[0],arr[1])   
                    if(!that.canvasLayer){
                        that.canvasLayer = new AMap.CanvasLayer({
                            canvas: that.canvas,
                            bounds: bounds,  
                            opacity:that.opacity,  
                            zooms: [2, 30]            
                        });        
                        that.map.add(that.canvasLayer);
                    }else{
                        that.canvasLayer.setBounds(bounds)                
                    }                
                    img.onload=(f)=>{    
                        if(that.canvasLayer){
                            that.ctx.clearRect(0,0,_width,_width)            
                            that.ctx.drawImage(img,0,0,_width,_width);                            
                            that.canvasLayer.reFresh();
                        }
                        //更新显示时间     
                        that.drawTimeText(time)                            
                           
                    }                    
                }
            })
        },
        addControl(){
    var that = this;
    AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
        //添加一个缩放控件
        // map.addControl(new BasicControl.Zoom({
        //     theme:"dark",
        //     position: 'lb'
        // }));

        //缩放控件，显示Zoom值
        // that.map.addControl(new BasicControl.Zoom({
        //     position: config.zoom.position,
        //     theme:"myself",
        //     showZoomNum: true
        // }));
        
        //比例尺
        var scale = new AMap.Scale();
        that.map.addControl(scale);

        //图层切换控件
        that.map.addControl(new BasicControl.LayerSwitcher({
            // position: {bottom: '160px',left: '50px' },
            position: `bottomControll`,
            theme:'myself'
        }));

        //3d指南针控件
        var controlBar = new AMap.ControlBar({
            //position:{bottom: '180px',left: '-70px' },
            position: `bottomControllBar`,
            theme:'myself'
        });
        that.map.addControl(controlBar) 
        
        //扫描图透明度         
        let el = that.map.getContainer();
        let styleO = 'cursor:pointer;bottom:100px;left:10px;';                       
        if(that.canvasLayer || that.opacity){                    
            that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
        }else{
            that.opacity = 0.7
        }                      
        var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
        <div class="amap-ui-control-layer-slider" style="display:flex;">
            <div class="opacity_box" >
                <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                </svg>
            </div>                            
            <div class="_slider">
                <input type="range" min="1" max="10" value="${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
            </div>
        </div>
        </div>`;   
        var html = $help.toDom(htmlstr)
        var _slider = html[0].querySelector("[type='range']");                        
        var _rest= html[0].querySelector("[data-type='reset']");
        let _numdiv = html[0].querySelector("[data-type='slider_num']")
        that.setOpacity = (value)=>{                   
            _slider.value = value;
            _numdiv.innerHTML = value;                    
            //重置maphelper
            that.opacity = value/10;
            if(that.canvasLayer){
                that.canvasLayer?.setOpacity(value/10)
            }                    
        }
        _rest.addEventListener('click',(e)=>{                                                                          
            _numdiv.innerHTML = 7;
            _slider.value = 7
            //重置maphelper
            that.opacity = 0.7;
            if(that.canvasLayer){
                that.canvasLayer?.setOpacity(0.7)
            }                      
        })
        _slider.addEventListener('change',(e)=>{
            let value = e.target.value;
            _slider.value = value;
            _numdiv.innerHTML = value;                    
            //重置maphelper
            that.opacity = value/10;
            if(that.canvasLayer){
                that.canvasLayer?.setOpacity(value/10)
            }  
        })
        //_slider.onchange = that.opacityChange(event);
        el.appendChild(html[0]);  

        //地点定位                
        let styleP = 'cursor:pointer;bottom:50px;left:15px;';                                                      
                                
        var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
        <div style="display:flex;">
            <div class="setLocation_box" >
                <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                </svg>
            </div>                                        
        </div>
        </div>`;   
        var html = $help.toDom(htmlstr)
        var _setlocal = html[0].querySelector("[type='setLocation']");                        
        _setlocal.addEventListener('click',(e)=>{                     
            that.map.setCenter(that.center)
        })                
        //_slider.onchange = that.opacityChange(event);
        el.appendChild(html[0]);                 
    })    
},
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.35 16 12.69 15.97 13.03 15.95C13 15.8 13 15.65 13 15.5C13 14.96 13.09 14.44 13.24 13.94C12.83 14 12.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V10.03C18.17 10 18.33 10 18.5 10C19 10 19.5 10.08 20 10.22V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C13.06 21 14.07 20.89 15 20.71C14.62 20.14 14.25 19.5 13.93 18.85C13.34 18.94 12.71 19 12 19M12 5C15.87 5 18 6.5 18 7S15.87 9 12 9 6 7.5 6 7 8.13 5 12 5M18.5 12C16.6 12 15 13.6 15 15.5C15 18.1 18.5 22 18.5 22S22 18.1 22 15.5C22 13.6 20.4 12 18.5 12M18.5 16.8C17.8 16.8 17.3 16.2 17.3 15.6C17.3 14.9 17.9 14.4 18.5 14.4S19.7 15 19.7 15.6C19.8 16.2 19.2 16.8 18.5 16.8Z" fill="black"/>
            </svg>
            位置数据</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                let site = that.$store.state.storage_global.currentSite;
                let siteCenter = [site.lng,site.lat]
                let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                if(dis < that.scanDistance * 1000){
                    let angle = $help.bearing(siteCenter,lnglat)
                    angle = angle >= 0 ? angle :angle +360
                    angle = angle - that.$store.state.storage_global.currentSite.yaw < 0 ? angle - that.$store.state.storage_global.currentSite.yaw + 360 : angle - that.$store.state.storage_global.currentSite.yaw;                              
                    if(that.lidarDatas.length > 0){
                        let min = that.lidarDatas[0]
                        let minAngle = Number(min.straightAngle) < 0 ? Number(min.straightAngle) + 360 :Number(min.straightAngle);
                        that.lidarDatas.forEach(a=>{
                            let _straightAngle = Number(a.straightAngle) < 0 ?  Number(a.straightAngle) + 360 :  Number(a.straightAngle);
                            if(Math.abs(_straightAngle - angle) < Math.abs(minAngle - angle)){
                                min = a;
                                minAngle = _straightAngle
                            }                            
                        })
                        if(Math.abs(minAngle - angle)>1){
                            let msg = `<div>点位值： </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }else{         
                            let all = min.distanceData[2] * min.distanceData[1];                         
                            let scanD = that.scanDistance/all * min.data.length;  
                            let _index = Math.round(scanD * dis/(that.scanDistance * 1000));
                            _index = _index - min.distanceData[0]/min.distanceData[1];
                            if(_index > min.data.length -1){
                                let msg = `<div>点位值： </div>`                     
                                new AMap.InfoWindow({
                                    content: msg,                        
                                }).open(that.map,lnglat);
                                return
                            }
                            let _value = min.data[_index]
                            let msg = `<div>点位值：${_value} </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }
                        
                    }                                        
                }                
                else{
                    openNotice({
                        type:'error',
                        text:"所选区域超出范围"
                    })
                }
            }, 1);  

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },
   
        //设置报警点在地图位置
        setCurrentPoint(event,data){ 
            //设置当前活动
            let childrenList = event.target?.parentElement?.parentElement?.children;
            for(var i in childrenList){
                if(i == "length"){
                    continue;
                }
                if(i == data.index){
                    childrenList[i].className = 'table-row-active'
                }else{                    
                    childrenList[i].className = ''
                }

            }
            let item = data.item;           
             var msg = `<div style="font-size:16px;font-weight:600;">报警点信息</div>
            <div>位置：${item.position}</div>
            <div>经纬度：${item.lng},${item.lat}</div>
            <div>次数：${item.count}</div>`

            let InfoWindowMap = new AMap.InfoWindow({                
                position:[item.lng,item.lat],
                content: msg,                        
            });            
             InfoWindowMap.open(this.map)

             //设置中心
            let pix = this.map.lngLatToContainer([item.lng,item.lat])
            let conSize = this.map.getSize();
            if(pix.x <= 0 || pix.y <= 0 || pix.x >= conSize.width || pix.y >= conSize.height){
                this.map.setCenter([item.lng,item.lat])
            }       
        },
        loadAlarmPoints(){           
            this.alarmList = [];
            let start = new Date(new Date().setHours(new Date().getHours() - 12)).pattern("yyyy-MM-dd HH:mm:ss") //前12个小时热图
            let end = new Date().pattern("yyyy-MM-dd HH:mm:ss")//
            var that = this;
            getAlarmPointsApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                filter:that.checkedId.length == 0 ? null :that.checkedId.join(','),  
                params:{
                    StartTime:start,
                    EndTime:end,
                    PageIndex:1,
                    PageSize:1000,
                    DapTypeId:6,     
                },
                success:(d)=>{
                    //分组 
                    let data = d.Data.sort((a,b)=>{
                        return new Date(b.DapTime) - new Date(a.DapTime)
                    }).filter(a=>{                                           
                        let rel = $help.alarmDistanceFilter(that.$store.state.storage_global.currentSite.lng,that.$store.state.storage_global.currentSite.lat,a.DapLng,a.DapLat,that.$store.state.storage_global.currentSite.scanDistance)                                                
                        return rel
                    }) ;
                    let group = []
                    for(var dt of data){
                        let item = group.find(a=>{
                            return a.DapSiteId == dt.DapSiteId && a.DapLng == dt.DapLng && a.DapLat == dt.DapLat
                        })
                        if(item){
                            item.DapLevel += dt.DapLevel;
                            item.DapCount+= dt.DapCount;
                        }   
                        else{
                            group.push(JSON.parse(JSON.stringify(dt)));
                        }
                    }    
                    that.mapHotDatas = group.filter(a=>{                                           
                        let rel = $help.alarmDistanceFilter(that.$store.state.storage_global.currentSite.lng,that.$store.state.storage_global.currentSite.lat,a.DapLng,a.DapLat,that.$store.state.storage_global.currentSite.scanDistance)                                                
                        return rel
                    }).map(a=>{
                        return {
                            lnglat:[a.DapLng,a.DapLat],
                            count:a.DapCount
                        }
                    })                                                 
                    //如果热力图打开则重新展示
                    if(that.hotLayer){
                        that.setHotLayer(that.hotLayer)
                    }    
                    if(this.warnMakerList.length > 0){
                        this.map.remove(this.warnMakerList) 
                    }                     
                     //大凉山森林特殊处理
                    if(that.$store.state.storage_global.currentSite.siteId == 'DALSHAN_370102_07_01'){
                        //查询最近一小时的合并点位
                         let _start = new Date(new Date().setHours(new Date().getHours() - 1)).pattern("yyyy-MM-dd HH:00:00") //前12个小时热图
                         let _end = new Date().pattern("yyyy-MM-dd HH:mm:ss")//          
                         getAlarmPointComputedsApi({
                            id:that.$store.state.storage_global.currentSite.siteId,
                            filter:that.checkedId.join(','),   
                            params:{
                                PageIndex:1,
                                PageSize:100,
                                StartTime:_start,
                                EndTime:_end,                                             
                            }, 
                            success:(d)=>{            
                                let computedData = d.ComputedData            
                                for(let j = 0;j< computedData.length;){
                                    let _computedData = computedData.slice(j,j+=20);                        
                                    let lnglatstr= _computedData.map(a=>{
                                        return `${a.lng},${a.lat}`
                                    }).join('|')                         
                                    getGisApi({
                                        params:{
                                            location:lnglatstr
                                        },
                                        success:(f)=>{
                                             _computedData.forEach(a=>{                                    
                                                var gisInfo = f.find(b=>{
                                                    var lnglat = b.GrLngLat.split(',');                                        
                                                    return Math.abs(Number(lnglat[0])*10000 - Math.round(a.lng*10000)) <= 1 && Math.abs(Number(lnglat[1]) * 10000 - Math.round(a.lat*10000))<=1
                                                }) 
                                                if(gisInfo){                                        
                                                    var position = JSON.parse(gisInfo.GrLoc)     
                                                    if(that.alarmList.findIndex(b=>a.lng == b.lng && a.lat == b.lat && a.time == `${$help.periodToTimeStr(a.timequantum[a.timequantum.length -1][1])}`)<0){
                                                        that.alarmList.push({                                            
                                                            lng:a.lng.toFixed(4),
                                                            lat:a.lat.toFixed(4),
                                                            position:position?.formatted_address,
                                                            province:position?.addressComponent.province,
                                                            city:position?.addressComponent.city == "" ? position?.addressComponent.province:position?.addressComponent.city,
                                                            street:position?.addressComponent.township,
                                                            district:position?.addressComponent.district,
                                                            count:$help.sum(a.intensity),
                                                            time:`${$help.periodToTimeStr(a.timequantum[a.timequantum.length -1][1])}`,                                        
                                                        })
                                                    }                                                                                            
                                                }else{
                                                    console.log(a,f)
                                                }
                                            })                             
                                        }
                                    })
                                } 
                                                    
                            }                
                        })

                    }else{
                        if(!d.Realtime){
                            return
                        }
                        //获得最近一批  
                        let alarmList = data.filter(a=>{
                            return a.DapTime == data[0].DapTime
                        })       
                         
                        //每20个一组      
                        for(let j = 0;j< alarmList.length;){
                            let _alarmList = alarmList.slice(j,j+=20);
                            let lnglatstr= _alarmList.map(a=>{
                                return `${a.DapLng},${a.DapLat}`
                            }).join('|')  
                            getGisApi({
                                params:{
                                    location:lnglatstr
                                },success:(f)=>{                                                            
                                    _alarmList.forEach(a=>{
                                        var gisInfo = f.find(b=>{
                                            var lnglat = b.GrLngLat.split(',');                                        
                                            return  Number(lnglat[0]).toFixed(4) == a.DapLng.toFixed(4) && Number(lnglat[1]).toFixed(4) == a.DapLat.toFixed(4)                                        
                                        })   
                                        if(gisInfo){
                                            var position = JSON.parse(gisInfo.GrLoc)     
                                            if(that.alarmList.findIndex(b=>b.lng == a.DapLng && b.lat == a.DapLat && b.time ==new Date(a.DapTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss')) < 0){
                                                that.alarmList.push({
                                                    time:new Date(a.DapTime.replace("T"," ")).pattern('yyyy-MM-dd HH:mm:ss'),
                                                    lng:a.DapLng,
                                                    lat:a.DapLat,
                                                    position:position?.formatted_address,
                                                    province:position?.addressComponent.province,
                                                    city:position?.addressComponent.city == "" ? position?.addressComponent.province:position?.addressComponent.city,
                                                    street:position?.addressComponent.township,
                                                    district:position?.addressComponent.district,
                                                    count:a.DapCount
                                                })   
                                            }  
                                        }                                         
                                        //添加报警点到地图
                                        that.addWarnPointToMap();  
                                    })                                   
                                }
                            })
                        }  
                    }                                           
                }
            })
        },
        addAlarmPonitToMap(point){    
            if(this.alarmList.length > 0 && point.date != this.alarmList[0].time){                
                this.alarmList = [];
            }
            this.alarmList.push({
                time:point.date,
                lng:point.lng,
                lat:point.lat,
                position:point.position?.formatted_address,
                province:point.position?.addressComponent.province,
                city:point.position?.addressComponent.city == ''?point.position?.addressComponent.province:point.position?.addressComponent.city,
                street:point.position?.addressComponent.township,
                district:point.position?.addressComponent.district,
                count:point.count
            })              
            //添加报警点到地图
            this.addWarnPointToMap();
        },
        addWarnPointToMap(){
            var that = this;  
            if(!this.map){
                return
            }
            if(this.warnMakerList.length > 0){
                this.map.remove(this.warnMakerList) 
            }
            new AMap.InfoWindow().close();
            this.warnMakerList = [];
            let _alarmList = this.alarmList;                                          
            _alarmList.forEach(a => {                
                let marker_item = new AMap.Marker({                                        
                    position: [a.lng,a.lat],
                    content:new_marker_y,
                    anchor:'bottom-center',
                    offset: new AMap.Pixel(0, 15),
                    zIndex:200
                })                                                    
                this.warnMakerList.push(marker_item)
                    marker_item.on("click",function(ev){                                        
                    that.setCurrentPoint(ev,{item:a})
                })
            })            
            if(this.warnMakerList.length > 0){
                this.map.add(this.warnMakerList)
            }            
        },
        //重置大小
        resize(touch){            
           if(touch){
                touch.preventDefault()
                touch.stopPropagation()                
                // let moveEndX = e.changedTouches[0].pageX,moveEndY = e.changedTouches[0].pageY;
                // let ox = moveEndX - startX,oy = moveEndY - startY;
                let resetW = document.body.clientWidth - touch.changedTouches[0].pageX //this.width //(ox/10);
                let resetH = document.body.clientHeight - touch.changedTouches[0].pageY//this.height -1//(oy/10);        
                this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                this.height = resetH > 0.86 * document.body.clientHeight ? 0.86 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                localStorage.setItem("warnTableMHeight",this.height)
                localStorage.setItem("warnTableMWidth",this.width)   

            }else{
                //监听鼠标移动
                let fun = (e)=>{                
                    //重设表格大小
                    let resetW = this.width - e.movementX ;
                    let resetH = this.height - e.movementY
                    this.width = resetW > 0.8 * document.body.clientWidth ? 0.8 * document.body.clientWidth : resetW < 380 ? 380 : resetW;
                    this.height = resetH > 0.86 * document.body.clientHeight ? 0.86 * document.body.clientHeight : resetH < 0.15 * document.body.clientHeight ? 0.15 * document.body.clientHeight : resetH;
                    localStorage.setItem("warnTableMHeight",this.height)
                    localStorage.setItem("warnTableMWidth",this.width)          
                }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{
                    document.removeEventListener('mousemove',fun)
                });
            }     
        },        
        resetWarnTableSize(){
            if(localStorage.getItem("warnTableHeight")){
                this.height = localStorage.getItem("warnTableHeight")
            }
            else{
                this.height = document.body.clientHeight * 0.38
            }
            if(localStorage.getItem("warnTableWidth")){
                this.width = localStorage.getItem("warnTableWidth")
            } 
            else{
                this.width = document.body.clientWidth* 0.46;
            }      
        },       
        draw(){        
            var that = this;   
            if(that.lidarDatas.length == 0 || !this.map){                
                return
            }
            if(!that.lidarDatas || that.lidarDatas.length == 0){
                    return;
            }
            if(!that.hiddenDom){                                                           
                let _width = 400;//包含设置像素

                if(!that.canvas){
                    let canvas = document.createElement('canvas')
                    //canvas = this.$refs.polar;
                    let ctx =canvas.getContext("2d"); 
                    canvas.width = _width;
                    canvas.height = _width;               
                    that.canvas = canvas;
                    that.ctx = ctx;
                }                           
                //let site = this.$store.state.storage_global.currentSite;            
                let arr= $help.getLayerMapPosition(Number(that.lidarDatas[0]?.angleOfPitch),this.scanDistance,this.center)            
                var bounds = new AMap.Bounds(arr[0],arr[1])   
                if(!that.canvasLayer){
                    that.canvasLayer = new AMap.CanvasLayer({
                        canvas: that.canvas,
                        bounds: bounds,  
                        opacity:that.opacity,  
                        zooms: [2, 30]            
                    });        
                    that.map.add(that.canvasLayer);
                }else{
                    if(that.canvas.width != _width){                    
                        that.canvas.width = _width;
                        that.canvas.height = _width;
                        that.canvasLayer.setCanvas(that.canvas)
                    }
                    that.canvasLayer.setBounds(bounds)                
                }            
                //插值画法                                    
                that.drawHelper(_width/2/this.scanDistance);            
                that.drawTimeText(new Date(that.lidarDatas[that.lidarDatas.length - 1].dataTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"))                                 
            }             
        },
        async drawHelper(rp){   
            let yaw =this.$store.state.storage_global.currentSite.yaw;                                 
            let _datas =this.lidarDatas.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });  
            if(_datas.length == 0 || !this.$refs.colorBar)   {
                return;
            }
            let d = this.scanDistance;   

            var that = this;
            let distanceData =_datas[0].distanceData;            
            let noDataLength =  distanceData[0] /  distanceData[1];//无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                that.ctx.putImageData(imageData,0,0)  
                if(that.canvasLayer){
                    that.canvasLayer.reFresh()
                }      
            },noDataLength,scanLength,_datas[0].distanceData[2],{d:d,rp:rp,yaw:yaw,span:2})                           
        },
        //周期时间显示
        drawTimeText(text){
            if(this.map){
                let pos = this.map.lngLatToContainer(this.center); 
                let angle = this.map.getPitch()
                pos.y = (170/ Math.cos(angle/360)).toFixed(2);            
                let lnglat = this.map.containerToLngLat(pos); 
                let datas = [
                    [lnglat.lng,lnglat.lat,text]
                ]                      
                let opt = {series:[{
                    type:'scatter',
                    data:datas,
                    coordinateSystem:'amap',
                    symbolSize:0,
                    label:{
                        show:true,
                        formatter:'{@[2]}',
                        fontSize:24,                
                    }
                }]}
                if(this.mapEchart && this.mapEchart.setOption){
                    this.mapEchart.setOption(opt) 
                }             
            }
        },        
        //热图
        setHotLayer(show){               
            let color = this.color;              
            if(this.mapHotLayer){
                this.map.remove(this.mapHotLayer)                 
            }            
            this.mapHotLayer = null;          
            //1.14版本
            this.mapHotLayer = this.map.getLayers().find(a=>{
                return a.CLASS_NAME == 'Loca.HeatmapLayer';
            });        
            if(!this.mapHotLayer){            
                this.mapHotLayer = new Loca.HeatmapLayer({
                    map:this.map
                })                
            }        
            if(show){             
                if(this.mapHotDatas && this.mapHotDatas.length > 0){                           
                    this.mapHotLayer.setData(this.mapHotDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                }        

                let level = this.map.getZoom();
                let r = level <= 12 ? 16 : 16 + (level -12) * 5;            
                this.mapHotLayer.setOptions({ 
                    map:this.map,                  
                    style: {
                        //altitude:500000,
                        radius: r,
                        color: color
                    }
                });
                this.mapHotLayer.render();
                this.mapHotLayer.show();
            }
            else{
                this.mapHotLayer.hide();
            }
        },     
        loadReciver(){
            var that = this;
            getReciverApi({
                id:this.$store.state.storage_user.user.companyKey,
                params:{
                    Name:""
                },
                success:(d)=>{
                    that.recivers = d.map(a=>{
                        return {
                            text:a.NerName,
                            value:a.NerWechatId
                        }
                    })
                }
            })
        },
        selectAllReciver(){
            if(this.recivers.length == this.wxUserIds.length){
                this.wxUserIds = []
            }else{
                this.wxUserIds = this.recivers.map(a=>{
                    return a.value;
                })
            }
        },
        sendMessage(){
            //发送消息
            let alarmList = this.alarmList;      
            let site = this.$store.state.storage_global.currentSite;   
            let templateId = 1;
            if(site.extendInfo && JSON.parse(site.extendInfo)?.AlarmTemplate) {
                if(JSON.parse(site.extendInfo)?.AlarmTemplate == 'FireSmokeTemplate'){
                    templateId = 2
                }
                if(JSON.parse(site.extendInfo)?.AlarmTemplate == 'PMLSPollutantTemplate'){
                    templateId = 1
                }
                if(JSON.parse(site.extendInfo)?.AlarmTemplate == 'SMSTemplate'){
                    templateId = 3
                }
                if(JSON.parse(site.extendInfo)?.AlarmTemplate == 'DBCenterTemplate'){
                    templateId = 4
                }
            }
            if(alarmList.length == 0){
                return
            }
            if(this.wxUserIds.length == 0)     {
                openNotice({
                    type:"error",
                    text:"请选择微信接收人员"
                })
                return;
            }            
            let provinceCity = alarmList[0].province + alarmList[0].city;
            let time = new Date(alarmList[0].time.replace("T"," "))
            let start = new Date();
            let end = new Date();
            start.setTime(time.getTime() - 1000 * 60 * 4)
            end.setTime(time.getTime() + 1000 * 60 * 4)
            let data =[]            
            this.wxUserIds.forEach(a=>{
                alarmList.forEach(b=>{                    
                    data.push( {
                        Receiver:a,
                        SiteID:site.siteId,
                        SiteName:site.siteName.indexOf("-") >= 0 ? site.siteName.split("-")[1]:site.siteName,
                        Count:b.count,
                        Date:b.time.replace("T"," "),
                        TimeRange:  `${start.pattern("yyyy-MM-dd HH:mm:ss")}~${end.pattern("yyyy-MM-dd HH:mm:ss")}`,
                        Province:b.province,
                        City:b.city,
                        District:b.district,
                        Township:b.street,
                        CommunityBuilding:b.position.replace(provinceCity,""),        
                    })
                })
                
            })          
            sendAlarmToWxUserApi({
                templateId:templateId,
                data:data,                
                success:(d)=>{
                    if(d[0].IsSucceed){
                        openNotice({
                            type:"success",
                            text:"已推送至微信服务"
                        })
                    }else{
                        openNotice({
                            type:"error",
                            text:"推送失败，请联系管理人员"
                        })
                    }
                }
            })

        },
    },
   
}
</script>


<style scoped lang="scss">
.contain{
    max-width: 100%;
}
.map{
    width: 100%;
    height: 100%;
}

.weatherinfo{
     display: flex;
     //flex-direction: column;
     position: absolute;
     flex-wrap: wrap;
     //left: 20px;
     //bottom: 400px
     top: 30px;
}
.weatherinfo i{
  color:#fff !important;
}

.weatherinfo div{
  margin: 0 4px;
  padding: 2px 4px;
  border-radius: 5px;
  line-height: 24px;
  background-color:var(--v-secondary-base);//色调
  color:#fff !important;
  border-bottom-color: #ccc;
  color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
  border: 1px solid #e2f1b4;
  display: flex;
  justify-content: space-around;
}

.warnBtn{
    position: absolute;
    bottom: -20px;
    right: 100px;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    z-index: 1;
}
.warnInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    z-index: 1;
    :deep(.v-data-table){
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

//热图
.hotMapColorBar{
    z-index: 2;
    display: flex;
    flex-direction: column;
    position:absolute;
    top: 100px;
    left: 10px
}
.hotMapColorBarBox{
    width: 15px;
    border-radius: 5px;  
    height: 100%;  
    margin: 10px 0 0 0;
}
.hotMapColorBarText{
    display: flex;
    align-items: center;
    position :relative;
    left:18px;    
    height: 100%;white-space: nowrap
}

.mapColorBarConfig{
    position: absolute;
    top: 15%;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 45%;
}
.mapColorBar{
    z-index: 2;
    position:absolute;
    top: 62px;
    right: 30px;    
}


:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 

// .amap-ui-control-container .amap-ui-control-position-rt .amap-ui-control-theme-my-red{
//   background: red;
// }

.amap-menu-outer{
  border-radius: 5px;
  padding:5px 0 !important;
  background: var(--v-fTheme-base);
  li{
      border-radius: 5px !important;
      margin: 5px;
      background: var(--v-fTheme-base);
      color:var(--v-rTheme-base); 
    }  
//   li:hover{
//     //background: var(--v-fTheme-base);
//     color:var(--v-rTheme-base);
//     border-radius: 5px !important;
//   }
  svg{
    margin-right:5px;
    }
  path{
    fill:var(--v-fTheme-base);
  }
} 
.amap-info-content{
  background: var(--v-fTheme-base);
  color:var(--v-rTheme-base);
}
.amap-info-sharp{
  border-top-color: var(--v-rTheme-base);
}
:deep(.table-row-active){
    background: var(--v-fTheme-lighten2);
}

:deep(.amap-controlbar){
    transition: bottom 0.2s linear;
    bottom: 160px !important;
    left: -40px !important;
}
:deep(.amap-ui-control-container){
    transition: bottom 0.2s linear;
}
:deep(.amap-scalecontrol){
    left: 70% !important;
    bottom: 30px !important;
    transition: bottom 0.2s linear;
}
:deep(.amap-ui-control-position-bottomcontroll){
    bottom: 150px !important;
    left: 10px !important;
}
//垂直热图
.externalDatasBox{    
    flex-shrink: 0;
    position: relative;
    top:0;
    right:0px;  
    overflow: auto;
    max-height: 100%;
    width: 6
    0%;
    z-index: 1;
}
.externalDatasBox:hover{
    background-color: var(--v-fTheme-base);
}
:deep(.amap-layer){
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.width200{
    width:200%;
    bottom: 0;
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.containBox{
    position: relative;
}
.containMapBox{
    position: relative;
    width: 100%;
    height: 100%;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
}
.hotChart{
    width: 50%;
    height: 100%;
    position: relative;    
    transition: width 0.3s linear;
    transition: height 0.3s linear;
    bottom: 0px;
    right: 0px;
}
.rowscale{
    height: 100%;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 20px;
    top: 80px;
}
</style>
